import React, { useState, useEffect } from "react";
import BlueSpeaker from "../../resources/images/bluespeakersm.png";
import DeviceImg from "../../resources/images/deviceimg.png";
import LoopIcon from "@material-ui/icons/Loop";
import FavoriteColor from "../../views/Dashboard/DeviceOverview/FavoriteColor";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { deviceOverviewAction } from "../../store/deviceOverview/deviceOverviewAction";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RoutesObj } from "../../routes";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AppModal from "../common/AppModal/AppModal";
import TransferDevice from "../../views/Dashboard/AddNewDevice/TransferDevice";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Cross from "../../resources/images/CrossBox.png";
import RenameIcon from "../../resources/images/renametick.png";
import Loader from "./Loader";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDown from "../../resources/images/down.png";

import {
  arrowDown,
  arrowUp,
  editYellow,
  frame,
  gateway,
  gatewayForDevice,
  mobDevice,
  productImages,
  spaControllerImg,
  spaTopSideImg,
} from "../../assets/images/images";
import { ArrowRight } from "@material-ui/icons";
import { backArrowActiveIcon } from "../../resources/images";
import { SpaOverviewAction } from "../../store/SpaOverview/SpaOverviewAction";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import routesObj from "../../routes/RoutesObj";
import { Button } from "@material-ui/core";
import CircleProgressBar from "./CircleProgressBar";
import SoftwareUpdateProductBox from "./SoftwareUpdateProductBox";
import mqttClient from "../../config/MqttClient";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import { isPDFUrl } from "./CommonMethods";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  background: "white",
  borderRadius: "20px",
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  padding: "30px",
  outline: "none",
};
const DetailedDeviceInfo = ({
  passDeviceInfo,
  onCloseDeviceInfo,
  onGetUserDevices,
  onUpdatePassDeviceInfo,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [isTranferModelShow, setIsTranferModelShow] = useState(false);
  const [isSoftwareUpdateModelShow, setIsSoftwareUpdateModelShow] =
    useState(false);
  const [isRemoveDeviceModelShow, setIsRemoveDeviceModelShow] = useState({
    modalState: false,
    selectedSection: "",
  });
  const [receiptStatus, setReceiptStatus] = useState("");
  const [displayReceipt, setDisplayReceipt] = useState("");
  const [receipt, setReceipt] = useState("");
  const [viewReceipt, setViewReceipt] = useState("");
  const [myEnv, setMyEnv] = useState({});
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [isOpenEnv, setIsOpenEnv] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [newDeviceName, setNewDeviceName] = useState(passDeviceInfo?.name);
  const [updatedDeviceInfo, setUpdatedDeviceInfo] = useState(passDeviceInfo);
  const [deviceInfo, setDeviceInfo] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [softwareProductOverview, setSoftwareProductOverview] = useState("");
  const [progressBar, setProgressBar] = useState("");

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const spaDeviceId = location?.state?.SpaId;
  const subscriptionTop =
    "devices/BW832F_F236/messages/fw_update_TOPSIDE_topic";
  const publishTop = "devices/BW832F_F236/messages/fw_update";

  const deviceOverviewState = useSelector(
    (state) => state.DeviceOverviewReducer
  );
  const {
    spaDeviceDetails,
    spaSettings,
    spaGateways,
    spaController,
    spaTopside,
  } = useSelector((state) => state.SpaOverviewReducer);

  const { deviceOverviewData, fav_colors } = deviceOverviewState;

  useEffect(() => {
    setUpdatedDeviceInfo(updatedDeviceInfo);
  }, [updatedDeviceInfo]);
  // const { deviceOverviewData, fav_colors } = deviceOverviewState;
  const currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
  useEffect(() => {
    onGetMyenv();
  }, []);
  useEffect(() => {
    dispatch(dashboardActions.onGetUserDevices(userId));
  }, []);
  const userId = localStorage.getItem("userId");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [activeTab, setActiveTab] = useState("Device Overview");
  const [defaultDeviceOverview, setDefaultDeviceOverview] = useState(true);
  const [connectedDeviceTopside, setConnectedDeviceTopside] = useState(
    spaTopside?.name
  );
  const [connectedDevicePack, setConnectedDevicePack] = useState(
    spaController?.name
  );
  const [connectedDeviceGatway, setConnectedDeviceGateway] = useState(
    spaGateways?.name
  );
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // if (tabName === "Device Overview") {
    //   setDeviceInfo("");
    //   setDefaultDeviceOverview(true);
    //   setActiveTab("Device Overview");
    // }
  };
  const backToTab = () => {
    setActiveTab("Device Overview");
    setDefaultDeviceOverview(true);
    setDeviceInfo("");
  };
  const handleEditIconClick = () => {
    setIsEditing(true);
  };
  const handleDeviceDetail = (value) => {
    setDeviceInfo(value);
    setActiveTab("Device Overview");
    setDefaultDeviceOverview(false);
  };
  useEffect(() => {
    if (deviceOverviewData?.id) {
      dispatch(SpaOverviewAction?.onGetSpaInfo(deviceOverviewData?.id));
    }
  }, []);
  const spaDevicesTypes = ["SPABasic", "SPAMid", "SPAAdvance"];

  // const onGetDevices = () => {
  //   // this.setState({ isLoading: true });
  //   if (localStorage.getItem("currentEnv") !== "null") {
  //     const userId = localStorage.getItem("userId");
  //     const userInfo = JSON.parse(
  //       localStorage.getItem("userInfo")
  //     );
  //     const currentEnv = JSON.parse(
  //       localStorage.getItem("currentEnv")
  //     this.props.actions
  //       .onGetUserDevices(userId)
  //       .then((data) => {
  //         console.log("dataaaa", data);
  //         this.setState({
  //           deviceDetail: data?.data?.data,
  //         });

  //         // if (data?.data?.data?.length) {
  //         //   data.data.data.forEach((value, index) => {
  //         //     if (value.productType.includes("SPA")) {
  //         //       this.getSpaOverviewData(value.id);
  //         //     }
  //         //   });
  //         // }
  //       });
  //     // this.props.actions.onGetTranferDevices(userId).then((deviceData) => {});
  //   }
  //   // this.setState({ isLoading: false });
  // };
  const onLoadUserData = (showMsg, msg) => {
    // setIsLoading(true);
    const passwordDec = localStorage.getItem("passwordDec");

    // Fetch user data using dispatch and the onUserGetData action creator
    dispatch(dashboardActions.onUserGetData(userId))
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          localStorage.setItem("userInfo", JSON.stringify(data.data.data));

          const userData = data.data.data;
          setIsloading(false);
          dispatch(
            dashboardActions.onLoadData(
              userData.firstName,
              userData.lastName,
              userData.email,
              passwordDec,
              userData.address,
              userData.cityName,
              userData.stateName,
              userData.zipCode,
              userData.countryName,
              userData.phoneNumber,
              userData
            )
          );

          // Fetch user devices using dispatch and the onGetUserDevices action creator
          dispatch(deviceOverviewAction.onGetUserDevices(userId)).then(
            (response) => {
              if (response.status === 200 && response.data.success) {
                // Load data into Redux store using dispatch and the onLoadData action creator
                dispatch(
                  dashboardActions.onLoadData(response.data.data, userData)
                );

                // Fetch user environment using dispatch and the onGetMyEnvironment action creator
                dispatch(
                  dashboardActions.onGetMyEnvironment(
                    userId,
                    userInfo.customerType === 3 ? userInfo.companyId : false
                  )
                ).then((env) => {
                  setIsloading(false);
                  if (env.status === 200 && env.data.success) {
                    if (showMsg) {
                      toast.success(msg);
                      // onGetDevices();
                      setUpdatedDeviceInfo((prevDeviceInfo) => ({
                        ...prevDeviceInfo,
                        name: newDeviceName,
                      }));
                    }
                  } else {
                    toast.error("Something went wrong, Please login again");
                  }
                });
              }
            }
          );
        } else {
          setIsloading(false);
          localStorage.removeItem("userEmail");
          localStorage.removeItem("password");
          localStorage.removeItem("passwordDec");
          toast.error("Something went wrong, Please login again");
          history.push(RoutesObj.Root.path);
        }
      })
      .catch((error) => {
        setIsloading(false);
        toast.error("Something went wrong, Please try again");
      });
  };
  const updateDeviceTopsideName = (topsideName) => {
    setIsloading(true);
    dispatch(dashboardActions.onUpdateSpaTopside(topsideName, spaTopside?.id))
      .then((data) => {
        if (data?.status === 200) {
          dispatch(SpaOverviewAction.onGetSpaInfo(updatedDeviceInfo?.id))
            .then((res) => {
              setIsloading(false);
              setIsEditing(false);
            })
            .catch((error) => {
              console.log(error, "error");
              setIsloading(false);
            });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again");
        setIsEditing(false);
        setIsloading(false);
      });
  };
  const updateDevicePackName = (packName) => {
    setIsloading(true);
    dispatch(dashboardActions.onUpdateSpaPack(packName, spaController?.id))
      .then((data) => {
        if (data?.status === 200) {
          dispatch(SpaOverviewAction.onGetSpaInfo(updatedDeviceInfo?.id))
            .then((res) => {
              setIsloading(false);
              setIsEditing(false);
            })
            .catch((error) => {
              console.log(error, "error");
              setIsloading(false);
            });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again");
        setIsEditing(false);
        setIsloading(false);

        // onLoadUserData();
      });
  };

  const updateDeviceGatewayName = (gatewayName) => {
    setIsloading(true);
    dispatch(dashboardActions.onUpdateSpaGateway(gatewayName, spaGateways?.id))
      .then((data) => {
        if (data?.status === 200) {
          dispatch(SpaOverviewAction.onGetSpaInfo(updatedDeviceInfo?.id))
            .then((res) => {
              setIsloading(false);
              setIsEditing(false);
              // onLoadUserData();
            })
            .catch((error) => {
              console.log(error, "error");
              setIsloading(false);
            });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again");
        setIsEditing(false);
        setIsloading(false);
      });
  };
  const updateDeviceName = (updateHeading, device) => {
    setIsloading(true);
    dispatch(dashboardActions.onUpdateDevName(updateHeading, device.id))
      .then((data) => {
        dispatch(dashboardActions.onGetUserDevices(userId))
          .then((res) => {
            setIsloading(false);
            setIsEditing(false);
            // onLoadUserData();
          })
          .catch((error) => {
            console.log(error, "error");
            setIsloading(false);
          });
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again");
        setIsEditing(false);
        setIsloading(false);
      });
  };

  const supportedFormat = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    if (!supportedFormat?.includes(file?.type)) {
      toast.error("Please upload only supported formats");
      return;
    }
    if (e.target.files.length) {
      setIsloading(true);
      dispatch(dashboardActions.onUploadReceiptImage(e.target.files[0])).then(
        (data) => {
          setIsloading(false);
          if (data && data.data && data.data.success) {
            setReceipt(data.data.data.url);
            dispatch(
              dashboardActions.onReplaceImage(
                updatedDeviceInfo?.id,
                data.data.data.url
              )
            ).then((res) => {
              if (res && res.data && res.data.success) {
                dispatch(dashboardActions.onGetUserDevices(userId)).then(
                  (newData) => {
                    const updatedDeviceInfoo = newData.data.data.filter(
                      (item) => item.id === updatedDeviceInfo?.id
                    );
                    setUpdatedDeviceInfo(updatedDeviceInfoo[0]);
                  }
                );
                toast.success("Receipt is uploaded successfully");
              } else {
                if (res && res.data && res.data.responseCode === 0) {
                  toast.error(res.data.message);
                } else {
                  toast.error("Something went wrong, Please try again");
                }
              }
            });
            toBase64(e.target.files[0]).then((data) => {
              const result = data.split("base64,")[1];
              setDisplayReceipt(result);
            });
            // toast.success("Receipt uploaded successfully");
            return;
          } else {
            setReceipt("");
            setDisplayReceipt("");
          }
        }
      );
    }
  };

  const onDeleteDevice = async () => {
    setIsloading(true);

    const req = [updatedDeviceInfo.id];
    await dispatch(dashboardActions.deleteDevice(req)).then(async (dev) => {
      setIsloading(false);

      if (dev && dev?.data && dev?.data?.success) {
        toast.success("Device Deleted Successfully!");
        setIsRemoveDeviceModelShow(false);
        onCloseDeviceInfo();
        // onGetUserDevices();
      } else {
        toast.error(
          dev && dev?.data && dev?.data?.message
            ? dev?.data?.message
            : "Something went wrong please try again"
        );
      }
    });
  };
  const onDeleteTopsideDevice = async () => {
    setIsRemoveDeviceModelShow(true);
    setIsloading(true);
    const req = [spaTopside?.id];
    await dispatch(dashboardActions.deleteTopsideDevice(req)).then(
      async (dev) => {
        setIsloading(false);
        if (dev && dev?.data && dev?.data?.success) {
          toast.success("Device Deleted Successfully!");
          setIsRemoveDeviceModelShow(false);
          onCloseDeviceInfo();
          // onGetUserDevices();
        } else {
          toast.error(
            dev && dev?.data && dev?.data?.message
              ? dev?.data?.message
              : "Something went wrong please try again"
          );
        }
      }
    );
  };
  const onDeletePackDevice = async () => {
    setIsloading(true);
    const req = [spaController?.id];
    await dispatch(dashboardActions.deletePackDevice(req)).then(async (dev) => {
      setIsloading(false);
      if (dev && dev?.data && dev?.data?.success) {
        toast.success("Device Deleted Successfully!");
        setIsRemoveDeviceModelShow(false);
        onCloseDeviceInfo();
        // onGetUserDevices();
      } else {
        toast.error(
          dev && dev?.data && dev?.data?.message
            ? dev?.data?.message
            : "Something went wrong please try again"
        );
      }
    });
  };
  const onDeleteGateway = async () => {
    setIsloading(true);
    const req = [spaGateways?.id];
    await dispatch(dashboardActions.deleteGateway(req)).then(async (dev) => {
      setIsloading(false);
      if (dev && dev?.data && dev?.data?.success) {
        toast.success("Device Deleted Successfully!");
        setIsRemoveDeviceModelShow(false);
        // onCloseDeviceInfo();
        // onGetUserDevices();
      } else {
        toast.error(
          dev && dev?.data && dev?.data?.message
            ? dev?.data?.message
            : "Something went wrong please try again"
        );
      }
    });
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onSelectDeviceOwnership = (item) => {
    deviceOverviewAction.onChange("fav_colors", []);
    deviceOverviewAction.onChange("addColors", []);
    deviceOverviewAction.onChange("deviceOverviewData", item);
    item?.fav_colors?.forEach((element) => {
      if (element && element !== "") {
        deviceOverviewAction.onSetSelectedColor(element);
      }
    });
    setIsTranferModelShow(true);
  };
  const onGetMyenv = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const env = await dispatch(dashboardActions.onGetMyEnvironment(userId));
      setMyEnv(env?.data?.data);
      // onGetUserDevices();
      // console.log("my local env--->", env?.data?.data);
      // this.setState({ allEnv: env?.data?.data });
    } catch (error) {
      // Handle any errors that occur during the asynchronous operation
      console.error("Error:", error);
    }
  };

  const handleCloseModal = () => {
    setIsUpdate(false);
    setIsSoftwareUpdateModelShow(false);
  };

  const onChangeEnv = async (id) => {
    setIsloading(true);
    // this.setState({ isLoading: true });

    const userId = localStorage.getItem("userId");
    let req = {
      serialNo: updatedDeviceInfo.serialNo,
      purchaseDate: updatedDeviceInfo.purchaseDate,
      dealerLocationId: updatedDeviceInfo.dealerId,
      receiptStatus: updatedDeviceInfo.receiptStatusCode
        ? updatedDeviceInfo.receiptStatusCode
        : 0,
      deviceId: updatedDeviceInfo.id,
      envId: id,
      name: updatedDeviceInfo.name,
      installationDate: "",
    };

    await dispatch(dashboardActions.onUpdateDeviceInfo(req)).then((data) => {
      if (data && data.data && data.data.success) {
        setIsOpenEnv(false);
        setIsloading(false);

        // // Call onGetUserDevices after a successful update
        dispatch(dashboardActions.onGetUserDevices(userId)).then((newData) => {
          const updatedDeviceInfoo = newData.data.data.filter(
            (item) => item.id === updatedDeviceInfo?.id
          );
          setUpdatedDeviceInfo(updatedDeviceInfoo[0]);
        });
        toast.success("Device environment has been updated successfully");
      } else {
        // this.setState({ isLoading: false });
        setIsloading(false);
        toast.error("Something went wrong, Please try again");
      }
    });
  };
  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      const truncatedText = text.slice(0, maxLength);
      return `${truncatedText}...`;
    }
    return text;
  };

  const handleRemoveDevice = () => {
    if (isRemoveDeviceModelShow.selectedSection === "defaultOverview") {
      onDeleteDevice();
    } else if (isRemoveDeviceModelShow.selectedSection === "gateway") {
      onDeleteGateway();
    } else if (isRemoveDeviceModelShow.selectedSection === "pack") {
      onDeleteTopsideDevice();
    } else if (isRemoveDeviceModelShow.selectedSection === "topside") {
      onDeletePackDevice();
    }
  };

  const handleSoftwareUpdate = () => {
    setIsUpdate(true);
    setupMqttConnection();
  };

  const handleCheckForUpdates = () => {
    setIsSoftwareUpdateModelShow(true);
    if (updatedDeviceInfo?.productId) {
      softwareReleaseProductOverview();
    }
  };
  const softwareReleaseProductOverview = () => {
    dispatch(
      dashboardActions.onGetSoftwareReleaseProductOverview(
        updatedDeviceInfo?.productId
      )
    )
      .then((res) => {
        setSoftwareProductOverview(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const setupMqttConnection = () => {
    console.log(mqttClient, "mqttClient");
    mqttClient.on("connect", () => {
      console.log("Connected to MQTT broker");
      // Subscribe to the desired topic
      mqttClient.subscribe(subscriptionTop, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        // After successful subscription, you can publish a message
        const message = {
          url: softwareProductOverview[0]?.buildUrl,
          destination: "TOPSIDE",
        };

        const updateScreenMessage = JSON.stringify(message);

        mqttClient.publish(publishTop, updateScreenMessage);
      });
    });
    // if (parseInt(progressValue) === 100) {
    //   // Unsubscribe from the topic
    //   mqttClient.unsubscribe(subscriptionTop);
    //   console.log("Unsubscribed from topic:", subscriptionTop);
    // }

    mqttClient.on("message", (topic, updateScreenMessage) => {
      // progress response
      console.log(updateScreenMessage.toString(), "updateScreenMessage");

      console.log(
        `Message received from MQTT Broker for topic ${topic} the message is ${updateScreenMessage}`
      );
    });

    mqttClient.on("error", (error) => {
      console.log("MQTT ERR##:", error);
    });
  };
  return (
    <div>
      <h1
        className="breadcrumbs"
        style={{ paddingLeft: "30px", marginTop: "-25px" }}
      >
        Home / Devices /{" "}
        {activeTab === "Device Overview" && defaultDeviceOverview && (
          <span style={{ color: "#3F4254" }}>{newDeviceName}</span>
        )}
        {activeTab === "Connected Devices" && (
          <span style={{ color: "#3F4254" }}>{newDeviceName}</span>
        )}
        {activeTab === "Product Registration" && (
          <span style={{ color: "#3F4254" }}>{newDeviceName}</span>
        )}
        {activeTab === "Favorite Colors" && (
          <span style={{ color: "#3F4254" }}>{newDeviceName}</span>
        )}
        {deviceInfo === "topside" &&
          activeTab === "Device Overview" &&
          !defaultDeviceOverview && (
            <span style={{ color: "#3F4254" }}>
              {connectedDeviceTopside + " " + "Topside"}
            </span>
          )}
        {deviceInfo === "pack" &&
          activeTab === "Device Overview" &&
          !defaultDeviceOverview && (
            <span style={{ color: "#3F4254" }}>
              {connectedDevicePack + " " + "Packs"}
            </span>
          )}
        {deviceInfo === "gateway" &&
          activeTab === "Device Overview" &&
          !defaultDeviceOverview && (
            <span style={{ color: "#3F4254" }}>
              {connectedDeviceGatway + " " + "Gateways"}
            </span>
          )}
      </h1>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          flexwrap: "wrap",
          // width:"100%",
          padding: "0 20px",
          marginBottom: "30px",
        }}
        className="mobileDetailDevice"
      >
        <div
          style={{ marginRight: "20px" }}
          className="mobileMainContainerDetailDevice"
        >
          <div style={{ position: "relative" }}>
            <div className="DeviceDetailEnv">
              {activeTab === "Device Overview" && defaultDeviceOverview && (
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img
                      src={updatedDeviceInfo?.productImage}
                      style={{
                        width: "60px",
                        height: "45px",
                        borderRadius: "100px",
                      }}
                      alt=""
                    />
                  </div>
                  <div className="deviceDetailHeading">
                    <Tooltip title={newDeviceName}>
                      <h1>
                        {newDeviceName && newDeviceName.length > 25
                          ? `${newDeviceName.slice(0, 20)}...`
                          : newDeviceName}
                      </h1>
                    </Tooltip>
                    <Tooltip title={updatedDeviceInfo?.product}>
                      <h2>
                        {updatedDeviceInfo.product &&
                        updatedDeviceInfo.product.length > 25
                          ? `${updatedDeviceInfo.product.slice(0, 20)}...`
                          : updatedDeviceInfo.product}
                      </h2>
                    </Tooltip>
                  </div>
                </div>
              )}

              {activeTab === "Connected Devices" && (
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img
                      src={updatedDeviceInfo?.productImage}
                      style={{
                        width: "60px",
                        height: "45px",
                        borderRadius: "100px",
                      }}
                      alt=""
                    />
                  </div>
                  <div className="deviceDetailHeading">
                    <Tooltip title={newDeviceName}>
                      <h1>
                        {newDeviceName && newDeviceName.length > 25
                          ? `${newDeviceName.slice(0, 20)}...`
                          : newDeviceName}
                      </h1>
                    </Tooltip>
                    <Tooltip title={updatedDeviceInfo?.product}>
                      <h2>
                        {updatedDeviceInfo.product &&
                        updatedDeviceInfo.product.length > 25
                          ? `${updatedDeviceInfo.product.slice(0, 20)}...`
                          : updatedDeviceInfo.product}
                      </h2>
                    </Tooltip>
                  </div>
                </div>
              )}
              {activeTab === "Product Registration" && (
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img
                      src={updatedDeviceInfo?.productImage}
                      style={{
                        width: "60px",
                        height: "45px",
                        borderRadius: "100px",
                      }}
                      alt=""
                    />
                  </div>
                  <div className="deviceDetailHeading">
                    <Tooltip title={newDeviceName}>
                      <h1>
                        {newDeviceName && newDeviceName.length > 25
                          ? `${newDeviceName.slice(0, 20)}...`
                          : newDeviceName}
                      </h1>
                    </Tooltip>
                    <Tooltip title={updatedDeviceInfo?.product}>
                      <h2>
                        {updatedDeviceInfo.product &&
                        updatedDeviceInfo.product.length > 25
                          ? `${updatedDeviceInfo.product.slice(0, 20)}...`
                          : updatedDeviceInfo.product}
                      </h2>
                    </Tooltip>
                  </div>
                </div>
              )}
              {activeTab === "Favorite Colors" && (
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img
                      src={updatedDeviceInfo?.productImage}
                      style={{
                        width: "60px",
                        height: "45px",
                        borderRadius: "100px",
                      }}
                      alt=""
                    />
                  </div>
                  <div className="deviceDetailHeading">
                    <Tooltip title={newDeviceName}>
                      <h1>
                        {newDeviceName && newDeviceName.length > 25
                          ? `${newDeviceName.slice(0, 20)}...`
                          : newDeviceName}
                      </h1>
                    </Tooltip>
                    <Tooltip title={updatedDeviceInfo?.product}>
                      <h2>
                        {updatedDeviceInfo.product &&
                        updatedDeviceInfo.product.length > 25
                          ? `${updatedDeviceInfo.product.slice(0, 20)}...`
                          : updatedDeviceInfo.product}
                      </h2>
                    </Tooltip>
                  </div>
                </div>
              )}

              {deviceInfo === "topside" &&
              activeTab === "Device Overview" &&
              !defaultDeviceOverview ? (
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img
                      src={mobDevice}
                      style={{
                        width: "60px",
                        height: "45px",
                        borderRadius: "100px",
                      }}
                      alt=""
                    ></img>
                  </div>
                  <div className="deviceDetailHeading">
                    <Tooltip title={connectedDeviceTopside + " " + "Topside"}>
                      <h1>
                        {connectedDeviceTopside?.length > 25
                          ? `${connectedDeviceTopside?.slice(0, 20)}...`
                          : connectedDeviceTopside + " " + "Topside"}
                      </h1>
                    </Tooltip>
                    <Tooltip title={spaTopside?.productSku}>
                      <h2>
                        {spaTopside?.productSku?.length > 25
                          ? `${spaTopside?.productSku?.slice(0, 20)}...`
                          : spaTopside?.productSku}
                      </h2>
                    </Tooltip>
                  </div>
                </div>
              ) : null}
              {deviceInfo === "pack" &&
              activeTab === "Device Overview" &&
              !defaultDeviceOverview ? (
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img
                      src={productImages}
                      style={{
                        width: "60px",
                        height: "45px",
                        borderRadius: "100px",
                      }}
                      alt=""
                    ></img>
                  </div>

                  <div className="deviceDetailHeading">
                    <Tooltip title={connectedDevicePack + " " + "Packs"}>
                      <h1>
                        {connectedDevicePack?.length > 25
                          ? `${connectedDevicePack?.slice(0, 20)}...`
                          : connectedDevicePack + " " + "Packs"}
                      </h1>
                    </Tooltip>
                    <Tooltip title={spaController?.productSku}>
                      <h2>
                        {spaController?.productSku?.length > 25
                          ? `${spaController?.productSku?.slice(0, 20)}...`
                          : spaController?.productSku}
                      </h2>
                    </Tooltip>
                  </div>
                </div>
              ) : null}
              {deviceInfo === "gateway" &&
              activeTab === "Device Overview" &&
              !defaultDeviceOverview ? (
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img
                      src={gatewayForDevice}
                      style={{
                        width: "60px",
                        height: "45px",
                        borderRadius: "100px",
                      }}
                      alt=""
                    ></img>
                  </div>
                  <div className="deviceDetailHeading">
                    <Tooltip title={connectedDeviceGatway + " " + "Gateway"}>
                      <h1>
                        {connectedDeviceGatway?.length > 25
                          ? `${connectedDeviceGatway?.slice(0, 20)}...`
                          : connectedDeviceGatway + " " + "Gateway"}
                      </h1>
                    </Tooltip>
                    <Tooltip title={spaGateways?.productSku}>
                      <h2>
                        {spaGateways?.productSku?.length > 25
                          ? `${spaGateways?.productSku?.slice(0, 20)}...`
                          : spaGateways?.productSku}
                      </h2>
                    </Tooltip>
                  </div>
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px dashed #E4E6EF",
                  padding: "10px 0",
                }}
              >
                <h1 className="deviceDetailPoints">SKU</h1>
                <h1 className="devicePin">{updatedDeviceInfo.sku}</h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px dashed #E4E6EF",
                  padding: "10px 0",
                }}
              >
                <h1 className="deviceDetailPoints">Serial Number</h1>
                <h1 className="devicePin">{updatedDeviceInfo?.serialNo}</h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // borderBottom: "1px dashed #E4E6EF",
                  padding: "10px 0",
                }}
              >
                <h1 className="deviceDetailPoints">Environment</h1>
                <Tooltip
                  title={
                    selectedEnv ? selectedEnv : updatedDeviceInfo?.environment
                  }
                >
                  <h1
                    className="devicePin"
                    onClick={() => setIsOpenEnv(!isOpenEnv)}
                    style={{ cursor: "pointer" }}
                  >
                    {truncateText(
                      selectedEnv
                        ? selectedEnv
                        : updatedDeviceInfo?.environment,
                      15
                    )}

                    <img
                      src={ArrowDown}
                      style={{ marginLeft: "5px" }}
                      alt="arrowDown"
                    />
                  </h1>
                </Tooltip>
              </div>
              {/* {spaDevicesTypes.includes(updatedDeviceInfo.productType) && (
                <div className="text-center my-2">
                  {deviceInfo ? (
                    <button
                      type="text"
                      style={{
                        borderRadius: "6px",
                        background: "#1BBED0",

                        color: "#fff",
                        // padding: "10px 15px", // Add padding to create space for the icon

                        fontWeight: 700,
                        fontFamily: "Roboto",
                        alignItems: "center",
                      }}
                      className="btn-lg"
                      onClick={() =>
                        history.push({
                          pathname: routesObj.SpaSettingsNew.path,
                          state: {
                            SpaId: spaDeviceId,
                          },
                        })
                      }
                    >
                      View in Spa Settings
                    </button>
                  ) : (
                    <button
                      type="text"
                      style={{
                        borderRadius: "6px",
                        background: "#1BBED0",
                        color: "#fff",
                        fontWeight: 700,
                        fontFamily: "Roboto",
                        alignItems: "center",
                      }}
                      className="btn-lg"
                      onClick={() =>
                        history.push({
                          pathname: routesObj.SpaSettingsNew.path,
                          state: {
                            SpaId: spaDeviceId,
                          },
                        })
                      }
                    >
                      Spa Ownership
                    </button>
                  )}
                </div>
              )} */}
            </div>
            {isOpenEnv && (
              <div
                style={{
                  height: "160px",
                  overflowY: "scroll",
                  width: "230px",
                  background: "#ffffff",
                  padding: "20px 0 20px 20px",
                  borderRadius: "10px",
                  position: "absolute",
                  right: 0,
                  top: "230px",
                  boxShadow: "0px 0px 20px 0px #00000024",
                }}
              >
                {myEnv &&
                  myEnv.length > 0 &&
                  myEnv.map((items, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedEnv(items.name);
                        setTimeout(() => {
                          onChangeEnv(items.id);
                        }, 1000); // Delay for 1000 milliseconds (1 second)
                      }}
                    >
                      <img
                        src={items.icon}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "10px",
                        }}
                        alt=""
                      />
                      <p
                        style={{
                          margin: "0",
                          fontWeight: 600,
                          fontSize: "14px",
                          textTransform: "Capitalize",
                        }}
                      >
                        {items.name}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="DeviceDetailEnvSupport">
            <div className="deviceSupportSection">
              <h1>Support Resources</h1>
              <a
                href="https://www.aquaticav.com/pages/support-center"
                target="_blank"
                style={{ color: "#1bbed0" }}
              >
                <h2>RGB Controller Support Center</h2>
              </a>
              <a
                href="https://aavazurestorage.blob.core.windows.net/ofishassets/MobileAppUserGuide.pdf"
                target="_blank"
                style={{ color: "#1bbed0" }}
              >
                <h2>RGB Controller Quick Start Guide</h2>
              </a>
              <h3>Still need help?</h3>
              <a
                href="https://www.aquaticav.com/pages/contact"
                target="_blank"
                style={{ color: "#1bbed0" }}
              >
                <h2>Contact Aquatic AV Support</h2>
              </a>
            </div>
          </div>
        </div>

        <div className="deviceOverviewContainer" style={{ width: "100%" }}>
          <div className="tabs-container">
            <div className="tabSection">
              <h6
                className={
                  activeTab === "Device Overview" ? "active-tab" : "tab"
                }
                onClick={() => handleTabClick("Device Overview")}
                style={{ marginRight: "7%" }}
              >
                Device Overview
              </h6>
              <h6
                className={
                  activeTab === "Product Registration" ? "active-tab" : "tab"
                }
                onClick={() => handleTabClick("Product Registration")}
                style={{ marginRight: "7%" }}
              >
                Product Registration
              </h6>
              {updatedDeviceInfo.productType !== "Physical + IOT" &&
              updatedDeviceInfo.productType !== "Physical" ? (
                <h6
                  className={
                    activeTab === "Connected Devices" ? "active-tab" : "tab"
                  }
                  onClick={() => handleTabClick("Connected Devices")}
                  style={{ marginRight: "7%" }}
                >
                  Connected Devices
                </h6>
              ) : null}
              {(updatedDeviceInfo.productType === "Physical + IOT" ||
                updatedDeviceInfo.productType === "Physical + IOT (RGB)") && (
                <h6
                  className={
                    activeTab === "Favorite Colors" ? "active-tab" : "tab"
                  }
                  onClick={() => handleTabClick("Favorite Colors")}
                >
                  Favorite Colors
                </h6>
              )}
            </div>
            {deviceInfo === "topside" &&
            activeTab === "Device Overview" &&
            !defaultDeviceOverview ? (
              <>
                <div className="" style={{ display: "flex" }}>
                  <div
                    style={{
                      marginTop: "2%",
                    }}
                  >
                    <div
                      className="backToTapContainer my-2"
                      onClick={backToTab}
                    >
                      <img
                        src={backArrowActiveIcon}
                        alt="arrow"
                        width={20}
                        height={20}
                      />
                      <h3 className="backToTap">
                        {deviceInfo && `back to ${newDeviceName}`}
                      </h3>
                    </div>

                    <div
                      style={{
                        marginTop: "3%",
                      }}
                    >
                      <img
                        src={spaTopSideImg}
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "100px",
                        }}
                        alt="productImage"
                      ></img>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "3%",
                    }}
                  >
                    <div>
                      <div
                        className="deviceDetailHeadingMain"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          {isEditing ? (
                            <div style={{ width: "100%" }}>
                              <input
                                type="text"
                                value={connectedDeviceTopside}
                                onChange={(e) =>
                                  setConnectedDeviceTopside(e.target.value)
                                }
                                className="updateName"
                              />
                              {isEditing && (
                                <img
                                  src={RenameIcon}
                                  onClick={() =>
                                    updateDeviceTopsideName(
                                      `${connectedDeviceTopside}`
                                    )
                                  }
                                  className="updatenamebtn"
                                  alt="productImage"
                                />
                              )}
                            </div>
                          ) : (
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ marginRight: "auto" }}>
                                  <Tooltip
                                    title={
                                      connectedDeviceTopside + " " + "Topside"
                                    }
                                  >
                                    <h1>
                                      {connectedDeviceTopside?.length > 25
                                        ? `${connectedDeviceTopside?.slice(
                                            0,
                                            20
                                          )}...`
                                        : connectedDeviceTopside +
                                          " " +
                                          "Topside"}
                                    </h1>
                                  </Tooltip>
                                </div>
                                <div style={{ marginLeft: "20px" }}>
                                  <img
                                    src={editYellow}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={handleEditIconClick}
                                    alt="productImage"
                                  />
                                </div>
                              </div>

                              <h2>{spaTopside?.productSku}</h2>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Model</h1>
                      <h1 className="devicePin">{spaTopside?.model}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Product SKU</h1>
                      <h1 className="devicePin">{spaTopside?.productSku}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Serial Number</h1>
                      <h1 className="devicePin">{spaTopside?.serialNo}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">MAC ID</h1>
                      <h1 className="devicePin">{spaTopside?.macId}</h1>
                    </div>
                    <button
                      className="mt-2"
                      style={{
                        // width: "105px",
                        width: "30%",
                        borderRadius: "6px",
                        backgroundColor:
                          spaTopside?.registrationStatus === 0
                            ? " #F2C94C26"
                            : spaTopside?.registrationStatus === 1
                            ? "#DEEFE5"
                            : "#FCE6E6",
                        // Default text color
                        color:
                          spaTopside?.registrationStatus === 0
                            ? "#E1B93E"
                            : spaTopside?.registrationStatus === 1
                            ? "#219653"
                            : "#EB5757",
                      }}
                    >
                      {spaTopside?.registrationStatus === 0
                        ? "Pending"
                        : spaTopside?.registrationStatus === 1
                        ? "Approved"
                        : "Rejected"}
                    </button>
                    <div className="removeAndTransferDevice">
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#F5F8FA",
                          color: "#7E8299",
                          marginRight: "25px",
                        }}
                        onClick={() =>
                          onSelectDeviceOwnership(updatedDeviceInfo)
                        }
                      >
                        Transfer Ownership
                      </button>
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#EB5757",
                          color: "#fff",
                        }}
                        onClick={() =>
                          setIsRemoveDeviceModelShow({
                            modalState: true,
                            selectedSection: "topside",
                          })
                        }
                      >
                        Remove Device
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {deviceInfo === "pack" &&
            activeTab === "Device Overview" &&
            !defaultDeviceOverview ? (
              <>
                <div className="" style={{ display: "flex" }}>
                  <div
                    style={{
                      marginTop: "2%",
                    }}
                  >
                    <div
                      className="backToTapContainer my-2"
                      onClick={backToTab}
                    >
                      <img
                        src={backArrowActiveIcon}
                        alt="arrow"
                        width={20}
                        height={20}
                      />
                      <h3 className="backToTap">
                        {deviceInfo && `back to ${newDeviceName}`}
                      </h3>
                    </div>
                    <img
                      src={spaControllerImg}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100px",
                      }}
                      alt="productImage"
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "3%",
                    }}
                  >
                    <div>
                      <div
                        className="deviceDetailHeadingMain"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          {isEditing ? (
                            <div style={{ width: "100%" }}>
                              <input
                                type="text"
                                value={connectedDevicePack}
                                onChange={(e) =>
                                  setConnectedDevicePack(e.target.value)
                                }
                                className="updateName"
                              />
                              {isEditing && (
                                <img
                                  src={RenameIcon}
                                  onClick={() =>
                                    updateDevicePackName(
                                      `${connectedDevicePack}`
                                    )
                                  }
                                  className="updatenamebtn"
                                  alt="productImage"
                                />
                              )}
                            </div>
                          ) : (
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title={connectedDevicePack + " " + "Packs"}
                                >
                                  <h1>
                                    {connectedDevicePack?.length > 25
                                      ? `${connectedDevicePack?.slice(
                                          0,
                                          20
                                        )}...`
                                      : connectedDevicePack + " " + "Packs"}
                                  </h1>
                                </Tooltip>
                                <img
                                  src={editYellow}
                                  style={{
                                    marginLeft: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleEditIconClick}
                                  alt="productImage"
                                />
                              </div>
                              <h2>{spaController?.productSku}</h2>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Model</h1>
                      <h1 className="devicePin">{spaController?.model}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Product SKU</h1>
                      <h1 className="devicePin">{spaController?.productSku}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Serial Number</h1>
                      <h1 className="devicePin">{spaController?.serialNo}</h1>
                    </div>
                    <button
                      style={{
                        // width: "105px",
                        width: "30%",
                        borderRadius: "6px",
                        backgroundColor:
                          spaController?.registrationStatus === 0
                            ? " #F2C94C26"
                            : spaController?.registrationStatus === 1
                            ? "#DEEFE5"
                            : "#FCE6E6",
                        // Default text color
                        color:
                          spaController?.registrationStatus === 0
                            ? "#E1B93E"
                            : spaController?.registrationStatus === 1
                            ? "#219653"
                            : "#EB5757",
                      }}
                    >
                      {spaController?.registrationStatus === 0
                        ? "Pending"
                        : spaController?.registrationStatus === 1
                        ? "Approved"
                        : "Rejected"}
                    </button>
                    <div className="removeAndTransferDevice">
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#F5F8FA",
                          color: "#7E8299",
                          marginRight: "25px",
                        }}
                        onClick={() =>
                          onSelectDeviceOwnership(updatedDeviceInfo)
                        }
                      >
                        Transfer Ownership
                      </button>
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#EB5757",
                          color: "#fff",
                        }}
                        onClick={() =>
                          setIsRemoveDeviceModelShow({
                            modalState: true,
                            selectedSection: "pack",
                          })
                        }
                      >
                        Remove Device
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {deviceInfo === "gateway" &&
            activeTab === "Device Overview" &&
            !defaultDeviceOverview ? (
              <>
                <div className="" style={{ display: "flex" }}>
                  <div
                    style={{
                      marginTop: "2%",
                    }}
                  >
                    <div
                      className="backToTapContainer my-2"
                      onClick={backToTab}
                    >
                      <img
                        src={backArrowActiveIcon}
                        alt="arrow"
                        width={20}
                        height={20}
                      />
                      <h3 className="backToTap">
                        {deviceInfo && `back to ${newDeviceName}`}
                      </h3>
                    </div>
                    <img
                      src={gateway}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100px",
                      }}
                      alt="productImage"
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "3%",
                    }}
                  >
                    <div>
                      <div
                        className="deviceDetailHeadingMain"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          {isEditing ? (
                            <div style={{ width: "100%" }}>
                              <input
                                type="text"
                                value={connectedDeviceGatway}
                                onChange={(e) =>
                                  setConnectedDeviceGateway(e.target.value)
                                }
                                className="updateName"
                              />
                              {isEditing && (
                                <img
                                  src={RenameIcon}
                                  onClick={() =>
                                    updateDeviceGatewayName(
                                      `${connectedDeviceGatway}`
                                    )
                                  }
                                  className="updatenamebtn"
                                  alt="productImage"
                                />
                              )}
                            </div>
                          ) : (
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title={
                                    connectedDeviceGatway + " " + "Gateway"
                                  }
                                >
                                  <h1>
                                    {connectedDeviceGatway?.length > 25
                                      ? `${connectedDeviceGatway?.slice(
                                          0,
                                          20
                                        )}...`
                                      : connectedDeviceGatway + " " + "Gateway"}
                                  </h1>
                                </Tooltip>
                                <img
                                  src={editYellow}
                                  style={{
                                    marginLeft: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleEditIconClick}
                                  alt="productImage"
                                />
                              </div>
                              <h2>{spaGateways?.productSKU}</h2>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Last Connected</h1>
                      <h1 className="devicePin">
                        {spaGateways?.lastConnected}
                      </h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">
                        {" "}
                        Gateway Serial Number
                      </h1>
                      <h1 className="devicePin">{spaGateways?.serialNo}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Product SKU</h1>
                      <h1 className="devicePin">{spaGateways?.productSKU}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Mac Address</h1>
                      <h1 className="devicePin">{spaGateways.macAddress}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Spa Model</h1>
                      <h1 className="devicePin">{spaGateways?.spaModel}</h1>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">
                        Software/Firmware Version
                      </h1>
                      <h1 className="devicePin">
                        {spaGateways?.softwareFirmwareVersion}
                      </h1>
                    </div>
                    <button
                      style={{
                        // width: "105px",
                        width: "30%",
                        borderRadius: "6px",
                        backgroundColor:
                          spaGateways?.registrationStatus === 0
                            ? " #F2C94C26"
                            : spaGateways?.registrationStatus === 1
                            ? "#DEEFE5"
                            : "#FCE6E6",
                        // Default text color
                        color:
                          spaGateways?.registrationStatus === 0
                            ? "#E1B93E"
                            : spaGateways?.registrationStatus === 1
                            ? "#219653"
                            : "#EB5757",
                      }}
                    >
                      {spaGateways?.registrationStatus === 0
                        ? "Pending"
                        : spaGateways?.registrationStatus === 1
                        ? "Approved"
                        : "Rejected"}
                    </button>
                    <div className="removeAndTransferDevice">
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#F5F8FA",
                          color: "#7E8299",
                          marginRight: "25px",
                        }}
                        onClick={() =>
                          onSelectDeviceOwnership(updatedDeviceInfo)
                        }
                      >
                        Transfer Ownership
                      </button>
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#EB5757",
                          color: "#fff",
                        }}
                        onClick={() =>
                          setIsRemoveDeviceModelShow({
                            modalState: true,
                            selectedSection: "gateway",
                          })
                        }
                      >
                        Remove Device
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {activeTab === "Device Overview" && defaultDeviceOverview && (
              <>
                <div className="" style={{ display: "flex" }}>
                  <div
                    style={{
                      marginTop: "5%",
                    }}
                    className="detailDeviceOverMobileImg"
                  >
                    <img
                      src={updatedDeviceInfo.productImage}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100px",
                      }}
                      alt="productImage"
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "3%",
                    }}
                  >
                    <div>
                      <div
                        className="deviceDetailHeadingMain"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          {isEditing ? (
                            <div style={{ width: "100%" }}>
                              <input
                                type="text"
                                value={newDeviceName}
                                onChange={(e) =>
                                  setNewDeviceName(e.target.value)
                                }
                                className="updateName"
                              />
                              {isEditing && (
                                <img
                                  src={RenameIcon}
                                  onClick={() =>
                                    updateDeviceName(
                                      newDeviceName,
                                      updatedDeviceInfo
                                    )
                                  }
                                  className="updatenamebtn"
                                  alt="productImage"
                                />
                              )}
                            </div>
                          ) : (
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Tooltip title={newDeviceName}>
                                  <h1>
                                    {newDeviceName?.length > 25
                                      ? `${newDeviceName?.slice(0, 20)}...`
                                      : newDeviceName}
                                  </h1>
                                </Tooltip>
                                <img
                                  src={editYellow}
                                  style={{
                                    marginLeft: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleEditIconClick}
                                  alt="productImage"
                                />
                              </div>
                              <h2>{updatedDeviceInfo.product}</h2>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">SKU</h1>
                      <h1 className="devicePin">{updatedDeviceInfo.sku}</h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Serial Number</h1>
                      <h1 className="devicePin">
                        {updatedDeviceInfo.serialNo}
                      </h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Environment</h1>
                      <h1 className="devicePin">
                        {updatedDeviceInfo.environment}
                      </h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Last Connected</h1>
                      <h1 className="devicePin">
                        {updatedDeviceInfo.lastConnected}
                      </h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">
                        Software/Firmware Version
                      </h1>
                      <h1 className="devicePin">
                        {updatedDeviceInfo.softwareVersion}
                      </h1>

                      <span
                        className="softwareUpdateBtn"
                        onClick={handleCheckForUpdates}
                      >
                        {/* <Button
                          className="checkUpdate"
                          endIcon={<LoopIcon />}
                          variant="contained"
                        >
                          Check for updates
                        </Button> */}

                        {/* <Button className="updateSoftware" endIcon={<LoopIcon />} variant="contained"> Update Software</Button> 

                          <Button className="uptodate"  variant="contained">Software Up to Date</Button>    */}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">
                        Warranty Expiration
                      </h1>
                      <h1 className="devicePin">
                        {updatedDeviceInfo.expiryDate}
                      </h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px dashed #E4E6EF",
                        padding: "10px 0",
                      }}
                    >
                      <h1 className="deviceDetailPoints">Warranty Remaining</h1>
                      <h1 className="devicePin">
                        {updatedDeviceInfo.remainingDays}
                      </h1>
                    </div>
                    <div className="deviceNotesWrapper">
                      <h1 className="deviceDetailPoints">Notes</h1>
                      <div className="devicePin notesText">
                        {updatedDeviceInfo?.notes}
                      </div>
                    </div>
                    <button
                      style={{
                        // width: "105px",
                        width: "30%",
                        borderRadius: "6px",
                        backgroundColor:
                          updatedDeviceInfo?.status === "Receipt Missing"
                            ? "#E0F0FA"
                            : updatedDeviceInfo?.status ===
                                "Registration Pending" ||
                              updatedDeviceInfo?.status === "Pending"
                            ? " #F2C94C26"
                            : updatedDeviceInfo?.status === "Incomplete" ||
                              updatedDeviceInfo?.status === "Incomplete"
                            ? " #F2C94C26"
                            : updatedDeviceInfo?.status === "Registered" ||
                              updatedDeviceInfo?.status === "Register" ||
                              updatedDeviceInfo?.status === "Approved"
                            ? "#DEEFE5"
                            : updatedDeviceInfo?.status === "Rejected" ||
                              updatedDeviceInfo?.status === "Denied" ||
                              updatedDeviceInfo?.status ===
                                "Registration Denied" ||
                              updatedDeviceInfo?.status === "Blocked"
                            ? "#FCE6E6"
                            : "#FCE6E6", // Default text color
                        color:
                          updatedDeviceInfo.status === "Receipt Missing"
                            ? "#2D9CDB"
                            : updatedDeviceInfo?.status ===
                                "Registration Pending" ||
                              updatedDeviceInfo?.status === "Pending"
                            ? "#E1B93E"
                            : updatedDeviceInfo?.status === "Incomplete" ||
                              updatedDeviceInfo?.status === "Incomplete"
                            ? "#E1B93E"
                            : updatedDeviceInfo?.status === "Registered" ||
                              updatedDeviceInfo?.status === "Register" ||
                              updatedDeviceInfo?.status === "Approved"
                            ? "#219653"
                            : updatedDeviceInfo?.status === "Rejected" ||
                              updatedDeviceInfo?.status === "Denied" ||
                              updatedDeviceInfo?.status ===
                                "Registration Denied" ||
                              updatedDeviceInfo?.status === "Blocked"
                            ? "#EB5757"
                            : "#EB5757", // Default text color
                      }}
                      className="deviceReceiptStatusMobile"
                    >
                      {updatedDeviceInfo?.status}
                    </button>
                    <div className="removeAndTransferDevice">
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#F5F8FA",
                          color: "#7E8299",
                          marginRight: "25px",
                        }}
                        className="ownershipMobile"
                        onClick={() =>
                          onSelectDeviceOwnership(updatedDeviceInfo)
                        }
                      >
                        Transfer Ownership
                      </button>
                      <button
                        style={{
                          //   width: "150px",
                          borderRadius: "6px",
                          background: "#EB5757",
                          color: "#fff",
                        }}
                        className="ownershipMobile"
                        onClick={() =>
                          setIsRemoveDeviceModelShow({
                            modalState: true,
                            selectedSection: "defaultOverview",
                          })
                        }
                      >
                        Remove Device
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {activeTab === "Product Registration" && (
              <>
                <div className="" style={{ display: "flex" }}>
                  <div
                    style={{
                      marginTop: "5%",
                    }}
                    className="detailDeviceOverMobileImg"
                  >
                    <img
                      src={updatedDeviceInfo.productImage}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100px",
                      }}
                      alt="productImage"
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "3%",
                    }}
                  >
                    <div>
                      <div className="deviceDetailHeadingMain">
                        <div
                          style={{
                            display: "flex",
                            // alignItems: "center",
                            justifyContent: "",
                            flexDirection: "column",
                          }}
                        >
                          <h1>Product Registration</h1>
                          {/* <span>Registered</span> */}
                          <h2>{updatedDeviceInfo.product}</h2>
                        </div>

                        <div className="productStatus">
                          <p
                            style={{
                              backgroundColor:
                                updatedDeviceInfo?.status === "Receipt Missing"
                                  ? "#E0F0FA"
                                  : updatedDeviceInfo?.status ===
                                      "Registration Pending" ||
                                    updatedDeviceInfo?.status === "Pending"
                                  ? " #F2C94C26"
                                  : updatedDeviceInfo?.status ===
                                      "Incomplete" ||
                                    updatedDeviceInfo?.status === "Incomplete"
                                  ? " #F2C94C26"
                                  : updatedDeviceInfo?.status ===
                                      "Registered" ||
                                    updatedDeviceInfo?.status === "Register" ||
                                    updatedDeviceInfo?.status === "Approved"
                                  ? "#DEEFE5"
                                  : updatedDeviceInfo?.status === "Rejected" ||
                                    updatedDeviceInfo?.status === "Denied" ||
                                    updatedDeviceInfo?.status ===
                                      "Registration Denied" ||
                                    updatedDeviceInfo?.status === "Blocked"
                                  ? "#FCE6E6"
                                  : "black", // Default text color
                              color:
                                updatedDeviceInfo?.status === "Receipt Missing"
                                  ? "#2D9CDB"
                                  : updatedDeviceInfo?.status ===
                                      "Registration Pending" ||
                                    updatedDeviceInfo?.status === "Pending"
                                  ? "#E1B93E"
                                  : updatedDeviceInfo?.status ===
                                      "Incomplete" ||
                                    updatedDeviceInfo?.status === "Incomplete"
                                  ? "#E1B93E"
                                  : updatedDeviceInfo?.status ===
                                      "Registered" ||
                                    updatedDeviceInfo?.status === "Register" ||
                                    updatedDeviceInfo?.status === "Approved"
                                  ? "#219653"
                                  : updatedDeviceInfo?.status === "Rejected" ||
                                    updatedDeviceInfo?.status === "Denied" ||
                                    updatedDeviceInfo?.status ===
                                      "Registration Denied" ||
                                    updatedDeviceInfo?.status === "Blocked"
                                  ? "#EB5757"
                                  : "black", // Default text color
                              width: "164px",
                              height: "28px",
                              padding: "4px 8px 4px 8px",
                              borderRadius: "6px",
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "Poppins",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {updatedDeviceInfo?.status}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                        // borderBottom: "1px dashed #E4E6EF",
                        padding: "20px 0",
                      }}
                      className="inputmobilepadding"
                    >
                      <h1 className="deviceDetaitInputLabel">Serial Number</h1>
                      <input
                        type="text"
                        value={updatedDeviceInfo.serialNo}
                        className="deviceInfoInput"
                        disabled
                      ></input>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                        // borderBottom: "1px dashed #E4E6EF",
                        padding: "20px 0",
                      }}
                      className="inputmobilepadding"
                    >
                      <h1 className="deviceDetaitInputLabel">
                        Purchase Location
                      </h1>

                      <input
                        type="text"
                        value={
                          updatedDeviceInfo.dealer
                            ? updatedDeviceInfo.dealer
                            : "Dealer not listed"
                        }
                        className="deviceInfoInput"
                        disabled
                      ></input>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      className="purdate"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                          // borderBottom: "1px dashed #E4E6EF",
                          padding: "20px 0",
                          width: "45%",
                        }}
                        className="purdatefield"
                      >
                        <h1 className="deviceDetaitInputLabel">
                          Purchase Date
                        </h1>
                        <input
                          type="text"
                          value={updatedDeviceInfo.purchaseDate}
                          className="deviceInfoInput"
                          disabled
                        ></input>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                          // borderBottom: "1px dashed #E4E6EF",
                          padding: "10px 0",
                          width: "45%",
                        }}
                        className="purdatefield"
                      >
                        <h1 className="deviceDetaitInputLabel">
                          Installation Date
                        </h1>
                        <input
                          type="text"
                          value={updatedDeviceInfo.installationDate}
                          className="deviceInfoInput"
                          disabled
                        ></input>
                      </div>
                    </div>
                    <div className="productStatusMobile">
                      <p
                        style={{
                          backgroundColor:
                            updatedDeviceInfo?.status === "Receipt Missing"
                              ? "#E0F0FA"
                              : updatedDeviceInfo?.status ===
                                  "Registration Pending" ||
                                updatedDeviceInfo?.status === "Pending"
                              ? " #F2C94C26"
                              : updatedDeviceInfo?.status === "Registered" ||
                                updatedDeviceInfo?.status === "Register" ||
                                updatedDeviceInfo?.status === "Approved"
                              ? "#DEEFE5"
                              : updatedDeviceInfo?.status === "Rejected" ||
                                updatedDeviceInfo?.status === "Denied" ||
                                updatedDeviceInfo?.status ===
                                  "Registration Denied" ||
                                updatedDeviceInfo?.status === "Blocked"
                              ? "#FCE6E6"
                              : "black", // Default text color
                          color:
                            updatedDeviceInfo?.status === "Receipt Missing"
                              ? "#2D9CDB"
                              : updatedDeviceInfo?.status ===
                                  "Registration Pending" ||
                                updatedDeviceInfo?.status === "Pending"
                              ? "#E1B93E"
                              : updatedDeviceInfo?.status === "Registered" ||
                                updatedDeviceInfo?.status === "Register" ||
                                updatedDeviceInfo?.status === "Approved"
                              ? "#219653"
                              : updatedDeviceInfo?.status === "Rejected" ||
                                updatedDeviceInfo?.status === "Denied" ||
                                updatedDeviceInfo?.status ===
                                  "Registration Denied" ||
                                updatedDeviceInfo?.status === "Blocked"
                              ? "#EB5757"
                              : "black", // Default text color
                          width: "164px",
                          height: "28px",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "6px",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {updatedDeviceInfo?.status}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "20px",
                      }}
                    >
                      {updatedDeviceInfo.receipt && (
                        <button
                          style={{
                            //   width: "150px",
                            borderRadius: "6px",
                            background: "#F5F8FA",
                            color: "#7E8299",
                            marginRight: "25px",
                          }}
                          onClick={() => setViewReceipt(true)}
                        >
                          View
                        </button>
                      )}
                      <div>
                        {/* Hidden file input element */}
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={handleChangeImage}
                          accept={supportedFormat.join(",")}
                        />

                        <button
                          style={{
                            borderRadius: "6px",
                            background: "#1BBED0",

                            color: "#fff",
                            // padding: "10px 15px", // Add padding to create space for the icon
                            paddingLeft: "40px",
                            paddingRight: "20px",
                            fontWeight: 700,
                            fontFamily: "Roboto",
                            alignItems: "center",
                          }}
                          className="uploadreceiptbtn"
                          onClick={() => {
                            const fileInput =
                              document.getElementById("fileInput");
                            fileInput.click();
                          }}
                        >
                          Upload Receipt
                        </button>
                      </div>
                    </div>
                    <h3
                      style={{
                        fontWeight: "500",
                        color: "red",
                        fontSize: "12px",
                        marginTop: "8px",
                      }}
                    >
                      Please ensure the Price, Model Number, Seller Name, and
                      Date of Purchase fields are properly filled on the
                      receipt.*
                    </h3>
                    <h3
                      style={{
                        fontWeight: "500",
                        color: "red",
                        fontSize: "12px",
                        marginTop: "2px",
                      }}
                    >
                      Receipt supported only. (JPEG, JPG, PDF and PNG)*
                    </h3>
                  </div>
                </div>
              </>
            )}
            {activeTab === "Connected Devices" && (
              <>
                <div className="connectedDeviceContainer">
                  <div
                    style={{
                      marginTop: "5%",
                    }}
                    className="detailDeviceOverMobileImg"
                  >
                    <img
                      src={frame}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100px",
                      }}
                      alt="productImage"
                    ></img>
                  </div>
                  <div className="connectedDeviceHeading">
                    <h1>Connected Devices</h1>
                    {Object.keys(spaTopside).length > 0 ? (
                      <div
                        className="deviceSection mt-4"
                        onClick={() => handleDeviceDetail("topside")}
                      >
                        <div className="innerDeviceLeftSection">
                          <img
                            src={mobDevice}
                            alt="mobDevice"
                            width={60}
                            height={29.31}
                          />
                          <div className="ml-2 mt-2">
                            <h2>{connectedDeviceTopside} Topside</h2>
                            <h5>{spaSettings?.spaSeries}</h5>
                          </div>
                        </div>
                        <img
                          src={arrowUp}
                          alt="arrowUp"
                          width={10}
                          height={17}
                        />
                      </div>
                    ) : null}
                    {Object.keys(spaController).length > 0 ? (
                      <div
                        className="deviceSection mt-4"
                        onClick={() => handleDeviceDetail("pack")}
                      >
                        <div className="innerDeviceLeftSection">
                          <img
                            src={productImages}
                            alt="mobDevice"
                            width={55}
                            height={50}
                          />

                          <div className="ml-2 mt-2">
                            <h2>{connectedDevicePack} Pack</h2>
                            <h5>{spaSettings?.spaSeries}</h5>
                          </div>
                        </div>

                        <img
                          src={arrowUp}
                          alt="arrowUp"
                          width={10}
                          height={17}
                        />
                      </div>
                    ) : null}

                    {Object.keys(spaGateways).length > 0 ? (
                      <div
                        className="deviceSection my-4"
                        onClick={() => handleDeviceDetail("gateway")}
                      >
                        <div className="innerDeviceLeftSection">
                          <img
                            src={gatewayForDevice}
                            alt="mobDevice"
                            width={50}
                            height={40}
                          />

                          <div className="ml-2 mt-2">
                            <h2>{connectedDeviceGatway} Gateway</h2>
                            <h5>{spaSettings?.spaSeries}</h5>
                          </div>
                        </div>

                        <img
                          src={arrowUp}
                          alt="arrowUp"
                          width={10}
                          height={17}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            )}
            {isLoading ? <Loader /> : null}
            {activeTab === "Favorite Colors" && (
              <>
                <FavoriteColor
                  colorCollection={fav_colors}
                  deviceId={deviceOverviewData.id}
                  envId={currentEnv?.id}
                />
              </>
            )}
          </div>
        </div>
        <div className="DeviceDetailEnvSupportMobile">
          <div className="deviceSupportSection">
            <h1>Support Resources</h1>
            <a
              href="https://www.aquaticav.com/pages/support-center"
              target="_blank"
              style={{ color: "#1bbed0" }}
            >
              <h2>RGB Controller Support Center</h2>
            </a>
            <a
              href="https://aavazurestorage.blob.core.windows.net/ofishassets/MobileAppUserGuide.pdf"
              target="_blank"
              style={{ color: "#1bbed0" }}
            >
              <h2>RGB Controller Quick Start Guide</h2>
            </a>
            <h3>Still need help?</h3>
            <a
              href="https://www.aquaticav.com/pages/contact"
              target="_blank"
              style={{ color: "#1bbed0" }}
            >
              <h2>Contact Aquatic AV Support</h2>
            </a>
          </div>
        </div>

        {/* {isTranferModelShow && ( */}
        <Modal
          title="Model"
          open={viewReceipt}
          onClose={() => setViewReceipt(false)}
        >
          <Box style={style}>
            <div className="registerDevicePopup">
              {receipt !== "" && !isPDFUrl(receipt) ? (
                <div>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {
                      <img
                        src={`${"data:image/png;base64,"}${displayReceipt}`}
                        alt=""
                        className="reciptview"
                      />
                    }
                  </span>
                </div>
              ) : isPDFUrl(updatedDeviceInfo.receipt) ? (
                <div
                  className=""
                  style={{
                    overflowX: "scroll",
                    overflowY: "scroll",
                    bottom: "0px",
                  }}
                >
                  <Document file={updatedDeviceInfo.receipt}>
                    <Page pageNumber={1} />
                  </Document>
                </div>
              ) : (
                <div>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {
                      <img
                        src={updatedDeviceInfo.receipt}
                        alt=""
                        className="reciptview"
                      />
                    }
                  </span>
                </div>
              )}
            </div>
          </Box>
        </Modal>
        <Modal
          title="Model"
          open={isTranferModelShow}
          // handleclose={() => this.closeTranferDevice()}
        >
          <Box style={style} className="transferOwnerShipMobile">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <img
                src={Cross}
                onClick={() => setIsTranferModelShow(false)}
                alt="transferModal"
              ></img>
            </div>
            <h1 className="transferOwner">Transfer Ownership</h1>
            <p className="transferOwnerHeading">
              Selling or giving this device away? Enter the new owner’s email
              below to transfer device ownership!
            </p>
            <TransferDevice
              // onLoadCompleteData={() => {
              //   this.closeTranferDevice();
              //   this.onLoadCompleteData(
              //     true,
              //     "Device transfer started! We'll keep you updated until it's off your Aquatic AV Account."
              //   );
              // }}
              isTranferModelShow={isTranferModelShow}
              setIsTranferModelShow={setIsTranferModelShow}
            />
          </Box>
        </Modal>

        {/* software update model starts*/}
        <Modal
          title="Model"
          open={isSoftwareUpdateModelShow}
          // handleclose={() => this.closeTranferDevice()}
        >
          <Box
            style={style}
            className="transferOwnerShipMobile softwareUpdateWrapper"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <img src={Cross} onClick={handleCloseModal} alt=""></img>
            </div>

            <div className="softwareUpdateHeader">
              <h1 className="transferOwner">Software Update</h1>
              {!isUpdate && (
                <p>
                  Your <b>RGB Controller, My Lights,</b> is currently on{" "}
                  <b>v1.1</b> and{" "}
                  <b>
                    {"v" + softwareProductOverview[0]?.newVersion}
                    {/* updatedDeviceInfo?.softwareVersion !== ""
                      ? updatedDeviceInfo?.softwareVersion
                      : 
                      softwareProductOverview[0]?.newVersion */}
                  </b>{" "}
                  is available. We recommend you update when you can.
                </p>
              )}

              <SoftwareUpdateProductBox />
            </div>

            <span className="updateOverview">Update Overview</span>
            <p className="transferOwnerHeading">
              Paired with the Aquatic AV App, the RGB Controller offers endless
              possibilities to fulfill your color customization dreams. Use with
              Aquatic AV’s RGB illuminated speakers and subwoofers for maximum
              effect.
            </p>

            {isUpdate && <CircleProgressBar />}

            {!isUpdate ? (
              <>
                <span className="updateOverview">Release Notes</span>

                <span className="releaseVersion">
                  <i> {softwareProductOverview[0]?.newVersion} </i>
                  {softwareProductOverview[0]?.releaseNotes}
                </span>

                {/* <span className="releaseVersion">
                  <i>2.1 </i>Short description of changes
                </span>

                <span className="releaseVersion">
                  <i>2.0 </i>Short description of changes
                </span> */}
              </>
            ) : (
              <>
                <span className="updateOverview">What’s Coming</span>

                <span className="releaseVersion">
                  <ul>
                    <li>New feature</li>
                    <li>New feature</li>
                    <li>New feature</li>
                    <li>New feature</li>
                  </ul>
                </span>
              </>
            )}

            <span className="updateSoftwareFooter">
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
              {!isUpdate && (
                <Button variant="contained" onClick={handleSoftwareUpdate}>
                  Start Update
                </Button>
              )}
            </span>
          </Box>
        </Modal>

        {/* software update model end*/}

        <Modal
          title="Model"
          open={isRemoveDeviceModelShow.modalState}
          // handleclose={() => this.closeTranferDevice()}
        >
          <Box style={style} className="transferOwnerShipMobile">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <img
                src={Cross}
                onClick={() =>
                  setIsRemoveDeviceModelShow({
                    modalState: false,
                    selectedSection: "",
                  })
                }
                alt=""
                className="crossMobile"
              ></img>
            </div>
            <h1 className="transferOwner">
              Would you like to remove this device?
            </h1>
            <p className="transferOwnerHeading">
              Are you sure you want to remove this device? Remember, you can
              also transfer ownership if you’re gifting or selling this device!
            </p>
            <span
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <button
                id="pane2"
                className=""
                style={{
                  marginRight: "30px",
                  background: "#F5F8FA",
                  color: "#7E8299",
                  fontWeight: "700",
                  fontSize: "16px",
                  width: "98px",
                  height: "48px",
                }}
                onClick={() =>
                  setIsRemoveDeviceModelShow({
                    modalState: false,
                    selectedSection: "",
                  })
                }
              >
                Cancel
              </button>
              <button
                id="pane2"
                className=""
                style={{
                  marginRight: "30px",
                  background: "#EB5757",
                  color: "#fff",
                  fontWeight: "700",
                  fontSize: "16px",
                  width: "109px",
                  height: "48px",
                }}
                // () => onDeleteDevice()
                onClick={() => handleRemoveDevice()}
              >
                Remove
              </button>
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  margin: "0",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#A7A8BB",
                }}
              >
                Need help?
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#1BBED0",
                }}
              >
                Contact Aquatic AV Support
              </p>
            </span>
          </Box>
        </Modal>
        {/* )} */}
      </div>
    </div>
  );
};
export default withRouter(DetailedDeviceInfo);
