import React from "react";
import {
  Login,
  ForgotPassword,
  VerificationCode,
  PasswordRest,
  UserRegistration,
  Dashboard,
  MyAccount,
  NotFound,
  DeviceOverview,
  DealerLocator,
  Notifications,
  SpaOverview,
  WaterTreatment,
  Reporting,
  SpaSettings,
  PriceList,
  Catalog,
  CatalogProduct,
  PriceListDetail,
  ProductRegistration,
  // NewMessage,
  UpdateMessages,
  SpaSettingNew,
} from "../views";
import AddDeviceFlow from "../views/Dashboard/AddDeviceFlow/AddDeviceFlow";
// import Messages from "../views/Messages/Messages";
import EmailVerification from "../views/UserRegistration/EmailVerification";
import PhoneVerification from "../views/UserRegistration/PhoneVerification";
import DetailedDeviceInfo from "../components/common/DetailedDeviceInfo";
import UpdateConversation from "../views/UpdateConversation";
import DeviceOwnership from "../views/Dashboard/DeviceOwnership";

const routesObj = {
  Root: {
    path: "/",
    component: <Login />,
    roles: [],
    fallback: null,
  },
  Login: {
    path: "/login",
    component: <Login />,
    roles: [],
    fallback: null,
  },
  ForgotPassword: {
    path: "/forgot-password",
    component: <ForgotPassword />,
    roles: [],
    fallback: null,
  },
  EmailVerification: {
    path: "/email-verification",
    component: <EmailVerification />,
    roles: [],
    fallback: null,
  },
  PhoneVerification: {
    path: "/phone-verification",
    component: <PhoneVerification />,
    roles: [],
    fallback: null,
  },
  VerificationCode: {
    path: "/verification-code",
    component: <VerificationCode />,
    roles: [],
    fallback: null,
  },
  PasswordRest: {
    path: "/password-rest",
    component: <PasswordRest />,
    roles: [],
    fallback: null,
  },
  UserRegistration: {
    path: "/user-registration",
    component: <UserRegistration />,
    roles: [],
    fallback: null,
  },
  Dashboard: {
    path: "/dashboard",
    component: <Dashboard />,
    roles: [],
    fallback: null,
  },
  // NewMessage: {
  //   path: "/newmessage",
  //   component: <NewMessage />,
  //   roles: [],
  //   fallback: null,
  // },
  UpdateMessages: {
    path: "/messages",
    component: <UpdateMessages />,
    roles: [],
    fallback: null,
  },
  UpdateConversation: {
    path: "/conversation",
    component: <UpdateConversation />,
    roles: [],
    fallback: null,
  },
  MyAccount: {
    path: "/my-account",
    component: <MyAccount />,
    roles: [],
    fallback: null,
  },
  DeviceOverview: {
    path: "/device-overview",
    component: <DeviceOverview />,
    roles: [],
    fallback: null,
  },
  TransferOwnership: {
    path: '/ownership',
    component: <DeviceOwnership />,
    roles: [],
    fallback: null,
  },
  // SpaOverview: {
  //   path: "/spa-ownership",
  //   component: <SpaOverview />,
  //   roles: [],
  //   fallback: null,
  // },
  WaterTreatment: {
    path: "/water-treatment",
    component: <WaterTreatment />,
    roles: [],
    fallback: null,
  },
  Reporting: {
    path: "/reporting",
    component: <Reporting />,
    roles: [],
    fallback: null,
  },
  // SpaSettings: {
  //   path: "/spa-settings",
  //   component: <SpaSettings />,
  //   roles: [],
  //   fallback: null,
  // },
  // SpaSettingsNew: {
  //   path: "/spa-settings-new",
  //   component: <SpaSettingNew />,
  //   roles: [],
  //   fallback: null,
  // },
  DealerLocator: {
    path: "/dealer-locator",
    component: <DealerLocator />,
    roles: [],
    fallback: null,
  },
  Notifications: {
    path: "/notifications",
    component: <Notifications />,
    roles: [],
    fallback: null,
  },
  // Messages: {
  //   path: "/messages",
  //   component: <Messages />,
  //   roles: [],
  //   fallback: null,
  // },
  PriceList: {
    path: "/catalog/price-list",
    component: <PriceList />,
    roles: [],
    fallback: null,
  },

  PriceListDetail: {
    path: "/catalog/price-list/price-list-detail",
    component: <PriceListDetail />,
    roles: [],
    fallback: null,
  },

  Catalog: {
    path: "/catalog",
    component: <Catalog />,
    roles: [],
    fallback: null,
  },

  CatalogProduct: {
    path: "/catalog/catalog-product",
    component: <CatalogProduct />,
    roles: [],
    fallback: null,
  },

  NotFound: {
    path: "*",
    component: <NotFound />,
    roles: [],
    fallback: null,
  },

  ProductRegistration: {
    path: "/product-registration",
    component: <ProductRegistration />,
    roles: [],
    fallback: null,
  },
  AddNewDeviceFlow: {
    path: "/add-device-flow",
    component: <AddDeviceFlow />,
    roles: [],
    fallback: null,
  },
  DetailDeviceInfo: {
    path: "/deviceinfo",
    component: <DetailedDeviceInfo />,
    roles: [],
    fallback: null,
  },
};
export default routesObj;
