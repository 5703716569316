import React from 'react';

function Branches({ text, picture }) {
  return (
    <div className="branchWrapper">
      <span className="branchWrapper__branchText">{text}</span>
      <span className="branchWrapper__branchPicture">
        <img src={picture} alt={text} />
      </span>
    </div>
  );
}

export default Branches;
