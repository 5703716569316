import React from "react";
import "./FloatingLabelStyle.css";

const FloatingLabelInput = ({
  className,
  type,
  id,
  placeholder,
  name,
  autoComplete,
  value,
  onChange,
  onBlur,
  MinDate,
  disabled,
  mandatory,
  maxLength,
  label
}) => {
  return (
    <div className="inputMainWrapper">
      <label
        className="form-label"
        style={{
          fontFamily: "Roboto !important",
          fontWeight: "500 !important",
          fontSize: 15,
          color: "#3F4254 !important",
          marginBottom: "5px !important",
        }}
      >
        {placeholder}{" "}
        {mandatory ? <span style={{ color: "red" }}>*</span> : null}
      </label>
      <input
        type={type}
        min={MinDate}
        className={className}
        id={id}
        name={name}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={label}
      />
    </div>
  );
};

export default FloatingLabelInput;
