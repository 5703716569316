export const dashboardTypes = {
  DASHBOARD_LOAD_DATA: "DASHBOARD_LOAD_DATA",
  DASHBOARD_SET_ENV: "DASHBOARD_SET_ENV",
  GET_PRODUCT_TYPE: "GET_PRODUCT_TYPE",
  GET_PRODUCT: "GET_PRODUCT",
  GET_DEALER_DATA: "GET_DEALER_DATA",
  DASHBOARD_ONCHANGE: "DASHBOARD_ONCHANGE",
  SET_DEVICEINFO: "SET_DEVICEINFO",
  ADD_DEVICE_ENV: "ADD_DEVICE_ENV",
  GET_TRANSFER_DEVICE: "GET_TRANSFER_DEVICE",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
};
