import React, { Component } from "react";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import "../tabs.css";
import Devices from "./Devices";

class Global extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDevicesScreen: false,
    };
  }

  toggleDeviceScreen = () => {
    this.setState({
      isShowDevicesScreen: !this.state.isShowDevicesScreen,
    });
  };

  render() {
    // const { isPlaying, trackProgress, isLoading } = this.state;
    const { spaOverviewReducer, dashboardState, homeState } = this.props;
    // const { userInfo, userEnv } = dashboardState;
    // const { history } = this.props;
    // let userNameS = "";
    // if (userInfo) {
    //   userNameS = `${userInfo.firstName}${`'s `}`;
    // }
    if (this.state.isShowDevicesScreen) {
      return (
        <Devices
          devices={spaOverviewReducer.userDevices.length}
          toggleDeviceScreen={this.toggleDeviceScreen}
        />
      );
    } else {
      return (
        <div
          className="tab-pane fade show active"
          id="v-pills-home"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <h4>Global</h4>
          <form>
            <div className="form-group row justify-content-between">
              <label className="col-sm-3 col-form-label">Time</label>
              <div className="col-sm-5">
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Time"
                    value={spaOverviewReducer.spaDeviceDetails.purchaseDate}
                    disabled={true}
                  />

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="btn edit text-right w-75"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group row justify-content-between">
              <label className="col-sm-3 col-form-label">
                Install location
              </label>
              <div className="col-sm-5">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Install location"
                    value={
                      spaOverviewReducer.spaDeviceDetails.installationLocation
                    }
                    disabled={true}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="btn text-right edit w-75"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group row justify-content-between">
              <label className="col-sm-3 col-form-label">Your dealer</label>
              <div className="col-sm-5">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Your dealer"
                    value={spaOverviewReducer.spaSettings.spaDealerName}
                    disabled={true}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="btn text-right edit w-75"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>{" "}
                </div>
              </div>
            </div>
            <div className="form-group row justify-content-between">
              <label className="col-sm-3 col-form-label">Your service</label>
              <div className="col-sm-5">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Your Service"
                    value={spaOverviewReducer.spaSettings.spaService}
                    disabled={true}
                  />

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="btn text-right edit w-75"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group row justify-content-between">
              <label className="col-sm-3 col-form-label">Devices</label>
              <div className="col-sm-5">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Devices"
                    value={`${spaOverviewReducer.userDevices.length} devices`}
                    disabled={true}
                  />
                  <button
                    onClick={(e) => {
                      this.toggleDeviceScreen();
                      e.preventDefault();
                    }}
                    className="btn text-right edit w-75"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(Global));
