import React, { useState, useEffect, useRef, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { useLocation } from "react-router-dom";
import { TabPanel, TabContext } from "@material-ui/lab";
import moment from "moment";
import ReportingGraph from "./ReportingGraph";
import { Loader } from "../index";
import { data } from "./ReportingData";
import { spaOverviewApi } from "../../../store/SpaOverview/SpaOverviewApi";

var testDate = new Date();

export default function ReportingCard({ isActive }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const spaDeviceId = location?.state?.SpaId;
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabGraphValues, setActiveTabGraphValues] = useState(null);

  const [graphValues, setGraphValues] = useState({
    pH: [],
    Chlorine: [],
    Alkalinity: [],
  });
  const [graphLabels, setGraphLabels] = useState([
    // "1 Aug",
    // "3 Aug",
    // "5 Aug",
    // "7 Aug",
    // "9 Aug",
    // "11 Aug",
  ]);

  const [activeTab, setActiveTab] = useState({
    label: "Use History",
    icon: require(`../../../assets/images/reporting1.png`),
    activeicon: require(`../../../assets/images/reporting1active.png`),
  });
  const [loadGraph, setLoadGraph] = useState(false);
  const [graphStartDate, setGraphStartDate] = useState(
    new Date(testDate.getFullYear(), testDate.getMonth(), 1)
  );
  const [graphEndDate, setGraphEndDate] = useState(
    new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0)
  );
  const [getHistory, setHistory] = useState([]);

  const getMonth = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const graphRef = useRef(null);

  const getAllWaterLife = useCallback(async () => {
    setLoadGraph(true);
    let labels = [];
    let values = [];

    if (activeTab.label === "WaterLife") {
      const waterLifeResult = await spaOverviewApi.getWaterLifeGraphDetails({
        startDate: moment(graphStartDate).format("MM/DD/yyyy"),
        endDate: moment(graphEndDate).format("MM/DD/yyyy"),
        deviceId: spaDeviceId,
      });
      // labels = waterLifeResult?.data?.map(({ date }) => date);
      // values = waterLifeResult?.data?.map(({ waterAdded }) => waterAdded);

      labels = waterLifeResult?.data?.map(({ key, data }) => {
        let gallonsReplacedTime = 0;
        data.forEach((t) => {
          if (t.gallonsReplaced > 0) gallonsReplacedTime += t.gallonsReplaced;
        });
        values.push(gallonsReplacedTime);

        return key;
      });

      // Update the data prop for the ReportingGraph component
      setActiveTabGraphValues({
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: "#1BBED0",
            barThickness: 30,
            borderRadius: 4,
          },
        ],
      });
    } else if (activeTab.label === "FilterLife") {
      const filterLifeResult = await spaOverviewApi.getFilterLifeGraphDetails({
        startDate: moment(graphStartDate).format("MM/DD/yyyy"),
        endDate: moment(graphEndDate).format("MM/DD/yyyy"),
        deviceId: spaDeviceId,
      });
      // labels = filterLifeResult?.data?.map(({date
      // }) => date);
      // values = filterLifeResult?.data?.map(({replaced}) => replaced);

      labels = filterLifeResult?.data?.map(({ key, data }) => {
        let cleanedTime = 0;
        data.forEach((t) => {
          if (t.cleaned) cleanedTime += 1;
        });
        values.push(cleanedTime);

        return key;
      });
      // Update the data prop for the ReportingGraph component
      setActiveTabGraphValues({
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: "#2AAA8A",
            barThickness: 30,
            borderRadius: 4,
          },
        ],
      });
    } else if (activeTab.label === "Temperature") {
      const tempratureResult =
        await spaOverviewApi.getTemperatureSettingGraphDetails({
          startDate: moment(graphStartDate).format("MM/DD/yyyy"),
          endDate: moment(graphEndDate).format("MM/DD/yyyy"),
          deviceId: spaDeviceId,
        });
      labels = tempratureResult?.data?.map(({ key, data }) => {
        let heatModeTimes = 0;
        data.forEach((t) => {
          if (t.heatMode) heatModeTimes += 1;
        });
        values.push(heatModeTimes);

        return key;
      });

      // values = tempratureResult?.data?.map(({replaced}) => replaced);

      // Update the data prop for the ReportingGraph component
      setActiveTabGraphValues({
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: "#ef8c21",
            barThickness: 30,
            borderRadius: 4,
          },
        ],
      });
    } else if (activeTab.label === "Chemical") {
      onLoadGraphDetails(moment(graphStartDate).format("MM/DD/yyyy"), moment(graphEndDate).format("MM/DD/yyyy"));
    } else if (activeTab.label === "Use History") {
      const formattedStartDate = `${(graphStartDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${graphStartDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${graphStartDate.getFullYear()}`;
      const formattedEndDate = `${(graphEndDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${graphEndDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${graphEndDate.getFullYear()}`;
      const fields = {
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
        deviceId: spaDeviceId,
      };
      const historyResult = await spaOverviewApi.getDeviceUseHistory(fields);
      labels = historyResult?.data?.map(
        (item) => `${getMonthName(item.month)} ${item.year}`
      );
      values = historyResult?.data?.map((item) => item.totalMinutes);
      //   // Update the data prop for the ReportingGraph component
      setActiveTabGraphValues({
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: "#FF4500",
            barThickness: 30,
            borderRadius: 4,
          },
        ],
      });
    }
    setLoadGraph(false);
  }, [spaDeviceId, activeTab.label, graphStartDate, graphEndDate]);


  const sorting = (a, b) => {
    let date1 = new Date();
    let date2 = new Date();

    let day1 = a?.day;
    let month1 = a?.month;
    let year1 = a?.year;

    let day2 = b?.day;
    let month2 = b?.month;
    let year2 = b?.year;

    let firstDate = date1.setFullYear(year1, month1 - 1, day1);
    let lastDate = date2.setFullYear(year2, month2 - 1, day2);
    return firstDate - lastDate;
  };

  const onLoadGraphDetails = (firstDate = false, lastDate = false) => {
    let testArray = [];
    let graphLabelsCopy = [];
    let graphValues = {
      pH: [],
      Chlorine: [],
      Alkalinity: [],
    };

    setIsLoading(true);
    const fields = {
      startDate: firstDate ? firstDate : graphStartDate,
      endDate: lastDate ? lastDate : graphEndDate,
      deviceId: spaDeviceId,
    };

    console.log("fileds:", fields);

    dispatch(
      SpaOverviewAction.onGetCurrentSpaChemicalTestigGraphDetails(fields)
    ).then((data) => {
      console.log("api graph response:", data);
      setIsLoading(false);
      const lastIndex = data?.data?.length - 1;

      if (data?.data !== null) {
        setLoadGraph(true);
        data?.data?.forEach((res, i) => {
          testArray.push(...res.data);

          if (lastIndex === i) {
            console.log("final data set:", graphValues);
            console.log("final test array:", testArray);

            let finalIndex = testArray.length - 1;

            testArray.sort(sorting)?.forEach((item, index) => {
              let items = `${item?.day} ${getMonth[item?.month]}`;
              graphLabelsCopy.push(items);
              setGraphLabels(graphLabelsCopy);

              // For graph datasets
              graphValues.pH.push(item.ph);
              graphValues.Alkalinity.push(item.alkalinity);
              graphValues.Chlorine.push(item.chlorine);

              setGraphValues(graphValues);

              if (finalIndex === index) {
                setTimeout(() => {
                  setLoadGraph(false);
                }, 2000);
              }
            });
          }
        });
      } else {
        setGraphValues({
          pH: [],
          Chlorine: [],
          Alkalinity: [],
        });
        setGraphLabels([]);
        setLoadGraph(false);
      }
    });
  };
  

  const onSetDate = (dates, setViewCalender) => {
    console.log("date range:", dates);
    setGraphStartDate(dates[0]?.startDate);
    setGraphEndDate(dates[0]?.endDate);
    setViewCalender(false);
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    const formatter = new Intl.DateTimeFormat("en-us", {
      month: "short",
    });

    return formatter.format(date);
  };

  const extractGraphDataSet = (activeTab, data) => {
    // Define variables to store the data you want to display
    let labels = [];
    let values = [];

    if (activeTab.label === "Temperature") {
      // Extract temperature data
      const temperatureData = data.data?.temprature || getHistory?.temprature;
      labels = temperatureData?.map((item) => item.testDate);
      values = temperatureData?.map((item) => item.totalTimes);
    } else if (activeTab.label === "WaterLife") {
      // const result = await getAllWaterLife();
      // console.log('WaterLife result : ', result)
      // Extract water life data
      const waterLifeData = data.data?.waterLife || getHistory?.waterLife;
      labels = waterLifeData?.map((item) => item.testDate);
      values = waterLifeData?.map((item) => item.ttoalTest);
    } else if (activeTab.label === "FilterLife") {
      // Extract filter life data
      const filterLifeData = data.data?.filterLife || getHistory?.filterLife;
      labels = filterLifeData?.map((item) => item.testDate);
      values = filterLifeData?.map((item) => item.totaTest);
    } else if (activeTab.label === "Chemical") {
      const selectedChemicalType = "pH"; // Change this to the desired chemical type
      const chemicalData =
        data.data?.chemical?.["ph"] ||
        getHistory?.chemical[selectedChemicalType];
      labels = chemicalData?.map((item) => item.testMonth);
      values = chemicalData?.map((item) => item.averagePH); // You can change this to other chemical properties

      // You can switch between chemical types by changing the value of selectedChemicalType
      // For example, selectedChemicalType = "chlorine" or "alkalinity"
    } else if (activeTab.label === "Use History") {
      // Extract filter life data
      const filterLifeData = getHistory || [];
      labels = filterLifeData?.map(
        (item) => `${getMonthName(item.month)} ${item.year}`
      );
      values = filterLifeData?.map((item) => item.totalMinutes);
    }

    console.log("Active Tab Data:", {
      labels,
      values,
    });

    // Update the data prop for the ReportingGraph component
    const graphDataSet = {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: "#1BBED0",
          barThickness: 30,
          borderRadius: 4,
        },
      ],
    };

    return graphDataSet;
  };

  useEffect(() => {
    getAllWaterLife();
  }, [getAllWaterLife, activeTab.label, graphEndDate, graphStartDate]);

  return (
    <Grid
      item
      md={12}
      className='gridMainSpaWrapper px-3'
      style={{ marginTop: "1rem", backgroundColor: "#fff", borderRadius: 12 }}
    >
      {isLoading ? <Loader /> : null}
      <Grid item md={12}>
        <Grid
          item
          md={12}
          className='gridSpaPumpsWrapperBody detailBody'
          style={{ padding: "20px ", marginTop: "40px" }}
        >
          <div className='gridReportingWrapper'>
            <div square>
              <TabContext value={"usageHistory"}>
                <TabPanel value='usageHistory' className='reportContent'>
                  {loadGraph ? (
                    <Loader />
                  ) : (
                    <ReportingGraph
                      graphRef={graphRef}
                      title={activeTab.label}
                      data={
                        activeTabGraphValues ||
                        extractGraphDataSet(activeTab, data)
                      }
                      // data={GraphDataSet}
                      dates={{
                        to: moment(graphEndDate).format("MMMM D, YYYY"),
                        from: moment(graphStartDate).format("MMMM D, YYYY"),
                      }}
                      active={activeTab}
                      setActive={setActiveTab}
                      onSetDate={onSetDate}
                      chGraphLabels={graphLabels}
                      chGraphValues={graphValues}
                    />
                  )}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
