import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getDealersSuggestion(
  search,
  sourcelat,
  sourcelong,
  dealertype,
  environments,
  distance
) {
  let data = JSON.stringify({
    input: search,
    sourcelat: sourcelat,
    sourcelong: sourcelong,
    dealertype: dealertype,
    environments: environments,
    distance: distance,
  });
  let config = {
    method: "post",
    url: endpoints.dealerLocator.getDealersSuggestion,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
async function getDealersFilter() {
  return axios
    .post(endpoints.dealerLocator.dealersFilter, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

async function getAutoCompleteSuggestion(text) {
  return axios
    .post(
      endpoints.dealerLocator.getDealersAutoComplete,
      JSON.stringify({
        input: text,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
async function getAuthorizedDealers() {
  return axios
    .post(endpoints.dealerLocator.getAuthorizedDealers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
async function getDNBLDealers() {
  return axios
    .post(endpoints.dealerLocator.getDNBLDealers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export const dealerLocatorApi = {
  getDealersSuggestion,
  getDealersFilter,
  getAutoCompleteSuggestion,
  getAuthorizedDealers,
  getDNBLDealers,
};
