import React, { useEffect } from "react";

const DirectionsRenderer = ({ directions }) => {
  console.log(directions, "directions");
  useEffect(() => {
    const google = window.google;
    const directionsDisplay = new google.maps.DirectionsRenderer({
      map: directions,
      //   suppressMarkers: true, // Suppress the default markers
      polylineOptions: {
        strokeColor: "#ff0000",
      },
    });

    // const mapOptions = {
    //   zoom: 12,
    //   center: { lat: 41.85, lng: -87.65 },
    // };

    const map = new google.maps.Map(
      document.getElementById("koib")
      // mapOptions
    );
    directionsDisplay.setMap(map);

    if (directions) {
      directionsDisplay.setDirections(directions);
    }

    return () => {
      directionsDisplay.setMap(null);
    };
  }, [directions]);

  return <div id="koib" style={{ height: "740px", strokeColor: "#ff0000" }} />;
};

export default DirectionsRenderer;
