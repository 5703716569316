import reducers from "./combineReducer";
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";

/** developemnt store starts */
// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : null || compose;

// const store = createStore(
//   reducers,
//   {},
//   composeEnhancers(applyMiddleware(ReduxThunk))
// );
/** developemnt store ends*/

/** deployment store starts */
const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
/** deployment store ends*/
export default store;
