import React, { useEffect, useState } from "react";
import { Header, Loader } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import RenameIcon from "../../../resources/images/renametick.png";
import { BsChevronLeft } from "react-icons/bs";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import editYellow from "../../../assets/images/editYellow.png";
import EnironmentModal from "./EnironmentModal";

import "./deviceOwnership.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import routesObj from "../../../routes/RoutesObj";
import Cookies from "js-cookie";

const DeviceOwnership = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deviceId = queryParams.get("deviceId");
  const email = queryParams.get("email");

  const queryValue = `?deviceId=${deviceId}&email=${email}`;

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [isLoading, setIsLoading] = useState(false);
  const [transferDeviceInfo, seTransferDeviceInfo] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isDeviceOwnerShip, setIsDeviceOwnerShip] = useState(true);

  const [newDeviceName, setNewDeviceName] = useState("");

  useEffect(() => {
    onGetDeviceOverviewInfo();
  }, []);


  useEffect(() => {
    if(userInfo){
      if (location?.pathname === "/ownership" && email !== userInfo?.email) {
        localStorage.setItem("transferedDeviceFlag", queryValue);
        onLogout();
      }
    }
  }, []);

  // zzxx@mailinator.com
  // zainabc@mailinator.com

  const onLogout = () => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("password");
    localStorage.removeItem("passwordDec");
    localStorage.removeItem("currentEnv");
    localStorage.removeItem("access_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("access_token_expiry");
    localStorage.removeItem("userId");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("forgotToken");
    Cookies.remove("access_token");

    history.push(routesObj.Dashboard.path);
  };

  const onGetDeviceOverviewInfo = () => {
    setIsLoading(true);
    dispatch(dashboardActions.onGetDeviceOverviewInfo(deviceId))
      .then((res) => {
        seTransferDeviceInfo(res?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "error");
      });
  };

  const updateDeviceName = (updateHeading, device) => {
    setIsLoading(true);
    dispatch(dashboardActions.onUpdateDevName(updateHeading, device.id))
      .then((data) => {
        dispatch(dashboardActions.onGetDeviceOverviewInfo(deviceId))
          .then((res) => {
            seTransferDeviceInfo(res?.data?.data);
            setIsLoading(false);
            setIsEditing(false);
            // onLoadUserData();
          })
          .catch((error) => {
            console.log(error, "error");
            setIsLoading(false);
          });
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again");
        setIsEditing(false);
        setIsLoading(false);
      });
  };

  const handleEditIconClick = () => {
    setIsEditing(true);
  };
  const handleRedirection = () => {
    history.push(routesObj.Dashboard.path);
  };

  return (
    <div className="Dashboardmain-container">
      {isLoading && <Loader />}
      <div className="Dashboardmain-container__header transferDeviceOwnershipCantainer">
        <Header
          userInformation={userInfo ? userInfo : null}
          isLoading={isLoading}
        />

        <div className="deviceOverviewContainer transferDeviceOwnershipDetail">
          <div className="tabs-container">
            <div className="tabSection ownershipWrapper" >
              <h6
                className="transferDeviceHeader"
                // onClick={() => handleTabClick("Device Overview")}
                style={{ marginRight: "7%" }}
              >
                Transfered Device Overview
              </h6>
              <button onClick={handleRedirection}><BsChevronLeft /> Back to Home Page</button>
            </div>

            <div className="transferDeviceOwnershipImageContainer">
              <Container fluid className="p-0">
                <Row  className="p-0">
                     <div className="col-lg-3">
                     <div className="detailDeviceOverMobileImg ownershipImage">
                <img src={transferDeviceInfo?.image} alt="productImage"></img>
                    </div>
                     </div>

                     <div className="col-lg-9">
                     <div className="ownershipDetails">
                <div>
                  <div
                    className="deviceDetailHeadingMain ownershipHeading"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      {isEditing ? (
                        <div style={{ width: "100%" }}>
                          <input
                            type="text"
                            value={newDeviceName}
                            onChange={(e) => setNewDeviceName(e.target.value)}
                            className="updateName"
                          />

                          {isEditing && (
                            <img
                              src={RenameIcon}
                              onClick={() =>
                                updateDeviceName(
                                  newDeviceName,
                                  transferDeviceInfo
                                )
                              }
                              className="updatenamebtn"
                              alt="productImage"
                            />
                          )}
                        </div>
                      ) : (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Tooltip title={transferDeviceInfo?.name}>
                              <h1>
                                {transferDeviceInfo?.name?.length > 25
                                  ? `${transferDeviceInfo?.name?.slice(
                                      0,
                                      20
                                    )}...`
                                  : transferDeviceInfo?.name}
                              </h1>
                            </Tooltip>
                            <img
                              src={editYellow}
                              style={{
                                marginLeft: "20px",
                                cursor: "pointer",
                              }}
                              onClick={handleEditIconClick}
                              alt="productImage"
                            />
                          </div>
                          <h2>{transferDeviceInfo?.product}</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Container className="p-0">
                  <Row>
                
                <div className="col-lg-6">
                <div className="ownershipRow">
                  <h1 className="deviceDetailPoints">SKU</h1>
                  <h1 className="devicePin">{transferDeviceInfo?.sku}</h1>
                </div>
                <div className="ownershipRow">
                  <h1 className="deviceDetailPoints">Serial Number</h1>
                  <h1 className="devicePin">{transferDeviceInfo?.serialNo}</h1>
                </div>
                <div className="ownershipRow">
                  <h1 className="deviceDetailPoints">Environment</h1>
                  <h1 className="devicePin">
                    {transferDeviceInfo?.environmentName}
                  </h1>
                </div>
                <div className="ownershipRow">
                  <h1 className="deviceDetailPoints">Last Connected</h1>
                  <h1 className="devicePin">
                    {transferDeviceInfo?.lastConnected}
                  </h1>
                </div>
                </div>

                <div className="col-lg-6">
                   <div className="ownershipRow">
                  <h1 className="deviceDetailPoints">
                    Software/Firmware Version
                  </h1>
                  <h1 className="devicePin">
                    {transferDeviceInfo?.softwareVersion}
                  </h1>

                  <span
                    className="softwareUpdateBtn"
                    // onClick={handleCheckForUpdates}
                  ></span>
                </div>
                <div className="ownershipRow">
                  <h1 className="deviceDetailPoints">Warranty Expiration</h1>
                  <h1 className="devicePin">
                    {transferDeviceInfo?.expiryDate}
                  </h1>
                </div>
                <div className="ownershipRow">
                  <h1 className="deviceDetailPoints">Warranty Remaining</h1>
                  <h1 className="devicePin">
                    {transferDeviceInfo?.remainingDays}
                  </h1>
                </div>
                
                </div>
                </Row>
               </Container>
                <div className="deviceNotesWrapper">
                  <h1 className="deviceDetailPoints">Notes</h1>
                 {transferDeviceInfo?.notes && <div className="devicePin notesText">
                    {transferDeviceInfo?.notes}
                  </div>}
                </div>
                      </div>
                      </div>
                </Row>
              </Container>
             
             
            </div>
          </div>
        </div>
      </div>
      {isDeviceOwnerShip && (
        <EnironmentModal
          transferDeviceInfo={transferDeviceInfo}
          isDeviceOwnerShip={isDeviceOwnerShip}
          setIsDeviceOwnerShip={setIsDeviceOwnerShip}
          onGetDeviceOverviewInfo={onGetDeviceOverviewInfo}
        />
      )}
    </div>
  );
};

export default DeviceOwnership;
