import React from 'react';
import locatorClose from '../../resources/images/locatorclose.svg';

function DealerLocatorModals({ handleclose, children,markerModal,findDealer }) {
  return (
    <div className="overlay">
       {markerModal &&(
      <div className="overlay__modalWrapper_locator">
        <span
          className="overlay__modalWrapper__modalTiele--close_locator"
          onClick={handleclose}
        >
          <img src={locatorClose} alt="" />
        </span>
        <span className="overlay__modalWrapper__modalContent_locator">{children}</span>
      </div>
        )}
      {findDealer &&(
        <div className="overlay__modalWrapper_find_dealer">
        <span
          className="overlay__modalWrapper__modalTiele--close_locator"
          onClick={handleclose}
        >
          <img src={locatorClose} alt="" />
        </span>
        <span className="overlay__modalWrapper__modalContent_find_dealer">{children}</span>
      </div>
      )}
    </div>
  );
}

export default DealerLocatorModals;
