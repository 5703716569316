import { combineReducers } from "redux";
import AccountReducer from "./accounts/accountReducer";
import DashboardReducer from "./dashboard/dashboardReducer";
import DeviceOverviewReducer from "./deviceOverview/deviceOverviewReducer";
import SpaOverviewReducer from "./SpaOverview/SpaOverviewReducer";
import DealerLocatorReducer from "./dealerLocator/dealerLocatorReducer";
import PriceListReducer from "./PriceList/PriceListReducer";
import conversationReducer from "./conversations/conversationReducer";

export default combineReducers({
  AccountReducer: AccountReducer,
  DashboardReducer: DashboardReducer,
  DeviceOverviewReducer: DeviceOverviewReducer,
  SpaOverviewReducer: SpaOverviewReducer,
  PriceListReducer: PriceListReducer,
  DealerLocatorReducer: DealerLocatorReducer,
  conversationReducer,
});
