import React, { useEffect, useState } from "react";
import {
  TempratureIcon1,
  TempratureIcon2,
  TempratureIcon3,
  FilterIcon,
  FilterIcon2,
} from "./SvgIcons";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { Bulb1, music, rainbow, spaVector } from "../../../resources/images";
import "./overview.css";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import CrossBox from "../../../resources/images/CrossBox.png";
import { toast } from "react-toastify";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 6,
  outline: "none",

  // width: "20%",
  // height: "326.91px",
  padding: "32px 48px 28px 48px",
  borderRadius: "20px",
  overflowY: "scroll",
};

export default function OverViewCard({
  gateways,
  isWaterTreatment,
}) {

  const {
    spaDeviceDetails,
    spaTemperatureSettings,
    spaFilterSettings,
    spaSettings,
  } = useSelector((state) => state.SpaOverviewReducer);

  const [openGatewayModal, setOpenGatewayModal] = useState(false);
  const [mqttDeviceName, setMqttDeviceName] = useState("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   getOwnedSpaDeviceInfo();
  // }, []);

  // const getOwnedSpaDeviceInfo = () => {
  //   dispatch(
  //     SpaOverviewAction.onGetSpaInfo(getOwnSpaid ? getOwnSpaid : spaDeviceId)
  //   ).then((res) => {
  //     if (res.success) {
  //     }
  //   });
  // };

  const tempConversion = (temprature) => {
    let tempratureScale =
      spaTemperatureSettings?.temperatureControl === 1 ? "F" : "C";
    if (tempratureScale === "C" && temprature !== 0) {
      let celsius = ((temprature - 32) * 5) / 9;
      return celsius?.toFixed(2);
    } else {
      return temprature;
    }
  };

  const onToggleModal = () => {
    setOpenGatewayModal(!openGatewayModal);
  };

  const onUpdateMqttDeviceName = (e) => {
    setMqttDeviceName(e.target.value);
  };

  const onConnectMqtt = () => {
    if (mqttDeviceName == "") {
      toast.error("device name is required!");
      return;
    }

    dispatch(SpaOverviewAction.onSetSpaDeviceName(mqttDeviceName)).then(
      (data) => {
        if (data) {
          onToggleModal();
          toast.success("device name updated successfully!");
          setMqttDeviceName("");
        } else {
          toast.error("something went wrong!");
        }
      }
    );
  };
  // console.log("ownSpaData???", spaOverviewReducer);
  return (
    <Grid item md={12} className="gridMainSpaWrapper px-3">
      <Grid
        item
        md={12}
        className="gridSpaOverviewWrapper"
        // style={{ height: isWaterTreatment ? 450 : 565 }}
      >
        {/* <Grid item md={12} className="gridSpaOverviewWrapperHeader">
          <Grid item md={12} className="gridRow">
            <Grid item md={12} className="gridSpaOverviewHeading">
              <h4 style={{ color: "#202A4C" }}>
                {ownSpaData?.spaDeviceDetails?.deviceName}
              </h4>

              <p className="modelname">
                {spaOverviewReducer?.spaSettings?.spaControllerModel ||
                  "SPA Model"}
                , {spaOverviewReducer?.spaSettings?.spaBrand || "Brand"}{" "}
              </p>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item md={12} className="gridSpaOverviewWrapperBody">
          <Grid item md={12} className="gridRow">
            <Grid
              item
              md={12}
              className="gridParentWidget"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid item md={4}>
                <img
                  style={{ height: 110, width: 110, objectFit: "contain" }}
                  src={spaVector}
                  alt="spa ownership"
                />
              </Grid>

              <Grid item md={7} style={{ marginTop: 15, marginLeft: 15 }}>
                <h4 className="cardTitle">{spaDeviceDetails?.deviceName}</h4>
                <p className="cardBrand" style={{ margin: "0px" }}>
                  {spaSettings?.spaModel}, {spaSettings?.spaBrand}{" "}
                </p>
                <p className="cardBrand" style={{ margin: "0px" }}>
                  {spaDeviceDetails?.installationLocation}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} className="gridRow">
            <Grid item md={11} className="gridInfoSection">
              <Grid md={12} style={{ marginTop: "8px" }}>
                <div className="spaStatus">
                  <span
                    className={`spa-${
                      spaTemperatureSettings?.heatMode || "Heating"
                    }`}
                  >
                    {spaTemperatureSettings?.heatMode || "Heating"}
                  </span>

                  <span className="spa-gateway">
                    {spaTemperatureSettings?.gateWay || "NO GATEWAY"}
                  </span>
                </div>
                <div
                  style={{ marginBottom: 5 }}
                  className="temperatureTextStyle"
                >
                  {tempConversion(
                    spaTemperatureSettings?.currentTemperature || 0
                  )}
                  &deg;
                  {spaTemperatureSettings?.temperatureControl === 1
                    ? "F"
                    : "C" || "F"}
                </div>
                <p className="currentTemp">Current Water Temperature</p>
              </Grid>
            </Grid>
            <Grid item md={1} className="gridInfoSectionActions">
              <Grid
                item
                md={12}
                className="gridRow tempratureInfo"
                style={{ marginTop: "35px" }}
              >
                <Grid md={12} style={{ marginBottom: "4px" }}>
                  {spaFilterSettings?.filter1On === true ? (
                    <TempratureIcon1 />
                  ) : spaFilterSettings?.filter2On === true ? (
                    <FilterIcon2 />
                  ) : (
                    <FilterIcon />
                  )}
                </Grid>
                <Grid md={12} style={{ marginBottom: "4px" }}>
                  <TempratureIcon2 />
                </Grid>
                <Grid md={12}>
                  {spaTemperatureSettings?.isTemperatureLock ? (
                    <TempratureIcon3 />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="22"
                      viewBox="0 0 24 33"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.82977 2.28819C7.2996 1.05858 9.09706 0.28587 11.0007 0.0652842C12.9043 -0.155302 14.8308 0.185876 16.5429 1.04677C18.2549 1.90767 19.6777 3.25067 20.6359 4.91026C20.912 5.38856 20.7482 6.00015 20.2699 6.27629C19.7916 6.55243 19.18 6.38856 18.9038 5.91026C18.1408 4.58865 17.0078 3.51917 15.6444 2.83359C14.281 2.14802 12.7468 1.87633 11.2309 2.05199C9.71496 2.22765 8.28355 2.843 7.11306 3.82219C5.94258 4.80139 5.08415 6.10164 4.64357 7.56272C4.4214 8.29951 4.31038 9.06096 4.31057 9.82245H21.1538C22.2584 9.82245 23.1538 10.7179 23.1538 11.8225V30.9763C23.1538 32.0809 22.2584 32.9763 21.1538 32.9763H2C0.895428 32.9763 0 32.0809 0 30.9763V11.8225C0 10.7179 0.895431 9.82245 2 9.82245H2.31057C2.31038 8.86639 2.44979 7.91037 2.72873 6.98532C3.28198 5.15058 4.35994 3.5178 5.82977 2.28819ZM14.2823 21.3736C14.2823 22.5055 13.5936 23.4766 12.6124 23.8907V27.4998H10.5039V23.8965C9.5154 23.4856 8.82031 22.5108 8.82031 21.3736C8.82031 19.8653 10.043 18.6426 11.5513 18.6426C13.0596 18.6426 14.2823 19.8653 14.2823 21.3736Z"
                        fill="#4CA5C1"
                      />
                    </svg>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div>
            {isWaterTreatment ? null : (
              <Grid item md={12} className="gridRow">
                <Grid
                  md={12}
                  className="gridDealerInfoSection"
                  style={{
                    borderBottom: "1px dashed #E4E6EF",
                    paddingBottom: 10,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid md={6}>
                    <span
                      style={{ color: "#5E6278", fontWeight: "500" }}
                      className="dealerName"
                    >
                      Last Connected:{" "}
                    </span>
                  </Grid>

                  <Grid
                    md={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <span
                      className="dealerLabel"
                      style={{ fontStyle: "italic" }}
                    >
                      10 minutes ago
                    </span>
                    {/* <span className="dealerChange">Change</span> */}
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item md={12} className="gridRow">
              <Grid
                md={12}
                className="gridDealerInfoSection"
                style={{
                  borderBottom: "1px dashed #E4E6EF",
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid md={6}>
                  <span
                    style={{ color: "#5E6278", fontWeight: "500" }}
                    className="dealerName"
                  >
                    Your Dealer:{" "}
                  </span>
                </Grid>
                <Grid
                  md={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <span className="dealerLabel">
                    {spaSettings?.dealer || "BOB's dealer"}
                  </span>
                  {/* <span className="dealerChange">Change</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} className="gridRow ">
              <Grid
                md={12}
                className="gridDealerInfoSection"
                style={{
                  borderBottom: "1px dashed #E4E6EF",
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid md={6}>
                  <span
                    style={{ color: "#5E6278", fontWeight: "500" }}
                    className="dealerName"
                  >
                    Your Service:{" "}
                  </span>
                </Grid>
                <Grid
                  md={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <span className="dealerLabel">
                    {spaSettings?.spaService || "BOB's service"}
                  </span>
                  {/* <span className="dealerChange">Change</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} className="gridRow ">
              <Grid
                md={12}
                className="gridDealerInfoSection"
                style={{
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid md={6}>
                  {gateways?.length > 0 ? (
                    gateways.map((item, index) => {
                      <span key={index} className="dealerLabel-grey">
                        {item}
                      </span>;
                    })
                  ) : (
                    <span
                      style={{ color: "#5E6278", fontWeight: "500" }}
                      className="dealerName"
                    >
                      Gateway
                    </span>
                  )}
                </Grid>
                <Grid
                  md={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <span onClick={onToggleModal} className="dealerChange">
                    ADD
                  </span>
                </Grid>
              </Grid>
            </Grid>

            {/* {isWaterTreatment ? null : (
              <Grid item md={12} className="gridRow ">
                <Grid
                  md={12}
                  className="gridDealerInfoSection"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    md={7}
                    style={{ borderRight: "1px solid #E4E6EF" }}
                    className="playerMainBox"
                  >
                    <img className="musicImage" src={music} />
                    <div className="playerTextBox">
                      <span className="nowPlayingText">Now playing</span>
                      <span className="playerName">
                        As It Was - Harry Styles
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    md={5}
                    style={{ marginLeft: 5 }}
                    className="playerMainBox"
                  >
                    <img className="musicImage" src={Bulb1} />
                    <div className="playerTextBox">
                      <span className="nowPlayingText">Lights</span>
                      <span className="playerName">Party</span>
                    </div>
                    <img className="rainbowImage" src={rainbow} />
                  </Grid>
                </Grid>
              </Grid>
            )} */}
          </div>
        </Grid>
      </Grid>

      <Modal
        open={openGatewayModal}
        onClose={onToggleModal}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0) !important",
        }}
      >
        <Box sx={style} style={{ width: "30%", height: "271px" }}>
          <div className="CrossSection" style={{ cursor: "pointer" }}>
            <img src={CrossBox} alt="" onClick={onToggleModal} />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Please Enter the Device</span>
            <input
              style={{ padding: 10, marginTop: 10 }}
              placeholder="Device Name"
              value={mqttDeviceName}
              onChange={(e) => onUpdateMqttDeviceName(e)}
            />
            <button
              onClick={onConnectMqtt}
              style={{ marginTop: 20 }}
              className="btn-success "
            >
              Connect
            </button>
          </div>
        </Box>
      </Modal>
    </Grid>
  );
}

// BW832F_AC2C
