import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

const DataLoader = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: "center" , mt:5}}>
            <CircularProgress />
        </Box>
    )
}

export default DataLoader
