import React from "react";
import productPic from "../../resources/images/product.png"
import bluetooth from "../../resources/images/bluetooth.png"
import { Button } from "@material-ui/core";
import BluetoothIcon from '@material-ui/icons/Bluetooth';

function SoftwareUpdateProductBox(props) {
  return (
    <div className="softwareUpdateProdWrapper">
      <span className="productDetail">
        <h2>Airbnb Spa Lights</h2>
        Airbnb Spa
        <span className="productName">RGB Controller </span>
      </span>

      <span className="productPic">
        <img src={productPic} alt="product name"/>
      </span>

      <span className="bluetoothBtn">
        <Button startIcon={<BluetoothIcon />} size="small">Connected</Button>
      </span>
    </div>
  );
}

export default SoftwareUpdateProductBox;
