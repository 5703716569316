import React from "react";
import { useHistory } from "react-router-dom";
const NotFound = (props) => {
  const history = useHistory();

  const backToHomePage = () => {
    history.push("/dashboard");
  };
  return (
    <div className="main-container notFoundWrapper d-flex flex-column justify-content-center align-items-center">
      <div className="pageNotFound">
        <span>&#9785;</span> Page not found.
      </div>
      <div>
        <br />
        <button onClick={backToHomePage}>Back To Home Page</button>
      </div>
    </div>
  );
};

export default NotFound;
