import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const PhScaleSlider = withStyles({
  root: {
    color: "transparent",

    borderRadius: 5,
    padding: "4px 4px !important",
    background:
      "linear-gradient(-90deg, #FFC700 0%, #FF9C28 53.65%, #FF0000 100%)",
  },
  thumb: {
    height: 5,
    width: 5,
    backgroundColor: "#3A7D9E",
    border: "1px solid transparent",
    marginTop: -12,
    marginLeft: 20,
  },
  valueLabel: {
    display: "none",
  },
  track: {
    boxSizing: "border-box",
    border: "none",
    backgroundColor: "transparent",
    "&:after": {
      content: "",
      position: "absolute",
      display: "block",
      width: "calc(100% + 10px)",
      transform: "translateX(-5px)",
    },
  },
  rail: {
    color: "transparent",
    transform: "scaleX(1.05)",
    top: "initial",
  },
  mark: {
    color: "transparent",
    background: "transparent",
  },
})(Slider);

const ChlorineScaleSlider = withStyles({
  root: {
    color: "transparent",

    borderRadius: 5,
    padding: "4px 4px !important",
    background: "linear-gradient(-90deg, #00A3FF 0%, #F6D14E 87.5%);",
  },
  thumb: {
    height: 5,
    width: 5,
    backgroundColor: "#3A7D9E",
    border: "1px solid transparent",
    marginTop: -12,
    marginLeft: 20,
  },
  valueLabel: {
    display: "none",
  },
  track: {
    boxSizing: "border-box",
    border: "none",
    backgroundColor: "transparent",
    "&:after": {
      content: "",
      position: "absolute",
      display: "block",
      width: "calc(100% + 10px)",
      transform: "translateX(-5px)",
    },
  },
  rail: {
    color: "transparent",
    transform: "scaleX(1.05)",
    top: "initial",
  },
  mark: {
    color: "transparent",
    background: "transparent",
  },
})(Slider);

const AlkalineScaleSlider = withStyles({
  root: {
    color: "transparent",

    borderRadius: 5,
    padding: "4px 4px !important",
    background:
      "linear-gradient(-90deg, #C233D9 0%, #EE9FBE 48.96%, #FFF6C7 100%);",
  },
  thumb: {
    height: 5,
    width: 5,
    backgroundColor: "#3A7D9E",
    border: "1px solid transparent",
    marginTop: -12,
    marginLeft: 20,
  },
  valueLabel: {
    display: "none",
  },
  track: {
    boxSizing: "border-box",
    border: "none",
    backgroundColor: "transparent",
    "&:after": {
      content: "",
      position: "absolute",
      display: "block",
      width: "calc(100% + 10px)",
      transform: "translateX(-5px)",
    },
  },
  rail: {
    color: "transparent",
    transform: "scaleX(1.05)",
    top: "initial",
  },
  mark: {
    color: "transparent",
    background: "transparent",
  },
})(Slider);

const PHSlider = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: 250,
      }}
    >
      <Typography
        id="vertical-slider"
        className="slider-label"
        style={{ marginRight: 10 }}
      >
        PH
      </Typography>
      <PhScaleSlider
        orientation="horizontal"
        marks
        defaultValue={30}
        aria-labelledby="non-linear-slider"
        // className="vertical-custom-Slider"
        {...props}
        value={value}
        disabled
      />
    </div>
  );
};

const ChlorineSlider = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: 250,
      }}
    >
      <Typography
        id="vertical-slider"
        className="slider-label"
        style={{ marginRight: 10 }}
      >
        CL
      </Typography>
      <ChlorineScaleSlider
        orientation="horizontal"
        marks
        defaultValue={30}
        aria-labelledby="non-linear-slider"
        // className="vertical-custom-Slider"
        {...props}
        value={value}
      />
    </div>
  );
};
const AlkalineSlider = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: 250,
      }}
    >
      <Typography
        id="vertical-slider"
        className="slider-label"
        style={{ marginRight: 10 }}
      >
        ALK
      </Typography>
      <AlkalineScaleSlider
        orientation="horizontal"
        marks
        defaultValue={30}
        aria-labelledby="non-linear-slider"
        // className="vertical-custom-Slider"
        {...props}
        value={value}
      />
    </div>
  );
};

export { PHSlider, ChlorineSlider, AlkalineSlider };
