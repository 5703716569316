// const rootURL = 'https://aavwebapi.azurewebsites.net'; old one..
// const rootURL = "https://aavsecureapi.azurewebsites.net";

// const rootURL2 = "https://ofishstagingapiv2.azurewebsites.net"; //development Chat url
const rootURL2 = "https://ofishproductionapi.azurewebsites.net";
// const rootURL2 = "https://ofishstagingapi.azurewebsites.net"; //UAT Chat url

const rootURL = "https://aavsecureapistagging.azurewebsites.net";
// const rootURL = "https://f539-202-69-55-234.ngrok.io";

//For Dev
// const rootURL3 = "https://aavofishdevapi.azurewebsites.net";
//For Production
const rootURL3 = "https://ofishproductionapi.azurewebsites.net";

// const chatURL = "https://aquaticavchatdev.azurewebsites.net/api"; //development Chat url
const chatURL = "https://aquaticavchat.azurewebsites.net/api"; //UAT Chat url

export const AAVNewsroomURL = "https://aavofishdev.azurewebsites.net/"; //development Chat url
// export const AAVNewsroomURL = "https://aavofishstaging.azurewebsites.net/"; //UAT Chat url

export const endpoints = {
  accounts: {
    login_old: `${rootURL}/userinfoV4`,
    loginold: `${rootURL3}/api/Accounts/login_mb`,
    login: `${rootURL3}/api/Accounts/login_mb`,
    forgot_old: `${rootURL}/reset?`,
    forgotold: `${rootURL3}/api/Accounts/SendPasswordResetLink?`,
    forgot: `${rootURL3}/api/Accounts/SendPasswordResetLink?`,
    verification: `${rootURL}/`,
    resetPassword_old: `${rootURL}/updatepinfo`,
    resetPasswordold: `${rootURL3}/api/Accounts/ResetPassword`,
    resetPassword: `${rootURL3}/api/Accounts/ResetPassword`,
    addUserAndDevice: `${rootURL}/adduseranddeviceblobv2a`,
    newUserRegister_old: `${rootURL}/api/User/AddUpdateUsers`,
    newUserRegisterold: `${rootURL3}/api/Accounts/register_mb`,
    newUserRegister: `${rootURL3}/api/Accounts/register_mb`,
    // verifyUser: `${rootURL}/verifyemail?user_email=`,
    verifyUser: `${rootURL3}/api/Accounts/verifyemail`,
    getUserInfoold: `${rootURL3}/api/Customers/GetUser?UserId=`,
    getUserInfo: `${rootURL3}/api/Customers/GetUser?UserId=`,
    updateNotification: `${rootURL3}/api/Customers/UpdateUserNotifications`,
    tokenLogin: `${rootURL3}/api/Accounts/DecryptToken`,
    onUserEmailVerification: `${rootURL3}/api/Accounts/UserEmailVerification`,
    onUserSMSVerification: `${rootURL3}/api/Accounts/UserSMSVerification`,
    onGetUserEmailVerification: `${rootURL3}/api/Accounts/EmailVerification`,
    onGetUserSMSVerification: `${rootURL3}/api/Accounts/SMSVerification`,
    addAccountNotifications: `${rootURL3}/api/Customers/AddAccountNotifications`,
  },
  dashboard: {
    getData: `${rootURL}/userinfoV3`,
    updatePersonalInfo_old: `${rootURL}/updateuserinfo`,
    updatePersonalInfoold: `${rootURL3}/api/Customers/UpdateUser_MB`,
    updatePersonalInfo: `${rootURL3}/api/Customers/UpdateUser_MB`,
    updateAddressold: `${rootURL}/updateusercontactinfo`,
    updateAddress: `${rootURL3}/updateusercontactinfo`,
    getEnvironment: `${rootURL}/envinfo?`,
    addDevice: `${rootURL}/deviceaddv2`,
    getDeviceInfo_old: `${rootURL}/devicesinfodash?user_email=`,
    getDeviceInfo: `${rootURL3}/api/Devices/GetDashboardInfo_MB`,
    dublicateSerial_old: `${rootURL}/verifyserial?serial_no=`,
    dublicateSerial: `${rootURL3}/api/Devices/VerifySerialNo`,
    getAllEnv_old: `${rootURL}/api/Environment/GetAllEnvironments`,
    getAllEnv: `${rootURL3}/api/Environment/GetEnvironments`,
    getEnvIcons: `${rootURL3}/api/Environment/GetEnvironmentIcons`,
    getStaticEnv: `${rootURL3}/api/Environment/GetStaticEnvironments`,
    addDashboardDevice_old: `${rootURL}/api/Devices/deviceaddv2`,
    addDashboardDevice: `${rootURL3}/api/Devices/RegisterSerial`,
    addDashboardEnv_old: `${rootURL}/api/Environment/AddUpdateEnvironments`,
    addDashboardEnvold: `${rootURL3}/api/Environment/AddUpdateEnvironments`,
    addDashboardEnv: `${rootURL3}/api/Environment/AddUpdateEnvironments`,
    getDashboardData: `${rootURL}/api/Devices/api/Devices/devicesinfodash?user_email=`,
    uploadImage_old: `${rootURL}/api/Generic/UploadImage`,
    uploadImage: `${rootURL3}/api/Artifacts/image`,
    uploadReceiptImage: `${rootURL3}/api/Artifacts/receiptimage`,
    getMyEnv_old: `${rootURL}/api/Environment/GetMyEnvironments`,
    getMyEnvold: `${rootURL3}/api/Environment/GetMyEnvironmentsWithDevicesCount`,
    getMyEnv: `${rootURL3}/api/Environment/GetMyEnvironmentsWithDevicesCount`,
    getComapnyEnv: `${rootURL3}/api/Environment/GetCompanyEnvironmentWithDevices_MB`,
    changeEnvName: `${rootURL}/api/Environment/AddUpdateEnvironments`,
    tranferDevice: `${rootURL3}/api/Devices/TransferOwnershipDevice`,
    viewtranferDevices: `${rootURL3}/api/Devices/ViewTransfershipDevices`,
    claimDeviceOnwership: `${rootURL3}/api/Devices/ConfirmTransferOwnership`,
    rejectDeviceOnwership: `${rootURL3}/api/Devices/CancelTransfership`,
    getEnvFavColor: `${rootURL3}/api/Environment/GetEnvironmentColors_MB_v2`,
    deleteEnvFavColor: `${rootURL3}/api/Environment/RemoveEnvironmentFavColor_MB`,
    addUpdateEnvFavColor: `${rootURL3}/api/Environment/AddUpdateFavColors_MB_v2`,
    fetchNotifications: `${rootURL3}/api/Generic/GetNotificationsByUser`,
    getCompanyDevices: `${rootURL3}/api/Devices/GetCompanyDevices_MB`,
    toMarkUnMarkPinEnv: `${rootURL3}/api/Environment/MarkUnMarkPinnedEnvironments`,
    getPinnedEnviornments: `${rootURL3}/api/Environment/GetPinnedEnvironments`,
    deleteEnvironment: `${rootURL3}/api/Environment/DeleteEnvironment`,
    deleteDevice: `${rootURL3}/api/Devices/DeleteDevices`,
    getNotificationReleases: `${rootURL3}/api/SoftwareRelease/GetNotificationReleases`,
    markNotificationRead: `${rootURL3}/api/SoftwareRelease/MarkNotificationRead`,
  },
  products: {
    getAllProductType_old: `${rootURL}/getallproducttypes`,
    getAllProductType: `${rootURL3}/api/Products/GetProductCategories_MB`,
    getProduct_old: `${rootURL}/getAllProductsByTypeId`,
    getProduct: `${rootURL3}/api/Products/GetProductsByProductCategory_MB`,
    getAllDealers_old: `${rootURL}/getalldealers`,
    // getAllDealers: `${rootURL3}/api/Devices/GetDealers`,
    // getAllDealers: `${rootURL3}/api/Devices/GetDealers_v2`,
    GetNewArrivalsProducts: `${rootURL3}/api/Products/GetNewArrivalsProducts`,
    GetPopularProducts: `${rootURL3}/api/Products/GetPopularProducts`,
    GetProductsDetailById: `${rootURL3}/api/Products/GetProductsDetailById`,
    GetAllNewsRoomContentByRoomId: `${rootURL3}/api/NewsRoom/getAllNewsRoomContentByRoomId?newsRoomId=1`,
    GetProductCategory: `${rootURL3}/api/Products/GetProductCategory_MB`,
    GetProductsByProductCategory: `${rootURL3}/api/Products/GetProductsByProductCategory_MB?id=2`,
  },
  device: {
    updateDeviceName_old: `${rootURL}/updatedevicename`,
    updateDeviceNameold: `${rootURL3}/api/Devices/UpdateDeviceName`,
    updateDeviceName: `${rootURL3}/api/Devices/UpdateDeviceName`,
    updateDeviceInfo: `${rootURL3}/api/Devices/UpdateDevicesInfo`,
    getDeviceOverviewInfo : `${rootURL3}/api/Devices/GetDeviceOverviewInfo`,
    replaceImage_old: `${rootURL}/api/Devices/UpdateDeviceReceipt`,
    replaceImage: `${rootURL3}/api/Devices/UpdateReceipt`,
    getDevicesOverView: `${rootURL}/api/Devices/api/Devices/devicesinfodash?user_email=`,
    updateColorCollection: `${rootURL}/api/Devices/UpdateRemoveDevicesColors`,
    updateMyDevice: `${rootURL}/api/Devices/UpdateDeviceEnv`,
    getUserDevices: `${rootURL3}/api/Devices/GetUserDevicesList_MB`,
    getSoftwareReleaseProductOverview: `${rootURL3}/api/SoftwareRelease/GetSoftwareReleaseProductOverview/`,
  },
  dealerLocator: {
    getDealersAutoComplete_old: `${rootURL}/api/Dealer/GetDealersAutoComplete`,
    getDealersAutoComplete: `${rootURL3}/api/Devices/GetDealersFilterAutoComplete`,
    getDealersSuggestion_old: `${rootURL}/api/Dealer/GetDealersSuggestion`,
    getDealersSuggestion: `${rootURL3}/api/Devices/FindDealers`,
    dealersFilter: `${rootURL3}/api/Devices/GetDealersFilter`,
    getAuthorizedDealers: `${rootURL3}/api/Devices/ViewAuthorizedDealers`,
    getDNBLDealers: `${rootURL3}/api/Devices/ViewDNBLDealers`,
    getUserDelears: `${rootURL3}/api/Devices/GetDealers_v2`,
  },
  lookups: {
    getCities: `${rootURL3}/api/Generic/GetCities`,
    getStates: `${rootURL3}/api/Generic/GetProvinces`,
    getCountries: `${rootURL3}/api/Generic/GetCountries`,
  },
  conversation: {
    getUserRoomsByUsersId: `${chatURL}/Hub/GetUserRoomsByUsersId`,
    getChatDetailsByRoomId: `${chatURL}/Hub/GetChatDetailsByRoomId`,
    makeRoomFavorite: `${chatURL}/Hub/MakeRoomFavorite`,
    createRoom: `${chatURL}/Hub/CreateRoom`,
    deleteRooms: `${chatURL}/Hub/DeleteRooms`,
    uploadFile: `${chatURL}/Hub/UploadFile`,
    downloadFile: `${chatURL}/Hub/DownloadFile/`,
    getDealors: `${rootURL3}/api/Devices/GetDealersByUser`,
    getChatUsers: `${rootURL3}/api/Companies/GetChatUsers`,
    sendMessageApi: `${chatURL}/Hub/SendMsgViaAPI`,
    updateCampaignIdInRoom: `${chatURL}/Hub/UpdateCampaignIdInRoom`,
    getCampaignsDdl: `${chatURL}/Hub/GetCampaignsDdl`,
    createLeadsForShopiy: `${chatURL}/Hub/CreateLeadsForShopiy`,
  },
  spaDevice: {
    getSpaInfoold: `${rootURL3}/api/Devices/GetSPADeviceDetailById`,
    getSpaInfo: `${rootURL3}/api/Devices/GetSPADeviceDetailById`,
    addUpdateSetting: `${rootURL3}/api/Devices/AddUpdateSPASettings`,
    updateWaterTreatment: `${rootURL3}/api/Devices/AddUpdateSPAWaterTreatment`,
    updateGlobalSetting: `${rootURL3}/api/Devices/AddUpdateSPAGlobalSettings`,
    updateTemperatureSettingold: `${rootURL3}/api/Devices/AddUpdateTemperatureSettings`,
    updateTemperatureSetting: `${rootURL3}/api/Devices/AddUpdateTemperatureSettings`,
    getSpaGeneralInfo: `${rootURL3}/api/Devices/GetSPAGeneralSettingsByDeviceId`,
    getSpaFilterInfoold: `${rootURL3}/api/Devices/GetSPAFilterSettingsByDeviceId`,
    getSpaFilterInfo: `${rootURL3}/api/Devices/GetSPAFilterSettingsByDeviceId`,
    getSpaTempInfo: `${rootURL3}/api/Devices/GetSPATemperatureSettings`,
    getAllWaterLif: `${rootURL3}/api/Devices/GetAllWaterLife?deviceId=`,
    addUpdateWaterLife: `${rootURL3}/api/Devices/AddUpdateWaterLife`,
    getAllFilterLif: `${rootURL3}/api/Devices/GetAllFilterLife?deviceId=`,
    getFilterLifeGraphDetails: `${rootURL3}/api/Devices/GetFilterLifeGraphDetails`,
    getWaterLifeGraphDetails: `${rootURL3}/api/Devices/GetWaterLifeGraphDetails`,
    getTemperatureSettingGraphDetails: `${rootURL3}/api/Devices/GetTemperatureSettingGraphDetails`,
    addUpdateFilterLife: `${rootURL3}/api/Devices/AddUpdateFilterLife`,
    getWaterLifeState: `${rootURL3}/api/Devices/GetWaterLifeStats?deviceId=`,
    getFilterLifeState: `${rootURL3}/api/Devices/GetFilterLifeStats?deviceId=`,
    deleteFilterLife: `${rootURL3}/api/Devices/DeleteFilterLife`,
    deleteWaterLife: `${rootURL3}/api/Devices/DeleteWaterLife`,
    getCurrentSpaChemicalTestig: `${rootURL3}/api/Devices/GetCurrentSPAChemicalTestingResult`,
    getCurrentSpaChemicalTestigGraphDetails: `${rootURL3}/api/Devices/GetSPAChemicalTestingGraphDetailsWithDates`,
    addUpdateChemicalTesting: `${rootURL3}/api/Devices/AddUpdateSPAChemicalTesting`,
    getSpaChemicalTesting: `${rootURL3}/api/Devices/GetSPAChemicalTestingList`,
    getSpaChemicalTestigList: `${rootURL3}/api/Devices/GetSPAChemicalTestingList`,
    deleteSPAChemicalTesting: `${rootURL3}//api/Devices/DeleteSPAChemicalTesting`,
    getSpaFilterSettingsold: `${rootURL3}/api/Devices/GetSPAFilterSettingsByDeviceId`,
    getSpaFilterSettings: `${rootURL3}/api/Devices/GetSPAFilterSettingsByDeviceId`,
    updateSpaFilterSettingsold: `${rootURL3}/api/Devices/AddUpdateSPAFilterSettings`,
    updateSpaFilterSettings: `${rootURL3}/api/Devices/AddUpdateSPAFilterSettings`,
    getSpaDevices: `${rootURL3}/api/LoggedInDevices/Device`,
    removeSpaDevice: `${rootURL3}/api/LoggedInDevices`,
    updateSpaDevice: `${rootURL3}/api/LoggedInDevices`,
    getSpaGlobalScenes: `${rootURL3}/api/SpaGlobalScenes/SpaGlobalSettings`,
    removeSpaGlobalScene: `${rootURL3}/api/SpaGlobalScenes`,
    updateSpaGlobalScene: `${rootURL3}/api/SpaGlobalScenes/SetAsCurrent`,
    getDeviceUseHistory: `${rootURL3}/api/Devices/GetDeviceUseHistory`,
    getConnectedDevices: `${rootURL3}/api/Devices/GetUserConnectedDevices`,
    getUserSpaDevices: `${rootURL3}/api/Devices/GetUserDevicesList_MB`,
    updateDeviceTopside: `${rootURL3}/api/Devices/UpdateTopsideNameRegistration`,
    updateDevicePack: `${rootURL3}/api/Devices/UpdateControllerNameRegistration`,
    updateDeviceGateway: `${rootURL3}/api/SpaGateway/UpdateName`,
    deleteTopsideDevice: `${rootURL3}/api/Devices/DeleteTopside`,
    deletePackDevice: `${rootURL3}/api/Devices/DeleteController`,
    deleteGatewayDevice: `${rootURL3}/api/SpaGateway`,
    getOwnedSpaDevices: `${rootURL3}/api/Devices/GetUserSPADevicesList_MB`,
  },
  spaUser: {
    getSpaUserById: `${rootURL3}/api/SpaUser/Device`,
    getUpdateSpaUserDeviceByDeviceId: `${rootURL3}/api/SpaUser`,
    removeSpaUserByDeviceId: `${rootURL3}/api/SpaUser`,
    getSpaAccessIcons: `${rootURL3}/api/Generic/GetSpaAccessIcons`,
  },
  spaNotification: {
    spaNotification: `${rootURL3}/api/SpaNotifications/Device`,
  },
  supportResource: {
    getSpaSupportResources: `${rootURL3}/api/Products/GetProductSupportResources`,
  },

  priceList: {
    getPriceList: `${rootURL3}/api/PriceListTool/GetPriceListByGroups`,
    getPriceListById: `${rootURL3}/api/PriceListTool/PriceListPreviewById`,
    DownloadCSV: `${rootURL3}/api/PriceListTool/DownloadCSV`,
    DownloadPDF: `${rootURL3}/api/PriceListTool/DownloadPDF`,
  },
  company: {
    getCompanyDetail: `${rootURL3}/api/Companies/GetCompanyDetail`,
  },
  baseUrl: rootURL3,
};
