import moment from "moment";
import React, { Component, useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { RiAddFill, RiArrowDownSLine, RiCheckFill } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import AutoCompleteLoc from "../../components/common/AutoCompleteLoc";
import {
  AccountsWizard,
  FloatingLabelInput,
  FloatingLabelSelect,
  Loader,
} from "../../components/common/index";
import { RoutesObj } from "../../routes";
import {
  Grill,
  House,
  Lighting,
  Marine,
  Motorcycles,
  Powersports,
  Spa,
  Star,
} from "../../components/common/SVG/SvgIcons";
import finish from "../../resources/images/finish.png";
import {
  backArrow,
  vector_0,
  vector_1,
  vector_2,
  vector_3,
  vector_4,
  vector_5,
  vector_6,
  vector_7,
  finishSetup,
} from "../../resources/images/index";
import logo from "../../resources/images/logoReg.svg";
import { accountActions } from "../../store/accounts/accountActions";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { deviceOverviewAction } from "../../store/deviceOverview/deviceOverviewAction";
import AddDeviceBox from "../AddDeviceBox";

import Autocomplete from "react-google-autocomplete";
import { Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  ContactInfoForm,
  CreateEnvironment,
  AddProduct,
  NotificationForm,
} from "./Components";
import ProductRegistrationHeader from "./Components/ProductRegistrationHeader";
import { AccountsWizardMob } from "../../components/common/Stepper/AccountsWizardMob";

class ProductRegistration extends Component {
  constructor() {
    super();
    this.autoRef = React.createRef();
    this.state = {
      currentTabId: "pane1",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
      termsError: false,
      address: "",
      city: "",
      stateCode: "",
      postalCode: "",
      country: "",
      phoneNumber: "",
      customEnv: "",
      addDevice: "",
      nameDevice: "",
      serialNumber: "",
      purchaseLoc: "",
      puchaseDate: "",
      installationDate: "",
      recepit: "",
      envIconName: "",
      focused: false,
      installationDateFocused: false,
      addEnvironment: false,
      addLogo: false,
      emailError: false,
      passError: false,
      confPassError: false,
      nameError: false,
      firstNameError: false,
      lastNameError: false,
      cityError: false,
      stateError: false,
      countryError: false,
      phoneError: false,
      postalError: false,
      isLoading: false,
      contactError: false,
      deviceError: false,
      deviceButton: true,
      deviceImage: false,
      selectedProduct: "",
      dealerId: "",
      isExpand: false,
      isShowSerialNo: false,
      dublicateSerial: false,
      selectedEnvName: "",
      selectedEnvId: null,
      selectedEnvData: null,
      selectedEnvLocObj: {},
      selectedEnvSameAccountLoc: true,
      productTypeId: null,
      receiptStatus: 0,
      displayReceipt: "",
      cityOptions: "",
      statesOptions: "",
      countryOptions: "",
      isAAVDeals: false,
      isSupportMessages: false,
      productCategory: "",
      step2: false,
      step3: false,
      purchaseLoc: "",
      delearId: "",
      allDelears: [],
      selectedDevice: "",
      isCityEditable: false,
      isAddressEdited: false,
      isCountryEditable: false,
      isStateEditable: false,
      isPostalCodeEditable: false,
      isManuallyEnter: false,
      // productCategory: "",
      isContactInfo: false,
      isEnvironmentInfo: false,
      isDeviceInfo: false,
      isLastStep: false,
      stepNumber: 0,
      isgettingStarted: false,
      screenWidth: window.innerWidth,
    };

    this.handleShowCustomEnvironment =
      this.handleShowCustomEnvironment.bind(this);
    this.returnIcons = this.returnIcons.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.props.actions.onChange("currentPaneId", "pane1");
    await this.props.homeActions.onGetAllCities().then((data) => {
      if (data.status === 200 && data.data.success) {
        let cityOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          cityOptions: cityOptions,
        });
      }
    });
    await this.props.homeActions.onGetAllStates().then((data) => {
      if (data.status === 200 && data.data.success) {
        let statesOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          statesOptions: statesOptions,
        });
      }
    });
    await this.props.homeActions.onGetAllCountries().then((data) => {
      if (data.status === 200 && data.data.success) {
        let countryOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          countryOptions: countryOptions,
        });
      }
    });
    this.setState({
      isLoading: false,
    });
  }

  returnIcons = (enName, enIconName) => {
    if (enName === "Spa" || enIconName === "vector_04") {
      return <img src={vector_4} alt="" />;
    } else if (enName === "Motorcycles" || enIconName === "vector_05") {
      return <img src={vector_5} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_02") {
      return <img src={vector_2} alt="" />;
    } else if (enName === "Marine" || enIconName === "vector_03") {
      return <img src={vector_3} alt="" />;
    } else if (enName === "Powersports" || enIconName === "vector_06") {
      return <img src={vector_6} alt="" />;
    } else if (enName === "House" || enIconName === "vector_00") {
      return <img src={vector_0} alt="" />;
    } else if (enName === "Grill" || enIconName === "vector_01") {
      return <img src={vector_1} alt="" />;
    } else if (enName === "Star" || enIconName === "vector_07") {
      return <img src={vector_7} alt="" />;
    } else if (enName === "Marine" || enIconName === "vector_08") {
      return <img src={vector_3} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_09") {
      return <img src={vector_2} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_10") {
      return <img src={vector_5} alt="" />;
    } else if (enName === "Powersports" || enIconName === "vector_11") {
      return <img src={vector_6} alt="" />;
    }
  };

  returnSVGIcons = (iconName) => {
    if (iconName === "vector_00") {
      return <House />;
    } else if (iconName === "vector_01") {
      return <Grill />;
    } else if (iconName === "vector_02") {
      return <Lighting />;
    } else if (iconName === "vector_03") {
      return <Marine />;
    } else if (iconName === "vector_04") {
      return <Spa />;
    } else if (iconName === "vector_05") {
      return <Motorcycles />;
    } else if (iconName === "vector_06") {
      return <Powersports />;
    } else if (iconName === "vector_07") {
      return <Star />;
    } else if (iconName === "vector_08") {
      return <Marine />;
    } else if (iconName === "vector_09") {
      return <Lighting />;
    } else if (iconName === "vector_10") {
      return <Motorcycles />;
    } else if (iconName === "vector_11") {
      return <Powersports />;
    }
  };

  onDeviceButtonClick = (itemId) => {
    this.setState({ isLoading: true, productTypeId: itemId });
    this.props.homeActions.onGetProducts(itemId).then((data) => {
      this.props.homeActions.getAllDealers(itemId);
      this.setState({ isLoading: false });
    });
    this.setState({
      deviceButton: false,
      deviceImage: true,
    });
  };
  changeHandler = (event) => {
    // setLoading(true);

    this.props.deviceAction
      .onGetDelears(
        event.target.value,
        this.state.productCategory.productCategoryId
      )
      .then((res) => {
        console.log("resssss", res);
        this.setState({ allDelears: res?.data || [] });
        // setLoading(false);
      });
  };
  onBackArroClick = () => {
    this.setState({
      deviceButton: true,
      deviceImage: false,
      selectedProduct: "",
      isShowSerialNo: false,
      step2: false,
    });
    // this.onDeviceButtonClick(this.state.productTypeId);
  };

  onSelectProduct(item) {
    // const prdData = { pTId: item.product_type_id, pId: item.product_id };
    this.setState({
      selectedProduct: item,
      isShowSerialNo: item.isSerialized === 0 ? false : true,
      serialNumber: "",
      step2: true,
    });
    this.setState({ selectedDevice: item });
  }
  checkSelectedProd(pId) {
    const { selectedProduct } = this.state;
    if (selectedProduct !== "" && selectedProduct.productId === pId) {
      return true;
    } else {
      return false;
    }
  }

  onSelectDealer = (e, dealer) => {
    const item = dealer.filter((obj) => obj.id === +e.target.value);
    if (item[0] && item[0].value) {
      this.setState({
        purchaseLoc: item[0].id,
        dealerId: item[0].id,
      });
    } else {
      this.setState({
        purchaseLoc: "",
        dealerId: "",
      });
    }
  };

  handleShowCustomEnvironment = () => {
    this.setState({
      addEnvironment: !this.state.addEnvironment,
    });
  };

  handleChangeImage = (e) => {
    if (e.target.files.length) {
      this.setState({ isLoading: true });
      this.props.homeActions.onUploadImage(e.target.files[0]).then((data) => {
        this.setState({ isLoading: false });
        if (data && data.data && data.data.success) {
          this.setState({ recepit: data.data.data.url });
          this.toBase64(e.target.files[0]).then((data) => {
            const result = data.split("base64,")[1];
            this.setState({ displayReceipt: result, receiptStatus: 1 });
          });
        } else {
          this.setState({ displayReceipt: "", recepit: "", receiptStatus: 0 });
        }
      });
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  checkAddDevice() {
    const {
      nameDevice,
      serialNumber,
      puchaseDate,
      installationDate,
      purchaseLoc,
      selectedProduct,
      isShowSerialNo,
      dublicateSerial,
    } = this.state;
    if (
      nameDevice !== "" &&
      purchaseLoc !== "" &&
      puchaseDate !== "" &&
      // installationDate !== "" &&
      selectedProduct !== ""
    ) {
      if (isShowSerialNo) {
        if (serialNumber !== "" && !dublicateSerial) {
          this.onAddDevice();
        } else {
          this.setState({ deviceError: true });
        }
      } else {
        this.onAddDevice();
      }
    } else {
      this.setState({ deviceError: true });
    }
  }

  isDublicateSerial(e) {
    if (e.target.value !== "" && this.state.isShowSerialNo) {
      this.setState({ isLoading: true });
      this.props.homeActions.onDublicateSerial(e.target.value).then((data) => {
        if (data && data.status === 200 && data.data && data.data.success) {
          this.setState({ dublicateSerial: false });
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ dublicateSerial: true });
    }
  }

  onRegisterNewUser = () => {
    this.setState({ isLoading: true });
    const user_info = {
      firstName: this.state.firstName,
      city: this.state.city,
      address: this.state.address,
      zipCode: this.state.postalCode,
      phoneNumber: this.state.phoneNumber,
      lastName: this.state.lastName,
      email: this.state.email,
      confirmEmail: this.state.email,
      country: this.state.country,
      state: this.state.stateCode,
      password: this.state.password !== "" ? this.state.password : "",
      confirmPassword:
        this.state.confirmPassword !== "" ? this.state.confirmPassword : "",
      groupId: 0,
      customerTypeName: "",
      companyId: 0,
      isFavourite: false,
      isAdmin: false,
      image: "",
      rating: "",
      policies: [],
    };
    this.props.actions.onRegisterUser(user_info).then((user) => {
      if (user && user.data && user.data.success) {
        this.props.homeActions
          .onGetAllEnvironment(user.data.data.id)
          .then((env) => {
            this.setState({ isLoading: false });
            this.props.actions.onChange("currentPaneId", "pane3");
          });

        this?.props?.actions
          ?.onUserEmailVerification(user.data.data.id)
          .then((res) => {
            console.log("email verification res:", res);
          })
          .catch((err) => {
            console.log("error in verification:", err);
          });

        this?.props?.actions
          ?.onUserSMSVerification(user.data.data.id)
          .then((res) => {
            console.log("sms verification res:", res);
            toast.success(
              "Email has been sent to you for verification of email and phone!"
            );
          })
          .catch((err) => {
            console.log("error in verification:", err);
          });
      } else {
        this.setState({ isLoading: false });
        toast.error(
          user && user.data && user.data.message
            ? user.data.message
            : "Something went wrong, please try again"
        );
      }
    });
  };
  onAddEnv = () => {
    const userId = localStorage.getItem("userId");
    const {
      selectedEnvId,
      envIconName,
      customEnv,
      selectedEnvData,
      selectedEnvLocObj,
      selectedEnvSameAccountLoc,
    } = this.state;
    let req;
    if (selectedEnvId !== null) {
      this.setState({ isLoading: true });
      req = {
        environmentId: 0,
        environmentName: selectedEnvData.environmentName,
        environmentType: selectedEnvData.environmentType,
        environmentIcon: selectedEnvData.environmentIcon,
        status: 1,
        userId: userId,
        location: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.fullAddress
          : "",
        latitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.lat || ""
          : "",
        longitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.long || ""
          : "",
        isSameAccountLocation: selectedEnvSameAccountLoc,
      };

      this.props.homeActions.onAddDashboardEnv(req).then((env) => {
        this.setState({ isLoading: false });
        if (env && env.data && env.data.success) {
          this.setState({
            selectedEnvId: env.data.data,
            selectedEnvName: customEnv,
          });
          this.props.actions.onChange("currentPaneId", "pane4");
          this.props.homeActions.onGetAllProductType().then((data) => {
            this.setState({
              isLoading: false,
            });
          });
          // this.props.homeActions.getAllDealers();
        } else {
          this.setState({ isLoading: false });
          toast.error(
            env && env.data && env.data.message
              ? env.data.message
              : "Something went wrong please try again"
          );
        }
      });
    } else {
      this.setState({ isLoading: true });
      req = {
        environmentId: 0,
        environmentName: customEnv,
        environmentType: "D",
        environmentIcon: envIconName,
        status: 1,
        userId: userId,
        location: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.fullAddress
          : "",
        latitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.lat || ""
          : "",
        longitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.long || ""
          : "",
        isSameAccountLocation: selectedEnvSameAccountLoc,
      };
      this.props.homeActions.onAddDashboardEnv(req).then((env) => {
        this.setState({ isLoading: false });
        if (env && env.data && env.data.success) {
          this.setState({
            selectedEnvId: env.data.data,
            selectedEnvName: customEnv,
          });
          this.props.actions.onChange("currentPaneId", "pane4");
          this.props.homeActions.onGetAllProductType().then((data) => {
            this.setState({
              isLoading: false,
            });
          });
          // this.props.homeActions.getAllDealers();
        } else {
          this.setState({ isLoading: false });
          toast.error(
            env && env.data && env.data.message
              ? env.data.message
              : "Something went wrong please try again"
          );
        }
      });
    }
  };

  onAddDevice = () => {
    const userId = localStorage.getItem("userId");
    this.setState({ isLoading: true });
    const {
      nameDevice,
      productTypeId,
      selectedProduct,
      serialNumber,
      purchaseLoc,
      puchaseDate,
      installationDate,
      recepit,
      dealerId,
      selectedEnvId,
      receiptStatus,
    } = this.state;
    let deviceData = {
      name: nameDevice,
      product_type_id: productTypeId,
      productId: selectedProduct.productId,
      serialNo: serialNumber,
      purchase_location: purchaseLoc,
      purchaseDate: moment(puchaseDate).format("MM/DD/YYYY"),
      basePath: "",
      receiptStatus: receiptStatus,
      receipt: recepit,
      userId: userId,
      dealerLocationId: dealerId,
      sku: selectedProduct.sku,
      envId: selectedEnvId,
      colors: [""],
    };
    if (installationDate) {
      deviceData.installationDate =
        moment(installationDate).format("MM/DD/YYYY");
    }
    this.props.homeActions.onAddDashboardDevice(deviceData).then((device) => {
      this.setState({ isLoading: false });
      if (device.data.success) {
        this.props.actions.onChange("currentPaneId", "pane5");
      } else {
        toast.error("Something went wrong, please try again");
      }
    });
  };

  handleDashboard = () => {
    const userId = localStorage.getItem("userId");
    const { history } = this.props;
    this.setState({ isLoading: true });
    const { isAAVDeals, isSupportMessages } = this.state;
    let req = {
      isAAVDeals,
      isSupportMessages,
      id: userId,
    };
    this.props.actions.onUpdateUserNotifications(req).then((data) => {});
    history.push(RoutesObj.Dashboard.path);
  };

  setEnvLocation = (place) => {
    console.log("place", place);
  };

  onCreateEv = () => {
    const { selectedEnvName, customEnv, envIconName, selectedEnvLocObj } =
      this.state;

    console.log("location:", selectedEnvLocObj);

    if ((selectedEnvName === "" || customEnv === "") && envIconName === "") {
      toast.error("Please select environment and icon");
      return;
    }
    // if (Object.keys(selectedEnvLocObj).length === 0) {
    //   toast.error("Enviornment location field is mandatory!");
    //   return;
    // }
    this.onAddEnv();
  };

  activeStep3 = () => {
    console.log("take note:", this.state.nameDevice);
    if (this.state.nameDevice) {
      this.setState({
        step3: true,
      });
    }
  };
  handleClickSkip = () => {
    this.props.actions.onChange("currentPaneId", "pane5");
  };
  onPlaceSelected = (place) => {
    this.setState({
      stateCode: "",
      country: "",
      city: "",
      postalCode: "",
    });

    this.setState({ address: place.formatted_address });
    const address = place.address_components;
    let state, country, city, postalCode;

    address.forEach((component) => {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
      if (component.types.includes("administrative_area_level_2")) {
        city = component.long_name;
      }
      if (component.types.includes("country")) {
        country = component.long_name;
      }
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });
    this.setState({
      stateCode: state,
      country: country,
      city: city,
      postalCode: postalCode ? postalCode : "",
      // isCityEditable: false,
      // isCountryEditable: false,
      // isStateEditable: false,
      // isPostalCodeEditable:false,
      // isManuallyEnter: false,
    });

    // enable city editing if the user starts editing the address manually
    // this.setState({ isCityEditable: false, isCountryEditable: false, isStateEditable: false, isPostalCodeEditable:false,});
    // const addressInput = document.getElementById("popupEnvLocInput");
    // addressInput.addEventListener("input", () => {
    //   this.setState({ isCityEditable: true, isCountryEditable: true, isStateEditable: true, isPostalCodeEditable:true, });
    //   addressInput.removeEventListener("input", () => { });
    // });
  };

  onGetEnvInfo = (envId, envName) => {
    console.log("check env details:", envId, envName);
    this.setState({
      selectedEnvId: envId,
      selectedEnvName: envName,
    });
  };
  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const {
      isLoading,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      terms,
      termsError,
      address,
      city,
      stateCode,
      postalCode,
      country,
      phoneNumber,
      customEnv,
      nameDevice,
      serialNumber,
      purchaseLoc,
      puchaseDate,
      installationDate,
      envIconName,
      emailError,
      passError,
      confPassError,
      // nameError,
      firstNameError,
      lastNameError,
      cityError,
      stateError,
      countryError,
      phoneError,
      contactError,
      deviceError,
      recepit,
      selectedProduct,
      // dealerId,
      isShowSerialNo,
      dublicateSerial,
      selectedEnvName,
      displayReceipt,
      postalError,
      cityOptions,
      statesOptions,
      countryOptions,
      selectedEnvSameAccountLoc,
      isAAVDeals,
      isSupportMessages,
      isCityEditable,
      isStateEditable,
      isCountryEditable,
      isAddressEdited,
      isContactInfo,
      isEnvironmentInfo,
      isDeviceInfo,
    } = this.state;
    const { productType, allProducts, allDealers, myStaticEnv } =
      this.props.homeState;
    const { dispatch, history } = this.props;
    const { screenWidth } = this.state;

    return (
      <>
        {/* <div className="registration-container__header">
          <img src={logo} alt="" />
        </div> */}
        <div className="mainWrapper">
      <div className="productMobileStepper">
            <ProductRegistrationHeader
              stepNumber={this.state.stepNumber}
              onStepChange={this.handleStepChange}
            />

      </div>
          
          <div className="registration-container__leftSection">
            <div className="registration-container__header">
              <img src={logo} alt="" width="304px" height="68px" />
            </div>
            <Box sx={{ width: "100%" }}>
              <AccountsWizard
                onNext={(activeStep) => {
                  console.log("aaaaaa", activeStep);
                  this.setState({ stepNumber: activeStep });
                }}
                activeStep={this.state.stepNumber}
                stepOnePrimary="Choose Environment"
                stepOneSecondary="Create an Aquatic AV environment "
                stepTowPrimary="Choose Device"
                stepTwoSecondary="Choose which device to register"
                stepThreePrimary="Device Details"
                stepThreeSecondary="Used for warranty + support"
                stepFourPrimary="Finish Setup"
                stepFourSecondary="Notifications and settings"
              />
          
            </Box>
            <Box className="helpingText">
              <Typography style={{ color: "#7E8299" }}>Need Help?</Typography>
              <Typography style={{ color: "#1BBED0" }}>
                Contact Aquatic AV Support
              </Typography>
            </Box>
          </div>
          <div className="registration-container__rightSection">
            <div className="registration-container ">
              <div className="alreadyAccountCls">
                {this.state.stepNumber === 3
                  ? ""
                  : "Not Ready to Register a Device?"}
                <span>
                  <a href="/dashboard" className="linkText">
                    {this.state.stepNumber === 3 ? "" : "Cancel"}
                  </a>
                </span>
              </div>

              {isLoading && <Loader />}
              {console.log(
                "selectedDeviceselectedDevice",
                this.state.selectedProduct?.isSerialized
              )}

              <div className="">
                {/* <TabNav> */}
                {/* PERSONAL INFORMATION */}
                {/* <Pane id="pane1" title="Personal Information" index="1"> */}

                {(() => {
                  switch (this.state.stepNumber) {
                    case 0:
                      return (
                        <CreateEnvironment
                          onEnvCreate={(isCreated) => {
                            if (
                              this.state.stepNumber < 3 &&
                              isCreated == true
                            ) {
                              this.setState({
                                stepNumber: this.state.stepNumber + 1,
                              });
                            }
                          }}
                          onGetEnvInfo={this.onGetEnvInfo}
                        />
                      );

                    case 1:
                      return (
                        <AddProduct
                          onClick={() => {
                            if (this.state.stepNumber < 3) {
                              this.setState({
                                stepNumber: this.state.stepNumber + 1,
                              });
                            }
                          }}
                          stepNumber={this.state.stepNumber}
                          environmentId={this.state.selectedEnvId}
                          environmentName={this.state.selectedEnvName}
                          // onSkipClick={() => {
                          //   this.setState({
                          //     stepNumber: this.state.stepNumber + 1,
                          //   });
                          // }}
                        />
                      );
                    case 2:
                      return (
                        <AddProduct
                          onClick={() => {
                            if (this.state.stepNumber < 3) {
                              this.setState({
                                stepNumber: this.state.stepNumber + 1,
                              });
                            }
                          }}
                          stepNumber={this.state.stepNumber}
                          environmentId={this.state.selectedEnvId}
                          environmentName={this.state.selectedEnvName}
                        />
                      );
                    case 3:
                      return (
                        <div>
                          <h2
                            style={{
                              marginTop: 14,
                            }}
                            className="productRegisterHeading"
                          >
                            Product Registration
                          </h2>
                          <div className="mainAccountWrap">
                            <img
                              src={finishSetup}
                              alt="finish setup"
                              width={275}
                              height={195}
                              style={{ marginTop: "10%" }}
                            />
                            <div className="finishAccountTitleWrap">
                              <div className="mainTitleStyle">
                                Device Added! You're All Set!
                              </div>
                              <p
                                style={{ marginTop: 10 }}
                                className="pane5 notifications"
                              >
                                Select which notifications you want to receive
                                via e-mail or within the Aquatic AV app.
                              </p>

                              <label className="container pane5">
                                <span
                                  // href="https://aquaticav.com/terms/"
                                  // target="_blank"
                                  // rel="noreferrer"
                                  style={{
                                    color: "#464E5F",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: "-3%",
                                    // textDecoration: "underline",
                                    marginLeft: 12,
                                  }}
                                >
                                  New AAV Deals
                                </span>
                                <input
                                  type="checkbox"
                                  id="aavdeals"
                                  value={isAAVDeals}
                                  checked={isAAVDeals ? true : false}
                                  onChange={(e) => {
                                    this.setState({
                                      isAAVDeals: !isAAVDeals,
                                    });
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="container pane5">
                                <span
                                  style={{
                                    color: "#464E5F",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: 30,
                                  }}
                                >
                                  Support Messages
                                </span>
                                <input
                                  type="checkbox"
                                  id="aavdeals"
                                  value={isSupportMessages}
                                  checked={isSupportMessages ? true : false}
                                  onChange={(e) => {
                                    this.setState({
                                      isSupportMessages: !isSupportMessages,
                                    });
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>

                            <button
                              id="pane2"
                              className="nextNavButton"
                              onClick={() => {
                                history.push({
                                  pathname: RoutesObj.Dashboard.path,
                                });
                              }}
                            >
                              GO TO DASHBOARD
                            </button>
                          </div>
                        </div>
                      );

                    default:
                      return null;
                  }
                })()}

                {/* REGISTER DEVICES */}

                {this.setState.isDeviceInfo === true && (
                  <>
                    <h1 className="environment">1. Add a Device</h1>
                    <div className="deviceInner">
                      <p>Add a device to your account.</p>
                      {selectedProduct === "" && deviceError ? (
                        <span style={{ color: "red" }}>
                          {"Please select device first"}
                        </span>
                      ) : null}
                      {this.state.deviceButton && (
                        <span className="deviceButtons">
                          {productType &&
                            productType.length > 0 &&
                            productType.map((items, index) => {
                              return (
                                <AddDeviceBox
                                  key={index}
                                  text={items.categoryName}
                                  picture={items.featuredImage}
                                  deviceClick={() => {
                                    this.onDeviceButtonClick(
                                      items.productCategoryId
                                    );
                                    this.setState({ productCategory: items });
                                  }}
                                />
                              );
                            })}
                        </span>
                      )}
                      {console.log("deviceImage", this.state.deviceImage)}
                      {this.state.deviceImage && (
                        <span className="productsPictures">
                          {allProducts && allProducts.length > 0 ? (
                            <>
                              {console.log("allProducts", allProducts)}
                              {allProducts &&
                                allProducts.length > 0 &&
                                allProducts.map((items, index) => {
                                  console.log(
                                    "testig:",
                                    this.checkSelectedProd(items.productId)
                                  );
                                  if (!this.state.step2) {
                                    console.log("statechk", this.state.step2);
                                    return (
                                      <i
                                        key={index}
                                        onClick={() =>
                                          this.onSelectProduct(items)
                                        }
                                      >
                                        <img src={items.featureImage} alt="" />
                                        <div>
                                          <span>{items.productName}</span>
                                          <span>{items.productId}</span>
                                        </div>

                                        {this.checkSelectedProd(
                                          items.productId
                                        ) && (
                                          <span className="selectedProduct">
                                            <RiCheckFill />
                                          </span>
                                        )}
                                      </i>
                                    );
                                  } else if (
                                    this.checkSelectedProd(items.productId) &&
                                    this.state.step2
                                  ) {
                                    console.log(
                                      "statechk====?",
                                      this.state.step2
                                    );

                                    return (
                                      <i
                                        key={index}
                                        onClick={() =>
                                          this.onSelectProduct(items)
                                        }
                                      >
                                        <img src={items.featureImage} alt="" />
                                        <div>
                                          <span>{items.productName}</span>
                                          <span>{items.productId}</span>
                                        </div>

                                        {this.checkSelectedProd(
                                          items.productId
                                        ) && (
                                          <span className="selectedProduct">
                                            <RiCheckFill />
                                          </span>
                                        )}
                                      </i>
                                    );
                                  }
                                })}
                            </>
                          ) : (
                            <span>Products are not available</span>
                          )}

                          <span
                            className="backBtn"
                            onClick={() => this.onBackArroClick()}
                          >
                            <i>
                              <img src={backArrow} alt="" />
                              Back to Devices
                            </i>
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="gap"></div>
                    <div className="gap"></div>
                    {/* <span className="deviceInnerDisabled "> */}
                    {this.state.step2 ? (
                      <>
                        <h1 className="environment">2. Name Device</h1>
                        <div className="deviceInner">
                          <p>
                            Name the selected device
                            <span style={{ color: "red" }}>*</span>
                          </p>
                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            <FloatingLabelInput
                              value={nameDevice}
                              className={
                                nameDevice === "" && deviceError
                                  ? "errorOutline"
                                  : ""
                              }
                              onChange={(e) =>
                                this.setState({ nameDevice: e.target.value })
                              }
                              onBlur={() => this.activeStep3()}
                            />
                          </span>
                        </div>
                      </>
                    ) : null}

                    {/* </span> */}
                    {/* <div className="gap"></div> */}
                    <div className="gap"></div>
                    {/* <span className="deviceInnerDisabled "> */}

                    {this.state.step3 ? (
                      <>
                        <h1 className="environment">3. Register Device</h1>
                        <div className="deviceInner">
                          <p className="groupFields">
                            Registering your device will provide the ability to
                            upgrade and customize your device, priority customer
                            support and an extended warranty.
                          </p>
                          {this.state.selectedProduct?.isSerialized === 1 ? (
                            <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                              <span
                              // className={!isShowSerialNo ? "deviceInnerDisabled" : ""}
                              >
                                <FloatingLabelInput
                                  placeholder="Serial Number"
                                  // disabled={isShowSerialNo}
                                  value={serialNumber}
                                  mandatory
                                  className={
                                    (isShowSerialNo &&
                                      deviceError &&
                                      serialNumber === "") ||
                                    dublicateSerial
                                      ? "errorOutline"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      serialNumber: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => this.isDublicateSerial(e)}
                                />
                                {isShowSerialNo && dublicateSerial ? (
                                  <span style={{ color: "red" }}>
                                    {"Please add serial number"}
                                  </span>
                                ) : null}
                              </span>
                            </span>
                          ) : null}

                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            {/* <FloatingLabelInput placeholder="Purchase Location" value={purchaseLoc} className={purchaseLoc === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ purchaseLoc: e.target.value })} /> */}
                            <label className="purchaseRecWrapper">
                              Purchase Location
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <AutoCompleteLoc
                              label="Purchase Location"
                              value={purchaseLoc}
                              isReg
                              onChange={(e) => {
                                this.changeHandler(e);
                                this.setState({
                                  purchaseLoc: e.target.value,
                                });
                              }}
                              // disabled={!isSelectedDevice ? true : false}
                              className="autoloc"
                              eventLocation={this.state.allDelears}
                              setEvent={(v) => {
                                this.setState({
                                  purchaseLoc: v.value,
                                });
                                this.setState({
                                  dealerId: v.id,
                                });
                                // setError(false);
                              }}
                              // onBlur={() => onCheckLocLength()}
                            />
                            {/* <select
                          name="dealers"
                          id="dealer"
                          value={purchaseLoc}
                          className={
                            deviceError && purchaseLoc === ""
                              ? "errorOutline"
                              : ""
                          }
                          onChange={(e) => this.onSelectDealer(e, allDealers)}
                        >
                          <option value="">Select Dealer</option>
                          {allDealers &&
                            allDealers.length > 0 &&
                            allDealers.map((items, index) => {
                              return (
                                <option key={index} value={items.id}>
                                  {items.value}
                                </option>
                              );
                            })}
                        </select>
                        {deviceError && purchaseLoc === "" ? (
                          <span style={{ color: "red" }}>
                            {"Please select dealer"}
                          </span>
                        ) : null} */}
                          </span>

                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            {/* <FloatingLabelInput placeholder="Purchase date" type="date" value={puchaseDate} className={puchaseDate === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ puchaseDate: e.target.value })} /> */}
                            <label className="purchaseRecWrapper">
                              Purchase Date
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <SingleDatePicker
                              date={puchaseDate !== "" ? puchaseDate : null} // momentPropTypes.momentObj or null
                              onDateChange={(puchaseDate) =>
                                this.setState({ puchaseDate })
                              } // PropTypes.func.isRequired
                              focused={this.state.focused} // PropTypes.bool
                              onFocusChange={({ focused }) =>
                                this.setState({ focused })
                              } // PropTypes.func.isRequired
                              id={"1"} // PropTypes.string.isRequired,
                              numberOfMonths={1}
                              displayFormat="YYYY/MM/DD"
                              placeholder={"YYYY/MM/DD"}
                              isOutsideRange={() => false}
                              hideKeyboardShortcutsPanel={true}
                            />
                            {deviceError && puchaseDate === "" ? (
                              <span style={{ color: "red" }}>
                                {"Please select Date"}
                              </span>
                            ) : null}
                          </span>

                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            {/* <FloatingLabelInput placeholder="Purchase date" type="date" value={puchaseDate} className={puchaseDate === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ puchaseDate: e.target.value })} /> */}
                            <label className="purchaseRecWrapper">
                              Installation Date
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <SingleDatePicker
                              date={
                                installationDate !== ""
                                  ? installationDate
                                  : null
                              } // momentPropTypes.momentObj or null
                              onDateChange={(installationDate) => {
                                if (installationDate >= puchaseDate) {
                                  this.setState({ installationDate });
                                }
                              }} // PropTypes.func.isRequired
                              focused={this.state.installationDateFocused} // PropTypes.bool
                              onFocusChange={({ focused }) =>
                                this.setState({
                                  installationDateFocused: focused,
                                })
                              } // PropTypes.func.isRequired
                              id={"1"} // PropTypes.string.isRequired,
                              numberOfMonths={1}
                              displayFormat="YYYY/MM/DD"
                              placeholder={"YYYY/MM/DD"}
                              isOutsideRange={() => false}
                              hideKeyboardShortcutsPanel={true}
                            />
                            {/* {deviceError && installationDate === "" ? (
                          <span style={{ color: "red" }}>
                            {"Please select installation Date"}
                          </span>
                        ) : null} */}
                          </span>

                          <span className="loginWrapper__loginFormWrapper--inner">
                            {/* <FileUpload /> */}
                            <div className="upload-btn-wrapper">
                              <button className="btn">ATTACH RECEIPT</button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) => this.handleChangeImage(e)}
                              />
                            </div>
                            {recepit !== "" ? (
                              <div className="uploadYhumbnail">
                                <span>
                                  {
                                    <img
                                      src={`${"data:image/png;base64,"}${displayReceipt}`}
                                      alt=""
                                    />
                                  }
                                </span>
                              </div>
                            ) : null}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {/* </span> */}

                    <span className="footerNav ">
                      <button
                        id="pane1"
                        className="prevNavButton"
                        onClick={() =>
                          this.props.actions.onChange("currentPaneId", "pane3")
                        }
                      >
                        PREVIOUS
                      </button>
                      <button
                        id="pane2"
                        className="nextNavButton"
                        onClick={() => this.checkAddDevice()}
                      >
                        NEXT STEP
                      </button>
                    </span>
                    <h className="skipbutton" onClick={this.handleClickSkip}>
                      SKIP STEP
                    </h>
                  </>
                )}
                {/* </Pane> */}

                {/* FINISH SETUP */}

                {/* <Pane id="pane5" title="Finish Setup" index="5"> */}
                {this.setState.isLastStep === false && (
                  <>
                    <span className="footerNav finishSteps">
                      <img className="onBoardingDone" src={finish} alt="" />
                      <h1 className="onBoardinDeviceAddedd">
                        Device added! You’re almost set!{" "}
                      </h1>
                      <p className="pane5 notifications">
                        Select which notifications you want to receive via
                        e-mail or within the Aquatic AV app.
                      </p>
                      <label className="container pane5">
                        <span
                          // href="https://aquaticav.com/terms/"
                          // target="_blank"
                          // rel="noreferrer"
                          style={{
                            color: "#464E5F",
                            fontWeight: 500,
                            fontSize: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "-3%",
                            // textDecoration: "underline",
                            // marginLeft: "25px",
                          }}
                        >
                          New AAV Deals
                        </span>
                        <input
                          type="checkbox"
                          id="aavdeals"
                          value={isAAVDeals}
                          checked={isAAVDeals ? true : false}
                          onChange={(e) => {
                            this.setState({
                              isAAVDeals: !isAAVDeals,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="container pane5">
                        <span
                          // href="https://aquaticav.com/terms/"
                          // target="_blank"
                          // rel="noreferrer"
                          style={{
                            color: "#464E5F",
                            fontWeight: 500,
                            fontSize: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // marginLeft:"-2%",
                          }}
                        >
                          Support Messages
                        </span>
                        <input
                          type="checkbox"
                          id="aavdeals"
                          value={isSupportMessages}
                          checked={isSupportMessages ? true : false}
                          onChange={(e) => {
                            this.setState({
                              isSupportMessages: !isSupportMessages,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <div className="previousButton">
                        <button
                          id="pane1"
                          className="dashboardLink"
                          onClick={() => this.handleDashboard()}
                        >
                          go to dashboard
                        </button>
                      </div>

                      {/* <Link to="/dashboard" id="pane1" className="dashboardLink">
                  go to dashboard
                </Link> */}
                    </span>
                  </>
                )}
                {/* </Pane> */}
                {/* </TabNav> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
    deviceAction: bindActionCreators(deviceOverviewAction, dispatch),
  })
)(withRouter(ProductRegistration));
