import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  waterlife,
  alertBulb,
  Alert,
  waterDrop,
} from "../../../resources/images";
import { useSelector } from "react-redux";

export default function WaterLifeCard({ waterlifemanage }) {
  const { waterLifeStats } = useSelector((state) => state.SpaOverviewReducer);
  return (
    <div onClick={waterlifemanage} style={{ cursor: "pointer" }}>
      <Grid
        item
        md={12}
        className="gridMainSpaWrapper "
        style={{ marginTop: "1rem" }}
      >
        {/* {isLoading ? <Loader /> : null} */}
        <Grid item md={12} style={{ background: "#fff", borderRadius: "12px" }}>
          {/* <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperHeader"
          style={{ padding: "0" }}
        >
          <Grid item md={12} className="gridRow">
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading px-3 filterLifeHeader"
            >
              <span item md={6} className="heading headingFilter">
                Water Life
              </span>
              <span
                item
                md={6}
                className="settingChange"
                onClick={waterlifemanage}
              >
                MANAGE
              </span>
            </Grid>
          </Grid>
        </Grid> */}
          <Grid
            item
            md={12}
            className="gridSpaPumpsWrapperBody"
            style={{ padding: "20px " }}
          >
            <Grid
              item
              md={12}
              className="gridRow"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                md={12}
                xs={12}
                className="gridSpaPumpsControlItem watertreatmentLeftWrapper"
              >
                <Grid item md={7}>
                  <div className="filterLifeInnerWrapper detailInnerFilter">
                    <span>
                      <img src={waterDrop} alt="" />
                    </span>

                    <span>
                      <div className="bigTitle">
                        {waterLifeStats?.replacedDays}{" "}
                        {waterLifeStats?.replacedDays > 1 ? "Days" : "Day"}
                      </div>
                      <div className="filterLifeSubHeading">
                        since water has been replaced.
                      </div>
                      <div className="filterLifeCardText">
                        Recommend Replacement:
                      </div>
                      <div
                        className="filterLifeCardDate"
                        style={{ color: "#EB5757" }}
                      >
                        <span style={{ fontStyle: "italic", color: "#EB5757" }}>
                          {Math.abs(waterLifeStats?.recommendedReplaceDays) !==
                          1
                            ? `${waterLifeStats?.recommendedReplaceDays} days away`
                            : `${waterLifeStats?.recommendedReplaceDays} day away`}
                        </span>{" "}
                        |{" "}
                        {waterLifeStats?.recommendedReplacingDate
                          ? waterLifeStats?.recommendedReplacingDate
                          : " September 1, 2021"}
                      </div>

                      <div
                        style={{ marginTop: 15 }}
                        className="filterLifeCardText"
                      >
                        Last Refill:
                      </div>
                      <span
                        style={{
                          color: "#8D99A5",
                          fontSize: 15,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        {waterLifeStats?.lastRefil
                          ? waterLifeStats?.lastRefil
                          : "April 1, 2023"}
                      </span>

                      <div
                        style={{ marginTop: 15 }}
                        className="filterLifeCardText"
                      >
                        Total Gallons used since ownership:
                      </div>
                      <span
                        style={{
                          color: "#8D99A5",
                          fontSize: 15,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        {waterLifeStats?.totalGallons + " gallons"}
                      </span>
                    </span>
                  </div>
                </Grid>

                <Grid
                  item
                  md={5}
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="lifeTip"
                    style={{ width: "80%", height: 150 }}
                  >
                    <span className="tipHeading">
                      Chemical Testing Tip <img src={alertBulb} alt="" />
                    </span>
                    <p className="chemicalTextNote">
                      Extend water life by keeping your chemicals in the green
                      and refilling your water every four months.
                    </p>
                  </span>

                  <span
                    className="lifeTip"
                    style={{
                      width: "80%",
                      height: 130,
                      marginTop: 10,
                      backgroundColor: "rgba(249, 184, 184, 0.25)",
                    }}
                  >
                    <span className="tipHeading">
                      Alert <img src={Alert} alt="" />
                    </span>
                    <p className="chemicalTextNote">
                      Replace water immediately to keep water health good.
                    </p>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
