import React, { useState, useEffect } from "react";

import { Loader, FloatingLabelInput } from "../../../components/common/index";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { toast } from "react-toastify";

function TransferDevice({ onLoadCompleteData, setIsTranferModelShow }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [tranfer, setTranfer] = useState("");
  const [tranferError, setTranferError] = useState(false);

  const { deviceOverviewData } = useSelector(
    (state) => state.DeviceOverviewReducer
  );

  const onFocusOutFun = (value) => {
    if (
      value !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      setTranferError(true);
    }
  };

  const checkTransfer = () => {
    if (tranfer !== "" && deviceOverviewData.id) {
      onTransferDevice();
    } else {
      setTranferError(true);
    }
  };

  const onTransferDevice = () => {
    // setIsLoading(true);
    const deviceData = {
      email: tranfer,
      deviceId: deviceOverviewData.id,
    };
    dispatch(dashboardActions.onTransferDevice(deviceData))?.then((data) => {
      if (data?.status === 200 && data?.data?.success) {
        setIsLoading(false);
        // onLoadCompleteData();
        toast.success(
          "The invitation for the device ownership has been transferred successfully"
        );
        setIsTranferModelShow(false);
      } else if (data?.data?.message) {
        setIsLoading(false);
        toast.error(data?.data?.message);
      } else {
        setIsLoading(false);
        toast.error("Something went wrong please try again");
      }
    });
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="transferDeviceMobile"
      >
        <h1 className="ownerEmail">New Owner’s Email</h1>
        <input
          placeholder="Email"
          value={tranfer}
          className={tranferError ? "errorOutline" : "transferOwnerInputMobile"}
          onBlur={() => onFocusOutFun(tranfer)}
          onChange={(e) => {
            setTranfer(e.target.value);
            setTranferError(false);
          }}
          style={{
            width: "70%",
            borderRadius: "5px",
            height: "42px",
            border: "none",
            background: "#F5F8FA",
            padding: "10px",
          }}
        />
        {tranferError ? (
          <span style={{ color: "red", display: "flex" }}>
            {tranferError !== "" ? "Invalid Email" : ""}
          </span>
        ) : null}
      </div>

      <span
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        <button
          id="pane2"
          className=""
          style={{
            marginRight: "30px",
            background: "#F5F8FA",
            color: "#7E8299",
            fontWeight: "700",
            fontSize: "16px",
            width: "98px",
            height: "48px",
          }}
          onClick={() => setIsTranferModelShow(false)}
        >
          Cancel
        </button>
        <button
          id="pane2"
          className=""
          onClick={() => checkTransfer()}
          style={{
            marginRight: "30px",
            background: "#1BBED0",
            color: "#fff",
            fontWeight: "700",
            fontSize: "16px",
            width: "109px",
            height: "48px",
          }}
        >
          Transfer
        </button>
      </span>
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <p
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "600",
            color: "#A7A8BB",
          }}
          className="needHelpMobile"
        >
          Need help?
        </p>
        <p
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "600",
            color: "#1BBED0",
          }}
          className="needHelpMobile"
        >
          Contact Aquatic AV Support
        </p>
      </span>
    </div>
  );
}

export default TransferDevice;
