import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import "./OwnedSpa.css";
import { spaVector } from "../../../resources/images";
import { arrowUp, spas } from "../../../assets/images/images";
export default function OwnedSpa({
  handleOpenOwnedSpaModal,
  ownSpa,
  setGetOwnSpaid,
  handleOwnDeviceClick,
  spaDeviceId
}) {
  const [selectedSpa, setSelectedSpa] = useState(spaDeviceId);
  const handleSelectedSpa = (tab) => {
    setSelectedSpa(tab);
    handleOwnDeviceClick(tab);
  };
  // console.log( handleOwnDeviceClick, "handleOwnDeviceClick")
  console.log(ownSpa?.slice(0, 3),"ownSpa?.slice(0, 3)")
  return (
    <div className="mt-3 px-3">
      <div className="mainTitle spaTitle">
        <span>Owned Spas</span>
      </div>
      {ownSpa?.slice(0, 3).map((item, index) => (
        <Grid
          key={index} // Make sure to provide a unique key for each item
          item
          md={12}
          className={`gridOwnedSpaWrapper ${
            selectedSpa === item.id ? "activeSpa" : ""
          }`}
          onClick={() => {
            handleSelectedSpa(item.id);
            setGetOwnSpaid(item.id);
          }}
          style={{ marginBottom: "15px" }}
        >
          <div className="ownedSpaInear">
            <img src={spaVector} alt="frame" width={60} height={60} />
            <div style={{ width: "100%" }}>
              <div className="bottomBorder">
                <h4 className="">{item?.name}</h4>
                <p className="">
                  {item?.spaModel} {item?.spaBrand}
                </p>
              </div>
              <div className="environmentHeading mt-2">
                <p>Environment</p>
                <h4>{item?.environment}</h4>
              </div>
            </div>
          </div>
        </Grid>
      ))}

      {ownSpa.length > 3 && (
        <Grid
          item
          md={12}
          className="gridOwnedSpaWrapper
mt-3"
        >
          <div className="ownedSpaInear" onClick={handleOpenOwnedSpaModal}>
            <img src={spas} alt="frame" width={60} height={60} />
            <div style={{ width: "100%" }}>
              <div className="spaLists">
                <h4 className="">View All Owned Spas</h4>
                <img src={arrowUp} alt="" width={9.9} height={16.7} />
              </div>
            </div>
          </div>
        </Grid>
      )}
    </div>
  );
}
