// import * as mqtt from "mqtt/dist/mqtt.min";
import mqtt from "mqtt";

// HTTP
// const options = {
//   // Clean session
//   clean: true,
//   connectTimeout: 5000,
//   // Authentication
//   clientId: `aav_${Math.random().toLocaleString(6)}`,
//   username: "admin1",
//   password: "admin1",
//   host: "172.170.254.193",
//   port: 9001,
//   protocol: "ws",
// };

// const url = "ws://172.170.254.193:9001/ws";

// const mqttClient = mqtt.connect(url, options);

// HTTPS

const options = {
  // Clean session
  clean: true,
  connectTimeout: 5000,
  // Authentication
  clientId: `aav_${Math.random().toLocaleString(6)}`,
  username: "admin1",
  password: "admin1",
  host: "rabitmq.aquaticav.com",
  // host: "172.170.254.193",
  port: 2087,
  protocol: "wss",
};

const url = "wss://rabitmq.aquaticav.com:2087/ws";
// const url = "/172.170.254.193";

const mqttClient = mqtt.connect(url, options);

export default mqttClient;
