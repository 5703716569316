import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";
var sha512 = require("js-sha512");

async function getData(email, password) {
  const userIp = localStorage.getItem("userIpAddress");
  var data = JSON.stringify({
    user_email: email,
    password: password,
    device_info: "Web",
    is_login: 0,
    device_val: userIp,
  });
  var config = {
    method: "post",
    url: endpoints.accounts.login,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.user_info.user_exist === "True"
      ) {
        localStorage.setItem("access_token", response.data.user_info.token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify(response.data.user_info)
        );
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function updatePersonal(reqObj) {
  let encPass = "";
  reqObj.password ? (encPass = sha512(reqObj.password)) : (encPass = "");
  let data = JSON.stringify(reqObj);

  return axios
    .post(endpoints.dashboard.updatePersonalInfo, data)
    .then((response) => {
      if (response.status === 200 && response.data.resp_msg === "True") {
        localStorage.setItem("userEmail", reqObj.email);
        encPass !== "" && localStorage.setItem("password", encPass);
        // localStorage.setItem("passwordDec", password);
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function updateAddress(
  address,
  city,
  stateCode,
  postalCode,
  country,
  phoneNumber
) {
  const userEmail = localStorage.getItem("userEmail");
  var data = JSON.stringify({
    city: city,
    address: address,
    zip_code: postalCode,
    phone: phoneNumber,
    state: stateCode,
    country: country,
    user_email: userEmail,
  });
  var config = {
    method: "post",
    url: endpoints.dashboard.updateAddress,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getEnvironment(email) {
  return axios
    .get(`${endpoints.dashboard.getEnvironment}${"user_email="}${email}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "X-Requested-With, content-type",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}

async function addDevice(
  envName,
  envIcon,
  deviceName,
  serialNo,
  purchaseLoc,
  purchaseDate,
  selectedProduct,
  recepit,
  dealerId
) {
  const userEmail = localStorage.getItem("userEmail");
  var data = JSON.stringify({
    user_email: userEmail,
    env_name: envName,
    device_name: deviceName,
    env_icon_name: envIcon,
    modified_by: null,
    created_by: null,
    product_type_id: selectedProduct.pTId,
    product_id: selectedProduct.pId,
    serial_no: serialNo,
    purchase_location: purchaseLoc,
    purchase_date: purchaseDate,
    receipt: recepit,
    purchase_location_dealer_id: dealerId,
  });
  var config = {
    method: "post",
    url: endpoints.dashboard.addDevice,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getAllProductType() {
  return axios
    .post(`${endpoints.products.getAllProductType}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}

async function getAllCities() {
  return axios
    .post(`${endpoints.lookups.getCities}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}
async function getAllStates() {
  return axios
    .post(`${endpoints.lookups.getStates}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}
async function getAllCountries() {
  return axios
    .post(`${endpoints.lookups.getCountries}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}

async function getProducts(productId) {
  return axios
    .post(`${endpoints.products.getProduct}?id=${productId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function allDealers(categoryId) {
  return axios
    .post(`${endpoints.dealerLocator.getUserDelears}`, {
      categoryId: categoryId,
      input: "",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}

async function getUserDevices(userId, type) {
  let data = JSON.stringify({
    userId: userId,
    // type:[type]
  });
  return axios
    .post(endpoints.device.getUserDevices, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getDeviceOverviewInfo(deviceId) {
  let data = JSON.stringify({
    id: deviceId,
    // type:[type]
  });
  return axios
    .post(endpoints.device.getDeviceOverviewInfo, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getUserData(userId) {
  return axios
    .get(`${endpoints.accounts.getUserInfo}${userId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function updateDeviceName(dName, dId) {
  let data = JSON.stringify({
    name: dName,
    deviceId: dId,
  });

  return axios
    .post(endpoints.device.updateDeviceName, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function updateDeviceTopside(dName, topsideId) {
  let data = JSON.stringify({
    name: dName,
    id: topsideId,
  });

  return axios
    .post(endpoints.spaDevice.updateDeviceTopside, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function updateDevicePack(dName, packId) {
  let data = JSON.stringify({
    name: dName,
    id: packId,
  });

  return axios
    .post(endpoints.spaDevice.updateDevicePack, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function updateDeviceGateway(dName, gatewayId) {
  let data = JSON.stringify({
    name: dName,
    id: gatewayId,
  });

  return axios
    .post(endpoints.spaDevice.updateDeviceGateway, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function updateDeviceinfo(devInfo) {
  return axios
    .post(
      endpoints.device.updateDeviceInfo,
      JSON.stringify({
        ...devInfo,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function updateDeviceEnv(envName, deviceId, envIcon) {
  const userEmail = localStorage.getItem("userEmail");
  var data = JSON.stringify({
    env_name: envName,
    device_id: deviceId,
    modified_by: null,
    env_icon_name: envIcon,
    user_email: userEmail,
  });
  var config = {
    method: "post",
    url: endpoints.device.updateDeviceEnv,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function replaceImage(deviceId, receipt) {
  var data = JSON.stringify({
    id: deviceId,
    receipt: receipt,
    basePath: "",
  });
  var config = {
    method: "post",
    url: endpoints.device.replaceImage,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getDeviceInfo(userid, envName) {
  return axios
    .post(
      endpoints.dashboard.getDeviceInfo,
      JSON.stringify({
        userId: userid,
        environmentId: envName,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function isDublicateSerial(serial) {
  return axios
    .post(
      endpoints.dashboard.dublicateSerial,
      JSON.stringify({
        serialNo: serial,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getAllEnv(userId) {
  return axios
    .post(
      endpoints.dashboard.getAllEnv,
      JSON.stringify({
        types: ["S", "D"],
        userId: userId,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getStaticEnv() {
  return axios
    .post(endpoints.dashboard.getStaticEnv)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function addDashboardDevice(deviceData) {
  return axios
    .post(endpoints.dashboard.addDashboardDevice, JSON.stringify(deviceData))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function addDashboardEnv(req) {
  return axios
    .post(endpoints.dashboard.addDashboardEnv, JSON.stringify(req))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getDashboardData(userid, userEnv) {
  return axios
    .post(
      endpoints.dashboard.getDeviceInfo,
      JSON.stringify({
        userId: userid,
        environmentId: userEnv,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function uploadImage(imageFile) {
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  var config = {
    method: "post",
    url: endpoints.dashboard.uploadImage,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function uploadReceiptImage(imageFile) {
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  var config = {
    method: "post",
    url: endpoints.dashboard.uploadReceiptImage,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getMyEnv(userId) {
  return axios
    .post(
      endpoints.dashboard.getMyEnv,
      JSON.stringify({
        // userid: userId, // old key for userID
        userId: userId,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getComapnyEnv(companyId) {
  return axios
    .post(
      endpoints.dashboard.getComapnyEnv,
      JSON.stringify({
        // userid: userId, // old key for userID
        id: companyId,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function updateMyDeviceEnv(deviceId, envName, envIconName, envId) {
  const userEmail = localStorage.getItem("userEmail");
  const userId = localStorage.getItem("userId");
  return axios
    .post(
      endpoints.device.updateMyDevice,
      JSON.stringify({
        device_id: deviceId,
        env_name: envName,
        env_icon_name: envIconName,
        modified_by: userEmail,
        user_email: userEmail,
        user_id: parseInt(userId),
        env_id: envId,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function changeEnvName(item, envName) {
  const userId = localStorage.getItem("userId");
  return axios
    .post(
      endpoints.dashboard.addDashboardEnv,
      JSON.stringify({
        environmentId: item.id,
        environmentName: envName,
        environmentType: item.type,
        environmentIcon: item?.icon ? item?.icon : item?.iconName,
        status: 1,
        userId: userId,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function transferDevice(req) {
  return axios
    .post(endpoints.dashboard.tranferDevice, JSON.stringify(req))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getTransferDevices(user_id) {
  let req = {
    userId: user_id,
  };
  return axios
    .post(endpoints.dashboard.viewtranferDevices, JSON.stringify(req))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function rejectOwnershipDevice(id) {
  let req = {
    id: id,
  };
  return axios
    .post(endpoints.dashboard.rejectDeviceOnwership, JSON.stringify(req))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function acceptOwnershipDevice(req) {
  return axios
    .post(endpoints.dashboard.claimDeviceOnwership, JSON.stringify(req))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function fetchNotification(req) {
  return axios
    .post(endpoints.dashboard.fetchNotifications, JSON.stringify(req))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getNotificationByPortalID({ portalId }) {
  return axios
    .post(`${endpoints.dashboard.getNotificationReleases}/${portalId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function markNotificationRead({ notificationId }) {
  return axios
    .post(`${endpoints.dashboard.markNotificationRead}/${notificationId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getCompanyDevices(companyId) {
  return axios
    .post(endpoints.dashboard.getCompanyDevices, { id: companyId })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function toMarkUnMarkPinEnv(req) {
  return axios
    .post(endpoints.dashboard.toMarkUnMarkPinEnv, JSON.stringify(req))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getPinnedEnviorments() {
  return axios
    .get(endpoints.dashboard.getPinnedEnviornments)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function deleteEnvironment(id) {
  return axios
    .post(endpoints.dashboard.deleteEnvironment, id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function deleteDevice(id) {
  return axios
    .post(endpoints.dashboard.deleteDevice, id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function deleteTopsideDevice(id) {
  return axios
    .delete(`${endpoints.spaDevice.deleteTopsideDevice}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function deletePackDevice(id) {
  return axios
    .delete(`${endpoints.spaDevice.deletePackDevice}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function deleteGateway(id) {
  return axios
    .delete(`${endpoints.spaDevice.deleteGatewayDevice}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getCustomEnvIcons() {
  return axios
    .get(endpoints.dashboard.getEnvIcons)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getUserSpaDevices(userId, type) {
  let data = JSON.stringify({
    userId: userId,
    type: [type],
  });
  return axios
    .post(endpoints.spaDevice.getUserSpaDevices, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function getOwnedSpaDevices(userId, type) {
  let data = JSON.stringify({
    userId: userId,
    type: [type],
  });
  return axios
    .post(endpoints.spaDevice.getOwnedSpaDevices, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getConnectedDevices() {
  return axios
    .post(endpoints.spaDevice.getConnectedDevices)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getSoftwareReleaseProductOverview(productId) {
  return axios
    .post(`${endpoints.device.getSoftwareReleaseProductOverview}${productId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const dashboardApi = {
  getData: getData,
  getUserData: getUserData,
  updatePersonal: updatePersonal,
  updateAddress: updateAddress,
  getDeviceOverviewInfo: getDeviceOverviewInfo,
  getEnvironment: getEnvironment,
  addDevice: addDevice,
  getAllProductType: getAllProductType,
  getProducts: getProducts,
  allDealers: allDealers,
  updateDeviceName: updateDeviceName,
  updateDeviceinfo: updateDeviceinfo,
  updateDeviceEnv: updateDeviceEnv,
  replaceImage: replaceImage,
  getDeviceInfo: getDeviceInfo,
  isDublicateSerial: isDublicateSerial,
  getAllEnv: getAllEnv,
  addDashboardDevice: addDashboardDevice,
  addDashboardEnv: addDashboardEnv,
  getDashboardData: getDashboardData,
  uploadImage: uploadImage,
  uploadReceiptImage: uploadReceiptImage,
  getMyEnv: getMyEnv,
  updateMyDeviceEnv: updateMyDeviceEnv,
  changeEnvName: changeEnvName,
  getUserDevices: getUserDevices,
  getOwnedSpaDevices: getOwnedSpaDevices,
  getAllCities: getAllCities,
  getAllStates: getAllStates,
  getAllCountries: getAllCountries,
  transferDevice: transferDevice,
  getTransferDevices: getTransferDevices,
  rejectOwnershipDevice: rejectOwnershipDevice,
  acceptOwnershipDevice: acceptOwnershipDevice,
  fetchNotification: fetchNotification,
  getNotificationByPortalID,
  markNotificationRead,
  getComapnyEnv,
  getCompanyDevices,
  getStaticEnv,
  toMarkUnMarkPinEnv,
  getPinnedEnviorments,
  deleteEnvironment: deleteEnvironment,
  deleteDevice: deleteDevice,
  getCustomEnvIcons,
  deleteTopsideDevice: deleteTopsideDevice,
  updateDeviceTopside: updateDeviceTopside,
  updateDevicePack: updateDevicePack,
  updateDeviceGateway: updateDeviceGateway,
  deletePackDevice: deletePackDevice,
  deleteGateway: deleteGateway,
  getUserSpaDevices,
  getConnectedDevices,
  getSoftwareReleaseProductOverview,
};
