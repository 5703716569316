export const accountTypes = {
    ACCOUNT_LOGIN_ONCHANGE: 'ACCOUNT_LOGIN_ONCHANGE',
    ACCOUNT_LOGIN_SUCCESS: 'ACCOUNT_LOGIN_SUCCESS',
    ACCOUNT_LOGIN_ERROR: 'ACCOUNT_LOGIN_ERROR',
    ACCOUNT_LOGOUT_SUCCESS: 'ACCOUNT_LOGOUT_SUCCESS',
    ACCOUNT_REGISTER_SUCCESS: 'ACCOUNT_REGISTER_SUCCESS',
    ACCOUNT_REGISTER_ERROR: 'ACCOUNT_REGISTER_ERROR',
    ACCOUNT_REGISTER_EMAIL_CHANGE: 'ACCOUNT_REGISTER_EMAIL_CHANGE',
    ACCOUNT_REGISTER_FIELD_CHANGE: 'ACCOUNT_REGISTER_FIELD_CHANGE',
    BULLET_TRAIN_FLAGS: 'BULLET_TRAIN_FLAGS'
};