export const TempratureIcon1 = () => {
  return (
    <svg
      width="34"
      height="27"
      viewBox="1 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8424 13.6875C25.3947 13.6875 25.8424 14.1352 25.8424 14.6875C25.8424 22.4058 20.148 27.6091 13.1521 27.6091C7.54835 27.6091 2.88185 24.6171 1.24416 19.7766C1.06716 19.2535 1.34777 18.6859 1.87092 18.5089C2.39408 18.3319 2.96166 18.6125 3.13867 19.1356C4.45752 23.0337 8.26011 25.6091 13.1521 25.6091C19.0697 25.6091 23.8424 21.2751 23.8424 14.6875C23.8424 14.1352 24.2901 13.6875 24.8424 13.6875Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6905 3.76604C6.77294 3.76604 2.00024 8.10001 2.00024 14.6876C2.00024 15.2399 1.55252 15.6876 1.00024 15.6876C0.447952 15.6876 0.000236512 15.2399 0.000236512 14.6876C0.000236512 6.96932 5.69467 1.76604 12.6905 1.76604C18.2955 1.76604 22.9608 4.75811 24.5985 9.59849C24.7755 10.1216 24.4949 10.6892 23.9717 10.8662C23.4485 11.0432 22.881 10.7626 22.704 10.2395C21.3851 6.34134 17.5836 3.76604 12.6905 3.76604Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.22225 19.2139C1.3562 18.6781 1.89913 18.3523 2.43493 18.4863L7.20355 19.6784C7.73935 19.8124 8.06511 20.3553 7.93116 20.8911C7.79721 21.4269 7.25428 21.7527 6.71848 21.6187L2.92 20.6691L1.97038 24.4676C1.83643 25.0034 1.2935 25.3291 0.757701 25.1952C0.221906 25.0612 -0.103855 24.5183 0.0300941 23.9825L1.22225 19.2139Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0859 4.18044C25.6217 4.31439 25.9475 4.85733 25.8135 5.39312L24.6214 10.1617C24.4874 10.6975 23.9445 11.0233 23.4087 10.8894L18.6401 9.6972C18.1043 9.56325 17.7785 9.02031 17.9124 8.48452C18.0464 7.94872 18.5893 7.62296 19.1251 7.75691L22.9236 8.70653L23.8732 4.90805C24.0072 4.37225 24.5501 4.04649 25.0859 4.18044Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36879 11.7545C6.5332 11.4027 6.88642 11.1779 7.27475 11.1779H17.9414C18.3297 11.1779 18.683 11.4027 18.8474 11.7545C19.0118 12.1063 18.9576 12.5215 18.7085 12.8194L14.863 17.4179V20.021C14.863 20.3998 14.649 20.746 14.3102 20.9154L11.8004 22.1703C11.4904 22.3253 11.1223 22.3088 10.8274 22.1265C10.5326 21.9443 10.3532 21.6225 10.3532 21.2759V17.4179L6.50763 12.8194C6.25851 12.5215 6.20438 12.1063 6.36879 11.7545ZM9.41459 13.1779L12.1203 16.4134C12.2707 16.5933 12.3532 16.8203 12.3532 17.0549V19.6579L12.863 19.403V17.0549C12.863 16.8203 12.9454 16.5933 13.0959 16.4134L15.8016 13.1779H9.41459Z"
        fill="#219653"
      />
      <path
        d="M10.0002 9.62883C10.0002 10.1811 9.55252 10.6288 9.00024 10.6288C8.44795 10.6288 8.00024 10.1811 8.00024 9.62883C8.00024 9.07655 8.44795 8.62883 9.00024 8.62883C9.55252 8.62883 10.0002 9.07655 10.0002 9.62883Z"
        fill="#219653"
      />
      <path
        d="M13.137 9.62883C13.137 10.1811 12.6892 10.6288 12.137 10.6288C11.5847 10.6288 11.137 10.1811 11.137 9.62883C11.137 9.07655 11.5847 8.62883 12.137 8.62883C12.6892 8.62883 13.137 9.07655 13.137 9.62883Z"
        fill="#219653"
      />
      <path
        d="M14.0002 23.6288C14.0002 24.1811 13.5525 24.6288 13.0002 24.6288C12.448 24.6288 12.0002 24.1811 12.0002 23.6288C12.0002 23.0765 12.448 22.6288 13.0002 22.6288C13.5525 22.6288 14.0002 23.0765 14.0002 23.6288Z"
        fill="#219653"
      />
      <path
        d="M16.2737 9.62883C16.2737 10.1811 15.826 10.6288 15.2737 10.6288C14.7214 10.6288 14.2737 10.1811 14.2737 9.62883C14.2737 9.07655 14.7214 8.62883 15.2737 8.62883C15.826 8.62883 16.2737 9.07655 16.2737 9.62883Z"
        fill="#219653"
      />
      <path
        d="M30.6478 0.753038C30.6478 0.635164 30.601 0.522118 30.5176 0.438768C30.4343 0.355419 30.3212 0.308594 30.2034 0.308594C30.0855 0.308594 29.9724 0.355419 29.8891 0.438768C29.8057 0.522118 29.7589 0.635164 29.7589 0.753038C29.7589 1.39482 29.6705 2.29793 29.2425 3.02815C28.8363 3.72015 28.0967 4.30859 26.6478 4.30859C26.5299 4.30859 26.4169 4.35542 26.3335 4.43877C26.2502 4.52212 26.2034 4.63516 26.2034 4.75304C26.2034 4.87091 26.2502 4.98396 26.3335 5.06731C26.4169 5.15066 26.5299 5.19748 26.6478 5.19748C27.238 5.19748 28.0176 5.37659 28.6416 5.89526C29.2487 6.39882 29.7589 7.26637 29.7589 8.75304C29.7589 8.87091 29.8057 8.98396 29.8891 9.06731C29.9724 9.15066 30.0855 9.19748 30.2034 9.19748C30.3212 9.19748 30.4343 9.15066 30.5176 9.06731C30.601 8.98396 30.6478 8.87091 30.6478 8.75304C30.6478 8.11126 30.7363 7.20815 31.1643 6.47793C31.5705 5.78593 32.31 5.19748 33.7589 5.19748C33.8768 5.19748 33.9898 5.15066 34.0732 5.06731C34.1565 4.98396 34.2034 4.87091 34.2034 4.75304C34.2034 4.63516 34.1565 4.52212 34.0732 4.43877C33.9898 4.35542 33.8768 4.30859 33.7589 4.30859C33.1687 4.30859 32.3891 4.12948 31.7651 3.61082C31.158 3.10726 30.6478 2.2397 30.6478 0.753038ZM30.0087 3.47793C30.0918 3.33659 30.1643 3.19126 30.2278 3.04459C30.4566 3.52657 30.7874 3.95313 31.1971 4.29482C31.3945 4.45882 31.602 4.59615 31.814 4.70993C31.1718 5.02104 30.7136 5.48948 30.398 6.02815C30.3161 6.16793 30.2429 6.31265 30.1789 6.46148C29.9501 5.97952 29.6193 5.55296 29.2096 5.21126C29.0183 5.05268 28.8116 4.91362 28.5927 4.79615C29.2349 4.48504 29.6927 4.01659 30.0087 3.47793Z"
        fill="#219653"
      />
    </svg>
  );
};

export const TempratureIcon2 = () => {
  return (
    <svg
      width="34"
      height="27"
      viewBox="1 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 17.3086C14.7614 17.3086 17 15.07 17 12.3086C17 9.54717 14.7614 7.30859 12 7.30859C9.23858 7.30859 7 9.54717 7 12.3086C7 15.07 9.23858 17.3086 12 17.3086Z"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1.30859V3.30859"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1016 9.97559V11.9756"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5703 13.333L12.1011 11.9761"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.3086V23.3086"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.21875 4.52832L5.63875 5.94832"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3594 18.6689L19.7794 20.0889"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 12.3086H3"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12.3086H23"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.21875 20.0889L5.63875 18.6689"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3594 5.94832L19.7794 4.52832"
        stroke="#49B8EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TempratureIcon3 = () => {
  return (
    <svg
      width="34"
      height="27"
      viewBox="0 0 17 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72573 6.08286H10.7733C10.7733 6.08271 10.7733 6.08256 10.7733 6.0824C10.7733 4.13626 9.19569 2.55859 7.24954 2.55859C5.30339 2.55859 3.72573 4.13626 3.72573 6.0824C3.72573 6.08256 3.72573 6.08271 3.72573 6.08286ZM1.72576 6.10151C1.72574 6.09514 1.72573 6.08877 1.72573 6.0824C1.72573 3.03169 4.19882 0.558594 7.24954 0.558594C10.3003 0.558594 12.7733 3.03169 12.7733 6.0824C12.7733 6.08873 12.7733 6.09506 12.7733 6.10138C13.7486 6.23466 14.5 7.07101 14.5 8.08286V18.5829C14.5 19.6874 13.6046 20.5829 12.5 20.5829H2C0.895431 20.5829 0 19.6874 0 18.5829V8.08286C0 7.07132 0.750947 6.23518 1.72576 6.10151ZM7.21368 11.6061C6.27874 11.6173 5.52427 12.3786 5.52427 13.3162C5.52427 14.0283 5.95946 14.6388 6.57837 14.8961V17.1527H7.8988V14.8927C8.51341 14.6334 8.9448 14.0252 8.9448 13.3162C8.9448 12.381 8.19414 11.6211 7.26247 11.6062C7.25816 11.6062 7.25385 11.6062 7.24954 11.6062C7.23758 11.6062 7.22563 11.6062 7.21368 11.6061Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
export const OpenLockIcon = () => {
  return (
    <svg
         width="34"
      height="27"
      viewBox="0 0 17 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 10C2.67157 10 2 10.6716 2 11.5V17C2 18.6569 3.34315 20 5 20H19C20.6569 20 22 18.6569 22 17V11.5C22 10.6716 21.3284 10 20.5 10H18V7C18 5.89543 17.1046 5 16 5C14.8954 5 14 5.89543 14 7V10H10V7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7V10H3.5ZM4 11.5C4 11.2239 4.22386 11 4.5 11C4.77614 11 5 11.2239 5 11.5V16.5C5 16.7761 4.77614 17 4.5 17C4.22386 17 4 16.7761 4 16.5V11.5ZM16 7V10H8V7C8 6.44772 8.44772 6 9 6H15C15.5523 6 16 6.44772 16 7ZM16 11H9V16H15V11H16Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export const PlayerNext = () => {
  return (
    <svg
      width="40"
      height="25"
      viewBox="0 0 40 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8352 1.8125L37.9595 12.2631L21.8352 22.7137L21.8352 1.8125Z"
        fill="#49B8EF"
      />
      <path
        d="M2.12891 1.8125L18.2532 12.2631L2.12891 22.7137L2.12891 1.8125Z"
        fill="#49B8EF"
      />
      <path
        d="M21.8352 1.8125L37.9595 12.2631L21.8352 22.7137L21.8352 1.8125Z"
        stroke="#49B8EF"
        strokeWidth="2.98588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.12891 1.8125L18.2532 12.2631L2.12891 22.7137L2.12891 1.8125Z"
        stroke="#49B8EF"
        strokeWidth="2.98588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PlayerPrevious = () => {
  return (
    <svg
      width="40"
      height="25"
      viewBox="0 0 40 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5114 22.7137L2.38281 12.2631L18.5114 1.8125V22.7137Z"
        fill="#49B8EF"
      />
      <path
        d="M38.2134 22.7137L22.0848 12.2631L38.2134 1.8125V22.7137Z"
        fill="#49B8EF"
      />
      <path
        d="M18.5114 22.7137L2.38281 12.2631L18.5114 1.8125V22.7137Z"
        stroke="#49B8EF"
        strokeWidth="2.98588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.2134 22.7137L22.0848 12.2631L38.2134 1.8125V22.7137Z"
        stroke="#49B8EF"
        strokeWidth="2.98588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PumpOne = () => {
  return (
    <svg
      width="32"
      height="29"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58007 24.4509H3.53025C1.59431 24.4509 0 22.8566 0 20.9207V7.93845C0 6.00251 1.59431 4.4082 3.53025 4.4082H5.69395C7.51601 4.4082 9.11032 6.00251 9.11032 7.93845V21.0345C9.11032 22.8566 7.51601 24.4509 5.58007 24.4509ZM3.53025 6.23027C2.61922 6.23027 1.82206 7.02742 1.82206 7.93845V21.0345C1.82206 21.9456 2.61922 22.7427 3.53025 22.7427H5.69395C6.60498 22.7427 7.40213 21.9456 7.40213 21.0345V7.93845C7.40213 7.02742 6.60498 6.23027 5.69395 6.23027H3.53025Z"
        fill="#202A4C"
      />
      <path
        d="M12.4136 28.0956H11.2748C9.11113 28.0956 7.28906 26.2735 7.28906 24.1098V4.75041C7.28906 2.58671 9.11113 0.764648 11.2748 0.764648H12.4136C14.5773 0.764648 16.3994 2.58671 16.3994 4.75041V24.1098C16.3994 26.3874 14.5773 28.0956 12.4136 28.0956ZM11.2748 2.58671C10.0222 2.58671 9.11113 3.61162 9.11113 4.75041V24.1098C9.11113 25.2486 10.0222 26.2735 11.2748 26.2735H12.4136C13.5524 26.2735 14.5773 25.3625 14.5773 24.1098V4.75041C14.5773 3.61162 13.6663 2.58671 12.4136 2.58671H11.2748Z"
        fill="#202A4C"
      />
      <path
        d="M17.9917 10.5575C17.5362 10.5575 17.1945 10.2159 17.0807 9.87425C16.9668 9.41873 17.3084 8.84934 17.7639 8.84934L23.4579 7.71055C23.9134 7.59667 24.4828 7.93831 24.4828 8.39382C24.5967 8.84934 24.255 9.41873 23.7995 9.41873L18.1056 10.5575H17.9917Z"
        fill="#202A4C"
      />
      <path
        d="M18.9032 15.4549C18.4477 15.4549 17.9922 14.9994 17.9922 14.5438C17.9922 14.0883 18.4477 13.6328 18.9032 13.6328H28.1274C28.5829 13.6328 29.0384 14.0883 29.0384 14.5438C29.0384 14.9994 28.5829 15.4549 28.1274 15.4549H18.9032Z"
        fill="#202A4C"
      />
      <path
        d="M24.2564 21.2628H24.1425L17.8792 20.4656C17.4237 20.3517 17.082 19.8962 17.082 19.4407C17.1959 18.9852 17.5375 18.6436 18.1069 18.6436L24.3703 19.4407C24.8258 19.5546 25.1674 20.0101 25.1674 20.4656C25.0536 20.9211 24.7119 21.2628 24.2564 21.2628Z"
        fill="#202A4C"
      />
      <path
        d="M32.0008 6.00254H31.0898V1.44738L29.6094 1.9029V1.10575L31.887 0.308594H32.0008V6.00254Z"
        fill="#202A4C"
      />
    </svg>
  );
};
export const PumpTwo = () => {
  return (
    <svg
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.36986 23.1021H3.39726C1.53425 23.1021 0 21.5679 0 19.7049V7.21171C0 5.3487 1.53425 3.81445 3.39726 3.81445H5.47945C7.23288 3.81445 8.76712 5.3487 8.76712 7.21171V19.8145C8.76712 21.5679 7.23288 23.1021 5.36986 23.1021ZM3.39726 5.56788C2.52055 5.56788 1.75342 6.335 1.75342 7.21171V19.8145C1.75342 20.6912 2.52055 21.4583 3.39726 21.4583H5.47945C6.35616 21.4583 7.12329 20.6912 7.12329 19.8145V7.21171C7.12329 6.335 6.35616 5.56788 5.47945 5.56788H3.39726Z"
        fill="#202A4C"
      />
      <path
        d="M11.9432 26.61H10.8473C8.76514 26.61 7.01172 24.8565 7.01172 22.7743V4.14421C7.01172 2.06202 8.76514 0.308594 10.8473 0.308594H11.9432C14.0254 0.308594 15.7788 2.06202 15.7788 4.14421V22.7743C15.7788 24.8565 14.0254 26.61 11.9432 26.61ZM10.8473 2.06202C9.64186 2.06202 8.76514 2.93873 8.76514 4.14421V22.7743C8.76514 23.8702 9.64186 24.8565 10.8473 24.8565H11.9432C13.0391 24.8565 14.0254 23.9798 14.0254 22.7743V4.14421C14.0254 3.04832 13.1487 2.06202 11.9432 2.06202H10.8473Z"
        fill="#202A4C"
      />
      <path
        d="M17.3159 9.73392C16.8776 9.73392 16.5488 9.40515 16.4392 9.07639C16.3296 8.63803 16.6584 8.09008 17.0967 8.09008L22.5762 6.99419C23.0146 6.88461 23.5625 7.10378 23.6721 7.65173C23.7817 8.09008 23.4529 8.63803 23.0146 8.63803L17.5351 9.73392C17.4255 9.73392 17.3159 9.73392 17.3159 9.73392Z"
        fill="#202A4C"
      />
      <path
        d="M18.1931 14.4448C17.7548 14.4448 17.3164 14.0065 17.3164 13.5681C17.3164 13.1298 17.7548 12.6914 18.1931 12.6914H27.0698C27.5082 12.6914 27.9465 13.1298 27.9465 13.5681C27.9465 14.0065 27.5082 14.4448 27.0698 14.4448H18.1931Z"
        fill="#202A4C"
      />
      <path
        d="M23.3416 20.0342H23.232L17.2046 19.2671C16.7663 19.1575 16.4375 18.7192 16.4375 18.2808C16.5471 17.8424 16.8759 17.5137 17.4238 17.5137L23.4512 18.2808C23.8896 18.3904 24.2183 18.8287 24.2183 19.2671C24.1087 19.7055 23.78 20.0342 23.3416 20.0342Z"
        fill="#202A4C"
      />
      <path
        d="M31.5657 26.5012H27.9492V25.8436L29.8122 23.871C30.0314 23.5422 30.2506 23.3231 30.3602 23.1039C30.4698 22.8847 30.5794 22.6655 30.5794 22.5559C30.5794 22.3368 30.4698 22.1176 30.3602 21.8984C30.2506 21.7888 30.0314 21.6792 29.8122 21.6792C29.4835 21.6792 29.2643 21.7888 29.1547 21.8984C29.0451 22.1176 28.9355 22.3368 28.9355 22.6655H28.0588C28.0588 22.3368 28.1684 22.008 28.278 21.7888C28.3876 21.5696 28.6068 21.3505 28.9355 21.1313C29.2643 21.0217 29.5931 20.9121 29.9218 20.9121C30.4698 20.9121 30.9081 21.0217 31.1273 21.3505C31.4561 21.5696 31.5657 22.008 31.5657 22.4464C31.5657 22.6655 31.4561 22.9943 31.3465 23.3231C31.2369 23.6518 30.9081 23.9806 30.5794 24.3094L29.2643 25.734H31.7848V26.5012H31.5657Z"
        fill="#202A4C"
      />
    </svg>
  );
};
export const Blower = () => {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9 21.6828H3.1C1.4 21.6828 0 20.2828 0 18.5828V7.18282C0 5.48282 1.4 4.08282 3.1 4.08282H5C6.7 4.08282 8.1 5.48282 8.1 7.18282V18.6828C8 20.2828 6.6 21.6828 4.9 21.6828ZM3.1 5.68282C2.3 5.68282 1.6 6.38282 1.6 7.18282V18.6828C1.6 19.4828 2.3 20.1828 3.1 20.1828H5C5.8 20.1828 6.5 19.4828 6.5 18.6828V7.18282C6.5 6.38282 5.8 5.68282 5 5.68282H3.1Z"
        fill="#202A4C"
      />
      <path
        d="M10.9 24.8828H9.9C8 24.8828 6.4 23.2828 6.4 21.3828V4.38281C6.4 2.48281 8 0.882812 9.9 0.882812H10.9C12.8 0.882812 14.4 2.48281 14.4 4.38281V21.3828C14.4 23.2828 12.8 24.8828 10.9 24.8828ZM9.9 2.48281C8.9 2.48281 8 3.28281 8 4.38281V21.3828C8 22.3828 8.8 23.2828 9.9 23.2828H10.9C11.9 23.2828 12.8 22.4828 12.8 21.3828V4.38281C12.8 3.38281 12 2.48281 10.9 2.48281H9.9Z"
        fill="#202A4C"
      />
      <path
        d="M20.3 12.6828C18 12.6828 16.1 10.8828 16.1 8.58282C16.1 6.28282 18 4.48281 20.3 4.48281C22.6 4.48281 24.5 6.28282 24.5 8.58282C24.5 10.8828 22.6 12.6828 20.3 12.6828ZM20.3 5.68282C18.6 5.68282 17.3 6.98282 17.3 8.58282C17.3 10.1828 18.6 11.4828 20.3 11.4828C22 11.4828 23.3 10.1828 23.3 8.58282C23.3 6.98282 22 5.68282 20.3 5.68282Z"
        fill="#202A4C"
      />
      <path
        d="M25.5 14.9828C24.5 14.9828 23.6 14.1828 23.6 13.0828C23.6 12.0828 24.4 11.1828 25.5 11.1828C26.5 11.1828 27.4 11.9828 27.4 13.0828C27.4 14.1828 26.5 14.9828 25.5 14.9828ZM25.5 12.3828C25.1 12.3828 24.8 12.6828 24.8 13.0828C24.8 13.4828 25.1 13.7828 25.5 13.7828C25.9 13.7828 26.2 13.4828 26.2 13.0828C26.2 12.6828 25.9 12.3828 25.5 12.3828Z"
        fill="#202A4C"
      />
      <path
        d="M18.2 18.6828C16.9 18.6828 15.8 17.5828 15.8 16.2828C15.8 14.9828 16.9 13.8828 18.2 13.8828C19.5 13.8828 20.6 14.9828 20.6 16.2828C20.6 17.5828 19.6 18.6828 18.2 18.6828ZM18.2 15.1828C17.5 15.1828 17 15.6828 17 16.3828C17 16.9828 17.5 17.5828 18.2 17.5828C18.9 17.5828 19.4 17.0828 19.4 16.3828C19.4 15.6828 18.9 15.1828 18.2 15.1828Z"
        fill="#202A4C"
      />
    </svg>
  );
};
export const FilterIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8424 12.9214C24.8424 20.0743 19.6088 24.843 13.1521 24.843C7.90422 24.843 3.66968 22.0593 2.19141 17.69"
        stroke="#BDBDBD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 12.9216C1 5.76863 6.23357 1 12.6903 1C17.9394 1 22.1727 3.78369 23.651 8.15294"
        stroke="#BDBDBD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.96078 18.8826L2.19216 17.6904L1 22.4591"
        stroke="#BDBDBD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8823 6.96099L23.651 8.15315L24.8431 3.38452"
        stroke="#BDBDBD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.9401 10.4119H7.27344L11.3519 15.2889V19.5099L13.8617 18.255V15.2889L17.9401 10.4119Z"
        stroke="#BDBDBD"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <circle cx="9" cy="7.86279" r="0.5" fill="#219653" stroke="#BDBDBD" />
      <circle
        cx="12.1367"
        cy="7.86279"
        r="0.5"
        fill="#219653"
        stroke="#BDBDBD"
      />
      <circle cx="13" cy="21.8628" r="0.5" fill="#219653" stroke="#BDBDBD" />
      <circle
        cx="15.2734"
        cy="7.86279"
        r="0.5"
        fill="#219653"
        stroke="#BDBDBD"
      />
    </svg>
  );
};
export const FilterIcon2 = () => {
  return (
    <svg
      width="35"
      height="28"
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8424 14.3789C24.8424 21.5318 19.6088 26.3005 13.1521 26.3005C7.90422 26.3005 3.66968 23.5168 2.19141 19.1475"
        stroke="#2977BE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 14.3791C1 7.22615 6.23357 2.45752 12.6903 2.45752C17.9394 2.45752 22.1727 5.24121 23.651 9.61046"
        stroke="#2977BE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.96078 20.3401L2.19216 19.1479L1 23.9166"
        stroke="#2977BE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8823 8.41851L23.651 9.61067L24.8431 4.84204"
        stroke="#2977BE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.9401 11.8694H7.27344L11.3519 16.7464V20.9674L13.8617 19.7125V16.7464L17.9401 11.8694Z"
        stroke="#2977BE"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <circle cx="9" cy="9.32031" r="1" fill="#2977BE" />
      <circle cx="12.1367" cy="9.32031" r="1" fill="#2977BE" />
      <circle cx="13" cy="23.3203" r="1" fill="#2977BE" />
      <circle cx="15.2734" cy="9.32031" r="1" fill="#2977BE" />
      <path
        d="M30.6476 0.444444C30.6476 0.32657 30.6007 0.213524 30.5174 0.130175C30.434 0.0468252 30.321 0 30.2031 0C30.0853 0 29.9722 0.0468252 29.8889 0.130175C29.8055 0.213524 29.7587 0.32657 29.7587 0.444444C29.7587 1.08622 29.6702 1.98933 29.2422 2.71956C28.836 3.41156 28.0965 4 26.6476 4C26.5297 4 26.4166 4.04683 26.3333 4.13018C26.25 4.21352 26.2031 4.32657 26.2031 4.44444C26.2031 4.56232 26.25 4.67536 26.3333 4.75871C26.4166 4.84206 26.5297 4.88889 26.6476 4.88889C27.2378 4.88889 28.0173 5.068 28.6413 5.58667C29.2485 6.09022 29.7587 6.95778 29.7587 8.44444C29.7587 8.56232 29.8055 8.67537 29.8889 8.75871C29.9722 8.84206 30.0853 8.88889 30.2031 8.88889C30.321 8.88889 30.434 8.84206 30.5174 8.75871C30.6007 8.67537 30.6476 8.56232 30.6476 8.44444C30.6476 7.80267 30.736 6.89956 31.164 6.16933C31.5702 5.47733 32.3098 4.88889 33.7587 4.88889C33.8766 4.88889 33.9896 4.84206 34.073 4.75871C34.1563 4.67536 34.2031 4.56232 34.2031 4.44444C34.2031 4.32657 34.1563 4.21352 34.073 4.13018C33.9896 4.04683 33.8766 4 33.7587 4C33.1685 4 32.3889 3.82089 31.7649 3.30222C31.1578 2.79867 30.6476 1.93111 30.6476 0.444444ZM30.0085 3.16933C30.0916 3.028 30.164 2.88267 30.2276 2.736C30.4564 3.21797 30.7871 3.64453 31.1969 3.98622C31.3942 4.15022 31.6018 4.28756 31.8138 4.40133C31.1716 4.71244 30.7133 5.18089 30.3978 5.71956C30.3159 5.85933 30.2427 6.00406 30.1787 6.15289C29.9498 5.67092 29.6191 5.24437 29.2093 4.90267C29.0181 4.74408 28.8114 4.60502 28.5925 4.48756C29.2347 4.17644 29.6925 3.708 30.0085 3.16933Z"
        fill="#2977BE"
      />
    </svg>
  );
};

export const FilterIcon3 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8424 12.9214C24.8424 20.0743 19.6088 24.843 13.1521 24.843C7.90422 24.843 3.66968 22.0593 2.19141 17.69"
        stroke="#4CA5C1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 12.9216C1 5.76863 6.23357 1 12.6903 1C17.9394 1 22.1727 3.78369 23.651 8.15294"
        stroke="#4CA5C1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.96078 18.8826L2.19216 17.6904L1 22.4591"
        stroke="#4CA5C1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8823 6.96099L23.651 8.15315L24.8431 3.38452"
        stroke="#4CA5C1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.9401 10.4119H7.27344L11.3519 15.2889V19.5099L13.8617 18.255V15.2889L17.9401 10.4119Z"
        stroke="#4CA5C1"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <circle cx="9" cy="7.86279" r="0.5" fill="#219653" stroke="#4CA5C1" />
      <circle
        cx="12.1367"
        cy="7.86279"
        r="0.5"
        fill="#219653"
        stroke="#4CA5C1"
      />
      <circle cx="13" cy="21.8628" r="0.5" fill="#219653" stroke="#4CA5C1" />
      <circle
        cx="15.2734"
        cy="7.86279"
        r="0.5"
        fill="#219653"
        stroke="#4CA5C1"
      />
    </svg>
  );
};

export const filterIcon4 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="96px"
      height="96px"
    >
      {/* <linearGradient
        id="I9GV0SozQFknxHSR6DCx5a"
        x1="9.858"
        x2="38.142"
        y1="9.858"
        y2="38.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#21ad64" />
        <stop offset="1" stop-color="#088242" />
      </linearGradient> */}
      <path
        fill="url(#I9GV0SozQFknxHSR6DCx5a)"
        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
      />
      <path
        d="M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z"
        opacity=".05"
      />
      <path
        d="M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z"
        opacity=".07"
      />
      <path
        fill="#fff"
        d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z"
      />
    </svg>
  );
};
