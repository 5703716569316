import { deviceOverviewApi } from "./deviceOverviewApi";
import { deviceOverviewTypes } from "./deviceOverviewTypes";
import _ from "lodash";

const onGetData = (email, device_id, enviromentName) => async (dispatch) => {
  let response = await deviceOverviewApi.getData(email, enviromentName);
  if (
    response &&
    response.data &&
    response.data.responseCode === 1 &&
    response.data.responseData.length > 0
  ) {
    let filterColorList = [];
    const record = response.data.responseData.find(
      (x) => x.device_id === device_id
    );
    if (record) {
      dispatch({
        type: deviceOverviewTypes.DEVICE_OVERVIEW_LOAD_DATA,
        payload: { value: record },
      });
      if (record) {
        filterColorList = record.fav_colors.filter((x) => x !== "");
      }

      dispatch({
        type: deviceOverviewTypes.FAV_COLORS,
        payload: { value: filterColorList },
      });
      return true;
    } else {
      return false;
    }
  }
  return false;
};
const onUpdateColorCollection =
  (device_id, colorCollection, operation_type) => async (dispatch) => {
    let response = await deviceOverviewApi.onChangeColorCollection(
      device_id,
      colorCollection,
      operation_type
    );
    if (response && response.data && response.data.responseCode === 1) {
      // dispatch({
      //   type: deviceOverviewTypes.DEVICEOVERVIEW_ONCHANGE,
      //   payload: { prop: "addColors", value: [] },
      // });
      dispatch({
        type: deviceOverviewTypes.ON_UPDATE_COLOR,
        payload: { value: colorCollection },
      });
    }
    return response;
  };

const onDeleteColorCollection = (colorCollection) => async (dispatch) => {
  let response = await deviceOverviewApi.onDeleteColorCollection(
    colorCollection
  );
  return response;
};
const onAddColorCollection = (colorReq) => async (dispatch) => {
  let response = await deviceOverviewApi.onAddColorCollection(colorReq);
  return response;
};

const onSetSelectedColor = (colors) => async (dispatch) => {
  dispatch({
    type: deviceOverviewTypes.SET_COLOR,
    payload: { value: colors },
  });
};

const onDelectSelectColor = (colors) => {
  return (dispatch) => {
    dispatch({
      type: deviceOverviewTypes.DELETE_COLOR,
      payload: { value: colors },
    });
  };
};
const onClearDelectSelectColor = () => {
  return (dispatch) => {
    dispatch({
      type: deviceOverviewTypes.CLEAR_DELETE_COLOR,
      payload: { value: [] },
    });
  };
};

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: deviceOverviewTypes.DEVICEOVERVIEW_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onAddNewColor = (colors) => async (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: deviceOverviewTypes.ON_ADD_NEW_COLOR,
      payload: { value: colors },
    });
  }, 500);
};

const getEnvFavColors = (env) => async (dispatch) => {
  let response = await deviceOverviewApi.getEnvFavColors(env);
  if (response.data.success) {
    dispatch({
      type: deviceOverviewTypes.FAV_COLORS,
      payload: { value: response.data.data },
    });
  }
  return response;
};
const onGetDelears = (dName, catId) => async (dispatch) => {
  let response = await deviceOverviewApi.getDealers(dName, catId);
  return response;
};
export const deviceOverviewAction = {
  onGetData: onGetData,
  onGetDelears: onGetDelears,
  onUpdateColorCollection: onUpdateColorCollection,
  onDeleteColorCollection: onDeleteColorCollection,
  onAddColorCollection: onAddColorCollection,
  onSetSelectedColor: onSetSelectedColor,
  onDelectSelectColor: onDelectSelectColor,
  onClearDelectSelectColor: onClearDelectSelectColor,
  onChange: onChange,
  onAddNewColor: onAddNewColor,
  getEnvFavColors: getEnvFavColors,
};
