import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { Header, Loader } from "../../components/common/index";
import { RoutesObj } from "../../routes";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { deviceOverviewAction } from "../../store/deviceOverview/deviceOverviewAction";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import * as signalR from "@microsoft/signalr";
import {
  capitalizeFirstLetter,
  getLastOnlineTimestamp,
} from "../../components/common/CommonMethods";
import DetailedDeviceInfo from "../../components/common/DetailedDeviceInfo";
import GenericCardComponent from "../../components/common/GenericCardComponent";
import NotificationCard from "../../components/common/NotificationCardComponent";
import CrossBox from "../../resources/images/CrossBox.png";
import NewDevice from "../../resources/images/NewDev.png";
import SpaIcon from "../../resources/images/Spaicon.png";
import SpaLarge from "../../resources/images/Spalarge.png";
import ArrowIcon from "../../resources/images/arrowicon.png";
import {
  default as BellIcon,
  default as NotLabel,
} from "../../resources/images/bellicon.png";
import MobileNotification from "../../resources/images/bellmobile.png";
import CrossBold from "../../resources/images/crossBold.png";
import CrossShape from "../../resources/images/crossshape.png";
import DeleteIcon from "../../resources/images/delicon.png";
import EditIcon from "../../resources/images/editBlue.png";
import EditEnv from "../../resources/images/editenv.png";
import RenameTick from "../../resources/images/envcross.png";
import GenericPin from "../../resources/images/genericpin.png";
import GolfEnv from "../../resources/images/golfenv.png";
import MarineEnv from "../../resources/images/marineenv.png";
import MessageBox from "../../resources/images/messagebox.png";
import MobileDelete from "../../resources/images/mobiledeleteenv.png";
import Mobilelogo from "../../resources/images/mobilelogo.png";
import MobilePin from "../../resources/images/mobilepinenv.png";
import MobileTick from "../../resources/images/mobiletickenv.png";
import MotorcyleEnv from "../../resources/images/motoercycleenv.png";
import MessageBoxWithoutCount from "../../resources/images/msgbox.png";
import OutDoorEnv from "../../resources/images/outdoorenv.png";
import Pin from "../../resources/images/pin.png";
import PinWidget from "../../resources/images/pinwidgt.png";
import PlusIcon from "../../resources/images/plusIcon.png";
import PowerSportsEnv from "../../resources/images/powersportenv.png";
import RedDot from "../../resources/images/reddot.png";
import UnpinkEnv from "../../resources/images/removepin.png";
import TickBox from "../../resources/images/tickbox.png";
import CreateEnv from "../../resources/images/tracker.png";
import routesObj from "../../routes/RoutesObj";
import { PriceListAction } from "../../store/PriceList/PriceListAction";
import { SpaOverviewAction } from "../../store/SpaOverview/SpaOverviewAction";
import { conversationAction } from "../../store/conversations/conversationAction";
import ReceivedMessage from "../UpdateMessages/ReceivedMessage";
import AddDeviceFlow from "./AddDeviceFlow/AddDeviceFlow";
import UserRegistrationFooter from "../UserRegistration/UserRegistrationFooter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// var sha256 = require("js-sha256").sha256;

const color = {
  Expired: "#eb5757",
  Upcoming: "#9B51E0",
  Current: "#27AE60",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 6,
  outline: "none",

  // width: "50%",
  // height: "326.91px",
  padding: "32px 48px 28px 48px",
  borderRadius: "20px",
  overflowY: "scroll",
};
class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      isValid: false,
      isLoading: false,
      isModal: false,
      customEnv: "",
      envIconName: "",
      isSessionModal: true,
      hashValue: "",
      selectedTranferDevice: null,
      showTransferDevice: false,
      isDealer: false,
      deviceDetail: [],
      deviceDetailDev: [],

      activeSub: "Home",
      allEnv: [],
      getNotification: [],
      isDivVisible: true,
      selectedData: null,
      icons: [
        SpaIcon,
        MotorcyleEnv,
        PowerSportsEnv,
        MarineEnv,
        OutDoorEnv,
        GolfEnv,
        SpaLarge,
        GolfEnv,
        SpaLarge,
      ],
      viewEnvModal: false,
      viewEnvCreateModal: false,
      viewDelEnvModal: false,
      customEnvList: [],
      customEnvIcons: [],
      staticEnvList: [],
      selectedEnv: {},
      customEnvName: "",
      deviceInfo: [],
      page: 0,
      changeEnvName: "",
      showAddNewDeviceModal: false,
      openDeiveInfo: false,
      passDeviceInfo: [],
      pinnedEnviornments: [],
      selectedenvironment: null,
      emailConfirmed: false,
      selectedEnvironment: null,
      selectedPinEnv: false,
      unMarkPinEnv: [],
      getConnectedDevices: [],
      showSubNavbar: false,
      showMenu: false,
      showSubMenu: false,
      editEnv: false,
      filteredEnvironments: [],
      notifications: [],
      isEmailVerified: false,
      allMessage: [],
      connection: null,
      footerClassName: "",
      showFooter: false,
      isDeviceOwnerShip: true,
      redirectToTransferOwnership: false,
    };
    this.roomId = 0;
    this.hubUserId = 0;
    // this.connectionUrl = "https://aquaticavchatdev.azurewebsites.net/signalr";
    this.connectionUrl = "https://aquaticavchat.azurewebsites.net/signalr";
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

    this.options = {
      // transport: transport,
      logMessageContent: true,
      logger: signalR.LogLevel.Trace,
      // skipNegotiation: true,
    };

    // this.handleClick = this.handleClick.bind(this);
  }

  buildConnection = () => {
    this.setState({ isLoading: true });

    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    let connect = new signalR.HubConnectionBuilder()
      .withUrl(
        this.connectionUrl +
          "?ClientId=" +
          this.userInfo?.id +
          "&ProductId=" +
          2 +
          // "&ClientName=" +
          // this.userInfo.name +
          // "" +
          "&generic=" +
          this.userInfo?.role,
        this.options
      )
      .withAutomaticReconnect()
      .withHubProtocol(protocol)
      .build();

    this.setState({ connection: connect });

    connect.on("send", (data) => {
      console.log(data);
    });

    connect
      .start()
      .then(() => {
        console.log("connection started");
      })
      .catch((err) => {
        console.log("error while establishing signalr connection: " + err);
      });
  };

  handleClick = (data) => {
    // try {
    this.setState({
      isLoading: true,
    });
    if (!this.state.isDivVisible) {
      // Additional check to prevent retriggering when isDivVisible is already true
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      this.setState(
        {
          isDivVisible: true,
          selectedData: data,
          selectedenvironment: data?.id,
        }, // Set isDivVisible to true directly here
        () => {
          // Code inside this callback will execute after state update
          this.props.actions
            .onGetDeviceInfo(userInfo.id, data?.id)
            .then((dev) => {
              this.setState({
                deviceDetailDev: dev?.data?.data,
                isLoading: false,
              });
            })
            .catch((error) => {
              this.setState({ isLoading: false });
            });
        }
      );
    }
  };

  onGetUserDevices() {
    return new Promise((resolve, reject) => {
      this.setState({
        isLoading: true,
      });
      const userId = localStorage.getItem("userId");
      this.props.actions
        .onGetUserDevices(userId)
        .then((dev) => {
          resolve(dev?.data?.data);
          this.setState({
            deviceInfo: dev?.data?.data,
          });
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          console.error("Error fetching device information:", error);
          reject(error);
        });
    });
  }
  deleteEnv = (id) => {
    this.props.actions
      .delete(id)
      .then((data) => {
        if (data?.status === 200) {
          toast.success("Environment deleted successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again");
      });
  };

  handleDevicesClick(itemName) {
    if (itemName === "Devices") {
      try {
        const userId = localStorage.getItem("userId");
        const environmentId = this?.state?.allEnv[0]?.id;

        const deviceInfo = this.onGetUserDevices(userId, environmentId);
        this.setState({ openDeiveInfo: false });

        // Do something with the deviceInfo here...
      } catch (error) {
        console.error("Error in button click:", error);
      }
    } else if (itemName === "Environment" || itemName === "Home") {
      this.setState({
        deviceInfo: [],
        // isLoading: false
      });
      this.setState({ isDivVisible: false });
      this.setState({ openDeiveInfo: false });
    }
  }
  onGetLatestPinnedEnv(itemName) {
    if (itemName === "Home") {
      this.onGetPinnedEnv();
      this.getConnectedDevices();
    }
  }

  onGetLatestEnv(itemName) {
    if (itemName === "Environment") {
      this.onLoadCompleteData();
    }
  }
  subNavItems = [
    { name: "Home" },
    { name: "Environment" },
    { name: "Devices" },
  ];

  handleOpenEnvModal = () => {
    this.setState({ viewEnvModal: true });
  };

  handleCloseEnvModal = () => {
    this.setState({ viewEnvModal: false });
  };
  handleOpenCreateEnvModal = () => {
    this.setState({ viewEnvCreateModal: true });
  };

  handleCloseCreateEnvModal = () => {
    this.setState({ viewEnvCreateModal: false });
  };

  handleOpenDelEnvModal = () => {
    this.setState({ viewDelEnvModal: true });
  };

  handleCloseDelEnvModal = () => {
    this.setState({ viewDelEnvModal: false });
  };

  handleSubClick = (name) => {
    this.setState({ activeSub: name });
  };
  async componentDidMount() {
    const currentPath = window.location.pathname;

    this.buildConnection();
    this.getConnectedDevices();
    this.userData();
    this.fetchActiveNotifications();
    this.setState({ emailConfirmed: true });

    if (localStorage.getItem("userInfo") !== null) {
      this.setState({ isLoading: true });
      await this.onGetPinnedEnv();

      this.onLoadCompleteData();
      if (JSON.parse(localStorage.getItem("userInfo")).customerType === 3) {
        this.setState({
          isDealer: true,
        });

        const data = {
          userId: JSON.parse(localStorage.getItem("userInfo"))?.id,
          companyId: JSON.parse(localStorage.getItem("userInfo"))?.companyId,
          groupId: JSON.parse(localStorage.getItem("userInfo"))?.groupId,
        };

        this.props.PriceListAction.onGetPriceList(data);
      }
    } else {
      const { history } = this.props;

      history.replace({
        pathname: RoutesObj.Root.path,
      });
    }
    if (
      currentPath === "/dealer-locator" ||
      currentPath === "/conversation" ||
      currentPath === "/messages"
    ) {
      this.setState({ footerClassName: "noFooter", showFooter: false });
    } else {
      this.setState({ footerClassName: "mobileFooter", showFooter: true });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.connection &&
      this.state.connection !== prevState.connection
    ) {
      const { connection } = this.state;

      connection.off("messageRecieved");

      connection.on("onUserConnected", (data) => {
        if (data) {
          localStorage.setItem("hubUserId", data?.hubUserId);
          this.hubUserId = data?.hubUserId;
          this.loadChat(data.hubUserId);
        }
      });

      connection.on("messageRecieved", async (data) => {
        if (this.hubUserId > 0) {
          if (this.roomId && data?.roomId === this.roomId) {
            // chatDetail(data?.roomId);
          } else {
            this.loadChat(this.hubUserId);
          }
        }
      });
    }
  }

  async onGetPinnedEnv() {
    this.setState({ isLoading: true });
    try {
      const env = await this.props.actions.onGetPinnedEnviornments();
      this.setState(
        {
          pinnedEnviornments: env.data.data,
          isLoading: false,
        },
        () => {
          // Now that the state is updated, you can perform further actions

          if (
            this.state.pinnedEnviornments &&
            this.state.pinnedEnviornments?.length > 0
          ) {
            this.setState({
              selectedEnvironment: this.state.pinnedEnviornments[0],
            });
          }
        }
      );
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching pinned environments:", error);
    }
  }

  async onLoadCompleteData(showMsg, msgString) {
    this.setState({
      isLoading: true,
    });
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    await this.props.actions
      .onGetStaticEnvironment(userId)
      .then(async (env) => {
        this.setState({
          staticEnvList: env?.data?.data,
        });
        this.setState({
          isLoading: false,
        });
      });

    await this.props.actions
      .onGetAllEnvironment(userId)
      .then(async (allenv) => {
        this.setState({
          customEnvList: allenv?.data?.data?.staticEnvironments,
        });
      });
    await this.props.actions.onGetCustomEnvIcons(userId).then(async (env) => {
      this.setState({
        customEnvIcons: env?.data?.data,
      });
    });
    await this.props.actions
      .onGetMyEnvironment(
        userId,
        JSON.parse(localStorage.getItem("userInfo")).customerType === 3
          ? JSON.parse(localStorage.getItem("userInfo")).companyId
          : false
      )
      .then(async (env) => {
        this.setState({ allEnv: env?.data?.data });

        if (env.status === 200 && env?.data?.success) {
          // let reqObj = {
          //   type: "type",
          //   userId: userId,
          // };

          // await this.props.actions.onfetchNotification(reqObj).then((res) => {
          //   this.setState({
          //     getNotification: res?.data?.data,
          //   });
          // });
          await this.props.actions
            .onGetUserDevices(userId, env.data?.data[0]?.id)
            .then((dev) => {
              this.setState({
                isLoading: false,
                deviceInfo: dev?.data?.data,
              });
            });
          this.setState({ isLoading: false });
        }
      });

    await this.onGetDashboardDataView();
  }
  async loadChat(hubId) {
    // this.setState({ isLoading: true });
    await this.props.conversationAction
      .onGetUserRoomsByUsersId({
        roomName: "",
        hubUserId: hubId,
        productId: 2,
        isFavorite: false,
        campaignId: 1,
        pageNo: 0,
        take: 200,
        name: "",
      })
      .then((data) => {
        this.setState({ isLoading: false });
        this.setState({ allMessage: data?.responseData?.data });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }
  handleRooms = () => {};

  onGetDashboardDataView() {
    this.setState({ isLoading: true });
    if (localStorage.getItem("currentEnv") !== "null") {
      const userId = localStorage.getItem("userId");
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
      this.props.actions
        .onGetDashboardData(userId, currentEnv, userInfo)
        .then((data) => {
          this.setState({
            deviceDetail: data?.data?.data,
          });

          if (data?.data?.data?.length) {
            data.data.data.forEach((value, index) => {
              if (value.productType.includes("SPA")) {
                this.getSpaOverviewData(value.id);
              }
            });
          }
        });
      this.props.actions.onGetTranferDevices(userId).then((deviceData) => {});
    }
    this.setState({ isLoading: false });
  }

  onRegisteredButtonClick(item, selectedTab) {
    //here color seeeting will be handeled
    const { history } = this.props;
    this.props.deviceOverviewAction.onChange("fav_colors", []);
    this.props.deviceOverviewAction.onChange("addColors", []);
    this.props.deviceOverviewAction.onChange("deviceOverviewData", item);
    this.props.deviceOverviewAction
      .getEnvFavColors(item.envid)
      .then((res) => {});
    // item?.fav_colors?.forEach((element) => {
    //   if (element && element !== "") {
    //     this.props.deviceOverviewAction.onSetSelectedColor(element);
    //   }
    // });
    history.push({
      pathname: RoutesObj.DeviceOverview.path,
      state: { showTab: selectedTab },
    });
    // this.setState({ isLoading: true });
    // const { history } = this.props;
    // const userEmail = localStorage.getItem("userEmail");
    // const userPassword = localStorage.getItem("password");
    // this.props.deviceOverviewAction
    //   .onGetData(userEmail, device_id, env_name)
    //   .then((response) => {
    //     this.setState({ isLoading: false });
    //     if (response) {
    //       history.push(RoutesObj.DeviceOverview.path);
    //     }
    //     if (!response) {
    //       toast.error("Unable to find record");
    //     }
    //   });
  }

  updateDeviceName(updateHeading, device, id) {
    if (updateHeading !== device.name) {
      this.setState({ isLoading: true });

      this.props.actions.onUpdateDevName(updateHeading, id).then((data) => {
        if (data?.status === 200 && data?.data?.success) {
          this.onLoadCompleteData(
            true,
            "Device name has been updated successfully"
          );
        } else {
          this.setState({ isLoading: false });
          toast.error("Something went wrong, Please try again");
        }
      });
    }
  }
  getConnectedDevices() {
    this.setState({ isLoading: true });
    this.props.actions
      .getConnectedDevices()
      .then((res) => this.setState({ getConnectedDevices: res.data }));
    this.setState({ isLoading: false });
  }

  closeAddDevice() {
    this.setState({ isModal: false });
  }
  handleNotification() {
    const { history } = this.props;

    history.push(RoutesObj.Notifications.path);
  }
  viewTransferDevice(item) {
    if (typeof item !== "undefined") {
      this.setState({
        selectedTranferDevice: item,
        showTransferDevice: true,
      });
    }
  }

  closeTransferDevice() {
    this.setState({
      selectedTranferDevice: null,
      showTransferDevice: false,
    });
  }

  getSpaOverviewData(SpaId) {
    this.props.SpaOverviewAction.onGetSpaInfo(SpaId);
  }

  userData = () => {
    const userId = localStorage.getItem("userId");

    this.props.actions.onUserGetData(userId).then((data) => {
      this.setState({ isEmailVerified: data?.data?.data?.emailConfirmed });
    });
  };

  // fucntion to check SPA to show SPA option
  decideIsSPA() {
    let isSPA = false;
    let SPAIId = null;
    this.state.deviceInfo?.forEach((value, index) => {
      if (
        // value.productType.includes("Physical + IOT") ||
        value.productType === "SPABasic" ||
        value.productType === "SPAAdvance" ||
        value.productType === "SPAMid"
      ) {
        isSPA = true;
        SPAIId = value.id;
        // this.getSpaOverviewData(value.id);
      }
    });

    return { isSPA, SPAIId };
  }

  onSelectEnv = (env, key) => {
    this.setState({
      selectedEnv: { ...env, key: key },
    });
  };

  onAddEnv = async () => {
    this.setState({ isLoading: true });

    const userId = localStorage.getItem("userId");

    const {
      environmentId,
      key,
      environmentName,
      environmentType,
      environmentIcon,
    } = this.state.selectedEnv;

    if (!environmentId) {
      this.setState({ isLoading: false });
      toast.error("Please select environment and icon");
      return;
    } else if (key === "custom" && /^\s*$/.test(this.state.customEnvName)) {
      this.setState({ isLoading: false });
      toast.error("Please enter environment name");
      return;
    }

    const req = {
      environmentId: 0,
      environmentName:
        key === "custom" ? this.state.customEnvName : environmentName,
      environmentType: key === "custom" ? "D" : environmentType,
      environmentIcon: environmentIcon,
      status: 1,
      userId: userId,
    };

    await this.props.actions.onAddDashboardEnv(req).then(async (env) => {
      this.setState({ isLoading: false });
      if (env && env.data && env.data.success) {
        this.setState({
          selectedEnv: {},
          viewEnvCreateModal: false,
        });
        this.componentDidMount();
      } else {
        toast.error(
          env && env.data && env.data.message
            ? env.data.message
            : "Something went wrong please try again"
        );
      }
    });
  };

  onUpdateEnv = async (myEnv) => {
    this.setState({ isLoading: true });
    const userId = localStorage.getItem("userId");

    const { id, type } = myEnv;
    const { environmentIcon } = this.state.selectedEnv;

    // this.setState({ isLoading: true });
    if (!id) {
      this.setState({ isLoading: false });
      toast.error("Please select environment icon");
      return;
    } else if (/^\s*$/.test(this.state.changeEnvName)) {
      this.setState({ isLoading: false });
      toast.error("Please enter environment name");
      return;
    }
    const req = {
      environmentId: id,
      environmentName: this.state.changeEnvName,
      environmentType: type,
      environmentIcon: environmentIcon
        ? environmentIcon
        : this.state.selectedData?.icon,
      status: 1,
      userId: userId,
    };

    await this.props.actions.onAddDashboardEnv(req).then(async (env) => {
      this.setState({ isLoading: false });
      if (env && env.data && env.data.success) {
        this.setState({
          selectedEnv: {},
          selectedData: {
            ...myEnv,
            icon: environmentIcon
              ? environmentIcon
              : this.state.selectedData?.icon,
            name: this.state.changeEnvName,
            id: id,
          },
          changeEnvName: "",
        });
        this.handleClick();
        this.componentDidMount();
        this.setState({ editEnv: false });
      } else {
        toast.error(
          env && env.data && env.data.message
            ? env.data.message
            : "Something went wrong please try again"
        );
      }
    });
  };

  onMarkPinEnv = async () => {
    // const { id } = this.state.selectedenvironment;

    this.setState({ isLoading: true });
    const req = {
      isPinned: true,
      ids: [this.state.selectedenvironment],
    };

    await this.props.actions.onMarkUnMarkPinEnv(req).then(async (env) => {
      this.setState({ isLoading: false });
      if (env && env.data && env.data.success) {
        this.onGetPinnedEnv();
        toast.success("Enviornment Pinned Successfully!");
        this.componentDidMount();
      } else {
        toast.error(
          env && env.data && env.data.message
            ? env.data.message
            : "Something went wrong please try again"
        );
      }
    });
  };

  goToAddDevicesFlow = () => {
    // const { history } = this.props;

    // history.push({
    //   pathname: RoutesObj.AddNewDeviceFlow.path,
    // });
    this.setState({
      showAddNewDeviceModal: true,
    });
  };

  handleCloseNewDeviceModal = () => {
    this.setState({ showAddNewDeviceModal: false });
  };

  onCloseDeviceModalAndRefresh = () => {
    this.setState({ showAddNewDeviceModal: false });
    this.componentDidMount();
  };

  handleDeviceClick = (device) => {
    this.setState({ isLoading: true });

    this.props.deviceOverviewAction.onChange("fav_colors", []);
    this.props.deviceOverviewAction.onChange("addColors", []);
    this.props.deviceOverviewAction.onChange("deviceOverviewData", device);
    this.props.deviceOverviewAction
      .getEnvFavColors(device.environmentId)
      .then((res) => {
        this.setState({ passDeviceInfo: device });
        this.setState({ openDeiveInfo: true });
        this.setState({ isLoading: false });
      });
  };

  onMarkPinEnvModal = async () => {
    const { id } = this.state.selectedEnv;

    this.setState({ isLoading: true });
    const req = {
      isPinned: true,
      ids: [id],
    };

    await this.props.actions.onMarkUnMarkPinEnv(req).then(async (env) => {
      this.setState({
        isLoading: false,
        viewEnvModal: false,
      });
      if (env && env.data && env.data.success) {
        toast.success("Enviornment Pinned Successfully!");
        this.componentDidMount();
      } else {
        toast.error(
          env && env.data && env.data.message
            ? env.data.message
            : "Something went wrong please try again"
        );
      }
    });
  };
  handleCardClick = (environmentData) => {
    this.setState({ selectedEnvironment: environmentData });
  };

  onDeleteEnv = async () => {
    this.setState({ isLoading: true });

    const req = [this?.state?.selectedenvironment];

    await this.props.actions.deleteEnvironment(req).then(async (env) => {
      this.setState({
        isLoading: false,
        viewDelEnvModal: false,
      });
      if (env && env?.data && env?.data?.success) {
        toast.success("Enviornment Deleted Successfully!");
        this.componentDidMount();
        this.setState({
          isDivVisible: false,
        });
      } else {
        toast.error(
          env && env?.data && env?.data?.message
            ? env?.data?.message
            : "Something went wrong please try again"
        );
      }
    });
  };

  onUnMarkPinEnvironment = async () => {
    this.setState({ isLoading: true });

    if (
      Array.isArray(this.state.unMarkPinEnv) &&
      this.state.unMarkPinEnv.length === 0
    ) {
      this.setState({ isLoading: false });
      toast.error("Please select Environment(s)");
    } else {
      const req = {
        isPinned: false,
        ids: this.state.unMarkPinEnv,
      };

      await this.props.actions.onMarkUnMarkPinEnv(req).then(async (env) => {
        this.setState({ isLoading: false });
        if (env && env.data && env.data.success) {
          this.onGetPinnedEnv();
          this.setState({
            selectedPinEnv: false,
            unMarkPinEnv: [],
          });
          if (req.ids.length > 1) {
            toast.success("Environment(s) unpinned successfully");
          } else if (req.ids.length === 1) {
            toast.success("Environment(s) unpinned successfully");
          }
          this.setState({ isLoading: false });
          // this.componentDidMount();
        } else {
          this.setState({ isLoading: false });
          toast.error(
            env && env.data && env.data.message
              ? env.data.message
              : "Something went wrong please try again"
          );
        }
      });
    }
  };

  closeDeviceInfo = () => {
    this.setState({ openDeiveInfo: false });
    this.onGetUserDevices();
  };
  handleCrossClick = () => {
    this.setState({ emailConfirmed: false });
  };
  truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      const truncatedText = text.slice(0, maxLength);
      return `${truncatedText}...`;
    }
    return text;
  }
  ShowSubNavbar = (value) => {
    this.setState({ showSubNavbar: value });
  };
  toggleSubNav = () => {
    this.setState((prevState) => ({
      showSubMenu: !prevState.showSubMenu,
    }));
  };
  removeSubNav = () => {
    this.setState({ showSubMenu: false });
  };

  updatePassDeviceInfo = (newDeviceInfo) => {
    this.setState({ passDeviceInfo: newDeviceInfo });
  };

  // const fetchActiveNotifications = useCallback(async () => {
  //   const notifications = await dashboardApi.getNotificationByPortalID({
  //     portalId: 1,
  //   });
  //   if (notifications.data?.data)
  //     setNotifications([...notifications.data?.data]);
  //   else if (notifications.data?.data === null) setNotifications([]);
  // }, []);

  fetchActiveNotifications = () => {
    this.props.actions
      .onGetNotificationByPortalId({ portalId: 1 })
      .then((res) => {
        this.setState({
          notifications: res?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  render() {
    const {
      devicesInfo,
      userInfo,
      userEnv,
      // isIntercomEnable,
      loadingDashboard,
      transferDevices,
    } = this.props.state;

    // Assume user is fetched from props or context
    const {
      isLoading,
      showTransferDevice,
      selectedTranferDevice,
      isEmailVerified,
      allMessage,
    } = this.state;
    const {
      history,
      spaOverviewReducer,
      PriceListReducer,
      deviceOverviewState,
    } = this.props;
    const currentRoot = history ? history.location.pathname : null;
    const { activeSub, notifications, showFooter, footerClassName } =
      this.state;

    const { deviceOverviewData } = deviceOverviewState;
    const user = JSON.parse(localStorage.getItem("userInfo"));

    let currentEnv = null;
    try {
      if (localStorage.getItem("currentEnv") !== "null") {
        currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
      }
    } catch (error) {
      console.log(error);
    }

    const returnIcons = (enName) => {
      // if (enName === "SPA") {
      //   return SpaIcon;
      // } else if (enName === "Motorcycle") {
      //   return MotorcyleEnv;
      // } else if (enName === "Marine") {
      //   return MarineEnv;
      // } else if (enName === "Golf Cart") {
      //   return GolfEnv;
      // } else if (enName === "Powersports") {
      //   return PowerSportsEnv;
      // } else if (enName === "Outdoor Living") {
      //   return OutDoorEnv;
      // }

      return (
        <img
          src={enName}
          style={{
            height: 80,
            width: 80,
            objectFit: "contain",
          }}
          alt=""
        />
      );
    };

    return (
      <div className="Dashboardmain-container">
        {(isLoading || loadingDashboard) && <Loader />}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userInfo ? userInfo : null}
            userEnvoirnment={userEnv}
            history={history}
            isSPA={this.decideIsSPA().isSPA} // sending this to show the spa dropdwon if there is any SPA in the environemnt
            SPAIId={this.decideIsSPA().SPAIId} // sending this to get spa details on the settings screen
            isDealer={this.state.isDealer}
            showSubNavbar={this.ShowSubNavbar}
            toggleSubNav={this.toggleSubNav}
            removeSubNav={this.removeSubNav}
            isLoading={isLoading}
          />
          {(currentRoot === "/dashboard" || currentRoot === "/my-account") && (
            <div className="subItemSection">
              {this?.subNavItems?.map((item) => (
                <li
                  className={`list-item ${
                    activeSub === item.name ? "active" : ""
                  }`}
                  key={item.name}
                  onClick={() => {
                    this.handleSubClick(item.name);
                    this.handleDevicesClick(item.name);
                    this.onGetLatestPinnedEnv(item.name);
                    this.onGetLatestEnv(item.name);
                    // Call the additional function here
                  }}
                >
                  <b className="left-curve"></b>
                  <b className="right-curve"></b>
                  <a>{item.name}</a>
                </li>
              ))}
            </div>
          )}

          {currentRoot === "/dashboard" && this.state.showSubMenu && (
            <div className="subItemSectionMobile">
              {this?.subNavItems?.map((item) => (
                <li
                  className={`list-item ${
                    activeSub === item.name ? "active" : ""
                  }`}
                  key={item.name}
                  onClick={() => {
                    this.handleSubClick(item.name);
                    this.handleDevicesClick(item.name);
                    this.onGetLatestPinnedEnv(item.name);
                    this.onGetLatestEnv(item.name);
                    // Call the additional function here
                  }}
                >
                  <b className="left-curve"></b>
                  <b className="right-curve"></b>
                  <a>{item.name}</a>
                </li>
              ))}
            </div>
          )}

          {activeSub === "Home" && (
            <>
              <div className="dashboardMainSection">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h1 className="welcomeHead">
                    Welcome back {capitalizeFirstLetter(user?.firstName)}!
                  </h1>
                  {this.state.emailConfirmed === true && !isEmailVerified ? (
                    <div className="notificationBR">
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "14px",
                          fontWeight: 700,
                          fontFamily: "Poppins",
                          color: "#ffffff",
                        }}
                      >
                        {" "}
                        <span>
                          <img
                            src={CrossShape}
                            onClick={this.handleCrossClick}
                            style={{
                              marginRight: "20px",
                              background: "#fffCC",
                            }}
                            alt=""
                          />
                        </span>
                        Verify Your Email: Please verify your email in Account
                        Settings to complete registration
                      </p>
                    </div>
                  ) : null}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="dashBoardMainMobileContainer"
                >
                  <div className="cardSectionMain">
                    <div>
                      <div
                        className="EnvironmentHeadSection EnvironmentHeadSectionMobile"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h className="EnvironmentHead">
                          My Enivornmentsss
                          <img
                            src={ArrowIcon}
                            style={{ marginLeft: "10px" }}
                            alt=""
                          ></img>
                        </h>

                        <div className="pinnedSec">
                          {this.state.selectedPinEnv && (
                            <img
                              src={UnpinkEnv}
                              onClick={this.onUnMarkPinEnvironment}
                              alt=""
                            ></img>
                          )}
                          <img
                            src={PinWidget}
                            onClick={() =>
                              this.setState({
                                selectedPinEnv: true,
                              })
                            }
                            alt=""
                            style={{ marginRight: "60px" }}
                          ></img>
                        </div>
                      </div>
                      <div className="dashboard_home_section">
                        {this.state?.pinnedEnviornments?.length > 0 ? null : (
                          <>
                            <GenericCardComponent
                              onClick={this.handleOpenEnvModal}
                              icon={GenericPin}
                              title="Pinned Enivornments"
                              backgroundColor="white"
                              subheadings={[
                                {
                                  text: "Pin favorite Environments to view them here",
                                },
                              ]}
                              iconStyle={{
                                width: "72px",
                                height: "72px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "20px",
                              }}
                              width="302px"
                              height="280px"
                              hasMargin
                            />
                            <GenericCardComponent
                              // onClick={
                              //   this
                              //     .handleOpenCreateEnvModal
                              // }
                              icon={GenericPin}
                              hasBorder
                              hasMargin
                              isCentered
                              width="302px"
                              height="280px"
                            />
                            <GenericCardComponent
                              icon={GenericPin}
                              hasBorder
                              hasMargin
                              isCentered
                              width="302px"
                              height="280px"
                            />
                          </>
                        )}

                        {this.state.pinnedEnviornments && (
                          <>
                            {this?.state?.pinnedEnviornments
                              ?.slice(0, 3)
                              ?.map((item, i) => (
                                <div
                                  className={
                                    this.state.selectedEnvironment === item
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => this.handleCardClick(item)}
                                  key={i}
                                  style={{
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                >
                                  <GenericCardComponent
                                    backgroundColor="#fff"
                                    className="genericMobilecard"
                                    icon={item.environmentIconName}
                                    hasMargin
                                    isCentered
                                    width="290px"
                                    height="280px"
                                    key={i}
                                    title={
                                      item?.environmentName.length > 25
                                        ? `${item?.environmentName.slice(
                                            0,
                                            20
                                          )}...`
                                        : item?.environmentName
                                    }
                                    cardtitle={item?.environmentName}
                                    subheadings={[
                                      {
                                        // text:
                                        //   item?.location?.length > 25
                                        //     ? `${item?.location.slice(
                                        //         0,
                                        //         25
                                        //       )}...${item?.location.slice(25)}`
                                        //     : item?.location,
                                        // style: {
                                        //   fontFamily: "Roboto",
                                        //   fontSize: "14px",
                                        //   fontWeight: "700",
                                        // },
                                      },
                                      {
                                        text: item.devicesCount.toString(),
                                        hasBorder: true,
                                        width: "50%",
                                        height: "50px",
                                        style: {
                                          fontFamily: "Roboto",
                                          fontWeight: "700",
                                          color: "#5E6278",
                                        },
                                      },
                                      {
                                        text: "Active Devices",
                                        hasBorder: true,
                                        style: {
                                          fontFamily: "Roboto",
                                          fontWeight: "500",
                                          textAlign: "center",
                                          color: "#B5B5C3",
                                        },
                                      },
                                    ]}
                                  />

                                  {this.state.selectedPinEnv === true && (
                                    <div
                                      className="tick-icon"
                                      style={{
                                        position: "absolute",
                                        top: "20px",
                                        right: "40px",
                                      }}
                                      onClick={() => {
                                        let newItems =
                                          this.state.pinnedEnviornments.filter(
                                            (pinnedItem) =>
                                              pinnedItem.id !== item.id
                                          );
                                        let newFilteredEnvironments = [
                                          ...this.state.filteredEnvironments,
                                          item,
                                        ];
                                        this.setState({
                                          filteredEnvironments:
                                            newFilteredEnvironments,
                                        });
                                        this.setState({
                                          pinnedEnviornments: newItems,
                                        });
                                        let newUnmarkEnv =
                                          this.state.unMarkPinEnv;
                                        newUnmarkEnv.push(item.id);
                                        this.setState({
                                          unMarkPinEnv: newUnmarkEnv,
                                        });
                                      }}
                                    >
                                      <img src={RenameTick} alt="" />
                                    </div>
                                  )}
                                </div>
                              ))}
                          </>
                        )}
                        {this.state?.pinnedEnviornments?.length === 2 ? (
                          // Show the special GenericCardComponent when the length is less than 3
                          <GenericCardComponent
                            onClick={this.handleOpenEnvModal}
                            icon={GenericPin}
                            hasBorder
                            hasMargin
                            isCentered
                            width="302px"
                            height="280px"
                          />
                        ) : this.state?.pinnedEnviornments?.length === 1 ? (
                          // Show the special GenericCardComponent when the length is equal to 2
                          <>
                            <GenericCardComponent
                              onClick={this.handleOpenEnvModal}
                              icon={GenericPin}
                              hasBorder
                              hasMargin
                              isCentered
                              width="302px"
                              height="280px"
                            />
                            <GenericCardComponent
                              onClick={this.handleOpenEnvModal}
                              icon={GenericPin}
                              hasBorder
                              hasMargin
                              isCentered
                              width="302px"
                              height="280px"
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "30px" }}
                      className="dashboardMyDevices"
                    >
                      <div
                        className="EnvironmentHeadSection"
                        style={{ marginTop: "10px" }}
                      >
                        <h className="EnvironmentHead">My Devices</h>
                        <img src={ArrowIcon} alt=""></img>
                      </div>
                      {this.state?.getConnectedDevices?.data?.length > 0 ? (
                        <>
                          {this.state.getConnectedDevices?.data?.map((dev) => (
                            <div
                              className="dashboard_home__section"
                              // onClick={() => {
                              //   this.handleDeviceClick(dev);
                              //   if (this.state.activeSub === "Home") {
                              //     this.setState({
                              //       activeSub: "Devices",
                              //     });
                              //   }
                              // }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <div className="devicesMain">
                                <div>
                                  <img
                                    src={dev?.productImage}
                                    style={{
                                      width: "123px",
                                      height: "123px",
                                      borderRadius: "10px",
                                    }}
                                    alt=""
                                    className="devicemainimg"
                                  ></img>
                                </div>
                                <div className="deviceDetailsec">
                                  <h1 className="devName">
                                    {dev.name.length > 10
                                      ? dev.name.substring(0, 10) + "..."
                                      : dev.name}
                                  </h1>

                                  <h2 className="productName">
                                    {dev?.product}
                                  </h2>
                                  <div className="deviceEnvDiv">
                                    <h1>Environment</h1>
                                    <h2>{dev?.environment?.environmentName}</h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="dashboard_home__section">
                          <GenericCardComponent
                            button={{
                              text: "Register Device",
                            }}
                            onClick={this.goToAddDevicesFlow}
                            width="100%"
                            height="170px"
                            backgroundColor="white"
                          />
                          {this?.state?.getConnectedDevices?.data?.length >
                            0 && (
                            <GenericCardComponent
                              hasBorder
                              width="100%"
                              height="170px"
                            />
                          )}
                        </div>
                      )}

                      {/*                     
                        {console.log("this?.state?.pinnedEnviornments[0]?",this?.state?.pinnedEnviornments[0])}
                        {this?.state?.pinnedEnviornments[0]?.devices.map((dev)=>(
                           <div className="dashboard_home__section">
                           <div className="devicesMain">
                             <div>
                               <img
                                 src={Speaker}
                               ></img>
                             </div>
                             <div className="deviceDetailsec">
                               <h1 className="devName">
                                 {dev.name}
                               </h1>
                               <h2 className="productName">
                                 {dev.product}
                               </h2>
                               <div className="deviceEnvDiv">
                                 <h1>Environment</h1>
                                 <h2>
                                   {dev.environment}
                                 </h2>
                               </div>
                             </div>
                           </div>
                         </div>
                        ))} */}
                    </div>
                  </div>

                  <div className="MessageNotificationMobile">
                    <div className="dashboardNotificationWrapper">
                      <div
                        className="EnvironmentHeadSection EnvironmentHeadSectionMessages"
                        onClick={() =>
                          history.push(RoutesObj.Notifications.path)
                        }
                      >
                        <h
                          className="EnvironmentHead"
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        >
                          Notifications
                        </h>
                        <img
                          style={{ cursor: "pointer" }}
                          src={ArrowIcon}
                          alt=""
                        ></img>
                      </div>
                      {notifications && notifications?.length > 0 ? (
                        <>
                          <div className="notificationCard">
                            <div className="notificationBell">
                              <img
                                src={NotLabel}
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginRight: "10px",
                                }}
                                alt=""
                              ></img>
                            </div>
                            <div>
                              <p className="notificationBar">
                                {notifications[0]?.description}
                              </p>
                              <p className="notificationBar">
                                {notifications[1]?.description}
                              </p>
                              <p
                                className="notificationBar"
                                style={{
                                  background: "#FFF4DE",
                                }}
                              >
                                {notifications[2]?.description}
                              </p>
                            </div>
                          </div>
                          <div className="notificationCardMobile">
                            <div className="notificationBellMobile">
                              <img
                                src={MobileNotification}
                                style={{
                                  width: "69px",
                                  height: "82px",
                                  marginRight: "10px",
                                }}
                                alt=""
                              ></img>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#3F4254",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#1BBED0",
                                }}
                              >
                                NEW
                              </span>{" "}
                              Notifications
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <NotificationCard
                            icon={BellIcon}
                            title="No New Notifications"
                            backgroundColor="white"
                            borderColor="black"
                            iconStyle={{
                              width: "50px",
                              height: "50px",
                            }}
                            margin="20px"
                            height="330px"
                            className="notides"
                          />
                          <div className="notificationCardMobile">
                            <div className="notificationBellMobile">
                              <img
                                src={MobileNotification}
                                style={{
                                  width: "69px",
                                  height: "82px",
                                  marginRight: "10px",
                                }}
                                alt=""
                              ></img>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#3F4254",
                              }}
                            >
                              No New Notifications
                            </p>
                          </div>
                        </>
                      )}
                    </div>

                    {/* messages for productions  */}

                    <div className="dashboardNotificationWrapper">
                      <div
                        className="EnvironmentHeadSection EnvironmentHeadSectionMessages"
                        style={{ marginTop: 25 }}
                        onClick={() =>
                          history.push({
                            pathname: routesObj.UpdateMessages.path,
                          })
                        }
                      >
                        <h
                          className="EnvironmentHead"
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        >
                          Messages
                        </h>
                        <img src={ArrowIcon} alt=""></img>
                      </div>
                      {allMessage && allMessage?.length > 0 ? (
                        <>
                          <>
                            <div className="notificationCard">
                              <div className="notificationBell">
                                <img
                                  src={MessageBoxWithoutCount}
                                  style={{
                                    height: "60px",
                                    width: "60px",
                                    marginRight: "10px",
                                  }}
                                  alt=""
                                ></img>
                              </div>
                              <div
                                style={{ width: "100%" }}
                                className="indexMessages"
                              >
                                {allMessage?.slice(0, 3)?.map((message) => (
                                  <div
                                    className="MessagesBar"
                                    onClick={() =>
                                      history.push({
                                        pathname: routesObj.UpdateMessages.path,
                                        state: {
                                          roomId: message?.roomId,
                                        },
                                      })
                                    }
                                  >
                                    {!message.lastMessage.isRead ? (
                                      <img
                                        src={RedDot}
                                        style={{
                                          marginRight: "20px",
                                        }}
                                        alt=""
                                      />
                                    ) : null}
                                    <p className="messageTitle">
                                      {`AQUATIC AV - ID # ${message?.roomId}`}
                                      <br />

                                      <span>
                                        {
                                          <ReceivedMessage
                                            htmlContent={`${message?.lastMessage?.content?.slice(
                                              0,
                                              30
                                            )}`}
                                          />
                                        }
                                      </span>
                                      <br />
                                      <span className="messageTime">
                                        {getLastOnlineTimestamp(
                                          message.lastMessage.date
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* <div className="notificationCardMobile">
                            <div className="notificationBellMobile">
                              <img
                                src={MobileNotification}
                                style={{
                                  width: "69px",
                                  height: "82px",
                                  marginRight: "10px",
                                }}
                                alt=""
                              ></img>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#3F4254",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#1BBED0",
                                }}
                              >
                                NEW
                              </span>{" "}
                              Messages
                            </p>
                          </div> */}
                          </>
                        </>
                      ) : (
                        <>
                          <NotificationCard
                            icon={MessageBox}
                            title="No New Messages"
                            backgroundColor="white"
                            borderColor="black"
                            iconStyle={{
                              width: "50px",
                              height: "50px",
                            }}
                            margin="20px"
                            height="300px"
                            className="notides"
                          />
                          <div className="notificationCardMobile">
                            <div className="notificationBellMobile">
                              <img
                                src={MobileNotification}
                                style={{
                                  width: "69px",
                                  height: "82px",
                                  marginRight: "10px",
                                }}
                                alt=""
                              ></img>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#3F4254",
                              }}
                            >
                              No New Messages
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeSub === "Environment" && (
            <div>
              {!this.state.isDivVisible && (
                <div className="mainEnvirnomentSection">
                  <h1 className="envrionmentsMainHeading">My Enivornments</h1>
                  <h1 className="breadcrumbs">
                    Home /{" "}
                    <span
                      style={{
                        color: "#3F4254",
                        fontFamily: "Poppins",
                      }}
                    >
                      Enivornments
                    </span>
                  </h1>

                  <Grid
                    container
                    spacing={2}
                    // style={{
                    //   display: "flex",
                    //   flexWrap: "wrap",
                    //   marginRight: "15px",
                    //   marginBottom: "20px",
                    // }}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      lg={3}
                      className="envCardItem"
                    >
                      <GenericCardComponent
                        onClick={this.handleOpenCreateEnvModal}
                        icon={PlusIcon}
                        title="New Environment"
                        iconStyle={{
                          width: "72px",
                          height: "72px",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "10px",
                        }}
                        width="100%"
                        height="290px"
                        hasRight
                        // hasMargin
                        hasBorder
                        className="genericMobilecard"
                      />
                    </Grid>
                    {this.state.allEnv?.map((data) => (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        className="envCardItem"
                      >
                        <GenericCardComponent
                          onClick={() => this.handleClick(data)}
                          icon={data.icon}
                          title={this.truncateText(data?.name, 15)}
                          cardtitle={data?.name}
                          backgroundColor="white"
                          iconStyle={{
                            width: "72px",
                            height: "72px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "20px",
                          }}
                          className="genericMobilecard"
                          subheadings={[
                            {
                              text: "",
                              // data?.location?.length > 25
                              //   ? `${data?.location.slice(
                              //       0,
                              //       25
                              //     )}...${data?.location.slice(25)}`
                              //   : data?.location,
                              // style: {
                              //   fontFamily: "Roboto",
                              //   fontSize: "14px",
                              //   fontWeight: "700",
                              // },
                            },
                            {
                              text: data.devicesCount.toString(),
                              hasBorder: true,
                              width: "50%",
                              height: "50px",
                              style: {
                                fontFamily: "Roboto",
                                fontWeight: "700",
                                color: "#5E6278",
                              },
                            },
                            {
                              text: "Active Devices",
                              hasBorder: true,
                              style: {
                                fontFamily: "Roboto",
                                fontWeight: "500",
                                textAlign: "center",
                                color: "#B5B5C3",
                              },
                            },
                          ]}
                          width="250px"
                          height="290px"
                          hasRight
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
              {this.state.isDivVisible && (
                <div className="mainEnvirnomentSection">
                  <h1 className="envrionmentsMainHeading">My Enivornments</h1>
                  <h1 className="breadcrumbs">
                    Home /{" "}
                    <span style={{ color: "#B5B5C3" }}>
                      Enivornments /{" "}
                      <span style={{ color: "#3F4254" }}>
                        {this.state.selectedData?.name}
                      </span>
                    </span>
                  </h1>
                  <div
                    style={{ display: "flex" }}
                    className="desktopMyEnvdetail"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginRight: "15px",
                        marginBottom: "20px",
                      }}
                    >
                      <GenericCardComponent
                        onClick={this.handleClick}
                        icon={this.state.selectedData.icon}
                        title={this.truncateText(
                          this.state.selectedData?.name,
                          15
                        )}
                        cardtitle={this.state.selectedData?.name}
                        backgroundColor="white"
                        iconStyle={{
                          width: "72px",
                          height: "72px",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "20px",
                        }}
                        subheadings={[
                          {
                            // text: "123 Very Long Street Namecc",
                            // style: {
                            //   fontFamily: "Roboto",
                            //   fontSize: "14px",
                            //   fontWeight: 700,
                            // },
                          },
                          {
                            text: this.state.selectedData?.devicesCount?.toString(),
                            hasBorder: true,
                            width: "50%",
                            height: "50px",
                            style: {
                              fontFamily: "Roboto",
                              fontWeight: "700",
                              color: "#5E6278",
                            },
                          },
                          {
                            text: "Active Devices",
                            hasBorder: true,
                            style: {
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              textAlign: "center",
                              color: "#B5B5C3",
                            },
                          },
                        ]}
                        width="250px"
                        // height="290px"
                        hasRight
                      />
                    </div>

                    <div className="EnvironmentDetailCard">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={this.state.selectedData?.icon}
                          className="iconStyle"
                          alt=""
                        ></img>
                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                        >
                          {this.state.selectedData?.name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "5%",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            // justifyContent: "space-between",
                            flexWrap: "wrap",
                            paddingRight: "20px",
                            marginTop: "30px",
                          }}
                        >
                          {this.state.customEnvList?.map((icon, index) => (
                            <span
                              className={
                                this.state.selectedEnv?.environmentId ===
                                  icon?.environmentId &&
                                this.state.selectedEnv?.key === "custom"
                                  ? "selectedEnv"
                                  : ""
                              }
                              style={{
                                marginRight: "30px",
                                marginTTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                key={index}
                                src={icon?.environmentIcon}
                                alt={`icon ${index}`}
                                style={{
                                  width: "70px",
                                  height: "70px",
                                }}
                                onClick={() => {
                                  this.onSelectEnv(icon, "custom");
                                  this.setState({
                                    selectedenvironment: icon.environmentId,
                                  });
                                }}
                              />
                              {/* <p style={{ textAlign: "center", }}>
                          {
                            icon.environmentName
                          }
                        </p> */}
                            </span>
                          ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "end",
                            height: "100px",
                          }}
                        >
                          <input
                            type="text"
                            className="inputenv"
                            value={this.state.changeEnvName}
                            onChange={(e) =>
                              this.setState({
                                changeEnvName: e.target.value,
                              })
                            }
                            maxLength={30}
                            placeholder="Environment Name"
                          />
                          <img
                            onClick={() =>
                              this.onUpdateEnv(this.state.selectedData)
                            }
                            src={TickBox}
                            alt=""
                          ></img>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img src={Pin} onClick={this.onMarkPinEnv} alt="" />
                          <img
                            src={DeleteIcon}
                            onClick={this.handleOpenDelEnvModal}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobileMyEnvDetail">
                    <div className="mobileMyEnv">
                      <span style={{ marginLeft: "20px" }}>
                        <img
                          src={this.state.selectedData.icon}
                          className="iconStyle"
                          alt=""
                        />
                      </span>
                      <div className="mobileMyEnvDetail">
                        <h1
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#3f4254",
                            fontFamily: "Poppins",
                          }}
                        >
                          {this.state.selectedData?.name}
                        </h1>
                        <div className="mobileDeviceCount">
                          <h1
                            style={{
                              fontSize: "10px",
                              fontFamily: "Poppins",
                              fontWeight: 700,
                              color: "#5E6278",
                            }}
                          >
                            {this.state.selectedData?.devicesCount?.toString()}
                          </h1>
                          <h1
                            style={{
                              fontSize: "9px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              color: "#B5B5C3",
                            }}
                          >
                            Active Devices
                          </h1>
                        </div>
                      </div>
                      {/* {!this.state.editEnv && ( */}

                      <div
                        className="editEnv"
                        onClick={() =>
                          this.setState({
                            editEnv: !this.state.editEnv,
                          })
                        }
                      >
                        <img src={EditEnv} alt="" />
                      </div>
                      {/* )} */}
                    </div>
                    {this.state.editEnv && (
                      <div className="">
                        <div
                          style={{
                            display: "flex",
                            //  flexDirection: "column",
                            //  alignItems: "center",
                            //  justifyContent: "center",
                          }}
                          className="editEnvTopContainer"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                              height: "100px",
                            }}
                            className="mobileEnvInput"
                          >
                            <input
                              type="text"
                              className="inputenv"
                              value={this.state.changeEnvName}
                              onChange={(e) =>
                                this.setState({
                                  changeEnvName: e.target.value,
                                })
                              }
                              placeholder="Environment Name"
                              maxLength={30}
                            />
                          </div>
                        </div>
                        <div className="editEnvWrapper">
                          <div className="editEnvInnerWrapper">
                            {this.state.customEnvList.map((icon, index) => (
                              <span
                                className={
                                  this.state.selectedEnv?.environmentId ===
                                    icon?.environmentId &&
                                  this.state.selectedEnv?.key === "custom"
                                    ? "selectedEnv"
                                    : "editEnvImgContainer"
                                }
                              >
                                <img
                                  key={index}
                                  src={icon?.environmentIcon}
                                  alt={`icon ${index}`}
                                  style={{
                                    width: 70,
                                    height: 70,
                                  }}
                                  onClick={() => {
                                    this.onSelectEnv(icon, "custom");
                                    this.setState({
                                      selectedenvironment: icon.environmentId,
                                    });
                                  }}
                                />
                                {/* <p style={{ textAlign: "center", }}>
                               {
                                 icon.environmentName
                               }
                             </p> */}
                              </span>
                            ))}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            <img
                              src={MobilePin}
                              onClick={this.onMarkPinEnv}
                              alt=""
                            />
                            <img
                              src={MobileDelete}
                              onClick={this.handleOpenDelEnvModal}
                              alt=""
                            />
                            <img
                              onClick={() =>
                                this.onUpdateEnv(this.state.selectedData)
                              }
                              src={MobileTick}
                              alt=""
                            ></img>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="deviceDetailWrapper">
                    {this.state?.deviceDetailDev?.map((device) => (
                      <div className="DeviceDetailEnv">
                        <div style={{ display: "flex" }}>
                          <div className="deviceimgEnv">
                            <img
                              src={device.productImage}
                              style={{
                                height: "60px",
                                width: "60px",
                                borderRadius: "10px",
                              }}
                              alt=""
                            ></img>
                          </div>
                          <div className="deviceDetailHeading">
                            <h1>{device.name}</h1>
                            <h2>{device.dealer}</h2>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">SKU</h1>
                          <h1 className="devicePin">{device.sku}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">Serial Number</h1>
                          <h1 className="devicePin">{device.serialNo}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">Last Connected</h1>
                          <h1 className="devicePin">{device.lastConnected}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">
                            Warranty Status
                          </h1>
                          <h1 className="devicePin">{device.status}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px 0",
                            alignItems: "center",
                          }}
                        >
                          <h1
                            style={{
                              backgroundColor:
                                device?.status === "Receipt Missing"
                                  ? "#E0F0FA"
                                  : device?.status === "Registration Pending" ||
                                    device?.status === "Pending"
                                  ? " #F2C94C26"
                                  : device?.status === "Incomplete" ||
                                    device?.status === "Incomplete"
                                  ? " #F2C94C26"
                                  : device?.status === "Registered" ||
                                    device?.status === "Register" ||
                                    device?.status === "Approved"
                                  ? "#DEEFE5"
                                  : device?.status === "Rejected" ||
                                    device?.status === "Denied" ||
                                    device?.status === "Registration Denied" ||
                                    device?.status === "Blocked"
                                  ? "#FCE6E6"
                                  : "black", // Default text color
                              color:
                                device?.status === "Receipt Missing"
                                  ? "#2D9CDB"
                                  : device?.status === "Registration Pending" ||
                                    device?.status === "Pending"
                                  ? "#E1B93E"
                                  : device?.status === "Incomplete" ||
                                    device?.status === "Incomplete"
                                  ? "#E1B93E"
                                  : device?.status === "Registered" ||
                                    device?.status === "Register" ||
                                    device?.status === "Approved"
                                  ? "#219653"
                                  : device?.status === "Rejected" ||
                                    device?.status === "Denied" ||
                                    device?.status === "Registration Denied" ||
                                    device?.status === "Blocked"
                                  ? "#EB5757"
                                  : "black", // Default text color
                              width: "164px",
                              height: "28px",
                              padding: "4px 8px 4px 8px",
                              borderRadius: "6px",
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "Poppins",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {device?.status}
                          </h1>
                          <img src={EditIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* )} */}
            </div>
          )}

          {activeSub === "Devices" && (
            <div className="mainEnvirnomentSection">
              <h1
                style={{
                  marginLeft: !this.state.openDeiveInfo ? "2%" : 0,
                }}
                className="envrionmentsMainHeading"
              >
                My Devices
              </h1>
              {!this.state.openDeiveInfo && (
                <h1 style={{ marginLeft: "2%" }} className="breadcrumbs">
                  Home / Devices{" "}
                  <span style={{ color: "#3F4254" }}>
                    {this.passDeviceInfo?.name}
                  </span>
                </h1>
              )}
              {!this.state.openDeiveInfo ? (
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                  className="deviceInfoTab"
                >
                  <div
                    className="DeviceDetailEnvCenter"
                    onClick={this.goToAddDevicesFlow}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      style={{
                        // display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="centeredIcon">
                        <img src={NewDevice} alt=""></img>
                      </div>
                    </div>
                  </div>
                  {this.state?.deviceInfo?.map((device) => {
                    return (
                      <div
                        className="DeviceDetailEnv_"
                        onClick={() => {
                          this.handleDeviceClick(device);
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div className="deviceimgEnv">
                            <img
                              src={device.productImage}
                              style={{
                                height: "60px",
                                width: "60px",
                                borderRadius: "10px",
                              }}
                              alt=""
                            ></img>
                          </div>
                          <div className="deviceDetailHeading">
                            <Tooltip title={device.name}>
                              <h1 className="deviceDetailName">
                                {device.name.length > 25
                                  ? `${device.name.slice(0, 20)}...`
                                  : device.name}
                              </h1>
                            </Tooltip>
                            <Tooltip title={device.product}>
                              <h2>
                                {" "}
                                {device.product.length > 25
                                  ? `${device.product.slice(0, 20)}...`
                                  : device.product}
                              </h2>
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed #E4E6EF",
                            padding: "7px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">SKU</h1>
                          <h1 className="devicePin">{device.sku}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed #E4E6EF",
                            padding: "7px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">Serial Number</h1>
                          <h1 className="devicePin">{device.serialNo}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed #E4E6EF",
                            padding: "7px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">Enviornment</h1>
                          <Tooltip title={device.environment}>
                            <h1 className="devicePin">
                              {this.truncateText(device.environment, 15)}
                            </h1>
                          </Tooltip>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed #E4E6EF",
                            padding: "7px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">Last Connected</h1>
                          <h1 className="devicePin">{device.lastConnected}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "7px 0",
                          }}
                        >
                          <h1 className="deviceDetailPoints">
                            Warranty Status
                          </h1>
                          <h1 className="devicePin">
                            {device?.warrantyStatus}
                          </h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "7px 0",
                            alignItems: "center",
                          }}
                        >
                          <h1
                            style={{
                              backgroundColor:
                                device?.status === "Receipt Missing"
                                  ? "#E0F0FA"
                                  : device?.status === "Registration Pending" ||
                                    device?.status === "Pending"
                                  ? " #F2C94C26"
                                  : device?.status === "Registered" ||
                                    device?.status === "Register" ||
                                    device?.status === "Approved"
                                  ? "#DEEFE5"
                                  : device?.status === "Incomplete" ||
                                    device?.status === "Incomplete"
                                  ? " #F2C94C26"
                                  : device?.status === "Rejected" ||
                                    device?.status === "Denied" ||
                                    device?.status === "Registration Denied" ||
                                    device?.status === "Blocked"
                                  ? "#FCE6E6"
                                  : "black", // Default text color
                              color:
                                device?.status === "Receipt Missing"
                                  ? "#2D9CDB"
                                  : device?.status === "Registration Pending" ||
                                    device?.status === "Pending"
                                  ? "#E1B93E"
                                  : device?.status === "Incomplete" ||
                                    device?.status === "Incomplete"
                                  ? "#E1B93E"
                                  : device?.status === "Registered" ||
                                    device?.status === "Register" ||
                                    device?.status === "Approved"
                                  ? "#219653"
                                  : device?.status === "Rejected" ||
                                    device?.status === "Denied" ||
                                    device?.status === "Registration Denied" ||
                                    device?.status === "Blocked"
                                  ? "#EB5757"
                                  : "black", // Default text color
                              width: "164px",
                              height: "28px",
                              padding: "4px 8px 4px 8px",
                              borderRadius: "6px",
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "Poppins",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {device?.status}
                          </h1>
                          <img src={EditIcon} alt="" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          )}

          {this.state.openDeiveInfo && (
            <>
              <DetailedDeviceInfo
                passDeviceInfo={this.state.passDeviceInfo}
                onCloseDeviceInfo={this.closeDeviceInfo}
                onGetUserDevices={this.onGetUserDevices}
                onUpdatePassDeviceInfo={this.updatePassDeviceInfo}
              />
            </>
          )}

          <Modal
            open={this.state.viewDelEnvModal}
            onClose={this.handleCloseDelEnvModal}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0) !important",
            }}
          >
            <Box
              sx={style}
              style={{ width: "40%" }}
              className="MobileDeleteEnvModal"
            >
              <div className="envModal">
                <div className="CrossSection">
                  <img
                    src={CrossBox}
                    onClick={this.handleCloseDelEnvModal}
                    alt=""
                  />
                </div>
                <div
                  className="EnvMainSection"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1 className="delEnvModal" style={{ textAlign: "center" }}>
                    Would you like to delete this environment?
                  </h1>
                  <div style={{ marginTop: "15px" }}>
                    <button
                      onClick={this.handleCloseDelEnvModal}
                      className="envModalCancelBtn"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={this.onDeleteEnv}
                      className="envModaldelBtn"
                    >
                      Delete
                    </button>
                    <h1 className="helpSection">Neep help?</h1>
                    <h1 className="supportSection">
                      Contact Aquatic AV Support
                    </h1>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={this.state.viewEnvModal}
            onClose={this.handleCloseEnvModal}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0) !important",
            }}
          >
            <Box
              sx={style}
              style={{ width: "900px", height: "340px" }}
              className="pinEnvmodal"
            >
              <div className="envModal">
                <div className="CrossSection">
                  <img
                    src={CrossBox}
                    onClick={this.handleCloseEnvModal}
                    alt=""
                  />
                </div>
                <div className="EnvMainSection">
                  <h1 className="addEnvModalM" style={{ textAlign: "center" }}>
                    Choose an Environment to Pin{" "}
                    <img
                      src={Pin}
                      onClick={this.onMarkPinEnvModal}
                      style={{ marginLeft: 20 }}
                      alt=""
                    />
                  </h1>
                  <h1 className="myenvHead">My Environments</h1>
                  <div className="envPinWrapper">
                    {this.state?.allEnv?.slice(0, 6).map((item, i) => (
                      <div
                        key={i}
                        className={
                          this.state.selectedEnv?.id === item?.id &&
                          this.state.selectedEnv?.key === "custom"
                            ? "selectedEnv"
                            : "envPinInnerCard"
                        }
                      >
                        <img
                          src={item?.icon}
                          onClick={() => this.onSelectEnv(item, "custom")}
                          alt=""
                          width={61}
                          height={61}
                        ></img>
                        <h6 className="iconText">
                          {this.truncateText(item.name, 10)}
                        </h6>
                      </div>
                    ))}

                    {/* <div>
                      <img src={MotorcyleEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Motorcycle</h6>
                    </div>
                    <div>
                      <img src={MarineEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Marine</h6>
                    </div>
                    <div>
                      <img src={OutDoorEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Outdoor Living</h6>
                    </div>
                    <div>
                      <img src={GolfEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Golf Cart</h6>
                    </div>
                    <div>
                      <img src={PowerSportsEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Powersports</h6>
                    </div> */}
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={this.state.viewEnvCreateModal}
            onClose={this.handleCloseCreateEnvModal}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0) !important",
            }}
          >
            <Box
              sx={style}
              style={{ width: "65%", height: "697px" }}
              className="envModalMobile"
            >
              <div className="envModal">
                <div className="CrossSection">
                  <img
                    src={CrossBox}
                    onClick={this.handleCloseCreateEnvModal}
                    alt=""
                  />
                </div>
                <div className="CrossSectionMobile">
                  <img
                    src={CrossBold}
                    onClick={this.handleCloseCreateEnvModal}
                    alt=""
                  />
                  <img src={Mobilelogo} alt="" />
                  <div></div>
                </div>
                <div className="addEnvMobile">
                  <img src={CreateEnv} alt="" />
                </div>
                <div className="EnvMainSection">
                  <h1 className="addEnvModal">
                    Create or Choose an Aquatic AV Environment
                  </h1>
                  <h1 className="addEnv_Modal">New Environment</h1>
                  <h1 className="addEnv_Modal_">Create an Environment</h1>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "#000000",
                    }}
                    className="addEnvDesc"
                  >
                    Organize your Aquatic AV devices by installation
                    environment. Select a preset environment or create a custom
                    environment where your Aquatic AV product will be installed.
                  </p>
                  <div className="EnvMainSection">
                    {/* <h1 className="addEnvModalM" style={{ textAlign: "center" }}>
                    Choose an Environment to Pin{" "}
                    <img
                      src={Pin}
                      onClick={this.onMarkPinEnvModal}
                      style={{ marginLeft: 20 }}
                      alt=""
                    />
                  </h1> */}
                    <h1 className="myenvHead">My Environments</h1>
                    <div className="envPinWrapper">
                      {this.state?.allEnv?.map((item, i) => (
                        <div
                          key={i}
                          className={
                            this.state.selectedEnv?.id === item?.id &&
                            this.state.selectedEnv?.key === "custom"
                              ? "selectedEnv"
                              : "envPinInnerCard"
                          }
                        >
                          <img
                            src={item?.icon}
                            onClick={() => this.onSelectEnv(item, "custom")}
                            alt=""
                            width={61}
                            height={61}
                          ></img>
                          <h6 className="iconText">
                            {this.truncateText(item.name, 10)}
                          </h6>
                        </div>
                      ))}

                      {/* <div>
                      <img src={MotorcyleEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Motorcycle</h6>
                    </div>
                    <div>
                      <img src={MarineEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Marine</h6>
                    </div>
                    <div>
                      <img src={OutDoorEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Outdoor Living</h6>
                    </div>
                    <div>
                      <img src={GolfEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Golf Cart</h6>
                    </div>
                    <div>
                      <img src={PowerSportsEnv}></img>
                      <h6 style={{ textAlign: "center" }}>Powersports</h6>
                    </div> */}
                    </div>
                  </div>

                  <h1 className="myenvHead">Preset Environment</h1>
                  <div
                    // style={{
                    //   display: "flex",
                    //   flexWrap: "wrap",
                    //   justifyContent: "space-between",
                    //   alignItems: "center",
                    //   marginTop: "30px",
                    //   marginBottom: "30px",
                    // }}
                    className="preEnv customRevEnvInner"
                  >
                    {this.state.staticEnvList?.map((env, i) => {
                      return (
                        <div
                          className="mobilepresenv "
                          key={i}
                          onClick={() => this.onSelectEnv(env, "preset")}
                        >
                          {/* <span
                              className={
                                this.state.selectedEnv
                                  ?.environmentId ===
                                  env?.environmentId &&
                                this.state.selectedEnv
                                  ?.key === "preset"
                                  ? "selectedEnv"
                                  : ""
                              }
                            > */}
                          <span
                            className={
                              this.state.selectedEnv?.environmentId ===
                                env?.environmentId &&
                              this.state.selectedEnv?.key === "preset"
                                ? "selectedEnvNew"
                                : ""
                            }
                          >
                            <img
                              src={env?.environmentIcon}
                              style={{
                                width: 70,
                                height: 70,
                              }}
                              alt=""
                            ></img>
                            {/* </span> */}
                          </span>
                          <h6
                            style={
                              {
                                // textAlign: "center",
                                // width: 70,
                                // height: 70,
                                // textTransform: "Uppercase",
                              }
                            }
                            className="EnvName"
                          >
                            {env?.environmentName}
                          </h6>
                          <span className="toolTip">
                            {" "}
                            {env?.environmentName}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <h1 className="myenvHead" style={{ marginTop: "7px" }}>
                    Custom Environment
                  </h1>
                  <input
                    type="text"
                    className="EnvInput"
                    placeholder="Environment Name"
                    value={this.state.customEnvName}
                    onChange={(e) =>
                      this.setState({
                        customEnvName: e.target.value,
                      })
                    }
                    maxLength={30}
                  />
                  <div className="customEnvInner">
                    {this.state.customEnvIcons?.map((icon, index) => (
                      <span
                        className={
                          this.state.selectedEnv?.environmentId === icon?.id &&
                          this.state.selectedEnv?.key === "custom"
                            ? "selectedEnv"
                            : "mobileEnv"
                        }
                        style={{
                          marginRight: "30px",
                          marginTTop: "20px",
                        }}
                      >
                        <img
                          key={index}
                          src={icon?.icon}
                          alt={`icon ${index}`}
                          style={{
                            width: 70,
                            height: 70,
                          }}
                          onClick={() =>
                            this.onSelectEnv(
                              {
                                ...icon,
                                environmentId: icon?.id,
                                environmentIcon: icon?.icon,
                              },
                              "custom"
                            )
                          }
                        />
                      </span>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      width: "100%",
                      justifyContent: "end",
                      paddingTop: "20px",
                    }}
                    className="SaveButton"
                    onClick={() => this.onAddEnv()}
                  >
                    <button className="MobileButton">Save</button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={this.state.showAddNewDeviceModal}
            onClose={this.handleCloseNewDeviceModal}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0) !important",
            }}
          >
            <Box
              sx={style}
              style={{ width: "80%", height: "90%" }}
              className="mobileAddDeviceBox"
            >
              <div className="CrossSection" style={{ cursor: "pointer" }}>
                <img
                  src={CrossBox}
                  onClick={this.handleCloseNewDeviceModal}
                  alt=""
                />
              </div>
              <div
                className="CrossSectionMobile_"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={CrossBox}
                  onClick={this.handleCloseNewDeviceModal}
                  alt=""
                />
              </div>
              <AddDeviceFlow
                noEnvHeading
                onClose={this.onCloseDeviceModalAndRefresh}
              />
            </Box>
          </Modal>
        </div>
        {showFooter ? (
          <div className={footerClassName}>
            <UserRegistrationFooter />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.DashboardReducer,
  spaOverviewReducer: state.SpaOverviewReducer,
  AccountReducer: state.AccountReducer,
  PriceListReducer: state.PriceListReducer,
  deviceOverviewState: state.DeviceOverviewReducer,

  recData: state.conversationReducer,
});

export default connect(mapStateToProps, (dispatch) => ({
  actions: bindActionCreators(dashboardActions, dispatch),
  SpaOverviewAction: bindActionCreators(SpaOverviewAction, dispatch),
  deviceOverviewAction: bindActionCreators(deviceOverviewAction, dispatch),
  PriceListAction: bindActionCreators(PriceListAction, dispatch),
  conversationAction: bindActionCreators(conversationAction, dispatch),
}))(withRouter(Dashboard));
