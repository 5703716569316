import React, { Component } from "react";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import CustomSwitch from "../../../components/common/Switch/CustomSwitch";
import { Loader } from "../../../components/common/index";

import "../tabs.css";

let spaDeviceId;

class Temperature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      temperstureScale: "F",
      tempLock: false,
      maintenanceMode: false,
    };
  }

  componentDidMount() {
    spaDeviceId = this?.props?.location?.state?.SpaId;
    console.log(
      "settings:",
      this?.props?.spaOverviewReducer?.spaTemperatureSettings
    );
    const { spaTemperatureSettings } = this?.props?.spaOverviewReducer;
    if (spaTemperatureSettings) {
      this.setState({
        temperstureScale:
          spaTemperatureSettings?.temperatureControl == 2 ? "C" : "F",
        tempLock: spaTemperatureSettings?.isTemperatureLock,
        maintenanceMode: spaTemperatureSettings?.isMaintenanceLock,
      });
    }
  }

  dispatchTempCall = (value) => {
    this.setState({
      isLoading: true,
    });

    this.props.actions.onUpdataTempSetting(value).then((res) => {
      if (res?.success) {
        this.props.actions.onGetSpaInfo(spaDeviceId).then((data) => {
          this.setState({
            isLoading: false,
          });

          if (data?.success === false) {
            toast.error(data?.message);
          } else {
            toast.success("Value updated successfully.");
          }
        });
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error(res?.message);
      }
    });
  };

  handleScaleChange = (event, newVal, stateName) => {
    console.log("test:", newVal, stateName, this.state.maintenanceMode);

    // event.preventDefault();
    this.setState({
      [stateName]: newVal,
    });
    // event &&
    // setTempratureScale(newVal);
  };

  onSaveChanges = () => {
    const scalePayload = {
      deviceId: spaDeviceId,
      temperatureControl: this.state.temperstureScale === "C" ? 2 : 1,
      isTemperatureLock: this.state.tempLock,
      isMaintenanceLock: this.state.maintenanceMode,
    };
    this.dispatchTempCall(scalePayload);
  };

  onHandleScale = (event) => {
    console.log("event:::::", event.target.value);

    this.setState({
      temperstureScale: event.target.value,
    });
  };

  render() {
    // const { isPlaying, trackProgress, isLoading } = this.state;
    const { spaOverviewReducer, dashboardState, homeState } = this.props;
    // const { userInfo, userEnv } = dashboardState;
    // const { history } = this.props;
    // let userNameS = "";
    // if (userInfo) {
    //   userNameS = `${userInfo.firstName}${`'s `}`;
    // }

    return (
      <div
        className="tab-pane fade show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <div className="filterheader">
          <h4 className="Filterheading">Temperature</h4>
          <p onClick={this.onSaveChanges} className="filtersavebutton">
            Save
          </p>
        </div>

        {this.state.isLoading && <Loader />}

        <form>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label subheadingsfilters">
              Temperature control
            </label>
            <div className="col-sm-5">
              <div className="d-flex justify-content-end">
                {/* <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Temperature control"
                  value={
                    spaOverviewReducer.spaTemperatureSettings
                      .temperatureControl === 1
                      ? "Farenheit (ºF)"
                      : "Celsius (ºC)"
                  }
                  // disabled={true}
                /> */}
                <select
                  name="temperature"
                  id="temperature"
                  className="selectionDropdown"
                  value={this?.state?.temperstureScale}
                  onChange={
                    this.state.maintenanceMode ? () => {} : this.onHandleScale
                  }
                >
                  <option value="F">Farenheit (ºF)</option>
                  <option value="C">Celsius (ºC)</option>
                </select>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn edit text-right w-30"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label subheadingsfilters">
              Heat mode
            </label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input durations"
                  placeholder="Heat mode"
                  value={spaOverviewReducer.spaTemperatureSettings.heatMode}
                  disabled={true}
                  style={{ marginRight: "16%" }}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-30"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label subheadingsfilters">
              Temperature lock
            </label>
            <div className="col-sm-5">
              <div className="d-flex align-items-center justify-content-end">
                <div style={{ marginRight: "20%", marginTop: "5px" }}>
                  <CustomSwitch
                    onClick={(e) => {
                      console.log(
                        "maintenance props:",
                        this.state.maintenanceMode
                      );
                      if (!this.state.maintenanceMode) {
                        this.handleScaleChange(e, e.target.checked, "tempLock");
                      }
                    }}
                    checked={this.state.tempLock}
                  />
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-40"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>{" "}
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label subheadingsfilters">
              Maintenance mode
            </label>
            <div className="col-sm-5">
              <div className="d-flex justify-content-end align-items-center">
                <div style={{ marginRight: "20%", marginTop: "5px" }}>
                  <CustomSwitch
                    onClick={(e) =>
                      this.handleScaleChange(
                        e,
                        e.target.checked,
                        "maintenanceMode"
                      )
                    }
                    checked={this.state.maintenanceMode}
                  />
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-70"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(Temperature));
