import { dashboardTypes } from "./dashboardTypes";
import { spaOverviewTypes } from "../SpaOverview/SpaOverviewTypes";
import { dashboardApi } from "./dashboardApi";
import _ from "lodash";
import { spaOverviewApi } from "../SpaOverview/SpaOverviewApi";

const onLoadData = (deviceInfo, userInfo) => {
  let currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
  let deviceData = [];
  if (deviceInfo.length > 0 && currentEnv !== null) {
    if (deviceInfo[0].device_id !== -1) {
      deviceData = _.filter(deviceInfo, (o) => {
        if (o.env_name) {
          return o.env_name
            .toLowerCase()
            .includes(currentEnv.env_name.toLowerCase());
        }
      });
    }
  }
  return (dispatch) => {
    dispatch({
      type: dashboardTypes.DASHBOARD_LOAD_DATA,
      payload: { value: deviceData, value1: userInfo },
    });
    if (deviceInfo && deviceInfo.length > 0 && deviceInfo[0].device_id !== -1) {
      dispatch({
        type: dashboardTypes.DASHBOARD_ONCHANGE,
        payload: { prop: "myDeviceBox", value: deviceInfo },
      });
    } else {
      dispatch({
        type: dashboardTypes.DASHBOARD_ONCHANGE,
        payload: { prop: "myDeviceBox", value: [] },
      });
    }
  };
};

const onGetData = (email, password) => async (dispatch) => {
  let response = await dashboardApi.getData(email, password);
  // if (response.status === 200 && response.data.user_info.user_exist === "True") {
  //     dispatch({
  //         type: dashboardTypes.DASHBOARD_LOAD_DATA,
  //         payload: { value: response.data.devices_info, value1: response.data.user_info }
  //     });
  // }
  return response;
};

const onUserGetData = (userId) => async (dispatch) => {
  let response = await dashboardApi.getUserData(userId);
  // if (response.status === 200 && response.data.user_info.user_exist === "True") {
  //     dispatch({
  //         type: dashboardTypes.DASHBOARD_LOAD_DATA,
  //         payload: { value: response.data.devices_info, value1: response.data.user_info }
  //     });
  // }
  return response;
};
const onGetUserDevices = (userId, type) => async (dispatch) => {
  let response;
  if (JSON.parse(localStorage.getItem("userInfo")).customerType === 3) {
    response = await dashboardApi.getCompanyDevices(
      JSON.parse(localStorage.getItem("userInfo")).companyId
    );
  } else {
    response = await dashboardApi.getUserDevices(userId, type);
  }
  return response;
};

const onUpdatePersonal = (reqObj) => async (dispatch) => {
  let response = await dashboardApi.updatePersonal(reqObj);
  return response;
};

const onUpdateAddress =
  (address, city, stateCode, postalCode, country, phoneNumber) =>
  async (dispatch) => {
    let response = await dashboardApi.updateAddress(
      address,
      city,
      stateCode,
      postalCode,
      country,
      phoneNumber
    );
    return response;
  };

const onGetEnvironment = (userEmail) => async (dispatch) => {
  let response = await dashboardApi.getEnvironment(userEmail);
  if (response && response.data && response.data.responseCode === 1) {
    dispatch({
      type: dashboardTypes.DASHBOARD_SET_ENV,
      payload: response.data.responseData,
    });
  } else {
    dispatch({
      type: dashboardTypes.DASHBOARD_SET_ENV,
      payload: [],
    });
  }
  return response;
};
const onAddDevice =
  (
    envName,
    envIcon,
    deviceName,
    serialNo,
    purchaseLoc,
    purchaseDate,
    selectedProduct,
    recepit,
    dealerId
  ) =>
  async (dispatch) => {
    let response = await dashboardApi.addDevice(
      envName,
      envIcon,
      deviceName,
      serialNo,
      purchaseLoc,
      purchaseDate,
      selectedProduct,
      recepit,
      dealerId
    );
    return response;
  };

const onGetAllProductType = () => async (dispatch) => {
  let response = await dashboardApi.getAllProductType();
  if (response.status === 200) {
    dispatch({
      type: dashboardTypes.GET_PRODUCT_TYPE,
      payload: response.data.data.data,
    });
  }
  return response;
};

const onGetAllCities = () => async (dispatch) => {
  let response = await dashboardApi.getAllCities();

  return response;
};

const onGetAllStates = () => async (dispatch) => {
  let response = await dashboardApi.getAllStates();

  return response;
};

const onGetAllCountries = () => async (dispatch) => {
  let response = await dashboardApi.getAllCountries();

  return response;
};

const onGetProducts = (value) => async (dispatch) => {
  let response = await dashboardApi.getProducts(value);
  dispatch({
    type: dashboardTypes.GET_PRODUCT,
    payload: response.data.data[0],
  });
  return response;
};

const getAllDealers = (categoryId) => async (dispatch) => {
  let response = await dashboardApi.allDealers(categoryId);
  if (response.status === 200 && response.data.data.length > 0) {
    dispatch({
      type: dashboardTypes.GET_DEALER_DATA,
      payload: response.data.data,
    });
  }
  return response;
};

const onUpdateDevName = (devName, devId) => async (dispatch) => {
  let response = await dashboardApi.updateDeviceName(devName, devId);
  return response;
};
const onUpdateSpaTopside = (devName, topsideId) => async (dispatch) => {
  let response = await dashboardApi.updateDeviceTopside(devName, topsideId);
  return response;
};
const onUpdateSpaPack = (devName, packId) => async (dispatch) => {
  let response = await dashboardApi.updateDevicePack(devName, packId);
  return response;
};
const onUpdateSpaGateway = (devName, gatewayId) => async (dispatch) => {
  let response = await dashboardApi.updateDeviceGateway(devName, gatewayId);
  return response;
};
const onUpdateDeviceInfo = (devInfo) => async (dispatch) => {
  let response = await dashboardApi.updateDeviceinfo(devInfo);
  return response;
};

const onUpdateDeviceEnv =
  (deviceId, envName, envIconName, envId) => async (dispatch) => {
    let response = await dashboardApi.updateMyDeviceEnv(
      deviceId,
      envName,
      envIconName,
      envId
    );
    return response;
  };

const onReplaceImage = (deviceId, receipt) => async (dispatch) => {
  let response = await dashboardApi.replaceImage(deviceId, receipt);
  return response;
};

const onGetHeaderEnvironment = (userId, companyId) => async (dispatch) => {
  let currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
  // let response = await dashboardApi.getEnvironment(email);
  let response;
  if (companyId) {
    response = await dashboardApi.getComapnyEnv(companyId);
  } else {
    response = await dashboardApi.getMyEnv(userId);
  }

  if (response.status === 200 && response.data.success) {
    if (
      currentEnv === null ||
      currentEnv.environmentId !== response.data.data[0].environmentId
    ) {
      dispatch({
        type: dashboardTypes.SET_DEVICEINFO,
        payload: {
          envData: response.data.data,
        },
      });
      dispatch({
        type: dashboardTypes.DASHBOARD_SET_ENV,
        payload: response.data.data,
      });
      localStorage.setItem(
        "currentEnv",
        response.data.data[0] ? JSON.stringify(response.data.data[0]) : null
      );
    }
  }
  return response;
};

const onGetDeviceInfo = (userId, envId) => async (dispatch) => {
  dispatch({
    type: dashboardTypes.DASHBOARD_ONCHANGE,
    payload: { prop: "devicesInfo", value: [] },
  });
  dispatch({
    type: dashboardTypes.DASHBOARD_ONCHANGE,
    payload: { prop: "loadingDashboard", value: true },
  });

  // let response = await dashboardApi.getDashboardData(userId, envId);

  let response;
  if (JSON.parse(localStorage.getItem("userInfo")).customerType === 3) {
    response = await dashboardApi.getCompanyDevices(
      JSON.parse(localStorage.getItem("userInfo")).companyId
    );
  } else {
    response = await dashboardApi.getDashboardData(userId, envId);
  }

  dispatch({
    type: dashboardTypes.DASHBOARD_ONCHANGE,
    payload: { prop: "loadingDashboard", value: false },
  });
  if (response && response.data && response.data.success) {
    response.data.data.forEach((value, index) => {
      if (value.productType.includes("SPA")) {
        spaOverviewApi.getSpaInfo(value.id).then((spaOverviewResponse) => {
          dispatch({
            type: spaOverviewTypes.SET_SPA,
            payload: spaOverviewResponse.data,
          });
        });
      }
    });
    dispatch({
      type: dashboardTypes.DASHBOARD_ONCHANGE,
      payload: { prop: "devicesInfo", value: response.data.data },
    });
  } else {
    dispatch({
      type: dashboardTypes.DASHBOARD_ONCHANGE,
      payload: { prop: "devicesInfo", value: [] },
    });
  }

  return response;
};

const onDublicateSerial = (serialNo) => async (dispatch) => {
  let response = await dashboardApi.isDublicateSerial(serialNo);
  return response;
};

const onGetAllEnvironment = (userId) => async (dispatch) => {
  let response = await dashboardApi.getAllEnv(userId);
  if (response && response.data && response.data.success) {
    dispatch({
      type: dashboardTypes.ADD_DEVICE_ENV,
      payload: response.data.data,
    });
  }
  return response;
};

const onGetStaticEnvironment = () => async (dispatch) => {
  let response = await dashboardApi.getStaticEnv();
  if (response && response.data && response.data.success) {
  }
  return response;
};

const onAddDashboardDevice =
  (deviceData, envName, envId) => async (dispatch) => {
    let response = await dashboardApi.addDashboardDevice(
      deviceData,
      envName,
      envId
    );
    return response;
  };

const onAddDashboardEnv = (req) => async (dispatch) => {
  let response = await dashboardApi.addDashboardEnv(req);
  return response;
};

const onGetDashboardData = (userId, userEnv, userInfo) => async (dispatch) => {
  dispatch({
    type: dashboardTypes.DASHBOARD_ONCHANGE,
    payload: { prop: "userInfo", value: userInfo },
  });

  let response;
  if (JSON.parse(localStorage.getItem("userInfo"))?.customerType === 3) {
    response = await dashboardApi.getCompanyDevices(
      JSON.parse(localStorage.getItem("userInfo")).companyId
    );
  } else {
    response = await dashboardApi.getDashboardData(userId, userEnv.id);
  }

  if (response && response.status === 200 && response.data.success) {
    dispatch({
      type: dashboardTypes.DASHBOARD_ONCHANGE,
      payload: {
        prop: "devicesInfo",
        value: response.data.data.map((item) => {
          return { ...item, envid: userEnv.id };
        }),
      },
    });
  } else {
    dispatch({
      type: dashboardTypes.DASHBOARD_ONCHANGE,
      payload: { prop: "devicesInfo", value: [] },
    });
  }

  return response;
};

const onUploadImage = (imageStr) => async (dispatch) => {
  let response = await dashboardApi.uploadImage(imageStr);
  return response;
};

const onUploadReceiptImage = (imageStr) => async (dispatch) => {
  let response = await dashboardApi.uploadReceiptImage(imageStr);
  return response;
};

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: dashboardTypes.DASHBOARD_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetMyEnvironment = (userId, companyId) => async (dispatch) => {
  let response;
  if (companyId) {
    response = await dashboardApi.getComapnyEnv(companyId);
  } else {
    response = await dashboardApi.getMyEnv(userId);
  }

  if (response && response.data && response.data.success) {
    dispatch({
      type: dashboardTypes.DASHBOARD_SET_ENV,
      payload: response.data.data,
    });

    try {
      let currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
      if (!currentEnv) {
        localStorage.setItem(
          "currentEnv",
          response.data.data[0] ? JSON.stringify(response.data.data[0]) : null
        );
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    dispatch({
      type: dashboardTypes.DASHBOARD_SET_ENV,
      payload: [],
    });
  }
  return response;
};

const onChangeEnvName = (item, envName) => async (dispatch) => {
  let response = await dashboardApi.changeEnvName(item, envName);
  return response;
};
// export const updateTransferedDeviceFlag = (flag) =>  async (dispatch) ({
//   type: dashboardTypes.UPDATE_TRANSFERED_DEVICE_FLAG,
//   payload: flag,
// });

const onGetSoftwareReleaseProductOverview = (productId) => async (dispatch) => {
  let response = await dashboardApi.getSoftwareReleaseProductOverview(
    productId
  );
  return response;
};
const onTransferDevice = (req) => async (dispatch) => {
  let response = await dashboardApi.transferDevice(req);
  return response;
};

const onGetTranferDevices = (userId) => async (dispatch) => {
  let response = await dashboardApi.getTransferDevices(userId);
  if (response && response.data && response.data.success) {
    dispatch({
      type: dashboardTypes.GET_TRANSFER_DEVICE,
      payload: response.data.data,
    });
  } else {
    dispatch({
      type: dashboardTypes.GET_TRANSFER_DEVICE,
      payload: [],
    });
  }
  return response;
};

const onGetDeviceOverviewInfo = (deviceId) => async (dispatch) => {
  let response = await dashboardApi.getDeviceOverviewInfo(deviceId);
  return response;
};

const onRejectOwnershipDevice = (device_id) => async (dispatch) => {
  let response = await dashboardApi.rejectOwnershipDevice(device_id);
  return response;
};
const onAcceptOwnershipDevice = (req) => async (dispatch) => {
  let response = await dashboardApi.acceptOwnershipDevice(req);
  return response;
};

const onfetchNotification = (req) => async (dispatch) => {
  let response = await dashboardApi.fetchNotification(req);
  if (response && response.data && response.data.success) {
    dispatch({
      type: dashboardTypes.SET_NOTIFICATIONS,
      payload: response.data.data,
    });
  } else {
    dispatch({
      type: dashboardTypes.SET_NOTIFICATIONS,
      payload: [],
    });
  }
  return response;
};

const onGetNotificationByPortalId =
  ({ portalId }) =>
  async (dispatch) => {
    return await dashboardApi.getNotificationByPortalID({ portalId: portalId });
  };

const onMarkUnMarkPinEnv = (req) => async (dispatch) => {
  let response = await dashboardApi.toMarkUnMarkPinEnv(req);
  return response;
};

const onGetPinnedEnviornments = () => async (dispatch) => {
  let response = await dashboardApi.getPinnedEnviorments();
  if (response && response.data && response.data.success) {
  }
  return response;
};
const deleteEnvironment = (id) => async (dispatch) => {
  let response = await dashboardApi.deleteEnvironment(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};
const deleteDevice = (id) => async (dispatch) => {
  let response = await dashboardApi.deleteDevice(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};
const deleteTopsideDevice = (id) => async (dispatch) => {
  let response = await dashboardApi.deleteTopsideDevice(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};

const deletePackDevice = (id) => async (dispatch) => {
  let response = await dashboardApi.deletePackDevice(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};
const deleteGateway = (id) => async (dispatch) => {
  let response = await dashboardApi.deleteGateway(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};
const onGetCustomEnvIcons = () => async (dispatch) => {
  let response = await dashboardApi.getCustomEnvIcons();
  if (response && response.data && response.data.success) {
  }
  return response;
};
const onGetUserSpaDevices = (userId, type) => async (dispatch) => {
  let response;
  if (JSON.parse(localStorage.getItem("userInfo")).customerType === 3) {
    response = await dashboardApi.getCompanyDevices(
      JSON.parse(localStorage.getItem("userInfo")).companyId
    );
  } else {
    response = await dashboardApi.getUserSpaDevices(userId, type);
  }
  return response;
};
const onGetOwnedSpaDevices = (userId, type) => async (dispatch) => {
  let response;
  if (JSON.parse(localStorage.getItem("userInfo")).customerType === 3) {
    response = await dashboardApi.getCompanyDevices(
      JSON.parse(localStorage.getItem("userInfo")).companyId
    );
  } else {
    response = await dashboardApi.getOwnedSpaDevices(userId, type);
  }
  return response;
};

const getConnectedDevices = () => async (dispatch) => {
  let response = await dashboardApi.getConnectedDevices();
  if (response && response.data && response.data.success) {
  }
  return response;
};

export const dashboardActions = {
  onLoadData: onLoadData,
  onGetData: onGetData,
  onUserGetData: onUserGetData,
  onUpdatePersonal: onUpdatePersonal,
  onUpdateAddress: onUpdateAddress,
  onGetEnvironment: onGetEnvironment,
  onAddDevice: onAddDevice,
  onGetAllProductType: onGetAllProductType,
  onGetProducts: onGetProducts,
  getAllDealers: getAllDealers,
  onUpdateDevName: onUpdateDevName,
  onUpdateDeviceInfo: onUpdateDeviceInfo,
  onUpdateDeviceEnv: onUpdateDeviceEnv,
  onReplaceImage: onReplaceImage,
  onGetHeaderEnvironment: onGetHeaderEnvironment,
  onGetDeviceInfo: onGetDeviceInfo,
  onDublicateSerial: onDublicateSerial,
  onGetAllEnvironment: onGetAllEnvironment,
  onAddDashboardDevice: onAddDashboardDevice,
  onAddDashboardEnv: onAddDashboardEnv,
  onGetDashboardData: onGetDashboardData,
  onUploadImage: onUploadImage,
  onUploadReceiptImage: onUploadReceiptImage,
  onChange: onChange,
  onGetMyEnvironment: onGetMyEnvironment,
  onChangeEnvName: onChangeEnvName,
  // updateTransferedDeviceFlag: updateTransferedDeviceFlag,
  onGetUserDevices: onGetUserDevices,
  onGetAllCities: onGetAllCities,
  onGetAllStates: onGetAllStates,
  onGetAllCountries: onGetAllCountries,
  onTransferDevice: onTransferDevice,
  onGetTranferDevices: onGetTranferDevices,
  onGetDeviceOverviewInfo: onGetDeviceOverviewInfo,
  onRejectOwnershipDevice: onRejectOwnershipDevice,
  onAcceptOwnershipDevice: onAcceptOwnershipDevice,
  onfetchNotification: onfetchNotification,
  onGetNotificationByPortalId,
  onGetStaticEnvironment,
  onMarkUnMarkPinEnv,
  deleteEnvironment: deleteEnvironment,
  deleteDevice: deleteDevice,
  onGetPinnedEnviornments,
  deleteTopsideDevice: deleteTopsideDevice,
  onGetCustomEnvIcons,
  onUpdateSpaTopside,
  onUpdateSpaPack,
  onUpdateSpaGateway,
  deletePackDevice: deletePackDevice,
  deleteGateway: deleteGateway,
  onGetUserSpaDevices,
  onGetOwnedSpaDevices,
  getConnectedDevices,
  onGetSoftwareReleaseProductOverview: onGetSoftwareReleaseProductOverview,
};
