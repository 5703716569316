import React, { useEffect, useState } from "react";
import { FloatingLabelInput, Loader } from "../../../components/common";
import Autocomplete from "react-google-autocomplete";
import { verifyEmailCode } from "../../../resources/images";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { accountActions } from "../../../store/accounts/accountActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const NotificationRow = ({
  title,
  notification,
  setRows,
  index,
  notifications = [],
}) => {
  const [notificationState, setNotificationState] = useState(notification);
  useEffect(() => {
    notifications[index] = notificationState;
    setRows([...notifications]);
  }, [notificationState]);

  return (
    <TableRow
      key={title}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row" className="tableCellStyle">
        {title}
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={notificationState.web}
          onChange={(e) => {
            setNotificationState({
              ...notificationState,
              web: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={notificationState.mobileApp}
          onChange={(e) => {
            setNotificationState({
              ...notificationState,
              mobileApp: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={notificationState.email}
          onChange={(e) => {
            setNotificationState({
              ...notificationState,
              email: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={notificationState.sms}
          onChange={(e) => {
            setNotificationState({
              ...notificationState,
              sms: e.target.checked,
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};
const NewsLetterRow = ({
  title,
  notification,
  setRows,
  index,
  notifications = [],
}) => {
  const [newsLetterState, setNewsLetterState] = useState(notification);
  useEffect(() => {
    notifications[index] = newsLetterState;
    setRows([...notifications]);
  }, [newsLetterState]);

  return (
    <TableRow
      key={title}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell
        component="th"
        scope="row"
        className="tableCellStyle_"
        style={{ paddingRight: "120px !important" }}
      >
        {title}
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={newsLetterState.web}
          onChange={(e) => {
            setNewsLetterState({
              ...newsLetterState,
              web: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={newsLetterState.mobileApp}
          onChange={(e) => {
            setNewsLetterState({
              ...newsLetterState,
              mobileApp: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={newsLetterState.email}
          onChange={(e) => {
            setNewsLetterState({
              ...newsLetterState,
              email: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell align="center" className="tableCellStyle">
        <Checkbox
          color="primary"
          checked={newsLetterState.sms}
          onChange={(e) => {
            setNewsLetterState({
              ...newsLetterState,
              sms: e.target.checked,
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const NotificationForm = ({ stepNumber, onGettingStarted }) => {
  const [agree, setIsAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [rows, setRows] = useState([
    {
      title: "Updates About Your Product",

      type: 1,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    {
      title: "When Changes Are Made To Your Account",
      type: 2,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    {
      title: "Conversations Between You + Your Dealer",
      type: 3,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    {
      title: "Conversations Between You + Aquatic AV",
      type: 4,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
  ]);

  const [newsLetterRows, setNewsLetterRows] = useState([
    {
      title: "Aquatic AV Monthly Newsletter",

      type: 5,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    {
      title: "Your Dealer’s Newsletter",
      type: 6,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
  ]);
  const dispatch = useDispatch();

  const onSubmitNotification = () => {
    setIsLoading(true);

    dispatch(
      accountActions.onNotificationSubmission([...rows, ...newsLetterRows])
    ).then((data) => {
      setIsLoading(false);
      if (data && data.success == false) {
        toast.error("Something went wrong");
      } else {
        toast.success("Notifications updated successfully");
        onGettingStarted(data);
      }
    });
  };
  console.log("rows", rows);
  return (
    <>
      <div className="userRegistration__tabs">
        <h2
          style={{
            marginBottom: 24,
            marginTop: 16,
            fontWeight: 600,
            fontSize: "30px",
            color: "#202A4C",
            fontFamily: "Poppins",
          }}
        >
          Finish Setup
        </h2>
        <h4
          style={{
            fontSize: "22px",
            fontWeight: 600,
            color: "#181C32",
            fontFamily: "Poppins",
          }}
        >
          Almost There, a Few More Questions
        </h4>
        <p
          className="environmentDesc"
          style={{
            fontSize: "18px",
            fontWeight: 400,
            color: "#000000",
            fontFamily: "Poppins",
          }}
        >
          Congratulations! Your account has been successfully created. Choose
          your notification preferences below or click Get Started to head to
          your account portal.
        </p>

        <span className="notificationGridWrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableCellStyle"></TableCell>
                <TableCell align="center" className="tableCellStyle">
                  Web/Browser
                </TableCell>
                <TableCell align="center" className="tableCellStyle">
                  Mobile/App
                </TableCell>
                <TableCell align="center" className="tableCellStyleSms">
                  E-Mail
                </TableCell>
                <TableCell align="center" className="tableCellStyleSms">
                  SMS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <NotificationRow
                  title={row.title}
                  notification={row}
                  setRows={setRows}
                  index={index}
                  notifications={rows}
                />
              ))}
            </TableBody>
          </Table>
        </span>
        <div className="notificationGridWrapper">
          <h4
            style={{ fontWeight: 600, fontSize: "18px", fontFamily: "Poppins",color:"#181C32" }}
          >
            Newsletter Settings
          </h4>
          <p className="environmentDescription">
            Stay updated on the latest AAV news by joining our newsletter.
          </p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableCellStyle"></TableCell>
                <TableCell align="center" className="tableCellStyle">
                  Web/Browser
                </TableCell>
                <TableCell align="center" className="tableCellStyle">
                  Mobile/App
                </TableCell>
                <TableCell align="center" className="tableCellStyleSms">
                  E-Mail
                </TableCell>
                <TableCell align="center" className="tableCellStyleSms">
                  SMS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsLetterRows.map((row, index) => (
                <NewsLetterRow
                  title={row.title}
                  notification={row}
                  setRows={setNewsLetterRows}
                  index={index}
                  notifications={newsLetterRows}
                  style={{ marginRight: "100px" }}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="checkBoxWrap">
          <Checkbox
            color="primary"
            checked={agree}
            onChange={(e) => {
              setIsAgree(e.target.checked);
            }}
          />

          <Typography
            href="https://aquaticav.com/terms/"
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: 10,fontFamily:"Roboto",fontSize:"14px",color:"#464E5F",fontWeight:500 }}
            className="linkText"
          >
            I would like to recieve updates on products and offers via email and
            other digital communications, including targeted adverts and social
            media from Aquatic AV. I understand that I can opt out, free of
            charge, at any time.
          </Typography>
        </div>
        <span className="footerNav ">
          {/* <button
                    id="pane1"
                    className="prevNavButton"
                    onClick={() =>
                      this.props.actions.onChange("currentPaneId", "pane1")
                    }
                  >
                    PREVIOUS
                  </button> */}

          {isLoading ? <Loader /> : null}

          <button
            id="pane2"
            onClick={onSubmitNotification}
            style={{float:"right",fontFamily:"poppins",fontWeight:700,fontSize:"16px",color:"#ffffff"}}
          >
            {stepNumber < 3 ? "NEXT STEP" : "Get Started"}
          </button>
        </span>
      </div>
    </>
  );
};

export { NotificationForm };
