import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import { AppBtn } from "../../components/common/index";

const DashboardPriceListBox = ({
  heading,
  subheadeing,
  valid,
  priceListStateColor,
  onClick,
  isAllAccess,
  isViewable,
}) => {
  const [showEdit, setShowEdit] = useState(true);
  const [showCheck, setShowCheck] = useState(false);
  const [updateHeading, setupdateHeading] = useState(heading);

  return (
    <div className="boxMain" style={{ height: "210px" }}>
      <div
        className="boxMain__header_priceList"
        style={{ paddingLeft: "20px" }}
      >
        <div className={`dashboardTiles`}>
          <span>{heading}</span>
        </div>
        <span>
          {/* <input
            type="text"
            value={updateHeading}
            onChange={(e) => {
              setupdateHeading(e.target.value);
            }}
            disabled={showEdit}
          /> */}
          <div className={`dashboardTiles`}>
            <i style={{ color: priceListStateColor }}>{subheadeing}</i>
          </div>
        </span>
      </div>
      <div className={`dashboardTiles`}>
        <span className="boxMain__sku">
          <i>Valid from </i>
          {`${valid}`}
        </span>
      </div>

      <div className={`dashboardTiles`}>
        <span className="boxMain__sku" style={{ marginTop: "20px",display:"flex",justifyContent:"space-between",alignItems:"center" }}>
          <i style={{ color: "#3699FF" }}>Review Updates </i>
          <button
            onClick={isViewable ? onClick : () => { }}
            id="pane2"
            // className="nextNav"
            disabled={isViewable ? false : true}
            style={{
              backgroundColor: "#3699ff",
              // backgroundColor: isAllAccess ? "#3699ff" : "#6c757d",
              cursor: isViewable ? null : "default",
              opacity: isViewable ? 1 : 0.5,
              width: "75px",
              // height: "35px",
            }}
          >
            View
          </button>
        </span>
      </div>
    </div>
  );
};

export default DashboardPriceListBox;
