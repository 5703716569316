import React, { Component } from "react";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import CustomSwitch from "../../../components/common/Switch/CustomSwitch";
import { RoutesObj } from "../../../routes";
import { Loader } from "../../../components/common/index";
import DurationPicker from "react-duration-picker";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@material-ui/core/Select";

import "../tabs.css";
import { toast } from "react-toastify";

let spaDeviceId;
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // duration1: 0,
      // duration2: 0,
      filter1: false,
      filter2: false,
      maintenance: false,
      filter1Time: "",
      filter2Time: "",
      deviceId: "",
      filterId: 0,
      isModalOpen: false,
      isModalOpenDuration: false,
      duration2: { hours: 1, minutes: 2 },
      duration1: { hours: 1, minutes: 2 },
      filter1Hours: 0,
      filter1Mints: 0,
      filter2Hours: 0,
      filter2Mints: 0,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    console.log("spaId in settings:", location?.state?.SpaId);
    if (location?.state?.SpaId) {
      this.getSpaOverviewData(location.state.SpaId);
      spaDeviceId = location.state.SpaId;
    } else {
      window.location = RoutesObj.Dashboard.path;
      toast.error("Please select Spa Device");
    }
  }
  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleDurationChange = (duration2) => {
    this.setState({ duration2 });
  };

  handleDurationSelection = () => {
    this.closeModal();
  };

  openModalFilter1 = () => {
    this.setState({ isModalOpenDuration: true });
  };

  closeModalFilter1 = () => {
    this.setState({ isModalOpenDuration: false });
  };

  handleDurationChangefilter = (duration1) => {
    console.log("ssss", duration1);
    this.setState({ duration1 });
  };

  handleDurationSelectionDuration = () => {
    this.closeModalFilter1();
  };

  durationInToHours(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    return rhours;
  }

  durationInToMints(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rminutes;
  }

  async getSpaOverviewData(SpaId) {
    this.setState({ isLoading: true });
    let data = {
      id: SpaId,
    };

    await this.props.actions.onGetSpaFilterSettings(data).then(async (res) => {
      console.log("settigs api response:", res);
      this.setState({
        isLoading: false,
        // duration1: res?.data?.filter1Duration,
        duration2: res?.data?.filter2Duration,
        filter1: res?.data?.filter1On,
        filter2: res?.data?.filter2On,
        maintenance: res?.data?.isMaintenanceMode,
        filter1Time: res?.data?.filter1Start,
        filter2Time: res?.data?.filter2Start,
        deviceId: res?.data?.deviceId,
        filterId: res?.data?.id,
        filter1Hours: this.durationInToHours(res?.data?.filter1Duration),
        filter1Mints: this.durationInToMints(res?.data?.filter1Duration),
        filter2Hours: this.durationInToHours(res?.data?.filter2Duration),
        filter2Mints: this.durationInToMints(res?.data?.filter2Duration),
      });
      if (res?.success) {
      }
    });
  }

  convertHourstoMinute(hours) {
    return Math.floor(hours * 60);
  }

  async UpdateSpaOverviewData() {
    const { location } = this.props;
    const {
      filter1,
      filter2,
      filter1Time,
      filter2Time,
      maintenance,
      deviceId,
      filterId,
      filter1Hours,
      filter1Mints,
      filter2Hours,
      filter2Mints,
    } = this.state;

    this.setState({ isLoading: true });

    let filter1TotalDuration =
      parseInt(this.convertHourstoMinute(filter1Hours)) +
      parseInt(filter1Mints);
    let filter2TotalDuration =
      parseInt(this.convertHourstoMinute(filter2Hours)) +
      parseInt(filter2Mints);

    let data = {
      id: filterId,
      filter1Start: filter1Time,
      filter1Duration: filter1TotalDuration,
      filter1On: filter1,
      filter2Start: filter2Time,
      filter2Duration: filter2TotalDuration,
      filter2On: filter2,
      deviceId: deviceId,
      maintenanceMode: maintenance,
    };

    console.log("params:", data);

    await this.props.actions
      .onUpdateSpaFilterSettings(data)
      .then(async (res) => {
        console.log("settigs update response:", res);
        this.setState({
          isLoading: false,
        });
        if (res?.success) {
          this.getSpaOverviewData(spaDeviceId);
        }
      });
  }

  render() {
    // const { isPlaying, trackProgress, isLoading } = this.state;
    const {
      duration1,
      duration2,
      filter1,
      filter2,
      maintenance,
      filter1Time,
      filter2Time,
    } = this.state;
    const { spaOverviewReducer, dashboardState, homeState } = this.props;
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 750,
      // bgcolor: "background.paper",
      // border: '2px solid #000',
      // boxShadow: 24,
      p: 3,
      outline: "none",
    };
    // const { userInfo, userEnv } = dashboardState;
    // const { history } = this.props;
    // let userNameS = "";
    // if (userInfo) {
    //   userNameS = `${userInfo.firstName}${`'s `}`;
    // }
    return (
      <div
        className="tab-pane fade show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <div className="filterheader">
          <h4 className="Filterheading">Filter</h4>
          <p
            onClick={() => this.UpdateSpaOverviewData()}
            className="filtersavebutton"
          >
            Save
          </p>
        </div>

        {this.state.isLoading && <Loader />}

        <form>
          <div className="form-group row justify-content-between">
            <div className="filtersec">
              <CustomSwitch
                onClick={(e) => {
                  console.log("meow:", e.target.checked);
                  this.setState({
                    filter1: e.target.checked,
                    filter2: false,
                  });
                }}
                checked={filter1}
                disabled={maintenance ? true : false}
              />
              <label className="subheadingsfilters filteralign">
                Filter 1 start
              </label>
            </div>
            <div className="col-sm-5">
              <div className="d-flex justify-content-end align-item-end">
                <input
                  id="time"
                  value={filter1Time}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      filter1Time: e.target.value,
                      filter1: true,
                    });
                  }}
                  //  label="Alarm clock"
                  type="time"
                  placeholder="abc"
                  defaultValue="07:30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  //  inputProps={{
                  //    step: 300, // 5 min
                  //  }}
                  inputProps={{
                    style: {
                      backgroundImage: "none",
                    },
                  }}
                  className="timepickerinput"
                  readOnly={maintenance ? true : false}
                />
                {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn edit text-right w-22"
                >
                  <i class="fas fa-chevron-right"></i>
                </button> */}
              </div>
            </div>
          </div>

          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label subheadingsfilters durationHeading">
              Filter 1 duration
            </label>
            <div className="">
              <div className="d-flex justify-content-end">
                <Select
                  value={this.state.filter1Hours}
                  onChange={(e) =>
                    this.setState({ filter1Hours: e.target.value })
                  }
                  id="hours"
                  className="durationSelect"
                >
                  {Array.from({ length: 24 }, (_, i) => (
                    <MenuItem key={i} value={i}>
                      {`${i} hr`}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  value={this.state.filter1Mints}
                  onChange={(e) =>
                    this.setState({ filter1Mints: e.target.value })
                  }
                  id="minutes"
                  className="durationSelect"
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <MenuItem key={i} value={i}>
                       {`${i} min`}
                    </MenuItem>
                  ))}
                </Select>
                {/* <input
          type="text"
          value={`${this.state.duration1?.hours || 0} hours and ${this.state.duration1?.minutes || 0} minutes`}
          onClick={this.openModalFilter1}
        /> */}
                {/* <input
                  type="text"
                  className="form-input durations"
                  placeholder="Filter 1 duration"
                  value={`${duration1}`}
                  // disabled={false}
                  // style={{ marginRight: "5%" }}
                  onChange={(e) => {
                    console.log("first val:", e.target.value);
                    this.setState({
                      duration1: e.target.value,
                    });
                  }}
                  readOnly={maintenance ? true : false}
                /> */}
                {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-25"
                >
                  <i class="fas fa-chevron-right"></i>
                </button> */}
              </div>
            </div>
          </div>

          <div className="form-group row justify-content-between">
            <div className="filtersec">
              <CustomSwitch
                onClick={(e) => {
                  console.log("meow:", e.target.checked);
                  this.setState({
                    filter2: e.target.checked,
                    filter1: false,
                  });
                }}
                checked={filter2}
                disabled={maintenance ? true : false}
              />
              <label className="subheadingsfilters filteralign">
                Filter 2 start
              </label>
            </div>
            <div className="col-sm-5">
              <div className="d-flex justify-content-end">
                {/* <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Filter 2 start"
                  value={spaOverviewReducer.spaFilterSettings.filter2Start}
                  disabled={true}
                /> */}
                <input
                  id="time"
                  value={filter2Time}
                  //  label="Alarm clock"
                  type="time"
                  placeholder="abc"
                  defaultValue="07:30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  //  inputProps={{
                  //    step: 300, // 5 min
                  //  }}
                  inputProps={{
                    style: {
                      backgroundImage: "none",
                    },
                  }}
                  className="timepickerinput"
                  onChange={(e) =>
                    this.setState({
                      filter2Time: e.target.value,
                      filter2: true,
                    })
                  }
                  readOnly={maintenance ? true : false}
                />
                {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-22"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>{" "} */}
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label subheadingsfilters durationHeading">
              Filter 2 duration
            </label>
            <div className="">
              <div className="d-flex justify-content-end align-items-center">
                <Select
                  value={this.state.filter2Hours}
                  onChange={(e) =>
                    this.setState({ filter2Hours: e.target.value })
                  }
                  id="hours"
                  className="durationSelect"
                >
                  {Array.from({ length: 24 }, (_, i) => (
                    <MenuItem key={i} value={i}>
                     {`${i} hr`}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  value={this.state.filter2Mints}
                  onChange={(e) =>
                    this.setState({ filter2Mints: e.target.value })
                  }
                  id="minutes"
                  className="durationSelect"
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <MenuItem key={i} value={i}  style={{ fontSize: 16, color: "#333" }}>
                     {`${i} min`}
                    </MenuItem>
                  ))}
                </Select>
                {/* <DurationPicker
                  initialDuration={{ hours: 1, minutes: 2 }}
                  maxHours={12}
                  // onChange={(e) =>
                  //   this.setState({
                  //     duration2: { hours, minutes, seconds },
                  //   })
                  // }
                /> */}
                {/* <input
                  type="text"
                  className="form-input durations"
                  placeholder="Filter 2 duration"
                  value={`${duration2}`}
                  // value={value}
                  // disabled={true}
                  // style={{ marginRight: "5%" }}
                  onChange={(e) =>
                    this.setState({
                      duration2: e.target.value,
                    })
                  }
                  readOnly={maintenance ? true : false}
                /> */}

                {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-25 btnpadding"
                  style={{ padding: "0px !important" }}
                >
                  <i class="fas fa-chevron-right"></i>
                </button> */}
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label subheadingsfilters">
              Maintenance mode
            </label>
            <div className="col-sm-5">
              <div className="d-flex justify-content-end">
                <CustomSwitch
                  onClick={(e) =>
                    this.setState({ maintenance: e.target.checked })
                  }
                  checked={maintenance}
                />
                {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-25"
                >
                  <i class="fas fa-chevron-right"></i>
                </button> */}
              </div>
            </div>
          </div>
        </form>
        {/* {this.state.isModalOpen &&
          <Modal open={this.state.isModalOpen}>
            <Box className="boxstyle">
              <DurationPicker
                initialDuration={this.state.duration2}
                maxHours={12}
                onChange={this.handleDurationChange}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={this.handleDurationSelection} className="durationButton">Save</button>
                <button onClick={this.closeModal} className="durationButton">Close</button>

              </div>
            </Box>
          </Modal>}
        {this.state.isModalOpenDuration &&
          <Modal open={this.state.isModalOpenDuration}>
            <Box className="boxstyle">
              <DurationPicker
                initialDuration={this.state.duration1 || { hours: 1, minutes: 0 }}
                maxHours={12}
                onChange={this.handleDurationChangefilter}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={this.handleDurationSelectionDuration} className="durationButton">Save</button>
                <button onClick={this.closeModalFilter1} className="durationButton">Close</button>

              </div>
            </Box>
          </Modal>} */}
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(Filter));
