// import React, { useState, useRef, useEffect } from "react";
// import { BsChevronDown, BsList, BsX, BsCircleFill } from "react-icons/bs";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   vector_0,
//   vector_1,
//   vector_2,
//   vector_3,
//   vector_4,
//   vector_5,
//   vector_6,
//   vector_7,
// } from "../../../resources/images/index";
// import logo from "../../../resources/images/logo.png";
// import { RoutesObj } from "../../../routes";
// import { dashboardActions } from "../../../store/dashboard/dashboardActions";
// import AppBtn from "../AppBtn/AppBtn";
// import { bell } from "../../../resources/images";
// import NotificationList from "./NotificationList";
// import "./header.css";
// import routesObj from "../../../routes/RoutesObj";
// import Cookies from "js-cookie";

// function Header(props) {
//   useEffect(() => {
//     if (localStorage.getItem("userInfo") !== null) {
//       if (JSON.parse(localStorage.getItem("userInfo")).customerType === 3) {
//         setIsDealer(true);
//       } else {
//         setIsDealer(false);
//       }
//     } else {
//       history.replace(routesObj.Root.path);
//     }
//   }, []);

//   const wrapperRefEnv = useRef(null);
//   const wrapperRefUser = useRef(null);
//   const wrapperRefNotify = useRef(null);

//   // function useOutsideAlerterEnv(ref) {
//   //   useEffect(() => {
//   //     /**
//   //      * Alert if clicked on outside of element
//   //      */
//   //     function handleClickOutside(event) {
//   //       if (ref.current && !ref.current?.contains(event.target)) {
//   //         setShowProfile(false);
//   //       }
//   //     }
//   //     // Bind the event listener
//   //     document.addEventListener("mousedown", handleClickOutside);
//   //     return () => {
//   //       // Unbind the event listener on clean up
//   //       document.removeEventListener("mousedown", handleClickOutside);
//   //     };
//   //   }, [ref]);
//   // }

//   // function useOutsideAlerterUser(ref) {
//   //   useEffect(() => {
//   //     /**
//   //      * Alert if clicked on outside of element
//   //      */
//   //     function handleClickOutsideUser(event) {
//   //       if (ref.current && !ref.current?.contains(event.target)) {
//   //         setShowLogout(false);
//   //       }
//   //     }
//   //     // Bind the event listener
//   //     document.addEventListener("mousedown", handleClickOutsideUser);
//   //     return () => {
//   //       // Unbind the event listener on clean up
//   //       document.removeEventListener("mousedown", handleClickOutsideUser);
//   //     };
//   //   }, [ref]);
//   // }
//   // function useOutsideAlerternotify(ref) {
//   //   useEffect(() => {
//   //     /**
//   //      * Alert if clicked on outside of element
//   //      */
//   //     function handleClickOutsideNotify(event) {
//   //       if (ref.current && !ref.current?.contains(event.target)) {
//   //         setShowNotification(false);
//   //       }
//   //     }
//   //     // Bind the event listener
//   //     document.addEventListener("mousedown", handleClickOutsideNotify);
//   //     return () => {
//   //       // Unbind the event listener on clean up
//   //       document.removeEventListener("mousedown", handleClickOutsideNotify);
//   //     };
//   //   }, [ref]);
//   // }

//   // useOutsideAlerterEnv(wrapperRefEnv);
//   // useOutsideAlerterUser(wrapperRefUser);
//   // useOutsideAlerternotify(wrapperRefNotify);
//   const dispatch = useDispatch();
//   const { user_notifications } = useSelector((state) => state.DashboardReducer);
//   const {
//     userInformation,
//     //  isDashboardShow,
//     history,
//     userEnvoirnment,
//     isSPA,
//     SPAIId,
//   } = props;

//   // const firstName =
//   //   userInformation && userInformation !== null
//   //     ? userInformation.firstName
//   //     : "";
//   const [showProfile, setShowProfile] = useState(false);
//   const [isDealer, setIsDealer] = useState(false);
//   const [showLogout, setShowLogout] = useState(false);
//   const [showMobile, setShowMobile] = useState(false);
//   const [showMenu, setShowMenu] = useState(false);
//   const [showNotification, setShowNotification] = useState(false);
//   const [isNotificationExist, setIsNotificationExist] = useState(true);
//   const ref = useRef(null);
//   const refNotification = useRef(null);

//   function handleClickOutside(event) {
//     if (ref.current && !ref.current.contains(event.target)) {
//       setShowProfile(false);
//     }
//   }
//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   function handleClickOutsideNotiications(event) {
//     if (
//       refNotification.current &&
//       !refNotification.current.contains(event.target)
//     ) {
//       setShowNotification(false);
//       setShowLogout(false);
//     }
//   }
//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutsideNotiications);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutsideNotiications);
//     };
//   }, []);

//   const toggleTrueFalse = () => {
//     setShowProfile(!showProfile);
//   };

//   const onLogout = () => {
//     history.replace(routesObj.Root.path);
//     localStorage.removeItem("userEmail");
//     localStorage.removeItem("password");
//     localStorage.removeItem("passwordDec");
//     localStorage.removeItem("currentEnv");
//     localStorage.removeItem("access_token");
//     localStorage.removeItem("isAuthenticated");
//     localStorage.removeItem("access_token_expiry");
//     localStorage.removeItem("userId");
//     localStorage.removeItem("userInfo");
//     localStorage.removeItem("forgotToken");
//     Cookies.remove("access_token");
//   };

//   const returnSVGs = (enName, enIconName) => {
//     if (
//       enName === "Spa" ||
//       enIconName === "vector_4" ||
//       enIconName === "vector_04"
//     ) {
//       return (
//         <svg
//           width="58"
//           height="46"
//           viewBox="0 0 58 46"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M29 46C12.6875 46 0 40.5951 0 33.6788C0 29.5718 4.31375 25.9412 11.8175 23.6576C12.2671 23.5147 12.7607 23.538 13.1915 23.7225C13.6223 23.907 13.9556 24.2378 14.1194 24.6433C14.2689 25.0551 14.2327 25.5037 14.0188 25.8915C13.8049 26.2792 13.4306 26.5746 12.9775 26.7133C7.25 28.5368 3.625 31.2146 3.625 33.6788C3.625 37.9502 14.0469 42.7144 29 42.7144C43.9531 42.7144 54.375 37.9502 54.375 33.6788C54.375 31.691 52.1275 29.5882 48.2306 27.929C47.8047 27.7388 47.4781 27.4047 47.3211 26.9985C47.1641 26.5923 47.1892 26.1465 47.391 25.757C47.5929 25.3674 47.9553 25.0652 48.4004 24.9153C48.8455 24.7654 49.3377 24.7798 49.7712 24.9555C55.0819 27.2226 58 30.3111 58 33.6788C58 40.5951 45.3125 46 29 46Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M15.5331 34.796C15.1206 34.8013 14.7185 34.679 14.3931 34.4491C14.0677 34.2193 13.8385 33.8958 13.7435 33.5319C13.6484 33.1681 13.6931 32.7857 13.8702 32.448C14.0473 32.1103 14.3462 31.8375 14.7175 31.6746C16.0507 30.9775 17.0275 29.8325 17.4363 28.4875C18.125 26.2861 17.3094 23.6576 15.1525 20.5363C12.9564 17.5255 12.0115 13.9056 12.4862 10.3221C12.9609 6.73852 14.8244 3.42427 17.7444 0.97027C17.9205 0.824647 18.1266 0.711894 18.3508 0.63845C18.575 0.565005 18.813 0.532308 19.0511 0.542223C19.2893 0.552139 19.5229 0.604474 19.7388 0.696241C19.9546 0.788007 20.1484 0.917407 20.3091 1.07705C20.4697 1.2367 20.5941 1.42347 20.6752 1.62669C20.7562 1.82991 20.7923 2.04561 20.7813 2.26147C20.7704 2.47732 20.7126 2.68912 20.6114 2.88475C20.5102 3.08038 20.3674 3.25602 20.1912 3.40165C17.9171 5.34356 16.472 7.95065 16.1106 10.7636C15.7491 13.5765 16.4946 16.4148 18.2156 18.7785C20.9706 22.7541 21.8406 26.3354 20.8981 29.4404C20.5487 30.5347 19.9636 31.5554 19.1765 32.4436C18.3895 33.3318 17.416 34.0699 16.3125 34.6152C16.0697 34.7247 15.8041 34.7862 15.5331 34.796Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M39.0956 36.4388C38.6831 36.4441 38.281 36.3218 37.9556 36.092C37.6302 35.8621 37.401 35.5386 37.306 35.1747C37.2109 34.8109 37.2556 34.4286 37.4327 34.0909C37.6098 33.7532 37.9087 33.4803 38.28 33.3174C39.6132 32.6203 40.59 31.4753 40.9988 30.1303C41.6875 27.929 40.8719 25.3005 38.715 22.1791C36.5189 19.1683 35.574 15.5484 36.0487 11.9649C36.5234 8.38134 38.3869 5.0671 41.3069 2.61309C41.6626 2.31899 42.1326 2.16502 42.6136 2.18504C43.0946 2.20507 43.5471 2.39745 43.8716 2.71988C44.196 3.0423 44.3659 3.46834 44.3438 3.90429C44.3217 4.34023 44.1095 4.75037 43.7537 5.04447C41.4796 6.98638 40.0345 9.59347 39.6731 12.4064C39.3116 15.2194 40.0571 18.0577 41.7781 20.4213C44.5331 24.3969 45.4031 27.9783 44.4606 31.0832C44.1112 32.1775 43.5261 33.1982 42.739 34.0865C41.952 34.9747 40.9785 35.7127 39.875 36.2581C39.6322 36.3675 39.3666 36.4291 39.0956 36.4388Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M28.2206 38.0816C27.8148 38.0872 27.4187 37.9692 27.0957 37.7465C26.7727 37.5238 26.5415 37.2093 26.4391 36.8533C26.3367 36.4973 26.3691 36.1205 26.5311 35.7833C26.6931 35.446 26.9753 35.1677 27.3325 34.9931C27.5863 34.8617 33.64 31.5432 27.7856 22.0805C25.6497 18.6957 24.7485 14.7857 25.2107 10.9089C25.6729 7.03215 27.4749 3.38691 30.3594 0.493852C30.526 0.339599 30.7245 0.216602 30.9436 0.131882C31.1626 0.0471629 31.3979 0.00238061 31.6361 9.23621e-05C31.8742 -0.00219589 32.1106 0.0380547 32.3316 0.118546C32.5525 0.199037 32.7539 0.318193 32.9241 0.46921C33.0942 0.620227 33.2299 0.800147 33.3234 0.998699C33.4169 1.19725 33.4663 1.41054 33.4688 1.6264C33.4713 1.84226 33.4269 2.05646 33.3381 2.25676C33.2493 2.45706 33.1179 2.63955 32.9513 2.7938C30.5737 5.15751 29.0952 8.15008 28.733 11.3318C28.3709 14.5136 29.1442 17.7167 30.9394 20.4706C33.7306 24.9883 34.5644 29.0132 33.5131 32.4632C32.8335 34.7013 31.2563 36.6288 29.0906 37.868C28.827 38.0087 28.5265 38.0825 28.2206 38.0816Z"
//             fill="#202A4C"
//           />
//         </svg>
//       );
//     } else if (
//       enName === "Motorcycles" ||
//       enIconName === "vector_5" ||
//       enIconName === "vector_05"
//     ) {
//       return (
//         <svg
//           width="59"
//           height="38"
//           viewBox="0 0 59 38"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M9.56757 38C7.67529 38 5.82549 37.4861 4.25212 36.5233C2.67874 35.5604 1.45244 34.1919 0.728292 32.5908C0.00414549 30.9896 -0.185324 29.2278 0.183842 27.528C0.553009 25.8283 1.46423 24.2669 2.80228 23.0415C4.14033 21.816 5.84511 20.9815 7.70103 20.6434C9.55696 20.3053 11.4807 20.4788 13.2289 21.142C14.9772 21.8052 16.4714 22.9283 17.5227 24.3693C18.574 25.8103 19.1351 27.5045 19.1351 29.2375C19.1351 31.5615 18.1271 33.7902 16.3329 35.4335C14.5386 37.0768 12.105 38 9.56757 38ZM9.56757 23.3958C8.30605 23.3958 7.07285 23.7385 6.02393 24.3803C4.97502 25.0222 4.15748 25.9346 3.67472 27.002C3.19195 28.0694 3.06564 29.244 3.31175 30.3772C3.55786 31.5103 4.16535 32.5512 5.05738 33.3682C5.94941 34.1852 7.08593 34.7415 8.32321 34.9669C9.5605 35.1923 10.843 35.0766 12.0085 34.6345C13.174 34.1924 14.1701 33.4436 14.871 32.483C15.5719 31.5223 15.946 30.3929 15.946 29.2375C15.946 27.6882 15.2739 26.2024 14.0778 25.1068C12.8816 24.0113 11.2592 23.3958 9.56757 23.3958Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M49.4324 38C47.5401 38 45.6904 37.4861 44.117 36.5233C42.5436 35.5604 41.3173 34.1919 40.5932 32.5908C39.869 30.9896 39.6795 29.2278 40.0487 27.528C40.4179 25.8283 41.3291 24.2669 42.6671 23.0415C44.0052 21.816 45.71 20.9815 47.5659 20.6434C49.4218 20.3053 51.3455 20.4788 53.0938 21.142C54.842 21.8052 56.3363 22.9283 57.3876 24.3693C58.4389 25.8103 59 27.5045 59 29.2375C59 31.5615 57.992 33.7902 56.1977 35.4335C54.4035 37.0768 51.9699 38 49.4324 38ZM49.4324 23.3958C48.1709 23.3958 46.9377 23.7385 45.8888 24.3803C44.8399 25.0222 44.0223 25.9346 43.5396 27.002C43.0568 28.0694 42.9305 29.244 43.1766 30.3772C43.4227 31.5103 44.0302 32.5512 44.9222 33.3682C45.8143 34.1852 46.9508 34.7415 48.1881 34.9669C49.4254 35.1923 50.7078 35.0766 51.8733 34.6345C53.0388 34.1924 54.035 33.4436 54.7359 32.483C55.4367 31.5223 55.8108 30.3929 55.8108 29.2375C55.8108 27.6882 55.1388 26.2024 53.9426 25.1068C52.7464 24.0113 51.1241 23.3958 49.4324 23.3958Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M11.1622 22.6656C10.912 22.6684 10.6652 22.6131 10.4446 22.505C10.069 22.3299 9.78441 22.0258 9.65294 21.6591C9.52147 21.2924 9.55384 20.8929 9.74298 20.548L13.7295 13.246C13.937 12.93 14.2675 12.6977 14.6544 12.5956C15.0412 12.4935 15.456 12.5292 15.8153 12.6956C16.1746 12.862 16.4519 13.1467 16.5915 13.4926C16.7311 13.8385 16.7226 14.2199 16.5678 14.5603L12.5814 21.8624C12.4488 22.1033 12.2461 22.3058 11.9956 22.4476C11.7451 22.5893 11.4566 22.6648 11.1622 22.6656Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M31.0946 29.9677H7.97298C7.55006 29.9677 7.14447 29.8139 6.84543 29.54C6.54638 29.2661 6.37838 28.8946 6.37838 28.5073C6.37838 28.12 6.54638 27.7485 6.84543 27.4746C7.14447 27.2008 7.55006 27.0469 7.97298 27.0469H29.9146L32.753 18.5911C32.8638 18.2539 33.104 17.9647 33.4293 17.7768C33.7545 17.589 34.1429 17.5151 34.523 17.5688C34.523 17.5688 38.4616 18.0799 40.6781 16.1814C41.2009 15.6829 41.6109 15.0947 41.8846 14.4506C42.1583 13.8065 42.2902 13.1191 42.2727 12.4281H41.7146C37.5846 12.3551 32.4341 12.2529 30.8554 14.6626C30.7143 14.8806 30.5136 15.0612 30.2732 15.1867C30.0327 15.3121 29.7608 15.3781 29.4841 15.3782H14.3514C14.0902 15.3785 13.8329 15.3201 13.6021 15.2081C13.3713 15.096 13.1741 14.9338 13.0278 14.7356C10.8273 11.7125 9.21676 10.8071 4.05027 10.9823C3.84087 10.99 3.63187 10.9598 3.43519 10.8935C3.23852 10.8272 3.05804 10.7261 2.90404 10.5959C2.75005 10.4657 2.62556 10.309 2.53769 10.1348C2.44981 9.9605 2.40027 9.77211 2.3919 9.58032C2.38352 9.38854 2.41647 9.19712 2.48887 9.017C2.56127 8.83688 2.67169 8.67158 2.81384 8.53054C2.95599 8.38951 3.12708 8.27549 3.31734 8.19501C3.5076 8.11453 3.7133 8.06916 3.92271 8.06149C9.58352 7.87164 12.3422 8.90853 15.1805 12.4427H28.7027C31.5092 9.28824 37.2019 9.40507 41.8262 9.52191H43.8832C44.285 9.52073 44.6724 9.65845 44.968 9.90755C45.2636 10.1566 45.4457 10.4988 45.4778 10.8655C45.7044 12.21 45.586 13.5843 45.1321 14.8796C44.6782 16.1749 43.9012 17.3556 42.8627 18.3282C40.7898 19.8954 38.1474 20.6882 35.4638 20.548L32.6573 28.9308C32.5563 29.2363 32.3489 29.5031 32.0666 29.6903C31.7844 29.8776 31.443 29.975 31.0946 29.9677Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M48.6351 31.4281C48.2687 31.4371 47.9101 31.3302 47.6197 31.1254C47.3292 30.9206 47.1247 30.6304 47.0405 30.3036L39.8649 1.82552C39.8178 1.63373 39.8123 1.43533 39.8489 1.24163C39.8856 1.04793 39.9635 0.862731 40.0782 0.696607C40.193 0.530482 40.3423 0.386685 40.5178 0.273425C40.6932 0.160166 40.8913 0.0796619 41.1007 0.0365104C41.3101 -0.00664111 41.5267 -0.0115953 41.7382 0.0219306C41.9497 0.0554566 42.1519 0.126806 42.3333 0.231905C42.5147 0.337005 42.6717 0.473796 42.7954 0.634468C42.919 0.79514 43.0069 0.976548 43.0541 1.16833L50.2297 29.6464C50.3209 30.0199 50.2484 30.4113 50.0277 30.7363C49.807 31.0613 49.4558 31.294 49.0497 31.3843L48.6351 31.4281Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M37.8078 6.57187L28.7027 6.23597C28.2798 6.22048 27.8809 6.05176 27.5938 5.76692C27.3068 5.48208 27.155 5.10447 27.1719 4.71714C27.1888 4.32981 27.373 3.96451 27.684 3.70158C27.995 3.43866 28.4074 3.29965 28.8303 3.31514L37.473 3.63643L42.6395 2.26364C43.0476 2.15519 43.486 2.19966 43.8583 2.38727C44.2306 2.57488 44.5063 2.89026 44.6247 3.26403C44.7431 3.6378 44.6946 4.03934 44.4897 4.38032C44.2849 4.7213 43.9405 4.97379 43.5324 5.08224L37.8078 6.57187Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M46.929 0.0313625L39.8697 1.74561L41.0958 5.98052L48.1551 4.26627L46.929 0.0313625Z"
//             fill="#202A4C"
//           />
//         </svg>
//       );
//     } else if (
//       enName === "Lighting" ||
//       enIconName === "vector_2" ||
//       enIconName === "vector_02"
//     ) {
//       return (
//         <svg
//           width="54"
//           height="50"
//           viewBox="0 0 54 50"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M50.7187 0H3.12011C2.27212 0 1.58468 0.687439 1.58468 1.53544V3.53151C1.58468 4.3795 2.27212 5.06694 3.12011 5.06694H50.7187C51.5667 5.06694 52.2541 4.3795 52.2541 3.53151V1.53544C52.2541 0.687439 51.5667 0 50.7187 0Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M11.565 3.37796H8.49415V24.1217H11.565V3.37796Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M28.4548 3.37796H25.384V13.7575H28.4548V3.37796Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M45.3446 3.37796H42.2738V24.1217H45.3446V3.37796Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M10.0296 41.1037C8.65346 41.0977 7.31343 40.6628 6.19599 39.8597C5.07855 39.0565 4.23928 37.925 3.795 36.6225C3.35071 35.3201 3.32351 33.9115 3.71717 32.5929C4.11084 31.2743 4.90579 30.1111 5.99138 29.2654V27.0851C5.99138 26.0345 6.40875 25.0269 7.15166 24.284C7.89457 23.541 8.90217 23.1237 9.95281 23.1237C10.4835 23.1094 11.0116 23.201 11.5064 23.3933C12.0012 23.5856 12.4527 23.8747 12.8345 24.2435C13.2162 24.6124 13.5206 25.0537 13.7297 25.5416C13.9388 26.0296 14.0485 26.5543 14.0524 27.0851V29.2654C15.1462 30.1057 15.9494 31.2674 16.3492 32.5874C16.749 33.9075 16.7254 35.3196 16.2816 36.6256C15.8378 37.9315 14.9962 39.0657 13.8749 39.8688C12.7536 40.672 11.4089 41.1038 10.0296 41.1037ZM10.0296 26.1946C9.90286 26.1819 9.77488 26.1952 9.65348 26.2337C9.53209 26.2722 9.41981 26.3351 9.32352 26.4184C9.22724 26.5018 9.14896 26.6039 9.09348 26.7185C9.03799 26.8331 9.00647 26.9579 9.00084 27.0851V30.9851L8.21777 31.4304C7.53509 31.8155 6.99921 32.4159 6.69386 33.1378C6.3885 33.8597 6.33086 34.6624 6.52995 35.4205C6.72904 36.1786 7.17364 36.8494 7.79429 37.3281C8.41494 37.8068 9.17667 38.0665 9.96049 38.0665C10.7443 38.0665 11.506 37.8068 12.1267 37.3281C12.7473 36.8494 13.1919 36.1786 13.391 35.4205C13.5901 34.6624 13.5325 33.8597 13.2271 33.1378C12.9218 32.4159 12.3859 31.8155 11.7032 31.4304L10.9201 30.9851V27.0851C10.9207 26.8592 10.8353 26.6415 10.6814 26.4762C10.5274 26.3108 10.3164 26.2101 10.091 26.1946H10.0296Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M1.53861 45.71C1.15278 45.7128 0.780028 45.5703 0.494517 45.3107C0.195968 45.0354 0.0186558 44.653 0.00138971 44.2473C-0.0158763 43.8415 0.128311 43.4455 0.402391 43.1458L3.07405 40.1824C3.34937 39.8838 3.73176 39.7065 4.13751 39.6893C4.54326 39.672 4.93933 39.8162 5.23902 40.0903C5.53757 40.3656 5.71488 40.748 5.73215 41.1537C5.74941 41.5595 5.60522 41.9555 5.33114 42.2552L2.67484 45.2186C2.52992 45.3749 2.35405 45.4993 2.15844 45.5839C1.96282 45.6685 1.75173 45.7114 1.53861 45.71Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M10.1064 49.349C9.71209 49.3494 9.33274 49.1983 9.0469 48.9267C8.76106 48.6552 8.59063 48.2841 8.57092 47.8903L8.40202 43.8674C8.40202 43.4602 8.56379 43.0697 8.85174 42.7817C9.13969 42.4938 9.53023 42.332 9.93746 42.332C10.3447 42.332 10.7352 42.4938 11.0232 42.7817C11.3111 43.0697 11.4729 43.4602 11.4729 43.8674L11.6418 47.8749C11.6418 48.2822 11.48 48.6727 11.1921 48.9607C10.9041 49.2486 10.5136 49.4104 10.1064 49.4104V49.349Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M18.6894 45.5411C18.3036 45.5439 17.9309 45.4014 17.6454 45.1419L14.6973 42.4241C14.396 42.1493 14.2162 41.7659 14.1974 41.3585C14.1787 40.951 14.3226 40.5528 14.5975 40.2515C14.8724 39.9501 15.2557 39.7703 15.6632 39.7516C16.0706 39.7329 16.4688 39.8768 16.7702 40.1517L19.7182 42.8694C19.8683 43.006 19.9898 43.1709 20.0758 43.3547C20.1618 43.5386 20.2104 43.7376 20.219 43.9404C20.2276 44.1431 20.1959 44.3455 20.1257 44.536C20.0556 44.7264 19.9484 44.901 19.8103 45.0497C19.6672 45.2041 19.4938 45.3274 19.301 45.4119C19.1081 45.4964 18.9 45.5404 18.6894 45.5411Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M12.517 27.1772H7.52682V30.2481H12.517V27.1772Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M26.8426 30.2021C25.4815 30.1804 24.1603 29.7395 23.0589 28.9395C21.9575 28.1395 21.1296 27.0193 20.6881 25.7317C20.2466 24.444 20.2129 23.0515 20.5916 21.744C20.9703 20.4365 21.743 19.2776 22.8044 18.4252V16.1835C22.8678 15.1575 23.32 14.1944 24.0689 13.4903C24.8178 12.7862 25.807 12.3942 26.8349 12.3942C27.8629 12.3942 28.8521 12.7862 29.601 13.4903C30.3499 14.1944 30.8021 15.1575 30.8655 16.1835V18.4252C31.9593 19.2655 32.7625 20.4272 33.1623 21.7473C33.5621 23.0673 33.5384 24.4795 33.0947 25.7854C32.6509 27.0913 31.8092 28.2255 30.6879 29.0287C29.5666 29.8318 28.2219 30.2636 26.8426 30.2635V30.2021ZM26.8426 15.293C26.7159 15.2803 26.5879 15.2936 26.4665 15.3321C26.3451 15.3706 26.2328 15.4334 26.1366 15.5168C26.0403 15.6002 25.962 15.7023 25.9065 15.8169C25.851 15.9315 25.8195 16.0563 25.8139 16.1835V20.0835L25.0308 20.5288C24.3481 20.9139 23.8123 21.5143 23.5069 22.2362C23.2015 22.9581 23.1439 23.7608 23.343 24.5189C23.5421 25.277 23.9867 25.9478 24.6073 26.4265C25.228 26.9052 25.9897 27.1649 26.7735 27.1649C27.5573 27.1649 28.3191 26.9052 28.9397 26.4265C29.5604 25.9478 30.005 25.277 30.2041 24.5189C30.4032 23.7608 30.3455 22.9581 30.0402 22.2362C29.7348 21.5143 29.1989 20.9139 28.5162 20.5288L27.7332 20.0835V16.1835C27.7188 15.9683 27.6268 15.7657 27.4743 15.6132C27.3218 15.4607 27.1192 15.3688 26.904 15.3544L26.8426 15.293Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M18.4284 34.8084C18.0426 34.8112 17.6698 34.6687 17.3843 34.4091C17.2311 34.2781 17.1051 34.1181 17.0137 33.9384C16.9223 33.7587 16.8672 33.5627 16.8515 33.3616C16.8358 33.1606 16.8599 32.9584 16.9223 32.7667C16.9848 32.575 17.0844 32.3974 17.2154 32.2442L19.9639 29.2808C20.2408 28.9815 20.6252 28.8044 21.0327 28.7886C21.4401 28.7728 21.8372 28.9194 22.1365 29.1963C22.4358 29.4732 22.6129 29.8577 22.6287 30.2652C22.6445 30.6726 22.4979 31.0697 22.221 31.369L19.5032 34.317C19.3654 34.4659 19.1995 34.5859 19.015 34.6702C18.8305 34.7546 18.6312 34.8015 18.4284 34.8084Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M26.9194 38.3859C26.5251 38.3864 26.1458 38.2352 25.8599 37.9637C25.5741 37.6921 25.4037 37.321 25.384 36.9273L25.2151 32.9044C25.2146 32.5102 25.3658 32.1308 25.6373 31.845C25.9089 31.5591 26.28 31.3887 26.6737 31.369C26.8817 31.3586 27.0897 31.3906 27.2849 31.4631C27.4802 31.5356 27.6586 31.647 27.8094 31.7906C27.9602 31.9343 28.0802 32.1071 28.1622 32.2985C28.2441 32.49 28.2862 32.6962 28.2859 32.9044L28.4548 36.8505C28.4548 37.2577 28.2931 37.6483 28.0051 37.9362C27.7172 38.2242 27.3266 38.3859 26.9194 38.3859Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M35.4871 34.6395C35.1066 34.6385 34.74 34.4962 34.4584 34.2402L31.495 31.5225C31.1937 31.2477 31.0138 30.8643 30.9951 30.4569C30.9764 30.0494 31.1203 29.6512 31.3952 29.3499C31.6701 29.0485 32.0534 28.8687 32.4608 28.85C32.8683 28.8313 33.2665 28.9752 33.5678 29.2501L36.5312 31.9678C36.6813 32.1044 36.8029 32.2693 36.8889 32.4531C36.9748 32.637 37.0235 32.836 37.0321 33.0387C37.0406 33.2415 37.0089 33.4439 36.9388 33.6343C36.8686 33.8248 36.7614 33.9994 36.6234 34.1481C36.4784 34.3044 36.3026 34.4288 36.107 34.5134C35.9113 34.598 35.7003 34.6409 35.4871 34.6395Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M29.33 16.2756H24.3399V19.3465H29.33V16.2756Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M43.8092 41.1804C42.4331 41.1744 41.093 40.7396 39.9756 39.9364C38.8582 39.1333 38.0189 38.0017 37.5746 36.6993C37.1303 35.3968 37.1031 33.9883 37.4968 32.6697C37.8905 31.351 38.6854 30.1879 39.771 29.3422V27.1619C39.8344 26.1359 40.2866 25.1727 41.0355 24.4687C41.7844 23.7646 42.7736 23.3726 43.8015 23.3726C44.8294 23.3726 45.8187 23.7646 46.5676 24.4687C47.3165 25.1727 47.7687 26.1359 47.832 27.1619V29.3422C48.9258 30.1825 49.729 31.3442 50.1289 32.6642C50.5287 33.9843 50.505 35.3964 50.0612 36.7024C49.6175 38.0083 48.7758 39.1425 47.6545 39.9456C46.5332 40.7488 45.1885 41.1806 43.8092 41.1804ZM43.8092 26.2713C43.6825 26.2587 43.5545 26.272 43.4331 26.3105C43.3117 26.349 43.1994 26.4118 43.1031 26.4952C43.0069 26.5785 42.9286 26.6806 42.8731 26.7953C42.8176 26.9099 42.7861 27.0347 42.7805 27.1619V31.0619L41.9974 31.5072C41.4585 31.8147 41.0101 32.2588 40.6974 32.7948C40.3847 33.3308 40.2188 33.9397 40.2163 34.5602C40.2138 35.1807 40.3749 35.7909 40.6832 36.3293C40.9916 36.8678 41.4364 37.3155 41.9728 37.6274C42.5092 37.9392 43.1183 38.1043 43.7388 38.1059C44.3593 38.1074 44.9693 37.9455 45.5073 37.6363C46.0453 37.3272 46.4924 36.8817 46.8034 36.3448C47.1145 35.8079 47.2786 35.1986 47.2793 34.578C47.2832 33.952 47.1185 33.3365 46.8024 32.7961C46.4863 32.2558 46.0305 31.8105 45.4828 31.5072L44.6998 31.0619V27.1619C44.7003 26.936 44.6149 26.7183 44.461 26.5529C44.307 26.3876 44.096 26.2869 43.8706 26.2713H43.8092Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M35.3182 45.7867C35.0216 45.786 34.7314 45.6994 34.4829 45.5373C34.2345 45.3752 34.0383 45.1446 33.9181 44.8733C33.7979 44.6021 33.7588 44.3018 33.8057 44.0089C33.8525 43.7159 33.9832 43.4428 34.182 43.2226L36.8537 40.2592C37.1306 39.9578 37.5159 39.7788 37.9248 39.7615C38.3336 39.7443 38.7327 39.8901 39.034 40.167C39.3353 40.444 39.5143 40.8292 39.5316 41.2381C39.5489 41.647 39.403 42.046 39.1261 42.3474L36.4545 45.2954C36.3095 45.4517 36.1337 45.5761 35.9381 45.6606C35.7424 45.7452 35.5314 45.7882 35.3182 45.7867Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M43.886 49.4257C43.4917 49.4262 43.1124 49.275 42.8265 49.0035C42.5407 48.7319 42.3703 48.3608 42.3505 47.9671L42.1816 43.9442C42.1654 43.537 42.3115 43.14 42.5879 42.8405C42.8644 42.541 43.2484 42.3636 43.6557 42.3474C44.0629 42.3311 44.4599 42.4772 44.7594 42.7536C45.0588 43.0301 45.2362 43.4142 45.2525 43.8214L45.4214 47.8289C45.4219 48.2231 45.2707 48.6025 44.9992 48.8883C44.7276 49.1742 44.3565 49.3446 43.9627 49.3643L43.886 49.4257Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M52.4691 45.6178C52.0812 45.6165 51.7081 45.4684 51.425 45.2033L48.4769 42.5009C48.1756 42.226 47.9958 41.8427 47.9771 41.4352C47.9583 41.0278 48.1023 40.6296 48.3771 40.3283C48.652 40.0269 49.0353 39.8471 49.4428 39.8284C49.8502 39.8097 50.2484 39.9536 50.5498 40.2285L53.4978 42.9922C53.6479 43.1288 53.7695 43.2938 53.8554 43.4776C53.9414 43.6614 53.9901 43.8604 53.9986 44.0632C54.0072 44.2659 53.9755 44.4684 53.9053 44.6588C53.8352 44.8492 53.728 45.0238 53.5899 45.1726C53.4428 45.3186 53.2677 45.4333 53.0751 45.5099C52.8824 45.5864 52.6763 45.6231 52.4691 45.6178Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M46.2966 27.254H41.3064V30.3249H46.2966V27.254Z"
//             fill="#202A4C"
//           />
//         </svg>
//       );
//     } else if (
//       enName === "Marine" ||
//       enIconName === "vector_3" ||
//       enIconName === "vector_03"
//     ) {
//       return (
//         <svg
//           width="64"
//           height="31"
//           viewBox="0 0 64 31"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M4.75518 30.5216C4.42844 30.5362 4.10594 30.4444 3.83799 30.2605C3.57003 30.0766 3.37169 29.8109 3.27391 29.5049L0.0743717 19.2659C0.00663522 19.0643 -0.0152057 18.8506 0.0103864 18.6398C0.0359786 18.429 0.108382 18.2263 0.222499 18.0459C0.344933 17.8794 0.501967 17.7403 0.683247 17.6376C0.864528 17.535 1.06594 17.4712 1.2742 17.4505L62.3173 8.73639C62.5404 8.70636 62.7676 8.72661 62.9815 8.79561C63.1955 8.8646 63.3906 8.98052 63.552 9.13456C63.7133 9.28859 63.8368 9.47668 63.9129 9.68451C63.989 9.89235 64.0157 10.1145 63.9911 10.334C63.9911 10.9004 62.3173 24.2329 52.8224 28.8949C52.6294 28.9922 52.417 29.0467 52.2002 29.0547L4.79962 30.507L4.75518 30.5216ZM3.40723 20.0211L5.83651 27.6169L51.7558 26.1645C57.6809 23.071 59.9473 15.4607 60.6435 11.9025L3.40723 20.0211Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M30.0256 14.3424L27.1668 13.6017L29.6257 4.2196C29.6974 3.95192 29.8457 3.70992 30.053 3.52209C30.2603 3.33426 30.5181 3.20842 30.7959 3.15938L48.4378 0.0223196C48.7586 -0.0334599 49.0891 0.0159926 49.3783 0.163021C49.6675 0.310049 49.8992 0.546453 50.0376 0.835633L54.4814 10.2468L51.8447 11.5104L47.8453 3.10129L32.3068 5.8317L30.0256 14.3424Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M41.3255 2.67536L38.4534 3.3879L40.779 12.3989L43.651 11.6864L41.3255 2.67536Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M61.1803 14.2952L3.51904 22.5529L3.94721 25.427L61.6085 17.1694L61.1803 14.2952Z"
//             fill="#202A4C"
//           />
//         </svg>
//       );
//     } else if (
//       enName === "Powersports" ||
//       enIconName === "vector_6" ||
//       enIconName === "vector_06"
//     ) {
//       return (
//         <svg
//           width="60"
//           height="43"
//           viewBox="0 0 60 43"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M18.8704 19.2001H2.07099V6.38492H18.8704V19.2001ZM5.16195 15.9015H15.7794V9.68356H5.16195V15.9015Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M37.0916 35.5119H24.7278C24.3404 35.5099 23.9587 35.4121 23.6128 35.2261C23.2668 35.0401 22.966 34.7709 22.7341 34.4398L15.5321 24.0986L5.48651 23.9666L4.63649 25.616C4.37341 26.1339 3.95362 26.5415 3.44559 26.7721C2.93757 27.0028 2.3713 27.0429 1.83916 26.8859C1.31095 26.7424 0.842939 26.4143 0.509654 25.954C0.176368 25.4937 -0.00302586 24.9277 3.8619e-05 24.346V18.5404C3.8619e-05 17.8405 0.260562 17.1693 0.724297 16.6744C1.18803 16.1795 1.81699 15.9015 2.47281 15.9015H19.9522C20.5078 15.8907 21.0512 16.0762 21.4977 16.4293C22.3477 17.155 26.4433 20.5361 29.9979 20.5361C32.857 20.5361 37.4626 18.1446 40.8163 15.9015C41.2048 15.6403 41.6552 15.503 42.1145 15.5056H52.701C53.2626 15.4879 53.8114 15.687 54.2465 16.0664L59.053 20.0743C59.4673 20.4231 59.767 20.9035 59.9087 21.4461C60.0503 21.9886 60.0267 22.5652 59.8412 23.0925C59.6575 23.5896 59.3352 24.0144 58.9177 24.3094C58.5003 24.6044 58.008 24.7554 57.5075 24.7418H44.6027L39.1626 34.1924C38.954 34.5849 38.6534 34.9128 38.2904 35.1441C37.9274 35.3753 37.5143 35.5021 37.0916 35.5119ZM25.0832 32.2627H36.7362L42.1917 22.7791C42.4007 22.3857 42.701 22.0563 43.0636 21.8223C43.4263 21.5884 43.8392 21.4578 44.2627 21.4432H55.7147L52.5465 18.8043H42.2381C39.7808 20.4536 34.1243 23.7522 29.8742 23.7522C25.6242 23.7522 21.1732 20.4536 19.674 19.1177H3.09101V21.7401C3.31923 21.3676 3.63462 21.0652 4.00597 20.8627C4.37731 20.6602 4.79176 20.5647 5.20832 20.5855L15.9031 20.734C16.2865 20.7429 16.6629 20.8457 17.0032 21.0344C17.3435 21.2231 17.6386 21.4928 17.8658 21.8225L25.0832 32.2627Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M29.9361 23.8347C25.6396 23.8347 21.235 20.5361 19.7359 19.2001H18.4222C18.2028 19.1998 17.9859 19.1497 17.786 19.053C17.5862 18.9563 17.4079 18.8153 17.2631 18.6393L17.1395 18.4909C16.891 18.1902 16.7536 17.8033 16.7531 17.4024V12.4544C16.7524 12.1942 16.8095 11.9375 16.9197 11.7053C17.0298 11.4731 17.1898 11.272 17.3867 11.1185C17.5849 10.9652 17.8143 10.8643 18.0561 10.8242C18.2979 10.7841 18.5452 10.8058 18.7777 10.8876C21.4386 11.6948 23.9669 12.9354 26.2733 14.5655L30.5079 15.4727C34.4318 14.8406 38.4224 14.8406 42.3463 15.4727C42.7313 15.5339 43.0805 15.748 43.3241 16.0721C43.5676 16.3963 43.6876 16.8065 43.6599 17.2209C43.6343 17.6006 43.4864 17.9591 43.2414 18.2357C42.9963 18.5122 42.6691 18.6898 42.3154 18.7383C39.8117 20.4041 34.1243 23.8347 29.9361 23.8347ZM19.844 15.9015H19.9522C20.5078 15.8907 21.0512 16.0762 21.4977 16.4293C22.3477 17.155 26.4433 20.5361 29.9979 20.5361C32.3513 20.2942 34.6337 19.5421 36.7053 18.326C34.7284 18.2672 32.751 18.4164 30.8015 18.7713C30.6018 18.8044 30.3985 18.8044 30.1988 18.7713L25.2223 17.7157C24.8277 17.634 24.4785 17.3915 24.2487 17.0395C22.876 16.1073 21.3953 15.3699 19.844 14.8459V15.9015Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M53.6592 19.3321C53.3106 19.3299 52.9729 19.202 52.701 18.9692L52.5465 18.8043H43.7527C43.3428 18.8043 42.9497 18.6305 42.6599 18.3212C42.37 18.0119 42.2072 17.5924 42.2072 17.155V10.3103C42.2072 9.87287 42.37 9.45336 42.6599 9.14406C42.9497 8.83475 43.3428 8.66098 43.7527 8.66098H51.5728C51.9157 8.64534 52.2537 8.75194 52.5335 8.96397C52.8134 9.176 53.0191 9.48139 53.1183 9.832L55.2047 17.2044C55.2969 17.5401 55.2867 17.8984 55.1756 18.2275C55.0645 18.5566 54.8582 18.8394 54.5865 19.0352C54.3152 19.2376 53.9901 19.3416 53.6592 19.3321ZM45.2982 15.5056H51.4801L50.4755 11.9596H45.2982V15.5056Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M48.3273 11.5473C47.9174 11.5473 47.5243 11.3735 47.2345 11.0642C46.9446 10.7549 46.7818 10.3354 46.7818 9.89797V7.20958L43.8763 2.575C43.6468 2.21194 43.5618 1.76644 43.64 1.3365C43.7183 0.906562 43.9534 0.52741 44.2936 0.282451C44.6338 0.0374924 45.0513 -0.0532068 45.4541 0.030306C45.857 0.113819 46.2123 0.364703 46.4418 0.727767L49.6101 5.77468L49.8728 6.69829V9.89797C49.8728 10.1146 49.8328 10.329 49.7551 10.5291C49.6775 10.7292 49.5636 10.9111 49.4201 11.0642C49.2766 11.2174 49.1062 11.3389 48.9187 11.4217C48.7312 11.5046 48.5303 11.5473 48.3273 11.5473Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M48.3273 10.1454C48.0977 10.138 47.8718 10.0819 47.6628 9.98044L41.3881 6.78076C41.0484 6.57456 40.7945 6.2387 40.6776 5.84078C40.5606 5.44285 40.5893 5.01242 40.7577 4.63613C40.9262 4.25984 41.222 3.96562 41.5856 3.81271C41.9491 3.65979 42.3535 3.65954 42.7172 3.81199L48.9919 6.99517C49.1775 7.08719 49.3441 7.21763 49.4821 7.37892C49.62 7.54021 49.7266 7.72914 49.7956 7.93474C49.8647 8.14035 49.8947 8.35853 49.8841 8.57663C49.8734 8.79473 49.8223 9.00841 49.7337 9.20526C49.6073 9.48846 49.4079 9.72733 49.1588 9.89385C48.9097 10.0604 48.6212 10.1476 48.3273 10.1454Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M49.6101 33.8955C49.2002 33.8955 48.8071 33.7218 48.5172 33.4125C48.2274 33.1031 48.0646 32.6836 48.0646 32.2462V23.4059C48.0646 22.9684 48.2274 22.5489 48.5172 22.2396C48.8071 21.9303 49.2002 21.7566 49.6101 21.7566C50.0199 21.7566 50.413 21.9303 50.7029 22.2396C50.9927 22.5489 51.1555 22.9684 51.1555 23.4059V32.2462C51.1555 32.6836 50.9927 33.1031 50.7029 33.4125C50.413 33.7218 50.0199 33.8955 49.6101 33.8955Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M11.4521 33.8955C11.0422 33.8955 10.6491 33.7218 10.3592 33.4125C10.0694 33.1031 9.90659 32.6836 9.90659 32.2462V22.3503C9.90659 21.9129 10.0694 21.4934 10.3592 21.1841C10.6491 20.8748 11.0422 20.701 11.4521 20.701C11.862 20.701 12.2551 20.8748 12.5449 21.1841C12.8347 21.4934 12.9976 21.9129 12.9976 22.3503V32.2462C12.9976 32.6836 12.8347 33.1031 12.5449 33.4125C12.2551 33.7218 11.862 33.8955 11.4521 33.8955Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M11.4521 42.9998C9.76205 42.9835 8.11434 42.4338 6.71653 41.4199C5.31872 40.4061 4.23335 38.9734 3.59714 37.3024C2.96094 35.6315 2.80237 33.7969 3.14141 32.0299C3.48045 30.263 4.30194 28.6426 5.50237 27.373C6.7028 26.1033 8.22849 25.2413 9.8872 24.8953C11.5459 24.5494 13.2635 24.7351 14.8235 25.429C16.3835 26.123 17.7161 27.2941 18.6536 28.7949C19.591 30.2956 20.0913 32.0589 20.0913 33.8625C20.0893 35.0688 19.8639 36.2628 19.4281 37.3758C18.9923 38.4887 18.3546 39.4988 17.5518 40.348C16.7489 41.1971 15.7967 41.8685 14.7499 42.3236C13.7031 42.7787 12.5824 43.0085 11.4521 42.9998ZM11.4521 28.024C10.3734 28.0402 9.32336 28.3964 8.43388 29.0478C7.5444 29.6991 6.85521 30.6166 6.45295 31.6848C6.05068 32.753 5.9533 33.9242 6.17305 35.0513C6.39279 36.1784 6.91986 37.211 7.68798 38.0193C8.4561 38.8276 9.431 39.3756 10.4901 39.5942C11.5492 39.8129 12.6453 39.6926 13.6405 39.2484C14.6357 38.8041 15.4856 38.0558 16.0834 37.0976C16.6812 36.1393 17.0002 35.0138 17.0004 33.8625C16.9984 33.0902 16.8528 32.326 16.5722 31.6141C16.2915 30.9022 15.8813 30.2568 15.3652 29.7153C14.8492 29.1738 14.2376 28.7469 13.5659 28.4595C12.8942 28.172 12.1757 28.0295 11.4521 28.0405V28.024Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M49.6101 42.9998C47.9173 42.9965 46.2635 42.4578 44.8575 41.4519C43.4515 40.4459 42.3565 39.0177 41.7108 37.3478C41.0652 35.6779 40.8978 33.8412 41.2299 32.0699C41.562 30.2985 42.3787 28.672 43.5767 27.3957C44.7748 26.1195 46.3004 25.2509 47.9608 24.8997C49.6213 24.5485 51.342 24.7304 52.9056 25.4224C54.4692 26.1145 55.8055 27.2857 56.7456 28.7879C57.6857 30.2901 58.1875 32.0561 58.1875 33.8625C58.1834 36.2874 57.2779 38.6114 55.6698 40.3245C54.0616 42.0376 51.8823 42.9998 49.6101 42.9998ZM49.6101 28.024C48.5287 28.0272 47.4724 28.3724 46.5747 29.016C45.6771 29.6595 44.9783 30.5725 44.5665 31.6396C44.1548 32.7068 44.0487 33.8802 44.2615 35.0117C44.4744 36.1432 44.9966 37.182 45.7624 37.9968C46.5281 38.8117 47.503 39.3662 48.5638 39.5901C49.6247 39.8141 50.7239 39.6975 51.7227 39.2551C52.7215 38.8127 53.5751 38.0644 54.1756 37.1046C54.776 36.1448 55.0965 35.0166 55.0965 33.8625C55.0945 33.0958 54.951 32.337 54.6742 31.6295C54.3973 30.9219 53.9926 30.2795 53.4832 29.7389C52.9737 29.1982 52.3695 28.77 51.7049 28.4786C51.0404 28.1872 50.3285 28.0383 49.6101 28.0405V28.024Z"
//             fill="#202A4C"
//           />
//         </svg>
//       );
//     } else if (enName === "House" || enIconName === "vector_0") {
//       return (
//         <svg
//           width="64"
//           height="43"
//           viewBox="0 0 64 43"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M45.9938 5.11366H35.7794V0H29.163V5.11366H15.6553L0 20.918L0.963384 23.2255H4.86434V42.8997H58.3023V23.2262H63.1078L64 20.8516L45.9938 5.11366ZM55.5911 40.1898H51.2125V29.623H44.5961V40.1898H27.6814V23.2994H55.5911V40.1898ZM29.163 7.8236V10.7077H35.7794V7.8236H44.9959L59.5008 20.5156H32.7368L20.0522 7.8236H29.163ZM16.4893 8.09256L28.9049 20.5156H4.20854L16.4893 8.09256ZM7.57361 23.2994H24.9714V40.1898H7.57361V23.2994Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M45.9938 5.11366H35.7794V0H29.163V5.11366H15.6553L0 20.918L0.963384 23.2255H4.86434V42.8997H58.3023V23.2262H63.1078L64 20.8516L45.9938 5.11366ZM55.5911 40.1898H51.2125V29.623H44.5961V40.1898H27.6814V23.2994H55.5911V40.1898ZM29.163 7.8236V10.7077H35.7794V7.8236H44.9959L59.5008 20.5156H32.7368L20.0522 7.8236H29.163ZM16.4893 8.09256L28.9049 20.5156H4.20854L16.4893 8.09256ZM7.57361 23.2994H24.9714V40.1898H7.57361V23.2994Z"
//             stroke="#202A4C"
//           />
//         </svg>
//       );
//     } else if (
//       enName === "Grill" ||
//       enIconName === "vector_1" ||
//       enIconName === "vector_01"
//     ) {
//       return (
//         <svg
//           width="64"
//           height="34"
//           viewBox="0 0 64 34"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M10.8601 17.4023H1.59006C1.16835 17.4023 0.763913 17.2356 0.465718 16.9389C0.167524 16.6422 0 16.2398 0 15.8202V10.7258C0 10.3062 0.167524 9.90374 0.465718 9.60703C0.763913 9.31033 1.16835 9.14364 1.59006 9.14364H10.6534C11.0751 9.14364 11.4796 9.31033 11.7778 9.60703C12.076 9.90374 12.2435 10.3062 12.2435 10.7258L12.4502 15.8202C12.4502 16.2398 12.2827 16.6422 11.9845 16.9389C11.6863 17.2356 11.2818 17.4023 10.8601 17.4023ZM3.18012 14.2381H9.20646V12.3079H3.18012V14.2381Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M28.0964 17.4023H18.6832C18.2615 17.4023 17.8571 17.2356 17.5589 16.9389C17.2607 16.6422 17.0932 16.2398 17.0932 15.8202L17.2045 10.7258C17.2045 10.3062 17.372 9.90374 17.6702 9.60703C17.9684 9.31033 18.3728 9.14364 18.7945 9.14364H27.8897C28.3114 9.14364 28.7158 9.31033 29.014 9.60703C29.3122 9.90374 29.4798 10.3062 29.4798 10.7258L29.6865 15.8202C29.6865 16.2398 29.5189 16.6422 29.2207 16.9389C28.9225 17.2356 28.5181 17.4023 28.0964 17.4023ZM20.3051 14.2381H26.4427L26.3632 12.3079H20.3528L20.3051 14.2381Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M45.3327 17.4023H35.9195C35.4978 17.4023 35.0934 17.2356 34.7952 16.9389C34.497 16.6422 34.3294 16.2398 34.3294 15.8202L34.4407 10.7258C34.4407 10.3062 34.6083 9.90374 34.9065 9.60703C35.2047 9.31033 35.6091 9.14364 36.0308 9.14364H45.126C45.5477 9.14364 45.9521 9.31033 46.2503 9.60703C46.5485 9.90374 46.716 10.3062 46.716 10.7258L46.9227 15.8202C46.9227 16.2398 46.7552 16.6422 46.457 16.9389C46.1588 17.2356 45.7544 17.4023 45.3327 17.4023ZM37.5414 14.2381H43.679V12.3079H37.5891L37.5414 14.2381Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M62.4099 17.4023H53.1717C52.9613 17.4014 52.7532 17.359 52.5594 17.2775C52.3656 17.1961 52.19 17.0771 52.0427 16.9277C51.8934 16.7779 51.7758 16.6 51.6966 16.4044C51.6174 16.2088 51.5783 15.9994 51.5816 15.7885L51.677 10.6941C51.677 10.2745 51.8445 9.8721 52.1427 9.57539C52.4409 9.27869 52.8454 9.112 53.2671 9.112H62.4099C62.8316 9.112 63.2361 9.27869 63.5343 9.57539C63.8325 9.8721 64 10.2745 64 10.6941V15.8202C64 16.2398 63.8325 16.6422 63.5343 16.9389C63.2361 17.2356 62.8316 17.4023 62.4099 17.4023ZM54.7935 14.2381H60.8199V12.3079H54.8253L54.7935 14.2381Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M11.3689 30.0592H1.59006C1.16835 30.0592 0.763913 29.8925 0.465718 29.5958C0.167524 29.2991 0 28.8967 0 28.4771V23.351C0 22.9314 0.167524 22.529 0.465718 22.2323C0.763913 21.9356 1.16835 21.7689 1.59006 21.7689H11.1304C11.5521 21.7689 11.9566 21.9356 12.2548 22.2323C12.553 22.529 12.7205 22.9314 12.7205 23.351L12.9272 28.4296C12.9272 28.8492 12.7597 29.2517 12.4615 29.5484C12.1633 29.8451 11.7589 30.0118 11.3371 30.0118L11.3689 30.0592ZM3.18012 26.895H9.71528L9.63578 24.9806H3.18012V26.895Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M28.6211 30.0592H18.4447C18.023 30.0592 17.6186 29.8925 17.3204 29.5958C17.0222 29.2991 16.8547 28.8967 16.8547 28.4771L16.9501 23.3985C16.9501 22.9789 17.1176 22.5765 17.4158 22.2798C17.714 21.9831 18.1184 21.8164 18.5401 21.8164H28.4144C28.8361 21.8164 29.2406 21.9831 29.5388 22.2798C29.8369 22.5765 30.0045 22.9789 30.0045 23.3985L30.2112 28.3664C30.2112 28.786 30.0437 29.1884 29.7455 29.4851C29.4473 29.7818 29.0428 29.9485 28.6211 29.9485V30.0592ZM20.0666 26.895H27.0311L26.9516 24.9806H20.0825L20.0666 26.895Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M45.8574 30.0592H35.6651C35.2434 30.0592 34.8389 29.8925 34.5407 29.5958C34.2426 29.2991 34.075 28.8967 34.075 28.4771L34.1704 23.3985C34.1704 22.9789 34.338 22.5765 34.6362 22.2798C34.9343 21.9831 35.3388 21.8164 35.7605 21.8164H45.6507C46.0724 21.8164 46.4768 21.9831 46.775 22.2798C47.0732 22.5765 47.2407 22.9789 47.2407 23.3985L47.4475 28.4771C47.4475 28.8967 47.2799 29.2991 46.9817 29.5958C46.6835 29.8925 46.2791 30.0592 45.8574 30.0592ZM37.287 26.895H44.2037L44.1242 24.9806H37.3188L37.287 26.895Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M62.4099 30.0592H52.8696C52.6565 30.0605 52.4454 30.0191 52.2487 29.9375C52.052 29.856 51.8739 29.736 51.7247 29.5846C51.5783 29.4336 51.4635 29.2551 51.3871 29.0595C51.3107 28.864 51.2741 28.6552 51.2795 28.4455L51.3908 23.3669C51.3908 22.9473 51.5583 22.5448 51.8565 22.2481C52.1547 21.9514 52.5592 21.7848 52.9809 21.7848H62.3781C62.7999 21.7848 63.2043 21.9514 63.5025 22.2481C63.8007 22.5448 63.9682 22.9473 63.9682 23.3669V28.4771C63.9683 28.8913 63.8051 29.289 63.5138 29.5848C63.2224 29.8806 62.8261 30.0509 62.4099 30.0592ZM54.4596 26.895H60.8199V24.9332H54.5709L54.4596 26.895Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M18.3493 33.7297H11.528C11.1062 33.7297 10.7018 33.563 10.4036 33.2663C10.1054 32.9696 9.93789 32.5672 9.93789 32.1476L8.82485 5.03014C8.81425 4.7832 8.86194 4.53722 8.96411 4.31193C9.06628 4.08664 9.22007 3.8883 9.41317 3.73281L13.6586 0.347077C13.9407 0.122411 14.2912 0 14.6524 0C15.0137 0 15.3642 0.122411 15.6462 0.347077L19.8917 3.73281C20.0833 3.88344 20.2375 4.07597 20.3423 4.29547C20.4471 4.51496 20.4996 4.75553 20.4959 4.9985L19.9394 32.1793C19.9311 32.5933 19.7599 32.9877 19.4626 33.2776C19.1653 33.5675 18.7656 33.7298 18.3493 33.7297ZM13.0544 30.5655H16.7911L17.2999 5.71045L14.6604 3.60624L12.0368 5.69463L13.0544 30.5655Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M35.5856 33.7297H28.7642C28.3425 33.7297 27.9381 33.563 27.6399 33.2663C27.3417 32.9696 27.1742 32.5672 27.1742 32.1476L26.0611 4.96686C26.0505 4.71992 26.0982 4.47394 26.2004 4.24865C26.3026 4.02335 26.4563 3.82501 26.6494 3.66952L30.8949 0.347077C31.1769 0.122411 31.5274 0 31.8887 0C32.25 0 32.6005 0.122411 32.8825 0.347077L37.1439 3.73281C37.3326 3.88504 37.4837 4.07827 37.5857 4.29768C37.6877 4.51708 37.7378 4.75683 37.7322 4.9985L37.1757 32.1793C37.1673 32.5933 36.9961 32.9877 36.6988 33.2776C36.4015 33.5675 36.0019 33.7298 35.5856 33.7297ZM30.2907 30.5655H34.0273L34.5362 5.71045L31.8966 3.60624L29.273 5.69463L30.2907 30.5655Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M52.8219 33.7297H46.0164C45.5947 33.7297 45.1903 33.563 44.8921 33.2663C44.5939 32.9696 44.4263 32.5672 44.4263 32.1476L43.2974 5.03014C43.2887 4.78188 43.3388 4.53507 43.4438 4.30968C43.5488 4.08429 43.7056 3.88667 43.9016 3.73281L48.1471 0.347077C48.4278 0.126358 48.7751 0.00629217 49.1329 0.00629217C49.4907 0.00629217 49.838 0.126358 50.1188 0.347077L54.3801 3.73281C54.5718 3.88344 54.726 4.07597 54.8307 4.29547C54.9355 4.51496 54.9881 4.75553 54.9844 4.9985L54.4119 32.1793C54.4036 32.5933 54.2324 32.9877 53.9351 33.2776C53.6378 33.5675 53.2381 33.7298 52.8219 33.7297ZM47.5429 30.5655H51.2636L51.7883 5.71045L49.1329 3.60624L46.5093 5.69463L47.5429 30.5655Z"
//             fill="#202A4C"
//           />
//         </svg>
//       );
//     } else if (
//       enName === "Star" ||
//       enIconName === "vector_7" ||
//       enIconName === "vector_07"
//     ) {
//       return (
//         <svg
//           width="46"
//           height="41"
//           viewBox="0 0 46 41"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M17.5229 40.9998C17.0706 41.0055 16.6309 40.8718 16.2828 40.6227C15.9348 40.3737 15.7013 40.0256 15.6244 39.6413C13.726 30.3284 12.7008 29.4446 1.89847 27.7915C1.39497 27.7915 0.912083 27.6191 0.55605 27.3121C0.200017 27.0052 0 26.5889 0 26.1548C0 25.7207 0.200017 25.3044 0.55605 24.9974C0.912083 24.6905 1.39497 24.5181 1.89847 24.5181C12.7008 22.8813 13.726 21.9811 15.6244 12.6682C15.7013 12.2839 15.9348 11.9359 16.2828 11.6869C16.6309 11.4378 17.0706 11.3041 17.5229 11.3097C17.9752 11.3041 18.4149 11.4378 18.763 11.6869C19.1111 11.9359 19.3445 12.2839 19.4214 12.6682C21.3199 21.9811 22.364 22.865 33.1663 24.5181C33.6698 24.5181 34.1527 24.6905 34.5087 24.9974C34.8648 25.3044 35.0648 25.7207 35.0648 26.1548C35.0648 26.5889 34.8648 27.0052 34.5087 27.3121C34.1527 27.6191 33.6698 27.7915 33.1663 27.7915C22.364 29.4282 21.3388 30.3284 19.4214 39.6413C19.3445 40.0256 19.1111 40.3737 18.763 40.6227C18.4149 40.8718 17.9752 41.0055 17.5229 40.9998ZM10.3087 26.2694C12.0101 26.7173 13.5578 27.5187 14.8158 28.6032C16.0737 29.6877 17.0033 31.0221 17.5229 32.4889C18.0395 31.0207 18.9681 29.685 20.2265 28.6001C21.4848 27.5153 23.0341 26.7147 24.7371 26.2694C23.0168 25.8118 21.4561 24.9913 20.1963 23.8825C18.9366 22.7736 18.0177 21.4112 17.5229 19.9189C17.0114 21.3948 16.0854 22.7391 14.8269 23.8326C13.5685 24.926 12.0166 25.7349 10.3087 26.1875V26.2694Z"
//             fill="#202A4C"
//           />
//           <path
//             d="M34.6092 19.3624C34.1569 19.3681 33.7171 19.2343 33.3691 18.9853C33.021 18.7362 32.7875 18.3882 32.7107 18.0039C31.6096 12.6846 31.2489 12.3736 25.1168 11.457C24.6133 11.457 24.1304 11.2846 23.7744 10.9777C23.4183 10.6707 23.2183 10.2544 23.2183 9.82032C23.2183 9.38623 23.4183 8.96993 23.7744 8.66298C24.1304 8.35604 24.6133 8.1836 25.1168 8.1836C31.2868 7.2343 31.6475 6.92332 32.7107 1.63672C32.7107 1.20263 32.9107 0.786329 33.2667 0.479384C33.6228 0.17244 34.1057 0 34.6092 0C35.1127 0 35.5956 0.17244 35.9516 0.479384C36.3076 0.786329 36.5076 1.20263 36.5076 1.63672C37.6087 6.95606 37.9695 7.26704 44.1015 8.1836C44.605 8.1836 45.0879 8.35604 45.444 8.66298C45.8 8.96993 46 9.38623 46 9.82032C46 10.2544 45.8 10.6707 45.444 10.9777C45.0879 11.2846 44.605 11.457 44.1015 11.457C37.9315 12.4063 37.5708 12.7173 36.5076 18.0039C36.4308 18.3882 36.1973 18.7362 35.8493 18.9853C35.5012 19.2343 35.0614 19.3681 34.6092 19.3624ZM32.0652 9.82032C33.134 10.3323 34.0153 11.0921 34.6092 12.0135C35.2031 11.0921 36.0843 10.3323 37.1531 9.82032C36.0843 9.3083 35.2031 8.54857 34.6092 7.62711C34.0153 8.54857 33.134 9.3083 32.0652 9.82032Z"
//             fill="#202A4C"
//           />
//         </svg>
//       );
//     }
//   };

// const returnIcons = (enName, enIconName) => {
//   if (
//     enName === "Spa" ||
//     enIconName === "vector_4" ||
//     enIconName === "vector_04"
//   ) {
//     return <img src={vector_4} alt="" />;
//   } else if (
//     enName === "Motorcycles" ||
//     enIconName === "vector_5" ||
//     enIconName === "vector_05"
//   ) {
//     return <img src={vector_5} alt="" />;
//   } else if (
//     enName === "Lightning" ||
//     enIconName === "vector_2" ||
//     enIconName === "vector_02"
//   ) {
//     return <img src={vector_2} alt="" />;
//   } else if (
//     enName === "Marine" ||
//     enIconName === "vector_3" ||
//     enIconName === "vector_03"
//   ) {
//     return <img src={vector_3} alt="" />;
//   } else if (
//     enName === "Powersports" ||
//     enIconName === "vector_6" ||
//     enIconName === "vector_06"
//   ) {
//     return <img src={vector_6} alt="" />;
//   } else if (enName === "House" || enIconName === "vector_0") {
//     return <img src={vector_0} alt="" />;
//   } else if (
//     enName === "Grill" ||
//     enIconName === "vector_1" ||
//     enIconName === "vector_01"
//   ) {
//     return <img src={vector_1} alt="" />;
//   } else if (
//     enName === "Star" ||
//     enIconName === "vector_7" ||
//     enIconName === "vector_07"
//   ) {
//     return <img src={vector_7} alt="" />;
//   }
// };
//   // let userNameS = "";
//   // if (userInformation && userInformation.user_exist === "True") {
//   //   userNameS = `${userInformation.last_name}${`'s `}`;
//   // }

//   const onClickEnv = (value) => {
//     const userId = localStorage.getItem("userId");
//     dispatch(dashboardActions.onChange("isIntercomEnable", false));
//     dispatch(dashboardActions.onGetDeviceInfo(userId, value.id));
//     dispatch(dashboardActions.onGetAllEnvironment(userId));

//     localStorage.setItem("currentEnv", JSON.stringify(value));
//     history.push(RoutesObj.Dashboard.path);
//   };

//   let currentEnv = {};
//   try {
//     currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
//   } catch (error) {
//     console.log(error);
//   }
//   const currentRoot = history ? history.location.pathname : null;
//   const notifications = [...user_notifications].reverse().slice(0, 3);
//   return (
//     <div className="headerWrapper">
//       <span className="headerWrapper__profileContainer">
//         <span className="dashboardLogo">
//           <img src={logo} alt="" />
//           {/* {isDashboardShow ? */}
//           <div className="dashboardBtn">
//             <AppBtn
//               id={`dashboard_link`}
//               text="Dashboard"
//               onClick={() => {
//                 dispatch(dashboardActions.onChange("isIntercomEnable", false));
//                 history.push(RoutesObj.Dashboard.path);
//               }}
//               style={{
//                 background:
//                   currentRoot === "/dashboard" ||
//                   currentRoot === "/device-overview"
//                     ? "#2B3B70"
//                     : "transparent",
//               }}
//             />

//             {isSPA ? (
//               <div className="dropdown buttonWrapper">
//                 <button
//                   className="dropdown-toggle"
//                   type="button"
//                   id="dropdownMenuButton"
//                   data-toggle="dropdown"
//                 >
//                   Spa Ownership
//                 </button>
//                 <div
//                   className="dropdown-menu header-nav"
//                   aria-labelledby="dropdownMenuButton"
//                 >
//                   <button
//                     className="dropdown-item"
//                     onClick={() => {
//                       history.push({
//                         pathname: RoutesObj.SpaOverview.path,
//                         state: { SpaId: SPAIId },
//                       });
//                     }}
//                   >
//                     Spa Ownership
//                   </button>

//                   <button
//                     className="dropdown-item"
//                     onClick={() => {
//                       history.push({
//                         pathname: RoutesObj.WaterTreatment.path,
//                         state: { SpaId: SPAIId },
//                       });
//                     }}
//                   >
//                     Water Treatment
//                   </button>
//                   <button
//                     className="dropdown-item"
//                     onClick={() => {
//                       history.push({
//                         pathname: RoutesObj.Reporting.path,
//                         state: { SpaId: SPAIId },
//                       });
//                     }}
//                   >
//                     Reportså
//                   </button>
//                   <button
//                     className="dropdown-item"
//                     onClick={() => {
//                       history.push({
//                         pathname: RoutesObj.SpaSettings.path,
//                         state: { SpaId: SPAIId },
//                       });
//                     }}
//                   >
//                     Spa Settings
//                   </button>
//                 </div>
//               </div>
//             ) : null}

//             {isDealer ? (
//               <>
//                 <AppBtn
//                   text="Catalog"
//                   style={{
//                     background: currentRoot.includes("/catalog")
//                       ? "#2B3B70"
//                       : "transparent",
//                   }}
//                   onClick={() => {
//                     dispatch(
//                       dashboardActions.onChange("isIntercomEnable", false)
//                     );
//                     history.push(RoutesObj.Catalog.path);
//                     // history.push({
//                     //   pathname: RoutesObj.PriceList.path,
//                     // });
//                   }}
//                 />
//                 <AppBtn
//                   text="Conversations"
//                   style={{
//                     background:
//                       currentRoot === "/messages" ? "#2B3B70" : "transparent",
//                   }}
//                   onClick={() => {
//                     dispatch(
//                       dashboardActions.onChange("isIntercomEnable", false)
//                     );
//                     history.push(RoutesObj.Messages.path);
//                   }}
//                 />
//               </>
//             ) : (
//               // <></>
//               <AppBtn
//                 text="Messages"
//                 style={{
//                   background:
//                     currentRoot === "/messages" ? "#2B3B70" : "transparent",
//                 }}
//                 onClick={() => {
//                   dispatch(
//                     dashboardActions.onChange("isIntercomEnable", false)
//                   );
//                   history.push(RoutesObj.Messages.path);
//                 }}
//               />
//             )}
//           </div>
//           {/* : null} */}
//         </span>

//         <BsList
//           className={`burger__Menu ${showMobile ? "mobileMenu" : ""}`}
//           onClick={() => setShowMenu(true)}
//         />
//         <span className={`userPicture ${!showMenu ? "mobileMenu" : ""}`}>
//           <BsX className="closeMenu" onClick={() => setShowMenu(false)} />
//           <span
//             className="headerWrapper__profileContainer__profileName"
//             onClick={toggleTrueFalse}
//           >
//             <div>
//               {currentEnv !== null ? (
//                 <i>{returnSVGs(currentEnv?.name, currentEnv?.icon)}</i>
//               ) : null}
//               {`${currentEnv?.name ? currentEnv?.name : "No Environment"}`}
//             </div>
//             <div>
//               <BsChevronDown className={showProfile ? "rorate" : ""} />
//             </div>

//             {showProfile && (
//               <div
//                 className="headerWrapper__profileContainer__profileName__profileWrapper headerDropdown_"
//                 ref={ref}
//               >
//                 {userEnvoirnment &&
//                   userEnvoirnment.length > 0 &&
//                   userEnvoirnment.map((items, index) => {
//                     return (
//                       <span key={index}>
//                         <span
//                           onClick={() => onClickEnv(items)}
//                           className="headerDropdown"
//                         >
//                           {returnIcons(
//                             items.name,
//                             items?.iconName ? items?.iconName : items?.icon
//                           )}
//                           {`${items.name}`}
//                         </span>
//                       </span>
//                     );
//                   })}
//               </div>
//             )}
//           </span>
//           <span className="userGroup">
//             <i className="notificationBellIcon">
//               <BsCircleFill />
//               <img
//                 style={{ cursor: "pointer" }}
//                 src={bell}
//                 alt=""
//                 onClick={() => setShowNotification(!showNotification)}
//               />
//             </i>

//             {showNotification && notifications?.length > 0 && (
//               <div
//                 className="headerWrapper__profileContainer__profileName__profileWrapper notificationtDropDown"
//                 ref={refNotification}
//               >
//                 <span>
//                   {notifications.map((item, index) => (
//                     <React.Fragment key={index}>
//                       <NotificationList
//                         time={item?.createdOn}
//                         text={item.description}
//                       />
//                     </React.Fragment>
//                   ))}
//                 </span>
//                 <AppBtn
//                   text="See all notifications"
//                   onClick={() => history.push(RoutesObj.Notifications.path)}
//                 />
//               </div>
//             )}
//             <div
//               className="nameLetter"
//               onClick={() => setShowLogout(!showLogout)}
//             >
//               <span>
//                 {`${
//                   typeof userInformation?.firstName
//                     ?.charAt(0)
//                     ?.toUpperCase() !== "undefined"
//                     ? userInformation?.firstName?.charAt(0)?.toUpperCase()
//                     : "A"
//                 }${
//                   typeof userInformation?.lastName?.charAt(0)?.toUpperCase() !==
//                   "undefined"
//                     ? userInformation?.lastName?.charAt(0)?.toUpperCase()
//                     : "A"
//                 }`}
//               </span>
//             </div>
//             {showLogout && (
//               <div className="logoutDropDown" ref={refNotification}>
//                 <span>
//                   <AppBtn
//                     text="Account settings"
//                     onClick={() => {
//                       dispatch(
//                         dashboardActions.onChange("isIntercomEnable", false)
//                       );
//                       history.push(RoutesObj.MyAccount.path);
//                     }}
//                     style={{ background: "none", color: "#828282" }}
//                   />
//                   <AppBtn
//                     text="Dealer Locator"
//                     onClick={() => {
//                       dispatch(
//                         dashboardActions.onChange("isIntercomEnable", false)
//                       );
//                       history.push(RoutesObj.DealerLocator.path);
//                     }}
//                     style={{ background: "none", color: "#828282" }}
//                   />
//                   <AppBtn
//                     text="Logout"
//                     onClick={() => onLogout()}
//                     className="logout"
//                     style={{ background: "none", color: "#828282" }}
//                   />
//                 </span>
//               </div>
//             )}
//           </span>
//         </span>
//       </span>
//     </div>
//   );
// }

// export default Header;
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import NotificationAlert from "../../../resources/images/alertnotification.png";
import logo from "../../../resources/images/logo.png";
import routesObj from "../../../routes/RoutesObj";
// import { useHistory } from "react-router-dom";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import MenuIcon from "@material-ui/icons/Menu";
import Cookies from "js-cookie";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useHistory, useLocation } from "react-router-dom";
import MobileLogout from "../../../resources/images/mobielogout.png";
import Mobilelogo from "../../../resources/images/mobilelogo.png";
import Mobilenotification from "../../../resources/images/notmobile.png";
import Settings from "../../../resources/images/rename.png";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { dashboardApi } from "../../../store/dashboard/dashboardApi";
import { capitalizeFirstLetter } from "../CommonMethods";
import { DealorIcon } from "../SVG/SvgIcons";

function Header({ isSPA, SPAIId, ShowSubNavbar, toggleSubNav, removeSubNav }) {
  const [activeMain, setActiveMain] = useState("Dashboard");
  const [activeSub, setActiveSub] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [subNavbarVisible, setSubNavbarVisible] = useState(true);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [showLogout, setShowLogout] = useState(false);
  const [showMobileLogout, setShowMobileLogout] = useState(false);
  const [isSpaDevice, setIsSpaDevice] = useState("");
  const [isDealer, setIsDealer] = useState(false);
  const [showNotificationList, setShowNotificationList] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isReadFalseCount, setIsReadFalseCount] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  let notificationInterval = useRef();
  const modalRef = useRef();
  // const {

  //   spaDeviceId,
  // } = props;
  useEffect(() => {
    // When the component mounts or the location changes,
    // check if the current route matches "/dashboard" or "/spa-ownership"
    const isDashboardRoute = location.pathname === "/dashboard";
    const isNewMessageRoute = location.pathname === "/messages";
    const isSpaOverviewRoute = location.pathname === "/spa-ownership";
    const isSpaWaterTreatmentRoute = location.pathname === "/water-treatment";
    const isSpaReportingRoute = location.pathname === "/reporting";
    const isSpaSettingsNewRoute = location.pathname === "/spa-settings-new";
    const isCatalogRoute = location.pathname === "/catalog";
    const isPriceListRoute = location.pathname === "/catalog/price-list";

    // Set the activeMain based on the matched route

    if (isDashboardRoute) {
      setActiveMain("Dashboard");
    } else if (isNewMessageRoute) {
      setActiveMain("Messages");
    } else if (isSpaOverviewRoute) {
      setActiveMain("Spa Ownership");
    } else if (isSpaWaterTreatmentRoute) {
      setActiveMain("Spa Ownership");
    } else if (isSpaReportingRoute) {
      setActiveMain("Spa Ownership");
    } else if (isSpaSettingsNewRoute) {
      setActiveMain("Spa Ownership");
    } else if (isCatalogRoute) {
      setActiveMain("Catalog");
      // If the route doesn't match either, set activeMain to an empty string
    } else if (isPriceListRoute) {
      setActiveMain("Price List");
    } else {
      setActiveMain("");
    }
  }, [location]);
  useEffect(() => {
    if (localStorage.getItem("userInfo") !== null) {
      if (JSON.parse(localStorage.getItem("userInfo")).customerType === 3) {
        setIsDealer(true);
      } else {
        setIsDealer(false);
      }
    } else {
      history.replace(routesObj.Root.path);
    }
    const isSPADeviceAvailable = JSON.parse(localStorage.getItem("userInfo"));
    setIsSpaDevice(isSPADeviceAvailable?.isSPADeviceAvailable);
  }, []);

  const onLogout = () => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("password");
    localStorage.removeItem("passwordDec");
    localStorage.removeItem("currentEnv");
    localStorage.removeItem("access_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("access_token_expiry");
    localStorage.removeItem("userId");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("forgotToken");
    history.replace(routesObj.Root.path);
    Cookies.remove("access_token");
  };
  const handleMainClick = (name) => {
    setActiveMain((prevActiveMain) => (prevActiveMain === name ? "" : name));

    switch (name) {
      case "Dashboard":
        // ShowSubNavbar(true);
        setSubNavbarVisible(!subNavbarVisible);
        dispatch(dashboardActions.onChange("isIntercomEnable", false));
        history.push(routesObj.Dashboard.path);
        break;
      case "Message":
        setSubNavbarVisible(!subNavbarVisible);
        dispatch(dashboardActions.onChange("isIntercomEnable", false));
        history.push({
          pathname: routesObj.NewMessage.path,
          state: {
            SpaId: SPAIId,
          },
        });
        break;
      case "Spa Ownership":
        setSubNavbarVisible(!subNavbarVisible);
        dispatch(dashboardActions.onChange("isIntercomEnable", false));
        history.push({
          pathname: routesObj.SpaOverview.path,
          state: {
            SpaId: SPAIId,
          },
        });
        break;

      // Handle other cases if needed
      default:
      // Do nothing
    }
  };
  // useEffect(() => {

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
    toggleSubNav();
    setShowMobileLogout(false);
  };

  const handleLogoutClick = () => {
    setShowMenu(false);
    removeSubNav();
    setShowMobileLogout(!showMobileLogout);
  };
  const handleSubClick = (name) => {
    setActiveSub(name);
  };
  const originalNavItems = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Messages", path: "/messages" },
    // { name: "Conversation", path: "/conversation" },
    // { name: "Spa Ownership", path: "/spa-ownership" },
  ];

  const navItems = isDealer
    ? [
        { name: "Price List", path: "/catalog/price-list" },
        // { name: "Catalog", path: "/catalog" },
        // { name: "Conversation", path: "/messages" },
      ] // Show "Catalog" and "Conversation" when isDealer is true
    : [
        ...originalNavItems, // Include the "Dashboard" item in all cases
        // Disable spa tab
        // ...(isSpaDevice > 0 || isSPA
        //   ? [
        //       {
        //         name: "Spa Ownership",
        //         path: "/spa-ownership",
        //       },
        //     ]
        //   : []),
        // Include "Spa" only if isSPA is true
      ];

  const fetchActiveNotifications = useCallback(async () => {
    const notifications = await dashboardApi.getNotificationByPortalID({
      portalId: 1,
    });
    if (notifications.data?.data)
      setNotifications([...notifications.data?.data]);
    else if (notifications.data?.data === null) setNotifications([]);
  }, []);

  const markNotificationRead = useCallback(async (id) => {
    const isNotificationRead = await dashboardApi.markNotificationRead({
      notificationId: id,
    });
    if (isNotificationRead.data) {
      fetchActiveNotifications();
    }
  }, []);

  useEffect(() => {
    notificationInterval.current = setInterval(
      () => fetchActiveNotifications(),
      60000
    );

    return () => {
      clearInterval(notificationInterval.current);
    };
  }, [fetchActiveNotifications]);

  useEffect(() => {
    fetchActiveNotifications();
  }, []);

  useEffect(() => {
    filterNotificationCountThatIsReadFalse();
  }, [notifications]);

  const filterNotificationCountThatIsReadFalse = () => {
    const isReadFalse = notifications?.filter((item) => item.isRead === false);
    setIsReadFalseCount(isReadFalse?.length);
  };

  // const handleClickOutside = (event) => {
  //   if (modalRef.current && !modalRef.current.contains(event.target)) {
  //     setShowNotificationList(false);
  //   } else {
  //      setShowNotificationList(false);
  //   }
  // };

  return (
    <>
      <div className="topNavbar">
        <ul>
          <span className="dashboardLogo">
            <img src={logo} className="dashboard_logo" alt="logo"></img>
          </span>
          <div className="itemSection">
            {navItems?.map((item) => (
              <li
                className={`list-item ${
                  activeMain === item.name ? "active" : ""
                }`}
                key={item.name}
                onClick={() => handleMainClick(item.name)}
              >
                <b className="left-curve"></b>
                <b className="right-curve"></b>
                <Link to={item.path}>
                  {/* <i className={`fa ${item.icon}`}></i> */}
                  {item.name}
                </Link>
              </li>
            ))}
          </div>
        </ul>

        <OutsideClickHandler
          onOutsideClick={() => setShowNotificationList(false)}
        >
          <div className="topNavbar_leftSection">
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => setShowNotificationList(!showNotificationList)}
            >
              <span
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  background: "rgba(255, 226, 255, 0.9)",
                  color: "#EB5757",
                  borderRadius: "15px",
                  height: "16px",
                  width: "16px",
                  marginLeft: "32px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {isReadFalseCount}
              </span>
              <img src={NotificationAlert} alt="" />
            </span>
            <p className="NavNameSection">
              {capitalizeFirstLetter(userInfo?.firstName)} {userInfo?.lastName}
            </p>
            <p className="NameIcon" onClick={() => setShowLogout(!showLogout)}>
              {capitalizeFirstLetter(userInfo?.firstName.charAt(0))}
            </p>

            {showNotificationList && (
              <div className="logoutDropDownNew notificatioWrap" ref={modalRef}>
                {notifications.length === 0 && (
                  <div
                    style={{
                      background: "#f0faff",
                      borderRadius: "5px",
                      paddingLeft: "14px",
                      marginBottom: "6px",
                      paddingTop: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        display: "block",
                      }}
                    >
                      You don't have any latest notification(s)
                    </span>
                  </div>
                )}
                {notifications?.map((n) => (
                  <div
                    key={n.id}
                    style={{
                      background: !n.isRead ? "#f0faff" : "#FFF4DE",
                      borderRadius: "5px",
                      paddingLeft: "14px",
                      marginBottom: "6px",
                      cursor: "pointer",
                      textAlign: "left",
                    }}
                    // onMouseEnter={() => {
                    //   markNotificationRead(n.id);
                    // }}
                    onClick={() => {
                      markNotificationRead(n.id);
                    }}
                  >
                    <span
                      className="EnvironmentHead"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {n.subject}
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        display: "block",
                      }}
                    >
                      {n.description}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </OutsideClickHandler>

        {showLogout && (
          <div className="logoutDropDownNew">
            <div className="headPersonalInfo">
              <p className="NameIconDrop">
                {" "}
                {capitalizeFirstLetter(userInfo.firstName.charAt(0))}
              </p>
              <h6>{capitalizeFirstLetter(userInfo.firstName)}</h6>
            </div>
            <h6
              className="headAccountSetting"
              onClick={() => {
                dispatch(dashboardActions.onChange("isIntercomEnable", false));
                history.push(routesObj.MyAccount.path);
              }}
            >
              Account Settings
              {/* <span style={{ color: "Red" }}>(1)</span> */}
            </h6>
            <h6
              className="headAccountSetting"
              onClick={() => {
                history.push(routesObj.DealerLocator.path);
              }}
            >
              Dealer Locator
            </h6>
            <h6 className="headAccountSetting" onClick={() => onLogout()}>
              Logout
            </h6>
          </div>
        )}
      </div>

      <div className="mobileTopNav">
        <div className="mobileTopNavContainer">
          <div>
            {!showMenu ? (
              <MenuIcon
                fontSize="large"
                style={{
                  color: "#ffffff",
                  marginRight: "10px",
                }}
                onClick={() => handleShowMenu()}
              />
            ) : (
              <ClearRoundedIcon
                fontSize="large"
                style={{
                  color: "#ffffff",
                  marginRight: "10px",
                }}
                onClick={() => handleShowMenu()}
              />
            )}
            <img src={Mobilelogo} alt="" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={Mobilenotification} alt="" />
            <h5 className="NameIconMobile" onClick={() => handleLogoutClick()}>
              {userInfo?.firstName.charAt(0)}
            </h5>
          </div>
        </div>
        {showMenu && (
          <div className="MobileitemSection">
            {navItems.map((item) => (
              <li
                className={`list-item ${
                  activeMain === item.name ? "active" : ""
                }`}
                key={item.name}
                onClick={() => handleMainClick(item.name)}
              >
                <b className="left-curve"></b>
                <b className="right-curve"></b>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </div>
        )}

        {showMobileLogout && (
          <div className="MobileitemSection">
            <div className="logoutDropDownNew" >
              <div
              className="headPersonalInfo">               
                <p className="NameIconDrop">
                {capitalizeFirstLetter(userInfo.firstName.charAt(0))}
              </p>
              <h6>{capitalizeFirstLetter(userInfo.firstName)}</h6>
              </div>
              <div
              className="headAccountSetting"
                onClick={() => {
                  dispatch(
                    dashboardActions.onChange("isIntercomEnable", false)
                  );
                  history.push(routesObj.MyAccount.path);
                }}
              >
                Account settings
              </div>
              <div className="headAccountSetting"
                onClick={() => {
                  history.push(routesObj.DealerLocator.path);
                }}
              >
                Dealer Locator
              </div>
              <div
              className="headAccountSetting"
                onClick={() => onLogout()}
              >
                Sign Out
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
