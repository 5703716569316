import { useState } from "react";
import { AppBtn } from "../../components/common";
import constantColors from "../../components/common/colorConstants";
import { RoutesObj } from "../../routes";

const CommonHeader = ({ spaDeviceId, currentRoot, history }) => {
  //   const [showEdit, setShowEdit] = useState(true);

  return (
    <div
      style={{
        backgroundColor: constantColors.white,
        height: 79,
        width: "100%",
        position: "absolute",
        top: 96,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
      }}
      className="headerWrapper"
    >
      <span
        style={{ justifyContent: "center", alignItems: "center" }}
        className="headerWrapper__profileContainer"
      >
        <span className="dashboardLogo">
          {/* <div className="mainTitle spaTitle">
          <span>Spa Ownership</span>
        </div> */}

          <div className="dashboardBtn bottomnav">
            <AppBtn
              className="buttonbtm"
              text="Overview"
              onClick={() => {
                history.push({
                  pathname: RoutesObj.SpaOverview.path,
                  state: {
                    SpaId: spaDeviceId,
                  },
                });
              }}
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentRoot === RoutesObj.SpaOverview.path
                    ? constantColors.lightGray
                    : "transparent",
                margin: "0 0 0 0px !important",
                color:
                  currentRoot === RoutesObj.SpaOverview.path
                    ? constantColors.theme
                    : constantColors.gray,
              }}
            />
            <AppBtn
              className="buttonbtm1"
              text="Water Treatment"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentRoot === RoutesObj.WaterTreatment.path
                    ? constantColors.lightGray
                    : "transparent",
                color:
                  currentRoot === RoutesObj.WaterTreatment.path
                    ? "#49B8EF"
                    : constantColors.gray,
              }}
              onClick={() => {
                history.push({
                  pathname: RoutesObj.WaterTreatment.path,
                  state: {
                    SpaId: spaDeviceId,
                  },
                });
              }}
            />

            <AppBtn
              text="Reporting"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentRoot === RoutesObj.Reporting.path
                    ? constantColors.lightGray
                    : "transparent",
                color:
                  currentRoot === RoutesObj.Reporting.path
                    ? "#49B8EF"
                    : constantColors.gray,
              }}
              onClick={() => {
                history.push({
                  pathname: RoutesObj.Reporting.path,
                  state: {
                    SpaId: spaDeviceId,
                  },
                });
              }}
            />
               <AppBtn
              className="buttonbtm2"
              text="Spa Settings"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentRoot === RoutesObj.SpaSettingsNew.path
                    ? constantColors.lightGray
                    : "transparent",
                color:
                  currentRoot === RoutesObj.SpaSettingsNew.path
                    ? "#49B8EF"
                    : constantColors.gray,
              }}
              onClick={() => {
                history.push({
                  pathname: RoutesObj.SpaSettingsNew.path,
                  state: {
                    SpaId: spaDeviceId,
                  },
                });
              }}
            />

            {/* <AppBtn
              className="buttonbtm2"
              text="Spa Settings"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentRoot === RoutesObj.SpaSettings.path
                    ? constantColors.lightGray
                    : "transparent",
                color:
                  currentRoot === RoutesObj.SpaSettings.path
                    ? "#49B8EF"
                    : constantColors.gray,
              }}
              onClick={() => {
                history.push({
                  pathname: RoutesObj.SpaSettings.path,
                  state: {
                    SpaId: spaDeviceId,
                  },
                });
              }}
            /> */}
          </div>
        </span>
      </span>
    </div>
  );
};

export default CommonHeader;
