import React, { useEffect, useState } from "react";
import { FloatingLabelInput, Loader } from "../../../components/common";
import Autocomplete from "react-google-autocomplete";
import { verifyEmailCode } from "../../../resources/images";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { accountActions } from "../../../store/accounts/accountActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { arrowUp } from "../../../assets/images/images";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NotificationFormMob = ({ stepNumber, onGettingStarted }) => {
  const [agree, setIsAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeNewsLetters = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [rows, setRows] = useState([
    {
      title: "Updates",

      type: 1,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    {
      title: "Changes ",
      type: 2,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    {
      title: "Conversations",
      type: 3,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    // {
    //   title: "Conversations Between You + Aquatic AV",
    //   type: 4,
    //   id: 0,
    //   web: false,
    //   mobileApp: false,
    //   email: false,
    //   sms: false,
    //   isAllPlatform: false,
    // },
  ]);

  const [newsLetterRows, setNewsLetterRows] = useState([
    {
      title: "Aquatic AV ",

      type: 4,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
    {
      title: "Dealer",
      type: 5,
      id: 0,
      web: false,
      mobileApp: false,
      email: false,
      sms: false,
      isAllPlatform: false,
    },
  ]);
  const dispatch = useDispatch();

  const handleChangeCheckbox = (bolVal, index, key) => {
    const temp = [...rows];
    temp[index][key] = bolVal;
    setRows(temp);
  };
  const handleChangeCheckboxNewsLetters = (bolVal, index, key) => {
    const temp = [...newsLetterRows];
    temp[index][key] = bolVal;
    setNewsLetterRows(temp);
  };

  const onSubmitNotification = () => {
    setIsLoading(true);

    dispatch(
      accountActions.onNotificationSubmission([...rows, ...newsLetterRows])
    ).then((data) => {
      setIsLoading(false);
      if (data && data.success == false) {
        toast.error("Something went wrong");
      } else {
        toast.success("Notifications updated successfully");
        onGettingStarted(data);
      }
    });
  };
  return (
    <>
      <div className="userRegistration__tabs">
        <h2
          style={{
            marginBottom: 24,
            marginTop: 16,
            fontWeight: 600,
            fontSize: "30px",
            color: "#202A4C",
            fontFamily: "Poppins",
          }}
        >
          Finish Setup
        </h2>
        <h4
          style={{
            fontSize: "22px",
            fontWeight: 600,
            color: "#181C32",
            fontFamily: "Poppins",
          }}
        >
          Almost There, a Few More Questions
        </h4>
        <p
          className="environmentDesc"
          style={{
            fontSize: "18px",
            fontWeight: 400,
            color: "#000000",
            fontFamily: "Poppins",
          }}
        >
          Congratulations! Your account has been successfully created. Choose
          your notification preferences below or click Get Started to head to
          your account portal.
        </p>
        <Typography
          style={{
            color: "#24346B",
            fontSize: "24px",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          Account
        </Typography>
        <div className="mobile-accordion">
          {rows.map((item, index) => (
            <Accordion
              expanded={expanded === `accountPanel${index + 1}`}
              onChange={handleChange(`accountPanel${index + 1}`)}
            >
              <AccordionSummary
                expandIcon={<img src={arrowUp} alt="arrow" />}
                aria-controls={`updates${index + 1}`}
                id={`updates${index + 1}`}
              >
                <Typography
                  style={{
                    color: "#24346B",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.web}
                        onChange={(e) =>
                          handleChangeCheckbox(!item.web, index, "web")
                        }
                      />
                    }
                    label="Web/Browser"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.mobileApp}
                        onChange={() =>
                          handleChangeCheckbox(
                            !item.mobileApp,
                            index,
                            "mobileApp"
                          )
                        }
                      />
                    }
                    label="Mobile/App"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.email}
                        onChange={() =>
                          handleChangeCheckbox(!item.email, index, "email")
                        }
                      />
                    }
                    label="E-Mail"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.sms}
                        onChange={() =>
                          handleChangeCheckbox(!item.sms, index, "sms")
                        }
                      />
                    }
                    label="SMS"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>

        <Typography
          style={{
            color: "#24346B",
            fontSize: "24px",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          Newsletters
        </Typography>
        <div className="mobile-accordion">
          {newsLetterRows.map((item, index) => (
            <Accordion
              expanded={expanded === `newsletterPanel${index + 1}`}
              onChange={handleChangeNewsLetters(`newsletterPanel${index + 1}`)}
            >
              <AccordionSummary
                expandIcon={<img src={arrowUp} alt="arrow" />}
                aria-controls={`aquatic-AV${index + 1}`}
                id={`aquatic-AV${index + 1}`}
              >
                <Typography
                  style={{
                    color: "#24346B",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.web}
                        onChange={(e) =>
                          handleChangeCheckboxNewsLetters(
                            !item.web,
                            index,
                            "web"
                          )
                        }
                      />
                    }
                    label="Web/Browser"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.mobileApp}
                        onChange={() =>
                          handleChangeCheckboxNewsLetters(
                            !item.mobileApp,
                            index,
                            "mobileApp"
                          )
                        }
                      />
                    }
                    label="Mobile/App"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.email}
                        onChange={() =>
                          handleChangeCheckboxNewsLetters(
                            !item.email,
                            index,
                            "email"
                          )
                        }
                      />
                    }
                    label="E-Mail"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={item.sms}
                        onChange={() =>
                          handleChangeCheckboxNewsLetters(
                            !item.sms,
                            index,
                            "sms"
                          )
                        }
                      />
                    }
                    label="SMS"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        {/* <div className="checkBoxWrap">
          <Checkbox
            color="primary"
            checked={agree}
            onChange={(e) => {
              setIsAgree(e.target.checked);
            }}
          />

          <Typography
            href="https://aquaticav.com/terms/"
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: 10 }}
            className="linkText"
          >
            I would like to recieve updates on products and offers via email and
            other digital communications, including targeted adverts and social
            media from Aquatic AV. I understand that I can opt out, free of
            charge, at any time.
          </Typography>
        </div> */}
        <span className="footerNav ">
          {/* <button
                    id="pane1"
                    className="prevNavButton"
                    onClick={() =>
                      this.props.actions.onChange("currentPaneId", "pane1")
                    }
                  >
                    PREVIOUS
                  </button> */}

          {isLoading ? <Loader /> : null}

          <button
            id="pane2"
            className="nextNavButton"
            onClick={onSubmitNotification}
          >
            {stepNumber < 3 ? "NEXT STEP" : "Get Started"}
          </button>
        </span>
      </div>
    </>
  );
};

export { NotificationFormMob };
