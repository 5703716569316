import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

function GoogleMap({ children, ...props }) {
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const { address, defaultOpen } = props;
  const apiHasLoaded = (map, maps) => {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApi(maps);
  };

  useEffect(() => {
    if (mapApiLoaded) {
      setCenter(
        address
          ? { lat: address.lat, lng: address.long }
          : { lat: defaultOpen[0], lng: defaultOpen[1] }
      );
    }
  }, [address, defaultOpen, mapApiLoaded]);
  return (
    <Wrapper>
      <GoogleMapReact
        {...props}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_KEY2,
        }}
        defaultCenter={defaultOpen}
        defaultZoom={11}
        center={[center.lat, center.lng]}
        yesIWantToUseGoogleMapApiInternals={false}
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
        {children}
      </GoogleMapReact>
    </Wrapper>
  );
}

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
