import moment from "moment";
import { Component } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { BsX } from "react-icons/bs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  AppBtn,
  AppModal,
  FloatingLabelInput,
  Header,
  Loader,
} from "../../../components/common/index";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { deviceOverviewAction } from "../../../store/deviceOverview/deviceOverviewAction";
import TabNav from "../TabNav";
import { RoutesObj } from "../../../routes";
import "../tabs.css";
import DevicePopup from "./DevicePopup";
import FavoriteColor from "./FavoriteColor";
import SingleDeviceOverview from "./SingleDeviceOverview";
import { toast } from "react-toastify";

const Pane = (props) => (
  <div
    className={"tab-pane" + (props?.activepane ? " active" : "")}
    id={props?.id}
  >
    {props.children}
  </div>
);
class DeviceOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      serialNumber: "",
      purchaseLoc: "",
      deviceError: false,
      isImage: false,
      isShowSerialNo: false,
      focused: false,
      activepane: "pane1",
      breadCrumTxt: "Bob’s RGB Controller - Device Overview",
      dublicateSerial: "False",
      isDevicePopup: false,
      isReplaceImage: false,
      isPurchase: false,
      recepit: "",
    };
  }
  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.showTab) {
      this.setState({ activepane: location.state.showTab });
    }
    this.props.homeActions.getAllDealers();
    // this.getSpaOverviewData();
    if (
      typeof this.props?.deviceOverviewState?.deviceOverviewData?.id ===
      "undefined"
    ) {
      const userId = localStorage.getItem("userId");
      this.props.homeActions
        .onGetMyEnvironment(
          userId,
          JSON.parse(localStorage.getItem("userInfo")).customerType === 3
            ? JSON.parse(localStorage.getItem("userInfo")).companyId
            : false
        )
        .then((data) => {
          console.log("response of device:", data);
          if (data?.data?.success == false) {
            this.props.history.push(RoutesObj.Dashboard.path);
          }
        });
    }
  }
  clickTab(tabId) {
    if (tabId === "pane1") {
      this.setState({ breadCrumTxt: "Bob’s RGB Controller - Device Overview" });
    } else if (tabId === "pane2") {
      this.setState({ breadCrumTxt: "Bob’s RGB Controller - Device Overview" });
    } else if (tabId === "pane3") {
      this.setState({ breadCrumTxt: "Bob’s RGB Controller - Device Overview" });
    }
    this.setState({ activepane: tabId });
  }

  handleReplaceImage = (e, deviceOverviewId) => {
    // const { deviceInformation } = this.state;
    if (e.target.files.length) {
      this.setState({ isLoading: true });
      this.props.homeActions.onUploadImage(e.target.files[0]).then((img) => {
        if (img && img.data && img.data.success) {
          console.log("img.data.data.url", img.data.data.url);
          this.props.homeActions
            .onReplaceImage(deviceOverviewId, img.data.data.url)
            .then((res) => {
              this.setState({ isLoading: false });
              if (res && res.data && res.data.success) {
                this.setState({
                  recepit: img.data.data.url,
                  isReplaceImage: true,
                });

                toast.success("Receipt is uploaded successfully");
              } else {
                if (res && res.data && res.data.responseCode === 0) {
                  toast.error(res.data.message);
                } else {
                  toast.error("Something went wrong, Please try again");
                }
              }
            });
        } else {
          this.setState({ isLoading: false });
          if (img && img.data && img.data.responseCode === 0) {
            toast.error(img.data.message);
          } else {
            toast.error("Something went wrong, Please try again");
          }
        }
      });
    }
  };

  // getSpaOverviewData=(deviceOverviewId)=> {
  //   const userId = localStorage.getItem("userId");
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   const currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
  //   console.log("currentEnv",currentEnv)
  //   const userEmail = localStorage.getItem("userEmail");

  //   // this.setState({ isLoading: true });
  //    this.props.actions.onGetData(userEmail,currentEnv).then(async (env) => {
  //     console.log("env==>",env)
  //     // this.setState({ isLoading: false });
  //     // if (env.success) {
  //     // }
  //   });
  // }
  render() {
    const {
      isLoading,
      //  breadCrumTxt
      isReplaceImage,
      recepit,
    } = this.state;
    const { deviceOverviewState, dashboardState, homeState } = this.props;
    const { allDealers } = homeState;
    const { userInfo, userEnv, devicesInfo } = dashboardState;
    const { deviceOverviewData, fav_colors } = deviceOverviewState;

    const { history } = this.props;

    let userNameS = "";
    if (userInfo) {
      userNameS = `${userInfo.firstName}${`'s `}`;
    }
    let rgbDevice = false;
    if (deviceOverviewData && deviceOverviewData?.productType !== "") {
      rgbDevice = deviceOverviewData?.productType?.includes("Physical + IOT");
    }

    let currentEnv = null;
    try {
      if (localStorage.getItem("currentEnv") !== "null") {
        currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
        // console.log("currentEnv", currentEnv);
      }
    } catch (error) {
      console.log(error);
    }

    return (
      <div className="Dashboardmain-container">
        {isLoading && <Loader />}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userInfo ? userInfo : null}
            isDashboardShow={true}
            history={history}
            userEnvoirnment={userEnv}
          />
          <div className="mainTitle">
            <span>
              {`${userNameS}${" "}${
                deviceOverviewData.productName || deviceOverviewData.name
              }`}
              <span className="mainTitle__breadCrum">
                <i></i> Dashboard <i></i>
                {`${userNameS}${" "}${
                  deviceOverviewData.productName || deviceOverviewData.name
                }${" - Device Overview"}`}
              </span>
            </span>
          </div>
        </div>

        <div className="Dashboardmain-container__innerBody deviceOverviewContainer">
          <TabNav
            activepane={this.state.activepane}
            clickTab={(value) => this.clickTab(value)}
          >
            {/* device overview */}
            <Pane id="pane1" title="Device Overview ">
              <h1>Device Overview</h1>
              {console.log("deviceOverviewData*****", deviceOverviewData)}
              <SingleDeviceOverview
                name={
                  deviceOverviewData?.name
                    ? deviceOverviewData?.name
                    : deviceOverviewData?.deviceName
                }
                sku={deviceOverviewData.sku ? deviceOverviewData.sku : ""}
                serialnumber={deviceOverviewData.serialNo}
                environment={
                  currentEnv?.environmentName || deviceOverviewData.environment
                }
                category={deviceOverviewData.category}
                productType={deviceOverviewData.productType}
                lastconnected={deviceOverviewData.lastConnected}
                version={deviceOverviewData.softwareVersion}
                registration={`${deviceOverviewData.receiptStatus}`}
                warrantyremaining={
                  deviceOverviewData.status === "Pending"
                    ? "Pending Approval"
                    : deviceOverviewData.remainingDays
                }
                warrantyexpire={
                  deviceOverviewData.status === "Pending"
                    ? "Pending Approval"
                    : deviceOverviewData.expiryDate
                }
                onregistrationclick={() =>
                  this.setState({ activepane: "pane2" })
                }
                picture={`${deviceOverviewData.productImage}`}
                supportResources={
                  deviceOverviewData?.supportResources
                    ? deviceOverviewData?.supportResources
                    : []
                }
              />
              {this.state.isDevicePopup && (
                <DevicePopup
                  deviceData={deviceOverviewData}
                  closeModal={() => this.setState({ isDevicePopup: false })}
                />
              )}
            </Pane>

            {/* Product Registration */}

            <Pane id="pane2" title="Product Registration">
              <h1>
                Product Registration
                <span>
                  {deviceOverviewData.receiptStatus ===
                    "Registration Pending" ||
                  deviceOverviewData.receiptStatus ===
                    "Registration pending" ? (
                    <div className="boxButtonMain regPending">
                      <AppBtn text="Registration Pending" />
                    </div>
                  ) : deviceOverviewData.receiptStatus === "registered" ||
                    deviceOverviewData.receiptStatus === "Registered" ? (
                    <div className="boxButtonMain deviceRegister">
                      <AppBtn text="Registered" />
                    </div>
                  ) : deviceOverviewData.receiptStatus === "Rejected" ||
                    deviceOverviewData.receiptStatus === "rejected" ? (
                    <div className="boxButtonMain regDenied">
                      <AppBtn text="Registration denied" />
                    </div>
                  ) : (
                    <div className="boxButtonMain receiptMissing">
                      <AppBtn text="Receipt missing" />
                    </div>
                  )}
                </span>
              </h1>
              <div>
                <div className="deviceOverview">
                  <div className="deviceInner">
                    <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                      <p className="purchaseRecWrapper">
                        {/* {`${userNameS}${" "}${ */}
                        {deviceOverviewData.name}
                        {/* }`} */}
                      </p>
                      <i>{deviceOverviewData.product}</i>
                      <span>
                        <label className="purchaseRecWrapper">
                          Serial Number
                        </label>
                        <FloatingLabelInput
                          value={deviceOverviewData.serialNo}
                          disabled={true}
                          // disabled={isShowSerialNo === true ? false : true} value={serialNumber}
                          // className={(isShowSerialNo && deviceError && serialNumber === '')||dublicateSerial==='True' ? 'errorOutline' : ''}
                          onChange={(e) =>
                            this.setState({ serialNumber: e.target.value })
                          }
                          // onBlur={(e)=> this.isDublicateSerial(e)}
                        />
                        {/* {isShowSerialNo && dublicateSerial==='True'? <span style={{ color: "red" }}>{'Please change serial number, it is a dublicate number'}</span>:null} */}
                      </span>
                    </span>
                    <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                      {/* <FloatingLabelInput placeholder="Purchase Location" value={purchaseLoc} className={purchaseLoc === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ purchaseLoc: e.target.value })} /> */}
                      <label className="purchaseRecWrapper">
                        Purchase Location
                      </label>
                      {/* <FloatingLabelInput value={deviceOverviewData.dealerId} /> */}
                      {/* <select
                        name="dealers"
                        id="dealer"
                        value={deviceOverviewData.dealerId}
                        disabled={true}
                        // onChange={(e) => this.onSelectDealer(e, allDealers)}
                      >
                        <option value="">Select Dealer</option>
                        {allDealers &&
                          allDealers.length > 0 &&
                          allDealers.map((items, index) => {
                            return (
                              <option key={index} value={items.id}>
                                {items.value}
                              </option>
                            );
                          })}
                      </select> */}
                      {console.log("deviceOverviewData", deviceOverviewData)}
                      <FloatingLabelInput
                        value={deviceOverviewData.dealer}
                        disabled={true}
                      />
                    </span>

                    <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                      {/* <FloatingLabelInput placeholder="Purchase date" type="date" value={puchaseDate} className={puchaseDate === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ puchaseDate: e.target.value })} /> */}
                      <label className="purchaseRecWrapper">
                        Purchase Date
                      </label>
                      <SingleDatePicker
                        // date={puchaseDate !== '' ? puchaseDate : null} // momentPropTypes.momentObj or null
                        onDateChange={(puchaseDate) =>
                          this.setState({ puchaseDate })
                        } // PropTypes.func.isRequired
                        focused={this.state.focused} // PropTypes.bool
                        onFocusChange={({ focused }) =>
                          this.setState({ focused })
                        } // PropTypes.func.isRequired
                        // id={'1'}// PropTypes.string.isRequired,
                        numberOfMonths={1}
                        displayFormat="YYYY/MM/DD"
                        placeholder={deviceOverviewData.purchaseDate}
                        disabled={true}
                      />
                      {/* {deviceError && puchaseDate === "" ?
                 <span style={{ color: "red" }}>
                   {"Please select Date"}
                 </span>
                 : null} */}
                    </span>
                    {deviceOverviewData.installationDate && (
                      <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                        {/* <FloatingLabelInput placeholder="Purchase date" type="date" value={puchaseDate} className={puchaseDate === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ puchaseDate: e.target.value })} /> */}
                        <label className="purchaseRecWrapper">
                          installation Date
                        </label>
                        <SingleDatePicker
                          // date={puchaseDate !== '' ? puchaseDate : null} // momentPropTypes.momentObj or null
                          onDateChange={(puchaseDate) =>
                            this.setState({ puchaseDate })
                          } // PropTypes.func.isRequired
                          focused={this.state.focused} // PropTypes.bool
                          onFocusChange={({ focused }) =>
                            this.setState({ focused })
                          } // PropTypes.func.isRequired
                          // id={'1'}// PropTypes.string.isRequired,
                          numberOfMonths={1}
                          displayFormat="YYYY/MM/DD"
                          placeholder={deviceOverviewData.installationDate}
                          disabled={true}
                        />
                        {/* {deviceError && puchaseDate === "" ?
                 <span style={{ color: "red" }}>
                   {"Please select Date"}
                 </span>
                 : null} */}
                      </span>
                    )}

                    <span className="loginWrapper__loginFormWrapper--inner  groupFields viewReplaceButton">
                      {this.state.isImage && (
                        <span className="closeUpPic">
                          <BsX
                            onClick={() => this.setState({ isImage: false })}
                          />
                          <span className="closeUpInner">
                            <img src={`${deviceOverviewData.receipt}`} alt="" />
                          </span>
                        </span>
                      )}
                      {/* <FloatingLabelInput placeholder="Purchase Location" value={purchaseLoc} className={purchaseLoc === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ purchaseLoc: e.target.value })} /> */}
                      <span className="purchase-receipt">
                        <label className="purchaseRecWrapper">
                          Purchase Receipt
                        </label>

                        <div className="receiptWrapper">
                          {(deviceOverviewData &&
                            deviceOverviewData.receiptStatus !== "" &&
                            deviceOverviewData.receipt !== "") ||
                          isReplaceImage === true ? (
                            <>
                              <AppBtn
                                text="View"
                                onClick={() =>
                                  this.setState({ isPurchase: true })
                                }
                              />
                              <div className="upload-btn-wrapper">
                                <button
                                  disabled
                                  className="btn"
                                  style={{
                                    background: "#E0E0E0",
                                    opacity: "0.6",
                                    color: "#828282",
                                  }}
                                >
                                  Replace
                                </button>
                                {/* <input
                                  type="file"
                                  name="myfile"
                                  onChange={(e) =>
                                    this.handleReplaceImage(
                                      e,
                                      deviceOverviewData.id
                                    )
                                  }
                                /> */}
                              </div>
                            </>
                          ) : (
                            <div className="upload-btn-wrapper">
                              <button className="btn">Attach Image</button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) =>
                                  this.handleReplaceImage(
                                    e,
                                    deviceOverviewData.id
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <span className="purchaseOverlay">
                {this.state.isPurchase && (
                  <AppModal
                    title="Model"
                    handleclose={() => this.setState({ isPurchase: false })}
                  >
                    <h1 className="environment">Purchase Receipt</h1>
                    {/* <p>Add a device to your account </p> */}
                    <span>
                      {isReplaceImage ? (
                        <img
                          className="imgPurchase-receipt"
                          src={this.state.recepit}
                          alt=""
                        />
                      ) : deviceOverviewData.receipt ? (
                        <img
                          className="imgPurchase-receipt"
                          src={deviceOverviewData.receipt}
                          alt=""
                        />
                      ) : (
                        <span>No receipt available</span>
                      )}{" "}
                    </span>
                  </AppModal>
                )}
              </span>
            </Pane>

            {/* Favorite Colors */}
            {/** Add condition for rgb devices to show fav colors only */}

            {/* {rgbDevice && ( */}
            <Pane id="pane3" title={rgbDevice && "Favorite Colors"}>
              {rgbDevice && (
                <FavoriteColor
                  colorCollection={fav_colors}
                  deviceId={deviceOverviewData.id}
                  envId={currentEnv?.id}
                />
              )}
            </Pane>
            {/* )} */}
          </TabNav>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    deviceOverviewState: state.DeviceOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(deviceOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(DeviceOverview));
