import { Component } from "react";
import { BsChevronDown } from "react-icons/bs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Header } from "../../components/common/index";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import TabNav from "../Dashboard/TabNav";
import Loader from "../../components/common/Loader";
import "../Dashboard/tabs.css";
import BellLabel from "../../resources/images/bellicon.png";
import NotLabel from "../../resources/images/notlabel.png";
const Pane = (props) => (
  <div
    className={"tab-pane" + (props.activepane ? " active" : "")}
    id={props.id}
  >
    {props.children}
  </div>
);
class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activepane: "pane1",
      breadCrumTxt: "ersonal Information - My Account - Aquatic AV",
      allNotifications: [],
      showSubMenu: false,
      unReadNotification: [],
      readNotification: [],
    };
  }
  clickTab(tabId) {
    this.setState({ activepane: tabId });
  }
  componentDidMount() {
    this.onLoadCompleteData(false, "");
    this.setState({ activepane: "pane1" });
  }
  onLoadCompleteData = async (showMsg, msg) => {
    this.setState({ isLoading: true }, async () => {
      const userId = localStorage.getItem("userId");
      // let reqObj = {
      //   type: "type",
      //   userId: userId,
      // };
      await this.props.actions
        .onGetNotificationByPortalId({ portalId: 1 })
        .then((res) => {
          const isReadTrue = res?.data?.data?.filter(
            (item) => item.isRead === true
          );

          const isReadFalse = res?.data?.data?.filter(
            (item) => item.isRead === false
          );
          this.setState({
            unReadNotification: isReadTrue,
            readNotification: isReadFalse,
          });

          this.setState({
            allNotifications: res?.data?.data,
          });
          this.setState({ isLoading: false });
        });
    });
  };
  toggleSubNav = () => {
    this.setState((prevState) => ({ showSubMenu: !prevState.showSubMenu }));
  };
  removeSubNav = () => {
    this.setState({ showSubMenu: false });
  };
  render() {
    const { userEnv, userInfo, user_notifications } = this.props.state;
    const {
      isLoading,
      allNotifications,
      unReadNotification,
      readNotification,
    } = this.state;
    const notifications = [...user_notifications].reverse();
    const { history } = this.props;
    return (
      <div className="Dashboardmain-container" style={{ paddingRight: "2.5%" }}>
        {isLoading && <Loader />}
        <div className="Dashboardmain-container__header">
          <Header
            isDashboardShow={true}
            history={history}
            userInformation={userInfo ? userInfo : null}
            userEnvoirnment={userEnv}
            toggleSubNav={this.toggleSubNav}
            removeSubNav={this.removeSubNav}
          />
          <div
            style={{
              marginTop: "8%",
              paddingLeft: "2%",
            }}
          >
            <h1 className="envrionmentsMainHeading">Notifications</h1>
            <h1 className="breadcrumbs">
              Home / <span style={{ color: "#3F4254" }}>Notifications</span>
            </h1>

            <p
              style={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#3F4254",
                marginTop: "20px",
              }}
            >
              New Notifications
            </p>
          </div>

          <div
            className="notificationCard_"
            style={{ width: "100% !important" }}
          >
            <div className="notificationBell">
              <img
                src={BellLabel}
                style={{
                  width: "60px",
                  height: "60px",
                  marginRight: "10px",
                }}
                alt=""
              ></img>
            </div>
            <div style={{ width: "100%" }}>
              {readNotification?.length > 0 ? (
                readNotification?.map((res, index) => (
                  <>
                    <p
                      key={index}
                      className="notificationBar_"
                      style={{ width: "100% !important" }}
                    >
                      {res.description}
                      <span className="messageTime">{res.createdOn}</span>
                    </p>
                  </>
                ))
              ) : (
                <p
                  className="notificationBar_"
                  style={{ width: "100% !important" }}
                >
                  You don't have any latest notification(s)
                </p>
              )}
            </div>
          </div>
          <p
            style={{
              fontWeight: 600,
              fontSize: "20px",
              color: "#3F4254",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            Past Notifications
          </p>

          <div
            className="notificationCard_"
            style={{ width: "100% !important", marginBottom: "5%" }}
          >
            <div className="notificationBell">
              <img
                src={BellLabel}
                style={{
                  width: "60px",
                  height: "60px",
                  marginRight: "10px",
                }}
                alt=""
              ></img>
            </div>
            <div style={{ width: "100%" }}>
              {unReadNotification?.map((res, index) => (
                <>
                  <p
                    key={index}
                    className="notificationBar_"
                    style={{
                      width: "100% !important",
                      background: !res?.isRead ? "#f0faff" : "#FFF4DE",
                    }}
                  >
                    {res.description}
                    <span className="messageTime">{res.createdOn}</span>
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(Notifications));
