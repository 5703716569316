import { Box, Typography } from "@material-ui/core";
import React from "react";

const UserRegistrationFooter = () => {
  return (
    <div className="registrationFooter">
      <Box className="helpingText">
        <Typography style={{ color: "#7E8299" }}>Need Help?</Typography>
        <Typography style={{ color: "#1BBED0" }}>
          Contact Aquatic AV Support
        </Typography>
      </Box>
      <div className="bottomFooterWrap">
        <span className="copyrightText">© 2021 Aquatic AV Inc.</span>
        <span>
          <a
            href="https://www.aquaticav.com/pages/terms-of-use"
            target="_blank"
            className="footerLinks"
          >
            Terms
          </a>
        </span>
        <span>
          <a
            href="https://www.aquaticav.com/pages/terms-of-use"
            target="_blank"
            className="footerLinks"
          >
            Privacy
          </a>
        </span>
        <span>
          <a
            href="https://www.aquaticav.com/pages/contact"
            target="_blank"
            className="footerLinks"
          >
            Contact Us
          </a>
        </span>
      </div>
    </div>
  );
};

export default UserRegistrationFooter;
