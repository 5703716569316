export function convertTimestamp(timestamp) {
  const date = new Date(timestamp);

  // Get the day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed
  const year = date.getFullYear();

  // Ensure day and month are formatted as two digits
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Format the date as "MM/DD/YYYY"
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

  return formattedDate;
}

export function getLastOnlineTimestamp(timestamp) {
  const currentDate = new Date();
  const lastOnlineDate = new Date(timestamp);
  const timeDifferenceInSeconds = Math.floor(
    (currentDate - lastOnlineDate) / 1000
  );

  if (timeDifferenceInSeconds < 60) {
    return "Just now";
  } else if (timeDifferenceInSeconds < 3600) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
  } else {
    const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
    return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
  }
}

// const lastOnlineTimestamp = "2023-09-21T18:12:49.3204478";
// const lastOnlineFormatted = getLastOnlineTimestamp(lastOnlineTimestamp);

// console.log(`Last online: ${lastOnlineFormatted}`);

const tempConversion = (temprature) => {
  let tempratureScale = temprature === 1 ? "F" : "C";
  if (tempratureScale === "C" && temprature !== 0) {
    let celsius = ((temprature - 32) * 5) / 9;
    return celsius?.toFixed(2);
  } else {
    return temprature;
  }
};

export function getFirstCharOfString(inputString) {
  if (typeof inputString !== "string" || inputString?.length === 0) {
    return ""; // Return an empty string for invalid input
  }
  return inputString?.charAt(0);
}

export function capitalizeFirstLetter(str) {
  return str?.slice(0, 1)?.toUpperCase() + str?.slice(1);
}

export function isPDFUrl(url) {
  return url?.toLowerCase()?.endsWith(".pdf");
}
export function isDOCXUrl(url) {
  return url?.toLowerCase()?.endsWith('.docx');
}
export function isExcelUrl(url) {
  return url.toLowerCase().endsWith('.xls') || url.toLowerCase().endsWith('.xlsx');
}