import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import {
  alertBulb,
  editSign,
  //   chemicaltest,
  plusSign,
  deleteIcon,
  tickIcon,
} from "../../../resources/images";
import chemicaltest from "../../../resources/images/chemicaltest.png";
import watercal from "../../../resources/images/watercal.jpg";
import alerticon from "../../../resources/images/alert.svg";
import { useSelector, useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { useLocation } from "react-router-dom";
import { Loader } from "../index";
import { toast } from "react-toastify";
import moment from "moment";
import ChemicalGraphReport from "./ChemicalTestGraph";
import { Tab, Paper } from "@material-ui/core";
import { TabList, TabPanel, TabContext } from "@material-ui/lab";
import cross from "../../../resources/images/crossx.svg";
import ChemicalTestingCard from "./ChemicalTestingCard";
import mqttClient from "../../../config/MqttClient";
import crc from "crc";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  outline: "none",
};
const modalData = [
  {
    Serial: "1",
    data: "The water is clear without a noticeable smell; you can clearly see the heads or tails of a dime dropped at the bottom of the spa.",
  },
  {
    Serial: "2",
    data: "The water is clear but has a noticeable chemical smell.",
  },
  {
    Serial: "3",
    data: "The water is clear but has a noticeable musty smell.",
  },
  {
    Serial: "4",
    data: "The water is green or otherwise discolored.",
  },
  {
    Serial: "5",
    data: "The water appears cloudy even when the jets are off.",
  },
  {
    Serial: "6",
    data: "The water appears foamy or soapy even when the jets are off.",
  },
];

var testDate = new Date();

export default function WaterLifeDetail({
  backtowatertreatment,
  isActive,
  waterlifemanage,
  filtermanage,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const spaDeviceId = location?.state?.SpaId;

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [active, SetActive] = useState("usageHistory");
  const [filterDate, setFilterDate] = useState(new Date());
  const [phLevel, setPhlevel] = useState(0);
  const [chlorineLevel, setChlorineLevel] = useState(0);
  const [alkalineLevel, setAlkalineLevel] = useState("");
  const [waterClarity, setWaterClarity] = useState(0);
  const [addedChemical, setAddedChemical] = useState(true);
  const [testingList, setTestingList] = useState([]);
  const [graphValues, setGraphValues] = useState({
    pH: [],
    Chlorine: [],
    Alkalinity: [],
  });
  const [latestData, setLatestData] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [graphLabels, setGraphLabels] = useState([
    // "1 Aug",
    // "3 Aug",
    // "5 Aug",
    // "7 Aug",
    // "9 Aug",
    // "11 Aug",
  ]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [replacedDays, setReplacedDays] = useState(0);
  const [activeTab, setActiveTab] = useState("pH");
  const [loadGraph, setLoadGraph] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [maxDate, setMaxDate] = useState("");
  const [graphStartDate, setGraphStartDate] = useState(
    new Date(testDate.getFullYear(), testDate.getMonth(), 1)
  );
  const [graphEndDate, setGraphEndDate] = useState(
    new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0)
  );
  const spaOverviewReducer = useSelector((state) => state.SpaOverviewReducer);

  const getMonth = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const graphRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      onLoadChemicalList();
      onLoadGraphDetails();
      onLoadCurrentChemicalList();
    }
  }, [isActive]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMaxDate(today);
  }, []);

  const onLoadCurrentChemicalList = () => {
    setIsLoading(true);
    // const fields = {
    //   deviceId: spaDeviceId,
    // };

    dispatch(SpaOverviewAction.onGetFilterLifeStats(spaDeviceId)).then(
      (data) => {
        console.log("current response--->:", data);
        setReplacedDays(data?.data?.replacedDays);
        setIsLoading(false);
      }
    );
  };

  const sorting = (a, b) => {
    let date1 = new Date();
    let date2 = new Date();

    let day1 = a?.day;
    let month1 = a?.month;
    let year1 = a?.year;

    let day2 = b?.day;
    let month2 = b?.month;
    let year2 = b?.year;

    let firstDate = date1.setFullYear(year1, month1 - 1, day1);
    let lastDate = date2.setFullYear(year2, month2 - 1, day2);
    return firstDate - lastDate;
  };

  const onLoadGraphDetails = (firstDate = false, lastDate = false) => {
    let testArray = [];
    let graphLabelsCopy = [];
    let graphValues = {
      pH: [],
      Chlorine: [],
      Alkalinity: [],
    };

    setIsLoading(true);
    const fields = {
      startDate: firstDate ? firstDate : graphStartDate,
      endDate: lastDate ? lastDate : graphEndDate,
      deviceId: spaDeviceId,
    };

    console.log("fileds:", fields);

    dispatch(
      SpaOverviewAction.onGetCurrentSpaChemicalTestigGraphDetails(fields)
    ).then((data) => {
      setIsLoading(false);
      const lastIndex = data?.data?.length - 1;

      if (data?.data !== null) {
        setLoadGraph(true);
        data?.data?.forEach((res, i) => {
          testArray.push(...res.data);

          if (lastIndex === i) {
            let finalIndex = testArray.length - 1;

            testArray.sort(sorting)?.forEach((item, index) => {
              let items = `${item?.day} ${getMonth[item?.month]}`;
              graphLabelsCopy.push(items);
              setGraphLabels(graphLabelsCopy);

              // For graph datasets
              graphValues.pH.push(item.ph);
              graphValues.Alkalinity.push(item.alkalinity);
              graphValues.Chlorine.push(item.chlorine);

              setGraphValues(graphValues);

              if (finalIndex === index) {
                setTimeout(() => {
                  setLoadGraph(false);
                }, 2000);
              }
            });
          }
        });
      } else {
        setGraphValues({
          pH: [],
          Chlorine: [],
          Alkalinity: [],
        });
        setGraphLabels([]);
        setLoadGraph(false);
      }
      // onGetWaterLifeStats();
    });
  };

  const onLoadChemicalList = () => {
    setIsLoading(true);
    const fields = {
      deviceId: spaDeviceId,
      pagination: {
        pageSize: 50,
        pageNo: 1,
        orderByKey: "Id",
        orderByVal: 0,
      },
    };

    console.log("params:", fields);

    dispatch(SpaOverviewAction.onGetSpaChemicalTestigList(fields)).then(
      (data) => {
        console.log("params--->:", data?.data);
        setLatestData(data?.data);
        setIsLoading(false);
        setTestingList(data?.data ? data?.data?.data : []);
      }
    );
  };

  const onGetWaterLifeStats = () => {
    dispatch(SpaOverviewAction.onGetWaterLifeStats(spaDeviceId));
  };

  const onUpdateWaterLife = () => {
    setIsEdit(!isEdit);
    setSelectedItem(null);
    const updatePayload = {
      id: selectedItem,
      testDate: moment(filterDate).format("MM/DD/yyyy"),
      chlroine: chlorineLevel,
      alkalinity: alkalineLevel,
      waterClarity: waterClarity,
      ph: phLevel,
      isChemicalsAdded: addedChemical,
      deviceId: spaDeviceId,
    };
    onAddUpdateChemicalTesting(updatePayload);
  };

  const onSaveChemicalTesting = () => {
    setIsEdit(false);
    setIsNewItem(false);
    const savePayload = {
      //   id: 0,
      testDate: moment(filterDate).format("MM/DD/yyyy"),
      chlroine: chlorineLevel,
      alkalinity: alkalineLevel,
      waterClarity: waterClarity,
      ph: phLevel,
      isChemicalsAdded: addedChemical,
      deviceId: spaDeviceId,
    };
    console.log("add data:", savePayload);

    const phInBit = Math.round(phLevel * (1 << 4));
    const clInBit = Math.round(chlorineLevel * (1 << 4));
    const alInBit = Math.round(alkalineLevel * (1 << 4));
    const claInBit = Math.round(waterClarity * (1 << 4));

    const phHex = phInBit.toString(16).padStart(4, "0");
    const clHex = clInBit.toString(16).padStart(4, "0");
    const alHex = alInBit.toString(16).padStart(4, "0");
    const claHex = claInBit.toString(16).padStart(4, "0");

    console.log("bit values:", phHex, "*", clHex, "*", alHex, "*", claHex);

    const phReversedHex = phHex.split("").reverse().join("");
    const clReversedHex = clHex.split("").reverse().join("");
    const alReversedHex = alHex.split("").reverse().join("");
    const claReversedHex = claHex.split("").reverse().join("");

    console.log(
      "reverse values:",
      phReversedHex,
      "*",
      clReversedHex,
      "*",
      alReversedHex,
      "*",
      claReversedHex
    );

    const crcValue = `${phReversedHex}${clReversedHex}${alReversedHex}${claReversedHex}`;

    const crc8Conversion = crc.crc8(crcValue).toString(16);

    console.log(crc8Conversion);

    // Conversion of CRC8 LEFT

    // console.log("dynamic values:", crcValue);

    mqttClient.publish(
      `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/devicebound`,
      `${crcValue}${crc8Conversion}`
    );

    onAddUpdateChemicalTesting(savePayload);
    setFilterDate("");
    setPhlevel(0);
    setChlorineLevel(0);
    setAlkalineLevel("");
    setWaterClarity("");
    setAddedChemical(false);
  };

  const onAddUpdateChemicalTesting = (chemicalPayload) => {
    setIsLoading(true);
    dispatch(
      SpaOverviewAction.onAddUpdateChemicalTesting(chemicalPayload)
    ).then((data) => {
      console.log("dataa-->", data);
      if (data?.success) {
        toast.success(data?.message);
        onLoadChemicalList();
        onLoadGraphDetails();
      } else {
        setIsLoading(false);
        toast.error(data?.message);
      }
    });
  };

  const onDeleteWaterLife = (value) => {
    const itemPayload = {
      ids: [value.id],
    };
    setIsLoading(true);
    dispatch(SpaOverviewAction.onDeleteSPAChemicalTesting(itemPayload)).then(
      (res) => {
        console.log("response of delete:", res);
        if (res?.success) {
          toast.success("Item deleted successfully");
          onLoadChemicalList();
          onLoadGraphDetails();
        } else {
          setIsLoading(false);
          toast.error(res?.message);
        }
      }
    );
  };

  const EditItem = (value) => {
    setSelectedItem(value?.id);
    setFilterDate(value?.testDate);
    setPhlevel(value?.ph);
    setWaterClarity(value?.waterClarity);
    setChlorineLevel(value?.chlroine);
    setAddedChemical(value?.isChemicalAdded);
    setAlkalineLevel(value?.alkalinity);
    setIsEdit(!isEdit);
    setIsNewItem(false);
  };

  const onPressAddNew = () => {
    setFilterDate(Date.now());
    setPhlevel("");
    setWaterClarity("");
    setChlorineLevel("");
    setAlkalineLevel("");
    setAddedChemical(false);
    setIsNewItem(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const latestTest = latestData?.data
    ?.sort((a, b) => {
      const dateA = new Date(a.testDate);
      const dateB = new Date(b.testDate);
      return dateB - dateA;
    })
    .slice(0, 1)[0];

  const today = new Date();
  const latestTestDate = new Date(latestTest?.testDate);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const numDays = Math.floor(
    (today.getTime() - latestTestDate.getTime()) / millisecondsPerDay
  );

  const onSetDate = (dates, setViewCalender) => {
    console.log("date range:", dates);
    setGraphStartDate(dates[0]?.startDate);
    setGraphEndDate(dates[0]?.endDate);
    setViewCalender(false);
    onLoadGraphDetails(dates[0]?.startDate, dates[0]?.endDate);
  };

  function addDaysToDate(inputDate, daysToAdd) {
    const date = new Date(inputDate);
    date.setDate(date.getDate() + daysToAdd);
    return date;
  }

  const givenDate = new Date(latestTest?.testDate);
  const futureDate = addDaysToDate(givenDate, 30);


  const treatmentLabels = () => {
    console.log("treatmentLabels", spaOverviewReducer?.spaMqttDeviceName);
    mqttClient.publish(
      `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/devicebound`,
      "55551b00600100040d5048000000434c000000414c4b0000434c41000040"
    );
  };

  const treatmentRanges = () => {
    console.log("treatmentRanges", spaOverviewReducer?.spaMqttDeviceName);
    mqttClient.publish(
      `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/devicebound`,
      "5555140160010060009000000040010000a00f0000a0004001000542"
    );
  };

  return (
    <Grid
      item
      md={12}
      className="gridMainSpaWrapper px-3"
      style={{ backgroundColor: "#fff", borderRadius: 12 }}
    >
      {isLoading ? <Loader /> : null}
      <Grid item md={12}>
        <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperHeader"
          style={{ padding: "0" }}
        >
          <Grid
            item
            md={12}
            className="gridRow"
            style={{
              borderBottom: "1px solid rgba(161, 165, 183, 0.18)",
              paddingBottom: 15,
            }}
          >
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading filterLifeHeader"
              style={{ borderBottom: "none" }}
            >
              <Grid md={8}>
                <span item md={6} className="customTabsColored customTabLine">
                  Chemical Testing
                </span>

                <span
                  onClick={filtermanage}
                  item
                  md={6}
                  className="customTabs"
                  style={{ marginLeft: "8%" }}
                >
                  Filter Life
                </span>

                <span
                  onClick={waterlifemanage}
                  item
                  md={6}
                  className="customTabs"
                  style={{ marginLeft: "8%" }}
                >
                  Water Life
                </span>
              </Grid>

              {/* <Grid>
                <span
                  item
                  md={4}
                  className="settingChange"
                  onClick={backtowatertreatment}
                >
                  back to water treatment
                </span>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperBody detailBody"
          style={{ padding: "20px " }}
        >
          <Grid item md={12} className="gridRow">
            <Grid
              item
              md={12}
              className="gridSpaPumpsControlItem"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid item md={5}>
                <div className="filterLifeInnerWrapper detailInnerFilter">
                  <span>
                    <img src={chemicaltest} alt="" />
                  </span>

                  <span>
                    <div className="bigTitle">
                      {numDays ? numDays : "0"} Days
                    </div>
                    <div className="filterLifeSubHeading">since last test</div>
                    <div className="filterLifeCardText">
                      Recommend next test date:
                    </div>
                    <div className="filterLifeCardDate">
                      30 days away | {moment(new Date()).format("LL")}
                    </div>
                  </span>
                </div>
              </Grid>

              <Grid item md={5}>
                {alert && (
                  <span className="lifeTipAlert">
                    <span className="tipHeading">
                      Alert <img src={alerticon} alt="" />
                    </span>
                    <p>
                      The chlorine level in your last test was too high. Make
                      sure its in recommend range.
                    </p>
                  </span>
                )}
                <span className="lifeTip">
                  <span className="tipHeading tipHeadingChemical">
                    Chemical Testing Tip <img src={alertBulb} alt="" />
                  </span>
                  <p className="testtip">
                    Extend water life by keeping your chemicals in the green and
                    refilling your water every four months.
                  </p>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

        <ChemicalTestingCard
          chemicalTestManage={() => {}}
          days={numDays ? numDays : "0"}
          // recommendedDate={moment(new Date()).format("LL")}
          recommendedDate={moment(futureDate.toDateString()).format("LL")}
        />
        <div style={{ borderTop: "1px solid #BDBDBD" }} />

        <Grid
          item
          md={12}
          xs={12}
          className="gridSpaPumpsWrapperBody detailBody"
          style={{ padding: "20px ", marginTop: "40px" }}
        >
          <div className="gridReportingWrapper">
            <div square>
              <TabContext value={active}>
                {/* <button>abc</button> */}
                {/* <TabList
                                    className="reportingTabs"
                                    variant="scrollable"
                                    onChange={(event, newValue) => {
                                        SetActive(newValue);
                                    }}
                                    textColor="primary">
                                    <Tab label="Use History" value={"usageHistory"} />
                                    <Tab label="Temprature History" value={"tempratureHistory"} />
                                    <Tab label="Chemical History" value={"chemicalHistory"} />
                                </TabList> */}
                <TabPanel value="usageHistory" className="reportContent">
                  {loadGraph ? (
                    <Loader />
                  ) : (
                    <ChemicalGraphReport
                      graphRef={graphRef}
                      title="Chemical Test History"
                      data={{
                        labels: graphLabels,
                        datasets: [
                          {
                            data:
                              Object.entries(graphValues).length > 0
                                ? graphValues[activeTab]
                                : [],
                            backgroundColor: "#1BBED0",
                            barThickness: 30,
                            borderRadius: 4,
                          },
                        ],
                      }}
                      dates={{
                        to: moment(graphEndDate).format("MMMM D, YYYY"),
                        from: moment(graphStartDate).format("MMMM D, YYYY"),
                      }}
                      active={activeTab}
                      setActive={setActiveTab}
                      onSetDate={onSetDate}
                    />
                  )}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </Grid>

        <div style={{ borderTop: "1px solid #BDBDBD" }} />

        <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperBody detailBody"
          style={{ padding: "20px ", marginTop: "30px" }}
        >
          <Grid item md={12} className="gridRow testMobileWrapper">
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading filterLifeHeader"
              style={{ borderBottom: "none", padding: "0" }}
            >
              <span item md={6} className="heading testhead">
                Your Tests
              </span>
              <span className="filterButtons">
                <button onClick={() => treatmentLabels()}>
                  Treatment Labels
                </button>

                <button onClick={() => treatmentRanges()}>
                  Treatment Ranges
                </button>

                <button onClick={() => onPressAddNew()}>
                  <img src={plusSign} alt="" /> Add
                </button>
              </span>
            </Grid>

            <div
              className="filterGrid"
              style={{
                margin: "0px",
              }}
            >
              {/* <table>
                <thead>
                  <th>DATE</th>
                  <th>PH LEVEL</th>
                  <th>CHLORINE LEVEL</th>
                  <th>ALKALINITY LEVEL</th>
                  <th>WATER CLARITY</th>
                  <th>ADDED CHEMICALS</th>
                  <th></th>
                </thead>
                <tbody>
                {testingList?.length > 0 &&
                testingList.map((item, i) => (
                  <tr className="filterGridContent" key={i}>
                    {
                      <>
                        {isEdit && selectedItem === item.id ? (
                          <>
                            <td className="dateHead">
                              <input
                                type="date"
                                max={maxDate}
                                value={new Date(`${filterDate}Z`)
                                  .toISOString()
                                  .slice(0, 10)}
                                style={{
                                  width: "84%",
                                }}
                                onChange={(e) => {
                                  setFilterDate(e.target.value);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={phLevel}
                                onChange={(e) => setPhlevel(e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={chlorineLevel}
                                onChange={(e) =>
                                  setChlorineLevel(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={alkalineLevel}
                                onChange={(e) =>
                                  setAlkalineLevel(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={waterClarity}
                                onChange={(e) =>
                                  setWaterClarity(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <select
                                name="clean"
                                id="clean"
                                value={addedChemical}
                                onChange={(e) =>
                                  setAddedChemical(JSON.parse(e.target.value))
                                }
                                className="select"
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </td>
                            <span className="editDone">
                              <button onClick={() => onUpdateWaterLife()}>
                                <img src={tickIcon} alt="" /> Done
                              </button>
                            </span>
                          </>
                        ) : (
                          <>
                            <td className="dateHead">
                            <img className="deleteRow"
                              style={{ cursor: "pointer" }}
                              onClick={() => onDeleteWaterLife(item)} src={deleteIcon} alt="" />
                              {moment(item?.testDate).format("MMM DD, YYYY")}
                            </td>
                            <td>{item?.ph}</td>
                            <td>{item?.chlroine}</td>
                            <td>{item?.alkalinity}</td>
                            <td>{item?.waterClarity}</td>
                            <td>{item?.isChemicalAdded ? "Yes" : "No"}</td>
                            <td className="">
                              <button onClick={() => EditItem(item)}>
                                <img src={editSign} alt="" /> Edit
                              </button>
                            </td>
                          </>
                        )}
                      </>
                    }
                  </tr>
                ))}
                </tbody>
              </table> */}
              <ul className="filterGridHeader1">
                <li className="dateHead_">DATE</li>
                <li>PH LEVEL</li>
                <li>CHLORINE LEVEL</li>
                <li>ALKALINITY LEVEL</li>

                <li className="waterCal" onClick={handleOpen}>
                  {" "}
                  {/* <img src={watercal} style={{ marginRight: "5px" }}></img> */}
                  WATER CLARITY
                </li>
                <li>ADDED CHEMICALS</li>
              </ul>

              {testingList?.length > 0 &&
                testingList.map((item, i) => (
                  <ul className="filterGridContent" key={i}>
                    {
                      <>
                        {isEdit && selectedItem === item.id ? (
                          <>
                            <li className="dateHead">
                              <input
                                type="date"
                                max={maxDate}
                                value={new Date(`${filterDate}Z`)
                                  .toISOString()
                                  .slice(0, 10)}
                                style={{
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  setFilterDate(e.target.value);
                                }}
                              />
                            </li>
                            <li>
                              <input
                                type="text"
                                value={phLevel}
                                onChange={(e) => setPhlevel(e.target.value)}
                              />
                            </li>
                            <li>
                              <input
                                type="text"
                                value={chlorineLevel}
                                onChange={(e) =>
                                  setChlorineLevel(e.target.value)
                                }
                              />
                            </li>
                            <li>
                              <input
                                type="text"
                                value={alkalineLevel}
                                onChange={(e) =>
                                  setAlkalineLevel(e.target.value)
                                }
                              />
                            </li>
                            <li>
                              <input
                                type="text"
                                value={waterClarity}
                                onChange={(e) =>
                                  setWaterClarity(e.target.value)
                                }
                              />
                            </li>
                            <li>
                              <select
                                name="clean"
                                id="clean"
                                value={addedChemical}
                                onChange={(e) =>
                                  setAddedChemical(JSON.parse(e.target.value))
                                }
                                className="chemicalTest"
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </li>
                            <span className="editDone">
                              <button onClick={() => onUpdateWaterLife()}>
                                <img src={tickIcon} alt="" /> Done
                              </button>
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="deleteRow"
                              style={{ cursor: "pointer" }}
                              onClick={() => onDeleteWaterLife(item)}
                            >
                              <img src={deleteIcon} alt="" />
                            </span>
                            <li className="dateHead">
                              {moment(item?.testDate).format("MMM DD, YYYY")}
                            </li>
                            <li>{item?.ph}</li>
                            <li>{item?.chlroine}</li>
                            <li>{item?.alkalinity}</li>
                            <li>{item?.waterClarity}</li>
                            <li>{item?.isChemicalAdded ? "Yes" : "No"}</li>
                            <span className="editDone">
                              <button onClick={() => EditItem(item)}>
                                <img src={editSign} alt="" /> Edit
                              </button>
                            </span>
                          </>
                        )}
                      </>
                    }
                  </ul>
                ))}

              <ul
                className="filterGridContent"
                style={{ paddingRight: "120px !important" }}
              >
                {isNewItem && (
                  <>
                    {console.log("alkalineLevel", alkalineLevel)}
                    {console.log("phLevel", phLevel)}
                    {console.log("chlorineLevel", chlorineLevel)}

                    <span
                      className="deleteRow"
                      onClick={() => setIsNewItem(false)}
                    >
                      <img src={deleteIcon} alt="" />
                    </span>
                    <li className="dateHead">
                      <input
                        type="date"
                        max={maxDate}
                        value={filterDate}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setFilterDate(e.target.value);
                        }}
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        value={phLevel}
                        onChange={(e) => setPhlevel(e.target.value)}
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        value={chlorineLevel}
                        onChange={(e) => setChlorineLevel(e.target.value)}
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        value={alkalineLevel}
                        onChange={(e) => setAlkalineLevel(e.target.value)}
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        value={waterClarity}
                        onChange={(e) => setWaterClarity(e.target.value)}
                      />
                    </li>
                    <li>
                      <select
                        name="clean"
                        id="clean"
                        value={addedChemical}
                        onChange={(e) =>
                          setAddedChemical(JSON.parse(e.target.value))
                        }
                        className="select"
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </li>
                    <span className="editDone">
                      <button onClick={() => onSaveChemicalTesting()}>
                        <img src={tickIcon} alt="" /> Done
                      </button>
                    </span>
                  </>
                )}
              </ul>
            </div>
          </Grid>
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <img src={cross} alt="cross"></img>
              </div>
              <div className="contentDiv">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h className="Waterresourceheading">
                    How to Determine Water Clarity
                  </h>
                  <h className="Waterresourcesubheading">
                    Aquatic AV measures water clarity based on the following
                    scale:
                  </h>
                </div>
                {modalData.map((item) => {
                  return (
                    <div className="ModalSerials">
                      <h className="serialNo">{item.Serial}</h>
                      <h className="modalData">{item.data}</h>
                    </div>
                  );
                })}
              </div>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
}
