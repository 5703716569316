import React, { useEffect, useState } from "react";
import { Box, Modal } from "@material-ui/core";
import CrossBold from "../../../resources/images/crossBold.png";
import Mobilelogo from "../../../resources/images/mobilelogo.png";
import CreateEnv from "../../../resources/images/tracker.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import "./deviceOwnership.css";
import { Loader } from "../../../components/common";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  p: 6,
  outline: "none",
  padding: "32px 48px 28px 48px",
  borderRadius: "20px",
  overflowY: "scroll",
};

const EnironmentModal = ({
  isDeviceOwnerShip,
  setIsDeviceOwnerShip,
  transferDeviceInfo,
  onGetDeviceOverviewInfo,
}) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = localStorage.getItem("userId");

  const [selectedEnv, setSelectedEnv] = useState("");
  const [customEnvName, setCustomEnvName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [staticEnvList, setStaticEnvList] = useState([]);
  const [allEnv, setAllEnv] = useState([]);
  const [customEnvIcons, setCustomEnvIcons] = useState([]);

  useEffect(() => {
    onGetStaticEnvironment(userId);
    onGetCustomEnvIcons(userId);
    onGetMyEnvironment(userId);
  }, []);

  const onSelectEnv = (env, key) => {
    setSelectedEnv({ ...env, key: key });
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      const truncatedText = text.slice(0, maxLength);
      return `${truncatedText}...`;
    }
    return text;
  };

  const onAddEnv = async () => {
    setIsLoading(true);
    const { environmentIcon, key, icon, environmentName } = selectedEnv;
    if (
      (key === "preset" && !icon && key === "preset" && !environmentIcon) ||
      !selectedEnv
    ) {
      setIsLoading(false);
      toast.error("Please select environment and icon");
      return;
    } else if (key === "custom" && /^\s*$/.test(customEnvName)) {
      setIsLoading(false);
      toast.error("Please enter environment name");
      return;
    }
    if (key === "custom" && !/^\s*$/.test(customEnvName)) {
      onAddDashboardEnv(customEnvName, "D");
    } else if (
      key === "preset" &&
      environmentIcon !== undefined &&
      environmentName !== undefined
    ) {
      onAddDashboardEnv(environmentName, "S");
    } else {
      claimDevice(false, selectedEnv.environmentId);
    }

    setIsLoading(false);
  };

  const onGetStaticEnvironment = (userId) => {
    dispatch(dashboardActions.onGetStaticEnvironment(userId))
      .then((env) => {
        setStaticEnvList(env?.data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const onGetCustomEnvIcons = (userId) => {
    dispatch(dashboardActions.onGetCustomEnvIcons(userId))
      .then((env) => {
        setCustomEnvIcons(env?.data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const onGetMyEnvironment = (userId) => {
    setIsLoading(true);
    dispatch(dashboardActions.onGetMyEnvironment(userId))
      .then((env) => {
        setIsLoading(false);
        setAllEnv(env?.data?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "error");
      });
  };

  const claimDevice = (isNewEnv, myEnvId) => {
    let req = {
      name: transferDeviceInfo.name,
      environmentId: isNewEnv ? myEnvId : selectedEnv.environmentId,
      deviceId: transferDeviceInfo.id,
    };
    setIsLoading(true);
    dispatch(dashboardActions.onAcceptOwnershipDevice(req)).then((res) => {
      setIsLoading(false);
      if (res && res?.data && res?.data?.success) {
        toast.success("Device has been successfully tranferred!");
        localStorage.removeItem("transferedDeviceFlag");
        if (isNewEnv) {
          onUpdateDeviceData(myEnvId);
        } else {
          onChangeEnv();
        }
        // closeTransferDevice();
        // onLoadCompleteData();
      } else {
        toast.error(
          res && res.data && res.data.message
            ? res.data.message
            : "Something went wrong please try again"
        );
      }
    });
  };

  const onAddDashboardEnv = async (envNam, envTap) => {
    setIsLoading(true);
    const req = {
      environmentId: 0,
      environmentName: envNam,
      environmentType: envTap,
      environmentIcon: selectedEnv.environmentIcon,
      latitud: "",
      longitude: "",
      status: 1,
      userId: userId,
      isSameAccountLocation: true,
    };
    dispatch(dashboardActions.onAddDashboardEnv(req)).then((data) => {
      if (data && data?.data && data?.data?.success) {
        claimDevice(true, data?.data?.data);
      } else {
        setIsLoading(false);
        toast.error(data?.data?.message);
      }
    });
  };
  const onUpdateDeviceData = async (selecEnv) => {
    let req = {
      serialNo: transferDeviceInfo.serialNo,
      purchaseDate: transferDeviceInfo.purchaseDate,
      dealerLocationId: transferDeviceInfo.dealerId,
      receiptStatus: transferDeviceInfo.receiptStatusCode
        ? transferDeviceInfo.receiptStatusCode
        : 0,
      deviceId: transferDeviceInfo.id,
      envId: selecEnv,
      name: transferDeviceInfo.name,
      installationDate: "",
    };
    await dispatch(dashboardActions.onUpdateDeviceInfo(req)).then((data) => {
      if (data && data?.data && data?.data?.success) {
        setIsLoading(false);
        toast.success("Device has been updated successfully");
        onGetDeviceOverviewInfo();

        setTimeout(() => {
          setIsDeviceOwnerShip(false);
        }, 3000);
      } else {
        setIsLoading(false);
        toast.error("Something went wrong, Please try again");
      }
    });
  };
  const onChangeEnv = async () => {
    setIsLoading(true);

    onUpdateDeviceData(selectedEnv?.id);
  };

  return (
    <div style={{ width: "80%" }}>
      {isLoading && <Loader />}
      <Modal
        open={isDeviceOwnerShip}
        // onClose={this.handleCloseCreateEnvModal}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0) !important",
        }}
      >
        <Box
          sx={style}
          style={{ width: "65%", height: "697px" }}
          className="envModalMobile"
        >
          <div className="envModal">
            <div className="CrossSection">
              {/* <img
              src={CrossBox}
              onClick={this.handleCloseCreateEnvModal}
              alt=""
            /> */}
            </div>
            <div className="CrossSectionMobile">
              <img
                src={CrossBold}
                // onClick={this.handleCloseCreateEnvModal}
                alt=""
              />
              <img src={Mobilelogo} alt="" />
              <div></div>
            </div>
            <div className="addEnvMobile">
              <img src={CreateEnv} alt="" />
            </div>
            <div className="EnvMainSection">
              <h1 className="addEnvModal">
                Create or Choose an Aquatic AV Environment
              </h1>
              <h1 className="addEnv_Modal">New Environment</h1>
              <h1 className="addEnv_Modal_">Create an Environment</h1>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: 400,
                  color: "#000000",
                }}
                className="addEnvDesc"
              >
                Organize your Aquatic AV devices by installation environment.
                Select a preset environment or create a custom environment where
                your Aquatic AV product will be installed.
              </p>
              <div className="EnvMainSection">
                {allEnv?.length > 0 && (
                  <h1 className="myenvHead">My Environments</h1>
                )}

                <div className="preEnv customRevEnvInner gab">
                  {allEnv?.map((env, i) => {
                    return (
                      <div
                        className="mobilepresenv"
                        key={i}
                        onClick={() => onSelectEnv(env, "preset")}
                      >
                        <span
                          className={
                            selectedEnv?.id === env?.id &&
                            selectedEnv?.key === "preset"
                              ? "selectedEnvNew"
                              : ""
                          }
                        >
                          <img
                            src={env?.icon}
                            style={{
                              width: 70,
                              height: 70,
                            }}
                            alt=""
                          ></img>
                        </span>
                        <h6 className="EnvName">
                          {truncateText(env?.name, 10)}
                        </h6>
                        <span className="toolTip"> {env?.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <h1 className="myenvHead">Preset Environment</h1>
              <div className="preEnv customRevEnvInner">
                {staticEnvList?.map((env, i) => {
                  return (
                    <div
                      className="mobilepresenv"
                      key={i}
                      onClick={() => onSelectEnv(env, "preset")}
                    >
                      <span
                        className={
                          selectedEnv?.environmentId === env?.environmentId &&
                          selectedEnv?.key === "preset"
                            ? "selectedEnvNew"
                            : ""
                        }
                      >
                        <img
                          src={env?.environmentIcon}
                          style={{
                            width: 70,
                            height: 70,
                          }}
                          alt=""
                        ></img>
                      </span>
                      <h6 className="EnvName">{env?.environmentName}</h6>
                      <span className="toolTip"> {env?.environmentName}</span>
                    </div>
                  );
                })}
              </div>
              <h1 className="myenvHead" style={{ marginTop: "7px" }}>
                Custom Environment
              </h1>
              <input
                type="text"
                className="EnvInput"
                placeholder="Environment Name"
                value={customEnvName}
                onChange={(e) => setCustomEnvName(e.target.value)}
                maxLength={30}
              />
              <div className="customEnvInner">
                {customEnvIcons?.map((icon, index) => (
                  <span
                    key={index}
                    className={
                      selectedEnv?.environmentId === icon?.id &&
                      selectedEnv?.key === "custom"
                        ? "selectedEnv"
                        : "mobileEnv"
                    }
                    style={{
                      marginRight: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <img
                      src={icon?.icon}
                      alt={`icon ${index}`}
                      style={{
                        width: 70,
                        height: 70,
                      }}
                      onClick={() =>
                        onSelectEnv(
                          {
                            ...icon,
                            environmentId: icon?.id,
                            environmentIcon: icon?.icon,
                          },
                          "custom"
                        )
                      }
                    />
                  </span>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  width: "100%",
                  justifyContent: "end",
                  paddingTop: "20px",
                }}
                className="SaveButton"
                onClick={() => onAddEnv()}
              >
                <button className="MobileButton">Save</button>
              </div>
            </div>
          </div>
          <Box>
            <div className="bottomFooterWrap regestrationFooter">
              <span className="copyrightText">© 2021 Aquatic AV Inc.</span>
              <span>
                <a
                  href="https://www.aquaticav.com/pages/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                  className="footerLinks"
                >
                  Terms
                </a>
              </span>
              <span>
                <a
                  href="https://www.aquaticav.com/pages/terms-of-use"
                  target="_blank"
                  className="footerLinks"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </span>
              <span>
                <a
                  href="https://www.aquaticav.com/pages/contact"
                  target="_blank"
                  className="footerLinks"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </span>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EnironmentModal;
