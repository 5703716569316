import React, { useState, useEffect, useImperativeHandle } from "react";
import { Typography } from "@material-ui/core";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import ChemicalGraphReport from './ChemicalTestGraph'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Calendar } from "../../../resources/images";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const types =  [
  {
    label: "Use History",
    icon: require(`../../../assets/images/reporting1.png`),
    activeicon: require(`../../../assets/images/reporting1active.png`),

  },
  {
    label: "Temperature",
    icon: require(`../../../assets/images/reporting2.png`),
    activeicon: require(`../../../assets/images/reporting2active.png`),

  },
  { label: "Chemical", icon: require(`../../../assets/images/reporting3.png`),
  activeicon: require(`../../../assets/images/reporting3active.png`),
},
  
  {
    label: "WaterLife",
    icon: require(`../../../assets/images/reporting4.png`),
    activeicon: require(`../../../assets/images/reporting4active.png`),

  },
  {
    label: "FilterLife",
    icon: require(`../../../assets/images/reporting5.png`),
    activeicon: require(`../../../assets/images/reporting5active.png`),

  },
];
const chTypes = ["pH", "Chlorine", "Alkalinity"];

const theme = {
  blue: {
    default: "#fff",
    //   hover: "#283593"
  },
};
const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: #8d99a5;
  padding: 4px 16px;
  border-radius: 6px;
  outline: 0;
  //   text-transform: uppercase;
  margin: 10px 3px;
  cursor: pointer;
  //   box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

Button.defaultProps = {
  theme: "blue",
};

const ButtonToggle = styled(Button)`
  opacity: 1;
  ${({ active }) =>
    active &&
    `
  opacity: 1; 
  background-color:#1BBED0;
  color:#fff;
  `}
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 3,
  outline: "none",
};
function ReportingGraph({
  title,
  data,
  dates,
  graphRef,
  active,
  setActive,
  onSetDate,
  chGraphLabels,
  chGraphValues
}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      customCanvasBackgroundColor: {
        color: "lightGreen",
      },
      title: {
        display: false,
        text: "Water Report",
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0) ",
        },
        title: {
          display: true,
          // text: "Date Used",
        },
      },
      y: {
        grid: {
          color: "#EAEAEA",
          borderDash: [3, 3],
        },
        title: {
          display: true,
          // text: "Minutes Used",
        },
      },
    },
  };
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);
  const [activeChTab, setActiveChTab] = useState(chTypes[0])

  const [viewCalender, setViewCalender] = useState(false);
  // const [active, setActive] = useState(types[0]);
  useImperativeHandle(
    graphRef,
    () => ({
      getMyState: () => {
        return active;
      },
    }),
    [active]
  );

  useEffect(() => {
    if (dates) {
      setSelectedDates([
        {
          startDate: new Date(dates?.from),
          endDate: new Date(dates?.to),
          key: "selection",
        },
      ]);
    }
  }, []);

  return (
    <div ref={graphRef} className="reportContent">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography className="reportTitle">{title}</Typography>
        </div>
        <span
          className="reportDateFilters"
          onClick={() => setViewCalender(!viewCalender)}
          style={{
            cursor: "pointer",
            background: "#F1F1F2",
            borderRadius: "6px",
            color: "#7E8299",
            fontFamily: "Roboto",
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          {dates.from} - {dates.to}{" "}
          <img
            src={Calendar}
            style={{ marginLeft: "10px", objectFit: "contain" }}
            alt=""
          />
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        {types.map((type, i) => (
          <div
            className={
              type.label === active.label ? "reportingSelectedTabStyle" : "reportingTabStyle"
            }
            style={{ cursor: 'pointer'}}
            onClick={() => setActive(type)}
          >
            <img
              src={type.label === active.label ? type.activeicon : type.icon}
              style={{ height: 35, width: 35, objectFit: "contain" }}
              alt=""
            />
            <ButtonToggle
              // active={active === type}
              style={{ fontSize: 14, fontFamily: "Roboto", fontWeight: 600, padding: `0 16px`,
              marginBottom: 0 }}
            >
              {type?.label}
            </ButtonToggle>
          </div>
        ))}
      </div>

      <div className="chartContent">
        {active.label === types[2].label ? 
        <ChemicalGraphReport
        graphRef={graphRef}
        data={{
          labels: chGraphLabels,
          datasets: [
            {
              data: 
                Object.entries(chGraphValues).length > 0
                  ? chGraphValues[activeChTab]
                  : [],
              backgroundColor: "#1BBED0",
              barThickness: 30,
              borderRadius: 4,
            },
          ],
        }}
        isReportingGraph={true}
        active={activeChTab}
        setActive={setActiveChTab}
        onSetDate={onSetDate}
      />: <Bar data={data} options={options} />}
      </div>
      {/* {viewCalender && ( */}
      <Modal
        open={viewCalender}
        onClose={() => setViewCalender(!viewCalender)}
        style={{ backgroundColor: "rgba(0, 0, 0, 0) !important" }}
      >
        <Box sx={style}>
          {/* <div style={{position:"relative"}}> */}
          <DateRangePicker
            onChange={(item) => {
              setSelectedDates([item.selection]);
            }}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={true}
            months={2}
            ranges={selectedDates}
            direction="horizontal"
            rangeColors={["#0083BB"]}
            calendars={2}
          />
          <div className="calendarbtndiv">
            <button
              className="calendarbtnCancel"
              onClick={() => setViewCalender(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => onSetDate(selectedDates, setViewCalender)}
              className="calendarbtn"
            >
              Set Date
            </button>
          </div>

          {/* </div> */}
        </Box>
      </Modal>
      {/* )} */}
    </div>
  );
}

export default React.forwardRef(ReportingGraph);
