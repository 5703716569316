import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const ThemedSlider = withStyles({
  root: {
    color: "#49B8EF",
    height: 8
  },
  thumb: {
    top: "14px",
    color: "#fff",
    border: "2px solid #49B8EF"
  },
  valueLabel: {
    display: "none"
  },
  track: {
    height: 4
  },
  rail: {
    height: 4
  }
})(Slider);

export default function CustomizedSlider(props) {
  return (
    <ThemedSlider
      valueLabelDisplay="auto"
      aria-label="pretto slider"
      defaultValue={20}
      size="large"
      {...props}
    />
  );
}
