import React, { useEffect, useState } from "react";
import { FloatingLabelInput, Loader } from "../../../components/common";
import { toast } from "react-toastify";
// import { ReactSVG } from 'react-svg';
import { accountActions } from "../../../store/accounts/accountActions";
import {
  Grill,
  House,
  Lighting,
  Marine,
  Motorcycles,
  Powersports,
  Spa,
  Star,
} from "../../../components/common/SVG/SvgIcons";

import {
  backArrow,
  vector_0,
  vector_1,
  vector_2,
  vector_3,
  vector_4,
  vector_5,
  vector_6,
  vector_7,
  finishSetup,
} from "../../../resources/images/index";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { RiAddFill, RiArrowDownSLine } from "react-icons/ri";
import Tooltip from "@material-ui/core/Tooltip";

const CreateEnvironment = ({
  onEnvCreate,
  onGetEnvInfo,
  noEnvHeading,
  title,
  setNextStep,
}) => {
  const dispatch = useDispatch();
  const [envInfo, setEnvInfo] = useState({
    envIconName: "",
    selectedEnvName: "",
    selectedEnvId: null,
    selectedEnvData: null,
    selectedEnvLocObj: {},
    selectedEnvSameAccountLoc: true,
    productTypeId: null,
    receiptStatus: 0,
    displayReceipt: "",
    isEnvironmentInfo: false,
    addEnvironment: false,
    // customEnv: "",
    addDevice: "",
  });
  const { myStaticEnv } = useSelector((state) => state.DashboardReducer);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const [customEnv, setCustomEnv] = useState("");
  const [staticEnvList, setStaticEnvList] = useState(false);
  const [customSelectedEnv, setCustomSelectedEnv] = useState({});
  const [myEnv, setMyEnv] = useState({});
  const [sentMyenv, setSentMyEnv] = useState(false);

  useEffect(() => {
    dispatch(dashboardActions.onGetAllEnvironment(userId));
    getStaticEnv();
    onGetMyenv();
  }, []);

  const getStaticEnv = async () => {
    const response = await dispatch(
      dashboardActions.onGetCustomEnvIcons(userId)
    );
    if (response && response?.data && response?.data?.success) {
      setStaticEnvList(response?.data?.data);
    }
  };
  const onGetMyenv = async () => {
    try {
      const env = await dispatch(dashboardActions.onGetMyEnvironment(userId));
      setMyEnv(env?.data?.data);
      // this.setState({ allEnv: env?.data?.data });
    } catch (error) {
      // Handle any errors that occur during the asynchronous operation
      console.error("Error:", error);
    }
  };

  const returnIcons = (enName, enIconName) => {
    if (enName === "Spa" || enIconName === "vector_04") {
      return <img src={vector_4} alt="" />;
    } else if (enName === "Motorcycles" || enIconName === "vector_05") {
      return <img src={vector_5} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_02") {
      return <img src={vector_2} alt="" />;
    } else if (enName === "Marine" || enIconName === "vector_03") {
      return <img src={vector_3} alt="" />;
    } else if (enName === "Powersports" || enIconName === "vector_06") {
      return <img src={vector_6} alt="" />;
    } else if (enName === "House" || enIconName === "vector_00") {
      return <img src={vector_0} alt="" />;
    } else if (enName === "Grill" || enIconName === "vector_01") {
      return <img src={vector_1} alt="" />;
    } else if (enName === "Star" || enIconName === "vector_07") {
      return <img src={vector_7} alt="" />;
    } else if (enName === "Marine" || enIconName === "vector_08") {
      return <img src={vector_3} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_09") {
      return <img src={vector_2} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_10") {
      return <img src={vector_5} alt="" />;
    } else if (enName === "Powersports" || enIconName === "vector_11") {
      return <img src={vector_6} alt="" />;
    }
  };

  const returnSVGIcons = (iconName) => {
    // if (iconName === "vector_00") {
    //   return <House />;
    // } else if (iconName === "vector_01") {
    //   return <Grill />;
    // } else if (iconName === "vector_02") {
    //   return <Lighting />;
    // } else if (iconName === "vector_03") {
    //   return <Marine />;
    // } else if (iconName === "vector_04") {
    //   return <Spa />;
    // } else if (iconName === "vector_05") {
    //   return <Motorcycles />;
    // } else if (iconName === "vector_06") {
    //   return <Powersports />;
    // } else if (iconName === "vector_07") {
    //   return <Star />;
    // } else if (iconName === "vector_08") {
    //   return <Marine />;
    // } else if (iconName === "vector_09") {
    //   return <Lighting />;
    // } else if (iconName === "vector_10") {
    //   return <Motorcycles />;
    // } else if (iconName === "vector_11") {
    //   return <Powersports />;
    // }
    // return <ReactSVG src={myIcon} />
    return (
      <img
        alt="mobicon"
        src={iconName}
        style={{ height: 80, width: 80, objectFit: "contain" }}
        className="mobEnvIcons"
      />
    );
  };
  const onCreateEv = () => {
    if (sentMyenv) {
      const { selectedEnvName, customEnv, envIconName, selectedEnvLocObj } =
        envInfo;
      if ((selectedEnvName === "" || customEnv === "") && envIconName === "") {
        toast.error("Please select environment and icon");
        return;
      }
      dispatch(dashboardActions.onGetAllProductType());
      setNextStep(sentMyenv);
    } else {
      const { selectedEnvName, envIconName, selectedEnvLocObj } = envInfo;

      if (customEnv && /^\s*$/.test(customEnv)) {
        setLoading(false);
        toast.error("Please enter environment name");
        return;
      }
      if ((selectedEnvName === "" || customEnv === "") && envIconName === "") {
        toast.error("Please select environment and icon");
        return;
      }
      onAddEnv();
    }
  };
  // const handleShowCustomEnvironment = () => {
  //   this.setState({
  //     addEnvironment: !this.state.addEnvironment,
  //   });
  // };

  const onAddEnv = () => {
    const userId = localStorage.getItem("userId");
    const {
      selectedEnvId,
      envIconName,
      // customEnv,
      selectedEnvData,
      selectedEnvLocObj,
      selectedEnvSameAccountLoc,
    } = envInfo;
    let req;
    if (selectedEnvId !== null) {
      setLoading(true);
      req = {
        environmentId: 0,
        environmentName:
          selectedEnvData.environmentName || selectedEnvData.name,
        environmentType:
          selectedEnvData.environmentType || selectedEnvData.type,
        environmentIcon:
          selectedEnvData.environmentIcon || selectedEnvData.icon,
        status: 1,
        userId: userId,
        location: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.fullAddress
          : "",
        latitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.lat || ""
          : "",
        longitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.long || ""
          : "",
        isSameAccountLocation: selectedEnvSameAccountLoc,
      };
      dispatch(dashboardActions.onAddDashboardEnv(req)).then((env) => {
        setLoading(false);
        // this.setState({ isLoading: false });
        if (env && env.data && env.data.success) {
          onGetEnvInfo(env.data.data, customEnv);
          // this.setState({
          //   selectedEnvId: env.data.data,
          //   selectedEnvName: customEnv,
          // });
          // this.props.actions.onChange("currentPaneId", "pane4");
          dispatch(dashboardActions.onGetAllProductType());
          onEnvCreate(env.data.success);
          // .then(data => {
          //   this.setState({
          //     isLoading: false,
          //   });
          // });
          // this.props.homeActions.getAllDealers();
        } else {
          // this.setState({ isLoading: false });
          toast.error(
            env && env.data && env.data.message
              ? env.data.message
              : "Something went wrong please try again"
          );
        }
      });
    } else {
      setLoading(true);
      if (customEnv === "") {
        setLoading(false);
        toast.error("Please enter enviornment name!");
        return;
      }

      req = {
        environmentId: 0,
        environmentName: customEnv,
        environmentType: "D",
        environmentIcon: envIconName,
        status: 1,
        userId: userId,
        location: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.fullAddress
          : "",
        latitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.lat || ""
          : "",
        longitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.long || ""
          : "",
        isSameAccountLocation: selectedEnvSameAccountLoc,
      };

      dispatch(dashboardActions.onAddDashboardEnv(req)).then((env) => {
        setLoading(false);
        // this.setState({ isLoading: false });
        if (env && env.data && env.data.success) {
          onGetEnvInfo(env.data.data, customEnv);
          // this.setState({
          //   selectedEnvId: env.data.data,
          //   selectedEnvName: customEnv,
          // });
          // this.props.actions.onChange("currentPaneId", "pane4");
          dispatch(dashboardActions.onGetAllProductType()).then((data) => {
            onEnvCreate(env.data.success);
            // this.setState({
            //   isLoading: false,
            // });
          });
          // this.props.homeActions.getAllDealers();
        } else {
          // this.setState({ isLoading: false });
          toast.error(
            env && env.data && env.data.message
              ? env.data.message
              : "Something went wrong please try again"
          );
        }
      });
    }
  };
  // const { productType, allProducts, allDealers, myStaticEnv } =
  //   this.props.homeState;
  return (
    <div className="userRegistration__tabs createEnvWrapper ">
      {noEnvHeading ? null : (
        <>
          <h2
            className="productRegisterHeading desktopHeading"
            style={{ marginBottom: 24, marginTop: 12 }}
          >
            Product Registration
          </h2>
          <h2
            className="productRegisterHeading mobileHeading"
            style={{ marginBottom: 24, marginTop: 12 }}
          >
            Environment
          </h2>
        </>
      )}
      <h4
        style={{
          fontWeight: 600,
          fontSize: 22,
          fontFamily: "Poppins",
          color: "#181C32",
        }}
        className="regSubtitile"
      >
        {title ? title : "Create an Aquatic AV Environment"}
      </h4>
      <p className="environmentDesc">
        Organize your Aquatic AV devices by installation environment. Select a
        preset environment or create a custom environment where your Aquatic AV
        product will be installed.
      </p>

      <div className="environmentListPopup wewew">
        {myEnv?.length > 0 && (
          <span
            className="addDeviceAdmin"
            style={{
              width: "100%",
              fontWeight: 600,
              fontFamily: "Poppins",
              fontSize: 18,
              marginLeft: "2%",
              color: "#181C32",
            }}
          >
            My Environment
          </span>
        )}
        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {myEnv &&
            myEnv.length > 0 &&
            myEnv.map((items, index) => {
              return (
                <li>
                  <div
                    onClick={() => {
                      onGetEnvInfo(items.id, items.name);
                      setEnvInfo({
                        selectedEnvName: items.name,
                        envIconName: items.icon,
                        selectedEnvId: items.id,
                        selectedEnvData: items,
                      });
                      // onEnvCreate(createmyenv)
                      setSentMyEnv(true);
                    }}
                    className={`mobilepresenv normalBox ${
                      envInfo.envIconName === items.icon &&
                      envInfo.selectedEnvName === items.name
                        ? "selectedBox"
                        : ""
                    }`}
                    style={{
                      flexDirection: "column",
                      marginLeft: index === 0 ? 0 : 20,
                      width: 130,
                    }}
                  >
                    <span className="">{returnSVGIcons(items?.icon)}</span>
                    <span className="iconText">{items?.name}</span>
                    {/* <Tooltip title={items?.name}>
                      <span className="iconText">
                        {items?.name.length > 12
                          ? `${items?.name.slice(0, 10)}...`
                          : items?.name}
                      </span>
                    </Tooltip> */}
                    <span className="toolTip">{items?.name}</span>
                  </div>
                </li>
              );
            })}
        </ul>
        <span
          className="addDeviceAdmin"
          style={{
            width: "100%",
            fontWeight: 600,
            fontFamily: "Poppins",
            fontSize: 18,
            marginLeft: "2%",
            color: "#181C32",
          }}
        >
          Preset Environment
        </span>
        <ul style={{ display: "flex" }} className="presetEnvMobile">
          {myStaticEnv &&
            myStaticEnv.length > 0 &&
            myStaticEnv.map((items, index) => {
              return (
                <li>
                  <div
                    onClick={() =>
                      setEnvInfo({
                        selectedEnvName: items.environmentName,
                        envIconName: items.environmentIcon,
                        selectedEnvId: items.environmentId,
                        customEnv: "",
                        selectedEnvData: items,
                      })
                    }
                    className={`mobilepresenv  normalBox ${
                      envInfo.envIconName === items.environmentIcon &&
                      envInfo.selectedEnvName === items.environmentName
                        ? "selectedBox"
                        : ""
                    }`}
                    style={{
                      flexDirection: "column",
                      marginLeft: index === 0 ? 0 : 20,
                      width: 130,
                    }}
                  >
                    <span className="">
                      {returnSVGIcons(items?.environmentIcon)}
                    </span>
                    <span className="iconText">{items?.environmentName}</span>
                    {/* <Tooltip title={items?.environmentName}>
                      <span className="iconText">
                        {items?.environmentName.length > 12
                          ? `${items?.environmentName.slice(0, 10)}...`
                          : items?.environmentName}
                      </span>
                    </Tooltip> */}
                    <span className="toolTip">{items?.environmentName}</span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <ul className="environmentBox">
        <span
          className="addDeviceAdmin"
          style={{
            width: "100%",
            fontWeight: "600",
            fontFamily: "Poppins",
            fontSize: "18px",
            marginLeft: "2%",
            color: "#181C32",
            marginTop: 0,
          }}
        >
          Custom Environment
        </span>
        {/* {this.state.addEnvironment && ( */}
        <span className="environmentAdminFieldWrapper">
          <input
            type="text"
            placeholder="Environment Name"
            value={customEnv}
            onChange={(e) => setCustomEnv(e.target.value)}
            style={{ marginLeft: "2%", color: "#828282" }}
            maxLength={30}
          />
          <div className="environmentListPopup  envIcons">
            <ul style={{ display: "flex", flexWrap: "wrap" }}>
              {staticEnvList &&
                staticEnvList.length > 0 &&
                staticEnvList.map((items, index) => {
                  return (
                    <li>
                      <div
                        onClick={() => {
                          setEnvInfo({
                            envIconName: items?.icon,
                            selectedEnvName: "",
                            selectedEnvId: null,
                          });
                          setCustomSelectedEnv(items);
                        }}
                        className={`normalBox ${
                          customSelectedEnv?.id === items?.id
                            ? "selectedBox"
                            : ""
                        }`}
                        style={{ flexDirection: "column", marginLeft: 0 }}
                      >
                        <span className="">{returnSVGIcons(items?.icon)}</span>
                        {/* <span className="iconText">{items.environmentName}</span> */}
                      </div>
                    </li>
                  );
                })}

              {/* <li>
                <span
                  onClick={() =>
                    setEnvInfo({
                      envIconName: "vector_01",
                      selectedEnvName: "",
                      selectedEnvId: null,
                    })
                  }
                  
                >
                  <Grill />
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    setEnvInfo({
                      envIconName: "vector_09",
                      selectedEnvName: "",
                      selectedEnvId: null,
                    })
                  }
                 
                >
                  <Lighting />
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    setEnvInfo({
                      envIconName: "vector_08",
                      selectedEnvName: "",
                      selectedEnvId: null,
                    })
                  }
                 
                >
                  <Marine />
                </span>
              </li>

              <li>
                <span
                  onClick={() =>
                    setEnvInfo({
                      envIconName: "vector_04",
                      selectedEnvName: "",
                      selectedEnvId: null,
                    })
                  }
                 
                >
                  <Spa />
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    setEnvInfo({
                      envIconName: "vector_10",
                      selectedEnvName: "",
                      selectedEnvId: null,
                    })
                  }
                
                >
                  <Motorcycles />
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    setEnvInfo({
                      envIconName: "vector_11",
                      selectedEnvName: "",
                      selectedEnvId: null,
                    })
                  }
                 
                >
                  <Powersports />
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    setEnvInfo({
                      envIconName: "vector_07",
                      selectedEnvName: "",
                      selectedEnvId: null,
                    })
                  }
                 
                >
                  <Star />
                </span>
              </li> */}
            </ul>
          </div>
        </span>
        {/* )} */}
      </ul>
      <span className="footerNav " style={{ paddingBottom: 30 }}>
        {loading ? <Loader /> : null}
        {/* <button
          id="pane1"
          className="prevNavButton"
          style={{ opacity: 0.5 }}
          // onClick={() =>
          //   this.props.actions.onChange("currentPaneId", "pane2")
          // }
        >
          PREVIOUS
        </button> */}
        <button className="nextNavButton" onClick={onCreateEv}>
          {title ? "NEXT" : "NEXT STEP"}
        </button>
      </span>

      <div className="bottomFooterWrap">
        <span className="copyrightText">© 2021 Aquatic AV Inc.</span>
        <span>
          <a
            href="https://www.aquaticav.com/pages/terms-of-use"
            target="_blank"
            className="footerLinks"
          >
            Terms
          </a>
        </span>
        <span>
          <a
            href="https://www.aquaticav.com/pages/terms-of-use"
            target="_blank"
            className="footerLinks"
          >
            Privacy
          </a>
        </span>
        <span>
          <a
            href="https://www.aquaticav.com/pages/contact"
            target="_blank"
            className="footerLinks"
          >
            Contact Us
          </a>
        </span>
      </div>
    </div>
  );
};
export { CreateEnvironment };
