import React, { useState } from "react";
import CustomSwitch from "./../Switch/CustomSwitch";
import Grid from "@material-ui/core/Grid";
import { PumpOne, PumpTwo, Blower } from "./SvgIcons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { useLocation } from "react-router-dom";
import { Loader } from "../index";
import { toast } from "react-toastify";
import "./spaPumpCard.css";
import mqttClient from "../../../config/MqttClient";

const pump1 = {
  High: "H",
  Low: "L",
  Off: "O",
};

const boolean = {
  False: false,
  True: true,
};

export default function SpaPumpCard({getOwnSpaid}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsloading] = useState(false);

  const spaDeviceId = location?.state?.SpaId;
  const spaOverviewReducer = useSelector((state) => state.SpaOverviewReducer);

  const handlePumpOneChange = (event, newVal) => {
    onTogglePump1();
    let pumpPayload = null;
    if (newVal === "O") {
      pumpPayload = {
        pump1: "Off",
        deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
        id: getOwnSpaid
          ? spaGlobalSettings.length > 0
            ? spaGlobalSettings.id
            : spaGlobalSettings.id
          : spaGlobalSettings.id,
      };
    } else if (newVal === "L") {
      pumpPayload = {
        pump1: "Low",
        deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
        id: getOwnSpaid
          ? spaGlobalSettings.length > 0
            ? spaGlobalSettings.id
            : spaGlobalSettings.id
          : spaGlobalSettings.id,
      };
    } else {
      pumpPayload = {
        pump1: "High",
        deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
        id: getOwnSpaid
          ? spaGlobalSettings.length > 0
            ? spaGlobalSettings.id
            : spaGlobalSettings.id
          : spaGlobalSettings.id,
      };
    }
    updatingGlobalSetting(pumpPayload);
  };

  const onChangePump2 = (value) => {
    onTogglePump2();
    const pump2Payload = {
      pump2: value ? "True" : "False",
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
      id: getOwnSpaid
        ? spaGlobalSettings.length > 0
          ? spaGlobalSettings.id
          : spaGlobalSettings.id
        : spaGlobalSettings.id,
    };
    updatingGlobalSetting(pump2Payload);
  };
  const onChangeBlower = (value) => {
    onToggleLight();
    const blowerPayload = {
      blower: value ? "True" : "False",
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
      id: getOwnSpaid
        ? spaGlobalSettings.length > 0
          ? spaGlobalSettings.id
          : spaGlobalSettings.id
        : spaGlobalSettings.id,
    };
    updatingGlobalSetting(blowerPayload);
  };

  const updatingGlobalSetting = (value) => {
    setIsloading(true);
    dispatch(SpaOverviewAction.onUpdataGlobalSetting(value)).then((res) => {
      if (res?.success) {
        console.log("toast:", res?.success);
        dispatch(SpaOverviewAction.onGetSpaInfo(getOwnSpaid ? getOwnSpaid : spaDeviceId)).then((data) => {
          setIsloading(false);
          if (data?.success === false) {
            toast.error(data?.message);
          } else {
            toast.success("Value updated successfully.");
          }
        });
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };

  const { spaGlobalSettings, spaTemperatureSettings } = useSelector(
    (state) => state.SpaOverviewReducer
  );

  const onTogglePump1 = () => {
    mqttClient.publish(
      `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/devicebound`,
      "PUMP1_BTN"
    );
    // LIGHT_BTN
    // PUMP2_BTN
    // PUMP1_BTN
  };

  const onTogglePump2 = () => {
    mqttClient.publish(
      `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/devicebound`,
      "PUMP2_BTN"
    );
    // LIGHT_BTN
    // PUMP2_BTN
    // PUMP1_BTN
  };

  const onToggleLight = () => {
    mqttClient.publish(
      `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/devicebound`,
      "LIGHT_BTN"
    );
    // LIGHT_BTN
    // PUMP2_BTN
    // PUMP1_BTN
  };

  return (
    <Grid item md={12} className="gridMainSpaWrapper px-3">
      {isLoading ? <Loader /> : null}
      <Grid
        item
        md={12}
        className="gridSpaPumpsWrapper"
        style={{ minHeight: 270 }}
      >
        <Grid item md={12} className="gridSpaPumpsWrapperBody">
          <Grid item md={12} className="gridRow">
            <Grid item md={12} className="gridSpaPumpsControlItem pumpRow">
              <span
                onClick={onTogglePump1}
                className="iconContainer textInfo pumpinfo pumpText"
              >
                Pump 1{" "}
                <span>
                  <PumpOne />
                </span>
              </span>

              <ToggleButtonGroup
                // value={pump1[spaGlobalSettings.pump1]}
                exclusive
                onChange={
                  spaTemperatureSettings?.isTemperatureLock === false &&
                  handlePumpOneChange
                }
              >
                <ToggleButton
                  variant="outlined"
                  value="O"
                  selected={spaGlobalSettings?.pump1 === "Off" ? true : false}
                  className="tempratureButtonControlGroup"
                >
                  O
                </ToggleButton>
                <ToggleButton
                  variant="outlined"
                  value="L"
                  selected={spaGlobalSettings?.pump1 === "Low" ? true : false}
                  className="tempratureButtonControlGroup"
                >
                  L
                </ToggleButton>
                <ToggleButton
                  variant="outlined"
                  value="H"
                  selected={spaGlobalSettings?.pump1 === "High" ? true : false}
                  className="tempratureButtonControlGroup"
                >
                  H
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item md={12} className="gridSpaPumpsControlItem pumpRow">
              <span className="iconContainer  textInfo pumpinfo pumpText">
                Pump 2{" "}
                <span>
                  <PumpTwo />
                </span>
              </span>

              <CustomSwitch
                onClick={(e) => {
                  spaTemperatureSettings?.isTemperatureLock === false &&
                    onChangePump2(e.target.checked);
                }}
                checked={boolean[spaGlobalSettings.pump2]}
              />
            </Grid>
            <Grid item md={12} className="gridSpaPumpsControlItem pumpRow">
              <span className="iconContainer textInfo pumpinfo pumpText">
                Blower{" "}
                <span>
                  <Blower />
                </span>
              </span>

              <CustomSwitch
                onClick={(e) => {
                  spaTemperatureSettings?.isTemperatureLock===false &&
                    onChangeBlower(e.target.checked);
                }}
                checked={boolean[spaGlobalSettings.blower]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
