import instance from "axios";

let cancelToken = instance.CancelToken;
let source = cancelToken.source();
const axiosInstance = instance.create({
  cancelToken: source.token,
});

axiosInstance.interceptors.request.use((config) => {
  let token = {
    access_token: localStorage.getItem("access_token"),
  };
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  var defaultHeaders = {
    "Content-Type": "application/json",
    timezone: timeZone,
    "User-Platform": "Account-Web",
  };

  if (token.access_token) {
    config.headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${token.access_token}`,
    };
  } else {
    config.headers = defaultHeaders;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    let newtoken = response.headers["newtoken"];

    if (newtoken) {
      localStorage.setItem("access_token", newtoken);
      let currentDateObj = new Date();
      let numberOfMlSeconds = currentDateObj.getTime();
      let addMlSeconds = 16 * 60 * 1000;
      let newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

      localStorage.setItem(
        "access_token_expiry",
        newDateObj.getTime() //response.data.data.accessToken.expiry
      );
    }
    return response;
  },
  (error) => {
    const { status } = error.response;
    if (status === 401 || status === 500) {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("password");
      localStorage.removeItem("passwordDec");
      localStorage.removeItem("currentEnv");
      localStorage.removeItem("access_token");
      localStorage.removeItem("access_token_expiry");
      localStorage.removeItem("userId");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("forgotToken");
      localStorage.removeItem("isAuthenticated");
      window.location = "/";
    }
    if (status === 403) {
      return {
        status: 200,
        data: { success: false, message: "You can not perform this action." },
      };
    }
  }
);

export const axios = axiosInstance;
