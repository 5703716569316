import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { filterlife1, alertBulb } from "../../../resources/images";
import { useSelector } from "react-redux";

export default function FilterLifeCard({ filtermanage }) {
  const { filterLifeStats } = useSelector((state) => state.SpaOverviewReducer);
  return (
    <div onClick={filtermanage} style={{ cursor: "pointer" }}>
      <Grid
        item
        md={12}
        className="gridMainSpaWrapper "
        style={{ marginTop: "1rem" }}
      >
        <Grid item md={12} style={{ background: "#fff", borderRadius: "12px" }}>
          {/* <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperHeader"
          style={{ padding: "0" }}
        >
          <Grid item md={12} className="gridRow">
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading px-3 filterLifeHeader"
            >
              <span item md={6} className="heading headingFilter">
                Filter Life
              </span>
              <span
                item
                md={6}
                className="settingChange"
                onClick={filtermanage}
              >
                MANAGE
              </span>
            </Grid>
          </Grid>
        </Grid> */}
          <Grid
            item
            md={12}
            className="gridSpaPumpsWrapperBody"
            style={{ padding: "20px " }}
          >
            <Grid
              item
              md={12}
              className="gridRow"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                md={12}
                className="gridSpaPumpsControlItem watertreatmentLeftWrapper"

                // style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid item md={7} xs={12}>
                  <div className="filterLifeInnerWrapper detailInnerFilter">
                    <span>
                      <img
                        src={filterlife1}
                        alt=""
                        style={{
                          filter: "grayscale(0)",
                          // backgroundColor: "#1BBED0",
                        }}
                      />
                    </span>

                    <span>
                      <div className="bigTitle">
                        {filterLifeStats?.replacedDays}{" "}
                        {filterLifeStats?.replacedDays > 1 ? "Days" : "Day"}
                      </div>
                      <div className="filterLifeSubHeading">
                        since filter has been replaced.
                      </div>
                      <div className="filterLifeCardText">
                        Recommend Replacement:
                      </div>
                      <div className="filterLifeCardDate">
                        <span style={{ fontStyle: "italic" }}>
                          {Math.abs(
                            filterLifeStats?.replacementRecommendedDays
                          ) !== 1
                            ? `${filterLifeStats?.replacementRecommendedDays} days away`
                            : `${filterLifeStats?.replacementRecommendedDays} day away`}
                        </span>{" "}
                        |{" "}
                        {filterLifeStats?.recommendedReplacingDate
                          ? filterLifeStats?.recommendedReplacingDate
                          : "September 1, 2021"}
                      </div>

                      <div
                        style={{ marginTop: 15 }}
                        className="filterLifeCardText"
                      >
                        Last Cleaned:
                      </div>
                      <span
                        style={{
                          color: "#8D99A5",
                          fontSize: 15,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        {filterLifeStats?.lastCleaned}
                      </span>
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  md={5}
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    className="lifeTip"
                    style={{ width: "80%", height: 150 }}
                  >
                    <span className="tipHeading">
                      Chemical Testing Tip <img src={alertBulb} alt="" />
                    </span>
                    <p className="chemicalTextNote">
                      Extend water life by keeping your chemicals in the green
                      and refilling your water every four months.
                    </p>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
