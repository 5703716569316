import React from "react";

function AddDeviceBox({ text, picture, deviceClick }) {
  return (
    <div className="deviceButtonWrapper" onClick={deviceClick}>
      <span>{text}</span>
      <span>
        <img src={picture} alt="" />
      </span>
    </div>
  );
}

export default AddDeviceBox;
