import moment from "moment";
import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { BsArrowLeft, BsThreeDots } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { RiAddFill, RiCheckFill } from "react-icons/ri";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import Autocomplete from "react-google-autocomplete";
import BlueSpeaker from "../../resources/images/bluespeakersm.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { accountActions } from "../../store/accounts/accountActions";
import {
  AppBtn,
  AppModal,
  FloatingLabelInput,
  FloatingLabelSelect,
  Header,
  Loader,
} from "../../components/common/index";
import {
  vector_0,
  vector_1,
  vector_2,
  vector_3,
  vector_4,
  vector_5,
  vector_6,
  vector_7,
} from "../../resources/images/index";
import { RoutesObj } from "../../routes";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { deviceOverviewAction } from "../../store/deviceOverview/deviceOverviewAction";
import { DeviceBox } from "../index";
import AddNewEnvironment from "./AddNewDevice/AddNewEnvironment";
import SelectEnvironment from "./AddNewDevice/SelectEnvironment";
import TransferDevice from "./AddNewDevice/TransferDevice";
import TabNav from "./TabNav";
import "./tabs.css";
import AccordionArrow from "../../resources/images/accordianarrow.png";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Cross from "../../resources/images/CrossBox.png";
import MessageEnv from "../../resources/images/messageenv.png";
import CodeBox from "../../resources/images/codebox.png";
import EditInfo from "../../resources/images/removecolor.png";
import Tick from "../../resources/images/removepin.png";
import { arrowDown } from "../../assets/images/images";
import PhoneInput from "../../components/common/PhoneNumber";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  background: "white",
  borderRadius: "20px",
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  padding: "30px",
  outline: "none",
};
const Pane = (props) => (
  <div
    className={"tab-pane" + (props.activepane ? " active" : "")}
    id={props.id}
  >
    {props.children}
  </div>
);

const cachePass = localStorage.getItem("passwordDec");
const cacheEmail = localStorage.getItem("userEmail");
const userObj = JSON.parse(localStorage.getItem("userInfo"));

let myUserInfo;

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewModelShow: false,
      isTranferModelShow: false,
      isEnvironmentPopup: false,
      isLoading: true,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      passwordsMatch: false,
      address: "",
      city: "",
      stateCode: "",
      postalCode: "",
      country: "",
      phoneNumber: "",
      countryCode: 1,
      isDisabled: true,
      emailError: false,
      personalError: false,
      contactError: false,
      recepit: "",
      myDevices: true,
      prodRegistration: false,
      deviceInformation: "",
      isReplaceImage: false,
      activepane: "pane1",
      breadCrumTxt: "Personal Information - My Account",
      isDeviceDropdown: false,
      isEnvEdit: false,
      editEnvId: "",
      editEnvValue: "",
      firstNameError: false,
      lastNameError: false,
      cityError: false,
      stateError: false,
      postalError: false,
      countryError: false,
      phoneError: false,
      deviceDetail: [],
      isCityEditable: false,
      isAddressEdited: false,
      isCountryEditable: false,
      isStateEditable: false,
      isPostalCodeEditable: false,
      isManuallyEnter: false,
      cityOptions: [],
      statesOptions: [],
      countryOptions: [],
      userData: {},
      activeTab: "Personal Information",
      expandedAccordions: [],
      openEmailVerfModal: false,
      isSendEmail: false,
      openPhoneVerfModal: false,
      isCodeSend: false,
      isEmailVerified: "",
      isShowContactBtn: false,
      showSubMenu: false,
      phoneNumberError: false,
      countryCodeError: false,
      type1: {
        type: 1,
        id: 0,
        web: false,
        mobileApp: false,
        email: false,
        sms: false,
        isAllPlatform: false,
      },
      type2: {
        type: 2,
        id: 0,
        web: false,
        mobileApp: false,
        email: false,
        sms: false,
        isAllPlatform: false,
      },
      type3: {
        type: 3,
        id: 0,
        web: false,
        mobileApp: false,
        email: false,
        sms: false,
        isAllPlatform: false,
      },
      type5: {
        type: 5,
        id: 0,
        web: false,
        mobileApp: false,
        email: false,
        sms: false,
        isAllPlatform: false,
      },
      type6: {
        type: 6,
        id: 0,
        web: false,
        mobileApp: false,
        email: false,
        sms: false,
        isAllPlatform: false,
      },
    };
    this.returnIcons = this.returnIcons.bind(this);
    // this.wrapperRef = React.createRef();
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  clickTab(tabId) {
    if (tabId === "pane1") {
      this.setState({
        breadCrumTxt: "Personal Information - My Account",
      });
    } else if (tabId === "pane2") {
      this.setState({
        breadCrumTxt: "Contact Information - My Account",
      });
    } else if (tabId === "pane3") {
      this.setState({ breadCrumTxt: "My Environments" });
    } else {
      this.setState({ breadCrumTxt: "My Devices" });
    }
    this.setState({ activepane: tabId });
  }
  async componentDidMount() {
    myUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userData();
    await this.onLoadCompleteData(false, "");
    this.onGetDevices();
    await this.props.actions.onGetAllCities().then((data) => {
      if (data.status === 200 && data.data.success) {
        let cityOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          cityOptions: cityOptions,
        });
      }
    });
    await this.props.actions.onGetAllStates().then((data) => {
      if (data.status === 200 && data.data.success) {
        let statesOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          statesOptions: statesOptions,
        });
      }
    });
    await this.props.actions.onGetAllCountries().then((data) => {
      if (data.status === 200 && data.data.success) {
        let countryOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          countryOptions: countryOptions,
        });
      }
    });
    this.setState({ activepane: "pane1" });
    // document.addEventListener("mousedown", this.handleClickOutside);
  }
  checkPasswordMatch = () => {
    const { password, confirmPassword } = this.state;
    if (password === confirmPassword && password !== "") {
      this.setState({ passwordsMatch: true });
    } else {
      this.setState({ passwordsMatch: false });
    }
  };
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClickOutside);
  // }

  // handleClickOutside(event) {
  //   if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
  //     this.setState({
  //       isDeviceDropdown: false,
  //     });
  //   }
  // }
  onGetDevices() {
    // this.setState({ isLoading: true });
    if (localStorage.getItem("currentEnv") !== "null") {
      const userId = localStorage.getItem("userId");
      this.props.actions.onGetUserDevices(userId).then((data) => {
        this.setState({
          deviceDetail: data?.data?.data,
        });

        // if (data?.data?.data?.length) {
        //   data.data.data.forEach((value, index) => {
        //     if (value.productType.includes("SPA")) {
        //       this.getSpaOverviewData(value.id);
        //     }
        //   });
        // }
      });
      // this.props.actions.onGetTranferDevices(userId).then((deviceData) => {});
    }
    // this.setState({ isLoading: false });
  }

  userData = () => {
    const userId = localStorage.getItem("userId");

    this.props.actions
      .onUserGetData(userId)
      .then((data) => this.setState({ isEmailVerified: data.data.data }));
  };
  onLoadCompleteData = (showMsg, msg) => {
    this.setState({ isLoading: true });
    const { history } = this.props;
    // const userEmail = localStorage.getItem("userEmail");
    // const userPassword = localStorage.getItem("password");
    const passwordDec = localStorage.getItem("passwordDec");
    const userId = localStorage.getItem("userId");
    this.props.actions.onUserGetData(userId).then((data) => {
      if (data.status === 200 && data.data.success) {
        localStorage.setItem("userInfo", JSON.stringify(data.data.data));
        myUserInfo = data.data.data;
        const userData = data.data.data;
        this.setState({
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          password: passwordDec,
          confirmPassword: passwordDec,
          address: userData.address,
          city: userData.cityName,
          stateCode: userData.stateName,
          postalCode: userData.zipCode,
          country: userData.countryName,
          countryCode: userData.countryCode ?? 1,
          phoneNumber: userData.phoneNumber,
          userData: userData,
        });
        this.props.actions.onGetUserDevices(userId).then((response) => {
          if (response.status === 200 && response.data.success) {
            this.props.actions.onLoadData(response.data.data, userData);
            this.props.actions
              .onGetMyEnvironment(
                userId,
                JSON.parse(localStorage.getItem("userInfo")).customerType === 3
                  ? JSON.parse(localStorage.getItem("userInfo")).companyId
                  : false
              )
              .then((env) => {
                this.setState({ isLoading: false });
                if (env.status === 200 && env.data.success) {
                  if (showMsg) {
                    toast.success(msg);
                    this.onGetDevices();
                  }
                } else {
                  toast.error("Something went wrong, Please login again");
                }
              });
          } else {
            this.setState({ isLoading: false });
          }
        });
      } else {
        this.setState({ isLoading: false });
        localStorage.removeItem("userEmail");
        localStorage.removeItem("password");
        localStorage.removeItem("passwordDec");
        toast.error("Something went wrong, Please login again");
        history.push(RoutesObj.Root.path);
      }
    });
    // this.setState({ isLoading: false });
  };

  handleClose = () => {
    this.setState({
      isEnvironmentPopup: false,
      recepit: "",
      isPurchase: false,
    });
  };
  handleShowEnvironment = () => {
    this.setState({
      isEnvironmentPopup: true,
    });
  };
  onCheckPersonalInfo = () => {
    if (this.state.firstName === "") {
      this.setState({ firstNameError: true });
    }
    if (this.state.lastName === "") {
      this.setState({ lastNameError: true });
    }
    if (this.state.password === "") {
      this.setState({ personalError: true });
    }
    if (this.state.email === "") {
      this.setState({ emailError: true });
    }
  };

  onCheckContact = () => {
    const { address, city, stateCode, postalCode, country, phoneNumber } =
      this.state;
    if (address === "") {
      this.setState({ contactError: true });
    }
    if (city === "") {
      this.setState({ cityError: true });
    }
    if (stateCode === "") {
      this.setState({ stateError: true });
    }
    if (postalCode === "") {
      this.setState({ postalError: true });
    }
    if (country === "") {
      this.setState({ countryError: true });
    }
    if (phoneNumber === "" || phoneNumber.length < 7) {
      this.setState({ phoneError: true });
    }
  };
  onUpdatePersonalInfo = () => {
    const { firstName, lastName, email, password, userData, confirmPassword } =
      this.state;
    if (password !== confirmPassword) {
      toast.error("Password did not match");
      // Passwords do not match, handle this case (display error, feedback, etc.)
      return;
    }
    this.setState({ isLoading: true });
    let reqObj = {
      ...userData,
      firstName: firstName,
      lastName: lastName,
      email: email,
      confirmEmail: email,
      password: password || cachePass,
      confirmPassword: password || cachePass,
    };

    this.props.actions.onUpdatePersonal(reqObj).then((data) => {
      if (data.status === 200 && data.data.success) {
        this.setState({
          isLoading: false,
          isDisabled: true,
        });
        this.onLoadCompleteData(true, "Personal Information has been updated");
      } else {
        toast.error("Something went wrong, Please try again");
      }
    });
    this.setState({ isLoading: false });
  };

  onUpdateAddressInfo = () => {
    const {
      email,
      password,
      address,
      city,
      stateCode,
      postalCode,
      countryCode,
      country,
      phoneNumber,
      userData,
    } = this.state;
    this.setState({ isLoading: true });

    let reqObj = {
      ...userData,
      address: address,
      cityName: city,
      stateName: stateCode,
      countryCode: countryCode,
      zipCode: postalCode,
      countryName: country,
      phoneNumber: phoneNumber,
      email: email || cacheEmail,
      confirmEmail: email || cacheEmail,
      password: password || cachePass,
      confirmPassword: password || cachePass,
    };
    this.props.actions.onUpdatePersonal(reqObj).then((data) => {
      if (data.status === 200 && data.data.success) {
        this.setState({ isDisabled: true });
        this.onLoadCompleteData(true, "Contact Information has been updated");
      } else {
        toast.error("Something went wrong, Please try again");
      }
      this.setState({ isLoading: false });
    });
  };
  onFocusOutFun = (value) => {
    if (
      value !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      this.setState({ emailError: true });
    }
  };

  returnIcons = (enName, enIconName) => {
    if (
      enName === "Spa" ||
      enIconName === "vector_4" ||
      enIconName === "vector_04"
    ) {
      return <img src={vector_4} alt="" />;
    } else if (
      enName === "Motorcycles" ||
      enIconName === "vector_5" ||
      enIconName === "vector_05"
    ) {
      return <img src={vector_5} alt="" />;
    } else if (
      enName === "Lighting" ||
      enIconName === "vector_2" ||
      enIconName === "vector_02"
    ) {
      return <img src={vector_2} alt="" />;
    } else if (
      enName === "Marine" ||
      enIconName === "vector_3" ||
      enIconName === "vector_03"
    ) {
      return <img src={vector_3} alt="" />;
    } else if (
      enName === "Powersports" ||
      enIconName === "vector_6" ||
      enIconName === "vector_06"
    ) {
      return <img src={vector_6} alt="" />;
    } else if (enName === "House" || enIconName === "vector_0") {
      return <img src={vector_0} alt="" />;
    } else if (
      enName === "Grill" ||
      enIconName === "vector_1" ||
      enIconName === "vector_01"
    ) {
      return <img src={vector_1} alt="" />;
    } else if (
      enName === "Star" ||
      enIconName === "vector_7" ||
      enIconName === "vector_07"
    ) {
      return <img src={vector_7} alt="" />;
    }
  };

  handleReplaceImage = (e) => {
    const { deviceInformation } = this.state;
    if (e.target.files.length) {
      this.setState({ isLoading: true });
      this.props.actions.onUploadImage(e.target.files[0]).then((img) => {
        if (img && img.data && img.data.success) {
          this.props.actions
            .onReplaceImage(deviceInformation.id, img.data.data.url)
            .then((res) => {
              this.setState({ isLoading: false });
              if (res && res.data && res.data.success) {
                this.onLoadCompleteData(
                  true,
                  "Receipt is uploaded successfully"
                );
                this.toBase64(e.target.files[0]).then((data) => {
                  const result = data.split("base64,")[1];
                  this.setState({
                    recepit: result,
                    isReplaceImage: true,
                  });
                });
              } else {
                if (res && res.data && res.data.responseCode === 0) {
                  toast.error(res.data.message);
                } else {
                  toast.error("Something went wrong, Please try again");
                }
              }
            });
        } else {
          this.setState({ isLoading: false });
          if (img && img.data && img.data.responseCode === 0) {
            toast.error(img.data.message);
          } else {
            toast.error("Something went wrong, Please try again");
          }
        }
      });
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  showRegistration(item) {
    this.setState({
      prodRegistration: true,
      myDevices: false,
      deviceInformation: item,
      isReplaceImage: false,
      receipt: "",
    });
  }

  showDevices() {
    this.setState({
      prodRegistration: false,
      myDevices: true,
      deviceInformation: "",
      isReplaceImage: false,
      receipt: "",
    });
  }

  updateDeviceName(updateHeading, device) {
    if (updateHeading !== device.name) {
      this.setState({ isLoading: true });
      this.props.actions
        .onUpdateDevName(updateHeading, device.id)
        .then((data) => {
          if (data?.status === 200 && data?.data?.success) {
            this.onLoadCompleteData(
              true,
              "Device name has been updated successfully"
            );
          } else {
            this.setState({ isLoading: false });
            toast.error("Something went wrong, Please try again");
          }
        });
    }
  }

  onChangeEnv(env, device) {
    if (env.name !== device.environment || env.id !== device.environmentId) {
      this.setState({ isLoading: true });
      let req = {
        serialNo: device.serialNo,
        purchaseDate: device.purchaseDate,
        dealerLocationId: device.dealerId,
        receiptStatus: device.receiptStatusCode,
        deviceId: device.id,
        envId: env.id,
        name: device.name,
        installationDate: "",
      };
      this.props.actions.onUpdateDeviceInfo(req).then((data) => {
        if (data && data.data && data.data.success) {
          this.onLoadCompleteData(
            true,
            "Device envoirnment has been updated successfully"
          );
        } else {
          this.setState({ isLoading: false });
          toast.error("Something went wrong, Please try again");
        }
      });
    }
  }

  closeAddDevice() {
    this.setState({ isNewModelShow: false });
  }

  closeTranferDevice() {
    this.setState({ isTranferModelShow: false });
  }

  onUpdateEnvName(item) {
    let currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
    this.setState({ isLoading: true });
    this.props.actions
      .onChangeEnvName(item, this.state.editEnvValue)
      .then((data) => {
        if (data && data.data && data.data.success) {
          if (currentEnv.id === item.id) {
            const aa = { ...item };
            aa.name = this.state.editEnvValue;
            localStorage.setItem("currentEnv", JSON.stringify(aa));
          }
          this.setState({
            isEnvEdit: false,
            editEnvId: "",
            editEnvValue: "",
          });
          this.onLoadCompleteData(true, "Environment name has been updated");
        } else {
          this.setState({ isLoading: false });
          toast.error(data.data.message);
        }
      });
  }
  onFirstNameValidation = (value) => {
    if (value !== "" && !/^[a-zA-Z\s]*$/i.test(value)) {
      this.setState({ firstNameError: true });
    } else {
      this.setState({
        firstName: value,
        firstNameError: false,
      });
    }
  };

  onLastNameValidation = (value) => {
    if (value !== "" && !/^[a-zA-Z\s]*$/i.test(value)) {
      this.setState({ lastNameError: true });
    } else {
      this.setState({
        lastName: value,
        lastNameError: false,
      });
    }
  };

  onCityValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ cityError: true });
    } else {
      this.setState({ city: value, cityError: false });
    }
  };

  onStateValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ stateError: true });
    } else {
      this.setState({
        stateCode: value,
        stateError: false,
      });
    }
  };

  onCountryValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ countryError: true });
    } else {
      this.setState({
        country: value,
        countryError: false,
      });
    }
  };
  onPostalValidation = (value) => {
    if (value !== "" && !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/i.test(value)) {
      this.setState({ postalError: true });
    } else {
      this.setState({
        postalCode: value.trim(),
        postalError: false,
      });
    }
  };

  onPhoneNumberValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ phoneError: true });
    } else {
      this.setState({ phoneNumber: value.trim() });
    }
  };

  onRegisteredButtonClick(item, selectedTab) {
    const { history } = this.props;
    this.props.deviceOverviewAction.onChange("fav_colors", []);
    this.props.deviceOverviewAction.onChange("addColors", []);
    this.props.deviceOverviewAction.onChange("deviceOverviewData", item);
    item?.fav_colors?.forEach((element) => {
      if (element && element !== "") {
        this.props.deviceOverviewAction.onSetSelectedColor(element);
      }
    });
    history.push({
      pathname: RoutesObj.DeviceOverview.path,
      state: { showTab: selectedTab },
    });
  }
  onSelectDeviceOwnership(item) {
    this.props.deviceOverviewAction.onChange("fav_colors", []);
    this.props.deviceOverviewAction.onChange("addColors", []);
    this.props.deviceOverviewAction.onChange("deviceOverviewData", item);
    item?.fav_colors?.forEach((element) => {
      if (element && element !== "") {
        this.props.deviceOverviewAction.onSetSelectedColor(element);
      }
    });
    this.setState({
      isTranferModelShow: true,
    });
  }
  onPlaceSelected = (place) => {
    this.setState({
      stateCode: "",
      country: "",
      city: "",
      postalCode: "",
    });

    this.setState({ address: place.formatted_address });
    const address = place.address_components;
    let state, country, city, postalCode, addresss;

    address.forEach((component) => {
      if (component.types.includes("route")) {
        addresss = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
      if (component.types.includes("administrative_area_level_2")) {
        city = component.long_name;
      }
      if (component.types.includes("country")) {
        country = component.long_name;
      }
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    this.setState({
      address: addresss,
      stateCode: state,
      country: country,
      city: city,
      postalCode: postalCode ? postalCode : "",
      // isCityEditable: false,
      // isCountryEditable: false,
      // isStateEditable: false,
      // isPostalCodeEditable:false,
      // isManuallyEnter: false,
    });

    // enable city editing if the user starts editing the address manually
    // this.setState({ isCityEditable: false, isCountryEditable: false, isStateEditable: false });
    // const addressInput = document.getElementById("popupEnvLocInput");
    // addressInput.addEventListener("input", () => {
    //   this.setState({ isCityEditable: true, isCountryEditable: true, isStateEditable: true });
    //   addressInput.removeEventListener("input", () => { });
    // });
  };
  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };
  handleAccordianChange = (index) => {
    const { expandedAccordions } = this.state;
    const newExpanded = [...expandedAccordions];
    if (newExpanded.includes(index)) {
      // Collapse the accordion if it's already expanded
      newExpanded.splice(newExpanded.indexOf(index), 1);
    } else {
      // Expand the accordion if it's currently collapsed
      newExpanded.push(index);
    }
    this.setState({ expandedAccordions: newExpanded });
  };

  handleEmailVerification = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this?.props?.accountActions
      ?.onUserEmailVerification(userInfo.id)
      .then((res) => {
        if (res?.data?.success) {
          toast.success("Email sent successfully");
        } else {
          toast.error("This E-mail address is not valid");
        }
      })
      .catch((err) => {
        toast.error(err);
        console.log("error in verification:", err);
      });
  };
  handlePhoneVerification = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this?.props?.accountActions
      ?.onUserSMSVerification(userInfo.id)
      .then((res) => {
        if (res?.data?.success) {
          toast.success("Message has been sent for verification!");
        } else {
          toast.error("This phone number is not valid");
        }
      })
      .catch((err) => {
        toast.error(err);
        console.log("error in verification:", err);
      });
  };

  onSubmitNotification = () => {
    const { type1, type2, type3, type5, type6 } = this.state;

    this.setState({ isLoading: true });

    this?.props?.accountActions
      .onNotificationSubmission([type1, type2, type3, type5, type6])
      .then((data) => {
        this.setState({ isLoading: false });
        if (data && data.success == false) {
          toast.error("Something went wrong");
        } else {
          toast.success("Notifications updated successfully");
        }
      })
      .catch((err) => {
        console.log("caught error:", err);
      });
  };
  toggleSubNav = () => {
    this.setState((prevState) => ({ showSubMenu: !prevState.showSubMenu }));
  };
  removeSubNav = () => {
    this.setState({ showSubMenu: false });
  };
  handlePhoneChange = (e) => {
    const { value } = e.target;
    const purePhoneNumber = value.replace(/\D/g, "");
    if (purePhoneNumber.length < 7 || purePhoneNumber.length > 12) {
      this.setState({ phoneNumberError: true, phoneError: true });
    } else {
      this.setState({ phoneNumberError: false, phoneError: false });
    }
    this.setState({
      phoneNumber: purePhoneNumber,
    });
  };
  handleCountryCodeChange = (value, countryCode) => {
    this.setState({
      countryCode: countryCode.dialCode,
    });
    if (!countryCode) {
      this.setState({ countryCodeError: true });
    }
  };

  render() {
    const {
      isLoading,
      firstName,
      lastName,
      customerTypeName,
      email,
      password,
      confirmPassword,
      address,
      city,
      stateCode,
      postalCode,
      country,
      phoneNumber,
      countryCode,
      phoneNumberError,
      countryCodeError,
      isDisabled,
      emailError,
      personalError,
      contactError,
      // recepit,
      deviceInformation,
      isReplaceImage,
      breadCrumTxt,
      isEnvEdit,
      editEnvId,
      editEnvValue,
      firstNameError,
      lastNameError,
      cityError,
      stateError,
      postalError,
      countryError,
      phoneError,
      cityOptions,
      statesOptions,
      countryOptions,
    } = this.state;
    const { userInfo, myDeviceBox, userEnv, allDealers } = this.props.state;
    const { expandedAccordions } = this.state;
    const { history } = this.props;
    let userNameS = "";
    if (userInfo) {
      userNameS = `${userInfo.firstName}${`'s `}`;
    }
    let className;
    switch (deviceInformation?.receiptStatus) {
      case "Registered":
        className = "regDevice";
        break;
      case "Receipt Missing":
        className = "ReceiptMissing";
        break;
      case "Registration Pending":
        className = "deviceStatus";
        break;
      case "Rejected":
        className = "deviceStatusRejected";
        break;
      default:
        className = "default";
    }

    return (
      <div className="Dashboardmain-container">
        {isLoading ? <Loader /> : null}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userInfo ? userInfo : null}
            isDashboardShow={true}
            history={history}
            userEnvoirnment={userEnv}
            toggleSubNav={this.toggleSubNav}
            removeSubNav={this.removeSubNav}
          />
          {/* <div className="mainTitle">
            <span>
              My Account
              <span className="mainTitle__breadCrum">
                <i></i> Account Settings <i></i>
                {breadCrumTxt}
              </span>
            </span>
          </div> */}
        </div>

        {/* <div className="userNmae">
          <span>
            {console.log("user info:", userInfo)}
            {userInfo
              ? `${
                  userInfo?.firstName !== ""
                    ? userInfo?.firstName?.charAt(0).toUpperCase()
                    : ""
                }${
                  userInfo.lastName !== ""
                    ? userInfo?.lastName?.charAt(0).toUpperCase()
                    : ""
                }`
              : "AA"}
          </span>
          <h1 style={{ color: "#202A4C", fontWeight: "700" }}>
            {userInfo
              ? `${userInfo?.firstName}${" "}${userInfo?.lastName}`
              : ""}
          </h1>
        </div> */}
        <div className="dashboardMainSection" style={{ marginTop: "6%" }}>
          <h1 className="envrionmentsMainHeading">My Account</h1>
          <h1 className="breadcrumbs">
            Home / <span style={{ color: "#3F4254" }}> Account Settings</span>
          </h1>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
            }}
            className="myaccountMainContainer"
          >
            <div
              style={{ marginRight: "20px" }}
              className="mobileAccountContainer"
            >
              <div className="DeviceDetailEnv">
                <div style={{ display: "flex" }}>
                  <div className="deviceimgEnv">
                    <img src={BlueSpeaker} alt="bluespecker"></img>
                  </div>
                  <div className="deviceDetailHeading">
                    <h1>
                      {userInfo.firstName} {userInfo.lastName}
                    </h1>
                    <h2>{userInfo.customerTypeName}</h2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px dashed #E4E6EF",
                    padding: "10px 0",
                  }}
                >
                  <h1 className="deviceDetailPoints">Devices</h1>
                  <h1 className="devicePin">{myUserInfo?.devicesCount}</h1>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px dashed #E4E6EF",
                    padding: "10px 0",
                  }}
                >
                  <h1 className="deviceDetailPoints">Environments</h1>
                  <h1 className="devicePin">{myUserInfo?.environmentCount}</h1>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // borderBottom: "1px dashed #E4E6EF",
                    padding: "10px 0",
                  }}
                >
                  <h1 className="deviceDetailPoints">Last Login</h1>
                  <h1 className="devicePin">
                    {myUserInfo?.lastLoginDate
                      ? moment(myUserInfo.lastLoginDate).format("MM/DD/YY")
                      : "--"}
                  </h1>
                </div>
              </div>
              {myUserInfo?.customerType !== 3 && (
                <div className="DeviceDetailEnvSupport">
                  <div className="deviceSupportSection">
                    <h1>Support Resources</h1>
                    <a
                      href="https://www.aquaticav.com/pages/support-center"
                      target="_blank"
                      style={{ color: "#1bbed0" }}
                    >
                      <h2>RGB Controller Support Center</h2>
                    </a>
                    <a
                      href="https://aavazurestorage.blob.core.windows.net/ofishassets/MobileAppUserGuide.pdf"
                      target="_blank"
                      style={{ color: "#1bbed0" }}
                    >
                      <h2>RGB Controller Quick Start Guide</h2>
                    </a>
                    <h3>Still need help?</h3>
                    <a
                      href="https://www.aquaticav.com/pages/contact"
                      target="_blank"
                      style={{ color: "#1bbed0" }}
                    >
                      <h2>Contact Aquatic AV Support</h2>
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="deviceOverviewContainer" style={{ width: "100%" }}>
              <div className="tabs-container_">
                <div className="tabSection">
                  <h6
                    className={
                      this.state.activeTab === "Personal Information"
                        ? "active-tab"
                        : "tab"
                    }
                    onClick={() => this.handleTabClick("Personal Information")}
                    style={{ marginRight: "7%" }}
                  >
                    Personal Information
                  </h6>
                  <h6
                    className={
                      this.state.activeTab === "Contact Information"
                        ? "active-tab"
                        : "tab"
                    }
                    onClick={() => this.handleTabClick("Contact Information")}
                    style={{ marginRight: "7%" }}
                  >
                    Contact Information
                  </h6>
                  <h6
                    className={
                      this.state.activeTab === "Notifications"
                        ? "active-tab"
                        : "tab"
                    }
                    onClick={() => this.handleTabClick("Notifications")}
                  >
                    Notifications
                  </h6>
                </div>
                {this.state.activeTab === "Personal Information" && (
                  <>
                    <div className="">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "20px",
                          justifyContent: "space-between",
                        }}
                      >
                        <h1
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#181C32",
                            // paddingTop: "20px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Personal Information
                        </h1>
                        <img
                          src={EditInfo}
                          className="editPersonalInformation"
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: "",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                            marginBottom: "20px",
                          }}
                          className="inputLableContainer"
                        >
                          <h1 className="deviceDetaitInputLabel">First Name</h1>
                          <input
                            type="text"
                            value={firstName}
                            className={
                              firstName === "" || firstNameError
                                ? "deviceInfoInput errorOutline"
                                : "deviceInfoInput"
                            }
                            style={{ width: "62%" }}
                            onChange={(e) =>
                              this.onFirstNameValidation(e.target.value)
                            }
                            readOnly
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                            marginBottom: "20px",
                          }}
                          className="inputLableContainer"
                        >
                          <h1 className="deviceDetaitInputLabel">Last Name</h1>
                          <input
                            type="text"
                            value={lastName}
                            className={
                              lastName === "" || lastNameError
                                ? "deviceInfoInput errorOutline"
                                : "deviceInfoInput"
                            }
                            style={{ width: "62%" }}
                            onChange={(e) =>
                              this.onLastNameValidation(e.target.value)
                            }
                            readOnly
                          />
                        </div>
                        <div
                          style={{
                            // display: "flex",
                            // flexDirection: "column",
                            // justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                            marginBottom: "20px",
                            position: "relative",
                          }}
                          className="inputLableContainer verficationContainer"
                        >
                          <h1 className="deviceDetaitInputLabel">E-Mail</h1>
                          <div className="emailVerificationWrapper">
                            {" "}
                            <input
                              type="text"
                              value={email}
                              className={
                                emailError || email === ""
                                  ? "deviceInfoInput errorOutline"
                                  : "deviceInfoInput"
                              }
                              style={{ width: "62%" }}
                              onChange={(e) =>
                                this.setState({
                                  email: e.target.value,
                                  emailError: false,
                                })
                              }
                              readOnly
                            />
                            <span
                              style={{
                                position: "absolute",
                                left:
                                  this.state?.isEmailVerified
                                    ?.emailConfirmed === false
                                    ? "51%"
                                    : "53%",
                                top: "48%",
                                background:
                                  this.state.isEmailVerified?.emailConfirmed ===
                                  false
                                    ? "#FCE6E6"
                                    : "#DEEFE5",

                                color:
                                  this.state.isEmailVerified?.emailConfirmed ===
                                  false
                                    ? "red"
                                    : "#219653",

                                padding: "4px 10px",
                                borderRadius: "6px",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                              className="emailStatus"
                            >
                              {this.state.isEmailVerified?.emailConfirmed ===
                              false
                                ? "Not Verified"
                                : "Verified"}
                            </span>
                          </div>
                          {this.state.isEmailVerified?.emailConfirmed ===
                            false && (
                            <button
                              className="verificationLinkBtn"
                              onClick={() => {
                                this.setState({
                                  openEmailVerfModal: true,
                                });
                                // Call your function here
                              }}
                            >
                              Send Verification Link
                            </button>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                            marginBottom: "20px",
                          }}
                          className="inputLableContainer"
                        >
                          <h1 className="deviceDetaitInputLabel">Password</h1>
                          <input
                            type="password"
                            value={password ? password : ""}
                            className={
                              personalError || password === ""
                                ? "deviceInfoInput errorOutline"
                                : "deviceInfoInput"
                            }
                            placeholder="******"
                            style={{ width: "62%" }}
                            onChange={(e) => {
                              this.setState({
                                password: e.target.value,
                              });
                              this.checkPasswordMatch();
                            }}
                          ></input>
                        </div>
                        <div
                          style={{
                            // display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                            marginBottom: "20px",
                          }}
                          className="inputLableContainer"
                        >
                          <h1 className="deviceDetaitInputLabel">
                            Confirm Password
                          </h1>
                          <input
                            type="password"
                            value={confirmPassword}
                            className={
                              confirmPassword === ""
                                ? "deviceInfoInput errorOutline"
                                : "deviceInfoInput"
                            }
                            placeholder="******"
                            style={{ width: "62%" }}
                            onChange={(e) => {
                              this.setState({
                                confirmPassword: e.target.value,
                              });
                              this.checkPasswordMatch();
                            }}
                          ></input>
                          <span className="changePasswordWrapper">
                            <button
                              style={{ marginLeft: "13px" }}
                              onClick={() =>
                                firstName !== "" &&
                                lastName !== "" &&
                                !emailError &&
                                password !== "" &&
                                !firstNameError &&
                                !lastNameError
                                  ? this.onUpdatePersonalInfo()
                                  : this.onCheckPersonalInfo()
                              }
                              className="changePassbtn"
                              // disabled={!this.state.passwordsMatch}
                            >
                              Change Password
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.activeTab === "Contact Information" && (
                  <>
                    <div className="">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "20px",
                          justifyContent: "space-between",
                        }}
                      >
                        <h1
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#181C32",
                            // paddingTop: "20px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Contact Information
                        </h1>
                        {!this.state.isShowContactBtn && (
                          <img
                            src={EditInfo}
                            className="editPersonalInformation"
                            alt=""
                            onClick={() =>
                              this.setState({
                                isShowContactBtn: true,
                              })
                            }
                          />
                        )}
                        {this.state.isShowContactBtn && (
                          <img
                            alt=""
                            src={Tick}
                            onClick={() =>
                              this.setState({
                                isShowContactBtn: false,
                              })
                            }
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: "",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-between",
                            // borderBottom: "1px dashed #E4E6EF",
                            padding: "10px 0",
                            marginBottom: "20px",
                          }}
                          className="contactInfoColumnBottom"
                        >
                          <h1 className="deviceDetaitInputLabel">
                            Address
                            <span className="asterk">*</span>
                          </h1>
                          {/* <input
                            type="text"
                            value={userInfo.address}
                            className="deviceInfoInput"
                            disabled
                            // style={{width:"62%"}}
                          ></input> */}
                          <Autocomplete
                            id="cntctAdresId"
                            className="location-input-registration deviceInfoInput"
                            apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
                            value={this.state.address}
                            onPlaceSelected={this.onPlaceSelected}
                            options={{
                              types: ["address"],
                            }}
                            onChange={(e) =>
                              this.setState({
                                address: e.target.value,
                                isCityEditable: true,
                                isStateEditable: true,
                                isCountryEditable: true,
                                isPostalCodeEditable: true,
                              })
                            }
                          />
                        </div>
                        <div
                          style={{ display: "flex" }}
                          className="contactInfoColumn"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "space-between",
                              // borderBottom: "1px dashed #E4E6EF",
                              padding: "10px 0",
                              marginBottom: "20px",
                              width: "100%",
                              marginRight: "20px",
                            }}
                            className="contactInfoColumnBottom"
                          >
                            <h1 className="deviceDetaitInputLabel">
                              City
                              <span className="asterk">*</span>
                            </h1>
                            <input
                              type="text"
                              value={city || ""}
                              placeholder="City"
                              className={
                                city === "" && cityError
                                  ? "deviceInfoInput errorOutline"
                                  : "deviceInfoInput"
                              }
                              onChange={(event) =>
                                this.setState({
                                  city: event.target.value,
                                  isManuallyEnter: true,
                                })
                              }
                            ></input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "space-between",
                              // borderBottom: "1px dashed #E4E6EF",
                              padding: "10px 0",
                              marginBottom: "20px",
                              width: "100%",
                            }}
                            className="contactInfoColumnBottom"
                          >
                            <h1 className="deviceDetaitInputLabel">
                              State
                              <span className="asterk">*</span>
                            </h1>
                            <input
                              type="text"
                              placeholder="State"
                              value={
                                typeof stateCode === "object"
                                  ? stateCode?.cityName
                                  : stateCode || ""
                              }
                              className={
                                stateCode === "" && stateError
                                  ? "deviceInfoInput errorOutline"
                                  : "deviceInfoInput"
                              }
                              onChange={(event) =>
                                this.setState({
                                  stateCode: event.target.value,
                                  isManuallyEnter: true,
                                })
                              }
                            ></input>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex" }}
                          className="contactInfoColumn"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "space-between",
                              // borderBottom: "1px dashed #E4E6EF",
                              padding: "10px 0",
                              marginBottom: "20px",
                              width: "100%",
                              marginRight: "20px",
                            }}
                            className="contactInfoColumnBottom"
                          >
                            <h1 className="deviceDetaitInputLabel">
                              Postal Code
                              <span className="asterk">*</span>
                            </h1>
                            <input
                              type="text"
                              value={postalCode}
                              placeholder="Postal Code"
                              className={
                                postalCode === "" || postalError
                                  ? "deviceInfoInput errorOutline"
                                  : "deviceInfoInput"
                              }
                              onChange={(e) =>
                                this.setState({
                                  postalCode: e.target.value,
                                  isManuallyEnter: true,
                                })
                              }
                            ></input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "space-between",
                              // borderBottom: "1px dashed #E4E6EF",
                              padding: "10px 0",
                              marginBottom: "20px",
                              width: "100%",
                            }}
                            className="contactInfoColumnBottom"
                          >
                            <h1 className="deviceDetaitInputLabel">
                              Country
                              <span className="asterk">*</span>
                            </h1>
                            <input
                              type="text"
                              placeholder="Country"
                              value={
                                typeof country === "object"
                                  ? country?.cityName
                                  : country || ""
                              }
                              className={
                                country === "" && countryError
                                  ? "deviceInfoInput errorOutline"
                                  : "deviceInfoInput"
                              }
                              onChange={(event) =>
                                this.setState({
                                  country: event.target.value,
                                  isManuallyEnter: true,
                                })
                              }
                            ></input>
                          </div>
                        </div>
                        <div className="countryFlagPhoneWrapper">
                          <div
                            style={{
                              flexDirection: "column",
                              marginBottom: "20px",
                              width: "100%",
                              position: "relative",
                            }}
                            className="contactInfoColumnBottom"
                          >
                            <h1 className="deviceDetaitInputLabel">
                              Phone Number<span class="asterk">*</span>
                            </h1>
                            <div>
                              <div className="emailVerificationWrapper phoneVerification">
                                <PhoneInput
                                  className={
                                    countryCodeError
                                      ? "deviceInfoInput errorOutline"
                                      : "deviceInfoInput"
                                  }
                                  label="Country Code"
                                  placeholder="Please Enter Country Code"
                                  style={{ xs: 12, lg: 4 }}
                                  id="countryCode1"
                                  name={countryCode}
                                  value={countryCode}
                                  onChange={this.handleCountryCodeChange}
                                  readOnly={"readOnly"}
                                />
                                <span className="phoneNumWrapper">
                                  <h1 class="deviceDetaitInputLabel"></h1>
                                  <span style={{ position: "relative" }}>
                                    <input
                                      type="text"
                                      placeholder="Phone Number"
                                      value={phoneNumber}
                                      onChange={this.handlePhoneChange}
                                      className={`
                                    ${
                                      phoneError
                                        ? "deviceInfoInput errorOutline "
                                        : "deviceInfoInput"
                                    } phoneNumberInput`}
                                      // style={{
                                      //   width: "100%",
                                      // }}
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "3%",
                                        top: "0",

                                        background:
                                          this.state.isEmailVerified
                                            .isSMSVerified === false
                                            ? "#FCE6E6"
                                            : "#DEEFE5",
                                        color:
                                          this.state.isEmailVerified
                                            ?.isSMSVerified === false
                                            ? "red"
                                            : "#219653",
                                        padding: "4px 10px",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {this.state.isEmailVerified
                                        ?.isSMSVerified === false
                                        ? "Not Verified"
                                        : "Verified"}
                                    </div>
                                  </span>
                                </span>

                                {phoneNumberError ? (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Invalid Phone Number*
                                  </span>
                                ) : null}
                                {/* {phoneNumberError ? (
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                Invalid Phone Number*
                              </span>
                            ) : null} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ContactInfoButtonsDesktop mt-5">
                          <button
                            style={{ marginLeft: "13px" }}
                            onClick={() =>
                              this.setState({
                                openPhoneVerfModal: true,
                              })
                            }
                          >
                            Send Verification Link
                          </button>

                          <button
                            style={{ marginLeft: "13px" }}
                            onClick={() =>
                              address !== "" &&
                              city !== "" &&
                              stateCode !== "" &&
                              postalCode !== "" &&
                              country !== "" &&
                              phoneNumber !== "" &&
                              !contactError &&
                              !cityError &&
                              !stateError &&
                              !postalError &&
                              !countryError &&
                              !phoneNumberError &&
                              !phoneError
                                ? this.onUpdateAddressInfo()
                                : this.onCheckContact()
                            }
                          >
                            Submit Changes
                          </button>
                        </div>
                        {this.state.isShowContactBtn && (
                          <div className="">
                            <button
                              style={{
                                width: "100%",
                                marginBottom: "10px",
                              }}
                              onClick={() =>
                                this.setState({
                                  openPhoneVerfModal: true,
                                })
                              }
                            >
                              Send Verification Link
                            </button>

                            <div className="ContactInfoButtonsDesktop">
                              <button
                                style={{ width: "100%" }}
                                onClick={() =>
                                  address !== "" &&
                                  city !== "" &&
                                  stateCode !== "" &&
                                  postalCode !== "" &&
                                  country !== "" &&
                                  phoneNumber !== "" &&
                                  !contactError &&
                                  !cityError &&
                                  !stateError &&
                                  !postalError &&
                                  !countryError &&
                                  !phoneError
                                    ? this.onUpdateAddressInfo()
                                    : this.onCheckContact()
                                }
                              >
                                Submit Changes
                              </button>{" "}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {this.state.activeTab === "Notifications" && (
                  <>
                    <div>
                      <h1
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#181C32",
                          paddingTop: "20px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Account
                      </h1>
                      <Accordion
                        style={{
                          boxShadow: "0px !important",
                        }}
                        expanded={expandedAccordions.includes(0)}
                        onChange={() => this.handleAccordianChange(0)}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            border: "none !important",
                          }}
                          className="accordianMobile"
                        >
                          <h5
                            className="accordionHeading"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "#1BBED0",
                              background: "#F0FAFF",
                              width: "50%",
                              boxShadow: "0px 2px 4px 0px #0000001A",
                              height: "58px",
                              padding: "0 20px",
                              color: "#00000",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Updates
                            <span>
                              {!this.state.expandedAccordions.includes(0) ? (
                                <img src={AccordionArrow} alt="" />
                              ) : (
                                <img src={arrowDown} alt="" />
                              )}
                            </span>
                          </h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-3">
                                <input
                                  type="checkbox"
                                  checked={this.state.type1.web}
                                  onChange={(e) => {
                                    this.setState({
                                      type1: {
                                        ...this.state.type1,
                                        web: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Web/Browser
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type1.mobileApp}
                                  onChange={(e) => {
                                    this.setState({
                                      type1: {
                                        ...this.state.type1,
                                        mobileApp: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Mobile/App
                              </label>
                            </div>
                            <div className="notificationDropdown my-2">
                              <label className="custom-checkbox ">
                                <input
                                  type="checkbox"
                                  checked={this.state.type1.email}
                                  onChange={(e) => {
                                    this.setState({
                                      type1: {
                                        ...this.state.type1,
                                        email: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> E-Mail
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox mt-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type1.sms}
                                  onChange={(e) => {
                                    this.setState({
                                      type1: {
                                        ...this.state.type1,
                                        sms: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> SMS
                              </label>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        style={{
                          boxShadow: "0px !important",
                        }}
                        expanded={expandedAccordions.includes(1)}
                        onChange={() => this.handleAccordianChange(1)}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            border: "none !important",
                          }}
                          className="accordianMobile"
                        >
                          <h5
                            className="accordionHeading"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "#1BBED0",
                              background: "#F0FAFF",
                              width: "50%",
                              boxShadow: "0px 2px 4px 0px #0000001A",
                              height: "58px",
                              padding: "0 20px",
                              color: "#00000",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Changes
                            <span>
                              {!this.state.expandedAccordions.includes(1) ? (
                                <img src={AccordionArrow} alt="" />
                              ) : (
                                <img src={arrowDown} alt="" />
                              )}
                            </span>
                          </h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type2.web}
                                  onChange={(e) => {
                                    this.setState({
                                      type2: {
                                        ...this.state.type2,
                                        web: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Web/Browser
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type2.mobileApp}
                                  onChange={(e) => {
                                    this.setState({
                                      type2: {
                                        ...this.state.type2,
                                        mobileApp: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Mobile/App
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type2.email}
                                  onChange={(e) => {
                                    this.setState({
                                      type2: {
                                        ...this.state.type2,
                                        email: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> E-Mail
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type2.sms}
                                  onChange={(e) => {
                                    this.setState({
                                      type2: {
                                        ...this.state.type2,
                                        sms: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> SMS
                              </label>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        style={{
                          boxShadow: "0px !important",
                        }}
                        expanded={expandedAccordions.includes(2)}
                        onChange={() => this.handleAccordianChange(2)}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            border: "none !important",
                          }}
                          className="accordianMobile"
                        >
                          <h5
                            className="accordionHeading"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "#1BBED0",
                              background: "#F0FAFF",
                              width: "50%",
                              boxShadow: "0px 2px 4px 0px #0000001A",
                              height: "58px",
                              padding: "0 20px",
                              color: "#00000",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Conversations
                            <span>
                              {!this.state.expandedAccordions.includes(2) ? (
                                <img src={AccordionArrow} alt="" />
                              ) : (
                                <img src={arrowDown} alt="" />
                              )}
                            </span>
                          </h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type3.web}
                                  onChange={(e) => {
                                    this.setState({
                                      type3: {
                                        ...this.state.type3,
                                        web: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Web/Browser
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type3.mobileApp}
                                  onChange={(e) => {
                                    this.setState({
                                      type3: {
                                        ...this.state.type3,
                                        mobileApp: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Mobile/App
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type3.email}
                                  onChange={(e) => {
                                    this.setState({
                                      type3: {
                                        ...this.state.type3,
                                        email: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> E-Mail
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type3.sms}
                                  onChange={(e) => {
                                    this.setState({
                                      type3: {
                                        ...this.state.type3,
                                        sms: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> SMS
                              </label>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <h1
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#181C32",
                          paddingTop: "20px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Newsletters
                      </h1>
                      <Accordion
                        style={{
                          boxShadow: "0px !important",
                        }}
                        expanded={expandedAccordions.includes(3)}
                        onChange={() => this.handleAccordianChange(3)}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            border: "none !important",
                          }}
                          className="accordianMobile"
                        >
                          <h5
                            className="accordionHeading"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "#1BBED0",
                              background: "#F0FAFF",
                              width: "50%",
                              boxShadow: "0px 2px 4px 0px #0000001A",
                              height: "58px",
                              padding: "0 20px",
                              color: "#00000",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Aquatic AV
                            <span>
                              {!this.state.expandedAccordions.includes(3) ? (
                                <img src={AccordionArrow} alt="" />
                              ) : (
                                <img src={arrowDown} alt="" />
                              )}
                            </span>
                          </h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type5.web}
                                  onChange={(e) => {
                                    this.setState({
                                      type5: {
                                        ...this.state.type5,
                                        web: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Web/Browser
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type5.mobileApp}
                                  onChange={(e) => {
                                    this.setState({
                                      type5: {
                                        ...this.state.type5,
                                        mobileApp: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Mobile/App
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type5.email}
                                  onChange={(e) => {
                                    this.setState({
                                      type5: {
                                        ...this.state.type5,
                                        email: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> E-Mail
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type5.sms}
                                  onChange={(e) => {
                                    this.setState({
                                      type5: {
                                        ...this.state.type5,
                                        sms: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> SMS
                              </label>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        style={{
                          boxShadow: "0px !important",
                        }}
                        expanded={expandedAccordions.includes(4)}
                        onChange={() => this.handleAccordianChange(4)}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            border: "none !important",
                          }}
                          className="accordianMobile"
                        >
                          <h5
                            className="accordionHeading"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "#1BBED0",
                              background: "#F0FAFF",
                              width: "50%",
                              boxShadow: "0px 2px 4px 0px #0000001A",
                              height: "58px",
                              padding: "0 20px",
                              color: "#00000",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Dealer
                            <span>
                              {!this.state.expandedAccordions.includes(4) ? (
                                <img src={AccordionArrow} alt="" />
                              ) : (
                                <img src={arrowDown} alt="" />
                              )}
                            </span>
                          </h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type6.web}
                                  onChange={(e) => {
                                    this.setState({
                                      type6: {
                                        ...this.state.type6,
                                        web: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Web/Browser
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type6.mobileApp}
                                  onChange={(e) => {
                                    this.setState({
                                      type6: {
                                        ...this.state.type6,
                                        mobileApp: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> Mobile/App
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type6.email}
                                  onChange={(e) => {
                                    this.setState({
                                      type6: {
                                        ...this.state.type6,
                                        email: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> E-Mail
                              </label>
                            </div>
                            <div className="notificationDropdown">
                              <label className="custom-checkbox my-2">
                                <input
                                  type="checkbox"
                                  checked={this.state.type6.sms}
                                  onChange={(e) => {
                                    this.setState({
                                      type6: {
                                        ...this.state.type6,
                                        sms: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <span className="checkmark"></span> SMS
                              </label>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <div className="ContactInfoButtonsDesktop">
                        {" "}
                        <button
                          style={{
                            marginLeft: "13px",
                            marginTop: 20,
                          }}
                          onClick={this.onSubmitNotification}
                        >
                          Submit Changes
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="DeviceDetailEnvSupportMobile">
              <div className="deviceSupportSection">
                <h1>Support Resources</h1>
                <a
                  href="https://www.aquaticav.com/pages/support-center"
                  target="_blank"
                  style={{ color: "#1bbed0" }}
                >
                  <h2>RGB Controller Support Center</h2>
                </a>
                <a
                  href="https://aavazurestorage.blob.core.windows.net/ofishassets/MobileAppUserGuide.pdf"
                  target="_blank"
                  style={{ color: "#1bbed0" }}
                >
                  <h2>RGB Controller Quick Start Guide</h2>
                </a>
                <h3>Still need help?</h3>
                <a
                  href="https://www.aquaticav.com/pages/contact"
                  target="_blank"
                  style={{ color: "#1bbed0" }}
                >
                  <h2>Contact Aquatic AV Support</h2>
                </a>
              </div>
            </div>
          </div>
          <Modal
            title="Model"
            open={this.state.openEmailVerfModal}
            // handleclose={() => this.closeTranferDevice()}
          >
            <Box style={style} className="phoneVerificationModal">
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <img
                  src={Cross}
                  onClick={() =>
                    this.setState({
                      openEmailVerfModal: false,
                      isSendEmail: false,
                    })
                  }
                  alt="Close"
                />
              </div>
              <h1 className="transferOwner" style={{ marginTop: "20px" }}>
                Verify Email
              </h1>

              {this.state.isSendEmail ? (
                <>
                  <p className="transferOwnerHeading">
                    We just sent you an e-mail with a verification link so we
                    can verify your e-mail address is correct. Your link will
                    expire within 15 minutes so make sure to hurry. If you don’t
                    get an e-mail soon, check your Junk/Spam folders first or
                    click the button below to resend the verification link.
                  </p>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      style={{
                        background: "#24346B",
                        padding: "10px 20px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                      onClick={() => {
                        // Call your function here
                        this.handleEmailVerification();
                      }}
                    >
                      RESEND
                    </button>
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#A7A8BB",
                      }}
                    >
                      Need help?
                    </p>
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#1BBED0",
                      }}
                    >
                      Contact Aquatic AV Support
                    </p>
                  </span>
                </>
              ) : (
                <>
                  <p className="transferOwnerHeading">
                    You’ve entered {userInfo.userName} as the e-mail address for
                    your account. Please verify by clicking below.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={MessageEnv} alt="Message" />
                    <button
                      style={{
                        background: "#24346B",
                        padding: "10px 20px",
                        marginTop: "20px",
                      }}
                      onClick={() => {
                        this.setState({
                          isSendEmail: true,
                        });
                        // Call your function here
                        this.handleEmailVerification();
                      }}
                    >
                      Send
                    </button>
                  </div>
                </>
              )}
            </Box>
          </Modal>
          <Modal
            title="Model"
            open={this.state.openPhoneVerfModal}
            // handleclose={() => this.closeTranferDevice()}
          >
            <Box style={style} className="phoneVerificationModal">
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <img
                  src={Cross}
                  onClick={() =>
                    this.setState({
                      openPhoneVerfModal: false,
                      isCodeSend: false,
                    })
                  }
                  alt="Close"
                />
              </div>
              <h1 className="transferOwner" style={{ marginTop: "20px" }}>
                Verify Phone Number
              </h1>

              {this.state.isCodeSend ? (
                <>
                  <p className="transferOwnerHeading">
                    We just sent you a text message with a verification link so
                    we can verify your phone number is correct. Your link will
                    expire within 15 minutes so make sure to hurry. If you don’t
                    get a text message soon, click the button below to resend
                    the verification link.
                  </p>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      style={{
                        background: "#24346B",
                        padding: "10px 20px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                      onClick={() => {
                        this.handlePhoneVerification();
                      }}
                    >
                      RESEND
                    </button>
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#A7A8BB",
                      }}
                    >
                      Need help?
                    </p>
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#1BBED0",
                      }}
                    >
                      Contact Aquatic AV Support
                    </p>
                  </span>
                </>
              ) : (
                <>
                  <p className="transferOwnerHeading">
                    You’ve entered {userInfo.phoneNumber} as the phone number
                    for your account. Please verify by clicking below.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={CodeBox} alt="Message" />
                    <button
                      style={{
                        background: "#24346B",
                        padding: "10px 20px",
                        marginTop: "20px",
                      }}
                      onClick={() => {
                        this.setState({ isCodeSend: true });
                        this.handlePhoneVerification();
                      }}
                    >
                      Send
                    </button>
                  </div>
                </>
              )}
            </Box>
          </Modal>
        </div>

        <div className="Dashboardmain-container__innerBody dashboard_myaccount">
          {/* <TabNav
            activepane={this.state.activepane}
            clickTab={(value) => this.clickTab(value)}
          > 
          {/* <Pane id="pane1" title="Personal Information ">
              <div className="personalInfoDiv">
                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: "24px",
                    color: "#333333",
                    fontFamily: "Roboto",
                  }}
                >
                  Personal Information
                </h1>
                <span className="footerNavButtons">
                  {isDisabled === "Personal Information" ? (
                    <button
                      id="pane2"
                      className="nextNav"
                      onClick={() =>
                        firstName !== "" &&
                        lastName !== "" &&
                        !emailError &&
                        password !== "" &&
                        !firstNameError &&
                        !lastNameError
                          ? this.onUpdatePersonalInfo()
                          : this.onCheckPersonalInfo()
                      }
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      id="pane2"
                      className="nextNav"
                      onClick={(e) =>
                        this.setState({ isDisabled: "Personal Information" })
                      }
                    >
                      Edit
                    </button>
                  )}
                </span>
              </div>

              <div
                className={
                  isDisabled === "Personal Information" ? "" : "disabledFields"
                }
              >
                <span className="loginWrapper__loginFormWrapper--inner">
                  <FloatingLabelInput
                    placeholder="First name"
                    value={firstName}
                    onBlur={() =>
                      this.setState({
                        firstName: firstName.trim(),
                        firstNameError: firstName !== "" ? false : true,
                      })
                    }
                    className={
                      firstName === "" || firstNameError ? "errorOutline" : ""
                    }
                    onChange={(e) => this.onFirstNameValidation(e.target.value)}
                  />
                </span>
                <span className="loginWrapper__loginFormWrapper--inner">
                  <FloatingLabelInput
                    placeholder="Last name"
                    value={lastName}
                    onBlur={() =>
                      this.setState({
                        lastName: lastName.trim(),
                        lastNameError: lastName !== "" ? false : true,
                      })
                    }
                    className={
                      lastName === "" || lastNameError ? "errorOutline" : ""
                    }
                    onChange={(e) => this.onLastNameValidation(e.target.value)}
                  />
                </span>
                <span className="loginWrapper__loginFormWrapper--inner">
                  <FloatingLabelInput
                    placeholder="Email"
                    value={email}
                    className={emailError || email === "" ? "errorOutline" : ""}
                    onBlur={() => this.onFocusOutFun(email)}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                        emailError: false,
                      })
                    }
                  />
                  {emailError ? (
                    <span style={{ color: "red" }}>
                      {email !== "" ? "Invalid Email" : ""}
                    </span>
                  ) : null}
                </span>
                <span className="groupFieldsMain">
                  <span className="loginWrapper__loginFormWrapper--inner ">
                    <FloatingLabelInput
                      placeholder="Password"
                      type="password"
                      value={password ? password : ""}
                      className={
                        personalError || password === "" ? "errorOutline" : ""
                      }
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </span>
                </span>

                <span className="groupFieldsMain">
                  <span className="loginWrapper__loginFormWrapper--inner ">
                    <label
                      className="form-label"
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "700",
                        fontSize: "18px",
                        color: "#464E5F",
                      }}
                    >
                      User Type
                    </label>
                    <span
                      className="customerType"
                      style={{
                        fontFamily: "Roboto",
                      }}
                    >
                      {this.state.userData.customerTypeName === "EndCustomer"
                        ? "End User"
                        : "Business Owner"}
                    </span>
                  </span>
                </span>
              </div>
              
              <div className="personalInfoDiv">
                <h1>Contact Information</h1>
                <span className="footerNavButtons ">
                  {isDisabled === "Contact Information" ? (
                    <button
                      id="pane2"
                      className="nextNav"
                      onClick={() =>
                        address !== "" &&
                        city !== "" &&
                        stateCode !== "" &&
                        postalCode !== "" &&
                        country !== "" &&
                        phoneNumber !== "" &&
                        !contactError &&
                        !cityError &&
                        !stateError &&
                        !postalError &&
                        !countryError &&
                        !phoneError
                          ? this.onUpdateAddressInfo()
                          : this.onCheckContact()
                      }
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      id="pane2"
                      className="nextNav"
                      onClick={(e) =>
                        this.setState({ isDisabled: "Contact Information" })
                      }
                    >
                      Edit
                    </button>
                  )}
                </span>
              </div>
              <div
                className={
                  isDisabled === "Contact Information" ? "" : "disabledFields"
                }
              >
              
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h className="adress-label addressLabel">
                    Address <span style={{ color: "red" }}>*</span>
                  </h>
                  <Autocomplete
                    id="popupEnvLocInput"
                    className="location-input-registration"
                    apiKey={process.env.REACT_APP_MAP_KEY2}
                    defaultValue={this.state.address}
                    onPlaceSelected={this.onPlaceSelected}
                    options={{
                      types: ["address"],
                    }}
                    onChange={() =>
                      this.setState({
                        isCityEditable: true,
                        isStateEditable: true,
                        isCountryEditable: true,
                        isPostalCodeEditable: true,
                      })
                    }
                  />
                </div>
                <span className="groupFieldsMain">
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelInput
                      placeholder="City"
                      className={city === "" && cityError ? "errorOutline" : ""}
                      value={city || ""}
                      onChange={(event) =>
                        this.setState({
                          city: event.target.value,
                          isManuallyEnter: true,
                        })
                      }
                      mandatory
                    />
                  </span>
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelInput
                      placeholder="State"
                      value={
                        typeof stateCode === "object"
                          ? stateCode?.cityName
                          : stateCode || ""
                      }
                      className={
                        stateCode === "" && stateError ? "errorOutline" : ""
                      }
                      onChange={(event) =>
                        this.setState({
                          stateCode: event.target.value,
                          isManuallyEnter: true,
                        })
                      }
                      mandatory
                    />
                  </span>
                </span>
                <span className="groupFieldsMain">
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelInput
                      placeholder="Postal Code"
                      value={postalCode}
                      // onBlur={() => this.onPostalValidation(postalCode)}
                      className={
                        postalCode === "" || postalError ? "errorOutline" : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          postalCode: e.target.value,
                          isManuallyEnter: true,
                        })
                      }
                      mandatory
                    />
                  </span>
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelInput
                      placeholder="Country"
                      value={
                        typeof country === "object"
                          ? country?.cityName
                          : country || ""
                      }
                      className={
                        country === "" && countryError ? "errorOutline" : ""
                      }
                      onChange={(event) =>
                        this.setState({
                          country: event.target.value,
                          isManuallyEnter: true,
                        })
                      }
                      mandatory
                    />
                  </span>
                </span>
                <span className="loginWrapper__loginFormWrapper--inner groupFields">
                  <FloatingLabelInput
                    placeholder="Phone Number"
                    value={phoneNumber}
                    className={
                      phoneNumber === "" || phoneError ? "errorOutline" : ""
                    }
                    onChange={(e) =>
                      this.setState({
                        phoneNumber: e.target.value,
                        phoneError: false,
                      })
                    }
                    onBlur={(e) => this.onPhoneNumberValidation(e.target.value)}
                  />
                </span>
              </div>
         
            </Pane> */}

          {/* CONTACT INFORMATION */}

          {/* <Pane id="pane2" title="Contact Information">
              <h1>Contact Information</h1>
              <div
                className={
                  isDisabled === "Contact Information" ? "" : "disabledFields"
                }
              >
                <span className="loginWrapper__loginFormWrapper--inner">
                  <FloatingLabelInput
                    placeholder="Address"
                    value={address}
                    onBlur={() => this.setState({ address: address.trim() })}
                    className={
                      address === "" || contactError ? "errorOutline" : ""
                    }
                    onChange={(e) =>
                      this.setState({
                        address: e.target.value,
                        contactError: false,
                      })
                    }
                  />
                </span>
                <span className="groupFieldsMain">
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelSelect
                      placeholder="City"
                      defaultValue={
                        cityOptions.filter((ops) => ops.value === city)[0]
                      }
                      options={cityOptions}
                      className={city === "" || cityError ? "errorOutline" : ""}
                      onChange={(e) => {
                        this.onCityValidation(e.value);
                      }}
                    />
                  </span>
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelSelect
                      placeholder="State"
                      defaultValue={
                        statesOptions.filter(
                          (ops) => ops.value === stateCode
                        )[0]
                      }
                      options={statesOptions}
                      className={
                        stateCode === "" || stateError ? "errorOutline" : ""
                      }
                      onChange={(e) => this.onStateValidation(e.value)}
                    />
                  </span>
                </span>
                <span className="groupFieldsMain">
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelInput
                      placeholder="Postal Code"
                      value={postalCode}
                      onBlur={() => this.onPostalValidation(postalCode)}
                      className={
                        postalCode === "" || postalError ? "errorOutline" : ""
                      }
                      onChange={(e) =>
                        this.setState({ postalCode: e.target.value })
                      }
                    />
                  </span>
                  <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                    <FloatingLabelSelect
                      placeholder="Country"
                      defaultValue={
                        countryOptions.filter((ops) => ops.value === country)[0]
                      }
                      options={countryOptions}
                      className={
                        country === "" || countryError ? "errorOutline" : ""
                      }
                      onChange={(e) => this.onCountryValidation(e.value)}
                    />
                  </span>
                </span>
                <span className="loginWrapper__loginFormWrapper--inner groupFields">
                  <FloatingLabelInput
                    placeholder="Phone Number"
                    value={phoneNumber}
                    className={
                      phoneNumber === "" || phoneError ? "errorOutline" : ""
                    }
                    onChange={(e) =>
                      this.setState({
                        phoneNumber: e.target.value,
                        phoneError: false,
                      })
                    }
                    onBlur={(e) => this.onPhoneNumberValidation(e.target.value)}
                  />
                </span>
              </div>
              <span className="footerNav ">
                {isDisabled === "Contact Information" ? (
                  <button
                    id="pane2"
                    className="nextNav"
                    onClick={() =>
                      address !== "" &&
                      city !== "" &&
                      stateCode !== "" &&
                      postalCode !== "" &&
                      country !== "" &&
                      phoneNumber !== "" &&
                      !contactError &&
                      !cityError &&
                      !stateError &&
                      !postalError &&
                      !countryError &&
                      !phoneError
                        ? this.onUpdateAddressInfo()
                        : this.onCheckContact()
                    }
                  >
                    Update
                  </button>
                ) : (
                  <button
                    id="pane2"
                    className="nextNav"
                    onClick={(e) =>
                      this.setState({ isDisabled: "Contact Information" })
                    }
                  >
                    Edit
                  </button>
                )}
              </span>
            </Pane> */}

          {/* REGISTER DEVICES */}

          {/* <Pane id="pane4" title="Devices">
              <div className="dashboardModal">
                {this.state.isNewModelShow && (
                  <AppModal
                    title="Model"
                    handleclose={() => this.closeAddDevice()}
                  >
                    <h1 className="environment ">Add New Device</h1>
                    <SelectEnvironment
                      closeAddDevice={() => this.closeAddDevice()}
                      onLoadCompleteData={() =>
                        this.onLoadCompleteData(
                          true,
                          "Device have been created successfully"
                        )
                      }
                    />
                  </AppModal>
                )}
                {this.state.isTranferModelShow && (
                  <AppModal
                    title="Model"
                    handleclose={() => this.closeTranferDevice()}
                  >
                    <h1 className="environment ">Transfer Ownership</h1>
                    <TransferDevice
                      onLoadCompleteData={() => {
                        this.closeTranferDevice();
                        this.onLoadCompleteData(
                          true,
                          "Device transfer started! We'll keep you updated until it's off your Aquatic AV Account."
                        );
                      }}
                    />
                  </AppModal>
                )}
              </div>
              {this.state.myDevices && (
                <>
                  <h1 className="environment">My Devices</h1>
                  {console.log("myDeviceBox", myDeviceBox)}
                  <ul>
                    {this.state.deviceDetail &&
                      this.state.deviceDetail?.length > 0 &&
                      this.state.deviceDetail?.map((items, index) => {
                        return (
                          <li key={index}>
                            <div className="navDotsWrapper">
                              <span className="navDots">
                                <BsThreeDots
                                  onClick={() =>
                                    this.setState({
                                      isDeviceDropdown:
                                        !this.state.isDeviceDropdown,
                                    })
                                  }
                                />
                                {this.state.isDeviceDropdown && (
                                  <div className="dropDown">
                                    <ul
                                    // ref={this.wrapperRef}
                                    >
                                      <li>
                                        <button
                                          type="botton"
                                          onClick={(e) =>
                                            this.onRegisteredButtonClick(
                                              items,
                                              "pane2"
                                            )
                                          }
                                        >
                                          Manage device
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          onClick={() =>
                                            this.onSelectDeviceOwnership(items)
                                          }
                                        >
                                          {" "}
                                          Transfer ownership{" "}
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </span>
                            </div>

                            <DeviceBox
                              heading={`${items.name}`}
                              subheadeing={items.product}
                              located={`${"Located in "}`}
                              productregistration={() =>
                                this.showRegistration(items)
                              }
                              changeHeading={(updateHeading) => {
                                this.updateDeviceName(updateHeading, items);
                              }}
                              userName={`${
                                userInfo ? userInfo.firstName : ""
                              }${`'s `}`}
                              envPressed={(value) =>
                                this.onChangeEnv(value, items)
                              }
                              currentEnv={items.environment}
                              envList={userEnv}
                              deviceImg={items?.productImage}
                              sku={items.sku}
                            />
                          </li>
                        );
                      })}
                  </ul>
                  <span
                    onClick={() => this.setState({ isNewModelShow: true })}
                    className="addNewDevice"
                  >
                    <RiAddFill /> add new device
                  </span>
                </>
              )}
              {this.state.prodRegistration && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h1>Product Registration</h1>
                    <p className={className}>
                      {deviceInformation.receiptStatus}
                    </p>
                  </div>

                  <span className="purchaseRecWrapper">
                    {deviceInformation.name}
                  </span>
                  <span className="userProductname">
                    {deviceInformation.product}
                  </span>

                  <span className="productDetail">
                    {deviceInformation.device_type}
                  </span>
                  <span className="loginWrapper__loginFormWrapper--inner devicesFields">
                    <FloatingLabelInput
                      placeholder="Serial Number"
                      value={deviceInformation.serialNo}
                      disabled={true}
                    />
                
                    {console.log("deviceInformation---->", deviceInformation)}
                    <div className="inputMainWrapper">
                      <label
                        className="form-label"
                        style={{ fontWeight: "700", fontSize: "18px" }}
                      >
                        Purchase Location
                      </label>
                      <input
                        name="dealers"
                        id="dealer"
                        value={deviceInformation.dealer}
                        readOnly={true}
                        disabled={true}
                      >
 
                      </input>
                    </div>
                    <FloatingLabelInput
                      placeholder="Purchase Date"
                      value={moment(
                        deviceInformation.purchaseDate,
                        "MM/DD/YYYY HH:mm:ss"
                      ).format("MM/DD/YYYY")}
                      disabled={true}
                    />

                    {deviceInformation.installationDate && (
                      <FloatingLabelInput
                        placeholder="installation Date"
                        value={moment(
                          deviceInformation.installationDate,
                          "MM/DD/YYYY HH:mm:ss"
                        ).format("MM/DD/YYYY")}
                        disabled={true}
                      />
                    )}

                    <span className="purchase-receipt">
                      <label>Purchase Receipt</label>
                      <div className="receiptWrapper">
                        {(deviceInformation &&
                          deviceInformation.receipt !== "") ||
                        isReplaceImage === true ? (
                          <>
                            <AppBtn
                              text="View"
                              onClick={() =>
                                this.setState({ isPurchase: true })
                              }
                            />
                            <div className="upload-btn-wrapper">
                              <button className="btn">Replace</button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) => this.handleReplaceImage(e)}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="upload-btn-wrapper">
                            <button className="btn">Attach Image</button>
                            <input
                              type="file"
                              name="myfile"
                              onChange={(e) => this.handleReplaceImage(e)}
                            />
                          </div>
                        )}
                      </div>
                    </span>

                    <span
                      className="backBtn"
                      onClick={() => this.showDevices()}
                    >
                      <BsArrowLeft />
                      Back to devices
                    </span>
                  </span>

                  <span className="purchaseOverlay">
                    {this.state.isPurchase && (
                      <AppModal
                        title="Model"
                        handleclose={() => this.setState({ isPurchase: false })}
                      >
                        <h1 className="environment">Purchase Receipt</h1>
                        <p>Add a device to your account </p>
                        <span>
                          {deviceInformation.receipt ? (
                            <img
                              className="imgPurchase-receipt"
                              src={deviceInformation.receipt}
                              alt=""
                            />
                          ) : (
                            <span>No receipt available</span>
                          )}{" "}
                        </span>
                      </AppModal>
                    )}
                  </span>
                </>
              )}
            </Pane> */}

          {/* CREATE AN INVIRONMENT */}

          {/* <Pane id="pane3" title="Environments">
              <h1 className="environment">My Environments</h1>
              <div className="environmentList">
                <ul>
                  {userEnv &&
                    userEnv.length > 0 &&
                    userEnv.map((items, index) => {
                      return (
                        <li key={index}>
                          <div>
                            <span className="icon">
                              {this.returnIcons(
                                items.name,
                                items?.iconName ? items?.iconName : items?.icon
                              )}
                            </span>
                            <span className="iconText">
                              {isEnvEdit && editEnvId === items.id ? (
                                <span className="nameOnly nameCursor">
                                  <input
                                    className={"enabledInput"}
                                    type="text"
                                    value={editEnvValue}
                                    onChange={(e) => {
                                      this.setState({
                                        editEnvValue: e.target.value,
                                      });
                                    }}
                                  />
                                </span>
                              ) : (
                                items.name
                              )}

                              <i>{`${
                                items?.devicesCount || 0
                              }${" devices"}`}</i>
                            </span>
                          </div>
                          <span className="chavron">
                            {isEnvEdit && editEnvId === items.id ? (
                              <span onClick={() => this.onUpdateEnvName(items)}>
                                <RiCheckFill />
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  this.setState({
                                    isEnvEdit: true,
                                    editEnvId: items.id,
                                    editEnvValue: items.name,
                                  });
                                }}
                              >
                                <MdEdit />
                              </span>
                            )}

                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>

              <span
                onClick={() => this.handleShowEnvironment()}
                className="addNewDevice"
              >
                <RiAddFill /> add new environment
              </span>
              {this.state.isEnvironmentPopup && (
                <AppModal title="Model" handleclose={() => this.handleClose()}>
                  <h1 className="environment">Add New Environment</h1>
                  <AddNewEnvironment
                    closeAddDevice={() =>
                      this.setState({ isEnvironmentPopup: false })
                    }
                    onLoadCompleteData={() =>
                      this.onLoadCompleteData(
                        true,
                        "Device have been created successfully"
                      )
                    }
                  />
                </AppModal>
              )}
            </Pane> */}
          {/* </TabNav> */}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(dashboardActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),

    deviceOverviewAction: bindActionCreators(deviceOverviewAction, dispatch),
  })
)(withRouter(MyAccount));
