import React from "react";
import "./stepper.css";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    "& .stepSmallTitle": {
      paddingTop: '5px'
    },
    "& .MuiStepIcon-active": {
      color: "#1BBED0",
      width: 48,
      height: 48,
      borderRadius: "10px",
    },
    "& .MuiStepIcon-completed": {
      color: "#1BBED0",
      width: 48,
      height: 48,
      borderRadius: "10px",
    },
    "& .Mui-disabled .MuiStepIcon-root": {
      color: "#15192E",
      width: 48,
      height: 48,
      borderRadius: "10px",
    },
    "& .MuiStepConnector-vertical": {
      marginLeft: 25,
      marginTop: 10,
    },
    "& .MuiStepConnector-lineVertical": {
      borderLeftStyle: "dashed",
      borderLeftWidth: 1,
      color: "#ffffff",
    },
    // "& .MuiStepLabel-iconContainer": {
    //   backgroundColor: "#1BBED0",
    //   padding: 0,
    //   borderRadius: 4,
    // },
    // "& .Mui-disabled": {
    //   backgroundColor: "#000",
    // },
  },
}));

// const useStyles1 = makeStyles((theme) => ({
//   customIcon: {
//     width: 48, 
//     height: 48,
//     borderRadius: "10px",
//     backgroundColor: '#15192E',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     color: 'white',
//     "& .active": {
//       backgroundColor: '#1BBED0'
//     }
//   },
// }));

// function CustomStepIcon({ active, activeStep }) {
//   const classes = useStyles1();
//   console.log(activeStep , "activeStep")
//   return (
//     <div className={`${classes.customIcon} ${active ? 'active' : ''}`}>
//       <span>{activeStep}</span>
//     </div>
//   );
// }

const AccountsWizard = ({
  activeStep = 0,
  setActiveStep = () => {},
  stepOnePrimary,
  stepOneSecondary,
  stepTowPrimary,
  stepTwoSecondary,
  stepThreePrimary,
  stepThreeSecondary,
  stepFourPrimary,
  stepFourSecondary,
}) => {
  const style = useStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={style.root}
      >
        <Step>
          <StepLabel>
            <Typography className="stepTitle">{stepOnePrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepOneSecondary}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography className="stepTitle">{stepTowPrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepTwoSecondary}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography className="stepTitle"> {stepThreePrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepThreeSecondary}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography className="stepTitle">{stepFourPrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepFourSecondary}
            </Typography>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

export { AccountsWizard };
