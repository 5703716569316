import React, { useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "../../../resources/images/arrowexpand.jpg"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./WaterTreatmentResources.css";

export default function SimpleAccordion() {
  const [expanded, setExpanded] = useState(false);
  const [expandedReplaceWater, setExpandedReplaceWater] = useState(false);
  const [expandedCleanFilter, setExpandedCleanFilter] = useState(false);
  const [expandDealer, setExpandDealer] = useState(false);
  const [expandSerivcePerson, setExpandSerivcePerson] = useState(false);

  return (
    <div className="waterresourcemain">
      <Typography
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottomWidth: 0,
        }}
        className="waterresourceheading"
      >
        <span className="supportText">Support Resources</span>
      </Typography>

      <Accordion
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          //   expandIcon={
          //     expanded ? (
          //       <KeyboardArrowUpIcon style={{ color: "#49B8EF" }} />
          //     ) : (
          //       <KeyboardArrowRight style={{ color: "#49B8EF" }} />
          //     )
          //   }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            border: "none !important",
          }}
        >
          <Typography className="accordianHeading supportTypeText">
            How to Determine Water Clarity
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Text is missing</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={() => setExpandedReplaceWater(!expandedReplaceWater)}
      >
        <AccordionSummary
          //   expandIcon={
          //     expandedReplaceWater ? (
          //       <KeyboardArrowUpIcon style={{ color: "#49B8EF" }} />
          //     ) : (
          //       <KeyboardArrowRight style={{ color: "#49B8EF" }} />
          //     )
          //   }
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ border: "none !important" }}
        >
          <Typography className="accordianHeading supportTypeText">
            How to Replace Filter
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Text is missing</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion onChange={() => setExpandedCleanFilter(!expandedCleanFilter)}>
        <AccordionSummary
          //   expandIcon={
          //     expandedCleanFilter ? (
          //       <KeyboardArrowUpIcon style={{ color: "#49B8EF" }} />
          //     ) : (
          //       <KeyboardArrowRight style={{ color: "#49B8EF" }} />
          //     )
          //   }
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ border: "none !important" }}
        >
          <Typography className="accordianHeading supportTypeText">
            When to Replace Filter
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Text is missing</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion onChange={() => setExpandDealer(!expandDealer)}>
        <AccordionSummary
          //   expandIcon={
          //     expandDealer ? (
          //       <KeyboardArrowUpIcon style={{ color: "#49B8EF" }} />
          //     ) : (
          //       <KeyboardArrowRight style={{ color: "#49B8EF" }} />
          //     )
          //   }
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ border: "none !important" }}
        >
          <Typography className="accordianHeading supportTypeText">
            How to Clean Filter
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Text is missing</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion onChange={() => setExpandSerivcePerson(!expandSerivcePerson)}>
        <AccordionSummary
          //   expandIcon={
          //     expandSerivcePerson ? (
          //       <KeyboardArrowUpIcon style={{ color: "#49B8EF" }} />
          //     ) : (
          //       <KeyboardArrowRight style={{ color: "#49B8EF" }} />
          //     )
          //   }
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ border: "none !important" }}
        >
          <Typography
            className="accordianHeading"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <span className="needHelp"> Still need help ? </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Text is missing</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion onChange={() => setExpandDealer(!expandDealer)}>
        <AccordionSummary
          //   expandIcon={
          //     expandDealer ? (
          //       <KeyboardArrowUpIcon style={{ color: "#49B8EF" }} />
          //     ) : (
          //       <KeyboardArrowRight style={{ color: "#49B8EF" }} />
          //     )
          //   }
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ border: "none !important" }}
        >
          <Typography className="accordianHeading supportTypeText">
            Contact Aquatic AV Support
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Text is missing</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
