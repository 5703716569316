import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { bindActionCreators } from "redux";
import {
  AppBtn,
  AppModal,
  Header,
  Loader,
} from "../../components/common/index";
import {
  Carosole,
  Feature1,
  Feature2,
  Headphone,
  ListPlus,
  SearchBlue,
  SliderContent,
} from "../../resources/images";
import { RoutesObj } from "../../routes";

import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProductsAction } from "../../store/Products/ProductsAction";

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

class Catalog extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      popularProducts: [],
      newArrivalsProducts: [],
      topSliderData: [],
      ProductCategory: [],
      selectedCategory: null,
      productByCategory: [],
    };
  }

  componentDidMount() {
   

    this.setState({
      isLoading: true,
    });
    this.onLoadCompleteData();
    this.props.ProductsAction.onGetNewArrivalsProducts().then((response) => {
      if (
        response !== null &&
        response.success === true &&
        response.data !== null
      ) {
        this.setState({
          newArrivalsProducts: response.data,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        // toast.error("Something went wrong, Please try again");
      }
    });

    this.props.ProductsAction.onGetPopularProducts().then((response) => {
      if (
        response !== null &&
        response.success === true &&
        response.data !== null
      ) {
        this.setState({
          popularProducts: response.data,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        // toast.error("Something went wrong, Please try again");
      }
    });

    this.props.ProductsAction.GetAllNewsRoomContentByRoomId().then(
      (response) => {
        if (
          response !== null &&
          response.success === true &&
          response.data !== null
        ) {
          this.setState({
            topSliderData: response.data,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          // toast.error("Something went wrong, Please try again");
        }
      }
    );

    this.props.ProductsAction.GetProductCategory().then((response) => {
      if (response !== null && response.success === true) {
        this.setState({
          ProductCategory: response.data,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error("Something went wrong, Please try again");
      }
    });
  }
  async onLoadCompleteData(showMsg, msgString) {
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    await this.props.actions
      .onGetMyEnvironment(
        userId,
        JSON.parse(localStorage.getItem("userInfo")).customerType === 3
          ? JSON.parse(localStorage.getItem("userInfo")).companyId
          : false
      )
      .then(async (env) => {
        {console.log("env",env)}
        if (env.status === 200 && env.data.success) {
          let reqObj = {
            type: "type",
            userId: userId,
          };

          await this.props.actions
            .onfetchNotification(reqObj)
            .then((res) => {});
          this.setState({ isLoading: false });
        }
      });

    await this.onGetDashboardDataView();
  }

  geProductsByCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });

    this.props.ProductsAction.GetProductsByProductCategory(value.id).then(
      (response) => {
        if (response !== null && response.success === true) {
          this.setState({
            productByCategory: response.data[0],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          toast.error("Something went wrong, Please try again");
        }
      }
    );
  };

  render() {
    const settings = {
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { devicesInfo, userInfo, userEnv } = this.props.state;
    const { isLoading } = this.state;
    const { history } = this.props;
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    // let currentEnv = {};
    // try {
    //   currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
    // } catch (error) {
    //   console.log(error);
    // }
    return (
      <div className="Dashboardmain-container">
        {isLoading && <Loader />}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userData ? userData : null}
            userEnvoirnment={userEnv}
            history={history}
          />

          <div className="mainTitle align-items-center">
            <span>
              {" "}
              Aquatic AV Catalog
              <span className="mainTitle__breadCrum">
                Catalog <i></i> Home
              </span>
            </span>
            <div className="d-flex">
              <button
                onClick={() => {
                  history.push({
                    pathname: RoutesObj.PriceList.path,
                  });
                }}
                className="view-price"
              >
                <img src={ListPlus} />
                View Price Lists
              </button>
              <div className="d-flex align-items-center input-search">
                <img src={SearchBlue} />
                <input placeholder="Search Catalog"></input>
              </div>
            </div>
            {/* <div className="deviceBtn">
              <AppBtn
                text="Devices"
                onClick={() => history.push(RoutesObj.MyAccount.path)}
              />
            </div> */}
          </div>
        </div>

        <div className="nav-btn pt-4">
          <div className="container">
            <div className="row justify-content-around">
              {this.state.ProductCategory.map((v, i) => {
                return (
                  <button
                    // onClick={() => this.geProductsByCategory(v)}
                    className={`btn1 ${
                      this.state.selectedCategory?.id === v.id ? "tab-view" : ""
                    }`}
                  >
                    {v.value}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="caro-slider">
          <div className="container-fluid mb-0">
            <Carousel variant="dark" className="carou">
              {this.state.topSliderData.map((v, i) => (
                <Carousel.Item>
                  <div style={{ width: "100%", background: "#fff" }}>
                    <img
                      src={v.bannerImageUrl}
                      alt="First slide"
                      className="back-img"
                    />
                    <div className="parallelogramContainer">
                      <h5>{v.slideTitle}</h5>
                      <p>{v.slideSubTitle}</p>
                      <img
                        className="parallelogram"
                        src={SliderContent}
                        alt="First slide continer"
                      />
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="feature-spot mt-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="feature1"
                  style={{
                    backgroundImage: `url(${Feature1})`,
                  }}
                >
                  <h4>Feature Spot #1 Title</h4>
                  <h6>Feature Spot #1 Subtitle</h6>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="feature2"
                  style={{
                    backgroundImage: `url(${Feature2})`,
                  }}
                >
                  <h4>Feature Spot #1 Title</h4>
                  <h6>Feature Spot #1 Subtitle</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.productByCategory.length ? (
          <div className="card-listings">
            <div className="container">
              <div className="row">
                {this.state.productByCategory.map((v, i) => {
                  return (
                    <div className="col-md-3 col-sm-6 col-12">
                      <Card
                        key={i}
                        onClick={() => {
                          history.push({
                            pathname: RoutesObj.CatalogProduct.path,
                            state: { productId: v.id },
                          });
                        }}
                        style={{ marginBottom: 20 }}
                      >
                        <Card.Img
                          variant="top"
                          src={v.featureImage ? v.featureImage : Headphone}
                        />
                        <Card.Body>
                          <Card.Title>{v.productName}</Card.Title>
                          <Card.Text>${v.price}</Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="arrivals pt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="text-center pb-4">New Arrivals</h3>
                  </div>
                </div>
                {this.state.newArrivalsProducts.length ? (
                  <Slider {...settings}>
                    {this.state.newArrivalsProducts.map((v, i) => (
                      <Card
                        key={i}
                        onClick={() => {
                          history.push({
                            pathname: RoutesObj.CatalogProduct.path,
                            state: { productId: v.id },
                          });
                        }}
                        style={{ width: "18rem" }}
                      >
                        <Card.Img
                          variant="top"
                          src={v.featureImage ? v.featureImage : Headphone}
                        />
                        <Card.Body>
                          <Card.Title>{v.productName}</Card.Title>
                          <Card.Text>${v.price}</Card.Text>
                        </Card.Body>
                      </Card>
                    ))}
                  </Slider>
                ) : (
                  <h4 className="no-item">No Products Found</h4>
                )}
              </div>
            </div>

            <div className="arrivals pt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="text-center pb-4">Popular Product</h3>
                  </div>
                </div>

                {this.state.popularProducts.length ? (
                  <Slider {...settings}>
                    {this.state.popularProducts.map((v, i) => (
                      <Card
                        key={i}
                        onClick={() => {
                          history.push({
                            pathname: RoutesObj.CatalogProduct.path,
                            state: { productId: v.id },
                          });
                        }}
                        style={{ width: "18rem" }}
                      >
                        <Card.Img
                          variant="top"
                          src={v.featureImage ? v.featureImage : Headphone}
                        />
                        <Card.Body>
                          <Card.Title>{v.productName}</Card.Title>
                          <Card.Text>${v.price}</Card.Text>
                        </Card.Body>
                      </Card>
                    ))}
                  </Slider>
                ) : (
                  <h4 className="no-item">No Products Found</h4>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.DashboardReducer,
  }),
  (dispatch) => ({
    ProductsAction: bindActionCreators(ProductsAction, dispatch),
    actions: bindActionCreators(dashboardActions, dispatch),

  })
)(withRouter(Catalog));
