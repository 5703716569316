import React, { Component } from "react";

import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import DataLoader from "../../components/common/DataLoader";
import {
  AutoComplete,
  GoogleMap,
  Header,
  Marker,
} from "../../components/common/index";
import LocationIcon from "../../resources/images/LocationPin.svg";
import BlockIcon from "../../resources/images/blockicon.svg";
import Clock from "../../resources/images/clock.svg";
import {
  backArrowActiveIcon,
  emailIcon,
  locationPin,
  phoneIcon,
  tagIcon,
  webIcon,
} from "../../resources/images/index";
import WebsiteIcon from "../../resources/images/websiteicon.svg";
import { RoutesObj } from "../../routes";
import { dealerLocatorActions } from "../../store/dealerLocator/dealerLocatorActions";
import DealerLocatorModals from "./DealerLocatorModals";
import DirectionsRenderer from "./DirectionRenderer";
class DealerLocator extends Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this.state = {
      searchValue: "",
      showFilterForm: false,
      showFilterTab: false,
      activeFilters: [],
      places: [],
      distances: [],
      dealers: [],
      allDealerLocator: [],
      products: [],
      authDealers: [],
      dNBLDealers: [],
      mycenter: { lat: "", long: "" },
      showMarkerModal: false,
      showFindDealer: false,
      showAuthorizedDealerModal: false,
      showBlockDealers: false,
      showProfiles: false,
      expandDealerData: false,
      selectedItemIndex: "",
      noDealerLocator: "",
      loadingDealerData: true,
      userLatitude: "",
      targetCustomerCords: "",
      userLongitude: "",
      directions: "",
      dealorProfileData: "",
      selctedCardDealorProfileData: "",
      userDestination: { lat: "", lng: "" },
      selectCard: false,

      // activeFiltersTab:false
    };
  }

  // Handle To Set Dealers Data in State
  handleAllDealers = (dealers) => {
    this.setState({ allDealerLocator: dealers });
  };

  // To manage Loader while fetching dealers data
  handleDealerLoader = (value) => {
    this.setState({ loadingDealerData: value });
  };

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        // Extract latitude and longitude from the position object
        var userLat = position.coords.latitude;
        var userLng = position.coords.longitude;

        localStorage.setItem("locationLat", userLat);
        localStorage.setItem("locationLong", userLng);
        // sourcelat = userLat;
        // sourcelong = userLng;

        // Update the map center with the user's current location
        //   map.setCenter({ lat: userLat, lng: userLng });
      },
      function () {
        // If geolocation fails, show an alert message
        alert("Please allow location to get directions!");
      }
    );

    const lng = localStorage.getItem("locationLong");
    const lat = localStorage.getItem("locationLat");

    const userLongitude = parseFloat(lng);
    const userLatitude = parseFloat(lat);

    // Check if the parsed values are valid numbers before setting the state
    this.setState({
      userLongitude: !isNaN(userLongitude) ? userLongitude : null,
      userLatitude: !isNaN(userLatitude) ? userLatitude : null,
    });
    this.setState({
      mycenter: { lat: userLatitude, long: userLongitude },
    });
    this.props.actions.getDealersFilter().then((filterData) => {
      if (filterData?.success) {
        let distances = [],
          dealers = [],
          products = [];
        if (filterData.data.milesdata?.length) {
          distances = filterData.data.milesdata.map((distance) => {
            if (distance === "0")
              return { label: "Any Distance", value: distance };
            else return { label: `${distance.value} miles`, value: distance };
          });
        }
        // distances.push({ label: "1000 miles", value: 1000 });
        if (filterData.data.dealertypedata?.length) {
          let uniq = {};
          dealers = filterData.data.dealertypedata.map((dealer) => {
            return { label: dealer.type, value: dealer.type };
          });
          dealers = dealers.filter(
            (obj) => !uniq[obj.value] && (uniq[obj.value] = true)
          );
        }
        if (filterData.data.envdata?.length) {
          let uniq = {};
          products = filterData.data.envdata.map((product) => {
            return { label: product.value, value: +product.key };
          });
          products = products.filter(
            (obj) => !uniq[obj.value] && (uniq[obj.value] = true)
          );
        }
        this.setState({
          distances,
          dealers,
          products,
          mycenter: {
            lat: this.props.state.sourceLat,
            long: this.props.state.sourceLong,
          },
        });
      } else {
        toast.error("Something went wrong please check");
        this.props.history.push(RoutesObj.Dashboard.path);
      }
    });
    this.props.actions.onChange("distance", 0);
  }

  addPlace = (place) => {
    this.setState({ places: [place] });
  };

  openMarkerModal = (place) => {
    this.setState({
      showMarkerModal: true,
      // showProfiles: true,
      dealorProfileData: place,
      // expandedDealerIndex:"",
    });
    const google = window.google;

    const directionsService = new google.maps.DirectionsService();

    const origin = {
      lat: this.state.userLatitude,
      lng: this.state.userLongitude,
    };

    this.setState({
      userDestination: {
        lat: this.state.selectCard ? this.state.mycenter.lat : place?.latitude,
        lng: this.state.selectCard
          ? this.state.mycenter.long
          : place?.longitude,
      },
    });
    const destination = {
      lat: this.state.selectCard ? this.state.mycenter.lat : place?.latitude,
      lng: this.state.selectCard ? this.state.mycenter.long : place?.longitude,
    };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK") {
          this.setState({
            directions: result,
            selectCard: false,
          });
        } else {
          toast.error("Error fetching direction");
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };
  openShowFindDealer = (e) => {
    this.setState({ showFindDealer: true });
    this.setState({ showMarkerModal: false });
    e.stopPropagation();
  };
  openAuthorizedDealerModal = () => {
    this.props.actions.getAuthorizedDealers().then((res) => {
      this.setState({ authDealers: res.data.data });
    });
    this.setState({ showAuthorizedDealerModal: true });
  };
  openBlockDealerModal = () => {
    this.props.actions.getDNBLDealers().then((res) => {
      this.setState({ dNBLDealers: res.data.data });
    });
    this.setState({ showBlockDealers: true });
    this.setState({ showAuthorizedDealerModal: false });
    this.setState({ showFindDealer: false });
  };
  moveCenter = (lat, long) => {
    this.setState({ mycenter: { lat, long } });
  };

  applyFilters = async (
    searchBy,
    sourceLat,
    sourceLong,
    destinationLat,
    desitnationLong,
    dealerType,
    product,
    distance
  ) => {
    this.handleDealerLoader(true);
    this.setState({ expandedDealerIndex: null });
    const resultDealers = await this.props.actions.getDealersSuggestion(
      searchBy,
      sourceLat,
      sourceLong,
      destinationLat,
      desitnationLong,
      dealerType,
      product,
      distance
    );
    this.setState({ allDealerLocator: resultDealers?.data?.data });
    this.handleDealerLoader(false);

    this.setState({ showFilterTab: false });

    // this.setState({ activeFilters: true });
    this.setState({ showFilterForm: false });
  };

  onFilterValueChange = (filterType, value) => {
    const { dealerType, product } = this.props.state;
    var newStateArray = [];
    var index = -1;
    if (filterType === "distance") {
      this.props.actions.onChange("distance", value);
    } else if (filterType === "dealers") {
      if (value !== "all") {
        newStateArray = dealerType.slice();
        index = newStateArray.indexOf(value);
        if (index === -1) {
          newStateArray.push(value);
        } else {
          newStateArray.splice(index, 1);
        }
      }
      this.props.actions.onChange("dealerType", newStateArray);
    } else if (filterType === "products") {
      if (value !== "all") {
        newStateArray = product.slice();
        index = newStateArray.indexOf(value);
        if (index === -1) {
          newStateArray.push(value);
        } else {
          newStateArray.splice(index, 1);
        }
      }
      this.props.actions.onChange("product", newStateArray);
    }
  };

  removeFilterItem = (filterType, value) => {
    const {
      searchBy,
      sourceLat,
      sourceLong,
      destinationLat,
      desitnationLong,
      dealerType,
      product,
      distance,
    } = this.props.state;
    var index = -1;
    var newStateArray = [];
    if (filterType === "distance") {
      this.props.actions.onChange("distance", 0);
    } else if (filterType === "dealers") {
      newStateArray = dealerType.slice();
      index = newStateArray.indexOf(value);
      if (index !== -1) {
        newStateArray.splice(index, 1);
        this.props.actions.onChange("dealerType", newStateArray);
      }
    } else if (filterType === "products") {
      newStateArray = product.slice();
      index = newStateArray.indexOf(value);
      if (index !== -1) {
        newStateArray.splice(index, 1);
        this.props.actions.onChange("product", newStateArray);
      }
    }
    this.props.actions.getDealersSuggestion(
      searchBy,
      sourceLat,
      sourceLong,
      destinationLat,
      desitnationLong,
      dealerType,
      product,
      distance
    );
  };

  clearAllFields = () => {
    const { dealerLocatorData } = this.props.state;
    console.log("check places:", dealerLocatorData);
    // this.props.actions.onChange("sourceLat", 0);
    // this.props.actions.onChange("sourceLong", 0);
    // this.props.actions.onChange("destinationLat", 0);
    // this.props.actions.onChange("desitnationLong", 0);
    this.props.actions.onChange("dealerType", []);
    this.props.actions.onChange("product", []);
    this.props.actions.onChange("distance", 0);
    this.props.actions.onChange("searchBy", "");

    this.setState({
      mycenter: {
        lat: this.props.state.sourceLat,
        long: this.props.state.sourceLong,
      },
      showProfiles: false,
    });
  };

  makeShowProfileTrue = () => {
    this.setState({
      showProfiles: true,
    });
  };

  makeShowProfileFalse = () => {
    this.setState({
      showProfiles: false,
    });
  };
  handleExpand = (index) => {
    this.setState((prevState) => ({
      expandedDealerIndex:
        prevState.expandedDealerIndex === index ? null : index,
    }));
  };
  toggleSubNav = () => {
    this.setState((prevState) => ({ showSubMenu: !prevState.showSubMenu }));
  };
  removeSubNav = () => {
    this.setState({ showSubMenu: false });
  };

  render() {
    const { userEnv, userInfo } = this.props.dashboardState;
    const { history } = this.props;
    const {
      searchBy,
      sourceLat,
      sourceLong,
      // destinationLat,
      // desitnationLong,
      dealerType,
      product,
      distance,
      dealerLocatorData,
    } = this.props.state;

    let searchInput = this.state.searchValue;
    if (searchBy && searchBy !== "") {
      searchInput = `${searchBy}`;
    }
    let dealerLocatorFilterData = [];
    let places = [];
    if (
      dealerLocatorData &&
      dealerLocatorData.length > 0 &&
      this.state.showProfiles
    ) {
      dealerLocatorFilterData = dealerLocatorData;
      dealerLocatorData.map((item, index) => {
        if (item.latitude !== 0 && item.longitude !== 0) {
          places.push({
            name: item.name,
            lat: item.latitude,
            long: item.longitude,
            companyBusinessHours: item.companyBusinessHours,
            email: item.email,
            fullAddress: item.fullAddress,
            image: item.image,
            miles: item.miles,
            phone: item.phone,
            tags: item.tags,
            type: item.type,
            website: item.website,
          });
        }
      });
    }
    const {
      showFilterForm,
      activeFilters,
      mapApiLoaded,
      mapInstance,
      mapApi,
      distances,
      dealers,
      products,
      mycenter,
      allDealerLocator,
      showFilterTab,
      userLatitude,
      userLongitude,
      showMarkerModal,
      directions,
      dealorProfileData,
      userDestination,
      selctedCardDealorProfileData,
    } = this.state;

    const defaultOpen = [+sourceLat, +sourceLong];

    const filteredProducts = products.filter(
      (prd) => product.indexOf(prd.value) > -1
    );

    return (
      <div className="Dashboardmain-container_dealerLocator">
        <div className="Dashboardmain-container__header" id="dealerList">
          <Header
            isDashboardShow={true}
            history={history}
            userEnvoirnment={userEnv}
            userInformation={userInfo ? userInfo : null}
            toggleSubNav={this.toggleSubNav}
            removeSubNav={this.removeSubNav}
          />
          {/* <div className="mainTitle">
            <span> Dealer Locator</span>
          </div> */}
        </div>
        <div className="DealerLocator-container__innerBody">
          {showFilterForm ? (
            <div
              className="DealerLocator-container__searchbar "
              style={{ paddingLeft: "15px" }}
            >
              <span className="filterWrapper__filterTopWrapper--inner">
                <img
                  className="icon"
                  src={backArrowActiveIcon}
                  alt=""
                  onClick={() => this.setState({ showFilterForm: false })}
                />
                <span className="heading">Filter By</span>
                <span
                  className="clearBtn"
                  onClick={() => this.clearAllFields()}
                >
                  Clear
                </span>
              </span>
              <span className="filterWrapper__filterFormWrapper--inner">
                {distances && distances.length > 0 ? (
                  <span className="filterCategoryContainer">
                    <hr />
                    <span className="title">Distance</span>
                    {distances &&
                      distances.length > 0 &&
                      distances.map((item, index) => {
                        return (
                          <span key={index} className="item">
                            <label>{item.label}</label>
                            <input
                              type="radio"
                              name="distance"
                              className="radio-input"
                              value={item.value.key}
                              checked={distance === parseInt(item.value.key)}
                              onChange={() =>
                                this.onFilterValueChange(
                                  "distance",
                                  parseInt(item.value.key)
                                )
                              }
                            />
                          </span>
                        );
                      })}
                  </span>
                ) : null}
                {dealers && dealers.length > 0 ? (
                  <span className="filterCategoryContainer">
                    <hr />
                    <span className="title">Dealer</span>
                    {/* <span className="item">
                      <label>All dealers</label>
                      <input
                        type="checkbox"
                        name="dealers"
                        className="checkbox-input"
                        value="all"
                        checked={dealerType.length === 0}
                        onChange={() =>
                          this.onFilterValueChange("dealers", "all")
                        }
                      />
                    </span> */}
                    {dealers &&
                      dealers.length > 0 &&
                      dealers.map((item, index) => {
                        return (
                          <span key={index} className="item">
                            <label>{item.label}</label>
                            <input
                              type="checkbox"
                              name="dealers"
                              className="checkbox-input"
                              value={item.value}
                              checked={
                                dealerType[dealerType.indexOf(item.value)] ===
                                item.value
                              }
                              onChange={() =>
                                this.onFilterValueChange("dealers", item.value)
                              }
                            />
                          </span>
                        );
                      })}
                  </span>
                ) : null}
                <h
                  className="authorized_dealers"
                  onClick={this.openAuthorizedDealerModal}
                >
                  View Authorized Online Resellers
                </h>
                {products && products.length > 0 ? (
                  <span className="filterCategoryContainer prodCarriedEnv">
                    <hr />
                    <span className="title">Products Carried/Environments</span>
                    {/* <span className="item">
                      <label>All Products</label>
                      <input
                        type="checkbox"
                        name="products"
                        className="checkbox-input"
                        value="all"
                        checked={product.length === 0}
                        onChange={() =>
                          this.onFilterValueChange("products", "all")
                        }
                      />
                    </span> */}
                    {products &&
                      products.length > 0 &&
                      products.map((item, index) => {
                        return (
                          <span key={index} className="item">
                            <label>{item.label}</label>
                            <input
                              type="checkbox"
                              name="products"
                              className="checkbox-input"
                              value={item.value}
                              checked={
                                product[product.indexOf(item.value)] ===
                                item.value
                              }
                              onChange={() =>
                                this.onFilterValueChange("products", item.value)
                              }
                            />
                          </span>
                        );
                      })}
                  </span>
                ) : null} 
                <span className="filterWrapper__filterFormBtnWrapper--inner">
                <button
                  type="button"
                  onClick={(e) => {
                    console.log("Click event:", e);
                    this.applyFilters(
                      searchBy,
                      userLatitude,
                      userLongitude,
                      // destinationLat,
                      // desitnationLong,
                      dealerType,
                      product,
                      distance
                    );
                  }}
                  onTouchStart={(e) => {
                    console.log("Touch event:", e);
                    this.applyFilters(
                      searchBy,
                      userLatitude,
                      userLongitude,
                      // destinationLat,
                      // desitnationLong,
                      dealerType,
                      product,
                      distance
                    );
                  }}
                  className="filterby-btn"
                >
                  Apply Filters
                </button>
              </span>
              </span>
             
            </div>
          ) : (
            <div
              style={{ height: searchInput ? null : 140 }}
              className="DealerLocator-container__searchbar"
            >
              <span
                className="searchWrapper__searchFormWrapper--inner"
                style={{ display: "block" }}
              >
                <div
                  style={{
                    padding: "8px 10px 8px 15px",
                    position: "sticky",
                    top: "0px",
                    zIndex: "5",
                    background: "#fff",
                  }}
                >
                  {showFilterTab ? (
                    <label
                      className="form-label"
                      onClick={() => {
                        this.clearAllFields();
                        this.setState({ showFilterTab: false });
                        // this.setState({ searchValue: "fai" })
                        this.applyFilters(
                          searchBy,
                          sourceLat,
                          sourceLong,
                          // destinationLat,
                          // desitnationLong,
                          dealerType,
                          product,
                          distance
                        );
                      }}
                      style={{ display: "flex", gap: 5 }}
                    >
                      <span>Dealer List</span>
                      <ArrowForward />
                    </label>
                  ) : (
                    <label
                      className="form-label"
                      onClick={() => {
                        this.clearAllFields();
                        this.setState({ showFilterTab: true, directions: "" });
                      }}
                      style={{ display: "flex", gap: 5 }}
                    >
                      <ArrowBack />
                      <span>Back to Filter</span>
                    </label>
                  )}
                  <label
                    className="form-label"
                    onClick={() => this.clearAllFields()}
                  >
                    Dealer Locator
                  </label>
                  <AutoComplete
                    searchInput={searchInput}
                    inputColor
                    handleAllDealers={this.handleAllDealers}
                    handleDealerLoader={this.handleDealerLoader}
                    addplace={this.addPlace}
                    makeShowProfileTrue={this.makeShowProfileTrue}
                    makeShowProfileFalse={this.makeShowProfileFalse}
                    clearAllFields={this.clearAllFields}
                  />
                </div>
                {showFilterTab ? (
                  <>
                    <span
                      className="searchWrapper__filterByBtnWrapper--inner"
                      style={{ paddingLeft: "15px" }}
                    >
                      <button
                        type="button"
                        onClick={() => this.setState({ showFilterForm: true })}
                        className="filterby-btn"
                      >
                        FILTER BY
                      </button>
                    </span>
                    {/* <span className="searchWrapper__filterByBtnWrapper--inner">
                    <button
                      type="button"
                      onClick={() => this.clearAllFields()}
                      className="filterby-btn"
                    >
                      Clear
                    </button>
                  </span> */}
                  </>
                ) : null}
                {this.state.loadingDealerData ? (
                  <DataLoader />
                ) : (
                  <div style={{ paddingLeft: "15px" }}>
                    <div style={{ overflowY: "scroll", maxHeight: "58vh" }}>
                      {!showFilterTab &&
                      allDealerLocator &&
                      allDealerLocator?.length > 0
                        ? allDealerLocator?.map((item, index) => (
                            <a
                              href="#mapContainer"
                              style={{ textDecoration: "none" }}
                            >
                              <div
                                className={`${
                                  this.state.expandedDealerIndex === item?.id &&
                                  "dealerListExpanded"
                                } dealer-card`}
                                key={item?.id}
                              >
                                <div
                                  className="small-card"
                                  onClick={() => {
                                    this.setState((prevState) => ({
                                      expandedDealerIndex: item.id,
                                    }));
                                    this.setState({
                                      mycenter: {
                                        lat: item.latitude,
                                        long: item.longitude,
                                      },
                                      selectCard: true,
                                      directions: "",
                                      selctedCardDealorProfileData: item,
                                    });
                                  }}
                                >
                                  <h3>Name: {item?.name}</h3>
                                  <p>Address: {item?.fullAddress}</p>
                                  {this.state.expandedDealerIndex ===
                                    item?.id && (
                                    <div className="expanded-details">
                                      <p>
                                        Website:{" "}
                                        <a href="website">{item?.website}</a>
                                      </p>
                                      <p>Phone Number: {item?.phone}</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </a>
                          ))
                        : !showFilterTab && (
                            <div className="text-center">
                              <p>No data found</p>
                            </div>
                          )}
                    </div>
                  </div>
                )}
              </span>
              {showFilterForm ? (
                <div>
                  <span className="searchWrapper__filterByBtnWrapper--inner">
                    <button
                      type="button"
                      onClick={() => this.setState({ showFilterForm: true })}
                      className="filterby-btn"
                      style={{ paddingLeft: "15px" }}
                    >
                      FILTER BY
                    </button>
                  </span>
                  <span className="searchWrapper__filterByBtnWrapper--inner">
                    <button
                      type="button"
                      onClick={() => this.clearAllFields()}
                      className="filterby-btn"
                    >
                      Clear
                    </button>
                  </span>
                </div>
              ) : null}
              {activeFilters ? (
                <span className="searchWrapper__activeFiltersWrapper--inner">
                  {/* {distance > 0 ? (
                    <span className="filterContainer">
                      {distance}
                      {" miles "}
                      <img
                        className="icon"
                        src={closeIcon}
                        alt=""
                        onClick={() =>
                          this.removeFilterItem("distance", distance)
                        }
                      />
                    </span>
                  ) : null} */}

                  {/* {dealerType &&
                    dealerType.length > 0 &&
                    dealerType.map((item, index) => {
                      return (
                        <span key={index} className="filterContainer">
                          {item}
                          {" dealers "}
                          <img
                            className="icon"
                            src={closeIcon}
                            alt=""
                            onClick={() =>
                              this.removeFilterItem("dealers", item)
                            }
                          />
                        </span>
                      );
                    })} */}

                  {/* {filteredProducts &&
                    filteredProducts.length > 0 &&
                    filteredProducts.map((item, index) => {
                      return (
                        <span key={index} className="filterContainer">
                          {item.label}{" "}
                          <img
                            className="icon"
                            src={closeIcon}
                            alt=""
                            onClick={() =>
                              this.removeFilterItem("products", item.value)
                            }
                          />
                        </span>
                      );
                    })} */}
                </span>
              ) : null}
              {/* 
              {this.state.showProfiles ? (
                <div className="searchWrapper__searchDataWrapper--inner">
                  {dealerLocatorFilterData &&
                    dealerLocatorFilterData.length > 0 &&
                    dealerLocatorFilterData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="searchBox"
                          onClick={(e) => {
                            e.preventDefault();
                            this.moveCenter(item.latitude, item.longitude);
                          }}
                        >
                          <hr />
                          <div className="leftSection">
                            <img src={locationPin} alt="" />
                          </div>
                          <div className="rightSection">
                            {item.name !== "" && item.name !== null ? (
                              <span className="title">{item.name}</span>
                            ) : null}
                            {item.type !== "" && item.type !== null ? (
                              <span
                                className="dealer"
                                onClick={this.openShowFindDealer}
                              >
                                {item.type} Dealer
                              </span>
                            ) : null}
                            {item.miles > 0 ? (
                              <span className="distance">
                                {item.miles} mile away
                              </span>
                            ) : null}
                            ' '
                            {item.fullAddress !== "" &&
                            item.fullAddress !== null ? (
                              <span className="address">
                                {item.fullAddress}
                              </span>
                            ) : null}
                            <div className="phone">
                              <div>
                                <img alt="" src={Clock}></img>
                              </div>

                              <div className="business_hours">
                                {item?.companyBusinessHours ? (
                                  <>
                                    {item?.companyBusinessHours?.map((item) => {
                                      return (
                                        <>
                                          <h className="dealer_timing">
                                            {`${Object.keys(item)[0]}:${
                                              Object.values(item)[0]
                                            }`}
                                          </h>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <h className="dealer_timing"></h>
                                  </>
                                )}
                              </div>
                            </div>
                            <p>{item?.companyBusinessHours[1]}</p>
                            {item.phone !== "" && item.phone !== null ? (
                              <span className="phone">
                                <img className="icon" src={phoneIcon} alt="" />{" "}
                                {item.phone}
                              </span>
                            ) : null}
                            {item.email !== "" && item.email !== null ? (
                              <span className="email">
                                <img className="icon" src={emailIcon} alt="" />{" "}
                                {item.email}
                              </span>
                            ) : null}
                            {item.tags !== "" && item.tags !== null ? (
                              <span className="tags">
                                <img className="icon" src={tagIcon} alt="" />{" "}
                                <p className="overflowtext">{item.tags}</p>
                              </span>
                            ) : null}
                            {item.website !== "" && item.website !== null ? (
                              <span className="web">
                                <img className="icon" src={webIcon} alt="" />{" "}
                                <p className="overflowtext">{item.website}</p>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : null} */}
            </div>
          )}
          <span id="mapContainer" style={{ position: "relative" }}>
            <span className="backtoTop">
              <a href="#dealerList">Back to Dealers List</a>
            </span>

            {directions !== "" ? (
              <DirectionsRenderer directions={directions} />
            ) : (
              <GoogleMap
                defaultZoom={12}
                defaultOpen={defaultOpen}
                address={mycenter}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_MAP_KEY2,
                  libraries: ["places", "geometry"],
                }}
              >
                {userLatitude && userLongitude && (
                  <Marker
                    markerColor="#1bbed0"
                    // onClick={this.openMarkerModal}
                    // key={index}
                    title={"My Location"}
                    text={"my location"}
                    lat={userLatitude}
                    lng={userLongitude}
                  />
                )}
                {mycenter.lat &&
                  mycenter.long &&
                  this.state.expandedDealerIndex && (
                    <Marker
                      onClick={this.openMarkerModal}
                      // key={index}
                      // title={place.name}
                      // text={place.name}
                      lat={mycenter.lat}
                      lng={mycenter.long}
                    />
                  )}
                {
                  // Object.keys(allDealerLocator).length > 0 &&
                  !this.state.expandedDealerIndex &&
                    allDealerLocator?.map((place, index) => (
                      <Marker
                        onClick={() => this.openMarkerModal(place)}
                        key={index}
                        // title={place.name}
                        // text={place.name}
                        lat={place.latitude}
                        lng={place.longitude}
                      />
                    ))
                }
                {/* <Polyline path={directions} strokeColor="#FF0000" strokeOpacity={0.8} strokeWeight={2} /> */}
              </GoogleMap>
            )}
          </span>
        </div>

        {showMarkerModal && (
          <div>
            <DealerLocatorModals
              handleclose={() => this.setState({ showMarkerModal: false })}
              markerModal
            >
              <div className="dealer_locator_content">
                <div>
                  <img alt="" src={LocationIcon}></img>
                  <h8 className="dealer_information">
                    {dealorProfileData?.name
                      ? dealorProfileData?.name
                      : selctedCardDealorProfileData?.name}
                  </h8>
                </div>

                <div className="dealer_locator_subcontent">
                  {/* <h8 className="dealer_type" onClick={this.openShowFindDealer}>
                    {dealorProfileData?.type
                      ? dealorProfileData?.type
                      : selctedCardDealorProfileData?.type}
                  </h8> */}
                  <h8 className="dealer_distance">
                    {dealorProfileData?.miles
                      ? `${Number(dealorProfileData?.miles.toFixed(3))} `
                      : `${Number(
                          selctedCardDealorProfileData?.miles.toFixed(3)
                        )} `}
                    miles away
                  </h8>
                  <h8 className="dealer_address">
                    {dealorProfileData?.fullAddress
                      ? dealorProfileData?.fullAddress
                      : selctedCardDealorProfileData?.fullAddress}
                  </h8>
                  <div className="dealerContent">
                    {/* <img alt="call" src={}></img> */}
                    <h8 className="dealer_contact">
                      {dealorProfileData?.phone
                        ? dealorProfileData?.phone
                        : selctedCardDealorProfileData?.phone}
                    </h8>
                  </div>
                  <div className="dealerContent">
                    {/* <img alt="emailIcon" src={EmailIcon}></img> */}
                    <h8 className="dealer_email">
                      {dealorProfileData?.email
                        ? dealorProfileData?.email
                        : selctedCardDealorProfileData?.email}
                    </h8>
                  </div>
                  <button>
                    <a
                      class="navigationButton"
                      href={`https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${userDestination.lat},${userDestination.lng}&travelmode=driving`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Start Navigation
                    </a>
                  </button>
                </div>
                {/* <div className="business_hours_container">
                  <img alt="clock" src={Clock}></img>
                  <div className="business_hours">
                    {dealorProfileData?.companyBusinessHours ? (
                      <>
                        {dealorProfileData?.companyBusinessHours?.map(
                          (item) => {
                            return (
                              <>
                                <h className="dealer_timing">
                                  {`${Object.keys(item)[0]}:${
                                    Object.values(item)[0]
                                  }`}
                                </h>
                              </>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="dealerContent">
                  <img alt="call" src={Call}></img>
                  <h8 className="dealer_contact">
                    {dealorProfileData?.phone ? dealorProfileData?.phone : ""}
                  </h8>
                </div>
                <div className="dealerContent">
                  <img alt="emailIcon" src={EmailIcon}></img>
                  <h8 className="dealer_email">
                    {dealorProfileData?.email ? dealorProfileData?.email : ""}
                  </h8>
                </div>
                <div className="dealerContent">
                  <img alt="tag" src={Tag}></img>
                  <h8 className="dealer_tag">
                    {dealorProfileData?.tags ? dealorProfileData?.tags : ""}
                  </h8>
                </div>
                <div className="dealerContent">
                  <img alt="webicon" src={WebIcon}></img>
                  <h8 className="dealer_website">
                    {dealorProfileData?.website
                      ? dealorProfileData?.website
                      : ""}
                  </h8>
                </div> */}
              </div>
            </DealerLocatorModals>
          </div>
        )}

        {/* <div>
            {console.log(places, "places")}
            {Object.keys(places).length >= 0 &&
              places.map((place, index) => (
                <DealerLocatorModals
                  handleclose={() => this.setState({ showMarkerModal: false })}
                  markerModal
                >
                  <div className="dealer_locator_content">
                    <div>
                      <img alt="" src={LocationIcon}></img>
                      <h8 className="dealer_information">
                        {place.name ? place.name : ""}
                      </h8>
                    </div>
                    <div className="dealer_locator_subcontent">
                      <h8
                        className="dealer_type"
                        onClick={this.openShowFindDealer}
                      >
                        {place.type ? place.type : ""}
                      </h8>
                      <h8 className="dealer_distance">
                        {place.miles} mile awayyyyy
                      </h8>
                      <h8 className="dealer_address">{place.fullAddress}</h8>
                    </div>
                    <div className="business_hours_container">
                      <img alt="clock" src={Clock}></img>
                      <div className="business_hours">
                        {place?.companyBusinessHours ? (
                          <>
                            {place?.companyBusinessHours?.map((item) => {
                              return (
                                <>
                                  <h className="dealer_timing">
                                    {`${Object.keys(item)[0]}:${
                                      Object.values(item)[0]
                                    }`}
                                  </h>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="dealerContent">
                      <img alt="call" src={Call}></img>
                      <h8 className="dealer_contact">
                        {place.phone ? place.phone : ""}
                      </h8>
                    </div>
                    <div className="dealerContent">
                      <img alt="emailIcon" src={EmailIcon}></img>
                      <h8 className="dealer_email">
                        {place.email ? place.email : ""}
                      </h8>
                    </div>
                    <div className="dealerContent">
                      <img alt="tag" src={Tag}></img>
                      <h8 className="dealer_tag">
                        {place.tags ? place.tags : ""}
                      </h8>
                    </div>
                    <div className="dealerContent">
                      <img alt="webicon" src={WebIcon}></img>
                      <h8 className="dealer_website">
                        {place.website ? place.website : ""}
                      </h8>
                    </div>
                  </div>
                </DealerLocatorModals>
              ))}
          </div> */}
        {this.state.showFindDealer && (
          <div>
            <DealerLocatorModals
              handleclose={() => this.setState({ showFindDealer: false })}
              findDealer
            >
              <div className="findDealer_container">
                <div className="findDealerContent">
                  <h className="findDealerContent_heading">Find a Dealer</h>
                  <h className="findDealerContent_detail">
                    Aquatic AV products are available for purchase from
                    authorized resellers around the world. Understand the
                    difference between Authorized Dealers and Elite Dealers to
                    find the best reseller for you:
                  </h>
                </div>
                <div className="findDealerContent">
                  <h className="findDealerContent_heading">
                    Aquatic AV ELITE Dealers
                  </h>
                  <h className="findDealerContent_detail">
                    Aquatic AV Elite Dealers carry the full Aquatic AV product
                    line and work directly with Aquatic AV to provide the best
                    buying experience. Elite Dealers sell Aquatic AV products
                    in-store as well as on the store’s website. Elite Dealers
                    are also authorized sellers on online marketplaces such as
                    Amazon/Ebay. Elite Dealers may also offer additional
                    services such as installation.
                  </h>
                </div>
                <div className="findDealerContent">
                  <h className="findDealerContent_heading">
                    Authorized Dealers
                  </h>
                  <h className="findDealerContent_detail">
                    Authorized Dealers carry certain Aquatic AV products but may
                    not carry the full Aquatic AV product line. Authorized
                    dealers sell Aquatic AV products in-store and online
                    exclusively via their store’s website and may also provide
                    additional services such as installation. Authorized Dealers
                    do not sell on Amazon/Ebay or other online marketplaces.
                  </h>
                </div>
                <div className="findDealerContent">
                  <h className="findDealerContent_heading">
                    Why purchase from an Authorized Reseller?
                  </h>
                  <h className="findDealerContent_detail">
                    In order to enjoy the best price, buying experience, product
                    support, and a valid warranty you must purchase from an
                    Aquatic AV authorized reseller.
                  </h>
                </div>
                <div
                  className="findDealerContent_"
                  onClick={this.openBlockDealerModal}
                >
                  <h className="findDealerContent_donot">
                    View Do not Buy List
                  </h>
                </div>
              </div>
            </DealerLocatorModals>
          </div>
        )}
        {this.state.showAuthorizedDealerModal && (
          <div>
            <DealerLocatorModals
              handleclose={() =>
                this.setState({ showAuthorizedDealerModal: false })
              }
              findDealer
            >
              <div className="dealer_upper_content">
                <h className="authorized_dealer_heading">
                  Authorized Online Dealers
                </h>
                <h className="authorized_dealer_detail">
                  Purchasing from an Aquatic AV authorized dealer or authorized
                  reseller is the best way to protect your purchase and ensure
                  your warranty remains valid.
                </h>
              </div>
              <div className="auth_dealer_main">
                {this.state.authDealers.map((dealerData) => {
                  return (
                    <div className="dealer_detail_content">
                      <h className="dealer_name">{dealerData.name}</h>
                      <div>
                        <img alt="" src={WebsiteIcon}></img>
                        <h className="dealer_website_name">
                          {dealerData.website
                            ? dealerData.website
                            : "No website found"}
                        </h>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="dealer_upper_content">
                <h className="authorized_dealer_heading">
                  Why purchase from an Authorized Reseller?
                </h>
                <h className="authorized_dealer_detail">
                  In order to enjoy the best price, buying experience, product
                  support, and a valid warranty you must purchase from an
                  Aquatic AV authorized reseller.
                </h>
              </div>
              <div
                className="findDealerContent_"
                onClick={this.openBlockDealerModal}
              >
                <h className="findDealerContent_donot">View Do not Buy List</h>
              </div>
            </DealerLocatorModals>
          </div>
        )}
        {this.state.showBlockDealers && (
          <DealerLocatorModals
            handleclose={() => this.setState({ showBlockDealers: false })}
            findDealer
          >
            <div className="block_dealer_container">
              <h className="block_dealer_heading">Do NOT Buy List</h>
              <h className="block_dealer_content">
                If you see our products for sale (including online and on
                third-party marketplaces likes Amazon, Ebay, Facebook, etc) by
                any of the resellers below, we advise you to NOT buy from them.
                The products offered by these sellers may not be genuine Aquatic
                AV products, blocked from Aquatic AV updates and support
                services, may not properly function, and are not under warranty.
                Protect your purchase by avoiding unauthorized sellers below. If
                you’ve purchased Aquatic AV products from these sellers, we’d
                advise you to return your products and purchase from an
                authorized reseller.
              </h>
            </div>
            <div className="auth_dealer_main_dnb">
              {this.state.dNBLDealers.map((res) => {
                return (
                  <div className="block_list">
                    <img alt="" src={BlockIcon}></img>
                    <h className="dealer_website_name_dndb">{res.name}</h>
                  </div>
                );
              })}
            </div>

            {/* <div className="block_list">
              <img src={BlockIcon}></img>
              <h className="dealer_website_name">AmazingParts USA</h>
            </div>
            <div className="block_list">
              <img src={BlockIcon}></img>
              <h className="dealer_website_name">AmazingParts USA</h>
            </div>
            <div className="block_list">
              <img src={BlockIcon}></img>
              <h className="dealer_website_name">AmazingParts USA</h>
            </div> */}
            <div className="footer_text">
              <h>
                Where to Buy + Brand Enforcement Powered By{" "}
                <span className="dBLtitle">OFish</span>
              </h>
            </div>
          </DealerLocatorModals>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.DealerLocatorReducer,
    dashboardState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(dealerLocatorActions, dispatch),
  })
)(withRouter(DealerLocator));
