import React, { useEffect, useState } from "react";
import { AppBtn } from "../../../components/common";
import {
  AiOutlineMinus,
  AiTwotoneEdit,
  AiOutlineClose,
  AiOutlineDelete,
} from "react-icons/ai";
import { BiCircle, BiCheckCircle } from "react-icons/bi";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { ColorPicker, Loader } from "../../../components/common/index";
import { deviceOverviewAction } from "../../../store/deviceOverview/deviceOverviewAction";
import RemoveButton from "../../../resources/images/removecolor.png"

function FavoriteColor(props) {
  console.log("deviceOverviewState-->", props.deviceOverviewState);
  const [clickRemoveButton, setClickRemoveButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onColorSelectForRemove = (color) => {
    props.deviceOverviewAction.onDelectSelectColor(color);
  };

  // console.log("env Id:", props?.envId);

  // const updateColorButtonPress = () => {
  //   const { addColors } = props.deviceOverviewState;
  //   if (addColors.length > 0) {
  //     setIsLoading(true);
  //     props.deviceOverviewAction
  //       .onUpdateColorCollection(deviceId, addColors, "update")
  //       .then((response) => {
  //         if (response && response.data && response.data.responseCode === 1) {
  //           toast.success("Color update");
  //         }
  //         setIsLoading(false);
  //       });
  //   } else {
  //     toast.error("Please add colors");
  //   }
  // };

  const removeColorButtonPress = () => {
    const { deleteColors } = props.deviceOverviewState;
    if (deleteColors.length > 0) {
      setIsLoading(true);
      props.deviceOverviewAction
        .onDeleteColorCollection(deleteColors)
        .then((response) => {
          if (response && response.data && response.data.success) {
            setClickRemoveButton(false);
            props.deviceOverviewAction.onChange("isShowCircle", false);
            props.deviceOverviewAction
              .getEnvFavColors(
                props.deviceOverviewState.deviceOverviewData.environmentId
              )
              .then((res) => {
                if (res && res.data && res.data.success) {
                  toast.success("Color Removed");
                  setIsLoading(false);
                } else {
                  toast.error("Something went wrong, Error fetching colors");
                  setIsLoading(false);
                }
              });
          } else {
            toast.error("Something went wrong, Error deleting colors");
            setIsLoading(false);
          }
        });
    } else {
      toast.error("Please select colors");
    }
  };
  const cancelDelete = () => {
    props.deviceOverviewAction.onClearDelectSelectColor();
    props.deviceOverviewAction.onChange("isShowCircle", false);
    setClickRemoveButton(false);
  };

  const setNewColor = (value, index) => {
    const userId = localStorage.getItem("userId");
    let colorReq = [
      {
        id: 0,
        environmentId:
          props.deviceOverviewState.deviceOverviewData.environmentId,
        userId: userId,
        isTop: true,
        isFavourite: true,
        color: value,
        order: index + 1,
      },
    ];
    if (value !== "") {
      setIsLoading(true);
      props.deviceOverviewAction
        .onAddColorCollection(colorReq)
        .then((response) => {
          if (response && response.data && response.data.success) {
            props.deviceOverviewAction
              .getEnvFavColors(
                props.deviceOverviewState.deviceOverviewData.environmentId
              )
              .then((res) => {
                if (res && res.data && res.data.success) {
                  toast.success("Color added");
                  setIsLoading(false);
                } else {
                  toast.error("Something went wrong, Error fetching colors");
                  setIsLoading(false);
                }
              });
          } else {
            toast.error("Something went wrong, Error adding colors");
            setIsLoading(false);
          }
        });
    } else {
      toast.error("Please select color");
    }
    // dispatch(deviceOverviewAction.onAddNewColor(value));
  };

  const checkColorSelected = (current) => {
    const { deleteColors, isShowCircle } = props.deviceOverviewState;
    const relExist = deleteColors.find((color) => {
      return color === current.id;
    });
    if (isShowCircle) {
      if (relExist) {
        return (
          <BiCheckCircle onClick={() => onColorSelectForRemove(current.id)} />
        );
      } else {
        return <BiCircle onClick={() => onColorSelectForRemove(current.id)} />;
      }
    }
  };

  return (
    <div className="colorWrapper">
      {isLoading && <Loader />}
      <div className="colorsHeaderButtons">
        {!clickRemoveButton ? (
          <div>
            {/* <span>
              <AiTwotoneEdit />
              <AppBtn
                text="Update color"
                onClick={() => updateColorButtonPress(!isColor)}
              />
            </span> */}
            <span className="removeDesktop">
              <AiTwotoneEdit />
              <AppBtn
                text="Remove color"
                onClick={() => {
                  setClickRemoveButton(true);
                  props.deviceOverviewAction.onChange("isShowCircle", true);
                }}
              />
            </span>
            <span className="removeMobile">
              <img  src={RemoveButton}  onClick={() => {
                  setClickRemoveButton(true);
                  props.deviceOverviewAction.onChange("isShowCircle", true);
                }}/>
             
            </span>
          </div>
        ) : (
          <div>
            <span>
              <AiOutlineDelete />
              <AppBtn
                text="Delete color"
                onClick={() => removeColorButtonPress()}
              />
            </span>
            <span>
              <AiOutlineClose />
              <AppBtn text="Cancel" onClick={() => cancelDelete()} />
            </span>
          </div>
        )}
      </div>
      <ul className="ulColorbox">
        {props.colorCollection?.map((x, i) => {
          return (
            <li key={i} style={{ backgroundColor: x.color }} className="colorbox">
              {x?.id === 0 ? (
                <ColorPicker
                  color={x?.color}
                  onColorSelect={(colorHex) => {
                    setNewColor(colorHex, i);
                  }}
                />
              ) : (
                checkColorSelected(x)
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default connect(
  (state) => ({ deviceOverviewState: state.DeviceOverviewReducer }),
  (dispatch) => ({
    deviceOverviewAction: bindActionCreators(deviceOverviewAction, dispatch),
  })
)(withRouter(FavoriteColor));
