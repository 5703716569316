import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import logo from "../../resources/images/logo.png";
import passwordreset from "../../resources/images/password-reset.png";
import {
  FloatingLabelInput,
  AppBtn,
  Loader,
} from "../../components/common/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "../../store/accounts/accountActions";
import { RoutesObj } from "../../routes";
import { toast } from "react-toastify";
import textLogo from "../../assets/images/aqutic.png";

class PasswordRest extends Component {
  constructor() {
    super();

    this.state = {
      isValid: false,
      password: "",
      confirmPass: "",
      isLoading: false,
      passError: false,
    };
  }

  onResetClick = (password, confirmPass) => {
    const { history } = this.props;
    if (password === confirmPass) {
      let reqObj = {
        email: this.props.state.forgotEmail,
        password: password,
        confirmPassword: confirmPass,
        token: this.props.state.forgotToken,
      };
      this.setState({ isLoading: true, isValid: true });
      this.props.actions.onResetPassword(reqObj).then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200 && data.data.success) {
          localStorage.removeItem("userEmail");
          toast.success("Your Password Changed Successfully");
          history.push(RoutesObj.Root.path);
        } else {
          toast.error("Something went wrong, Please try again");
        }
      });
    } else {
      this.setState({ isValid: true });
      toast.error("Password does not match");
    }
  };
  onPasswordValidation = (value) => {
    if (
      value !== "" &&
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).*$/i.test(value)
    ) {
      this.setState({
        passError: true,
        password: value,
      });
    } else {
      this.setState({
        passError: false,
        password: value,
      });
    }
  };

  render() {
    const { password, confirmPass, isValid, passError } = this.state;
    console.log(isValid, "isValid");
    return (
      <div className="main-container">
        {this.state.isLoading && <Loader />}
        <div className="forgotPasswordLogin">
          <div className="logoContainer">
            <img className="loginLogoStyle" src={textLogo} alt="Logo" />
          </div>
          <div className="loginWrapper__loginFormWrapper">
            <div className="loginTitle">
              <img src={passwordreset} alt="" />
              <h2
                style={{
                  color: "#202A4C",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                Password Reset
              </h2>
            </div>

            <span className="loginWrapper__loginFormWrapper--inner_">
              <FloatingLabelInput
                placeholder="New Password"
                type="password"
                className={this.state.isValid ? "errorOutline" : ""}
                value={password}
                // onChange={(e) =>
                //   this.setState({ password: e.target.value, isValid: false })
                // }
                onChange={(e) => this.onPasswordValidation(e.target.value)}
              />
              {passError ? (
                <span style={{ color: "red" }}>
                  {password !== ""
                    ? "Password must contain at least 1 uppercase, lowercase, number and special character"
                    : ""}
                </span>
              ) : null}
            </span>

            <span className="loginWrapper__loginFormWrapper--inner_">
              <div style={{ marginTop: "20px" }}>
                <FloatingLabelInput
                  placeholder="Confirm Password"
                  type="password"
                  className={this.state.isValid ? "errorOutline" : ""}
                  value={confirmPass}
                  onChange={(e) =>
                    this.setState({
                      confirmPass: e.target.value,
                      isValid: false,
                    })
                  }
                />
              </div>
            </span>

            <span
              className="loginBtnWrapper"
              style={{ marginTop: "15px", paddingBottom: "8%" }}
            >
              <AppBtn
                text="CONTINUE"
                onClick={() =>
                  password !== "" && confirmPass !== ""
                    ? this.onResetClick(password, confirmPass)
                    : this.setState({ isValid: true })
                }
              />
            </span>

            <span className="loginWrapper__loginFormWrapper--inner">
              <div className="login_description">
                Need help?
                <span>
                  {" "}
                  <a
                    href="https://www.aquaticav.com/pages/contact"
                    target="_blank"
                    style={{ color: "#1bbed0" }}
                  >
                    Chat with us.
                  </a>
                </span>
              </div>
            </span>
          </div>
        </div>
        <div className="main-container__column-right">
          <div className="logoinnerCls">
            <img src={logo} alt="Logo" width="420px" height="101px" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
  })
)(withRouter(PasswordRest));
