import { spaOverviewTypes } from "./SpaOverviewTypes";

const INITIAL_STATE = {
  errorMessage: "",
  userOverview: "",
  allUserEnv: null,
  envWithDeviceCount: [],
  userDevices: [],
  editUserName: "",
  deviceOverviewInfo: "",
  panel1: false,
  panel2: true,
  panel3: false,
  panel4: false,
  panel5: false,
  panel6: false,
  spaDeviceSetting: "",
  delearName: "",
  prchPrice: "",
  prchDate: "",
  address: "",
  city: "",
  province: "",
  country: "",
  zipCode: "",
  googleLocations: [],
  foucusAddress: false,
  spaLat: null,
  spaLon: null,
  deviceCatId: null,
  deviceDelearId: null,
  spaTemperatureSettings: "",
  spaGlobalSettings: "",
  spaWaterTreatmentR: "",
  spaDeviceDetails: "",
  spaFilterSettings: "",
  spaSettings: "",
  spaWRTDeviceId: 0,
  spaGeneralInfo: "",
  spaAllWaterLife: [],
  spaAllFilterLife: [],
  filterLifeStats: "",
  waterLifeStats: "",
  spaTopside: "",
  spaController: "",
  spaUser: [],
  spaDevices: [],
  spaNotification: [],
  spaGlobalScences: [],
  supportResources: [],
  userIcons: [],
  spaMqttDeviceName: "BW832F_F236",
  spaGateways: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case spaOverviewTypes.ON_CHANGE_SPA:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case spaOverviewTypes.SET_SPA: {
      const newState = { ...state };
      const spaData = action.payload;
      console.log(spaData, "spaDataStore");
      // if (spaData?.spaTopside?.length > 0) {
      newState.spaTopside = spaData?.spaTopside[0] || {};
      // }
      // if (spaData?.spaController?.length > 0) {
      newState.spaController = spaData?.spaController[0] || {};
      // }
      // if (spaData?.spaGlobalSettings?.length > 0) {
      newState.spaGlobalSettings = spaData?.spaGlobalSettings[0] || {};
      // }
      // if (spaData?.spaGateways?.length > 0) {
      newState.spaGateways = spaData?.spaGateways[0] || {};
      // }
      // if (spaData?.spaFilterSettings?.length > 0) {
      newState.spaFilterSettings = spaData?.spaFilterSettings[0] || {};
      // }
      if (spaData?.spaWaterTreatment) {
        newState.spaWaterTreatmentR = spaData.spaWaterTreatment;
        newState.spaWRTDeviceId = spaData.spaWaterTreatment.id;
      }
      // if (spaData?.spaTemperatureSettings?.length > 0) {
      newState.spaTemperatureSettings =
        spaData?.spaTemperatureSettings[0] || {};
      // }
      // if (spaData?.spaSettings.length > 0) {
      newState.spaSettings = spaData?.spaSettings[0] || {};
      newState.prchPrice = spaData?.spaSettings[0]?.price || null;
      // }
      // if (spaData?.spaDeviceDetails) {
      newState.spaDeviceDetails = spaData?.spaDeviceDetails || null;
      newState.delearName = spaData?.spaDeviceDetails?.dealer || null;
      newState.prchDate = spaData?.spaDeviceDetails?.purchaseDate || null;
      newState.spaLat = spaData?.spaDeviceDetails?.installationLatitude || null;
      newState.spaLon =
        spaData?.spaDeviceDetails?.installationLongitude || null;
      newState.deviceCatId = spaData?.spaDeviceDetails?.categoryId || null;
      newState.deviceDelearId = spaData?.spaDeviceDetails?.dealerId || null;
      const values = spaData?.spaDeviceDetails?.installationLocation
        ? spaData.spaDeviceDetails.installationLocation?.split(", ")
        : [];

      if (values?.length > 0) {
        newState.address = values[0];

        newState.city = values[1] ? values[1] : "";
        newState.zipCode = values[2] ? values[2] : "";
        newState.province = values[3] ? values[3] : "";
        newState.country = values[4] ? values[4] : "";
      }
      // }
      return newState;
    }
    case spaOverviewTypes.SET_SELECT_LOCATION_SPA: {
      const newState = { ...state };
      const spaData = action.payload;
      if (spaData) {
        const values = spaData.formatted_address.split(", ");
        newState.address = values[0];
        if (spaData.geometry?.location) {
          newState.spaLat = spaData.geometry.location.lat;
          newState.spaLon = spaData.geometry.location.lng;
        }
        const adrValues = spaData.address_components;
        adrValues.forEach((adrItem) => {
          if (adrItem.types?.length > 0 && adrItem.types[0] === "locality") {
            newState.city = adrItem.long_name;
          }
          if (
            adrItem.types?.length > 0 &&
            adrItem.types[0] === "administrative_area_level_1"
          ) {
            newState.province = adrItem.long_name;
          }
          if (adrItem.types?.length > 0 && adrItem.types[0] === "country") {
            newState.country = adrItem.long_name;
          }
          if (adrItem.types?.length > 0 && adrItem.types[0] === "postal_code") {
            newState.zipCode = adrItem.long_name;
          }
        });
      }
      return newState;
    }
    case spaOverviewTypes.SPA_RESET_FIELDS: {
      const newState = { ...state };
      newState.spaDeviceSetting = "";
      newState.delearName = "";
      newState.prchPrice = "";
      newState.prchDate = "";
      newState.address = "";
      newState.city = "";
      newState.province = "";
      newState.country = "";
      newState.zipCode = "";
      newState.googleLocations = [];
      newState.foucusAddress = false;
      newState.spaLat = null;
      newState.spaLon = null;
      newState.deviceCatId = null;
      newState.deviceDelearId = null;
      return newState;
    }
    case spaOverviewTypes.SPA_USER_DETAIL: {
      return { ...state, spaUser: action.payload };
    }
    case spaOverviewTypes.SPA_DEVICES: {
      return { ...state, spaDevices: action.payload };
    }
    case spaOverviewTypes.SPA_NOTIFICATION: {
      return { ...state, spaNotification: action.payload };
    }
    case spaOverviewTypes.SPA_GLOBAL_SCENCES: {
      return { ...state, spaGlobalScences: action.payload };
    }
    case spaOverviewTypes.SUPPORT_RESOURCES: {
      return { ...state, supportResources: action.payload };
    }
    case spaOverviewTypes.USER_ICONS: {
      return { ...state, userIcons: action.payload };
    }
    case spaOverviewTypes.SPA_DEVICE_NAME: {
      console.log("yes in reducer mqqt device name", action.payload);
      return { ...state, spaMqttDeviceName: action.payload };
    }
    // case spaOverviewTypes.: {
    //   console.log("yes in reducer mqqt device name", action.payload);
    //   return { ...state, spaMqttDeviceName: action.payload };
    // }
    default:
      return state;
  }
};
