import { conversationApi } from "./conversationApi";
import { chatTypes } from "./conversationTypes";

const onGetUserRoomsByUsersId = (data) => async (dispatch) => {
  let response = await conversationApi.getUserRoomsByUsersId(data);
  dispatch({
    type: chatTypes.REFETCH_DATA,
    payload: { prop: "loadingMessages", value: true },
  });
  return response;
};
const onGetChatDetailsByRoomId = (data) => async (dispatch) => {
  let response = await conversationApi.getChatDetailsByRoomId(data);
  dispatch({
    type: chatTypes.REFETCH_DATA,
    payload: false,
  });
  return response;
};
const onMakeRoomFavorite = (data) => async (dispatch) => {
  let response = await conversationApi.makeRoomFavorite(data);
  dispatch({
    type: chatTypes.REFETCH_DATA,
    payload: true,
  });
  return response;
};
const onCreateRoom = (data) => async (dispatch) => {
  let response = await conversationApi.createRoom(data);
  dispatch({
    type: chatTypes.REFETCH_DATA,
    payload: true,
  });
  return response;
};
const onDeleteRooms = (data) => async (dispatch) => {
  let response = await conversationApi.deleteRooms(data);
  dispatch({
    type: chatTypes.REFETCH_DATA,
    payload: true,
  });
  return response;
};
const onGetChatUsers = (data) => async (dispatch) => {
  let response = await conversationApi.getChatUsers(data);
  return response;
};
const onGetUserProductDealers = (data) => async (dispatch) => {
  let response = await conversationApi.getAllDealors(data);
  return response;
};

const uploadFile = (data) => async (dispatch) => {
  let response = await conversationApi.uploadFiles(data);
  return response;
};
const onSendMessageViaApi = (data) => async (dispatch) => {
  let response = await conversationApi.sendMessageViaApi(data);
  return response;
};

const updateCampaignIdInRoom = (data) => async (dispatch) => {
  let response = await conversationApi.updateCampaignIdInRoomAPI(data);
  return response;
};

const getCampaignsDdl = (data) => async (dispatch) => {
  let response = await conversationApi.getCampaignsDdl(data);
  return response;
};

const onCreateLeadsForShopiy = (data) => async (dispatch) => {
  let response = await conversationApi.createLeadsForShopiy(data);
  return response;
};

export const conversationAction = {
  onGetUserRoomsByUsersId: onGetUserRoomsByUsersId,
  onGetChatDetailsByRoomId: onGetChatDetailsByRoomId,
  onMakeRoomFavorite: onMakeRoomFavorite,
  onCreateRoom: onCreateRoom,
  onDeleteRooms: onDeleteRooms,
  uploadFile: uploadFile,
  onGetChatUsers: onGetChatUsers,
  onSendMessageViaApi: onSendMessageViaApi,
  updateCampaignIdInRoom,
  getCampaignsDdl,
  onGetUserProductDealers:onGetUserProductDealers,
  onCreateLeadsForShopiy: onCreateLeadsForShopiy,
};
