import React, { Component } from "react";
import OverViewCard from "../../../components/common/SpaProduct/overViewCard";
import SpaWaterTreatment from "../../../components/common/SpaProduct/spaWaterTreatment";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import Grid from "@material-ui/core/Grid";
import { Header, Loader } from "../../../components/common/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import AppBtn from "../../../components/common/AppBtn/AppBtn";
import { RoutesObj } from "../../../routes";
import { toast } from "react-toastify";

import "../tabs.css";
import FilterLifeCard from "../../../components/common/SpaProduct/FilterLifeCard";
import WaterLifeCard from "../../../components/common/SpaProduct/WaterLifeCard";
import FilterLifeDetail from "../../../components/common/SpaProduct/FilterLifeDetail";
import WaterLifeDetail from "../../../components/common/SpaProduct/WaterLifeDetail";
import TabNav from "../TabNav";
import CommonHeader from "../CommonHeader";
import constantColors from "../../../components/common/colorConstants";
import WaterTreatmentResources from "../../../components/common/SpaProduct/WaterTreatmentResources";
import ChemicalTestingDetail from "../../../components/common/SpaProduct/ChemicalTestingDetail";
import ReportingCard from "../../../components/common/SpaProduct/ReportingCard";

const Pane = (props) => (
  <div
    className={"tab-pane" + (props.activepane ? " active" : "")}
    id={props.id}
  >
    {props.children}
  </div>
);

let spaDeviceId;
class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPlaying: "",
      trackProgress: "",
      activepane: "pane1",
      isFilterLife: false,
      isWaterLife: false,
      isWaterTreatment: true,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.onLoadCompleteData();
    this.fetchDeviceUseHistory();
    if (location?.state?.SpaId) {
      spaDeviceId = location.state.SpaId;
    } else {
      window.location = RoutesObj.Dashboard.path;
    }
  }
  ShowFilerLife = () => {
    this.setState({
      isWaterTreatment: false,
      isFilterLife: true,
    });
  };

  HideFilerLife = () => {
    this.setState({
      isWaterTreatment: true,
      isFilterLife: false,
    });
  };

  ShowWaterLife = () => {
    this.setState({
      isWaterTreatment: false,
      isWaterLife: true,
    });
  };

  HideWaterLife = () => {
    this.setState({
      isWaterTreatment: true,
      isWaterLife: false,
    });
  };
  async onLoadCompleteData(showMsg, msgString) {
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    // await this.props.actions
    //   .onGetAllEnvironment(userId)
    //   .then(async (env) => {});

    await this.props.homeActions
      .onGetMyEnvironment(
        userId,
        JSON.parse(localStorage.getItem("userInfo")).customerType === 3
          ? JSON.parse(localStorage.getItem("userInfo")).companyId
          : false
      )
      .then(async (env) => {
        {
          console.log("env", env);
        }
        if (env.status === 200 && env.data.success) {
          let reqObj = {
            type: "type",
            userId: userId,
          };

          await this.props.homeActions
            .onfetchNotification(reqObj)
            .then((res) => {});
          // await this.props.actions
          //   .onGetDeviceInfo(userId, env.data.data[0].id)
          //   .then((dev) => {
          //     this.setState({ isLoading: false });
          //   });
          this.setState({ isLoading: false });
        }
      });

    // await this.onGetDashboardDataView();
  }
  fetchDeviceUseHistory = () => {
    const data = { /* your data object here */ };
    
    this.props.SpaOverviewAction?.getDeviceUseHistory(data)
      .then((response) => {
        // Handle the response data here
        console.log("use history",response);
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  }
  render() {
    const { isLoading } = this.state;
    const { dashboardState } = this.props;
    const { userInfo, userEnv } = dashboardState;
    const { history } = this.props;
    const currentRoot = history ? history.location.pathname : null;

    let userNameS = "";
    if (userInfo) {
      userNameS = `${userInfo.firstName}${`'s `}`;
    }
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    return (
      <div className="Dashboardmain-container">
        {isLoading && <Loader />}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userData ? userData : null}
            isDashboardShow={true}
            history={history}
            userEnvoirnment={userEnv}
            isSPA={true} // sending this to show the spa dropdwon if there is any SPA in the environemnt
            SPAIId={spaDeviceId} // sending this to get spa details on the settings screen
          />

          <CommonHeader
            spaDeviceId={spaDeviceId}
            currentRoot={currentRoot}
            history={history}
          />
        </div>

        <div
          style={{ marginTop: 220, marginLeft: "4.5%" }}
          className="deviceOverviewContainer"
        >
          <div className="mainTitle spaSpecificTitle">
            <span>Spa Ownership</span>
          </div>
          <span className="spaAndHomeText">
            <span
              className="spaStandardHeading"
              style={{ color: constantColors.softGray }}
            >
              Home /{" "}
            </span>
            <span className="spaStandardHeading">Reporting </span>
          </span>
        </div>

        <div className="spaProductContentBody" style={{ marginTop: 0 }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              marginLeft: "5%",
              marginRight: "5%",

              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={12} style={{ marginBottom: 120 }}>
              <ReportingCard
                backtowatertreatment={this.HideChemicalTest}
                isActive={true}
                filtermanage={this.ShowFilerLife}
                waterlifemanage={this.ShowWaterLife}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(Reporting));
