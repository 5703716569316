import React from 'react';

function NewChatForm({
    chatUserData,
    submitChatUserData,
    handleInputChange,
    isDisabled,
    error,
    validateEmail,
}) {

    return (
        <div className="form-container">
            <span >
                <h3>Start Conversation</h3>

                <div className="form-group">
                    <input
                        type="text"
                        placeholder=" "
                        id="firstName"
                        value={chatUserData.firstName}
                        onChange={handleInputChange} />
                    <label for="firstName">First Name</label>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder=" "
                        id="lastName"
                        value={chatUserData.lastName}
                        onChange={handleInputChange} />
                    <label for="lastName">Last Name</label>
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        placeholder=" "
                        id="email"
                        value={chatUserData.email}
                        onChange={(e)=>{
                            handleInputChange(e)
                            validateEmail(e.target.value)
                            }} />
                    <label for="email">Email Address</label>
                    {error && <div className="error-message">{error}</div>}
                </div>
            </span>

            <div className='newChatBtn'>
                <button
                    className="submit-button"
                    type="submit"
                    onClick={submitChatUserData}
                    disabled={isDisabled}
                    style={{
                        opacity: isDisabled ? 0.5 : 1,
                        cursor: isDisabled ? 'initial' : 'pointer'
                    }}
                >Start New Chat</button>
            </div>

        </div>
    );
}

export default NewChatForm;
