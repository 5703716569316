import { debounce } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { searchIcon } from "../../resources/images/index";
import { dealerLocatorActions } from "../../store/dealerLocator/dealerLocatorActions";

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
    this.throttleHandleChange = debounce(
      this.throttleHandleChange.bind(this),
      500
    );
    this.handleChange = this.handleChange.bind(this);
    this.setSuggestion = this.setSuggestion.bind(this);
  }
  state = {
    autoCompleteData: [],
    suggestionData: [],
  };

  componentDidMount() {
    this.props.handleDealerLoader(true)
    this.throttleHandleChange();
    // { map, mapApi } = this.props // old autoComplete
    // old google place suggestion code
    // const options = {
    //   // restrict your search to a specific type of result
    //   // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
    //   // restrict your search to a specific country, or an array of countries
    //   // componentRestrictions: { country: ['gb', 'us'] },
    // };
    // this.autoComplete = new mapApi.places.Autocomplete(
    //   this.searchInput,
    //   options
    // );
    // this.autoComplete.addListener("place_changed", this.onPlaceChanged);
    // this.autoComplete.bindTo("bounds", map);
    this.props.actions.onAutoComplete("").then((res) => {
      if (res?.data?.success) {
        this.setState({
          autoCompleteData: res.data.data,
        });
      }
    });
  }

  componentWillUnmount({ mapApi } = this.props) {
    // mapApi.event.clearInstanceListeners(this.searchInput);
  }

  async throttleHandleChange() {
    const {
      searchBy,
      sourceLat,
      sourceLong,
      // destinationLat,
      // desitnationLong,
      dealerType,
      product,
      distance,

    } = this.props.state;
    const {handleAllDealers , handleDealerLoader} = this.props
    const resultDealers = await this.props.actions.getDealersSuggestion(
      searchBy,
      sourceLat,
      sourceLong,
      // destinationLat,
      // desitnationLong,
      dealerType,
      product,
      distance
    );
    if (resultDealers?.data?.data && resultDealers.data.data.length > 0) {
      handleAllDealers(resultDealers.data.data);
      
    }else {
      // If resultDealers.data.data is empty or undefined, pass another argument
      handleAllDealers([])
    }
    handleDealerLoader(false)

    // this.setState({
    //   suggestionData: [],
    // });
  }

  handleChange(value, show) {
    this.props.handleDealerLoader(true)
    this.props.actions.onChange("searchBy", value);

    if (!value) {
      if (this.props.makeShowProfileFalse) {
        this.props.makeShowProfileFalse();
        this.props.clearAllFields();
      }
    }

    let matches = [];
    if (value !== "" && this.state.autoCompleteData.length && show) {
      matches = this.state.autoCompleteData.filter((item) => {
        const regex = new RegExp(`${value}`, "gi");
        return item.address.match(regex);
        //  || item.address.match(regex);
      });
      this.setState({
        suggestionData: matches,
      });
    }
    this.throttleHandleChange();
  }

  onPlaceChanged = ({ map, addplace } = this.props) => {
    const place = this.autoComplete.getPlace();
    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addplace(place);
    this.searchInput.blur();
  };

  clearSearchBox() {
    this.searchInput.value = "";
  }
  setSuggestion = (item) => {
    this.searchInput.value = item;
    let here = this;
    this.setState(
      {
        suggestionData: [],
      },
      () => {
        here.handleChange(item, false);
        if (here.props.makeShowProfileTrue) {
          here.props.makeShowProfileTrue();
        }
      }
    );
  };

  clearSuggestion() {
    this.setState({ suggestionData: [] });
  }

  render() {
    const { searchBy } = this.props.state;
    const { suggestionData } = this.state;
    return (
      <>
        <Wrapper className="dealerSerchBarWrapper">
          <input
            ref={(ref) => {
              this.searchInput = ref;
            }}
            value={searchBy}
            onChange={(e) => this.handleChange(e.target.value, true)}
            type="text"
            className="search-input"
            // onFocus={this.clearSearchBox}
            placeholder="Search by zip code or city, state"
            style={{
              color: this.props.inputColor ? "#000000" : null,
              textTransform: this.props.inputColor ? "capitalize" : null,
            }}
          />
          <img className="icon" src={searchIcon} alt="" />
          {this.props.searchInput &&
            suggestionData.length > 0 &&
            suggestionData.map((item, i) => (
              <div
                className="autoComplete-suggest"
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  this.setSuggestion(item.name);
                }}
              >
                {item.name}
              </div>
            ))}
        </Wrapper>
      </>
    );
  }
}

export default connect(
  (state) => ({
    state: state.DealerLocatorReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(dealerLocatorActions, dispatch),
  })
)(withRouter(AutoComplete));
