import React, { Component } from "react";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import "../tabs.css";

class Devices extends Component {
  render() {
    // const { isPlaying, trackProgress, isLoading } = this.state;
    const { spaOverviewReducer, dashboardState, homeState } = this.props;
    // const { userInfo, userEnv } = dashboardState;
    // const { history } = this.props;
    // let userNameS = "";
    // if (userInfo) {
    //   userNameS = `${userInfo.firstName}${`'s `}`;
    // }
    return (
      <div
        className="tab-pane fade show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h4 class="mb-0">Devices</h4>
          <button
            onClick={this.props.toggleDeviceScreen}
            class="btn bg-transparent"
            style={{ color: "#49B8EF" }}
          >
            BACK TO GLOBAL
          </button>
        </div>
        <p class="mb-5">
          Here is a list of Bluetooth devices that have connected to your Spa.
          You can remove access for a device anytime. Keep in mind some devices
          may be of others that have access to your spa, including family
          members or dealers.
        </p>
        <table class="table table-wrap">
          <thead class="thead-light">
            <tr>
              <th scope="col">DEVICE NAME</th>
              <th scope="col">DEVICE TYPE</th>
              <th scope="col">LAST CONNECTED</th>
              <th scope="col">REMOVE ACCESS</th>
            </tr>
          </thead>
          <tbody>
            {this.props.devices.length ? (
              this.props.devices.map((value) => (
                <tr>
                  <td class="font-weight-bold">Bob's Gateway</td>
                  <td>Gateway</td>
                  <td>Augest 1, 2021 at 8:00PM</td>
                  <td class="text-center">
                    <button type="button" class="btn-close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <div class="font-weight-bold t-center">No Devices</div>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(Devices));
