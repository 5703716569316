export const data={
    "success": true,
    "data": {
      "temprature": [
        {
          "month": 11,
          "year": 2022,
          "totalTimes": 1,
          "testDate": "Nov 2022"
        }
      ],
      "chemical": {
        "ph": [
          {
            "month": 8,
            "year": 2022,
            "averagePH": 38.21,
            "testMonth": "Aug 2022"
          },
          {
            "month": 9,
            "year": 2022,
            "averagePH": 12,
            "testMonth": "Sep 2022"
          },
          {
            "month": 10,
            "year": 2022,
            "averagePH": 22,
            "testMonth": "Oct 2022"
          },
          {
            "month": 11,
            "year": 2022,
            "averagePH": 7.720000076293945,
            "testMonth": "Nov 2022"
          }
        ],
        "chlorine": [
          {
            "month": 8,
            "year": 2022,
            "averageChlorine": 49,
            "testMonth": "Aug 2022"
          },
          {
            "month": 9,
            "year": 2022,
            "averageChlorine": 43.5,
            "testMonth": "Sep 2022"
          },
          {
            "month": 10,
            "year": 2022,
            "averageChlorine": 26,
            "testMonth": "Oct 2022"
          },
          {
            "month": 11,
            "year": 2022,
            "averageChlorine": 20.63999996185303,
            "testMonth": "Nov 2022"
          }
        ],
        "alkalinity": [
          {
            "month": 8,
            "year": 2022,
            "averageAlkalinity": 49.11,
            "testMonth": "Aug 2022"
          },
          {
            "month": 9,
            "year": 2022,
            "averageAlkalinity": 89,
            "testMonth": "Sep 2022"
          },
          {
            "month": 10,
            "year": 2022,
            "averageAlkalinity": 25,
            "testMonth": "Oct 2022"
          },
          {
            "month": 11,
            "year": 2022,
            "averageAlkalinity": 8.7,
            "testMonth": "Nov 2022"
          }
        ]
      },
      "waterLife": [
        {
          "month": 6,
          "year": 2022,
          "ttoalTest": 1,
          "testDate": "Jun 2022"
        },
        {
          "month": 8,
          "year": 2022,
          "ttoalTest": 5,
          "testDate": "Aug 2022"
        },
        {
          "month": 9,
          "year": 2022,
          "ttoalTest": 1,
          "testDate": "Sep 2022"
        },
        {
          "month": 11,
          "year": 2022,
          "ttoalTest": 2,
          "testDate": "Nov 2022"
        }
      ],
      "filterLife": [
        {
          "month": 1,
          "year": 2022,
          "totaTest": 1,
          "testDate": "Jan 2022"
        },
        {
          "month": 6,
          "year": 2022,
          "totaTest": 2,
          "testDate": "Jun 2022"
        },
        {
          "month": 7,
          "year": 2022,
          "totaTest": 1,
          "testDate": "Jul 2022"
        },
        {
          "month": 8,
          "year": 2022,
          "totaTest": 25,
          "testDate": "Aug 2022"
        },
        {
          "month": 9,
          "year": 2022,
          "totaTest": 2,
          "testDate": "Sep 2022"
        },
        {
          "month": 10,
          "year": 2022,
          "totaTest": 1,
          "testDate": "Oct 2022"
        },
        {
          "month": 12,
          "year": 2022,
          "totaTest": 3,
          "testDate": "Dec 2022"
        }
      ]
    },
    "message": "Success"
  }