import React, { useState, useEffect } from "react";
import CustomSwitch from "./../Switch/CustomSwitch";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { useLocation, useHistory } from "react-router-dom";
import { Loader } from "../index";
import { toast } from "react-toastify";
import { RoutesObj } from "../../../routes";
import "./spaQuickToolsCard.css";
import mqttClient from "../../../config/MqttClient";

export default function SpaQuickToolsCard({ getOwnSpaid }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsloading] = useState(false);

  const spaDeviceId = location?.state?.SpaId;
  const { spaGlobalSettings, spaTemperatureSettings } = useSelector(
    (state) => state.SpaOverviewReducer
  );
  const spaOverviewReducer = useSelector((state) => state.SpaOverviewReducer);
  const onChangeCleanCycle = (value) => {
    const quickPayload = {
      cleanCycle: value,
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
      id: getOwnSpaid
        ? spaGlobalSettings.length > 0
          ? spaGlobalSettings.id
          : spaGlobalSettings.id
        : spaGlobalSettings.id,
    };
    updatingGlobalSetting(quickPayload);
  };
  const onChangeSummer = (value) => {
    const quickPayload = {
      summerTimer: value,
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
      id: getOwnSpaid
        ? spaGlobalSettings.length > 0
          ? spaGlobalSettings.id
          : spaGlobalSettings.id
        : spaGlobalSettings.id,
    };
    updatingGlobalSetting(quickPayload);
  };

  const onChangeLockStatus = (value) => {
    // const quickPayload = {
    //   spaLockStatus: value,
    //   deviceId: spaDeviceId,
    //   id: spaGlobalSettings.id,
    // };
    // updatingGlobalSetting(quickPayload);

    const scalePayload = {
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
      isTemperatureLock: value,
    };
    dispatchTempCall(scalePayload);
  };

  const onChangeInsideLight = (value) => {
    onToggleLight();
    const quickPayload = {
      insightLights: value,
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
      id: getOwnSpaid
        ? spaGlobalSettings.length > 0
          ? spaGlobalSettings.id
          : spaGlobalSettings.id
        : spaGlobalSettings.id,
    };
    updatingGlobalSetting(quickPayload);
  };
  const updatingGlobalSetting = (value) => {
    setIsloading(true);
    dispatch(SpaOverviewAction.onUpdataGlobalSetting(value)).then((res) => {
      if (res?.success) {
        dispatch(
          SpaOverviewAction.onGetSpaInfo(
            getOwnSpaid ? getOwnSpaid : spaDeviceId
          )
        ).then((data) => {
          setIsloading(false);
          if (data?.success === false) {
            toast.error(data?.message);
          } else {
            toast.success("Value updated successfully.");
          }
        });
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };

  const dispatchTempCall = (value) => {
    setIsloading(true);
    dispatch(SpaOverviewAction.onUpdataTempSetting(value)).then((res) => {
      if (res?.success) {
        dispatch(
          SpaOverviewAction.onGetSpaInfo(
            getOwnSpaid ? getOwnSpaid : spaDeviceId
          )
        ).then((data) => {
          setIsloading(false);
          if (data?.success === false) {
            toast.error(data?.message);
          } else {
            toast.success("Value updated successfully.");
          }
        });
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    console.log("got settigs:", spaTemperatureSettings);
  }, [spaTemperatureSettings]);

  const onToggleLight = () => {
    mqttClient.publish(
      `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/devicebound`,
      "LIGHT_BTN"
    );
    // LIGHT_BTN
    // PUMP2_BTN
    // PUMP1_BTN
  };

  return (
    <Grid item md={12} className="gridMainSpaWrapper px-3">
      {isLoading ? <Loader /> : null}
      <Grid
        item
        md={12}
        className="gridSpaQuickToolsWrapper"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <Grid item md={12} className="gridSpaQuickToolsWrapperHeader">
          <Grid item md={12} className="gridRow">
            <Grid item md={12} className="gridSpaQuickToolsHeading">
              <span item md={6} className="heading">
                Quick Tools
              </span>
              <span
                item
                md={6}
                className="settingChange"
                onClick={() =>
                  history.push({
                    pathname: RoutesObj.SpaSettings.path,
                    state: {
                      SpaId: spaDeviceId,
                    },
                  })
                }
              >
                SPA SETTING
              </span>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item md={12} className="gridSpaQuickToolsWrapperBody">
          <Grid
            style={{ marginBlock: "30px" }}
            item
            md={12}
            sm={12}
            className="gridRow"
          >
            <Grid item md={12} className="gridSpaQuickToolsControlItem toolRow">
              <span className="textInfo">Clean Cycle</span>

              <CustomSwitch
                onClick={(e) => {
                  spaTemperatureSettings?.isTemperatureLock === false &&
                    onChangeCleanCycle(e.target.checked);
                }}
                checked={spaGlobalSettings.cleanCycle}
              />
            </Grid>
            <Grid item md={12} className="gridSpaQuickToolsControlItem toolRow">
              <span className="textInfo">Summer Timer</span>

              <CustomSwitch
                onClick={(e) => {
                  spaTemperatureSettings?.isTemperatureLock === false &&
                    onChangeSummer(e.target.checked);
                }}
                checked={spaGlobalSettings.summerTimer}
              />
            </Grid>
            <Grid item md={12} className="gridSpaQuickToolsControlItem toolRow">
              <span className="textInfo">Spa Lock</span>

              <CustomSwitch
                onClick={(e) => {
                  onChangeLockStatus(e.target.checked);
                }}
                checked={spaTemperatureSettings?.isTemperatureLock}
              />
            </Grid>
            <Grid item md={12} className="gridSpaQuickToolsControlItem toolRow">
              <span className="textInfo">Inside Lights</span>

              <CustomSwitch
                onClick={(e) => {
                  spaTemperatureSettings?.isTemperatureLock === false &&
                    onChangeInsideLight(e.target.checked);
                }}
                checked={spaGlobalSettings.insightLights}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
