import React, { Component } from "react";
import logo from "../../../resources/images/logoReg.svg";
import { Box } from "@material-ui/core";
import { AccountsWizardMob } from "../../../components/common/Stepper/AccountsWizardMob";

class ProductRegistrationHeader extends Component {
    constructor() {
        super();
        this.state = {
          screenWidth: window.innerWidth,
        };
      }
  handleNext = (activeStep) => {
    this.props.onStepChange(activeStep);
  };
 

  render() {
    return (
      <div className="userRegustrationHeader-container">
        <div className="registrationContainer-header">
          <img src={logo} alt="" width="304px" height="68px" />
        </div>
        <div className="registrationInneatContentMob">
          <div className="dottedLineRegistrationHeader"></div>

          <AccountsWizardMob
            onNext={this.handleNext}
            activeStep={this.props.stepNumber}
            stepOnePrimary="Choose Environment"
            stepOneSecondary="Create an AAV Environment"
            stepTowPrimary="Choose Device"
            stepTwoSecondary="Choose which device to register"
            stepThreePrimary="Device Details"
            stepThreeSecondary="Choose which device to register"
            stepFourPrimary="Finish Setup"
            stepFourSecondary="Notifications & Settings"
          />
        </div>
      </div>
    );
  }
}

export default ProductRegistrationHeader;
