import _ from "lodash";
import { dashboardTypes } from "./dashboardTypes";

const INITIAL_STATE = {
  devicesInfo: [],
  userInfo: "",
  userEnv: [],
  productType: [],
  allProducts: [],
  allDealers: [],
  myDeviceBox: [],
  myDynamicEnv: [],
  myStaticEnv: [],
  transferDevices: [],
  isIntercomEnable: false,
  loadingDashboard: false,
  user_notifications: [],
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case dashboardTypes.DASHBOARD_ONCHANGE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case dashboardTypes.DASHBOARD_LOAD_DATA: {
      const newState = { ...state };
      newState.devicesInfo = action.payload.value;
      newState.userInfo = action.payload.value1;
      return newState;
    }
    case dashboardTypes.ADD_DEVICE_ENV:
      return {
        ...state,
        myDynamicEnv: action.payload.dynamicEnvironments,
        myStaticEnv: action.payload.staticEnvironments,
      };
    case dashboardTypes.DASHBOARD_SET_ENV:
      return { ...state, userEnv: action.payload };
      case dashboardTypes.GET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload,
      };
    case dashboardTypes.GET_PRODUCT:
      return { ...state, allProducts: action.payload };
    case dashboardTypes.GET_DEALER_DATA:
      return {
        ...state,
        allDealers: action.payload,
      };
    case dashboardTypes.SET_DEVICEINFO: {
      const newState = { ...state };
      const searchData = action.payload;
      let deviceData = [];
      if (newState.myDeviceBox.length > 0) {
        deviceData = _.filter(newState.myDeviceBox, (o) => {
          if (o.environmentName) {
            return o.environmentName
              .toLowerCase()
              .includes(searchData.envData.environmentName.toLowerCase());
          }
        });
      }
      newState.devicesInfo = deviceData;
      return newState;
    }
    case dashboardTypes.GET_TRANSFER_DEVICE:
      return {
        ...state,
        transferDevices: action.payload,
      };
    case dashboardTypes.SET_NOTIFICATIONS:{
      return {
        ...state,
        user_notifications: action.payload,
      }
    }
    default:
      return state;
  }
};
