import React, { Component } from "react";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import "../tabs.css";

let spaDeviceId;
class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location?.state?.SpaId) {
      spaDeviceId = location.state.SpaId;
      this.getSpaGeneralInfo(location.state.SpaId);
    }
  }

  async getSpaGeneralInfo(SpaId) {
    this.setState({ isLoading: true });
    const spaPayload = { id: SpaId };
    await this.props.actions
      .onGetSpaGeneralInfo(spaPayload)
      .then(async (res) => {
        if (res?.success) {
          this.setState({ isLoading: false });
        }
      });
  }

  render() {
    const { spaSettings, spaGeneralInfo } = this.props.spaOverviewReducer;
    // const { history } = this.props;

    return (
      <div
        className="tab-pane fade show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <h4>General information</h4>
        <form>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Spa name</label>
            <div className="col-sm-5">
              <div className="d-flex justify-content-end">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Spa name"
                  value={spaSettings?.spaName}
                  disabled={true}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn edit w-75"
                >
                  <i class="fas fa-pen mr-2"></i>Edit
                </button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Spa brand</label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Spa Brand"
                  value={spaSettings?.spaBrand}
                  disabled={true}
                />

                <button className="btn w-50 invisible">.......</button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Spa model</label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Spa Model"
                  value={spaSettings?.spaModel}
                  disabled={true}
                />

                <button className="btn w-50 invisible">.......</button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Serial number</label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="92398647132"
                  value={spaSettings?.spaSerialNo}
                  disabled={true}
                />

                <button className="btn w-50 invisible">.......</button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Controller model</label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Aquatic AV Series 1"
                  value={spaSettings?.spaControllerModel}
                  disabled={true}
                />

                <button className="btn w-50 invisible">.......</button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Firmware version</label>
            <div className="col-sm-5">
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="031 Beta"
                  value={spaSettings?.spaFirmwareVersion}
                  disabled={true}
                />

                <p className="up-date mb-0 text-center d-flex align-items-center justify-content-around">
                  <span className="bg-green"></span> Up to date
                </p>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Your service</label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Service name"
                  value={spaSettings?.spaService}
                  disabled={true}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn edit w-50"
                >
                  Change
                </button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between border-0">
            <label className="col-sm-3 col-form-label">Your dealer</label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Dealer name"
                  value={spaSettings?.spaDealerName}
                  disabled={true}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn edit w-50"
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(GeneralInformation));
