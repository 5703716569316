import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: red;
  color: #fff;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  &:after {
    content: "A";
    color: "ffffff";
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    margin: 5px 0 0 5px;
    position: absolute;
    border-radius: 50%;
    transform: rotate(45deg);
  }
  &:hover {
    z-index: 1;
  }
`;
const renderTooltip = (props) => <Tooltip {...props}>{props.title}</Tooltip>;

const Marker = ({ text, onClick, title, markerColor }) => (
  <OverlayTrigger placement="top" overlay={renderTooltip({ title })}>
    <Wrapper
      alt={text}
      onClick={onClick}
      style={{ backgroundColor: markerColor ? markerColor : "red" }}
    />
  </OverlayTrigger>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
