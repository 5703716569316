import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../resources/images/logo.png";
import {
  FloatingLabelInput,
  AppBtn,
  Loader,
  AppModal,
} from "../../components/common/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "../../store/accounts/accountActions";
import { RoutesObj } from "../../routes";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import textLogo from "../../assets/images/aqutic.png";

let keyDownHandler = null;
class Login extends Component {
  constructor() {
    super();

    this.state = {
      isValid: false,
      isLoading: false,
      showModal: false,
    };
  }

  onSignInClick = () => {
    this.setState({ isLoading: true });
    const { history } = this.props;

    const { email, password } = this.props.state;
    this.props.actions.onSignInPress(email, password).then((data) => {
      this.setState({ isLoading: false });
      if (data.status === 200 && data.data.success) {
        this.props.actions.onChange("email", "");
        this.props.actions.onChange("password", "");
        if (localStorage.getItem("transferedDeviceFlag")) {
          history.replace(
            `${RoutesObj.TransferOwnership.path}${localStorage.getItem(
              "transferedDeviceFlag"
            )}`
          );
          localStorage.removeItem("transferedDeviceFlag");
        } else {
          history.replace(RoutesObj.Dashboard.path);
          if (data?.data?.data?.customerType === 3) {
            history.replace(RoutesObj.PriceList.path);
          } else {
            history.replace(RoutesObj.Dashboard.path);
          }
        }
      } else {
        this.setState({ isValid: true });
        toast.error(data?.data?.message || "Something went wrong please check");
      }
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  onFocusOutFun = (value) => {
    if (
      value !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      this.setState({ isValid: true });
    }
  };

  componentDidMount() {
    const { history } = this.props;
    const token = Cookies.get("access_token");
    if (token) {
      this.onTokenLogin(token);
      // localStorage.setItem("access_token", token);
      // history.replace(RoutesObj.Dashboard.path);
    }

    keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        this.props.state.email !== "" && this.props.state.password !== ""
          ? this.onSignInClick()
          : this.setState({ isValid: true });
      }
    };

    document.addEventListener("keydown", keyDownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", keyDownHandler);
  }

  onTokenLogin = (token) => {
    const { history } = this.props;

    this.props.actions.onLoginWithToken(token).then((data) => {
      if (data.status === 200 && data.data.success) {
        this.props.actions.onChange("email", "");
        this.props.actions.onChange("password", "");
        history.replace(RoutesObj.Dashboard.path);
      } else {
        this.setState({ isValid: true });
        toast.error(data?.data?.message || "Something went wrong please check");
      }
    });
  };

  render() {
    const { email, password } = this.props.state;
    const { isValid, isLoading, showModal } = this.state;
    return (
      <div className="main-container">
        {isLoading && <Loader />}

        <div className="main-container__column-left">
          <div className="logoContainer">
            <img className="loginLogoStyle" src={textLogo} alt="Logo" />
          </div>
          <div className="loginWrapper__loginFormWrapper">
            <div className="loginTitle">
              <h2>Sign In</h2>
              <span>
                Or <Link to="/user-registration">Create an Account</Link>
              </span>
            </div>

            <span className="loginWrapper__loginFormWrapper--inner_">
              <FloatingLabelInput
                placeholder="Email"
                className={isValid ? "errorOutline" : ""}
                value={email}
                onChange={(e) => {
                  this.props.actions.onChange("email", e.target.value);
                  this.setState({ isValid: false });
                }}
              />
            </span>

            <span
              className="loginWrapper__loginFormWrapper--inner_  passwordField"
              style={{ marginTop: "20px" }}
            >
              <div style={{ marginTop: "20px" }}>
                <span className="forgot-Password">
                  <Link
                    to="/forgot-password"
                    onClick={() => this.setState({ isLoading: true })}
                  >
                    Forgot Password?
                  </Link>
                </span>
                <FloatingLabelInput
                  placeholder="Password"
                  type="password"
                  className={isValid ? "errorOutline" : ""}
                  value={password}
                  onChange={(e) => {
                    this.props.actions.onChange("password", e.target.value);
                    this.setState({ isValid: false });
                  }}
                />
              </div>
            </span>

            {isValid ? (
              <div className="inputfieldError inputErrorNew">
                Incorrect login information. Try again or click Forgot Password
                to reset.
              </div>
            ) : null}

            <span className="loginBtnWrapper loginbtn_">
              <AppBtn
                text="SIGN IN"
                onClick={() =>
                  email !== "" && password !== ""
                    ? this.onSignInClick()
                    : this.setState({ isValid: true })
                }
              />
            </span>
            <span className="loginWrapper__loginFormWrapper--inner">
              <div
                className="login_description"
                onClick={() => this.toggleModal()}
              >
                <a style={{ cursor: "pointer" }}>Why Create An Account?</a>
              </div>
            </span>
            <span className="loginWrapper__loginFormWrapper--inner">
              <div className="login_footer">
                Dealer or Distributor? You can also manage your Aquatic AV
                account from here. Sign in above to continue.
              </div>
            </span>
          </div>
        </div>
        <div className="main-container__column-right">
          <div className="logoinnerCls">
            <img src={logo} alt="Logo" width="420px" height="101px" />
          </div>
        </div>
        <div className="dashboardModal">
          {this.state.showModal && (
            <AppModal title="Model" handleclose={() => this.toggleModal()}>
              <h1 className="environment ">Features & Support</h1>
              <ul className="loginModalContent">
                <li>
                  Register all your Aquatic AV products easily, even if they
                  don’t connect to the Aquatic AV App.
                </li>
                <li>Product Registration adds 90 days to your warranty.</li>
                <li>
                  Enjoy priority access to new products and special product
                  deals.
                </li>
                <li>
                  Easily manage and organize all your devices by installing
                  environments.
                </li>
                <li>Chat with Aquatic AV's Support Team through the app.</li>
              </ul>
            </AppModal>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
  })
)(withRouter(Login));
