import React, { Component } from "react";

class TabNav extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     activepane: this.props.children[0].props.id
  //   }

  //   this.clickTab = this.clickTab.bind(this);
  // }

  // clickTab(tabId) {
  //   this.setState({ activepane: tabId });
  // }

  render() {
    const { activepane, clickTab } = this.props;
    return (
      <div>
        <nav className="navbar">
          <ul className="nav nav-tabs">
            {this.props.children.length ? (
              this.props.children.flat().map((child) => (
                <li className="nav-item" key={child?.props?.id}>
                  <a
                    href="#"
                    className={
                      "nav-link " +
                      (activepane === child?.props?.id ? " active" : "")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      clickTab(child?.props?.id);
                    }}
                   
                  >
                    {child?.props?.title}
                  </a>
                </li>
              ))
            ) : (
              <li className="nav-item" key={this.props.children?.props?.id}>
                <a
                  href="#"
                  className={
                    "nav-link " +
                    (activepane === this.props.children?.props?.id
                      ? " active"
                      : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    clickTab(this.props.children?.props?.id);
                  }}
                >
                  {this.props.children?.props?.title}
                </a>
              </li>
            )}
          </ul>
        </nav>
        <div className="tab-content">
          {this.props.children.length
            ? this.props.children.flat().map((child) =>
                React.cloneElement(child, {
                  activepane: activepane === child?.props?.id,
                  key: child?.props?.id,
                })
              )
            : React.cloneElement(this.props.children, {
                activepane: activepane === this.props.children?.props?.id,
                key: this.props.children?.props?.id,
              })}
        </div>
      </div>
    );
  }
}

export default TabNav;
