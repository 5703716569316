import React, { useState,useEffect,useRef } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

function ColorPicker(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState();
  // const ref = useRef(null);

  // function handleClickOutside(event) {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     setDisplayColorPicker(false);
  //   }
  //   }
  //   useEffect(() => {
  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };
  //   }, [displayColorPicker]);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    props.onColorSelect(color);
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.hex);
    // props.onColorSelect(color.hex);
    // setDisplayColorPicker(false);
  };

  const closeButton = () => {
    setDisplayColorPicker(false);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "99px",
        height: "104px",
        top: "-5px",
        borderRadius: "4px",
        background: `rgb(196, 196, 196, 1)`,
      },
      swatch: {
        padding: "0",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "-5px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={() => handleClick()} >
        <div style={styles.color} className="boxwithoutcolor" />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover} >
          <div className="colorpicker" style={styles.cover} />
          <SketchPicker
            color={color}
            onChange={(color) => handleChange(color)}
          />
          <button onClick={() => closeButton()} className="colorpickerCancel">
            Cancel
          </button>
          <button onClick={() => handleClose()} className="colorpickerbutton">
            Select
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default ColorPicker;
