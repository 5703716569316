import { chatTypes } from "./conversationTypes";

const INITIAL_STATE = {
  refetchData: false,
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case chatTypes.REFETCH_DATA:
      return { ...state, refetchData: action.payload };
    default:
      return state;
  }
};
