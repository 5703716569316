import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "../../store/accounts/accountActions";

class TabNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activepane: this.props.children[0].props.id,
    };

    this.clickTab = this.clickTab.bind(this);
  }

  clickTab(tabId) {
    this.props.actions.onChange("currentPaneId", tabId);
  }

  render() {
    const { currentPaneId } = this.props.state;
    return (
      <div>
        <nav className="navbar">
          <ul className="nav nav-tabs">
            {this.props.children.map((child) => (
              <li className="nav-item" key={child.props.id}>
                <span
                  className={
                    "nav-link " +
                    (currentPaneId === child.props.id ? " active" : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.clickTab(child.props.id);
                  }}
                >
                  <span className="link-number">{child.props.index}</span>
                  <span className="link-title">{child.props.title}</span>
                </span>
              </li>
            ))}
          </ul>
        </nav>
        <div className="tab-content">
          {this.props.children.map((child) =>
            React.cloneElement(child, {
              activepane: currentPaneId === child.props.id,
              key: child.props.id,
            })
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
  })
)(TabNav);
