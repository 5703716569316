import React from 'react';
import './appBtn.css';

function AppBtnStatus({ text, onClick, style }) {
  return (
    <div className="buttonStatusWrapper">
      <button type="button" onClick={onClick} style={style}>
        {text}
      </button>
    </div>
  );
}

export default AppBtnStatus;
