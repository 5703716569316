import MuiPhoneNumber from "material-ui-phone-number";
import React, { memo, useEffect, useState } from "react";

const PhoneInput = memo(
  ({
    disabled,
    label,
    placeholder,
    helperText,
    style,
    type,
    required,
    readOnly,
    helperInfo,
    func,
    handleChange,
    headComponent,
    contactData,
    value,
    onChange,
    ...rest
  }) => {
    /**
     * Event handler for onChange event of the phone number input.
     * @param {string} value - The phone number value entered by the user.
     * @param {object} countryData - The country data object.
     */

    return (
      <MuiPhoneNumber
        defaultCountry={"us"}
        onChange={onChange}
        value={value ? `+${value}` : ""}
        name={rest.name}
        id={rest.name}
        data-testid={rest.name}
        disabled={disabled}
        fullWidth
        required
        error={rest.error}
        helperText={rest.helperText || helperText}
        inputProps={{ readOnly: readOnly }}
        {...rest}
      />
    );
  }
);

export default PhoneInput;
