import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  width: 60,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(34px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        // content: "<p>On</p>",
        backgroundColor: "#49B8EF",
        opacity: 1,
        border: 0,
      },
      "& + .MuiSwitch-track::before": {
        content: '"ON"',
        textAlign: "left",
        position: "relative",
        top: "2px",
        left: "8px",
        backgroundColor: "transparent",
        color: "#fff",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 1,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#828282",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-track::before": {
    content: '"OFF"',
    textAlign: "right",
    position: "relative",
    top: "4px",
    left: "26px",
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

export default function CustomSwitch({ onClick, checked, disabled }) {
  const [switchChecked, setSwitchChecked] = useState(false);

  useEffect(() => {
    setSwitchChecked(checked);
  }, [checked]);

  return (
    <div>
      <Switch
        className="Mui-CustomSwitch"
        disableRipple
        onClick={onClick && onClick}
        checked={switchChecked}
        disabled={disabled}
      />
    </div>
  );
}
