import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import { AppBtn } from "../../components/common/index";
import { placeholderImage } from "../../resources/images/index";

import _truncate from "lodash/truncate";

const DashboardBox = ({
  heading,
  changeHeading,
  subheadeing,
  sku,
  serial,
  remaining,
  expiry,
  located,
  onClick,
  userName,
  deviceImg,
  onClickDevice,
  lastconnected,
  receiptStatus,
  onClickTitle,
  category,
  productType,
  items,
}) => {
  const [showEdit, setShowEdit] = useState(true);
  const [showCheck, setShowCheck] = useState(false);
  const [updateHeading, setupdateHeading] = useState(heading);
  const [deviceId, setDeviceId] = useState(null);

  const showEditBtn = () => {
    console.log("tap on pencil:", items?.deviceId);
    setDeviceId(items?.deviceId);
    setShowEdit(false);
    setShowCheck(true);
  };

  const showCheckBtn = () => {
    setShowEdit(true);
    setShowCheck(false);
    changeHeading(updateHeading);
  };

  return (
    <div className="boxMain">
      <div className="boxMain__header">
        <img src={deviceImg ? deviceImg : placeholderImage} />
        <span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ marginRight: 20, marginLeft: 6 }}
              className="nameCursor"
              onClick={(e) => !showCheck && onClickTitle()}
            >
              {showCheck && deviceId == items?.deviceId ? (
                <input
                  className={`${showCheck ? "enabledInput" : ""}`}
                  type="text"
                  value={
                    showCheck
                      ? updateHeading
                      : _truncate(updateHeading, {
                        length: 25,
                      })
                  }
                  onChange={(e) => {
                    setupdateHeading(e.target.value);
                  }}
                  disabled={showEdit}
                />
              ) : (
                <div style={{ marginLeft: -6 }} >  {_truncate(updateHeading, {
                  length: 45
                })}</div>
              )}
            </div>
            <div>
              {!showCheck && <MdEdit onClick={showEditBtn} style={{ color: "#49B8EF" }} />}
            </div>
          </div>
          <span className="dashboardEdit">
            {showCheck && <RiCheckFill onClick={showCheckBtn} />}
          </span>
          <i className={`${showCheck ? "textDisabled" : ""}`}>{subheadeing}</i>
        </span>
      </div>
      <div className={`dashboardTiles ${showCheck ? "textDisabled" : ""}`}>
        <span className="boxMain__sku">
          <i>SKU: </i>
          {`${sku}`}
        </span>
        <span className="boxMain__serial">
          <i>Serial Number: </i>
          {`${serial ? serial : "Non Serial Product"}`}
        </span>
        {(productType && productType === "Physical + IOT") ||
          productType === "SPA Basic" ||
          productType === "SPA Mid" ? (
          <span className="boxMain__serial">
            <i>Last Connected: </i>
            {lastconnected}
          </span>
        ) : (
          ""
        )}

        <span className="boxMain__expiry">
          <i>Warranty Status: </i>
          {receiptStatus === "approved" ? "In Warranty" : "Not Under Warranty"}
        </span>
        {/* {sku && !sku.includes("RG") ? (
          <span className="boxMain__serial">
            <i style={{ color: "transparent" }}>{"a"}</i>
            {""}
          </span>
        ) : (
          ""
        )} */}
        <span className="boxMain__located">{located}</span>
        {/* <span className="boxMain__sku">{`${'SKU: '}${sku}`}</span>
            <span className="boxMain__serial">{`${'Serial Number: '}${serial}`}</span>
            <span className="boxMain__remaining">{`${'Warranty Remaining: '}${remaining}${remaining!==''?' days':''}`}</span>
            <span className="boxMain__expiry">{`${'Warranty Expiration: '}${expiry}`}</span>
            <span className="boxMain__located">{located}</span> */}
      </div>

      {receiptStatus === "Registration Pending" ||
        receiptStatus === "Registration pending" ? (
        <div
          className={`boxButtonMain regPending ${showCheck ? "textDisabled" : ""
            }`}
        >
          <AppBtn text="Registration Pending" onClick={() => onClickDevice()} />
        </div>
      ) : receiptStatus === "Registered" || receiptStatus === "registered" ? (
        <div
          className={`boxButtonMain register ${showCheck ? "textDisabled" : ""
            }`}
        >
          <AppBtn text="Registered" onClick={() => onClickDevice()} />
        </div>
      ) : receiptStatus === "Rejected" || receiptStatus === "rejected" ? (
        <div
          className={`boxButtonMain regDenied ${showCheck ? "textDisabled" : ""
            }`}
        >
          <AppBtn text="Registration denied" onClick={() => onClickDevice()} />
        </div>
      ) : (
        <div
          className={`boxButtonMain receiptMissing ${showCheck ? "textDisabled" : ""
            }`}
        >
          <AppBtn text="Receipt missing" onClick={() => onClickDevice()} />
        </div>
      )}
    </div>
  );
};

export default DashboardBox;
