import React, { Component } from "react";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import Grid from "@material-ui/core/Grid";
import { Header, Loader } from "../../../components/common/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import AppBtn from "../../../components/common/AppBtn/AppBtn";
import "../tabs.css";
import GeneralInformation from "./GeneralInformation";
import Global from "./Global";
import DailyTasks from "./DailyTasks";
import Notifications from "./Notifications";
import Temperature from "./Temperature";
import Filter from "./Filter";
import { RoutesObj } from "../../../routes";
import { toast } from "react-toastify";
import CommonHeader from "../CommonHeader";
import WaterTreatment from "../WaterTreatment/WaterTreatment";
let spaDeviceId;
class SpaSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // isPlaying: "",
      // trackProgress: "",
      activepane: <GeneralInformation />,
      activePaneString: "GeneralInformation",
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.onLoadCompleteData();
    if (location?.state?.SpaId) {
      spaDeviceId = location.state.SpaId;
      this.getSpaOverviewData(location.state.SpaId);
    } else {
      window.location = RoutesObj.Dashboard.path;
    }
  }

  async getSpaOverviewData(SpaId) {
    this.setState({ isLoading: true });
    await this.props.actions.onGetSpaInfo(SpaId).then(async (env) => {
      if (env.success) {
        this.setState({ isLoading: false });
      }
    });
  }
  async onLoadCompleteData(showMsg, msgString) {
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    // await this.props.actions
    //   .onGetAllEnvironment(userId)
    //   .then(async (env) => {});

    await this.props.homeActions
      .onGetMyEnvironment(
        userId,
        JSON.parse(localStorage.getItem("userInfo")).customerType === 3
          ? JSON.parse(localStorage.getItem("userInfo")).companyId
          : false
      )
      .then(async (env) => {
        if (env.status === 200 && env.data.success) {
          let reqObj = {
            type: "type",
            userId: userId,
          };

          await this.props.homeActions
            .onfetchNotification(reqObj)
            .then((res) => {});
          // await this.props.actions
          //   .onGetDeviceInfo(userId, env.data.data[0].id)
          //   .then((dev) => {
          //     this.setState({ isLoading: false });
          //   });
          this.setState({ isLoading: false });
        }
      });

    await this.onGetDashboardDataView();
  }
  render() {
    const { isPlaying, trackProgress, isLoading } = this.state;
    const { spaOverviewReducer, dashboardState, homeState } = this.props;
    const { userInfo, userEnv } = dashboardState;
    const { history } = this.props;
    const currentRoot = history ? history.location.pathname : null;
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    // let userNameS = "";
    // if (userInfo) {
    //   userNameS = `${userInfo.firstName}${`'s `}`;
    // }
    // console.log("spaOverviewReducer", spaOverviewReducer);
    return (
      <div className="Dashboardmain-container">
        {isLoading && <Loader />}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userData ? userData : null}
            isDashboardShow={true}
            history={history}
            userEnvoirnment={userEnv}
            isSPA={true} // sending this to show the spa dropdwon if there is any SPA in the environemnt
            SPAIId={spaDeviceId} // sending this to get spa details on the settings screen
          />
          <div className="headerWrapper">
            <span className="headerWrapper__profileContainer">
              <span className="dashboardLogo">
                <div className="mainTitle spaTitle">
                  <span>Spa Ownership</span>
                </div>

                <div className="dashboardBtn">
                  <AppBtn
                    text="Overview"
                    className="buttonbtm"
                    onClick={() => {
                      history.push({
                        pathname: RoutesObj.SpaOverview.path,
                        state: {
                          SpaId: spaDeviceId,
                        },
                      });
                    }}
                    style={{
                      background: "transparent",
                      color:
                        currentRoot === RoutesObj.SpaOverview.path
                          ? "#49B8EF"
                          : "#FFFFFF",
                    }}
                  />
                  <AppBtn
                    text="Water Treatment"
                    className="buttonbtm1"
                    style={{
                      background: "transparent",
                      color:
                        currentRoot === RoutesObj.WaterTreatment.path
                          ? "#49B8EF"
                          : "#FFFFFF",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: RoutesObj.WaterTreatment.path,
                        state: {
                          SpaId: spaDeviceId,
                        },
                      });
                    }}
                  />

                  <AppBtn
                    text="Reporting"
                    style={{
                      background: "transparent",
                      color:
                        currentRoot === RoutesObj.Reporting.path
                          ? "#49B8EF"
                          : "#FFFFFF",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: RoutesObj.Reporting.path,
                        state: {
                          SpaId: spaDeviceId,
                        },
                      });
                    }}
                  />

                  {/* <AppBtn
                    text="Spa Settings"
                    className="buttonbtm2"
                    style={{
                      background: "transparent",
                      color:
                        currentRoot === RoutesObj.SpaSettings.path
                          ? "#49B8EF"
                          : "#FFFFFF",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: RoutesObj.SpaSettings.path,
                        state: {
                          SpaId: spaDeviceId,
                        },
                      });
                    }}
                    
                  /> */}
                  <AppBtn
                    text="Spa Settings"
                    className="buttonbtm2"
                    style={{
                      background: "transparent",
                      color:
                        currentRoot === RoutesObj.SpaSettingsNew.path
                          ? "#49B8EF"
                          : "#FFFFFF",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: RoutesObj.SpaSettingsNew.path,
                        state: {
                          SpaId: spaDeviceId,
                        },
                      });
                    }}
                  />
                </div>
              </span>
            </span>
          </div>
        </div>

        <CommonHeader
          spaDeviceId={spaDeviceId}
          currentRoot={currentRoot}
          history={history}
        />

        {/* <div className="Dashboardmain-container__innerBody deviceOverviewContainer">
          <div className="mx-5 info-wrap">
            <div className="d-flex align-items-start bg-white">
              <div className="col-md-3 pr-0">
                <div
                  className="nav flex-column nav-pills bor-right"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    onClick={() => {
                      this.setState({
                        activepane: <GeneralInformation />,
                        activePaneString: "GeneralInformation",
                      });
                    }}
                    className={`btn ${
                      this.state.activePaneString === "GeneralInformation"
                        ? "active"
                        : null
                    }`}
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    General information
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        activepane: <Global />,
                        activePaneString: "Global",
                      });
                    }}
                    className={`btn ${
                      this.state.activePaneString === "Global" ? "active" : null
                    }`}
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Global
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        activepane: <DailyTasks />,
                        activePaneString: "DailyTasks",
                      });
                    }}
                    className={`btn ${
                      this.state.activePaneString === "DailyTasks"
                        ? "active"
                        : null
                    }`}
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    Daily task
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        activepane: <Filter />,
                        activePaneString: "Filter",
                      });
                    }}
                    className={`btn ${
                      this.state.activePaneString === "Filter" ? "active" : null
                    }`}
                    id="v-pills-settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                  >
                    Filter
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        activepane: <Temperature />,
                        activePaneString: "Temperature",
                      });
                    }}
                    className={`btn ${
                      this.state.activePaneString === "Temperature"
                        ? "active"
                        : null
                    }`}
                    id="v-pills-settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                  >
                    Temperature
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        activepane: <Notifications />,
                        activePaneString: "Notifications",
                      });
                    }}
                    className={`btn ${
                      this.state.activePaneString === "Notifications"
                        ? "active"
                        : null
                    }`}
                    id="v-pills-settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                  >
                    Notifications
                  </button>
                </div>
              </div>
              <div className="col-md-9 pl-0">
                <div
                  className="tab-content padd-wrap ps-5 me-5 pb-5"
                  id="v-pills-tabContent"
                >
                  {this.state.activepane}
                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(SpaSettings));
