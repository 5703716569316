import React, { useState } from "react";
import { Tab, Paper } from "@material-ui/core";
import { TabList, TabPanel, TabContext } from "@material-ui/lab";
import WaterLifeReport from "./waterLifeReport";
import moment from "moment";

function Reporting() {
  const [active, SetActive] = useState("usageHistory");
  return (
    <div className="gridReportingWrapper">
      <Paper square>
        <TabContext value={active}>
          <TabList
            className="reportingTabs"
            variant="scrollable"
            onChange={(event, newValue) => {
              SetActive(newValue);
            }}
            textColor="primary"
          >
            <Tab label="Use History" value={"usageHistory"} />
            <Tab label="Temprature History" value={"tempratureHistory"} />
            <Tab label="Chemical History" value={"chemicalHistory"} />
            <Tab label="Water Life" value={"waterLife"} />
            <Tab label="Filter Life" value={"filterLife"} />
          </TabList>
          <TabPanel value="usageHistory" className="reportContent">
            {" "}
            <WaterLifeReport
              title="Usage History"
              data={{
                labels: ["1 Aug", "3 Aug", "5 Aug", "7 Aug", "9 Aug", "11 Aug"],
                datasets: [
                  {
                    data: [22, 11, 33, 44, 15, 56],
                    backgroundColor: "rgba(45, 156, 219, 1)",
                    barThickness: 30,
                    borderRadius: 4,
                  },
                ],
              }}
              dates={{
                to: moment("01-01-2022").format("MMMM d, YYYY"),
                from: moment("01-01-2021").format("MMMM d, YYYY"),
              }}
            />
          </TabPanel>
          <TabPanel value="tempratureHistory" className="reportContent">
            <WaterLifeReport
              title="Temprature History"
              data={{
                labels: ["1 Aug", "3 Aug", "5 Aug", "7 Aug", "9 Aug", "11 Aug"],
                datasets: [
                  {
                    data: [22, 11, 33, 44, 15, 56],
                    backgroundColor: "rgba(45, 156, 219, 1)",
                    barThickness: 30,
                    borderRadius: 4,
                  },
                ],
              }}
              dates={{
                to: moment("01-01-2022").format("MMMM d, YYYY"),
                from: moment("01-01-2021").format("MMMM d, YYYY"),
              }}
            />
          </TabPanel>
          <TabPanel value="chemicalHistory" className="reportContent">
            <WaterLifeReport
              title="Chemical History"
              data={{
                labels: ["1 Aug", "3 Aug", "5 Aug", "7 Aug", "9 Aug", "11 Aug"],
                datasets: [
                  {
                    data: [22, 11, 33, 44, 15, 56],
                    backgroundColor: "rgba(45, 156, 219, 1)",
                    barThickness: 30,
                    borderRadius: 4,
                  },
                ],
              }}
              dates={{
                to: moment("01-01-2022").format("MMMM d, YYYY"),
                from: moment("01-01-2021").format("MMMM d, YYYY"),
              }}
            />
          </TabPanel>
          <TabPanel value="waterLife" className="reportContent">
            <WaterLifeReport
              title="Water Life"
              data={{
                labels: ["1 Aug", "3 Aug", "5 Aug", "7 Aug", "9 Aug", "11 Aug"],
                datasets: [
                  {
                    data: [22, 11, 33, 44, 15, 56],
                    backgroundColor: "rgba(45, 156, 219, 1)",
                    barThickness: 30,
                    borderRadius: 4,
                  },
                ],
              }}
              dates={{
                to: moment("01-01-2022").format("MMMM d, YYYY"),
                from: moment("01-01-2021").format("MMMM d, YYYY"),
              }}
            />
          </TabPanel>
          <TabPanel value="filterLife" className="reportContent">
            <WaterLifeReport
              title="Filter Life"
              data={{
                labels: ["1 Aug", "3 Aug", "5 Aug", "7 Aug", "9 Aug", "11 Aug"],
                datasets: [
                  {
                    data: [22, 11, 33, 44, 15, 56],
                    backgroundColor: "rgba(45, 156, 219, 1)",
                    barThickness: 30,
                    borderRadius: 4,
                  },
                ],
              }}
              dates={{
                to: moment("01-01-2022").format("MMMM d, YYYY"),
                from: moment("01-01-2021").format("MMMM d, YYYY"),
              }}
            />
          </TabPanel>
        </TabContext>
      </Paper>
    </div>
  );
}

export default Reporting;
