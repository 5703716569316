import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  alertBulb,
  editSign,
  waterlife,
  plusSign,
  deleteIcon,
  tickIcon,
} from "../../../resources/images";
import { useSelector, useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { useLocation } from "react-router-dom";
import { Loader } from "../index";
import { toast } from "react-toastify";
import moment from "moment";
import FilterLifeCard from "./FilterLifeCard";
import WaterLifeCard from "./WaterLifeCard";

export default function WaterLifeDetail({
  backtowatertreatment,
  isActive,
  chemicalTestManage,
  filtermanage,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const spaDeviceId = location?.state?.SpaId;

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterDate, setFilterDate] = useState(Date.now());
  const [waterAdded, setWaterAdded] = useState("");
  const [gallonReplaced, setGallonReplaced] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  const [spaAllWaterLife, setSpaAllWaterLife] = useState([])

  useEffect(() => {
    if (isActive) {
      onLoadSpaWaterLife();
    }
  }, [isActive]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMaxDate(today);
  }, []);

  const onLoadSpaWaterLife = () => {
    setIsLoading(true);
    dispatch(SpaOverviewAction.onGetSpaAllWaterLife(spaDeviceId)).then(
      (data) => {
        setIsLoading(false);
        setSpaAllWaterLife(data.data)
        onGetWaterLifeStats()
      }
    );
  };

  const onGetWaterLifeStats = () => {
    dispatch(SpaOverviewAction.onGetWaterLifeStats(spaDeviceId));
  };

  const onUpdateWaterLife = () => {
    setIsEdit(!isEdit);
    setSelectedItem(null);
    const updatePayload = {
      id: selectedItem,
      testDate: moment(filterDate).format("MM/DD/yyyy"),
      waterAdded: waterAdded,
      gallonsReplaced: gallonReplaced,
      deviceId: spaDeviceId,
    };
    onAddUpdateWaterLife(updatePayload);
  };

  const onSaveWaterLife = () => {
    setIsEdit(false);
    setIsNewItem(false);
    const savePayload = {
      id: 0,
      testDate: moment(filterDate).format("MM/DD/yyyy"),
      waterAdded: waterAdded,
      gallonsReplaced: gallonReplaced,
      deviceId: spaDeviceId,
    };
    onAddUpdateWaterLife(savePayload);
  };

  const onAddUpdateWaterLife = (waterPayload) => {
    setIsLoading(true);
    dispatch(SpaOverviewAction.onAddUpdateWaterLife(waterPayload)).then(
      (data) => {
        if (data?.success) {
          toast.success(data?.message);
          onLoadSpaWaterLife();
        } else {
          setIsLoading(false);
          toast.error(data?.message);
        }
      }
    );
  };
  const onDeleteWaterLife = (value) => {
    const itemPayload = {
      ids: [value.id],
    };
    setIsLoading(true);
    dispatch(SpaOverviewAction.onDeleteWaterLife(itemPayload)).then((res) => {
      if (res?.success) {
        toast.success("Item deleted successfully");
        onLoadSpaWaterLife();
        // onLoadChemicalList();
        // onLoadGraphDetails();
        setSpaAllWaterLife(prevState =>
          prevState.filter(item => item.id !== value.id)
        );
      } else {
        setIsLoading(false);
        toast.error(res?.message);
      }
    });
  };
  const EditItem = (value) => {
    setSelectedItem(value.id);
    setFilterDate(value.date);
    setWaterAdded(value.waterAdded);
    setGallonReplaced(value.gallonsReplaced);
    setIsEdit(!isEdit);
    setIsNewItem(false);
  };

  const onPressAddNew = () => {
    setSelectedItem(null);
    setFilterDate(Date.now());
    setWaterAdded("");
    setGallonReplaced("");
    setIsEdit(false);
    setIsNewItem(true);
  };

  const handleChangeValue = (e) => {
    const enteredValue = e.target.value;

    if (enteredValue <= 100) {
      setWaterAdded(enteredValue);
    } else {
      toast.error("Value should be less then or equal to 100.");
    }
  };

  console.log(spaAllWaterLife, "spaAllWaterLife");
  return (
    <Grid
      item
      md={12}
      className="gridMainSpaWrapper px-3"
      style={{ backgroundColor: "#fff", borderRadius: 12 }}
    >
      {isLoading ? <Loader /> : null}
      <Grid item md={12}>
        <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperHeader"
          style={{ padding: "0" }}
        >
          <Grid
            item
            md={12}
            className="gridRow"
            style={{
              borderBottom: "1px solid rgba(161, 165, 183, 0.18)",
              paddingBottom: 15,
            }}
          >
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading filterLifeHeader"
              style={{ borderBottom: "none" }}
            >
              <Grid md={8}>
                <span
                  onClick={chemicalTestManage}
                  item
                  md={6}
                  className="customTabs"
                >
                  Chemical Testing
                </span>

                <span
                  onClick={filtermanage}
                  item
                  md={6}
                  className="customTabs"
                  style={{ marginLeft: "8%" }}
                >
                  Filter Life
                </span>

                <span
                  item
                  md={6}
                  className="customTabsColored customTabLine"
                  style={{ marginLeft: "8%" }}
                >
                  Water Life
                </span>
              </Grid>

              {/* <Grid>
                <span
                  item
                  md={4}
                  className="settingChange"
                  onClick={backtowatertreatment}
                >
                  back to water treatment
                </span>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <WaterLifeCard waterlifemanage={() => {}} />
        <div style={{ borderTop: "1px solid #BDBDBD" }} />

        <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperBody detailBody"
          style={{ padding: "20px ", marginTop: "60px" }}
        >
          <Grid item md={12} className="gridRow">
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading filterLifeHeader"
              style={{ borderBottom: "none", padding: "16px 38px 0 38px" }}
            >
              <span item md={6} className="heading">
                Your Refills
              </span>

              <span className="filterButtons">
                <button onClick={() => onPressAddNew()}>
                  <img src={plusSign} alt="" /> Add
                </button>
              </span>
            </Grid>

            <div className="filterGrid">
              <ul className="filterGridHeader">
                <li>DATE</li>
                <li>WATER ADDED</li>
                <li>GALLONS REPLACED</li>
              </ul>

              {spaAllWaterLife?.length > 0 &&
                spaAllWaterLife.map((item, i) => (
                  <ul className="filterGridContent" key={i}>
                    <>
                      {isEdit && selectedItem === item.id ? (
                        <>
                          <li className="dateHead">
                            <input
                              type="date"
                              max={maxDate}
                              value={new Date(`${filterDate}Z`)
                                .toISOString()
                                .slice(0, 10)}
                              style={{ width: "70%" }}
                              onChange={(e) => {
                                setFilterDate(e.target.value);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              type="text"
                              value={waterAdded}
                              onChange={(e) => {
                                setWaterAdded(e.target.value);
                                handleChangeValue(e);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              type="text"
                              value={gallonReplaced}
                              onChange={(e) =>
                                setGallonReplaced(e.target.value)
                              }
                            />
                          </li>
                          <span className="editDone">
                            <button onClick={() => onUpdateWaterLife()}>
                              <img src={tickIcon} alt="" /> Done
                            </button>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="deleteRow"
                            style={{ cursor: "pointer" }}
                            onClick={() => onDeleteWaterLife(item)}
                          >
                            <img src={deleteIcon} alt="" />
                          </span>
                          <li>{moment(item.date).format("MMM DD, YYYY")}</li>
                          <li>{item.waterAdded}%</li>
                          <li>{item.gallonsReplaced}</li>
                          <span className="editDone">
                            <button onClick={() => EditItem(item)}>
                              <img src={editSign} alt="" /> Edit
                            </button>
                          </span>
                        </>
                      )}
                    </>
                  </ul>
                ))}
              <ul className="filterGridContent">
                {isNewItem && (
                  <>
                    <span
                      className="deleteRow"
                      onClick={() => setIsNewItem(false)}
                    >
                      <img src={deleteIcon} alt="" />
                    </span>
                    <li>
                      <input
                        type="date"
                        max={maxDate}
                        value={filterDate}
                        style={{ width: "70%" }}
                        onChange={(e) => {
                          setFilterDate(e.target.value);
                        }}
                        // style={{ width: 110 }}
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        value={waterAdded}
                        onChange={(e) => {
                          setWaterAdded(e.target.value);
                          handleChangeValue(e);
                        }}
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        value={gallonReplaced}
                        onChange={(e) => setGallonReplaced(e.target.value)}
                      />
                    </li>
                    <span className="editDone">
                      <button onClick={() => onSaveWaterLife()}>
                        <img src={tickIcon} alt="" /> Done
                      </button>
                    </span>
                  </>
                )}
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
