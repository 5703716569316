export const constantColors = {
  theme: "#1bbed0",
  white: "#fff",
  gray: "#80808f",
  lightGray: "#f5f8fa",
  darkBlue: "#181c32",
  softGray: "#b5b5c3",
};

export default constantColors;
