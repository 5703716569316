export const spaOverviewTypes = {
  SET_SPA: "SET_SPA",
  SET_SELECT_LOCATION_SPA: "SET_SELECT_LOCATION_SPA",
  SPA_RESET_FIELDS: "SPA_RESET_FIELDS",
  ON_CHANGE_SPA: "ON_CHANGE_SPA",
  SPA_USER_DETAIL: "SPA_USER_DETAIL",
  SPA_DEVICES: "SPA_DEVICES",
  SPA_NOTIFICATION: "SPA_NOTIFICATION",
  SPA_GLOBAL_SCENCES: "SPA_GLOBAL_SCENCES",
  SUPPORT_RESOURCES: "SUPPORT_RESOURCES",
  USER_ICONS: "USER_ICONS",
  SPA_DEVICE_NAME: "SPA_DEVICE_NAME",
};
