import React, { Component } from "react";
import logo from "../../resources/images/logoReg.svg";
import { AccountsWizardMob } from "../../components/common/Stepper/AccountsWizardMob";
import { Box } from "@material-ui/core";

class UserRegistrationHeader extends Component {
  handleNext = (activeStep) => {
    this.props.onStepChange(activeStep);
  };

  render() {
    return (
      <div className="userRegustrationHeader-container">
        <div className="registrationContainer-header">
          <img src={logo} alt="" width="304px" height="68px" />
        </div>
        <div className="registrationInneatContentMob">
          <div className="dottedLineRegistrationHeader"></div>

          <AccountsWizardMob
            onNext={this.handleNext}
            activeStep={this.props.stepNumber}
            stepOnePrimary="Account Basics"
            stepOneSecondary=" Setup your account details"
            stepTowPrimary="Verify Email"
            stepTwoSecondary=" Confirm your email"
            stepThreePrimary="Contact Information"
            stepThreeSecondary="Used for warranty + support"
            stepFourPrimary="Finish Setup"
            stepFourSecondary="Notifications and settings"
          />
        </div>
      </div>
    );
  }
}

export default UserRegistrationHeader;
