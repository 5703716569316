import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import fotgotPassword from "../../resources/images/fotgotPassword.png";
import logo from "../../resources/images/logo.png";
import {
  FloatingLabelInput,
  AppBtn,
  Loader,
} from "../../components/common/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "../../store/accounts/accountActions";
import { RoutesObj } from "../../routes";
import { toast } from "react-toastify";
import textLogo from "../../assets/images/aqutic.png";

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      isValid: false,
      forgotEmail: "",
      isLoading: false,
    };
  }

  forgotPasswordPress = (email) => {
    this.setState({ isLoading: true });
    const { history } = this.props;
    this.props.actions.onForgotPassword(email).then((data) => {
      this.setState({ isLoading: false });
      if (data.status === 200 && data.data.success) {
        toast.success("Please check your mail box");
        history.push(RoutesObj.VerificationCode.path);
      } else {
        toast.error("Email you entered is incorrect");
      }
    });
  };

  onFocusOutFun = (value) => {
    if (
      value !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      this.setState({ isValid: true });
    }
  };

  render() {
    const { forgotEmail } = this.props.state;
    return (
      <div className="main-container">
        {this.state.isLoading && <Loader />}
        <div className="forgotPasswordLogin">
          <div className="logoContainer">
            <img className="loginLogoStyle" src={textLogo} alt="Logo" />
          </div>
          <div className="loginWrapper__loginFormWrapper ">
            <div className="loginTitle">
              <img src={fotgotPassword} alt="Logo" />
              <h3 className="forgotPassheading">Forgot Password?</h3>
            </div>
            <div className="gap"></div>

            <span className="loginWrapper__loginFormWrapper--inner_">
              <FloatingLabelInput
                placeholder="Email"
                className={this.state.isValid ? "errorOutline" : ""}
                value={forgotEmail}
                onBlur={() => this.onFocusOutFun(forgotEmail)}
                onChange={(e) => {
                  this.props.actions.onChange("forgotEmail", e.target.value);
                  this.setState({ isValid: false });
                }}
              />
              {this.state.isValid ? (
                <span style={{ color: "red" }}>
                  {forgotEmail !== "" ? "Invalid Email" : ""}
                </span>
              ) : null}
            </span>

            <span className="loginBtnWrapper">
              <AppBtn
                text="CONTINUE"
                onClick={() =>
                  forgotEmail !== "" && !this.state.isValid
                    ? this.forgotPasswordPress(forgotEmail)
                    : this.setState({ isValid: true })
                }
              />
            </span>
            <span className="loginWrapper__loginFormWrapper--inner">
              <div className="login_description">
                Need help?
                <span>
                  {" "}
                  <a
                    href="https://www.aquaticav.com/pages/contact"
                    target="_blank"
                    style={{ color: "#1bbed0" }}
                  >
                    Chat with us.
                  </a>
                </span>
              </div>
            </span>
          </div>
        </div>
        <div className="main-container__column-right">
          <div className="logoinnerCls">
            <img src={logo} alt="Logo" width="420px" height="101px" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
  })
)(withRouter(ForgotPassword));
