import React, { useState } from "react";
import { FloatingLabelInput, Loader } from "../../../components/common";
import Autocomplete from "react-google-autocomplete";
import { toast } from "react-toastify";
import { accountActions } from "../../../store/accounts/accountActions";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import moment from "moment";
import { useDispatch } from "react-redux";
import PhoneInput from "../../../components/common/PhoneNumber";
import { tree } from "../../../assets/images/images";
const ContactInfoForm = ({ onSuccess, user_record }) => {
  const [contactInfo, setContactInfo] = useState({
    address: "",
    city: "",
    stateCode: "",
    postalCode: "",
    country: "",
    phoneNumber: "",
    phoneErrorMsg: "",
    countryCode: 1,
    terms: false,
    termsError: false,
    nameError: false,
    firstNameError: false,
    lastNameError: false,
    cityError: false,
    stateError: false,
    countryError: false,
    phoneError: false,
    postalError: false,

    ////////
    isLoading: false,
  });

  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(1);
  const [countryCodeError, setCountryCodeError] = useState(false);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const onRegisterNewUser = () => {
    setContactInfo({ isLoading: true });
    const user_info = {
      city: this.state.city,
      address: this.state.address,
      zipCode: this.state.postalCode,
      phoneNumber: this.state.phoneNumber,
      country: this.state.country,
      state: this.state.stateCode,
      groupId: 0,
      customerTypeName: "",
      companyId: 0,
      isFavourite: false,
      isAdmin: false,
    };
  };
  var sha512 = require("js-sha512");

  const onRegisterUser = () => {
    setIsLoading(true);
    const userEmail = localStorage.getItem("userEmail");
    const userId = localStorage.getItem("userId");

    if (!contactInfo.city) {
      setIsLoading(false);
      toast.error("City is required");
      setContactInfo({ ...contactInfo, cityError: true });
      return;
    }
    if (!contactInfo.stateCode) {
      setIsLoading(false);
      toast.error("State code is required");
      setContactInfo({ ...contactInfo, stateError: true });
      return;
    }

    if (!contactInfo.postalCode) {
      setIsLoading(false);
      toast.error("Postal code is required");
      setContactInfo({ ...contactInfo, postalError: true });
      return;
    }

    if (!contactInfo.country) {
      setIsLoading(false);
      toast.error("Country is required");
      setContactInfo({ ...contactInfo, countryError: true });
      return;
    }

    if (!phoneNumber || phoneNumberError) {
      setIsLoading(false);
      // setContactInfo({ ...contactInfo, phoneError: true });

      setPhoneNumberError(true);
      return;
    }
    if (!countryCode || countryCodeError) {
      setCountryCodeError(true);
      return;
    }
    const user_info = {
      firstName: user_record?.firstName,
      lastName: user_record?.lastName,
      cityName: contactInfo.city,
      address: contactInfo.address,
      zipCode: contactInfo.postalCode,
      phoneNumber: phoneNumber,
      countryCode: countryCode,
      countryName: contactInfo.country,
      stateName: contactInfo.stateCode,
      email: userEmail,
      id: userId,
    };
    dispatch(dashboardActions.onUpdatePersonal(user_info)).then((data) => {
      setIsLoading(false);
      const user = localStorage.getItem("userInfo");
      const existingUserInfo = user ? JSON.parse(user) : {};
      const newUserInfo = {
        ...existingUserInfo,
        emailConfirmed: data?.data?.data.emailConfirmed,
        isSMSVerified: data?.data?.data.isSMSVerified,
        // Add other properties as needed
      };
      localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
      if (data.status === 200 && data.data.success) {
        toast.success("Congratulations you are successfully registered");
        onSuccess(data.data);
      } else {
        toast.error(
          "Something went wrong with your information, Please try again"
        );
        setIsLoading(false);
      }
    });
  };

  const onCheckContact = () => {
    setContactInfo({
      ...contactInfo,
      contactError: contactInfo.address === "",
      cityError: contactInfo.city === "",
      stateError: contactInfo.stateCode === "",
      postalError: contactInfo.postalCode === "",
      countryError: contactInfo.country === "",
      phoneError: contactInfo.phoneNumber === "",
    });
    if (phoneNumber === "") {
      setPhoneNumberError(true);
    }
  };
  const onPlaceSelected = (place) => {
    // setContactInfo({
    //   stateCode: "",
    //   country: "",
    //   city: "",
    //   postalCode: "",
    //   address:place.formatted_address
    // });

    // setContactInfo({ address: place.formatted_address });
    const address = place.address_components;
    let state, country, city, postalCode, addresss;

    address.forEach((component) => {
      if (component.types.includes("route")) {
        addresss = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
      if (component.types.includes("administrative_area_level_2")) {
        city = component.long_name;
      }
      if (component.types.includes("country")) {
        country = component.long_name;
      }
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });
    setContactInfo({
      stateCode: state,
      country: country,
      city: city,
      postalCode: postalCode ? postalCode : "",
      address: addresss,
      // isCityEditable: false,
      // isCountryEditable: false,
      // isStateEditable: false,
      // isPostalCodeEditable:false,
      // isManuallyEnter: false,
    });

    // enable city editing if the user starts editing the address manually
    // this.setState({ isCityEditable: false, isCountryEditable: false, isStateEditable: false, isPostalCodeEditable:false,});
    // const addressInput = document.getElementById("popupEnvLocInput");
    // addressInput.addEventListener("input", () => {
    //   this.setState({ isCityEditable: true, isCountryEditable: true, isStateEditable: true, isPostalCodeEditable:true, });
    //   addressInput.removeEventListener("input", () => { });
    // });
  };
  const onAddressValidation = (value) => {
    if (!/^[a-zA-Z]*(?:\s[a-zA-Z]+)*$/.test(value)) {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        contactError: true,
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        address: value,
        contactError: false,
      }));
    }
  };
  const onCityValidation = (value) => {
    // Allow alphabetic characters and spaces at the beginning, end, or between characters
    if (!/^[a-zA-Z]*(?:\s[a-zA-Z]+)*$/.test(value)) {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        cityError: true,
        city: value,
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        city: value,
        cityError: false,
      }));
    }
  };
  const onStateValidation = (value) => {
    // Allow alphabetic characters and spaces at the beginning, end, or between characters
    if (!/^[a-zA-Z]*(?:\s[a-zA-Z]+)*$/.test(value)) {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        stateError: true,
        stateCode: value,
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        stateCode: value,
        stateError: false,
      }));
    }
  };
  const onPostalCodeValidation = (value) => {
    // Allow alphanumeric characters and spaces at the beginning, end, or between characters
    if (!/^[a-zA-Z0-9]*(?:\s[a-zA-Z0-9]+)*$/.test(value)) {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        postalError: true,
        postalCode: value,
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        postalCode: value,
        postalError: false,
      }));
    }
  };
  const onCountryValidation = (value) => {
    // Allow alphabetic characters and spaces at the beginning, end, or between characters
    if (!/^[a-zA-Z]*(?:\s[a-zA-Z]+)*$/.test(value)) {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        countryError: true,
        country: value,
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        country: value,
        countryError: false,
        isManuallyEnter: true,
      }));
    }
  };
  const onPhoneNumberValidation = (e) => {
    const { value } = e.target;
    const purePhoneNumber = value.replace(/\D/g, "");
    if (purePhoneNumber.length < 7 || purePhoneNumber.length > 12) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
    setPhoneNumber(purePhoneNumber);
  };
  const handleCountryCodeChange = (value, countryCode) => {
    setCountryCode(countryCode.dialCode);
    if (!countryCode) {
      setCountryCodeError(true);
    }
  };

  return (
    <>
      <div className="userRegistration__tabs">
        <h2
          style={{
            marginBottom: 24,
            marginTop: 16,
            fontWeight: 600,
            fontSize: "30px",
            color: "#202A4C",
            fontFamily: "Poppins",
          }}
        >
          Contact Information
        </h2>
        <h4
          style={{
            fontSize: "22px",
            fontWeight: 600,
            color: "#181C32",
            fontFamily: "Poppins",
          }}
        >
          Tell Us How to Reach You
        </h4>
        <p
          className="environmentDesc"
          style={{
            fontSize: "18px !important",
            fontWeight: 400,
            color: "#000000 !important",
            fontFamily: "Poppins !important",
          }}
        >
          Provide us your full contact information so we can make warranty
          coverage easier and personalize your support experience. This
          information is strictly used for support purposes - we take your
          privacy seriously and won’t spam you or share/sell your information.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="adress-label addressLabelForContactInfo">
            Address <span style={{ color: "red" }}>*</span>
          </span>
          <Autocomplete
            id="popupEnvLocInput"
            className={
              contactInfo.address === "" && contactInfo.contactError
                ? "errorOutline location-input-registration"
                : "location-input-registration"
            }
            value={contactInfo.address}
            apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
            options={{
              types: ["address"],
            }}
            onChange={(event) => onAddressValidation(event.target.value)}
            mandatory
            onPlaceSelected={onPlaceSelected}
            style={{
              backgroundColor: "#F5F8FA",
              fontSize: "14px",
              marginBottom: "20px",
              height: "42px !important",
              color: "#5e6278",
              fontFamily: "Roboto",
              fontWeight: "500",
            }}
          />
        </div>

        <span className="groupFieldForContactInfo">
          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
            <FloatingLabelInput
              placeholder="City"
              value={
                // typeof city === "object"
                //   ? contactInfo.city.cityName
                //   :
                contactInfo.city
                // || ""
              }
              onChange={(event) => onCityValidation(event.target.value)}
              // disabled={city  && !this.state.isCityEditable && !this.state.isManuallyEnter}
              mandatory
              className={
                contactInfo.cityError ? "errorOutline" : "inputCustomStyling"
              }
              style={{ marginBottom: "5px" }}
              label="City"
            />
          </span>

          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
            <FloatingLabelInput
              placeholder="State"
              label="State"
              value={
                // typeof stateCode === "object"
                //   ? contactInfo.stateCode.cityName
                contactInfo.stateCode
              }
              onChange={(event) => onStateValidation(event.target.value)}
              className={
                contactInfo.stateError ? "errorOutline" : "inputCustomStyling"
              }
              // disabled={stateCode && !this.state.isStateEditable && !this.state.isManuallyEnter}
              mandatory
            />
          </span>
        </span>

        <span className="groupFieldForContactInfo">
          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
            <FloatingLabelInput
              placeholder="Postal Codes"
              label="Postal Codes"
              value={contactInfo.postalCode}
              // onBlur={() => this.onPostalValidation(postalCode)}
              className={
                contactInfo.postalError ? "errorOutline" : "inputCustomStyling"
              }
              onChange={(e) => onPostalCodeValidation(e.target.value)}
              mandatory
              // disabled={contactInfo.postalCode && !this.state.isPostalCodeEditable && !this.state.isManuallyEnter}
            />
          </span>

          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
            <FloatingLabelInput
              placeholder="Country"
              label="Country"
              value={
                // typeof country === "object"
                //   ? contactInfo.country.cityName
                //   :
                contactInfo.country
                // ||
                // ""
              }
              onChange={(e) => onCountryValidation(e.target.value)}
              // onChange={(event) =>
              //   setContactInfo({
              //     ...contactInfo,
              //     country: event.target.value,
              //     isManuallyEnter: true,
              //   })
              // }
              className={
                contactInfo.countryError ? "errorOutline" : "inputCustomStyling"
              }
              // disabled={contactInfo.country && !this.state.isCountryEditable && !this.state.isManuallyEnter}
              mandatory
            />
          </span>
        </span>
        <span className="groupFieldForContactInfo countryFlagPhoneWrapper">
          <span className="loginWrapper__loginFormWrapper--inner groupFields phoneVerification ">
            <h1 className="deviceDetaitInputLabel">
              Phone Number
              <span className="asterk"> *</span>
            </h1>

            <PhoneInput
              className={
                countryCodeError
                  ? "deviceInfoInput errorOutline"
                  : "deviceInfoInput"
              }
              label="Phone Number"
              placeholder="Please Enter Phone Number"
              style={{ xs: 12, lg: 4 }}
              id="contactPhone"
              name={countryCode}
              value={countryCode}
              // mandatory
              onChange={handleCountryCodeChange}
              readOnly={true}
            />

            <span className="loginWrapper__loginFormWrapper--inner groupFields phoneNumWrapper regPhoneNumber">
              <h1 className="deviceDetaitInputLabel"></h1>
              <input
                type="text"
                value={phoneNumber}
                name={`${phoneNumber}`}
                style={{ width: "100%" }}
                onChange={onPhoneNumberValidation}
                placeholder="Please Enter Phone Number"
                className={phoneNumberError ? " errorOutline" : " "}
              ></input>
            </span>
          </span>
          {phoneNumberError ? (
            <span
              style={{
                color: "red",
                fontSize: "14px",
                marginTop: "-30px",
                fontWeight: "500",
              }}
            >
              Phone number is required and must be valid *
            </span>
          ) : null}
        </span>
        <span className="footerNav ">
          {/* <button
                    id="pane1"
                    className="prevNavButton"
                    onClick={() =>
                      this.props.actions.onChange("currentPaneId", "pane1")
                    }
                  >
                    PREVIOUS
                  </button> */}
          {isLoading ? <Loader /> : null}
          <button
            id="pane2"
            className="nextNavButton"
            onClick={() => {
              // contactInfo.address !== "" &&
              //   contactInfo.city !== "" &&
              //   contactInfo.stateCode !== "" &&
              //   contactInfo.postalCode !== "" &&
              //   contactInfo.country !== "" &&
              //   contactInfo.phoneNumber !== "" &&
              onCheckContact();
              if (
                contactInfo.city !== "" &&
                contactInfo.address !== "" &&
                contactInfo.stateCode !== "" &&
                contactInfo.country !== "" &&
                contactInfo.postalCode !== "" &&
                contactInfo.phoneNumber !== "" &&
                phoneNumber !== "" &&
                !phoneNumberError
              ) {
                onRegisterUser();
              }
            }}
          >
            NEXT STEP
          </button>
        </span>
      </div>
    </>
  );
};
export { ContactInfoForm };
