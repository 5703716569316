import { PriceListTypes } from "./PriceListTypes";

const INITIAL_STATE = {
  priceList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case PriceListTypes.GET_PRICE_LIST: {
      return {
        ...state,
        priceList: action.payload,
      };
    }
    default:
      return state;
  }
};
