import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import verificationcode from "../../resources/images/verificationcode.png";
import logo from "../../resources/images/logo.png";
import {
  FloatingLabelInput,
  AppBtn,
  Loader,
} from "../../components/common/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "../../store/accounts/accountActions";
import { RoutesObj } from "../../routes";
import { toast } from "react-toastify";
import textLogo from "../../assets/images/aqutic.png";

class VerificationCode extends Component {
  constructor() {
    super();

    this.state = {
      isValid: false,
      veriCode: "",
      isLoading: false,
    };
  }

  onVeriCode = (code) => {
    const { history } = this.props;
    const forgotCode = localStorage.getItem("forgotCode");
    if (code === forgotCode) {
      localStorage.removeItem("forgotCode");
      history.push(RoutesObj.PasswordRest.path);
    } else {
      toast.error("Verification code is not correct");
    }
  };

  forgotPasswordPress = () => {
    this.setState({ isLoading: true });
    const { history } = this.props;
    const email = localStorage.getItem("userEmail");
    this.props.actions.onForgotPassword(email).then((data) => {
      this.setState({ isLoading: false });
      if (data.status === 200 && data.data.success) {
        toast.success("Please check your mail box");
        history.push(RoutesObj.VerificationCode.path);
      } else {
        toast.error("Email you entered is incorrect");
        history.push(RoutesObj.ForgotPassword.path);
      }
    });
  };

  render() {
    const { veriCode } = this.state;
    return (
      <div className="main-container">
        {this.state.isLoading && <Loader />}
        <div className="forgotPasswordLogin">
          <div className="logoContainer">
            <img className="loginLogoStyle" src={textLogo} alt="Logo" />
          </div>
          <div className="loginWrapper__loginFormWrapper">
            <div className="loginTitle verificationTitle">
              <img src={verificationcode} alt="Logo" />
              <h2 style={{ marginBottom: "20px" }}>Verification Code Sent!</h2>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  color: "#202A4C",
                  width: "101%",
                  marginBottom: "30px",
                }}
              >
                {
                  "A verification code has been sent to your e-mail. \nEnter the code below to reset your password."
                }
              </p>
            </div>

            <span className="loginWrapper__loginFormWrapper--inner_">
              <FloatingLabelInput
                className={this.state.isValid ? "errorOutline" : ""}
                value={veriCode}
                onChange={(e) =>
                  this.setState({ veriCode: e.target.value, isValid: false })
                }
              />
            </span>

            <span className="loginBtnWrapper" style={{ marginTop: "20px" }}>
              <AppBtn
                text="CONTINUE"
                onClick={() =>
                  veriCode !== ""
                    ? this.onVeriCode(veriCode)
                    : this.setState({ isValid: true })
                }
              />
            </span>

            <span className="loginWrapper__loginFormWrapper--inner">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  fontWeight: 400,
                  fontSize: "18px",
                  fontFamily: "Roboto",
                  color: "#202A4C",
                }}
              >
                Not received?{" "}
                <a
                  style={{
                    color: "#1bbed0",
                    marginLeft: 10,
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.forgotPasswordPress()}
                >
                  RESEND
                </a>
              </p>
              <div className="login_description" style={{ marginTop: 30 }}>
                Need help?{" "}
                <a
                  href="https://www.aquaticav.com/pages/contact"
                  target="_blank"
                  style={{ color: "#1bbed0" }}
                >
                  Chat with us.
                </a>
              </div>
            </span>
          </div>
        </div>
        <div className="main-container__column-right">
          <div className="logoinnerCls">
            <img src={logo} alt="Logo" width="420px" height="101px" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
  })
)(withRouter(VerificationCode));
