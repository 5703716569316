import _ from "lodash";
import { deviceOverviewTypes } from "./deviceOverviewTypes";

const INITIAL_STATE = {
  deviceOverviewData: {},
  fav_colors: [],
  addColors: [],
  deleteColors: [],
  isShowCircle: false,
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case deviceOverviewTypes.DEVICE_OVERVIEW_LOAD_DATA: {
      const newState = { ...state };
      newState.deviceOverviewData = action.payload.value;
      return newState;
    }
    case deviceOverviewTypes.FAV_COLORS: {
      const newState = { ...state };
      newState.fav_colors = action.payload.value;
      return newState;
    }
    case deviceOverviewTypes.SET_COLOR: {
      const newState = { ...state };
      const colorData = action.payload;
      newState.fav_colors.push(colorData.value);
      return newState;
    }
    case deviceOverviewTypes.DELETE_COLOR: {
      const newState = { ...state };
      const colorData = action.payload;
      const recordExist = newState.deleteColors.find(
        (x) => x === colorData.value
      );
      if (recordExist) {
        _.remove(newState.deleteColors, (color) => {
          return color === colorData.value;
        });
      } else {
        newState.deleteColors.push(colorData.value);
      }
      return newState;
    }
    case deviceOverviewTypes.DEVICEOVERVIEW_ONCHANGE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case deviceOverviewTypes.REMOVE_EXTRA_COLOR: {
      const newState = { ...state };
      _.forEach(newState.deleteColors, function (delColor) {
        _.remove(newState.fav_colors, (color) => {
          return color === delColor;
        });
      });
      newState.deleteColors = [];
      return newState;
    }
    case deviceOverviewTypes.ON_ADD_NEW_COLOR: {
      const newState = { ...state };
      const colorData = action.payload;
      newState.fav_colors.push(colorData.value);
      newState.addColors.push(colorData.value);
      return newState;
    }
    case deviceOverviewTypes.CLEAR_DELETE_COLOR: {
      const newState = { ...state };
      newState.deleteColors = [];
      return newState;
    }
    // case deviceOverviewTypes.ON_UPDATE_COLOR: {
    //   const newState = { ...state };
    //   newState.addColors = [];
    //   _.forEach(action.payload.value, function (color) {
    //     newState.fav_colors.push(color);
    //   });
    //   return newState;
    // }
    default:
      return state;
  }
};
