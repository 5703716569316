import { dealerLocatorTypes } from "./dealerLocatorTypes";

const INITIAL_STATE = {
  searchBy: "",
  sourceLat: localStorage.getItem("locationLat") || 44.8793886,
  sourceLong: localStorage.getItem("locationLong") || -91.4308606,
  dealerType: [],
  product: [],
  distance: 1000,
  dealerLocatorData: [],
  miles: [],
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case dealerLocatorTypes.DEALERLOCATOR_ONCHANGE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case dealerLocatorTypes.DEALERLOCATOR_DATA:
      return {
        ...state,
        dealerLocatorData: action.payload.value,
      };
    // case dealerLocatorTypes.DEALERLOCATOR_FILTER:
    //   return {
    //     ...state,
    //     dealerType: action.payload.dealertypedata,
    //     product: action.payload.envdata,
    //     miles: action.payload.milesdata,
    //   };
    default:
      return state;
  }
};
