import React, { useState, useEffect, useCallback } from "react";
import {
  PHSlider,
  ChlorineSlider,
  AlkalineSlider,
} from "./../Sliders/verticalCustomSlider";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import { useSelector, useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { Loader } from "../index";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { RoutesObj } from "../../../routes";
import { FilterIcon, FilterIcon2, FilterIcon3, filterIcon4 } from "./SvgIcons";
import { ReactComponent as Filter4 } from "../../../resources/svg/Filter4.svg";
import { ReactComponent as WaterDrop } from "../../../resources/svg/WaterDrop.svg";
import "./spaWaterTreatment.css";

export default function SpaWaterTreatment({
  isActive,
  isShowManageBtn,
  chemicalTestManage,
  noDate,
  titleToShow,
  getOwnSpaid
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const spaDeviceId = location?.state?.SpaId;

  const [isLoading, setIsloading] = useState(false);
  const [phValue, setPhValue] = useState(0);
  const [chlorineValue, setChlorineValue] = useState(0);
  const [alkalineValue, setAlkalineValue] = useState(0);
  const [lastChemicalDate, setLastChemicalDate] = useState("");
  const [latestData, setLatestData] = useState("");
  const [chemicalList, setChemicalList] = useState([]);

  const { spaWaterTreatmentR, spaWRTDeviceId } = useSelector(
    (state) => state.SpaOverviewReducer
  );
  const [configs, setConfigs] = useState({
    phMax: 100,
    phMin: 0,
    chlorineMax: 100,
    chlorineMin: 0,
    alkalinityMax: 100,
    alkalinityMin: 0,
  });

  useEffect(() => {
    if (spaWaterTreatmentR) {
      setPhValue(spaWaterTreatmentR?.ph);
      setChlorineValue(spaWaterTreatmentR?.chlorine);
      setAlkalineValue(spaWaterTreatmentR?.alkalinity);
    }
  }, [isActive, spaWaterTreatmentR]);

  useEffect(() => {
    onLoadCompleteData();
    onLoadCurrentChemicalList();
    onLoadChemicalList();
  }, []);

  const onLoadCurrentChemicalList = () => {
    dispatch(SpaOverviewAction.onGetFilterLifeStats(getOwnSpaid ? getOwnSpaid : spaDeviceId)).then(
      (data) => {
        console.log("current response:", data);
        setLastChemicalDate(data?.data?.lastCleaned);
      }
    );
  };

  const onChangePh = (value) => {
    setPhValue(value);
    const phPayload = {
      id: spaWRTDeviceId,
      ph: value,
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
    };
    onUpdateTreatmentValue(phPayload);
  };

  const onChangeChlorine = (value) => {
    setChlorineValue(value);
    const chlPayload = {
      id: spaWRTDeviceId,
      chlroine: value,
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
    };
    onUpdateTreatmentValue(chlPayload);
  };

  const onChangeAlkaline = (value) => {
    setAlkalineValue(value);
    const alKPayload = {
      id: spaWRTDeviceId,
      alkalinity: value,
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
    };
    onUpdateTreatmentValue(alKPayload);
  };

  const onUpdateTreatmentValue = (value) => {
    setIsloading(true);
    dispatch(SpaOverviewAction.onChangeWaterTreatment(value)).then((res) => {
      if (res?.success) {
        dispatch(SpaOverviewAction.onGetSpaInfo(getOwnSpaid ? getOwnSpaid : spaDeviceId)).then((data) => {
          setIsloading(false);
          if (data?.success === false) {
            toast.error(data?.message);
          } else {
            toast.success("Value updated successfully.");
          }
        });
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };
  const onLoadCompleteData = (showMsg, msgString) => {
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    dispatch(dashboardActions.onGetMyEnvironment(userId)).then(async (env) => {
      if (env.status === 200 && env.data.success) {
        let reqObj = {
          type: "type",
          userId: userId,
        };

        setIsloading(false);

        // await this?.props?.homeActions
        //   .onfetchNotification(reqObj)
        //   .then((res) => {});
        // this.setState({ isLoading: false });
      }
    });
  };
  const debouncedPhValue = useCallback(debounce(onChangePh, 500), []);
  const debouncedChlorineValue = useCallback(
    debounce(onChangeChlorine, 500),
    []
  );
  const debouncedAlkalineValue = useCallback(
    debounce(onChangeAlkaline, 500),
    []
  );
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  const onLoadChemicalList = () => {
    // setIsLoading(true);
    const fields = {
      deviceId: getOwnSpaid ? getOwnSpaid : spaDeviceId,
      pagination: {
        pageSize: 50,
        pageNo: 1,
        orderByKey: "Id",
        orderByVal: 0,
      },
    };
    dispatch(SpaOverviewAction.onGetSpaChemicalTestigList(fields)).then(
      (data) => {
        console.log("params--->Hash:", data.data);
        setLatestData(data?.data);
        // setIsLoading(false);
        setChemicalList(data?.data ? data?.data?.data : []);
      }
    );
  };

  const latestTest = latestData?.data
    ?.sort((a, b) => {
      const dateA = new Date(a.testDate);
      const dateB = new Date(b.testDate);
      return dateB - dateA;
    })
    .slice(0, 1)[0];

  return (
    <Grid item md={12} className="gridMainSpaWrapper px-3">
      {isLoading ? <Loader /> : null}
      <Grid item md={12} className="gridSpaWaterTreatmentWrapper">
        {/* <Grid item md={12} className="gridSpaWaterTreatmentWrapperHeader">
          <Grid item md={12} className="gridRow">
            <Grid item md={12} className="gridSpaWaterTreatmentHeading">
              <span item md={6} className="heading headingChem">
                {titleToShow ? titleToShow : "Water Treatment"}
              </span>
              {isShowManageBtn && (
                <span
                  item
                  md={6}
                  className="settingChange"
                  onClick={chemicalTestManage}
                >
                  MANAGE
                </span>
              )}
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item md={12} className="gridSpaWaterTreatmentWrapperBody">
          <Grid container className="gridRow">
            <Grid item md={12} className="gridSpaWaterTreatmentText">
              {/* {chemicalList?.length === 0 ? null : noDate ? null : (
                <span style={{ color: "#49B8EF", fontSize: 13 }}>{` (${moment(
                  latestTest?.testDate
                ).format("MMMM DD, YYYY")})`}</span>
              )} */}

              <Grid md={12} style={{ display: "flex", flexDirection: "row" }}>
                <Grid
                  md={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FilterIcon3 />
                  <span className="noOfDays">60</span>
                  <span className="daysText">Days</span>
                  <span className="daysText">since</span>
                  <span className="daysText">replaced</span>
                </Grid>
                <Grid
                  md={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Filter4 width={46} height={46} />
                  <span className="noOfDays">45</span>
                  <span className="daysText">Days</span>
                  <span className="daysText">since</span>
                  <span className="daysText">tested</span>
                </Grid>
                <Grid
                  md={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <WaterDrop width={46} height={46} />
                  <span className="noOfDays">53</span>
                  <span className="daysText">Days</span>
                  <span className="daysText">since</span>
                  <span className="daysText">replaced</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{ minHeight: 145 }}
              item
              md={12}
              className="gridSpaWaterTreatmentControlItem"
            >
              <span
                className="lastTestText"
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 20,
                }}
              >
                Last Test Results:
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PHSlider
                  value={latestTest?.ph}
                  min={configs.phMin}
                  max={configs.phMax}
                  step={0.1}
                  valueLabelDisplay={"off"}
                  onChange={(e, val) => {
                    setPhValue(val);
                    debouncedPhValue(val);
                  }}
                  disabled={true}
                />

                <Input
                  value={latestTest?.ph}
                  className="slider-input"
                  readOnly={true}
                  align={"middle"}
                  style={{ marginLeft: 5, backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ChlorineSlider
                  value={latestTest?.chlroine}
                  min={configs.chlorineMin}
                  max={configs.chlorineMax}
                  step={1}
                  valueLabelDisplay={"off"}
                  onChange={(e, val) => {
                    setChlorineValue(val);
                    debouncedChlorineValue(val);
                  }}
                  disabled={true}
                />
                <Input
                  value={latestTest?.chlroine}
                  className="slider-input"
                  readOnly={true}
                  align={"middle"}
                  style={{ marginLeft: 5, backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AlkalineSlider
                  value={latestTest?.alkalinity}
                  min={configs.alkalinityMin}
                  max={configs.alkalinityMax}
                  step={1}
                  valueLabelDisplay={"off"}
                  onChange={(e, val) => {
                    setAlkalineValue(val);
                    debouncedAlkalineValue(val);
                  }}
                  disabled={true}
                />
                <Input
                  value={latestTest?.alkalinity}
                  className="slider-input"
                  readOnly={true}
                  align={"middle"}
                  style={{ marginLeft: 5, backgroundColor: "transparent" }}
                />
              </div>
            </Grid>
            {/* <Grid item md={4} className="gridSpaWaterTreatmentControlItem">
             
            </Grid> */}
            {/* <Grid item md={4} className="gridSpaWaterTreatmentControlItem">
             
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
