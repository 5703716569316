import React, { Component } from "react";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import "../tabs.css";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoading: false,
      // isPlaying: "",
      // trackProgress: "",
      // activepane: "pane1",
    };
  }

  render() {
    const { isPlaying, trackProgress, isLoading } = this.state;
    const { spaOverviewReducer, dashboardState, homeState } = this.props;
    const { userInfo, userEnv } = dashboardState;
    const { history } = this.props;
    // let userNameS = "";
    // if (userInfo) {
    //   userNameS = `${userInfo.firstName}${`'s `}`;
    // }
    // console.log("spaOverviewReducer", spaOverviewReducer);
    return (
      <div
        className="tab-pane fade show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <h4>Notifications</h4>
        <form>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">All notifications</label>
            <div className="col-sm-5">
              <div className="d-flex justify-content-end">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="App, E-mail, Web"
                  // value="App, E-mail, Web"
                  disabled={true}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn edit text-right w-75"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">
              Gateway/Connections
            </label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="App, E-mail, Web"
                  // value="App, E-mail, Web"
                  disabled={true}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-75"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-4 col-form-label">
              Filter/Water Treatment
            </label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="App, E-mail, Web"
                  // value="App, E-mail, Web"
                  disabled={true}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-75"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>{" "}
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">Sensors/Warnings</label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="App, E-mail, Web"
                  // value="App, E-mail, Web"
                  disabled={true}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-75"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group row justify-content-between">
            <label className="col-sm-3 col-form-label">
              Heating/Temperature
            </label>
            <div className="col-sm-5">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="App, E-mail, Web"
                  // value="App, E-mail, Web"
                  disabled={true}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="btn text-right edit w-75"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(Notifications));
