import React from "react";
import { render } from "react-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import finishUpdate from "../../resources/images/finishUpdate.png"
import loding from "../../resources/images/loading.png"

const percentage = 25;

const CircleProgressBar = () => (
  <>
    <Example>
      <CircularProgressbar
        value={percentage}
        strokeWidth={12}
        text={`${percentage}%`}
      />
    </Example>
  </>
);

function Example(props) {
  return (
    <div className="circleProgressBarWrapper">
       <span className="downloadUpdate">
        <img src={finishUpdate} alt="" />
         Download Update</span>
      <span className="circleMainInner">
        <div className="circleProgressBarInner">{props.children}</div>
        <span className="progressDetail">Verify File </span>
      </span>
      <span className="sendToDevice">
        <img src={loding} alt="" />
        Send to Device</span>
    </div>
  );
}

export default CircleProgressBar;
