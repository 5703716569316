import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  alertBulb,
  editSign,
  filterlife,
  plusSign,
  deleteIcon,
  tickIcon,
} from "../../../resources/images";
import {useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { useLocation } from "react-router-dom";
import { Loader } from "../index";
import { toast } from "react-toastify";
import moment from "moment";
import FilterLifeCard from "./FilterLifeCard";

export default function FilterLifeDetail({
  backtowatertreatment,
  isActive,
  chemicalTestManage,
  waterlifemanage,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const spaDeviceId = location?.state?.SpaId;

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterDate, setFilterDate] = useState(new Date());
  const [filterClean, setFilterClean] = useState(false);
  const [filterReplaced, setFilterReplaced] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  const [spaAllFilterLife, setSpaAllFilterLife] = useState([])

  useEffect(() => {
    if (isActive) {
      onLoadSpaFilterLife();
    }
  }, [isActive]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMaxDate(today);
  }, []);


  const onGetFilterLifeStats = () => {
    dispatch(SpaOverviewAction.onGetFilterLifeStats(spaDeviceId));
  };
  const onLoadSpaFilterLife = () => {
    setIsLoading(true);
    dispatch(SpaOverviewAction.onGetSpaAllFilterLife(spaDeviceId)).then(
      (data) => {
        setSpaAllFilterLife(data.data)
        onGetFilterLifeStats();
        setIsLoading(false);
      }
    );
  };

  const EditItem = (value) => {
    setSelectedItem(value.id);
    setFilterDate(value.date);
    setFilterClean(value.cleaned);
    setFilterReplaced(value.replaced);
    setIsEdit(!isEdit);
    setIsNewItem(false);
  };
  const onPressAddNew = () => {
    setSelectedItem(null);
    setFilterDate(Date.now());
    setFilterClean(false);
    setFilterReplaced(false);
    setIsEdit(false);
    setIsNewItem(true);
  };

  const onUpdateFilterLife = () => {
    setIsEdit(!isEdit);
    setSelectedItem(null);
    const updatePayload = {
      id: selectedItem,
      testDate: moment(filterDate).format("MM/DD/yyyy"),
      cleaned: filterClean,
      replaced: filterReplaced,
      deviceId: spaDeviceId,
    };
    onAddUpdateFilterLife(updatePayload);
  };

  const onSaveFilterLife = () => {
    setIsEdit(false);
    setIsNewItem(false);
    const savePayload = {
      id: 0,
      testDate: moment(filterDate).format("MM/DD/yyyy"),
      cleaned: filterClean,
      replaced: filterReplaced,
      deviceId: spaDeviceId,
    };
    onAddUpdateFilterLife(savePayload);
  };

  const onDeleteWaterLife = (value) => {
    const itemPayload = {
      ids: [value.id],
    };
    setIsLoading(true);
    dispatch(SpaOverviewAction.onDeleteFilterLife(itemPayload)).then((res) => {
      if (res?.success) {
        toast.success("Item deleted successfully");
        onLoadSpaFilterLife();
        setSpaAllFilterLife(prevState =>
          prevState.filter(item => item.id !== value.id)
        );
      } else {
        setIsLoading(false);
        toast.error(res?.message);
      }
    });
  };
  const onAddUpdateFilterLife = (fltrPayload) => {
    setIsLoading(true);
    dispatch(SpaOverviewAction.onAddUpdateFilterLife(fltrPayload)).then(
      (data) => {
        if (data?.success) {
          toast.success(data?.message);
          onLoadSpaFilterLife();
        } else {
          setIsLoading(false);
          toast.error(data?.message);
        }
      }
    );
  };

  return (
    <Grid
      item
      md={12}
      className="gridMainSpaWrapper px-3"
      style={{ backgroundColor: "#fff", borderRadius: 12 }}
    >
      {isLoading ? <Loader /> : null}
      <Grid item md={12}>
        <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperHeader"
          style={{ padding: "0" }}
        >
          <Grid
            item
            md={12}
            className="gridRow"
            style={{
              borderBottom: "1px solid rgba(161, 165, 183, 0.18)",
              paddingBottom: 15,
            }}
          >
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading filterLifeHeader"
              style={{ borderBottom: "none" }}
            >
              <Grid md={8}>
                <span
                  item
                  md={6}
                  className="customTabs"
                  onClick={chemicalTestManage}
                >
                  Chemical Testing
                </span>

                <span
                  item
                  md={6}
                  className="customTabsColored customTabLine"
                  style={{ marginLeft: "8%" }}
                >
                  Filter Life
                </span>

                <span
                  onClick={waterlifemanage}
                  item
                  md={6}
                  className="customTabs"
                  style={{ marginLeft: "8%" }}
                >
                  Water Life
                </span>
              </Grid>

              {/* <Grid>
                <span
                  item
                  md={4}
                  className="settingChange"
                  onClick={backtowatertreatment}
                >
                  back to water treatment
                </span>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <FilterLifeCard filtermanage={() => {}} />
        <div style={{ borderTop: "1px solid #BDBDBD" }} />
        <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperBody detailBody"
          style={{
            padding: "20px ",
            marginTop: "60px",
          }}
        >
          <Grid item md={12} className="gridRow">
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading filterLifeHeader"
              style={{ borderBottom: "none", padding: "16px 38px 0 38px" }}
            >
              <span item md={6} className="heading TableHeading">
                Your Filters
              </span>

              <span className="filterButtons">
                <button onClick={() => onPressAddNew()}>
                  <img src={plusSign} alt="" /> Add
                </button>
              </span>
            </Grid>

            <div className="filterGrid">
              <ul className="filterGridHeader">
                <li className="dateHead_">DATE</li>
                <li>FILTER CLEANED</li>
                <li>FILTER REPLACED</li>
              </ul>
              {spaAllFilterLife?.length > 0 &&
                spaAllFilterLife.map((item, i) => {
                  return (
                    <ul className="filterGridContent" key={i}>
                      {isEdit && selectedItem === item.id ? (
                        <>
                          <li className="dateHead">
                            <input
                              type="date"
                              max={maxDate}
                              value={new Date(`${filterDate}Z`)
                                .toISOString()
                                .slice(0, 10)}
                              // style={{ width: "100%" }}
                              onChange={(e) => {
                                setFilterDate(e.target.value);
                              }}
                            />
                          </li>
                          <li>
                            <select
                              name="clean"
                              id="clean"
                              value={filterClean}
                              onChange={(e) =>
                                setFilterClean(JSON.parse(e.target.value))
                              }
                              className="select"
                            >
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                          </li>
                          <li>
                            <select
                              name="replace"
                              id="replaced"
                              value={filterReplaced}
                              onChange={(e) =>
                                setFilterReplaced(JSON.parse(e.target.value))
                              }
                              className="select"
                            >
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                          </li>
                          <span className="editDone">
                            <button onClick={() => onUpdateFilterLife()}>
                              <img src={tickIcon} alt="" /> Done
                            </button>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="deleteRow"
                            style={{ cursor: "pointer" }}
                            onClick={() => onDeleteWaterLife(item)}
                          >
                            <img src={deleteIcon} alt="" />
                          </span>
                          <li className="dateHead">
                            {moment(item.date).format("MMM DD, YYYY")}
                          </li>
                          <li>{item.cleaned ? "Yes" : "No"}</li>
                          <li>{item.replaced ? "Yes" : "No"}</li>
                          <span className="editDone">
                            <button onClick={() => EditItem(item)}>
                              <img src={editSign} alt="" /> Edit
                            </button>
                          </span>
                        </>
                      )}
                    </ul>
                  );
                })}
              <ul className="filterGridContent">
                {isNewItem && (
                  <>
                    <span
                      className="deleteRow"
                      onClick={() => setIsNewItem(false)}
                    >
                      <img src={deleteIcon} alt="" />
                    </span>
                    <li className="dateHead">
                      <input
                        type="date"
                        value={filterDate}
                        max={maxDate}
                        // style={{ width: "70%" }}
                        onChange={(e) => {
                          setFilterDate(e.target.value);
                        }}
                        // style={{ width: 100 }}
                      />
                    </li>
                    <li>
                      <select
                        name="clean"
                        id="clean"
                        value={filterClean}
                        onChange={(e) =>
                          setFilterClean(JSON.parse(e.target.value))
                        }
                        // style={{ width: "60px" }}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </li>
                    <li>
                      <select
                        name="replace"
                        id="replaced"
                        value={filterReplaced}
                        onChange={(e) =>
                          setFilterReplaced(JSON.parse(e.target.value))
                        }
                        // style={{ width: "60px"  }}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </li>
                    <span className="editDone">
                      <button onClick={() => onSaveFilterLife()}>
                        <img src={tickIcon} alt="" /> Done
                      </button>
                    </span>
                  </>
                )}
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
