import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  AppBtn,
  AppModal,
  Header,
  Loader,
} from "../../../components/common/index";
import { RoutesObj } from "../../../routes";
import { PriceListAction } from "../../../store/PriceList/PriceListAction";
import {dashboardActions} from "../../../store/dashboard/dashboardActions"
import DashboardPriceListBox from "../DashboardPriceListBox";
import { SearchBlue } from "../../../resources/images";

class PriceList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      currentPriceList: [],
      upcomingPriceList: [],
      expiredPriceList: [],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
   this.onLoadCompleteData();
    const data = {
      userId: JSON.parse(localStorage.getItem("userInfo"))?.id,
      companyId: JSON.parse(localStorage.getItem("userInfo"))?.companyId,
      groupId: JSON.parse(localStorage.getItem("userInfo"))?.groupId,
    };

    this.props.PriceListAction.onGetPriceList(data).then((response) => {
      console.log("api res:", response);
      if (response !== null && response.success === true) {
        let currentPriceList = [];
        let upcomingPriceList = [];
        let expiredPriceList = [];

        if (response.data.length) {
          response.data.forEach((value, index) => {
            if (value.availabilityPeriodStatus === "Expired") {
              expiredPriceList.push(value);
            }

            if (value.availabilityPeriodStatus === "Current") {
              currentPriceList.push(value);
            }

            if (value.availabilityPeriodStatus === "Upcoming") {
              upcomingPriceList.push(value);
            }
          });

          this.setState({
            currentPriceList,
            upcomingPriceList,
            expiredPriceList,
          });
        }
      } else {
        toast.error("Something went wrong, Please try again");
      }
    });

    this.setState({
      isLoading: false,
    });
  }
  async onLoadCompleteData(showMsg, msgString) {
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    await this.props.homeActions
      .onGetMyEnvironment(
        userId,
        JSON.parse(localStorage.getItem("userInfo")).customerType === 3
          ? JSON.parse(localStorage.getItem("userInfo")).companyId
          : false
      )
      .then(async (env) => {
        if (env.status === 200 && env.data.success) {
          let reqObj = {
            type: "type",
            userId: userId,
          };

          await this.props.homeActions
            .onfetchNotification(reqObj)
            .then((res) => {});
          this.setState({ isLoading: false });
        }
      });

    await this.onGetDashboardDataView();
  }
  render() {
    const { isLoading} = this.state;
    const {dashboardState } = this.props;
    const { userInfo, userEnv } = dashboardState;


    const { history} = this.props;
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    return (
      <div className="Dashboardmain-container">
        {isLoading && <Loader />}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userData ? userData : null}
            userEnvoirnment={userEnv}
            history={history}
          />

          <div className="mainTitle">
            <span>
              {" "}
              Aquatic AV Price Lists
              <span className="mainTitle__breadCrum">
                Catalog <i></i>Price Lists
              </span>
            </span>

            <div className="d-flex">
              <div className="d-flex align-items-center input-search">
                <img src={SearchBlue} />
                <input placeholder="Search Catalog"></input>
              </div>
            </div>
            {/* <div className="deviceBtn">
              <AppBtn
                text="Devices"
                onClick={() => history.push(RoutesObj.MyAccount.path)}
              />
            </div> */}
          </div>
        </div>

        <div className="dealer-text">
          <div>
            You can view Price Lists online, in the AAV App, as well as download
            in PDF or other formats where available.
          </div>
          {this.state.currentPriceList.length ? (
            <>
              <div>
                <strong style={{ fontSize: "25px" }}>
                  Current Price Lists
                </strong>
              </div>
              <ul>
                {this.state.currentPriceList.map((items, index) => {
                  return (
                    <li key={index}>
                      <DashboardPriceListBox
                        heading={items.priceListNameVisible}
                        isAllAccess={items.groupAccess.isAllAccess}
                        subheadeing={`${items.availabilityPeriodStatus} Price List`}
                        valid={items.validity}
                        onClick={() => {
                          history.push({
                            pathname: RoutesObj.PriceListDetail.path,
                            state: { priceList: items },
                          });
                        }}
                        priceListStateColor={"#27AE60"}
                        isViewable={items?.isViewable}
                      />
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}

          {this.state.upcomingPriceList.length ? (
            <>
              <div>
                <strong style={{ fontSize: "25px", marginRight: "25px" }}>
                  Upcoming Price Lists
                </strong>
              </div>
              <ul>
                {this.state.upcomingPriceList.map((items, index) => {
                  return (
                    <li key={index}>
                      <DashboardPriceListBox
                        heading={items.priceListNameVisible}
                        // heading="fsdhjfksdhfkjsdhfkjsdhfkdhksdfsdfsd"

                        isAllAccess={items.groupAccess.isAllAccess}
                        subheadeing={`${items.availabilityPeriodStatus} Price List`}
                        valid={items.validity}
                        onClick={() => {
                          history.push({
                            pathname: RoutesObj.PriceListDetail.path,
                            state: { priceList: items },
                          });
                        }}
                        priceListStateColor={"#9B51E0"}
                        isViewable={items?.isViewable}
                      />
                    </li>
                  );
                })}
              </ul>{" "}
            </>
          ) : null}

          {this.state.expiredPriceList.length ? (
            <>
              <div>
                <strong style={{ fontSize: "25px", marginRight: "25px" }}>
                  Expired Price Lists
                </strong>
              </div>
              <ul>
                {this.state.expiredPriceList.map((items, index) => {
                  return (
                    <li key={index}>
                      <DashboardPriceListBox
                        priceListStateColor={"#EB5757"}
                        heading={items.priceListNameVisible}
                        isAllAccess={items.groupAccess.isAllAccess}
                        subheadeing={`${items.availabilityPeriodStatus} Price List`}
                        valid={items.validity}
                        onClick={() => {
                          history.push({
                            pathname: RoutesObj.PriceListDetail.path,
                            state: { priceList: items },
                          });
                        }}
                        isViewable={items?.isViewable}
                      />
                    </li>
                  );
                })}
              </ul>{" "}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    PriceListReducer: state.PriceListReducer,
    dashboardState: state.DashboardReducer,

  }),
  (dispatch) => ({
    PriceListAction: bindActionCreators(PriceListAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),

  })
)(withRouter(PriceList));
