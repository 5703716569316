import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";
var sha512 = require("js-sha512");

async function getData(email, envName) {
  return axios
    .get(
      `${endpoints.device.getDevicesOverView}${email}${"&env_name="}${envName}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With, content-type",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}
async function onDeleteColorCollection(colorCollection) {
  return axios
    .post(
      endpoints.dashboard.deleteEnvFavColor,
      JSON.stringify({
        ids: colorCollection,
      })
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function onAddColorCollection(colorReq) {
  return axios
    .post(endpoints.dashboard.addUpdateEnvFavColor, JSON.stringify(colorReq))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function onChangeColorCollection(
  device_id,
  colorCollection,
  operation_type
) {
  var data = JSON.stringify({
    device_id: device_id,
    fav_colors: colorCollection,
    operation_type: operation_type,
  });
  var config = {
    method: "post",
    url: endpoints.device.updateColorCollection,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function getEnvFavColors(env) {
  console.log("env id:", env);
  return axios
    .post(
      endpoints.dashboard.getEnvFavColor,
      JSON.stringify({
        id: env,
      })
    )
    .then((response) => {
      console.log("env fav colors:", response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function getDealers(search, catId) {
  return axios
    .post(endpoints.dealerLocator.getUserDelears, {
      categoryId: catId,
      input: search,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

export const deviceOverviewApi = {
  getData,
  onChangeColorCollection,
  onDeleteColorCollection,
  onAddColorCollection,
  getEnvFavColors,
  getDealers,
};
