import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getUserRoomsByUsersId(data) {
  return axios
    .post(endpoints.conversation.getUserRoomsByUsersId, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return null;
    });
}
async function getChatDetailsByRoomId(data) {
  return axios
    .post(endpoints.conversation.getChatDetailsByRoomId, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return null;
    });
}
async function makeRoomFavorite(data) {
  return axios
    .post(endpoints.conversation.makeRoomFavorite, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return null;
    });
}
async function createRoom(data) {
  return axios
    .post(endpoints.conversation.createRoom, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return null;
    });
}
async function deleteRooms(data) {
  return axios
    .post(endpoints.conversation.deleteRooms, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return null;
    });
}
async function getChatUsers(data) {
  return axios
    .post(endpoints.conversation.getChatUsers, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return [];
    });
}
async function getAllDealors(data) {
  return axios
    .post(endpoints.conversation.getDealors, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return [];
    });
}
async function sendMessageViaApi(data) {
  return axios
    .post(endpoints.conversation.sendMessageApi, data)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.error("errorMessage ", error);
      return null;
    });
}

async function uploadFiles(data) {
  var config = {
    method: "post",
    url: endpoints.conversation.uploadFile,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function updateCampaignIdInRoomAPI(data) {
  return axios
    .post(endpoints.conversation.updateCampaignIdInRoom, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return null;
    });
}

async function getCampaignsDdl(data) {
  return axios
    .post(endpoints.conversation.getCampaignsDdl, data)
    .then((response) => response?.data)
    .catch((error) => {
      console.error("error : ", error);
      return null;
    });
}

async function createLeadsForShopiy(data) {
  return axios
    .post(endpoints.conversation.createLeadsForShopiy, data)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.error("errorMessage ", error);
      return null;
    });
}

export const conversationApi = {
  getUserRoomsByUsersId: getUserRoomsByUsersId,
  getChatUsers: getChatUsers,
  getChatDetailsByRoomId: getChatDetailsByRoomId,
  makeRoomFavorite: makeRoomFavorite,
  deleteRooms: deleteRooms,
  uploadFiles: uploadFiles,
  sendMessageViaApi: sendMessageViaApi,
  updateCampaignIdInRoomAPI,
  getCampaignsDdl,
  createRoom: createRoom,
  getAllDealors:getAllDealors,
  createLeadsForShopiy: createLeadsForShopiy,
};
