import React, { useState } from "react";
import { getFirstCharOfString } from "../../../components/common/CommonMethods";

const MblContactCard = ({
  setIsMblNewChat,
  allDealors,
  handleCreateNewRoom,
}) => {
  const createNewRoom = (item) => {
    handleCreateNewRoom(item);
    setIsMblNewChat(false);
  };
  const [adminChatPayload, setAdminChatPayload] = useState({
    //For Production
    id: "5e63f328-1876-4771-bcb6-53f59f29f8a3",
    //For Dev
    //id: "d5083803-29d5-4bf0-bce7-fbaab34011a6",
    name: "AQUATIC AV",
  });
  return (
    <>
      {/* {allDealors?.map((item) => (
        <div
          className="contact-card"
          onClick={() => createNewRoom(item)}
          key={item.id}
        >
          <div className="recipient-img contact-view">
            <span className="text-uppercase">
              {getFirstCharOfString(item.name)}
            </span>
          </div>
          <div className="contact-info">
            <h4>{item.name}</h4>
            <p>{item.generic}</p>
          </div>
        </div>
        
      ))} */}
      <div
        className="contact-card"
        onClick={() => createNewRoom(adminChatPayload)}
        // key={}
      >
        <div className="recipient-img contact-view">
          <span className="text-uppercase">{getFirstCharOfString("A")}</span>
        </div>
        <div className="contact-info">
          <h4>AQUATIC AV</h4>
          <p>Admin</p>
        </div>
      </div>
    </>
  );
};

export default MblContactCard;
