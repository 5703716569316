import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { filterlife1, alertBulb, TestTube } from "../../../resources/images";
import { useSelector } from "react-redux";

export default function ChemicalTestingCard({
  chemicalTestManage,
  days,
  recommendedDate,
}) {
  const { filterLifeStats } = useSelector((state) => state.SpaOverviewReducer);
  // const endDate = new Date();
  const currentDate = new Date();
  const daysLeft = (day) => {
    let leftday = 30 - day;
    return leftday;
  };

  return (
    <div onClick={chemicalTestManage} style={{ cursor: "pointer" }}>
      <Grid
        item
        md={12}
        className="gridMainSpaWrapper "
        style={{ marginTop: "1rem" }}
      >
        <Grid item md={12} style={{ background: "#fff", borderRadius: "12px" }}>
          {/* <Grid
          item
          md={12}
          className="gridSpaPumpsWrapperHeader"
          style={{ padding: "0" }}
        >
          <Grid item md={12} className="gridRow">
            <Grid
              item
              md={12}
              className="gridSpaWaterTreatmentHeading px-3 filterLifeHeader"
            >
              <span item md={6} className="heading headingFilter">
                Filter Life
              </span>
              <span
                item
                md={6}
                className="settingChange"
                onClick={filtermanage}
              >
                MANAGE
              </span>
            </Grid>
          </Grid>
        </Grid> */}
          <Grid
            item
            md={12}
            className="gridSpaPumpsWrapperBody"
            style={{ padding: "20px " }}
          >
            <Grid
              item
              md={12}
              className="gridRow"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                md={12}
                xs={12}
                className="gridSpaPumpsControlItem watertreatmentLeftWrapper"
              
              >
                <Grid item md={7} xs={12}>
                  <div className="filterLifeInnerWrapper detailInnerFilter">
                    <span>
                      <img
                        src={TestTube}
                        alt=""
                        style={{
                          filter: "grayscale(0)",
                          // backgroundColor: "#1BBED0",
                        }}
                      />
                    </span>

                    <span>
                      <div className="bigTitle">
                        {days ? days: days} {" "}
                        {days > 1 ? "Days" : "Day"}
                      </div>
                      <div className="filterLifeSubHeading">
                        since last test
                      </div>
                      <div className="filterLifeCardText">
                        Recommend next test date:
                      </div>
                      <div className="filterLifeCardDate">
                        <span style={{ fontStyle: "italic" }}>
                          {Math.abs(daysLeft(days)) !== 1
                            ? `${daysLeft(days)} days away`
                            : `${daysLeft(days)} day away`}
                        </span>{" "} |
                        {" "} 
                        {`${
                          recommendedDate
                            ? recommendedDate
                            : "September 1, 2021"
                        }`}
                      </div>
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  md={5}
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    className="lifeTip"
                    style={{ width: "80%", height: 150 }}
                  >
                    <span className="tipHeading">
                      Chemical Testing Tip <img src={alertBulb} alt="" />
                    </span>
                    <p className="chemicalTextNote">
                      Extend water life by keeping your chemicals in the green
                      and refilling your water every four months.
                    </p>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
