import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
// import { CircleSlider } from "react-circle-slider";
import { MyRoundSlider } from "./../RoundSlider/RoundSlider";
import Grid from "@material-ui/core/Grid";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { useLocation } from "react-router-dom";
import { Loader } from "../index";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import "./tempratureCard.css";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import { lockIcon } from "../../../assets/images/images";

export default function TempratureCard({ isActive, getOwnSpaid }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const spaDeviceId = getOwnSpaid || location?.state?.SpaId;
  const ref = useRef(null);
  console.log(getOwnSpaid, "getOwnSpaid");
  const { spaTemperatureSettings, spaGlobalSettings } = useSelector(
    (state) => state.SpaOverviewReducer
  );
  const [isLoading, setIsloading] = useState(false);
  const [temprature, setTemprature] = useState(0);
  const [tempratureRange, setTempratureReange] = useState({
    min: 80,
    max: 104,
  });
  const [tempratureScale, setTempratureScale] = useState("F");
  // const [temperature, setTemperature] = useState(spaTemperatureSettings?.currentTemperature);
  const [tempWidth, setTempWidth] = useState(0);

  useLayoutEffect(() => {
    setTempWidth(ref.current.offsetWidth);
  }, []);
  useEffect(() => {
    if (isActive) {
      if (spaTemperatureSettings?.temperatureControl === 1) {
        setTempratureScale("F");
      } else {
        setTempratureScale("C");
      }
      setTemprature(spaTemperatureSettings?.setTemperature);
    }
    // if (isActive) {
    //   if (spaTemperatureSettings?.temperatureControl === 1) {
    //     setTempratureScale("F");
    //     let farh = spaTemperatureSettings?.setTemperature * 1.8 + 32;
    //     setTemprature(farh.toFixed(2));
    //   } else {
    //     let celcius = ((spaTemperatureSettings?.setTemperature - 32) * 5) / 9;
    //     setTemprature(celcius.toFixed(2));
    //     setTempratureScale("C");
    //   }
    //   // setTemprature(spaTemperatureSettings?.setTemperature);
    // }
  }, [isActive, spaTemperatureSettings]);

  const decreaseTemp = (e) => {
    e && e.preventDefault();
    if (temprature - 1 >= tempratureRange.min) {
      const aa = temprature - 1;
      setTemprature(temprature - 1);
      onIncDecDebounce(aa);
    }
  };

  const increseTemp = (e) => {
    e && e.preventDefault();
    if (temprature + 1 <= tempratureRange.max) {
      const aa = temprature + 1;
      if (temprature === 0) {
        const tempVal = tempratureScale === "F" ? 80 : 26.6;
        setTemprature(tempVal);
        onIncDecDebounce(tempVal);
      } else {
        setTemprature(temprature + 1);
        onIncDecDebounce(aa);
      }
    }
  };

  const onUpdateTemVal = (value) => {
    const tempPayload = {
      deviceId:  spaDeviceId,
      setTemperature: value,
    };
    dispatchTempCall(tempPayload);
  };
  const dispatchTempCall = (value) => {
    setIsloading(true);
    dispatch(SpaOverviewAction.onUpdataTempSetting(value)).then((res) => {
      if (res?.success) {
        dispatch(
          SpaOverviewAction.onGetSpaInfo( spaDeviceId)
        ).then((data) => {
          setIsloading(false);
          if (data?.success === false) {
            toast.error(data?.message);
          } else {
            toast.success("Value updated successfully.");
          }
        });
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };

  const handleScaleChange = (event, newVal) => {
    event && setTempratureScale(newVal);
    const scalePayload = {
      deviceId: spaDeviceId,
      temperatureControl: newVal === "C" ? 2 : 1,
    };
    dispatchTempCall(scalePayload);

    // let temprature = spaTemperatureSettings?.setTemperature;
    // console.log("-----", newVal, temprature);
    // event && setTempratureScale(newVal);

    // if (newVal == "C") {
    //   let celcius = ((temprature - 32) * 5) / 9;
    //   setTemprature(celcius.toFixed(2));
    // } else {
    //   let farh = temprature * 1.8 + 32;
    //   setTemprature(farh.toFixed(2));
    // }

    // // setTemprature((temprature * 9) / 5 + 32);
    // setTempratureScale(newVal ? newVal : "F");
    // const scalePayload = {
    //   deviceId: spaDeviceId,
    //   temperatureControl: newVal === "C" ? 2 : 1,
    // };
    // dispatchTempCall(scalePayload);
  };

  function handleCelsiusToFahrenheit() {
    setTemprature((temprature * 9) / 5 + 32);
    setTempratureScale("F");
  }

  function handleFahrenheitToCelsius() {
    setTemprature(((temprature - 32) * 5) / 9);
    setTempratureScale("C");
  }

  const tempConversion = (temprature) => {
    if (tempratureScale === "C" && temprature !== 0) {
      let celsius = ((temprature - 32) * 5) / 9;
      console.log("conversion:", celsius);
      return celsius?.toFixed(2);
    } else {
      return temprature;
    }
  };

  const onIncDecDebounce = useCallback(debounce(onUpdateTemVal, 1000), [spaDeviceId]);
  return (
    <Grid item md={12} className="gridMainSpaWrapper px-3">
      {isLoading ? <Loader /> : null}
      <Grid item md={12} className="gridSpaTempratureWrapper width-wrap">
        <Grid item md={12} className="gridSpaTempratureWrapperBody">
          <Grid item md={12} className="gridRow">
            <Grid item md={4} xs={4} className="gridTempratureControlSection">
              <div className="roundslider">
                <span className="TempTopValue">
                  {tempratureScale === "F" ? 104 : 40}
                </span>
                <MyRoundSlider
                  value={temprature}
                  min={tempratureRange.min}
                  max={tempratureRange.max}
                  step={1}
                  radius={100}
                  startAngle={105}
                  arcLength={150}
                  handleSize={7}
                  rtl={true}
                  valueChanged={(e) => {
                    if (spaTemperatureSettings?.isMaintenanceLock === false) {
                      spaTemperatureSettings?.isTemperatureLock === false &&
                        setTemprature(e.detail.value);
                      onIncDecDebounce(e.detail.value);
                    }
                  }}
                  disabled={
                    spaTemperatureSettings?.isTemperatureLock === true ||
                    spaTemperatureSettings?.isMaintenanceLock === true
                      ? true
                      : false
                  }
                />
                <span className="TempbottomValue">
                  {tempratureScale === "F" ? 80 : 26.6}
                </span>
              </div>
            </Grid>

            <Grid item md={8} className="gridTempratureInfoSection">
              <Grid item md={12}>
                <h3 className="currentTemprature">
                  {tempConversion(
                    spaTemperatureSettings?.currentTemperature || 0
                  )}
                  &deg;
                  {/* {temprature} */}
                  {tempratureScale ? tempratureScale : "F"}
                </h3>
                <p
                  style={{
                    color: "#A1A5B7",
                    fontSize: 14,
                    fontWeight: 600,
                    marginBottom: 5,
                  }}
                >
                  Current Water Temperature
                </p>

                <div className="temp-Adjusting">
                  <span className="circleStyle"> </span>
                  <span>ADJUSTING</span>
                </div>

                <div
                  className="tempratureButtonControls tempBoxSetting"
                  ref={ref}
                >
                  <div className="incrementBox">
                    <Remove
                      onClick={(e) => {
                        if (
                          spaTemperatureSettings?.isTemperatureLock !== true &&
                          spaTemperatureSettings?.isMaintenanceLock !== true
                        ) {
                          decreaseTemp(e);
                        }
                      }}
                      style={{
                        color: "#ffff",
                        fontSize: "30",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <b className="temperature-reading">
                    {temprature ? tempConversion(temprature) : 0}&deg;
                    {tempratureScale ? tempratureScale : "F"}
                  </b>

                  <div className="incrementBox">
                    <Add
                      onClick={(e) => {
                        if (
                          spaTemperatureSettings?.isTemperatureLock === false &&
                          spaTemperatureSettings?.isMaintenanceLock === false
                        ) {
                          increseTemp(e);
                        }
                      }}
                      style={{
                        color: "#ffff",
                        fontSize: "30",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <div className="lockRow" style={{ width: tempWidth }}>
                  {spaTemperatureSettings?.isTemperatureLock === true ||
                  spaTemperatureSettings?.isMaintenanceLock === true ? (
                    <img
                      style={{
                        cursor: "pointer",
                      }}
                      src={lockIcon}
                      alt="lock"
                    />
                  ) : (
                    <LockOpenOutlinedIcon
                      style={{
                        color: "#4ca5c1",
                        fontSize: "40",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
