import React, { useState } from "react";
import { AppModal } from ".";
import { sessioncock } from "../../resources/images/index";

function SessionPopup({
  closeModal,
  heading,
  icon,
  bodytext,
  expiryMin,
  logout,
  continueSession,
}) {
  return (
    <div className="sessionTimeoutWrapper">
      <AppModal title="Modal" handleclose={closeModal}>
        <div className="sessionInner">
          <img src={sessioncock} alt="" />
          <span>
            <h2>Session Timeout</h2>
            <p>
              Your session will expire soon. <br />
              You will be logged out in {expiryMin || 0} minutes.{" "}
            </p>
          </span>
        </div>
        <span className="footerNav sessionFooterInner">
          <button onClick={logout}>Logout</button>
          <button onClick={continueSession}>Continue Session</button>
        </span>
      </AppModal>
    </div>
  );
}

export default SessionPopup;
