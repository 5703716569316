import { accountTypes } from './accountTypes';

const INITIAL_STATE = {
    email: '',
    password: '',
    errorMessage: '',
    isLoading: false,
    currentPaneId: 'pane1',
    forgotCode: '',
    forgotEmail: ''
};

export default (state, action) => {
    if (!state) state = INITIAL_STATE;
    switch (action.type) {
        case accountTypes.ACCOUNT_LOGIN_ONCHANGE:
            return {
                ...state,
                errorMessage: '',
                [action.payload.prop]: action.payload.value
            };

        case accountTypes.ACCOUNT_LOGIN_ERROR:
            return { ...state, isLoading: false };

        case accountTypes.ACCOUNT_LOGOUT_SUCCESS:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};