import React from "react";
import "./stepper.css";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  makeStyles,
  StepIcon
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// const useStyles = makeStyles(() => ({
//   root: {
//     backgroundColor: "transparent",
//     "& .MuiStepIcon-active": {
//       fontFamily: "Poppins",
//       fontSize: "19px",
//       fontWeight: "600",
//       ineHeight: "19px",
//       letterSpacing: "0em",
//       textAlign: "center",
//       color: "#ffffff",
//     },
//     "& .MuiStepIcon-completed": {
//       color: "#1BBED0",
//       width: 48,
//       height: 48,
//       borderRadius: "10px",
//     },
//     "& .Mui-disabled .MuiStepIcon-root": {
//       color: "#15192E",
//       width: 48,
//       height: 48,
//       borderRadius: "10px",
//     },
//     "& .MuiStepConnector-vertical": {
//       marginLeft: 25,
//       marginTop: 10,
//     },
//     "& .MuiStepConnector-lineVertical": {
//       borderLeftStyle: "dashed",
//       borderLeftWidth: 1,
//       color: "#ffffff",
//     },
//     // "& .MuiStepLabel-iconContainer": {
//     //   backgroundColor: "#1BBED0",
//     //   padding: 0,
//     //   borderRadius: 4,
//     // },
//     // "& .Mui-disabled": {
//     //   backgroundColor: "#000",
//     // },
//   },
// }));

const AccountsWizardMob = ({
  activeStep = 2,
  setActiveStep = () => {},
  stepOnePrimary,
  stepOneSecondary,
  stepTowPrimary,
  stepTwoSecondary,
  stepThreePrimary,
  stepThreeSecondary,
  stepFourPrimary,
  stepFourSecondary,
}) => {
  // const style = useStyles();
  // console.log(style,"style")

  return (
    <Box className="custom-class-for-steps">
      <Stepper activeStep={activeStep} orientation="row">
        <Step className="steps">
          <StepLabel>
            <Typography className="stepTitle">{stepOnePrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepOneSecondary}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography className="stepTitle">{stepTowPrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepTwoSecondary}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography className="stepTitle"> {stepThreePrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepThreeSecondary}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography className="stepTitle">{stepFourPrimary}</Typography>
            <Typography className="stepSmallTitle">
              {stepFourSecondary}
            </Typography>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

export { AccountsWizardMob };
