import React, { Component } from "react";
import OverViewCard from "../../../components/common/SpaProduct/overViewCard";
import SpaWaterTreatment from "../../../components/common/SpaProduct/spaWaterTreatment";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import Grid from "@material-ui/core/Grid";
import { Header, Loader } from "../../../components/common/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import AppBtn from "../../../components/common/AppBtn/AppBtn";
import { RoutesObj } from "../../../routes";
import WaterTreatmentResources from "../../../components/common/SpaProduct/WaterTreatmentResources";
import "../tabs.css";
import FilterLifeCard from "../../../components/common/SpaProduct/FilterLifeCard";
import WaterLifeCard from "../../../components/common/SpaProduct/WaterLifeCard";
import FilterLifeDetail from "../../../components/common/SpaProduct/FilterLifeDetail";
import WaterLifeDetail from "../../../components/common/SpaProduct/WaterLifeDetail";
import ChemicalTestingDetail from "../../../components/common/SpaProduct/ChemicalTestingDetail";
import { toast } from "react-toastify";
import ChemicalTestingCard from "../../../components/common/SpaProduct/ChemicalTestingCard";
import CommonHeader from "../CommonHeader";
import constantColors from "../../../components/common/colorConstants";

let spaDeviceId;
class WaterTreatment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPlaying: "",
      trackProgress: "",
      activepane: "pane1",
      isFilterLife: false,
      isWaterLife: false,
      isWaterTreatment: true,
      isChemicalTesting: true,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location?.state?.SpaId) {
      this.onGetFilterLifeStats(location.state.SpaId);
      this.onGetWaterLifeStats(location.state.SpaId);
      this.getSpaOverviewData(location.state.SpaId);
      spaDeviceId = location.state.SpaId;
      if (location?.state?.isShowChemicalTesting) {
        this.ShowChemicalTest();
      }
    } else {
      window.location = RoutesObj.Dashboard.path;
      toast.error("Please select Spa Device");
    }
  }
  async getSpaOverviewData(SpaId) {
    this.setState({ isLoading: true });
    await this.props.actions.onGetSpaInfo(SpaId).then(async (env) => {
      this.setState({ isLoading: false });
      if (env.success) {
      }
    });
  }
  onGetFilterLifeStats(SpaId) {
    this.props.actions.onGetFilterLifeStats(SpaId);
  }
  onGetWaterLifeStats(SpaId) {
    this.props.actions.onGetWaterLifeStats(SpaId);
  }

  ShowFilerLife = () => {
    this.setState({
      isWaterTreatment: false,
      isFilterLife: true,
      isChemicalTesting: false,
      isWaterLife: false,
    });
  };

  HideFilerLife = () => {
    this.setState({
      isWaterTreatment: true,
      isFilterLife: false,
    });
  };

  ShowWaterLife = () => {
    this.setState({
      isWaterTreatment: false,
      isWaterLife: true,
      isChemicalTesting: false,
      isFilterLife: false,
    });
  };
  ShowChemicalTest = () => {
    this.setState({
      isWaterTreatment: false,
      isChemicalTesting: true,
      isWaterLife: false,
      isFilterLife: false,
    });
  };

  HideWaterLife = () => {
    this.setState({
      isWaterTreatment: true,
      isWaterLife: false,
    });
  };
  HideChemicalTest = () => {
    this.setState({
      isWaterTreatment: true,
      isChemicalTesting: false,
    });
  };

  render() {
    const { isLoading } = this.state;
    const { dashboardState } = this.props;
    const { userInfo, userEnv } = dashboardState;
    const { history } = this.props;
    const currentRoot = history ? history.location.pathname : null;

    let userNameS = "";
    if (userInfo) {
      userNameS = `${userInfo.firstName}${`'s `}`;
    }
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    return (
      <div className="Dashboardmain-container">
        {isLoading && <Loader />}
        <div
          style={{ height: 130 }}
          className="Dashboardmain-container__header"
        >
          <Header
            userInformation={userInfo ? userInfo : null}
            isDashboardShow={true}
            history={history}
            userEnvoirnment={userEnv}
            isSPA={true} // sending this to show the spa dropdwon if there is any SPA in the environemnt
            SPAIId={spaDeviceId} // sending this to get spa details on the settings screen
          />

          <CommonHeader
            spaDeviceId={spaDeviceId}
            currentRoot={currentRoot}
            history={history}
          />
        </div>
        <div
          style={{ marginTop: 100 }}
          className="Dashboardmain-container__innerBody deviceOverviewContainer"
        >
          <div className="mainTitle spaSpecificTitle">
            <span>Water Treatment</span>
          </div>
          <span className="spaAndHomeText">
            <span
              className="spaStandardHeading"
              style={{ color: constantColors.softGray }}
            >
              Home /{" "}
            </span>
            <span className="spaStandardHeading">Water Treatment</span>
          </span>

          <div className="spaProductContentBody">
       

            {this.state.isFilterLife && (
              <Grid
                item
                xs={12}
                md={12}
                className="watertreatmentLeftWrapper"
                style={{  paddingBottom: '30px' }}
              >
                <Grid item xs={4} md={4}>
                  <OverViewCard userName={userData?.firstName} />
                  <WaterTreatmentResources />
                </Grid>

                <Grid item xs={8} md={8}>
                  <FilterLifeDetail
                    backtowatertreatment={this.HideFilerLife}
                    isActive={true}
                    chemicalTestManage={this.ShowChemicalTest}
                    waterlifemanage={this.ShowWaterLife}
                  />
                </Grid>
              </Grid>
            )}

            {this.state.isWaterLife && (
              <Grid
                item
                xs={12}
                md={12}
                className="watertreatmentLeftWrapper"
              >
                <Grid item xs={4} md={4}>
                  <OverViewCard userName={userData?.firstName} />
                  <WaterTreatmentResources />
                </Grid>

                <Grid item xs={8} md={8} style={{ marginBottom: 120 }}>
                  <WaterLifeDetail
                    backtowatertreatment={this.HideWaterLife}
                    isActive={true}
                    chemicalTestManage={this.ShowChemicalTest}
                    filtermanage={this.ShowFilerLife}
                  />
                </Grid>
              </Grid>
            )}

            {this.state.isChemicalTesting && (
              <Grid
                item
                xs={12}
                md={12}
                className="watertreatmentLeftWrapper"
             
              >
                <Grid item xs={12} md={4}>
                  <OverViewCard userName={userData?.firstName} />
                  <WaterTreatmentResources />
                </Grid>

                <Grid item xs={12} md={9} style={{ marginBottom: 120 }}>
                  <ChemicalTestingDetail
                    backtowatertreatment={this.HideChemicalTest}
                    isActive={true}
                    filtermanage={this.ShowFilerLife}
                    waterlifemanage={this.ShowWaterLife}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(WaterTreatment));

