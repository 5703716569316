import React from 'react';
import './appBtn.css';

function AppBtn({ text, onClick, style, className }) {
  return (
    <div className="buttonWrapper">
      <button
        type="button"
        onClick={onClick}
        style={style}
        className={className}
      >
        {text}
      </button>
    </div>
  );
}

export default AppBtn;
