import moment from "moment";
import React, { Component, useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { RiAddFill, RiArrowDownSLine, RiCheckFill } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import AutoCompleteLoc from "../../components/common/AutoCompleteLoc";
import {
  AccountsWizard,
  FloatingLabelInput,
  FloatingLabelSelect,
  Loader,
} from "../../components/common/index";
import { RoutesObj } from "../../routes";
import {
  Grill,
  House,
  Lighting,
  Marine,
  Motorcycles,
  Powersports,
  Spa,
  Star,
} from "../../components/common/SVG/SvgIcons";
import finish from "../../resources/images/finish.png";
import {
  backArrow,
  vector_0,
  vector_1,
  vector_2,
  vector_3,
  vector_4,
  vector_5,
  vector_6,
  vector_7,
  finishSetup,
} from "../../resources/images/index";
import logo from "../../resources/images/logoReg.svg";
import { accountActions } from "../../store/accounts/accountActions";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { deviceOverviewAction } from "../../store/deviceOverview/deviceOverviewAction";
import AddDeviceBox from "../AddDeviceBox";
import TabNav from "./TabNav";
import "./tabs.css";
import Autocomplete from "react-google-autocomplete";
import { Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  ContactInfoForm,
  PersonalInfoForm,
  VerifyEmailForm,
  NotificationForm,
  NotificationFormMob,
} from "./Components";
import EmailVerification from "./EmailVerification";
import UserRegistrationHeader from "./UserRegistrationHeader";
import UserRegistrationFooter from "./UserRegistrationFooter";

var sha512 = require("js-sha512");

const Pane = (props) => (
  <div
    className={"tab-pane" + (props.activepane ? " active" : "")}
    id={props.id}
  >
    {props.children}
  </div>
);

class UserRegistration extends Component {
  constructor() {
    super();
    this.autoRef = React.createRef();
    this.state = {
      currentTabId: "pane1",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
      termsError: false,
      address: "",
      city: "",
      stateCode: "",
      postalCode: "",
      country: "",
      phoneNumber: "",
      customEnv: "",
      addDevice: "",
      nameDevice: "",
      serialNumber: "",
      purchaseLoc: "",
      puchaseDate: "",
      installationDate: "",
      recepit: "",
      envIconName: "",
      focused: false,
      installationDateFocused: false,
      addEnvironment: false,
      addLogo: false,
      emailError: false,
      passError: false,
      confPassError: false,
      nameError: false,
      firstNameError: false,
      lastNameError: false,
      cityError: false,
      stateError: false,
      countryError: false,
      phoneError: false,
      postalError: false,
      isLoading: false,
      contactError: false,
      deviceError: false,
      deviceButton: true,
      deviceImage: false,
      selectedProduct: "",
      dealerId: "",
      isExpand: false,
      isShowSerialNo: false,
      dublicateSerial: false,
      selectedEnvName: "",
      selectedEnvId: null,
      selectedEnvData: null,
      selectedEnvLocObj: {},
      selectedEnvSameAccountLoc: true,
      productTypeId: null,
      receiptStatus: 0,
      displayReceipt: "",
      cityOptions: "",
      statesOptions: "",
      countryOptions: "",
      isAAVDeals: false,
      isSupportMessages: false,
      productCategory: "",
      step2: false,
      step3: false,
      purchaseLoc: "",
      delearId: "",
      allDelears: [],
      selectedDevice: "",
      isCityEditable: false,
      isAddressEdited: false,
      isCountryEditable: false,
      isStateEditable: false,
      isPostalCodeEditable: false,
      isManuallyEnter: false,
      // productCategory: "",
      isContactInfo: false,
      isEnvironmentInfo: false,
      isDeviceInfo: false,
      isLastStep: false,
      stepNumber: 0,
      isgettingStarted: false,
      onLoading: false,
      user_record: {},
      screenWidth: window.innerWidth,
    };

    this.handleShowCustomEnvironment =
      this.handleShowCustomEnvironment.bind(this);
    this.returnIcons = this.returnIcons.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.setState({ windowSize: window.innerWidth });
    window.addEventListener("resize", this.handleResize);
    this.props.actions.onChange("currentPaneId", "pane1");
    await this.props.homeActions.onGetAllCities().then((data) => {
      if (data.status === 200 && data.data.success) {
        let cityOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          cityOptions: cityOptions,
        });
      }
    });
    await this.props.homeActions.onGetAllStates().then((data) => {
      if (data.status === 200 && data.data.success) {
        let statesOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          statesOptions: statesOptions,
        });
      }
    });
    await this.props.homeActions.onGetAllCountries().then((data) => {
      if (data.status === 200 && data.data.success) {
        let countryOptions = data.data.data.map((record) => {
          return {
            label: record.value,
            value: record.id,
          };
        });
        this.setState({
          countryOptions: countryOptions,
        });
      }
    });
    this.setState({
      isLoading: false,
    });
  }
  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  returnIcons = (enName, enIconName) => {
    if (enName === "Spa" || enIconName === "vector_04") {
      return <img src={vector_4} alt="" />;
    } else if (enName === "Motorcycles" || enIconName === "vector_05") {
      return <img src={vector_5} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_02") {
      return <img src={vector_2} alt="" />;
    } else if (enName === "Marine" || enIconName === "vector_03") {
      return <img src={vector_3} alt="" />;
    } else if (enName === "Powersports" || enIconName === "vector_06") {
      return <img src={vector_6} alt="" />;
    } else if (enName === "House" || enIconName === "vector_00") {
      return <img src={vector_0} alt="" />;
    } else if (enName === "Grill" || enIconName === "vector_01") {
      return <img src={vector_1} alt="" />;
    } else if (enName === "Star" || enIconName === "vector_07") {
      return <img src={vector_7} alt="" />;
    } else if (enName === "Marine" || enIconName === "vector_08") {
      return <img src={vector_3} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_09") {
      return <img src={vector_2} alt="" />;
    } else if (enName === "Lighting" || enIconName === "vector_10") {
      return <img src={vector_5} alt="" />;
    } else if (enName === "Powersports" || enIconName === "vector_11") {
      return <img src={vector_6} alt="" />;
    }
  };

  returnSVGIcons = (iconName) => {
    if (iconName === "vector_00") {
      return <House />;
    } else if (iconName === "vector_01") {
      return <Grill />;
    } else if (iconName === "vector_02") {
      return <Lighting />;
    } else if (iconName === "vector_03") {
      return <Marine />;
    } else if (iconName === "vector_04") {
      return <Spa />;
    } else if (iconName === "vector_05") {
      return <Motorcycles />;
    } else if (iconName === "vector_06") {
      return <Powersports />;
    } else if (iconName === "vector_07") {
      return <Star />;
    } else if (iconName === "vector_08") {
      return <Marine />;
    } else if (iconName === "vector_09") {
      return <Lighting />;
    } else if (iconName === "vector_10") {
      return <Motorcycles />;
    } else if (iconName === "vector_11") {
      return <Powersports />;
    }
  };

  onDeviceButtonClick = (itemId) => {
    this.setState({
      isLoading: true,
      productTypeId: itemId,
    });
    this.props.homeActions.onGetProducts(itemId).then((data) => {
      this.props.homeActions.getAllDealers(itemId);
      this.setState({ isLoading: false });
    });
    this.setState({
      deviceButton: false,
      deviceImage: true,
    });
  };
  changeHandler = (event) => {
    // setLoading(true);

    this.props.deviceAction
      .onGetDelears(
        event.target.value,
        this.state.productCategory.productCategoryId
      )
      .then((res) => {
        console.log("resssss", res);
        this.setState({ allDelears: res?.data || [] });
        // setLoading(false);
      });
  };
  onBackArroClick = () => {
    this.setState({
      deviceButton: true,
      deviceImage: false,
      selectedProduct: "",
      isShowSerialNo: false,
      step2: false,
    });
    // this.onDeviceButtonClick(this.state.productTypeId);
  };

  onSelectProduct(item) {
    // const prdData = { pTId: item.product_type_id, pId: item.product_id };
    this.setState({
      selectedProduct: item,
      isShowSerialNo: item.isSerialized === 0 ? false : true,
      serialNumber: "",
      step2: true,
    });
    this.setState({ selectedDevice: item });
  }
  checkSelectedProd(pId) {
    const { selectedProduct } = this.state;
    if (selectedProduct !== "" && selectedProduct.productId === pId) {
      return true;
    } else {
      return false;
    }
  }

  onSelectDealer = (e, dealer) => {
    const item = dealer.filter((obj) => obj.id === +e.target.value);
    if (item[0] && item[0].value) {
      this.setState({
        purchaseLoc: item[0].id,
        dealerId: item[0].id,
      });
    } else {
      this.setState({
        purchaseLoc: "",
        dealerId: "",
      });
    }
  };

  handleShowCustomEnvironment = () => {
    this.setState({
      addEnvironment: !this.state.addEnvironment,
    });
  };

  handleChangeImage = (e) => {
    if (e.target.files.length) {
      this.setState({ isLoading: true });
      this.props.homeActions.onUploadImage(e.target.files[0]).then((data) => {
        this.setState({ isLoading: false });
        if (data && data.data && data.data.success) {
          this.setState({ recepit: data.data.data.url });
          this.toBase64(e.target.files[0]).then((data) => {
            const result = data.split("base64,")[1];
            this.setState({
              displayReceipt: result,
              receiptStatus: 1,
            });
          });
        } else {
          this.setState({
            displayReceipt: "",
            recepit: "",
            receiptStatus: 0,
          });
        }
      });
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  onRegisterUser = () => {
    this.setState({ isLoading: true });
    const { puchaseDate } = this.state;
    const user_info = {
      first_name: this.state.firstName,
      city: this.state.city,
      address: this.state.address,
      zip_code: this.state.postalCode,
      phone: this.state.phoneNumber,
      last_name: this.state.lastName,
      user_email: this.state.email,
      country: this.state.country,
      state: this.state.stateCode,
      user_password:
        this.state.password !== "" ? sha512(this.state.password) : "",
      serial_no: this.state.serialNumber,
      device_name: this.state.nameDevice,
      device_id: 1,
      receipt: this.state.recepit,
      env_icon_name: this.state.envIconName,
      env_name: this.state.customEnv,
      device_type: this.state.selectedProduct,
      purchase_date: moment(puchaseDate).format("YYYY/MM/DD"),
      purchase_location: this.state.purchaseLoc,
      dealer: this.state.dealerId,
    };
    this.props.actions
      .onRegisterUser(user_info, this.state.password)
      .then((data) => {
        this.setState({ isLoading: false });
        console.log("checking", data);
        if (data.status === 200 && data.data.resp_msg === "True") {
          this.props.actions.onChange("currentPaneId", "pane5");
          toast.success("Congratulations you are successfully registered");
        } else {
          if (
            data.data.resp_msg === "error" &&
            data.data.is_user_duplicate === "True"
          ) {
            toast.error("User already exist.");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("password");
            localStorage.removeItem("passwordDec");
          } else {
            toast.error(data.errors);
          }
          this.setState({ isLoading: false });
        }
      });
  };

  // onFocusOutFun = value => {
  //   if (
  //     value !== "" &&
  //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  //   ) {
  //     this.setState({ emailError: true });
  //   }
  // };

  // onCheckPersonalInfo = () => {
  //   if (this.state.firstName === "") {
  //     this.setState({ firstNameError: true });
  //   }
  //   if (this.state.lastName === "") {
  //     this.setState({ lastNameError: true });
  //   }
  //   if (this.state.confirmPassword === "") {
  //     this.setState({ confPassError: true });
  //   }
  //   if (this.state.email === "") {
  //     this.setState({ emailError: true });
  //   }
  //   if (this.state.password === "") {
  //     this.setState({ passError: true });
  //   }
  //   if (!this.state.terms) {
  //     this.setState({ termsError: true });
  //   }
  // };
  onCheckContact = () => {
    const { address, city, stateCode, postalCode, country, phoneNumber } =
      this.state;
    if (address === "") {
      this.setState({ contactError: true });
    }
    if (city === "") {
      this.setState({ cityError: true });
    }
    if (stateCode === "") {
      this.setState({ stateError: true });
    }
    if (postalCode === "") {
      this.setState({ postalError: true });
    }
    if (country === "") {
      this.setState({ countryError: true });
    }
    if (phoneNumber === "") {
      this.setState({ phoneError: true });
    }
  };

  // onVerifyUserEmail = () => {
  //   // const { email } = this.state;
  //   // this.setState({ isLoading: true });
  //   // this.props.actions.onVerifyUserEmail(email).then((data) => {
  //   //   this.setState({ isLoading: false });
  //   //   if (data && data.user_exist === "True") {
  //   //     toast.error("Email address is already registered.");
  //   //   } else {
  //   this.props.actions.onChange("currentPaneId", "pane2");
  //   //   }
  //   // });
  // };

  checkAddDevice() {
    const {
      nameDevice,
      serialNumber,
      puchaseDate,
      installationDate,
      purchaseLoc,
      selectedProduct,
      isShowSerialNo,
      dublicateSerial,
    } = this.state;
    if (
      nameDevice !== "" &&
      purchaseLoc !== "" &&
      puchaseDate !== "" &&
      // installationDate !== "" &&
      selectedProduct !== ""
    ) {
      if (isShowSerialNo) {
        if (serialNumber !== "" && !dublicateSerial) {
          this.onAddDevice();
        } else {
          this.setState({ deviceError: true });
        }
      } else {
        this.onAddDevice();
      }
    } else {
      this.setState({ deviceError: true });
    }
  }

  isDublicateSerial(e) {
    if (e.target.value !== "" && this.state.isShowSerialNo) {
      this.setState({ isLoading: true });
      this.props.homeActions.onDublicateSerial(e.target.value).then((data) => {
        if (data && data.status === 200 && data.data && data.data.success) {
          this.setState({ dublicateSerial: false });
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ dublicateSerial: true });
    }
  }

  onRegisterNewUser = () => {
    this.setState({ isLoading: true });
    const user_info = {
      firstName: this.state.firstName,
      city: this.state.city,
      address: this.state.address,
      zipCode: this.state.postalCode,
      phoneNumber: this.state.phoneNumber,
      lastName: this.state.lastName,
      email: this.state.email,
      confirmEmail: this.state.email,
      country: this.state.country,
      state: this.state.stateCode,
      password: this.state.password !== "" ? this.state.password : "",
      confirmPassword:
        this.state.confirmPassword !== "" ? this.state.confirmPassword : "",
      groupId: 0,
      customerTypeName: "",
      companyId: 0,
      isFavourite: false,
      isAdmin: false,
      image: "",
      rating: "",
      policies: [],
    };
    this.props.actions.onRegisterUser(user_info).then((user) => {
      if (user && user.data && user.data.success) {
        this.props.homeActions
          .onGetAllEnvironment(user.data.data.id)
          .then((env) => {
            this.setState({ isLoading: false });
            this.props.actions.onChange("currentPaneId", "pane3");
          });

        this?.props?.actions
          ?.onUserEmailVerification(user.data.data.id)
          .then((res) => {
            console.log("email verification res:", res);
          })
          .catch((err) => {
            console.log("error in verification:", err);
          });

        this?.props?.actions
          ?.onUserSMSVerification(user.data.data.id)
          .then((res) => {
            console.log("sms verification res:", res);
            toast.success(
              "Email has been sent to you for verification of email and phone!"
            );
          })
          .catch((err) => {
            console.log("error in verification:", err);
          });
      } else {
        this.setState({ isLoading: false });
        toast.error(
          user && user.data && user.data.message
            ? user.data.message
            : "Something went wrong, please try again"
        );
      }
    });
  };
  onAddEnv = () => {
    const userId = localStorage.getItem("userId");
    const {
      selectedEnvId,
      envIconName,
      customEnv,
      selectedEnvData,
      selectedEnvLocObj,
      selectedEnvSameAccountLoc,
    } = this.state;
    let req;
    if (selectedEnvId !== null) {
      this.setState({ isLoading: true });
      req = {
        environmentId: 0,
        environmentName: selectedEnvData.environmentName,
        environmentType: selectedEnvData.environmentType,
        environmentIcon: selectedEnvData.environmentIcon,
        status: 1,
        userId: userId,
        location: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.fullAddress
          : "",
        latitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.lat || ""
          : "",
        longitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.long || ""
          : "",
        isSameAccountLocation: selectedEnvSameAccountLoc,
      };

      this.props.homeActions.onAddDashboardEnv(req).then((env) => {
        this.setState({ isLoading: false });
        if (env && env.data && env.data.success) {
          this.setState({
            selectedEnvId: env.data.data,
            selectedEnvName: customEnv,
          });
          this.props.actions.onChange("currentPaneId", "pane4");
          this.props.homeActions.onGetAllProductType().then((data) => {
            this.setState({
              isLoading: false,
            });
          });
          // this.props.homeActions.getAllDealers();
        } else {
          this.setState({ isLoading: false });
          toast.error(
            env && env.data && env.data.message
              ? env.data.message
              : "Something went wrong please try again"
          );
        }
      });
    } else {
      this.setState({ isLoading: true });
      req = {
        environmentId: 0,
        environmentName: customEnv,
        environmentType: "D",
        environmentIcon: envIconName,
        status: 1,
        userId: userId,
        location: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.fullAddress
          : "",
        latitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.lat || ""
          : "",
        longitude: !selectedEnvSameAccountLoc
          ? selectedEnvLocObj?.long || ""
          : "",
        isSameAccountLocation: selectedEnvSameAccountLoc,
      };
      this.props.homeActions.onAddDashboardEnv(req).then((env) => {
        this.setState({ isLoading: false });
        if (env && env.data && env.data.success) {
          this.setState({
            selectedEnvId: env.data.data,
            selectedEnvName: customEnv,
          });
          this.props.actions.onChange("currentPaneId", "pane4");
          this.props.homeActions.onGetAllProductType().then((data) => {
            this.setState({
              isLoading: false,
            });
          });
          // this.props.homeActions.getAllDealers();
        } else {
          this.setState({ isLoading: false });
          toast.error(
            env && env.data && env.data.message
              ? env.data.message
              : "Something went wrong please try again"
          );
        }
      });
    }
  };

  // onFirstNameValidation = value => {
  //   if (value !== "" && !/^[a-zA-Z\s]*$/i.test(value)) {
  //     this.setState({ firstNameError: true });
  //   } else {
  //     this.setState({ firstName: value, firstNameError: false });
  //   }
  // };

  onLastNameValidation = (value) => {
    if (value !== "" && !/^[a-zA-Z\s]*$/i.test(value)) {
      this.setState({ lastNameError: true });
    } else {
      this.setState({
        lastName: value,
        lastNameError: false,
      });
    }
  };

  onPasswordValidation = (value) => {
    if (
      value !== "" &&
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).*$/i.test(value)
    ) {
      this.setState({ passError: true, password: value });
    } else {
      this.setState({ password: value, passError: false });
    }
  };

  onCityValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ cityError: true });
    } else {
      this.setState({ city: value, cityError: false });
    }
  };

  onStateValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ stateError: true });
    } else {
      this.setState({
        stateCode: value,
        stateError: false,
      });
    }
  };

  onCountryValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ countryError: true });
    } else {
      this.setState({
        country: value,
        countryError: false,
      });
    }
  };
  onPostalValidation = (value) => {
    if (value !== "" && !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/i.test(value)) {
      this.setState({ postalError: true });
    } else {
      this.setState({
        postalCode: value.trim(),
        postalError: false,
      });
    }
  };

  onPhoneNumberValidation = (value) => {
    if (value !== "" && !/^[0-9\b]+$/i.test(value)) {
      this.setState({ phoneError: true });
    } else {
      this.setState({
        phoneNumber: value.trim(),
        phoneError: false,
      });
    }
  };

  onAddDevice = () => {
    const userId = localStorage.getItem("userId");
    this.setState({ isLoading: true });
    const {
      nameDevice,
      productTypeId,
      selectedProduct,
      serialNumber,
      purchaseLoc,
      puchaseDate,
      installationDate,
      recepit,
      dealerId,
      selectedEnvId,
      receiptStatus,
    } = this.state;
    let deviceData = {
      name: nameDevice,
      product_type_id: productTypeId,
      productId: selectedProduct.productId,
      serialNo: serialNumber,
      purchase_location: purchaseLoc,
      purchaseDate: moment(puchaseDate).format("MM/DD/YYYY"),
      basePath: "",
      receiptStatus: receiptStatus,
      receipt: recepit,
      userId: userId,
      dealerLocationId: dealerId,
      sku: selectedProduct.sku,
      envId: selectedEnvId,
      colors: [""],
    };
    if (installationDate) {
      deviceData.installationDate =
        moment(installationDate).format("MM/DD/YYYY");
    }
    this.props.homeActions.onAddDashboardDevice(deviceData).then((device) => {
      this.setState({ isLoading: false });
      if (device.data.success) {
        this.props.actions.onChange("currentPaneId", "pane5");
      } else {
        toast.error("Something went wrong, please try again");
      }
    });
  };

  handleDashboard = () => {
    const userId = localStorage.getItem("userId");
    const { history } = this.props;
    this.setState({ isLoading: true });
    const { isAAVDeals, isSupportMessages } = this.state;
    let req = {
      isAAVDeals,
      isSupportMessages,
      id: userId,
    };
    this.props.actions.onUpdateUserNotifications(req).then((data) => {});
    history.push(RoutesObj.Dashboard.path);
  };

  setEnvLocation = (place) => {
    console.log("place", place);
  };

  onCreateEv = () => {
    const { selectedEnvName, customEnv, envIconName, selectedEnvLocObj } =
      this.state;

    console.log("location:", selectedEnvLocObj);

    if ((selectedEnvName === "" || customEnv === "") && envIconName === "") {
      toast.error("Please select environment and icon");
      return;
    }
    // if (Object.keys(selectedEnvLocObj).length === 0) {
    //   toast.error("Enviornment location field is mandatory!");
    //   return;
    // }
    this.onAddEnv();
  };

  activeStep3 = () => {
    console.log("take note:", this.state.nameDevice);
    if (this.state.nameDevice) {
      this.setState({
        step3: true,
      });
    }
  };
  handleClickSkip = () => {
    this.props.actions.onChange("currentPaneId", "pane5");
  };
  onPlaceSelected = (place) => {
    this.setState({
      stateCode: "",
      country: "",
      city: "",
      postalCode: "",
    });

    this.setState({ address: place.formatted_address });
    const address = place.address_components;
    let state, country, city, postalCode;

    address.forEach((component) => {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
      if (component.types.includes("administrative_area_level_2")) {
        city = component.long_name;
      }
      if (component.types.includes("country")) {
        country = component.long_name;
      }
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });
    this.setState({
      stateCode: state,
      country: country,
      city: city,
      postalCode: postalCode ? postalCode : "",
      // isCityEditable: false,
      // isCountryEditable: false,
      // isStateEditable: false,
      // isPostalCodeEditable:false,
      // isManuallyEnter: false,
    });

    // enable city editing if the user starts editing the address manually
    // this.setState({ isCityEditable: false, isCountryEditable: false, isStateEditable: false, isPostalCodeEditable:false,});
    // const addressInput = document.getElementById("popupEnvLocInput");
    // addressInput.addEventListener("input", () => {
    //   this.setState({ isCityEditable: true, isCountryEditable: true, isStateEditable: true, isPostalCodeEditable:true, });
    //   addressInput.removeEventListener("input", () => { });
    // });
  };
  handleStepChange = (stepNumber) => {
    this.setState({ stepNumber });
  };

  render() {
    const {
      isLoading,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      terms,
      termsError,
      address,
      city,
      stateCode,
      postalCode,
      country,
      phoneNumber,
      customEnv,
      nameDevice,
      serialNumber,
      purchaseLoc,
      puchaseDate,
      installationDate,
      envIconName,
      emailError,
      passError,
      confPassError,
      // nameError,
      firstNameError,
      lastNameError,
      cityError,
      stateError,
      countryError,
      phoneError,
      contactError,
      deviceError,
      recepit,
      selectedProduct,
      // dealerId,
      isShowSerialNo,
      dublicateSerial,
      selectedEnvName,
      displayReceipt,
      postalError,
      cityOptions,
      statesOptions,
      countryOptions,
      selectedEnvSameAccountLoc,
      isAAVDeals,
      isSupportMessages,
      isCityEditable,
      isStateEditable,
      isCountryEditable,
      isAddressEdited,
      isContactInfo,
      isEnvironmentInfo,
      isDeviceInfo,
    } = this.state;
    const { productType, allProducts, allDealers, myStaticEnv } =
      this?.props?.homeState;
    const { dispatch, history } = this.props;
    const { screenWidth, user_record } = this.state;

    return (
      <>
        {/* <div className="registration-container__header">
          <img src={logo} alt="" />
        </div> */}
        <div className="mainWrapper">
          {screenWidth <= 599 && (
            <UserRegistrationHeader
              stepNumber={this.state.stepNumber}
              onStepChange={this.handleStepChange}
            />
          )}
          <div className="registration-container__leftSection">
            <div className="registration-container__header">
              <img src={logo} alt="" width="304px" height="68px" />
            </div>
            <Box sx={{ width: "100%" }}>
              <AccountsWizard
                onNext={(activeStep) => {
                  this.setState({ stepNumber: activeStep });
                }}
                activeStep={this.state.stepNumber}
                stepOnePrimary="Account Basics"
                stepOneSecondary=" Setup your account details"
                stepTowPrimary="Verify Email"
                stepTwoSecondary=" Confirm your email"
                stepThreePrimary="Contact Information"
                stepThreeSecondary="Used for warranty + support"
                stepFourPrimary="Finish Setup"
                stepFourSecondary="Notifications and settings"
              />
            </Box>
            <Box className="helpingText">
              <Typography
                style={{
                  color: "#A7A8BB",
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                }}
              >
                Need Help?
              </Typography>
              <a
                style={{
                  cursor: "pointer",
                  color: "#1BBED0",
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
                href="https://www.aquaticav.com/pages/contact"
                target="_blank"
              >
                Contact Aquatic AV Support
              </a>
            </Box>
          </div>
          <div className="registration-container__rightSection">
            <div className="registration-container ">
              {this.state.stepNumber !== 3 && (
                <div className="alreadyAccountCls">
                  Already have an account?{" "}
                  <span>
                    <a href="/" className="linkText">
                      Sign In
                    </a>
                  </span>
                </div>
              )}

              {isLoading && <Loader />}

              <div className="">
                {/* <TabNav> */}
                {/* PERSONAL INFORMATION */}
                {/* <Pane id="pane1" title="Personal Information" index="1"> */}

                {(() => {
                  switch (this.state.stepNumber) {
                    case 0:
                      return (
                        <PersonalInfoForm
                          onLoading={this.state.onLoading}
                          onClick={(user_record) => {
                            if (
                              user_record.password !==
                              user_record.confirmPassword
                            ) {
                              toast.error(
                                "Password and Confirm Password do not match."
                              );
                              return; // Exit the function if passwords don't match
                            }
                            const emailRegex =
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
                            if (!emailRegex.test(user_record?.email)) {
                              toast.error("Please enter a valid Email.");
                              return; // Exit the function if email is not valid
                            }
                            if (this.state.stepNumber < 3) {
                              this.setState({
                                onLoading: true,
                              });
                              this.props.actions
                                .onRegisterUser(user_record)
                                .then((user) => {
                                  if (user?.data?.success) {
                                    toast.success(
                                      "User Registered Successfully"
                                    );
                                    if (user === undefined) {
                                      toast.error(
                                        "Something went wrong please check your details"
                                      );
                                    }

                                    this?.props?.actions
                                      ?.onUserEmailVerification(
                                        user?.data?.data?.id
                                      )
                                      .then((res) => {
                                        console.log(
                                          "email verification res:",
                                          res
                                        );
                                      })
                                      .catch((err) => {
                                        console.log(
                                          "error in verification:",
                                          err
                                        );
                                      });
                                    this.setState({
                                      onLoading: false,
                                    });
                                    if (
                                      user &&
                                      user?.data &&
                                      user?.data?.success
                                    ) {
                                      this.setState({
                                        stepNumber: this.state.stepNumber + 1,
                                        user_record: user.data.data,
                                      });
                                    } else {
                                      // toast.error(
                                      //   user?.data?.errors ||
                                      //     "Something went wrong please check"
                                      // );
                                    }
                                  } else {
                                    this.setState({
                                      onLoading: false,
                                    });
                                    toast.error(
                                      user?.data?.message ||
                                        "Email address is not valid"
                                    );
                                  }
                                })
                                .catch((err) => {
                                  console.log("err", err);
                                  this.setState({
                                    onLoading: false,
                                  });
                                  toast.error(err?.ConfirmPassword);
                                });
                            }
                          }}
                        />
                      );

                    case 1:
                      return (
                        <VerifyEmailForm
                          onLoading={this.state.onLoading}
                          onClick={() => {
                            if (this.state.stepNumber < 3) {
                              this.setState({
                                onLoading: true,
                              });
                              this.props.actions
                                .onUserEmailVerification(
                                  this.state.user_record?.id
                                )
                                .then((user) => {
                                  toast.success("Email sent for verification");

                                  this.setState({
                                    onLoading: false,
                                  });
                                  // if (
                                  //   user &&
                                  //   user.data &&
                                  //   user.data.success
                                  // ) {
                                  //   this.setState({
                                  //     stepNumber:
                                  //       this.state
                                  //         .stepNumber + 1,
                                  //   });
                                  // } else {
                                  //   toast.error(
                                  //     user?.data?.message ||
                                  //       "Something went wrong please check"
                                  //   );
                                  // }
                                })
                                .catch((err) => {
                                  this.setState({
                                    onLoading: false,
                                  });
                                  toast.error(
                                    err?.data?.message ||
                                      "Something went wrong please check"
                                  );
                                });
                            }
                          }}
                          onSkipClick={() => {
                            this.setState({
                              stepNumber: this.state.stepNumber + 1,
                            });
                          }}
                          onClickNext={() => {
                            if (this.state.stepNumber < 3) {
                              this.setState({
                                onLoading: true,
                              });
                              this.props.actions
                                .onUserEmailVerification(
                                  this.state.user_record?.id
                                )
                                .then((user) => {
                                  this.setState({
                                    onLoading: false,
                                  });
                                  if (user && user.data && user.data.success) {
                                    this.setState({
                                      stepNumber: this.state.stepNumber + 1,
                                    });
                                  } else {
                                    toast.error(
                                      user?.data?.message ||
                                        "Something went wrong please check"
                                    );
                                  }
                                })
                                .catch((err) => {
                                  this.setState({
                                    onLoading: false,
                                  });
                                  toast.error(
                                    err?.data?.message ||
                                      "Something went wrong please check"
                                  );
                                });
                            }
                          }}
                        />
                      );
                    case 2:
                      return (
                        <ContactInfoForm
                          onSuccess={(res) => {
                            if (res.success) {
                              this.setState({
                                stepNumber: this.state.stepNumber + 1,
                              });
                            }
                          }}
                          user_record={this.state.user_record}
                        />
                      );
                    case 3:
                      return this.state.isgettingStarted ? (
                        <div>
                          <h2
                            style={{
                              marginBottom: 24,
                              marginTop: 16,
                              fontWeight: 600,
                              fontSize: "30px",
                              color: "#202A4C",
                              fontFamily: "poppins",
                              marginTop: "9%",
                              paddingLeft: "25px",
                            }}
                          >
                            Account Created
                          </h2>
                          <div className="mainAccountWrap">
                            <img
                              src={finishSetup}
                              alt="finish setup"
                              width={275}
                              height={195}
                              style={{ marginTop: "30px" }}
                            />
                            <div
                              className="finishAccountTitleWrap"
                              style={{
                                paddingLeft: "25px",
                                paddingRight: "25px",
                              }}
                            >
                              <div
                                className="mainTitleStyle"
                                style={{
                                  fontSize: "22px",
                                  fontWeight: 600,
                                  marginBottom: "5px",
                                  fontFamily: "poppins",
                                  color: "#181C32",
                                }}
                              >
                                Would you like to register a new device?
                              </div>
                              <div
                                className="secondTitle"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 400,
                                  fontFamily: "Poppins",
                                  color: "#000000",
                                }}
                              >
                                If you have a device to register you can do so
                                now, or later from the dashboard.
                              </div>
                            </div>

                            <button
                              id="pane2"
                              className="nextNavButton"
                              onClick={() => {
                                history.push({
                                  pathname: RoutesObj.ProductRegistration.path,
                                });
                              }}
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "15px",
                                fontWeight: 500,
                                // width: "199px",
                                height: "42px",
                                marginTop: "60px",
                              }}
                            >
                              REGISTER NEW DEVICE
                            </button>
                            <button
                              id="pane2"
                              className="gotoDashboardBtn"
                              onClick={() => {
                                history.push({
                                  pathname: RoutesObj.Dashboard.path,
                                });
                              }}
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "15px !important",
                                fontWeight: 500,
                                // width: "176px",
                                height: "42px",
                                marginBottom: "30px",
                              }}
                            >
                              GO TO DASHBOARD
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginTop: "9%" }}>
                          {screenWidth <= 599 ? (
                            <NotificationFormMob
                              onGettingStarted={() => {
                                this.setState({
                                  isgettingStarted: true,
                                });
                              }}
                            />
                          ) : (
                            <NotificationForm
                              onGettingStarted={() => {
                                this.setState({
                                  isgettingStarted: true,
                                });
                              }}
                            />
                          )}
                        </div>
                      );

                    default:
                      return null;
                  }
                })()}
                <div>
                  {/* <h2>Account Created</h2>
                  <div className="mainAccountWrap">
                    <img src={finishSetup} alt="finish setup" />
                    <div className="finishAccountTitleWrap">
                      <div className="mainTitleStyle">
                        Would you like to register a new device?
                      </div>
                      <div className="secondTitle">
                        If you have a device to register you can do so now, or
                        later from the dashboard.
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="footerNav ">
                    <button
                      id="pane2"
                      className="nextNavButton"
                      onClick={() => {
                        if (this.state.stepNumber < 3) {
                          this.setState({
                            stepNumber: this.state.stepNumber + 1,
                          });
                        }
                      }}>
                      {this.state.stepNumber < 3 ? "NEXT STEP" : "GET STARTED"}
                    </button>
                  </div> */}
                  {screenWidth >= 600 && (
                    <div className="bottomFooterWrap regestrationFooter">
                      <span className="copyrightText">
                        © 2021 Aquatic AV Inc.
                      </span>
                      <span>
                        <a
                          href="https://www.aquaticav.com/pages/terms-of-use"
                          target="_blank"
                          className="footerLinks"
                        >
                          Terms
                        </a>
                      </span>
                      <span>
                        <a
                          href="https://www.aquaticav.com/pages/terms-of-use"
                          target="_blank"
                          className="footerLinks"
                        >
                          Privacy
                        </a>
                      </span>
                      <span>
                        <a
                          href="https://www.aquaticav.com/pages/contact"
                          target="_blank"
                          className="footerLinks"
                        >
                          Contact Us
                        </a>
                      </span>
                    </div>
                  )}
                </div>

                {/* </Pane> */}

                {/* CREATE AN INVIRONMENT */}

                {/* <Pane id="pane3" title="Create an Environment" index="3"> */}
                {this.setState.isContactInfo === true && (
                  <>
                    <h1 className="environment">Create an Environment</h1>
                    <p className="environmentDesc">
                      Select an environment for where your Aquatic AV product
                      will be installed.
                    </p>

                    <div className="environmentListPopup">
                      <ul>
                        {myStaticEnv &&
                          myStaticEnv.length > 0 &&
                          myStaticEnv.map((items, index) => {
                            return (
                              <li key={index}>
                                <div
                                  onClick={() =>
                                    this.setState({
                                      selectedEnvName: items.environmentName,
                                      envIconName: items.environmentIcon,
                                      selectedEnvId: items.environmentId,
                                      customEnv: "",
                                      selectedEnvData: items,
                                    })
                                  }
                                  className={`normalBox ${
                                    envIconName === items.environmentIcon &&
                                    selectedEnvName === items.environmentName
                                      ? "selectedBox"
                                      : ""
                                  }`}
                                >
                                  <span className="icon">
                                    {this.returnSVGIcons(items.environmentIcon)}
                                  </span>
                                  <span className="iconText">
                                    {items.environmentName}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>

                    <ul className="environmentBox">
                      <span
                        className="addDeviceAdmin"
                        onClick={this.handleShowCustomEnvironment}
                        style={{ width: "100%" }}
                      >
                        {this.state.addEnvironment ? (
                          <RiArrowDownSLine />
                        ) : (
                          <RiAddFill />
                        )}{" "}
                        custom environment
                      </span>
                      {this.state.addEnvironment && (
                        <span className="environmentAdminFieldWrapper">
                          <input
                            type="text"
                            placeholder="Enter a name"
                            value={customEnv}
                            onChange={(e) =>
                              this.setState({
                                customEnv: e.target.value,
                                selectedEnvName: "",
                                selectedEnvId: null,
                              })
                            }
                            maxLength={30}
                          />
                          <div className="environmentListPopup  envIcons">
                            <ul>
                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_0",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_0"
                                      ? "activeSvg"
                                      : ""
                                  }`}
                                >
                                  <House />
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_01",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_01"
                                      ? "activeSvg"
                                      : ""
                                  }`}
                                >
                                  <Grill />
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_09",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_09"
                                      ? "activeSvg_"
                                      : ""
                                  }`}
                                >
                                  <Lighting />
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_08",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_08"
                                      ? "activeSvg"
                                      : ""
                                  }`}
                                >
                                  <Marine />
                                </span>
                              </li>

                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_04",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_04"
                                      ? "activeSvg"
                                      : ""
                                  }`}
                                >
                                  <Spa />
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_10",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_10"
                                      ? "activeSvg"
                                      : ""
                                  }`}
                                >
                                  <Motorcycles />
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_11",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_11"
                                      ? "activeSvg"
                                      : ""
                                  }`}
                                >
                                  <Powersports />
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      envIconName: "vector_07",
                                      selectedEnvName: "",
                                      selectedEnvId: null,
                                    })
                                  }
                                  className={`icon ${
                                    envIconName === "vector_07"
                                      ? "activeSvg"
                                      : ""
                                  }`}
                                >
                                  <Star />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </span>
                      )}
                    </ul>

                    <div className="environmentLocationPopup">
                      <span className="loginWrapper__loginFormWrapper--inner">
                        <div className="inputMainWrapper">
                          <label
                            // htmlFor="popupEnvLocInput"
                            className="form-label"
                          >
                            Environment Location
                          </label>
                          <Autocomplete
                            id="popupEnvLocInput"
                            className={
                              selectedEnvSameAccountLoc
                                ? "location-input disabled"
                                : "location-input"
                            }
                            apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
                            options={{
                              types: ["address"],
                            }}
                            onPlaceSelected={(place) => {
                              // console.log(place.geometry.location.lng());
                              this.setState({
                                selectedEnvLocObj: {
                                  lat: place.geometry.location.lat(),
                                  long: place.geometry.location.lng(),
                                  fullAddress: place.formatted_address,
                                },
                              });
                            }}
                            ref={this.autoRef}
                          />
                        </div>
                      </span>
                      <label className="container">
                        <span
                          // href="https://aquaticav.com/terms/"
                          // target="_blank"
                          // rel="noreferrer"
                          style={{
                            // color: "#49b8ef",
                            // textDecoration: "underline",
                            marginLeft: "25px",
                          }}
                        >
                          Same location as on my account
                        </span>
                        <input
                          type="checkbox"
                          id="popupEnvLoc"
                          value={selectedEnvSameAccountLoc}
                          checked={selectedEnvSameAccountLoc ? true : false}
                          onChange={(e) => {
                            if (!selectedEnvSameAccountLoc) {
                              this.setState({
                                selectedEnvLocObj: {},
                              });
                              this.autoRef.current.value = "";
                            }
                            this.setState({
                              selectedEnvSameAccountLoc:
                                !selectedEnvSameAccountLoc,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <span className="footerNav ">
                      <button
                        id="pane1"
                        className="prevNavButton"
                        style={{ opacity: 0.5 }}
                        // onClick={() =>
                        //   this.props.actions.onChange("currentPaneId", "pane2")
                        // }
                      >
                        PREVIOUS
                      </button>
                      <button
                        id="pane2"
                        className="nextNavButton"
                        onClick={this.onCreateEv}
                      >
                        NEXT STEP
                      </button>
                    </span>
                  </>
                )}
                {/* </Pane> */}

                {/* REGISTER DEVICES */}

                {/* <Pane id="pane4" title="Add and Register Device" index="4"> */}
                {this.setState.isDeviceInfo === true && (
                  <>
                    <h1 className="environment">1. Add a Device</h1>
                    <div className="deviceInner">
                      <p>Add a device to your account.</p>
                      {selectedProduct === "" && deviceError ? (
                        <span style={{ color: "red" }}>
                          {"Please select device first"}
                        </span>
                      ) : null}
                      {this.state.deviceButton && (
                        <span className="deviceButtons">
                          {productType &&
                            productType?.length > 0 &&
                            productType?.map((items, index) => {
                              return (
                                <AddDeviceBox
                                  key={index}
                                  text={items.categoryName}
                                  picture={items.featuredImage}
                                  deviceClick={() => {
                                    this.onDeviceButtonClick(
                                      items.productCategoryId
                                    );
                                    this.setState({
                                      productCategory: items,
                                    });
                                  }}
                                />
                              );
                            })}
                        </span>
                      )}
                      {console.log("deviceImage", this.state.deviceImage)}
                      {this.state.deviceImage && (
                        <span className="productsPictures">
                          {allProducts && allProducts.length > 0 ? (
                            <>
                              {console.log("allProducts", allProducts)}
                              {allProducts &&
                                allProducts.length > 0 &&
                                allProducts.map((items, index) => {
                                  console.log(
                                    "testig:",
                                    this.checkSelectedProd(items.productId)
                                  );
                                  if (!this.state.step2) {
                                    console.log("statechk", this.state.step2);
                                    return (
                                      <i
                                        key={index}
                                        onClick={() =>
                                          this.onSelectProduct(items)
                                        }
                                      >
                                        <img src={items.featureImage} alt="" />
                                        <div>
                                          <span>{items.productName}</span>
                                          <span>{items.productId}</span>
                                        </div>

                                        {this.checkSelectedProd(
                                          items.productId
                                        ) && (
                                          <span className="selectedProduct">
                                            <RiCheckFill />
                                          </span>
                                        )}
                                      </i>
                                    );
                                  } else if (
                                    this.checkSelectedProd(items.productId) &&
                                    this.state.step2
                                  ) {
                                    console.log(
                                      "statechk====?",
                                      this.state.step2
                                    );

                                    return (
                                      <i
                                        key={index}
                                        onClick={() =>
                                          this.onSelectProduct(items)
                                        }
                                      >
                                        <img src={items.featureImage} alt="" />
                                        <div>
                                          <span>{items.productName}</span>
                                          <span>{items.productId}</span>
                                        </div>

                                        {this.checkSelectedProd(
                                          items.productId
                                        ) && (
                                          <span className="selectedProduct">
                                            <RiCheckFill />
                                          </span>
                                        )}
                                      </i>
                                    );
                                  }
                                })}
                            </>
                          ) : (
                            <span>Products are not available</span>
                          )}

                          <span
                            className="backBtn"
                            onClick={() => this.onBackArroClick()}
                          >
                            <i>
                              <img src={backArrow} alt="" />
                              Back to Devices
                            </i>
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="gap"></div>
                    <div className="gap"></div>
                    {/* <span className="deviceInnerDisabled "> */}
                    {this.state.step2 ? (
                      <>
                        <h1 className="environment">2. Name Device</h1>
                        <div className="deviceInner">
                          <p>
                            Name the selected device
                            <span style={{ color: "red" }}>*</span>
                          </p>
                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            <FloatingLabelInput
                              value={nameDevice}
                              className={
                                nameDevice === "" && deviceError
                                  ? "errorOutline"
                                  : ""
                              }
                              onChange={(e) =>
                                this.setState({
                                  nameDevice: e.target.value,
                                })
                              }
                              onBlur={() => this.activeStep3()}
                            />
                          </span>
                        </div>
                      </>
                    ) : null}

                    {/* </span> */}
                    {/* <div className="gap"></div> */}
                    <div className="gap"></div>
                    {/* <span className="deviceInnerDisabled "> */}

                    {this.state.step3 ? (
                      <>
                        <h1 className="environment">3. Register Device</h1>
                        <div className="deviceInner">
                          <p className="groupFields">
                            Registering your device will provide the ability to
                            upgrade and customize your device, priority customer
                            support and an extended warranty.
                          </p>
                          {this.state.selectedProduct?.isSerialized === 1 ? (
                            <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                              <span
                              // className={!isShowSerialNo ? "deviceInnerDisabled" : ""}
                              >
                                <FloatingLabelInput
                                  placeholder="Serial Number"
                                  // disabled={isShowSerialNo}
                                  value={serialNumber}
                                  mandatory
                                  className={
                                    (isShowSerialNo &&
                                      deviceError &&
                                      serialNumber === "") ||
                                    dublicateSerial
                                      ? "errorOutline"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      serialNumber: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => this.isDublicateSerial(e)}
                                />
                                {isShowSerialNo && dublicateSerial ? (
                                  <span style={{ color: "red" }}>
                                    {"Please add serial number"}
                                  </span>
                                ) : null}
                              </span>
                            </span>
                          ) : null}

                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            {/* <FloatingLabelInput placeholder="Purchase Location" value={purchaseLoc} className={purchaseLoc === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ purchaseLoc: e.target.value })} /> */}
                            <label className="purchaseRecWrapper">
                              Purchase Location
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <AutoCompleteLoc
                              label="Purchase Location"
                              value={purchaseLoc}
                              isReg
                              onChange={(e) => {
                                this.changeHandler(e);
                                this.setState({
                                  purchaseLoc: e.target.value,
                                });
                              }}
                              // disabled={!isSelectedDevice ? true : false}
                              className="autoloc"
                              eventLocation={this.state.allDelears}
                              setEvent={(v) => {
                                this.setState({
                                  purchaseLoc: v.value,
                                });
                                this.setState({
                                  dealerId: v.id,
                                });
                                // setError(false);
                              }}
                              // onBlur={() => onCheckLocLength()}
                            />
                            {/* <select
                          name="dealers"
                          id="dealer"
                          value={purchaseLoc}
                          className={
                            deviceError && purchaseLoc === ""
                              ? "errorOutline"
                              : ""
                          }
                          onChange={(e) => this.onSelectDealer(e, allDealers)}
                        >
                          <option value="">Select Dealer</option>
                          {allDealers &&
                            allDealers.length > 0 &&
                            allDealers.map((items, index) => {
                              return (
                                <option key={index} value={items.id}>
                                  {items.value}
                                </option>
                              );
                            })}
                        </select>
                        {deviceError && purchaseLoc === "" ? (
                          <span style={{ color: "red" }}>
                            {"Please select dealer"}
                          </span>
                        ) : null} */}
                          </span>

                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            {/* <FloatingLabelInput placeholder="Purchase date" type="date" value={puchaseDate} className={puchaseDate === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ puchaseDate: e.target.value })} /> */}
                            <label className="purchaseRecWrapper">
                              Purchase Date
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <SingleDatePicker
                              date={puchaseDate !== "" ? puchaseDate : null} // momentPropTypes.momentObj or null
                              onDateChange={(puchaseDate) =>
                                this.setState({
                                  puchaseDate,
                                })
                              } // PropTypes.func.isRequired
                              focused={this.state.focused} // PropTypes.bool
                              onFocusChange={({ focused }) =>
                                this.setState({ focused })
                              } // PropTypes.func.isRequired
                              id={"1"} // PropTypes.string.isRequired,
                              numberOfMonths={1}
                              displayFormat="YYYY/MM/DD"
                              placeholder={"YYYY/MM/DD"}
                              isOutsideRange={() => false}
                              hideKeyboardShortcutsPanel={true}
                            />
                            {deviceError && puchaseDate === "" ? (
                              <span style={{ color: "red" }}>
                                {"Please select Date"}
                              </span>
                            ) : null}
                          </span>

                          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
                            {/* <FloatingLabelInput placeholder="Purchase date" type="date" value={puchaseDate} className={puchaseDate === '' && deviceError ? 'errorOutline' : ''} onChange={(e) => this.setState({ puchaseDate: e.target.value })} /> */}
                            <label className="purchaseRecWrapper">
                              Installation Date
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <SingleDatePicker
                              date={
                                installationDate !== ""
                                  ? installationDate
                                  : null
                              } // momentPropTypes.momentObj or null
                              onDateChange={(installationDate) => {
                                if (installationDate >= puchaseDate) {
                                  this.setState({
                                    installationDate,
                                  });
                                }
                              }} // PropTypes.func.isRequired
                              focused={this.state.installationDateFocused} // PropTypes.bool
                              onFocusChange={({ focused }) =>
                                this.setState({
                                  installationDateFocused: focused,
                                })
                              } // PropTypes.func.isRequired
                              id={"1"} // PropTypes.string.isRequired,
                              numberOfMonths={1}
                              displayFormat="YYYY/MM/DD"
                              placeholder={"YYYY/MM/DD"}
                              isOutsideRange={() => false}
                              hideKeyboardShortcutsPanel={true}
                            />
                            {/* {deviceError && installationDate === "" ? (
                          <span style={{ color: "red" }}>
                            {"Please select installation Date"}
                          </span>
                        ) : null} */}
                          </span>

                          <span className="loginWrapper__loginFormWrapper--inner">
                            {/* <FileUpload /> */}
                            <div className="upload-btn-wrapper">
                              <button className="btn">ATTACH RECEIPT</button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) => this.handleChangeImage(e)}
                              />
                            </div>
                            {recepit !== "" ? (
                              <div className="uploadYhumbnail">
                                <span>
                                  {
                                    <img
                                      src={`${"data:image/png;base64,"}${displayReceipt}`}
                                      alt=""
                                    />
                                  }
                                </span>
                              </div>
                            ) : null}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {/* </span> */}

                    <span className="footerNav ">
                      <button
                        id="pane1"
                        className="prevNavButton"
                        onClick={() =>
                          this.props.actions.onChange("currentPaneId", "pane3")
                        }
                      >
                        PREVIOUS
                      </button>
                      <button
                        id="pane2"
                        className="nextNavButton"
                        onClick={() => this.checkAddDevice()}
                      >
                        NEXT STEP
                      </button>
                    </span>
                    <h className="skipbutton" onClick={this.handleClickSkip}>
                      SKIP STEP
                    </h>
                  </>
                )}
                {/* </Pane> */}

                {/* FINISH SETUP */}

                {/* <Pane id="pane5" title="Finish Setup" index="5"> */}
                {this.setState.isLastStep === true && (
                  <>
                    <span className="footerNav finishSteps">
                      <img className="onBoardingDone" src={finish} alt="" />
                      <h1 className="onBoardinDeviceAddedd">
                        Device added! You’re almost set!{" "}
                      </h1>
                      <p className="pane5 notifications">
                        Select which notifications you want to receive via
                        e-mail or within the Aquatic AV app.
                      </p>
                      <label className="container pane5">
                        <span
                          // href="https://aquaticav.com/terms/"
                          // target="_blank"
                          // rel="noreferrer"
                          style={{
                            color: "#464E5F",
                            fontWeight: 500,
                            fontSize: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "-3%",
                            // textDecoration: "underline",
                            // marginLeft: "25px",
                          }}
                        >
                          New AAV Deals
                        </span>
                        <input
                          type="checkbox"
                          id="aavdeals"
                          value={isAAVDeals}
                          checked={isAAVDeals ? true : false}
                          onChange={(e) => {
                            this.setState({
                              isAAVDeals: !isAAVDeals,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="container pane5">
                        <span
                          // href="https://aquaticav.com/terms/"
                          // target="_blank"
                          // rel="noreferrer"
                          style={{
                            color: "#464E5F",
                            fontWeight: 500,
                            fontSize: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // marginLeft:"-2%",
                          }}
                        >
                          Support Messages
                        </span>
                        <input
                          type="checkbox"
                          id="aavdeals"
                          value={isSupportMessages}
                          checked={isSupportMessages ? true : false}
                          onChange={(e) => {
                            this.setState({
                              isSupportMessages: !isSupportMessages,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <div className="previousButton">
                        <button
                          id="pane1"
                          className="dashboardLink"
                          onClick={() => this.handleDashboard()}
                        >
                          go to dashboard
                        </button>
                      </div>

                      {/* <Link to="/dashboard" id="pane1" className="dashboardLink">
                  go to dashboard
                </Link> */}
                    </span>
                  </>
                )}
                {/* </Pane> */}
                {/* </TabNav> */}
              </div>
            </div>
          </div>
          {screenWidth <= 599 && <UserRegistrationFooter />}
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
    homeState: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
    deviceAction: bindActionCreators(deviceOverviewAction, dispatch),
  })
)(withRouter(UserRegistration));
