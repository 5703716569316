import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RoutesObj } from "../routes";

class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path={RoutesObj.Root.path}
            render={() => RoutesObj.Login.component}
          />
          <Route
            exact
            path={RoutesObj.EmailVerification.path}
            render={() => RoutesObj.EmailVerification.component}
          />
          <Route
            exact
            path={RoutesObj.PhoneVerification.path}
            render={() => RoutesObj.PhoneVerification.component}
          />
          <Route
            exact
            path={RoutesObj.ForgotPassword.path}
            render={() => RoutesObj.ForgotPassword.component}
          />
          <Route
            exact
            path={RoutesObj.VerificationCode.path}
            render={() => RoutesObj.VerificationCode.component}
          />
          <Route
            exact
            path={RoutesObj.PasswordRest.path}
            render={() => RoutesObj.PasswordRest.component}
          />
          <Route
            exact
            path={RoutesObj.UserRegistration.path}
            render={() => RoutesObj.UserRegistration.component}
          />
          <Route
            exact
            path={RoutesObj.Dashboard.path}
            render={() => RoutesObj.Dashboard.component}
          />
          <Route
            exact
            path={RoutesObj.TransferOwnership.path}
            render={() => RoutesObj.TransferOwnership.component}
          />
          {/* <Route
          <Route
            exact
            path={RoutesObj.NewMessage.path}
            render={() => RoutesObj.NewMessage.component}
          /> */}
          <Route
            exact
            path={RoutesObj.MyAccount.path}
            render={() => RoutesObj.MyAccount.component}
          />
          <Route
            exact
            path={RoutesObj.DeviceOverview.path}
            render={() => RoutesObj.DeviceOverview.component}
          />
          {/* <Route
            exact
            path={RoutesObj.SpaOverview.path}
            render={() => RoutesObj.SpaOverview.component}
          /> */}
          <Route
            exact
            path={RoutesObj.WaterTreatment.path}
            render={() => RoutesObj.WaterTreatment.component}
          />
          <Route
            exact
            path={RoutesObj.Reporting.path}
            render={() => RoutesObj.Reporting.component}
          />
          {/* <Route
            exact
            path={RoutesObj.SpaSettings.path}
            render={() => RoutesObj.SpaSettings.component}
          /> */}
          {/* <Route
            exact
            path={RoutesObj.SpaSettingsNew.path}
            render={() => RoutesObj.SpaSettingsNew.component}
          /> */}
          <Route
            exact
            path={RoutesObj.DealerLocator.path}
            render={() => RoutesObj.DealerLocator.component}
          />
          <Route
            exact
            path={RoutesObj.Notifications.path}
            render={() => RoutesObj.Notifications.component}
          />
          {/* <Route
            exact
            path={RoutesObj.Messages.path}
            render={() => RoutesObj.Messages.component}
          /> */}
          <Route
            exact
            path={RoutesObj.PriceList.path}
            render={() => RoutesObj.PriceList.component}
          />
          <Route
            exact
            path={RoutesObj.PriceListDetail.path}
            render={() => RoutesObj.PriceListDetail.component}
          />
          <Route
            exact
            path={RoutesObj.Catalog.path}
            render={() => RoutesObj.Catalog.component}
          />
          <Route
            exact
            path={RoutesObj.CatalogProduct.path}
            render={() => RoutesObj.CatalogProduct.component}
          />
          <Route
            exact
            path={RoutesObj.ProductRegistration.path}
            render={() => RoutesObj.ProductRegistration.component}
          />
          <Route
            exact
            path={RoutesObj.AddNewDeviceFlow.path}
            render={() => RoutesObj.AddNewDeviceFlow.component}
          />
          <Route
            exact
            path={RoutesObj.UpdateMessages.path}
            render={() => RoutesObj.UpdateMessages.component}
          />
          <Route
            exact
            path={RoutesObj.UpdateConversation.path}
            render={() => RoutesObj.UpdateConversation.component}
          />
          <Route
            exact
            path={RoutesObj.NotFound.path}
            render={() => RoutesObj.NotFound.component}
          />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
