import React, { Component } from "react";
import OverViewCard from "../../../components/common/SpaProduct/overViewCard";
import TempratureCard from "../../../components/common/SpaProduct/tempratureCard";
import SpaQuickToolsCard from "../../../components/common/SpaProduct/spaQuickToolsCard";
import SpaPumpCard from "../../../components/common/SpaProduct/spaPumpCard";
import LightsAudioCard from "../../../components/common/SpaProduct/lightsAudioCard";
import SpaWaterTreatment from "../../../components/common/SpaProduct/spaWaterTreatment";
import Reporting from "../../../components/common/SpaProduct/reporting";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SpaOverviewAction } from "../../../store/SpaOverview/SpaOverviewAction";
import { spaVector } from "../../../resources/images";
import Grid from "@material-ui/core/Grid";
import { Header, Loader } from "../../../components/common/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import AppBtn from "../../../components/common/AppBtn/AppBtn";
import { RoutesObj } from "../../../routes";
import "./spaOverview.css";

import "../tabs.css";
import { toast } from "react-toastify";
import constantColors from "../../../components/common/colorConstants";
import CommonHeader from "../CommonHeader";
import OwnedSpa from "../../../components/common/SpaProduct/OwnedSpa";
import Modal from "@material-ui/core/Modal";
import { Box } from "@material-ui/core";
import mqttClient from "../../../config/MqttClient";

let spaDeviceId;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 6,
  outline: "none",

  // width: "50%",
  // height: "326.91px",
  padding: "32px 48px 28px 48px",
  borderRadius: "20px",
  overflowY: "scroll",
};
class SpaOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPlaying: "",
      trackProgress: "",
      activepane: "pane1",
      openOwnedSpaModal: false,
      ownSpas: [],
      getOwnSpaid: null,
      spaDevData: "",
      selectedSpaTab: false,
      showSubMenu: false,
    };
  }

  componentDidMount() {
    // MQTT WORK
    this.setupMqttConnection();

    const { location } = this?.props;
    this.onGetSpaDevices();

    if (location?.state?.SpaId) {
      this.getSpaOverviewData(location.state.SpaId);
      spaDeviceId = location.state.SpaId;
    } else {
      window.location = RoutesObj.Dashboard.path;
      toast.error("Please select Spa Device");
    }
  }
  shouldComponentUpdate(prevProps, prevState) {
    const { spaOverviewReducer } = this?.props;
    if (
      prevProps?.spaOverviewReducer?.spaMqttDeviceName !==
      spaOverviewReducer.spaMqttDeviceName
    ) {
      console.log("Yes i'm called");
      mqttClient.unsubscribe(
        `devices/${spaOverviewReducer.spaMqttDeviceName}/messages/events/`
      );

      mqttClient.subscribe(
        `devices/${prevProps?.spaOverviewReducer?.spaMqttDeviceName}/messages/events/`
      );
    }
    return true;
  }

  setupMqttConnection() {
    const { spaOverviewReducer } = this?.props;

    mqttClient.on("connect", () => {
      // console.log(
      //   "Connected to AAV MQTT broker 1",
      //   spaOverviewReducer?.spaMqttDeviceName
      // );
      // setConnectionStatus("Connected");
      console.log("mttq2")
      mqttClient.subscribe(
        `devices/${spaOverviewReducer?.spaMqttDeviceName}/messages/events/`
      );
      // client.subscribe("devices/BW832F_AC2C/messages/events");
      // client.subscribe("devices/BW832F_AC2C/messsages/devicebound");
    });

    mqttClient.on("message", (topic, message) => {
      console.log(
        `Message received from MQTT Broker for topic ${topic} the message is ${message}`
      );
    });

    mqttClient.on("error", (error) => {
      console.log("MQTT ERR##:", error);
      // setConnectionStatus(
      //   `Something went wrong: can't be connected! --> ${JSON.stringify(error)}`
      // );
    });
  }

  // componentWillUnmount() {
  //   mqttClient.end();
  // }

  async getSpaOverviewData(SpaId) {
    this.setState({ isLoading: true });
    await this?.props?.actions
      ?.onGetSpaInfo(SpaId)
      .then(async (env) => {
        this.setState({ isLoading: false });
        console.log(env.data.spaDeviceDetails, "success");
        if (env.success) {
          this.setState({ spaDevData: env.data });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  SetIsPlaying = () => {
    this.setState({
      isPlaying: !this.state.isPlaying,
    });
  };
  setTrackProgress = (progress) => {
    if (progress !== this.state.trackProgress) {
      this.setState({
        trackProgress: progress,
      });
    }
  };
  clickTab = (tabId) => {
    if (tabId === "pane1") {
      this.setState({
        breadCrumTxt: "Bob’s RGB Controller - Device Overview",
      });
    } else if (tabId === "pane2") {
      this.setState({
        breadCrumTxt: "Bob’s RGB Controller - Device Overview",
      });
    } else if (tabId === "pane3") {
      this.setState({
        breadCrumTxt: "Bob’s RGB Controller - Device Overview",
      });
    }
    this.setState({ activepane: tabId });
  };
  async onLoadCompleteData(showMsg, msgString) {
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    // await this.props.actions
    //   .onGetAllEnvironment(userId)
    //   .then(async (env) => {});

    await this?.props?.homeActions
      .onGetMyEnvironment(
        userId,
        JSON.parse(localStorage.getItem("userInfo")).customerType === 3
          ? JSON.parse(localStorage.getItem("userInfo")).companyId
          : false
      )
      .then(async (env) => {
        if (env.status === 200 && env.data.success) {
          let reqObj = {
            type: "type",
            userId: userId,
          };

          await this?.props?.homeActions
            .onfetchNotification(reqObj)
            .then((res) => {});
          // await this.props.actions
          //   .onGetDeviceInfo(userId, env.data.data[0].id)
          //   .then((dev) => {
          //     this.setState({ isLoading: false });
          //   });
          this.setState({ isLoading: false });
        }
      });

    // await this.onGetDashboardDataView();
  }
  onGetSpaDevices() {
    const userId = localStorage.getItem("userId");
    const spa = "spa";
    this.props.homeActions.onGetOwnedSpaDevices(userId, spa).then((data) => {
      this.setState({
        ownSpas: data?.data?.data,
      });
    });
  }
  goToChemicalTesting = () => {
    const { history } = this?.props;
    history.push({
      pathname: RoutesObj.WaterTreatment.path,
      state: {
        SpaId: this.state.getOwnSpaid ? this.state.getOwnSpaid : spaDeviceId,
        // isShowChemicalTesting: true,
      },
    });
  };
  handleCloseOwnedSpaModal = () => {
    this.setState({ openOwnedSpaModal: false });
  };
  handleOpenOwnedSpaModal = () => {
    this.setState({ openOwnedSpaModal: true });
  };
  handleOwnDeviceClick = (id) => {
    this.setState({ isLoading: true, getOwnSpaid: id });
    this?.props?.actions?.onGetSpaInfo(id).then(async (env) => {
      this.setState({ spaDevData: env.data });
      this.setState({ isLoading: false });
    });
  };

  handleSelectedSpa = (tab) => {
    this.setState({ selectedSpaTab: tab });
  };
  toggleSubNav = () => {
    this.setState((prevState) => ({ showSubMenu: !prevState.showSubMenu }));
  };
  removeSubNav = () => {
    this.setState({ showSubMenu: false });
  };
  render() {
    const { isPlaying, trackProgress, isLoading } = this.state;
    const { spaOverviewReducer, dashboardState, homeState } = this?.props;
    // const { userInfo, userEnv } = dashboardState;
    const { userEnv, userInfo } = this?.props?.state;
    const { history, location } = this?.props;
    const currentRoot = history ? history.location.pathname : null;
    spaDeviceId = location.state.SpaId;

    let userNameS = "";
    if (userInfo) {
      userNameS = `${userInfo.firstName}${`'s `}`;
    }
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    return (
      <div className="Dashboardmain-container">
        {isLoading && <Loader />}
        <div
          style={{ height: 130 }}
          className="Dashboardmain-container__header"
        >
          <Header
            userInformation={userData ? userData : null}
            isDashboardShow={true}
            history={history}
            userEnvoirnment={userEnv ? userEnv : null}
            isSPA={true} // sending this to show the spa dropdwon if there is any SPA in the environemnt
            SPAIId={
              this.state.getOwnSpaid ? this.state.getOwnSpaid : spaDeviceId
            } // sending this to get spa details on the settings screen
            toggleSubNav={this.toggleSubNav}
            removeSubNav={this.removeSubNav}
          />
        </div>

        <CommonHeader
          spaDeviceId={
            this.state.getOwnSpaid ? this.state.getOwnSpaid : spaDeviceId
          }
          currentRoot={currentRoot}
          history={history}
        />

        <div
          style={{ marginTop: 70 }}
          className="Dashboardmain-container__innerBody deviceOverviewContainer"
        >
          <div className="mainTitle spaSpecificTitle">
            <span className="spaOwnerShipText">Spa Ownership</span>
          </div>
          <span className="spaAndHomeText">
            <span
              className="spaStandardHeading"
              style={{ color: constantColors.softGray }}
            >
              Home /{" "}
            </span>
            <span className="spaStandardHeading">Overview </span>
          </span>

          <div style={{ marginTop: 10 }} className="spaProductContentBody">
            <Grid container className="gridMainSpaWrapper adfd">
              <Grid item xs={12} sm={6} md={4}>
                <div className="mainTitle spaTitle">
                  <span className="pb-2">Spa Overview</span>
                </div>
                <OverViewCard
                  userName={userData?.firstName}
                  ownSpaData={this.state.spaDevData}
                  getOwnSpaid={this.state.getOwnSpaid}
                  spaDeviceId={spaDeviceId}
                />
                {this?.state?.ownSpas ? (
                  <OwnedSpa
                    handleOpenOwnedSpaModal={this.handleOpenOwnedSpaModal}
                    ownSpa={this.state.ownSpas}
                    setGetOwnSpaid={(value) =>
                      this.setState({ getOwnSpaid: value })
                    }
                    handleOwnDeviceClick={this.handleOwnDeviceClick}
                    spaDeviceId={spaDeviceId}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div className="mainTitle spaTitle">
                  <span className="pb-2">Temperature</span>
                </div>
                <TempratureCard
                  isActive={true}
                  getOwnSpaid={this.state.getOwnSpaid}
                />

                <div className="mainTitle spaTitle">
                  <span className="pb-2">Quick Tools</span>
                </div>
                <SpaQuickToolsCard getOwnSpaid={this.state.getOwnSpaid} />
                {/* {spaOverviewReducer?.spaDeviceDetails?.productType ===
                  "SPAMid" && (
                  <div style={{ margin: "4%" }}>
                    <LightsAudioCard
                      isPlaying={isPlaying}
                      togglePlayPause={this.SetIsPlaying}
                      handleNextTrack={(e) => console.log(e)}
                      handlePreviousTrack={(e) => console.log(e)}
                      trackProgress={trackProgress}
                      setTrackProgress={this.setTrackProgress}
                    />
                  </div>
                )} */}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div className="mainTitle spaTitle">
                  <span className="pb-2">Pumps</span>
                </div>
                <SpaPumpCard getOwnSpaid={this.state.getOwnSpaid} />
                {/* <LightsAudioCard
                  isPlaying={isPlaying}
                  togglePlayPause={this.SetIsPlaying}
                  handleNextTrack={(e) => console.log(e)}
                  handlePreviousTrack={(e) => console.log(e)}
                  trackProgress={trackProgress}
                  setTrackProgress={this.setTrackProgress}
                /> */}

                <div className="mainTitle spaTitle">
                  <span className="pb-2">Water Treatment</span>
                </div>
                <SpaWaterTreatment
                  chemicalTestManage={this.goToChemicalTesting}
                  isActive={true}
                  isShowManageBtn={true}
                  noDate
                  getOwnSpaid={this.state.getOwnSpaid}
                />
              </Grid>
            </Grid>
          </div>
          <div className="spaProductContentBody">
            {/* <Grid container spacing={1} className="gridMainSpaWrapper">
              <Reporting />
            </Grid> */}
          </div>
          <Modal
            open={this.state.openOwnedSpaModal}
            onClose={this.handleCloseOwnedSpaModal}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0) !important",
            }}
          >
            <Box sx={style} style={{ width: "65%", height: "471px" }}>
              <span className="spaTitle">All Owned Spas</span>
              <div style={{ marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {this?.state?.ownSpas?.map((item) => (
                    <div
                      style={{
                        marginBottom: "20px",
                        width: "45%",
                        marginRight: "10px",
                      }}
                      className={`gridOwnedSpaWrapper_ ${
                        this.state.selectedSpaTab === item.id ? "activeSpa" : ""
                      }`}
                    >
                      <div
                        className="ownedSpaInear"
                        style={{ padding: "10px 10px" }}
                        onClick={() => {
                          this.handleOwnDeviceClick(item.id);
                          this.handleCloseOwnedSpaModal();
                          this.handleSelectedSpa(item.id);
                        }}
                      >
                        <img
                          src={spaVector}
                          alt="frame"
                          width={60}
                          height={60}
                        />
                        <div style={{ width: "100%" }}>
                          <div className="bottomBorder">
                            <h4 className="">{item.name}</h4>
                            <p className="">{item.product}</p>
                          </div>
                          <div className="environmentHeading mt-2">
                            <p>Environment</p>
                            <h4>{item.environment}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    spaOverviewReducer: state.SpaOverviewReducer,
    dashboardState: state.DashboardReducer,
    homeState: state.DashboardReducer,
    state: state.DashboardReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(SpaOverviewAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),
  })
)(withRouter(SpaOverview));
