import React from "react";
import {
  getFirstCharOfString,
  getLastOnlineTimestamp,
} from "../../../components/common/CommonMethods";
import ReceivedMessage from "../ReceivedMessage";

const MblMessageInfo = ({ handleMessageListClick, userRooms }) => {
  const selectedChatWithChatBox = (chat) => {
    handleMessageListClick(chat);
  };
  return (
    <div>
      {userRooms?.length > 0 ? (
        userRooms?.map((item, index) =>
          item.chatInfo.map((info) => (
            <div
              className="message-info"
              onClick={() => selectedChatWithChatBox(item)}
              key={index}
            >
              <span
                className={`${item.lastMessage.isRead ? " " : "unread"}`}
              ></span>
              <div className="recipient">
                <div className="recipient-img">
                  <span className="text-uppercase">
                    {getFirstCharOfString(info.name)}
                  </span>
                </div>
                <div className="recipient-info">
                  <h3>{`${info.name} - ${item.roomId}`}</h3>
                  <p>
                    {item.lastMessage.co}
                    <ReceivedMessage
                      htmlContent={`${item?.lastMessage?.content?.slice(
                        0,
                        30
                      )}...`}
                    />
                  </p>
                </div>
              </div>
              <div className="time">
                <p>{getLastOnlineTimestamp(item.lastMessage.date)}</p>
              </div>
            </div>
          ))
        )
      ) : (
        <div className="noContactFound">
          <h5>No contact found</h5>
        </div>
      )}
    </div>
  );
};

export default MblMessageInfo;
