import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { attachFile, close } from "../../../assets/images/images";
import {
  getFirstCharOfString,
  getLastOnlineTimestamp,
  isDOCXUrl,
  isExcelUrl,
  isPDFUrl,
} from "../../../components/common/CommonMethods";
import {
  BlueHeaderLogo,
  BlueLogo,
  ProfileLogo
} from "../../../components/common/SVG/SvgIcons";
import ReactQuill from "react-quill";
import ReceivedMessage from "../ReceivedMessage";
import { toast } from "react-toastify";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { BsPaperclip } from "react-icons/bs";
import { IoIosCheckmark } from "react-icons/io";
import NewChatForm from "./NewChatForm";
import { Loader } from "../../../components/common";
import { useDispatch } from "react-redux";
import { conversationAction } from "../../../store/conversations/conversationAction";

const NewChat = ({
  selectedChatBox,
  messageDetailsData,
  onSendNewMessage,
  selectedChat,
  selectedNewRoomChatRoomId,
  getHubId,
  isNewChat,
  setIsNewChat,
  getChatIds,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [attachment, setAttachment] = useState(null);
  const chatboxRef = useRef(null);

  const [chatUserData , setChatUserData ] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
  });
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[a-zA-Z]{2,}[^\s@]*\.[a-zA-Z]{2,}$/;
    setError( !emailRegex.test(email) ? 'Please enter a valid email address.' : "")
    return emailRegex.test(email);
};

 const validateForm = () => {
  const { firstName, lastName, email } = chatUserData;
  if (!firstName || !lastName || !email) {
    setIsDisabled(true);
  } else if (!validateEmail(email)) {
    setIsDisabled(true);
  } else {
    setError("");
    setIsDisabled(false);
  }
};

const handleInputChange = (e) => {
  const { id, value } = e.target;
  setChatUserData((prevValue) => ({
      ...prevValue,
      [id]: value.trim()
  }));
};

useEffect(()=>{
  validateForm(); 
},[chatUserData])

  const handleMessageChange = (value) => {
    setMessage(value);
  };

  const handleMessageSend = (event) => {
    event.preventDefault();

    // Remove leading and trailing spaces from the message
    const trimmedMessage = message.trim();

    // Remove <br> tags if trimmedMessage is empty
    const cleanedMessage =
      trimmedMessage === "" ? "" : trimmedMessage.replace(/<br\s*\/?>/gi, "");

    // Remove empty <p> tags and extra whitespace
    const finalMessage = cleanedMessage.replace(/<p>\s*<\/p>\s*/g, "");

    // Check if the final message is either empty or contains only the specified pattern
    if (
      (finalMessage === "" || /^<p>\s*<\/p>\s*$/.test(finalMessage)) &&
      !attachment
    ) {
      toast.error("Text field can't be empty");
      setMessage("");
    } else {
      onSendNewMessage(finalMessage, selectedUser, attachment);
      setMessage("");
      setAttachment(null);
    }
  };

  const supportedFormat = [".jpeg", ".jpg", ".png", ".pdf", ".docx", ".doc"];

  const handleChangeAttachment = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileExtension = file.name
      .slice(file.name.lastIndexOf("."))
      .toLowerCase();

    if (!supportedFormat.includes(fileExtension)) {
      toast.error("Please upload only supported formats");
      return;
    } else {
      setAttachment(file);
      e.target.value = null;
    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", { list: "bullet" }, { list: "ordered" }],
    ],
  };

  const formats = ["bold", "italic", "underline", "bullet", "list"];
  useEffect(() => {
    // Scroll to the bottom when messages change
    scrollToBottom();
  }, [messageDetailsData]);

  const scrollToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };
  const handleRemove = (removed) => {
    // let newArray = attachment.filter((item) => item !== removed);
    // setAttachment(newArray);
    setAttachment(null);
  };
  const closeChat = () => {
    selectedChat();
    setMessage("");
    setAttachment(null);
  };
  const handleClickAttachment = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  function truncate(fileName) {
    if (fileName && fileName.length > 15) {
      return fileName.substring(0, 13) + "...";
    }
    return fileName;
  }
  
  const submitChatUserData = (event) => {
    event.preventDefault();
    setLoading(true);
    const payload = {
      content: "",
      roomName: "",
      messageFrom:  {
        id: "-1",
        name: "Guest user",
        allowedOnCampaignsId: "1",
        generic: ""
      },
      messageTo: [{
        id: "5e63f328-1876-4771-bcb6-53f59f29f8a3",
        name: "AQUATIC AV",
        allowedOnCampaignsId: "1",
        generic: "",
      }],
      productId: 2,
      attachments: [],
      connectionId: "",
      firstName: chatUserData.firstName,
      leadsBasicInfo: chatUserData
    };
    dispatch(conversationAction.onCreateLeadsForShopiy(payload))
    .then((res) => {
      if (res.responseCode === 1) {
        if(res?.responseData?.hubUserId && res?.responseData?.roomId){
       setIsNewChat(false);
       getChatIds(res?.responseData)
       localStorage.setItem("hubUserId", res?.responseData.hubUserId);
       localStorage.setItem(
        "selectedRoomIdLocal",
        JSON.stringify({
          id: res?.responseData?.roomId,
          isChatRoomExist: false,
          hubUserId: res?.responseData?.hubUserId,
        })
      )}
      }
    })
    .catch((error) => {
      setIsNewChat(true);
      console.log("There is some issue in chat Detail", error);
    })
    setLoading(false);
  }

  return (
    <>
      <div className="chat-screen">
        <div className="chat-screen-header">
          <div className="chat-screen-header-info">
            <div className="recipient-img chat-header-profile">
              {/* <span>{getFirstCharOfString("AQUATIC AV")}</span> */}
              <BlueHeaderLogo /> 
            </div>
            <div className="chat-screen-header-name">
              <h4 className="chat-screen-title">
               Aquatic AV Support
                {/* {selectedNewRoomChatRoomId?.id
                  ? selectedNewRoomChatRoomId?.id
                  : ""} */}
                <span className="newChatDot"></span>
              </h4>
              <p>
                {selectedChatBox
                  ? selectedChatBox.chatInfo[0].generic
                  : selectedUser?.generic}
              </p>
            </div>
          </div>
          <div className="chat-screen-header-action">
            {/* <div className="expand-img">
                  {chatFullScreen ? (
                    <img
                      src={collapse}
                      alt="collapse"
                      onClick={() => setChatFullScreen(false)}
                    />
                  ) : (
                    <img
                      src={expand}
                      alt="expand"
                      onClick={() => setChatFullScreen(true)}
                    />
                  )}
                </div> */}
            {/* <img src={close} alt="close" onClick={closeChat} /> */}
          </div>
        </div>
        {isNewChat ?
        (<NewChatForm
        chatUserData={chatUserData}
        handleInputChange={handleInputChange}
        submitChatUserData = {submitChatUserData}
        isDisabled={isDisabled}
        error={error}
        validateEmail={validateEmail}
        />) :
         <span>
          <div className="chat-wrapper" ref={chatboxRef}>
            {
              !messageDetailsData.length &&
              <p className="chat-help-text">
                Hi! How can we help?
              </p>
            }
            {messageDetailsData?.map((item) =>
              item?.hubUserId === getHubId?.hubUserId ? (
                <div className="sender">
                  <div className="sender-info">
                    <p>{getLastOnlineTimestamp(item.date)}</p>
                    <h4>You</h4>
                  </div>
                  {item?.attachements && item.attachements.length > 0
                    ? item.attachements.map((file, index) =>
                      file.path && file.path.trim() !== "" ? (
                        isPDFUrl(file.path) ||
                          isDOCXUrl(file.path) ||
                          isExcelUrl(file.path) ? (
                          <div
                            className="message"
                            onClick={() => handleClickAttachment(file.path)}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            {file.name}
                          </div>
                        ) : (
                          <div
                            className="message"
                            onClick={() => handleClickAttachment(file.path)}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            <img
                              src={file.path}
                              alt=""
                              style={{
                                maxWidth: "200px",
                                height: "auto",
                                borderRadius: 16,
                                marginTop: 6,
                              }}
                            />
                          </div>
                        )
                      ) : null
                    )
                    : null}
  
                  {item?.content !== "<p><br></p>" && item?.content !== "" ? (
                    <div className="message">
                      <p>
                        <ReceivedMessage htmlContent={item?.content} />
                      </p>
                    </div>
                  ) : null}
                  <div className="read-info">
                    {item?.isRead ? <p>Read</p> : <p>Delivered</p>}
                  </div>
                </div>
              ) : (
                <div className="receiver">
                  <div className="receiver-info">
              
                      <ProfileLogo />
                    <h4>
                      {/* {item?.name}{" "} */}
                      Aquatic AV Support
                      <span>{getLastOnlineTimestamp(item?.date)}</span>
                      <span className="activeDot"></span>
                    </h4>
                  </div>
                  {item?.attachements && item.attachements.length > 0
                    ? item.attachements.map((file, index) =>
                      file.path && file.path.trim() !== "" ? (
                        isPDFUrl(file.path) ||
                          isDOCXUrl(file.path) ||
                          isExcelUrl(file.path) ? (
                          <div
                            className="message"
                            onClick={() => handleClickAttachment(file.path)}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            {file.name}
                          </div>
                        ) : (
                          <div
                            className="message"
                            onClick={() => handleClickAttachment(file.path)}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            <img
                              src={file.path}
                              alt=""
                              style={{
                                maxWidth: "200px",
                                height: "auto",
                                borderRadius: 16,
                                marginTop: 6,
                              }}
                            />
                          </div>
                        )
                      ) : null
                    )
                    : null}
                  {item?.content && <div className="message">
                    <p>
                      <ReceivedMessage htmlContent={item?.content} />
                    </p>
                  </div>}
                  <div className="delivered">{/* <p>Delivered</p> */}</div>
                </div>
              )
            )}
          </div>
          <div className="chat-screen-bottom">
            <ReactQuill
              onChange={handleMessageChange}
              theme="snow"
              style={{
                border: "none",
              }}
              placeholder="Type message...."
              modules={modules}
              formats={formats}
              value={message}
            />
            <form className="chat-send" onSubmit={handleMessageSend}>
              
  
              <div className="chat-file">
                <label htmlFor="fileInput">
                  <input
                    type="file"
                    multiple
                    id="fileInput"
                    accept={supportedFormat.join(",")}
                    max={4}
                    style={{ display: "none" }}
                    onChange={(e) => handleChangeAttachment(e)}
                  />
                  {/* <img src={attachFile} alt="attach file" /> */}
                  <BsPaperclip />
                </label>
              </div>
              <button
                type="submit"
                style={{
                  opacity:
                    (/^\s*<p>\s*<\/p>\s*$/.test(message.replace(/\s+/g, "")) ||
                      message === "<p><br></p>") &&
                      !attachment
                      ? "0.6"
                      : "1",
                  pointerEvents:
                    (/^\s*<p>\s*<\/p>\s*$/.test(message.replace(/\s+/g, "")) ||
                      message === "<p><br></p>") &&
                      !attachment
                      ? "none"
                      : "",
                }}
              >
              
                <PiPaperPlaneRightFill />
              </button>
            </form>
            {attachment && (
                <div className="selectedFile" onClick={handleRemove}>
                  <p>{truncate(attachment.name)} <i><IoIosCheckmark /></i></p>
                </div>
              )}
          </div>
         </span>
        }
       
       
      
      </div>
     
    </>
  );
};

export default NewChat;
