import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

export default function AutoCompleteLoc({
  label,
  value,
  onChange,
  eventLocation,
  setEvent,
  onBlur,
  showRequired,
  disabled,
  className,
  isReg,
}) {
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const showList = (e) => {
    setIsAutocomplete(true);
    onChange(e);
  };
  const handle = (e) => {
    onChange(e);
  };
  return (
    <div className="autoCompleteWrapper">
      <Grid className="autompleteInner">
        <input
          placeholder={label}
          inputlabel={label}
          onChange={(e) => showList(e)}
          value={value}
          onBlur={onBlur}
          showRequired={showRequired}
          disabled={disabled}
          className={className}
        />
      </Grid>

      {isAutocomplete && (
        <div className={isReg ? "autoCompleteInnner_" : "autoCompleteInnner"}>
          <ul>
            {value !== "" &&
              eventLocation?.length > 0 &&
              eventLocation.map((x) => (
                <>
                  {x.value && x.value !== "" && (
                    <span
                      onClick={() => {
                        setIsAutocomplete(false);
                        setEvent(x);
                      }}
                    >
                      <li>
                        <span style={{ color: "#181c32", fontWeight: "600" }}>
                          {x.value}
                        </span>
                        <br />
                        {x.locationName}
                      </li>
                    </span>
                  )}
                </>
              ))}
            {eventLocation?.length === 0 && (
              <li onClick={() => setIsAutocomplete(false)}>no record found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
