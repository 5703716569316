import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getPriceList(groupId) {
  return axios
    .post(endpoints.priceList.getPriceList, groupId)
    .then((response) => {
      console.log("price list response:", response);
      let data = response?.data;
      return data;
    })
    .catch((error) => {
      console.log("price list error:", error);
      return null;
    });
}

async function getPriceListById(priceListId) {
  return axios
    .post(endpoints.priceList.getPriceListById, {
      id: priceListId,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function DownloadCSV(priceListId) {
  return axios
    .post(
      endpoints.priceList.DownloadCSV,
      {
        id: priceListId,
      },
      { responseType: "blob" }
    )
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function DownloadPDF(priceListId) {
  return axios
    .post(
      endpoints.priceList.DownloadPDF,
      {
        id: priceListId,
      },
      { responseType: "blob" }
    )
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export const PriceListApi = {
  getPriceList,
  getPriceListById,
  DownloadCSV,
  DownloadPDF,
};
