import React from "react";
import {
  convertTimestamp,
  getFirstCharOfString,
  getLastOnlineTimestamp,
} from "../../../components/common/CommonMethods";
import ReceivedMessage from "../ReceivedMessage";

const MessageInfo = ({ handleSelectedChat, userRooms }) => {
  const selectedChatWithChatBox = (chat) => {
    handleSelectedChat(chat);
  };

  return (
    <div>
      {userRooms?.length > 0 ? (
        userRooms?.map((item, index) =>
          item?.chatInfo?.map((info) => (
            <div
              className="message-info"
              onClick={() => selectedChatWithChatBox(item)}
              key={index + 1}
            >
              <span
                className={`${item.lastMessage.isRead ? " " : "unread"}`}
              ></span>
              <div className="recipient">
                <div className="recipient-img">
                  <span className="text-uppercase">
                    {getFirstCharOfString(info.name)}
                  </span>
                </div>
                <div className="recipient-info">
                  <h3>{`${info.name} - ${
                    item.roomId === undefined ? "" : item.roomId
                  }`}</h3>
                  <p>
                    <ReceivedMessage
                      htmlContent={`${item?.lastMessage?.content?.slice(
                        0,
                        30
                      )}...`}
                    />
                  </p>
                </div>
              </div>
              <div className="time">
                <p>{getLastOnlineTimestamp(item.lastMessage.date)}</p>
              </div>
            </div>
          ))
        )
      ) : (
        <div className="noContactFound">
          <h5>No contact found</h5>
        </div>
      )}
    </div>
  );
};

export default MessageInfo;
