import React, { useState, useEffect, useRef } from "react";
import {
  Spa,
  Motorcycles,
  Lighting,
  Grill,
  Powersports,
  Marine,
  House,
  Star,
} from "../../../components/common/SVG/SvgIcons";
import { Loader } from "../../../components/common/index";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import DeviceCategory from "./DeviceCategory";
import { RiAddFill, RiArrowDownSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { toast } from "react-toastify";
import Autocomplete from "react-google-autocomplete";

function SelectEnvironment({ closeAddDevice, onLoadCompleteData }) {
  const dispatch = useDispatch();
  const autoRef = useRef(null);
  const userId = localStorage.getItem("userId");
  const [isMyEnvironment, setIsMyEnvironment] = useState(false);
  const [isNewEnvironment, setIsNewEnvironment] = useState(false);

  const [isSelectEnv, setIsSelectEnv] = useState(true);
  const [selectCategory, setSelectCategory] = useState(false);
  const [selectedEnvName, setSelectedEnvName] = useState("");
  const [selectedEnvIcon, setSelectedEnvIcon] = useState("");
  const [selectedEnvData, setSelectedEnvData] = useState(null);
  const [customEnvName, setCustomEnvName] = useState("");
  const [selecedEnvId, setSelectedEnvId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addEnvironment, setAddEnvironment] = useState(false);
  const [selectedEnvLocObj, setSelectedEnvLocObj] = useState({});
  const [selectedEnvSameAccountLoc, setSelectedEnvSameAccountLoc] =
    useState(true);

  useEffect(() => {
    setIsLoading(true);
    dispatch(dashboardActions.onGetAllEnvironment(userId)).then((data) => {
      dispatch(dashboardActions.onGetAllProductType()).then((prod) => {
        setIsLoading(false);
      });
    });
  }, []);

  const changeEnvironment = () => {
    let req;
    // if (selecedEnvId && selecedEnvId !== null) {
    // setIsSelectEnv(false);
    // setSelectCategory(true);
    if (selecedEnvId) {
      setIsSelectEnv(false);
      setSelectCategory(true);
    } else {
      //we have selectd a env from the list of new env
      if (customEnvName === "") {
        req = {
          environmentId: 0,
          environmentName: selectedEnvData.environmentName,
          environmentType: selectedEnvData.environmentType,
          environmentIcon: selectedEnvData.environmentIcon,
          status: 1,
          userId: userId,
          location: !selectedEnvSameAccountLoc
            ? selectedEnvLocObj?.fullAddress
            : "",
          latitude: !selectedEnvSameAccountLoc
            ? selectedEnvLocObj?.lat || ""
            : "",
          longitude: !selectedEnvSameAccountLoc
            ? selectedEnvLocObj?.long || ""
            : "",
          isSameAccountLocation: selectedEnvSameAccountLoc,
        };
        setIsLoading(true);
        dispatch(dashboardActions.onAddDashboardEnv(req)).then((env) => {
          setIsLoading(false);
          if (env && env.data && env.data.success) {
            setSelectedEnvId(env.data.data);
            setSelectedEnvName(selectedEnvData.environmentName);
            setIsSelectEnv(false);
            setSelectedEnvData(null);
            setSelectCategory(true);
            dispatch(
              dashboardActions.onGetMyEnvironment(
                userId,
                JSON.parse(localStorage.getItem("userInfo")).customerType === 3
                  ? JSON.parse(localStorage.getItem("userInfo")).companyId
                  : false
              )
            );
          } else {
            toast.error(
              env && env.data && env.data.message
                ? env.data.message
                : "Something went wrong please try again"
            );
          }
        });
      } else {
        //we have Created a new env of our own which is not in the preset list
        if (selectedEnvIcon !== "" && customEnvName !== "") {
          req = {
            environmentId: 0,
            environmentName: customEnvName,
            environmentType: "D",
            environmentIcon: selectedEnvIcon,
            status: 1,
            userId: userId,
            location: !selectedEnvSameAccountLoc
              ? selectedEnvLocObj?.fullAddress
              : "",
            latitude: !selectedEnvSameAccountLoc
              ? selectedEnvLocObj?.lat || ""
              : "",
            longitude: !selectedEnvSameAccountLoc
              ? selectedEnvLocObj?.long || ""
              : "",
            isSameAccountLocation: selectedEnvSameAccountLoc,
          };
          setIsLoading(true);
          dispatch(dashboardActions.onAddDashboardEnv(req)).then((env) => {
            setIsLoading(false);
            if (env && env.data && env.data.success) {
              setSelectedEnvId(env.data.data);
              setSelectedEnvName(customEnvName);
              setIsSelectEnv(false);
              setSelectCategory(true);
              dispatch(
                dashboardActions.onGetMyEnvironment(
                  userId,
                  JSON.parse(localStorage.getItem("userInfo")).customerType ===
                    3
                    ? JSON.parse(localStorage.getItem("userInfo")).companyId
                    : false
                )
              );
            } else {
              toast.error(
                env && env.data && env.data.message
                  ? env.data.message
                  : "Something went wrong please try again"
              );
            }
          });
        } else {
          toast.error("Please select envoirnment");
        }
      }
    }
  };

  const onClickPrevious = () => {
    setIsSelectEnv(true);
    setSelectCategory(false);
  };

  const returnIcons = (iconName) => {
    if (iconName === "vector_0") {
      return <House />;
    } else if (iconName === "vector_1" || iconName === "vector_01") {
      return <Grill />;
    } else if (iconName === "vector_2" || iconName === "vector_02") {
      return <Lighting />;
    } else if (iconName === "vector_3" || iconName === "vector_03") {
      return <Marine />;
    } else if (iconName === "vector_4" || iconName === "vector_04") {
      return <Spa />;
    } else if (iconName === "vector_5" || iconName === "vector_05") {
      return <Motorcycles />;
    } else if (iconName === "vector_6" || iconName === "vector_06") {
      return <Powersports />;
    } else if (iconName === "vector_7" || iconName === "vector_07") {
      return <Star />;
    }
  };

  const { myStaticEnv, userEnv } = useSelector(
    (state) => state.DashboardReducer
  );

  const updatedStaticEnv = myStaticEnv.map((env) => {
    let index = userEnv.findIndex((i) => i.name === env?.environmentName);
    if (index > 0) {
      return { ...env, environmentId: userEnv[index].id };
    } else {
      return { ...env };
    }
  });

  return (
    <div>
      {isLoading && <Loader />}
      {isSelectEnv && (
        <>
          <h2>1. Select Environment</h2>
          <p>Select a pre-existing or new environment.</p>
          <span className="gap" />
          <p onClick={() => setIsMyEnvironment(!isMyEnvironment)}>
            My Environment{" "}
            {!isMyEnvironment ? (
              <BsChevronDown style={{ cursor: "pointer" }} />
            ) : (
              <BsChevronUp
                style={{ cursor: "pointer" }}
                // onClick={() => setIsMyEnvironment(!isMyEnvironment)}
              />
            )}
          </p>
          {isMyEnvironment && (
            <div className="environmentListPopup">
              <ul>
                {userEnv &&
                  userEnv.length > 0 &&
                  userEnv.map((items, index) => {
                    return (
                      <li key={index}>
                        <div
                          onClick={() => {
                            setSelectedEnvName(items.name);
                            setSelectedEnvIcon(items.icon);
                            setSelectedEnvId(items.id);
                            setSelectedEnvData(null);
                            setCustomEnvName("");
                          }}
                          className={`normalBox ${
                            selectedEnvName === items.name &&
                            selectedEnvIcon === items.icon &&
                            selecedEnvId === items.id
                              ? "selectedBox"
                              : ""
                          }`}
                        >
                          <span className="icon">
                            {returnIcons(
                              items?.iconName ? items?.iconName : items?.icon
                            )}
                          </span>
                          <span className="iconText">{items.name}</span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
          <span className="gap" />
          <p onClick={() => setIsNewEnvironment(!isNewEnvironment)}>
            New Environment{" "}
            {!isNewEnvironment ? (
              <BsChevronDown style={{ cursor: "pointer" }} />
            ) : (
              <BsChevronUp
                style={{ cursor: "pointer" }}
                // onClick={() => setIsNewEnvironment(!isNewEnvironment)}
              />
            )}
          </p>
          {isNewEnvironment && (
            <div className="environmentListPopup">
              <ul>
                {updatedStaticEnv &&
                  updatedStaticEnv.length > 0 &&
                  updatedStaticEnv.map((items, index) => {
                    return (
                      <li key={index}>
                        <div
                          onClick={() => {
                            setSelectedEnvName(items.environmentName);
                            setSelectedEnvIcon(items.environmentIcon);
                            setSelectedEnvId(0);
                            // setSelectedEnvId(items.environmentId);
                            setSelectedEnvData(items);
                            setCustomEnvName("");
                          }}
                          className={`normalBox ${
                            selectedEnvName === items.environmentName &&
                            selectedEnvIcon === items.environmentIcon
                              ? "selectedBox"
                              : ""
                          }`}
                        >
                          <span className="icon">
                            {returnIcons(items.environmentIcon)}
                          </span>
                          <span className="iconText">
                            {items.environmentName}
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <span
                className="addDeviceAdmin"
                onClick={() => setAddEnvironment(!addEnvironment)}
                style={{ width: "100%" }}
              >
                {addEnvironment ? <RiArrowDownSLine /> : <RiAddFill />} new
                custom environment
              </span>
              {addEnvironment && (
                <span className="environmentAdminFieldWrapper dashboardNewEnvironmentWrapper">
                  <input
                    type="text"
                    placeholder="Enter a name"
                    value={customEnvName}
                    onChange={(e) => {
                      setSelectedEnvId(null);
                      setSelectedEnvData(null);
                      setSelectedEnvName("");
                      setCustomEnvName(e.target.value);
                    }}
                    maxLength={30}
                  />
                  <div className="dashboardAddEnvironment  envIcons">
                    <ul>
                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_0");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_0" ? "selectedBox" : ""
                          }`}
                        >
                          <House />
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_1");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_1" ? "selectedBox" : ""
                          }`}
                        >
                          <Grill />
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_2");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_2" ? "selectedBox" : ""
                          }`}
                        >
                          <Lighting />
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_3");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_3" ? "selectedBox" : ""
                          }`}
                        >
                          <Marine />
                        </span>
                      </li>

                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_4");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_4" ? "selectedBox" : ""
                          }`}
                        >
                          <Spa />
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_5");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_5" ? "selectedBox" : ""
                          }`}
                        >
                          <Motorcycles />
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_6");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_6" ? "selectedBox" : ""
                          }`}
                        >
                          <Powersports />
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            setSelectedEnvIcon("vector_7");
                            setSelectedEnvName("");
                          }}
                          className={`icon ${
                            selectedEnvIcon === "vector_7" ? "selectedBox" : ""
                          }`}
                        >
                          <Star />
                        </span>
                      </li>
                    </ul>
                  </div>
                </span>
              )}
              <div className="environmentLocationPopup">
                <span className="loginWrapper__loginFormWrapper--inner">
                  <div className="inputMainWrapper">
                    <label
                      // htmlFor="popupEnvLocInput"
                      className="form-label"
                    >
                      Environment Location
                    </label>
                    <Autocomplete
                      id="popupEnvLocInput"
                      className={
                        selectedEnvSameAccountLoc
                          ? "location-input disabled"
                          : "location-input"
                      }
                      apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
                      options={{
                        types: ["address"],
                      }}
                      onPlaceSelected={(place) => {
                        // console.log(place.geometry.location.lng());
                        setSelectedEnvLocObj({
                          lat: place.geometry.location.lat(),
                          long: place.geometry.location.lng(),
                          fullAddress: place.formatted_address,
                        });
                      }}
                      ref={autoRef}
                    />
                  </div>
                </span>
                <label className="container">
                  <span
                    style={{
                      // color: "#49b8ef",
                      // textDecoration: "underline",
                      marginLeft: "25px",
                    }}
                  >
                    Same location as on my account
                  </span>
                  <input
                    type="checkbox"
                    id="popupEnvLoc"
                    value={selectedEnvSameAccountLoc}
                    checked={selectedEnvSameAccountLoc ? true : false}
                    onChange={(e) => {
                      if (!selectedEnvSameAccountLoc) {
                        setSelectedEnvLocObj({});
                        autoRef.current.value = "";
                      }
                      setSelectedEnvSameAccountLoc(!selectedEnvSameAccountLoc);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          )}
          <span className="footerNav ">
            <button
              id="pane2"
              className="nextNav"
              onClick={() => changeEnvironment()}
            >
              Next
            </button>
          </span>
        </>
      )}

      {selectCategory && (
        <DeviceCategory
          handlerPrevious={onClickPrevious}
          startLoading={() => setIsLoading(true)}
          stopLoading={() => setIsLoading(false)}
          customEnvName={customEnvName}
          selecedEnvId={selecedEnvId}
          selectedEnvIcon={selectedEnvIcon}
          selectedEnvName={selectedEnvName}
          closeAddDevice={() => closeAddDevice()}
          onLoadCompleteData={() => onLoadCompleteData()}
        />
      )}
    </div>
  );
}

export default SelectEnvironment;
