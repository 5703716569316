import React, { useEffect, useRef, useState } from "react";
import { attachFile, close } from "../../../assets/images/images";
import MblContactCard from "./MblContactCard";
import {
  getFirstCharOfString,
  getLastOnlineTimestamp,
  isDOCXUrl,
  isExcelUrl,
  isPDFUrl,
} from "../../../components/common/CommonMethods";
import ReactQuill from "react-quill";
import ReceivedMessage from "../ReceivedMessage";
import { toast } from "react-toastify";

const MblNewChat = ({
  isMblNewChat,
  setIsMblNewChat,
  setIsMblMessageList,
  setSelectChat,
  allDealors,
  selectedChatBox,
  hubUserIdProp,
  onSendNewMessage,
  selectedChat,
  fetchAllChatUsers,
  messageDetailsData,
  setMessageDetailsData,
}) => {
  const [message, setMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [attachment, setAttachment] = useState(null);
  const chatboxRef = useRef(null);

  const toolbarOptions = [
    ["bold", "italic", "underline", { list: "ordered" }, { list: "bullet" }],
  ];
  const formats = ["header", "bold", "italic", "underline", "list", "ordered"];

  const modules = {
    toolbar: {
      container: toolbarOptions,
    },
  };

  const handleMessageChange = (value) => {
    setMessage(value);
  };

  const handleMessageSend = (event) => {
    event.preventDefault();

    // Remove leading and trailing spaces from the message
    const trimmedMessage = message.trim();

    // Remove <br> tags if trimmedMessage is empty
    const cleanedMessage =
      trimmedMessage === "" ? "" : trimmedMessage.replace(/<br\s*\/?>/gi, "");

    // Remove empty <p> tags and extra whitespace
    const finalMessage = cleanedMessage.replace(/<p>\s*<\/p>\s*/g, "");

    // Check if the final message is either empty or contains only the specified pattern
    if (finalMessage === "" || /^<p>\s*<\/p>\s*$/.test(finalMessage)) {
      setMessage("");
      toast.error("Text field can't be empty");
    } else {
      onSendNewMessage(finalMessage, selectedUser, attachment);
      setMessage("");
      setAttachment(null);
    }
  };
  const handleCreateNewRoom = (chat) => {
    setSelectChat(true);
    setSelectedUser(chat);
    setMessageDetailsData([]);
  };
  const handleCLoseChat = () => {
    selectedChat();
    setIsMblMessageList(true);
    setMessage("");
    setAttachment(null);
  };

  const supportedFormat = [".jpeg", ".jpg", ".png", ".pdf", ".docx", "doc"];

  const handleChangeAttachment = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileExtension = file.name
      .slice(file.name.lastIndexOf("."))
      .toLowerCase();

    if (!supportedFormat.includes(fileExtension)) {
      toast.error("Please upload only supported formats");
      return;
    } else {
      setAttachment(file);
      e.target.value = null;
    }
  };

  const handleGetAllUsers = () => {
    fetchAllChatUsers();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageDetailsData]);

  const scrollToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };
  const handleRemove = () => {
    setAttachment(null);
  };

  const handleClickAttachment = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  return (
    <>
      {!isMblNewChat ? (
        <>
          <div className="chat-screen">
            <div className="chat-screen-header">
              <div className="chat-screen-header-info">
                <div className="recipient-img chat-screen-header-profile">
                  <span>
                    {getFirstCharOfString(
                      selectedChatBox
                        ? selectedChatBox?.chatInfo[0].name
                        : selectedUser?.name
                    )}
                  </span>
                </div>
                <div className="chat-screen-header-name">
                  <h4>
                    {/* {selectedChatBox
                      ? selectedChatBox?.chatInfo[0].name
                      : selectedUser?.name}{" "} */}
                    {`AQUATIC AV  ${
                      selectedChatBox.roomId !== undefined
                        ? -selectedChatBox.roomId
                        : ""
                    }`}
                    <span></span>
                  </h4>
                  <p>
                    {" "}
                    {selectedChatBox
                      ? selectedChatBox.chatInfo[0].generic
                      : selectedUser?.generic}
                  </p>
                </div>
              </div>
              <div className="chat-screen-header-action">
                <img
                  src={close}
                  alt="close"
                  onClick={() => {
                    handleCLoseChat();
                    handleGetAllUsers();
                  }}
                />
              </div>
            </div>
            <div className="chat-wrapper" ref={chatboxRef}>
              {messageDetailsData?.map((item) =>
                item?.hubUserId === hubUserIdProp.current ? (
                  <div className="sender">
                    <div className="sender-info">
                      <p>{getLastOnlineTimestamp(item.date)}</p>
                      <h4>You</h4>
                    </div>
                    {item?.attachements && item.attachements.length > 0
                      ? item.attachements.map((file, index) =>
                          file.path && file.path.trim() !== "" ? (
                            isPDFUrl(file.path) ||
                            isDOCXUrl(file.path) ||
                            isExcelUrl(file.path) ? (
                              <div
                                className="message"
                                onClick={() => handleClickAttachment(file.path)}
                                style={{ cursor: "pointer" }}
                                key={index}
                              >
                                {file.name}
                              </div>
                            ) : (
                              <div
                                className="message"
                                onClick={() => handleClickAttachment(file.path)}
                                style={{ cursor: "pointer" }}
                                key={index}
                              >
                                <img
                                  src={file.path}
                                  alt=""
                                  style={{
                                    maxWidth: "200px",
                                    height: "auto",
                                    borderRadius: 16,
                                    marginTop: 6,
                                  }}
                                />
                              </div>
                            )
                          ) : null
                        )
                      : null}

                    {item?.content !== "<p><br></p>" ? (
                      <div className="message">
                        <p>
                          <ReceivedMessage htmlContent={item?.content} />
                        </p>
                      </div>
                    ) : null}
                    <div className="read-info">
                      {item?.isRead ? <p>Read</p> : <p>Delivered</p>}
                    </div>
                  </div>
                ) : (
                  <div className="receiver">
                    <div className="receiver-info">
                      <div className="recipient-img">
                        <span className="text-uppercase">
                          {getFirstCharOfString(item?.name)}
                        </span>
                      </div>
                      <h4>
                        {item?.name}{" "}
                        <span>{getLastOnlineTimestamp(item?.date)}</span>
                      </h4>
                    </div>
                    {item?.attachements && item.attachements.length > 0
                      ? item.attachements.map((file, index) =>
                          file.path && file.path.trim() !== "" ? (
                            isPDFUrl(file.path) ||
                            isDOCXUrl(file.path) ||
                            isExcelUrl(file.path) ? (
                              <div
                                className="message"
                                onClick={() => handleClickAttachment(file.path)}
                                style={{ cursor: "pointer" }}
                                key={index}
                              >
                                {file.name}
                              </div>
                            ) : (
                              <div
                                className="message"
                                onClick={() => handleClickAttachment(file.path)}
                                style={{ cursor: "pointer" }}
                                key={index}
                              >
                                <img
                                  src={file.path}
                                  alt=""
                                  style={{
                                    maxWidth: "200px",
                                    height: "auto",
                                    borderRadius: 16,
                                    marginTop: 6,
                                  }}
                                />
                              </div>
                            )
                          ) : null
                        )
                      : null}
                    <div className="message">
                      <p>
                        <ReceivedMessage htmlContent={item?.content} />
                      </p>
                    </div>
                    <div className="delivered">{/* <p>Delivered</p> */}</div>
                  </div>
                )
              )}
            </div>
            <div className="chat-screen-bottom">
              <ReactQuill
                placeholder="Type message...."
                value={message}
                onChange={handleMessageChange}
                theme="snow"
                modules={modules}
                formats={formats}
                style={{ border: "none" }}
              />

              <form className="chat-send" onSubmit={handleMessageSend}>
                {attachment && (
                  <div className="selectedFile" onClick={handleRemove}>
                    <p>{attachment.name}</p>
                  </div>
                )}
                <div className="chat-file">
                  <label htmlFor="fileInputMob">
                    <input
                      type="file"
                      multiple
                      id="fileInputMob"
                      accept={supportedFormat.join(",")}
                      max={4}
                      style={{ display: "none" }}
                      onChange={(e) => handleChangeAttachment(e)}
                    />
                    <img src={attachFile} alt="attach file" />
                  </label>
                </div>
                <button
                  type="submit"
                  style={{
                    opacity:
                      (/^\s*<p>\s*<\/p>\s*$/.test(
                        message.replace(/\s+/g, "")
                      ) ||
                        message === "<p><br></p>") &&
                      !attachment
                        ? "0.6"
                        : "1",
                    pointerEvents:
                      (/^\s*<p>\s*<\/p>\s*$/.test(
                        message.replace(/\s+/g, "")
                      ) ||
                        message === "<p><br></p>") &&
                      !attachment
                        ? "none"
                        : "",
                  }}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="new-chat-header">
            <div>
              <h4>Start New Conversation</h4>
              <p>Select a contact to start a new chat</p>
            </div>
            <img
              src={close}
              alt="close"
              onClick={() => setIsMblMessageList(true)}
            />
          </div>
          <div className="contact-cards-list">
            <MblContactCard
              setIsMblNewChat={setIsMblNewChat}
              allDealors={allDealors}
              handleCreateNewRoom={handleCreateNewRoom}
            />
          </div>
        </>
      )}
    </>
  );
};

export default MblNewChat;
