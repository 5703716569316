import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getSpaInfo(deviceInfo) {
  return axios
    .post(endpoints.spaDevice.getSpaInfo, {
      id: deviceInfo,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function addUpdateSpaSetting(spaData) {
  return axios
    .post(endpoints.spaDevice.addUpdateSetting, spaData)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

// SPA APIs
async function changeWaterTreatment(wtrData) {
  return axios
    .post(endpoints.spaDevice.updateWaterTreatment, wtrData)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function changeGlobalSetting(globalData) {
  return axios
    .post(endpoints.spaDevice.updateGlobalSetting, globalData)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function changeTemperatureSetting(tempData) {
  return axios
    .post(endpoints.spaDevice.updateTemperatureSetting, tempData)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getSpaGeneralInfor(genInfo) {
  return axios
    .post(endpoints.spaDevice.getSpaGeneralInfo, genInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getSpaFilterInfor(filtInfo) {
  return axios
    .post(endpoints.spaDevice.getSpaFilterInfo, filtInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getSpaTempInfor(tempInfo) {
  return axios
    .post(endpoints.spaDevice.getSpaTempInfo, tempInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getAllWaterLife(spaId) {
  return axios
    .get(`${endpoints.spaDevice.getAllWaterLif}${spaId}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function addUpdateWaterLife(waterInfo) {
  return axios
    .post(endpoints.spaDevice.addUpdateWaterLife, waterInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function addUpdateChemicalTesting(chemicalInfo) {
  return axios
    .post(endpoints.spaDevice.addUpdateChemicalTesting, chemicalInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getAllFilterLife(spaId) {
  return axios
    .get(`${endpoints.spaDevice.getAllFilterLif}${spaId}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function addUpdateFilterLife(waterInfo) {
  return axios
    .post(endpoints.spaDevice.addUpdateFilterLife, waterInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getFilterStats(spaId) {
  return axios
    .get(`${endpoints.spaDevice.getFilterLifeState}${spaId}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getWaterStats(spaId) {
  return axios
    .get(`${endpoints.spaDevice.getWaterLifeState}${spaId}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function deleteFilterLife(filtrInfo) {
  return axios
    .post(endpoints.spaDevice.deleteFilterLife, filtrInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function deleteWaterLife(waterInfo) {
  return axios
    .post(endpoints.spaDevice.deleteWaterLife, waterInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getCurrentSpaChemicalTestig(testInfo) {
  return axios
    .post(endpoints.spaDevice.getCurrentSpaChemicalTestig, testInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getCurrentSpaChemicalTestigGraphDetails(testInfo) {
  return axios
    .post(endpoints.spaDevice.getCurrentSpaChemicalTestigGraphDetails, testInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getFilterLifeGraphDetails(payload) {
  return axios
    .post(endpoints.spaDevice.getFilterLifeGraphDetails, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

async function getWaterLifeGraphDetails(payload) {
  return axios
    .post(endpoints.spaDevice.getWaterLifeGraphDetails, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

async function getTemperatureSettingGraphDetails(payload) {
  return axios
    .post(endpoints.spaDevice.getTemperatureSettingGraphDetails, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

async function getSpaChemicalTestigList(testInfo) {
  return axios
    .post(endpoints.spaDevice.getSpaChemicalTestigList, testInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function deleteSPAChemicalTesting(filtrInfo) {
  return axios
    .post(endpoints.spaDevice.deleteSPAChemicalTesting, filtrInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getSpaFilterSettings(testInfo) {
  return axios
    .post(endpoints.spaDevice.getSpaFilterSettings, testInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function updateSpaFilterSettings(testInfo) {
  return axios
    .post(endpoints.spaDevice.updateSpaFilterSettings, testInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function addUpdateSpaUser(spaUser) {
  return axios
    .post(endpoints.spaUser.getUpdateSpaUserDeviceByDeviceId, spaUser)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getSpaUser(id) {
  return axios
    .get(`${endpoints.spaUser.getSpaUserById}/${id}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function removeSpaUser(id) {
  return axios
    .delete(`${endpoints.spaUser.removeSpaUserByDeviceId}/${id}`)
    .then((response) => {
      console.log("res issue:", response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
async function getSpaDevices(deviceId) {
  return axios
    .get(`${endpoints.spaDevice.getSpaDevices}/${deviceId}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function removeSpaDevice(id) {
  return axios
    .delete(`${endpoints.spaDevice.removeSpaDevice}/${id}`)
    .then((response) => {
      console.log("res issue:", response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function updateSpaDevice(spaUser) {
  return axios
    .put(endpoints.spaDevice.updateSpaDevice, spaUser)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getSpaNotification(deviceId) {
  return axios
    .get(`${endpoints.spaNotification.spaNotification}/${deviceId}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function getSpaGlobalScenes(id) {
  return axios
    .get(`${endpoints.spaDevice.getSpaGlobalScenes}/${id}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}
async function removeSpaGlobalScene(id) {
  return axios
    .delete(`${endpoints.spaDevice.removeSpaGlobalScene}/${id}`)
    .then((response) => {
      console.log("res issue:", response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function setAsCurrentSpaGlobalScene(value) {
  return axios
    .post(endpoints.spaDevice.updateSpaGlobalScene, value)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getSpaSupportResources(productId) {
  return axios
    .post(endpoints.supportResource.getSpaSupportResources, productId)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getSpaAccessIcons() {
  return axios
    .post(endpoints.spaUser.getSpaAccessIcons)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getDeviceUseHistory(data) {
  return axios
    .post(endpoints.spaDevice.getDeviceUseHistory, data)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

export const spaOverviewApi = {
  getSpaInfo,
  addUpdateSpaSetting,
  changeWaterTreatment,
  changeGlobalSetting,
  changeTemperatureSetting,
  getSpaGeneralInfor,
  getSpaFilterInfor,
  getSpaTempInfor,
  getAllWaterLife,
  addUpdateWaterLife,
  getAllFilterLife,
  addUpdateFilterLife,
  getFilterStats,
  getWaterStats,
  deleteFilterLife,
  deleteWaterLife,
  getCurrentSpaChemicalTestig,
  getCurrentSpaChemicalTestigGraphDetails,
  getFilterLifeGraphDetails,
  getWaterLifeGraphDetails,
  getTemperatureSettingGraphDetails,
  addUpdateChemicalTesting,
  getSpaChemicalTestigList,
  deleteSPAChemicalTesting,
  getSpaFilterSettings,
  updateSpaFilterSettings,
  addUpdateSpaUser,
  getSpaUser,
  removeSpaUser,
  getSpaDevices,
  removeSpaDevice,
  updateSpaDevice,
  getSpaNotification,
  getSpaGlobalScenes,
  removeSpaGlobalScene,
  setAsCurrentSpaGlobalScene,
  getSpaSupportResources,
  getSpaAccessIcons,
  getDeviceUseHistory,
};
