import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  AppBtn,
  AppModal,
  Header,
  Loader,
} from "../../../components/common/index";
import { RoutesObj } from "../../../routes";
import { deviceOverviewAction } from "../../../store/deviceOverview/deviceOverviewAction";
import { Speaker, ArrowDown, ArrowUp } from "../../../resources/images";
import { PriceListAction } from "../../../store/PriceList/PriceListAction";
import { dashboardActions } from "../../../store/dashboard/dashboardActions"
import { toast } from "react-toastify";
import fileDownload from "js-file-download";

class PriceListDetail extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      priceListDetail: null,
      priceListData: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.onLoadCompleteData();
    this.setState({
      isLoading: true,
      priceListData: location.state.priceList,
    });
    this.props.PriceListAction.onGetPriceListById(
      location.state.priceList.priceListId
    ).then((response) => {
      if (response !== null && response.success === true) {
        this.setState({
          priceListDetail: response?.data,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error("Something went wrong, Please try again");
      }
    });
  }

  onDownloadCSV = () => {
    this.setState({ isLoading: true });
    this.props.PriceListAction.onDownloadCSV(
      this.state.priceListData.priceListId
    ).then((response) => {
      this.setState({ isLoading: false });
      fileDownload(
        response,
        `${this.state.priceListData?.priceListNameVisible}.csv`
      );
    });
  };

  onDownloadPDF = () => {
    this.setState({ isLoading: true });
    this.props.PriceListAction.onDownloadPDF(
      this.state.priceListData.priceListId
    )
      .then((response) => {
        console.log("response status:", response);
        this.setState({ isLoading: false });
        fileDownload(
          response,
          `${this.state.priceListData?.priceListNameVisible}.pdf`
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error("Something went wrong, Please try again");
      });
  };
  async onLoadCompleteData(showMsg, msgString) {
    const userId = localStorage.getItem("userId");
    if (showMsg) {
      toast.success(msgString);
    }
    await this.props.homeActions
      .onGetMyEnvironment(
        userId,
        JSON.parse(localStorage.getItem("userInfo")).customerType === 3
          ? JSON.parse(localStorage.getItem("userInfo")).companyId
          : false
      )
      .then(async (env) => {
        if (env.status === 200 && env.data.success) {
          let reqObj = {
            type: "type",
            userId: userId,
          };

          await this.props.homeActions
            .onfetchNotification(reqObj)
            .then((res) => { });
          this.setState({ isLoading: false });
        }
      });

    await this.onGetDashboardDataView();
  }
  render() {
    const { isLoading } = this.state;
    const { history } = this.props;
    const { dashboardState } = this.props;
    const { userInfo, userEnv } = dashboardState;
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    return (
      <div className="Dashboardmain-container">
        {/* {isLoading && <Loader />} */}
        <div className="Dashboardmain-container__header">
          <Header
            userInformation={userData ? userData : null}
            userEnvoirnment={userEnv}
            history={history}
          />

          <div className="mainTitle">
            <span>
              {this.state.priceListData?.priceListNameVisible}
              <span className="mainTitle__breadCrum">
                Catalog <i></i> Price Lists <i></i>{" "}
                {this.state.priceListData?.priceListNameVisible}
              </span>
            </span>

            <button
              className="back-to-catalog"
              onClick={() => history.push(RoutesObj.Catalog.path)}
            >
              Back to Catalog
            </button>

            {/* <button
              className="back-to-catalog"
              onClick={() => history.goBack()}
            >
              Back to Price Lists
            </button> */}
            {/* <div className="deviceBtn">
              <AppBtn
                text="Devices"
                onClick={() => history.push(RoutesObj.MyAccount.path)}
              />
            </div> */}
          </div>
        </div>

        <div className="price mt-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 price-list">
                <div className="price-list-wrap">
                  <a
                    onClick={() => history.goBack()}
                    className="backtopricelist"
                  >
                    {" "}
                    {"<"} Back to All Price Lists{" "}
                  </a>
                  <div className="row">
                    <div className="col-md-7">
                      <h2 className="mt-2">
                        {this.state.priceListData?.priceListNameVisible}
                      </h2>
                      <p>
                        {console.log(
                          "priceListDetail",
                          this.state.priceListDetail
                        )}
                        {/* Prices effective October 2021 - December 2021; subject
                        to change. Prices per item and in retail packaging
                        unless otherwise stated. Minimum order is a full Master
                        Carton. For the latest pricing, visit your account on
                        Aquatic AV Store at store.aquaticav.com */}
                        {this.state.priceListDetail && (
                          <>
                            {this.state.priceListDetail[0].priceListDescription}
                          </>
                        )}
                      </p>
                    </div>
                    <div className="col-md-5 text-right">
                      {this.state.priceListData?.groupAccess?.isAllAccess ? (
                        <button
                          disabled={
                            this.state.priceListData?.groupAccess?.isAllAccess
                              ? false
                              : this.state.priceListData?.groupAccess
                                .canDownloadCSVAccess
                                ? false
                                : true
                          }
                          onClick={this.onDownloadCSV}
                          className="btn1 mr-2"
                          style={{
                            backgroundColor: this.state.priceListData
                              ?.groupAccess?.isAllAccess
                              ? "#3699ff"
                              : this.state.priceListData?.groupAccess
                                .canDownloadCSVAccess
                                ? "#3699ff"
                                : "#6c757d",
                            cursor: this.state.priceListData?.groupAccess
                              ?.isAllAccess
                              ? null
                              : this.state.priceListData?.groupAccess
                                .canDownloadCSVAccess
                                ? null
                                : "default",
                          }}
                        >
                          Download as CSV
                        </button>
                      ) : this.state.priceListData?.groupAccess
                        ?.canDownloadCSVAccess ? (
                        <button
                          disabled={
                            this.state.priceListData?.groupAccess?.isAllAccess
                              ? false
                              : this.state.priceListData?.groupAccess
                                .canDownloadCSVAccess
                                ? false
                                : true
                          }
                          onClick={this.onDownloadCSV}
                          className="btn1 mr-2"
                          style={{
                            backgroundColor: this.state.priceListData
                              ?.groupAccess?.isAllAccess
                              ? "#3699ff"
                              : this.state.priceListData?.groupAccess
                                .canDownloadCSVAccess
                                ? "#3699ff"
                                : "#6c757d",
                            cursor: this.state.priceListData?.groupAccess
                              ?.isAllAccess
                              ? null
                              : this.state.priceListData?.groupAccess
                                .canDownloadCSVAccess
                                ? null
                                : "default",
                          }}
                        >
                          Download as CSV
                        </button>
                      ) : null}

                      {this.state.priceListData?.groupAccess?.isAllAccess ? (
                        <button
                          onClick={this.onDownloadPDF}
                          disabled={
                            this.state.priceListData?.groupAccess?.isAllAccess
                              ? false
                              : this.state.priceListData?.groupAccess
                                .canDownloadPDFAccess
                                ? false
                                : true
                          }
                          className="btn1"
                          style={{
                            backgroundColor: this.state.priceListData
                              ?.groupAccess?.isAllAccess
                              ? "#3699ff"
                              : this.state.priceListData?.groupAccess
                                .canDownloadPDFAccess
                                ? "#3699ff"
                                : "#6c757d",
                            cursor: this.state.priceListData?.groupAccess
                              ?.isAllAccess
                              ? null
                              : this.state.priceListData?.groupAccess
                                .canDownloadPDFAccess
                                ? null
                                : "default",
                          }}
                        >
                          Download as PDF
                        </button>
                      ) : this.state.priceListData?.groupAccess
                        ?.canDownloadPDFAccess ? (
                        <button
                          onClick={this.onDownloadPDF}
                          disabled={
                            this.state.priceListData?.groupAccess?.isAllAccess
                              ? false
                              : this.state.priceListData?.groupAccess
                                .canDownloadPDFAccess
                                ? false
                                : true
                          }
                          className="btn1"
                          style={{
                            backgroundColor: this.state.priceListData
                              ?.groupAccess?.isAllAccess
                              ? "#3699ff"
                              : this.state.priceListData?.groupAccess
                                .canDownloadPDFAccess
                                ? "#3699ff"
                                : "#6c757d",
                            cursor: this.state.priceListData?.groupAccess
                              ?.isAllAccess
                              ? null
                              : this.state.priceListData?.groupAccess
                                .canDownloadPDFAccess
                                ? null
                                : "default",
                          }}
                        >
                          Download as PDF
                        </button>
                      ) : null}

                      <p className="text-danger pt-4">
                        This Document is Confidental.
                      </p>
                      <p className="text-danger">DO NOT SHARE.</p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "2%",
                  }}
                >
                  {!this.state.priceListDetail ?<Loader/>: (
                    <table
                      className={
                        this.state.priceListDetail?.[0]?.columns?.length > 5
                          ? "table table-style"
                          : "table table-style newt"
                      }
                    >
                      <thead>
                        <tr>
                          {this.state.priceListDetail?.[0]?.columns?.map(
                            (value, index) => (
                              <th key={index} scope="col">
                                {value}
                              </th>
                            )
                          )}

                          {/* <th scope="col">
                        Product Name
                        <button className="p-0 pl-2 bg-transparent arrow-sort">
                          <img src={ArrowUp} />
                        </button>
                        <button className="p-0 bg-transparent arrow-sort">
                          <img src={ArrowDown} />
                        </button>
                      </th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.priceListDetail?.map((value, index) => {
                          return (
                            <>
                              <tr
                                key={index}
                                style={{ backgroundColor: "#317fed" }}
                              >
                                <td
                                  colSpan={
                                    this.state.priceListDetail[0].columns.length
                                  }
                                  style={{
                                    textAlign: "start",
                                    color: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {value.category}
                                </td>
                              </tr>
                              {console.log("value", value)}

                              {value.data.map((innerValue, innerIndex) => {
                                return (
                                  <tr key={innerIndex}>
                                    {value.columns.map((cv, ci) => {
                                      if (cv === "Product Image") {
                                        return (
                                          <td>
                                            <img src={innerValue[cv]} />
                                          </td>
                                        );
                                      } else {
                                        return (
                                          <td key={ci}>{innerValue[cv]}</td>
                                        );
                                      }
                                    })}

                                    {/* <td>Mark</td>
                                <td>
                                  <img src={Speaker} />
                                </td>
                                <td className="text-under">$200.00</td>
                                <td className="text-under">$200.00</td>
                                <td>
                                  <ul>
                                    <li>Product Feature 1</li>
                                    <li>Product Feature 1</li>
                                    <li>Product Feature 1</li>
                                    <li>Product Feature 1</li>
                                    <li>Product Feature 1</li>
                                    <li>Product Feature 1</li>
                                  </ul>
                                </td> */}
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}

                        {/* <tr>
                      <td>SKU111</td>
                      <td>Jacob</td>
                      <td>
                        <img src={Speaker} />
                      </td>
                      <td className="text-under">$200.00</td>
                      <td className="text-under">$200.00</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>SKU111</td>
                      <td>Larry</td>
                      <td>
                        <img src={Speaker} />
                      </td>
                      <td>$200.00</td>
                    </tr> */}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dashboardState: state.DashboardReducer,
  }),
  (dispatch) => ({
    PriceListAction: bindActionCreators(PriceListAction, dispatch),
    homeActions: bindActionCreators(dashboardActions, dispatch),

  })
)(withRouter(PriceListDetail));
