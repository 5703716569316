import React from "react";
import { Link } from "react-router-dom";

function SingleDeviceOverview({
  picture,
  name,
  sku,
  serialnumber,
  environment,
  lastconnected,
  version,
  registration,
  warrantyremaining,
  warrantyexpire,
  onregistrationclick,
  supportResources,
  category,
  productType
}) {
  return (
    <div className="overviewInner">
      <div className="devicePicture">
        <img src={picture} alt="" />
      </div>

      <div className="deviceDetail">
        <ul>
          <li>
            <span>Name</span>
            <span>{name}</span>
          </li>
          <li>
            <span>SKU</span>
            <span>{sku}</span>
          </li>
          <li>
            <span>Serial Number</span>
            <span>{serialnumber}</span>
          </li>
          <li>
            <span>Environment :</span>
            <span>{environment}</span>
          </li>
          {/* {sku && sku.includes("RG") */}
          {productType && productType==="Physical + IOT" || productType==="SPA Basic" || productType==="SPA Mid" ? (
            <>
              <li>
                <span>Last Connected :</span>
                <span>{lastconnected}</span>
              </li>
              <li>
                <span>Software/Firmware Version :</span>
                <span>{version}</span>
              </li>
            </>
          ) : null}
          <li>
            <span>Product Registration</span>
            <span className="registrationLink">
              {registration.charAt(0).toUpperCase() + registration.slice(1)}
              <button onClick={() => onregistrationclick()}>View</button>
            </span>
          </li>
          <li>
            <span>Warranty Remaining: </span>
            <span>{warrantyremaining}</span>
          </li>
          <li>
            <span>Warranty Expiration: </span>
            <span>{warrantyexpire}</span>
          </li>
        </ul>
      </div>
      {supportResources.length ? (
        <div className="supportResources">
          <h3>Support Resources</h3>
          <ul>
            {supportResources.map((item, key) => (
              <React.Fragment key={key}>
                <li>
                  <Link to={item.value} target="_blank">
                    {item.key}
                  </Link>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
}

export default SingleDeviceOverview;
