import React, { useState } from "react";
import { FloatingLabelInput, Loader } from "../../../components/common";
import Autocomplete from "react-google-autocomplete";
import { ChevronBack, verifyEmailCode } from "../../../resources/images";
import { RiCheckFill } from "react-icons/ri";
import moment from "moment";
import { toast } from "react-toastify";
import AddDeviceBox from "../../AddDeviceBox";
import { useDispatch, useSelector } from "react-redux";
import { backArrow, searchIcon } from "../../../resources/images/index";
import { dashboardActions } from "../../../store/dashboard/dashboardActions";
import { SingleDatePicker } from "react-dates";
import { deviceOverviewAction } from "../../../store/deviceOverview/deviceOverviewAction";
import Grid from "@material-ui/core/Grid";

import "react-dates/lib/css/_datepicker.css";
import { accountActions } from "../../../store/accounts/accountActions";
import AutoCompleteLoc from "../../../components/common/AutoCompleteLoc";
import { isPDFUrl } from "../../../components/common/CommonMethods";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import { Document, Page, pdfjs } from "react-pdf";

const AddProduct = ({
  onClick,
  onSkipClick,
  stepNumber,
  environmentId,
  environmentName,
  isAddDeviceFlow,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nameDevice, setNameDevice] = useState("");
  const [productTypeId, setProductTypeId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [purchaseLoc, setPurchaseLoc] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [receipt, setReceipt] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [selectedEnvId, setSelectedEnvId] = useState("");
  const [deviceError, setDeviceError] = useState(false);
  const [isShowSerialNo, setIsShowSerialNo] = useState(false);
  const [dublicateSerial, setDublicateSerial] = useState(false);
  const [deviceButton, setDeviceButton] = useState(true);
  const [deviceImage, setDeviceImage] = useState(false);
  const [step2, setStep2] = useState(false);
  const [productCategory, setProductCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [recepit, setRecepit] = useState("");
  const [displayReceipt, setDisplayReceipt] = useState("");
  const [receiptStatus, setReceiptStatus] = useState("");
  const [allDelears, setAllDealers] = useState([]);
  const [focused, setFocused] = useState(false);
  const [installationDateFocused, setInstallationDateFocused] = useState(false);
  const [searchQueryProduct, setSearchQueryProduct] = useState("");
  const [purchaseError, setPurchaseError] = useState(false);
  const [viewReceipt, setViewReceipt] = useState(false);

  const { productType, allProducts } = useSelector(
    (state) => state.DashboardReducer
  );
  const dispatch = useDispatch();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    background: "white",
    borderRadius: "20px",
    // bgcolor: "background.paper",
    // border: '2px solid #000',
    // boxShadow: 24,
    padding: "30px",
    outline: "none",
  };

  const onAddDevice = () => {
    const userId = localStorage.getItem("userId");
    setIsLoading(true);
    const deviceData = {
      name: nameDevice,
      product_type_id: productTypeId,
      productId: selectedProduct.productId,
      serialNo: serialNumber,
      purchase_location: purchaseLoc,
      purchaseDate: moment(purchaseDate).format("MM/DD/YYYY"),
      basePath: "",
      receiptStatus: receiptStatus,
      receipt: receipt,
      userId: userId,
      companyLocationId: dealerId?.id,
      dealerLocationId: dealerId?.companyId,
      sku: selectedProduct.sku,
      envId: environmentId,
      colors: [""],
    };

    if (installationDate) {
      deviceData.installationDate =
        moment(installationDate).format("MM/DD/YYYY");
    }

    dispatch(dashboardActions.onAddDashboardDevice(deviceData))
      .then((device) => {
        setIsLoading(false);
        if (device?.data?.success) {
          accountActions.onChange("currentPaneId", "pane5");
          onClick();
        } else {
          toast.error("Something went wrong, please try again");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong, please try again");
      });
  };

  const checkAddDevice = () => {
    if (
      nameDevice !== "" &&
      purchaseLoc !== "" &&
      purchaseDate !== "" &&
      selectedProduct !== ""
    ) {
      // if (isShowSerialNo) {
      //   if (serialNumber !== "" && !dublicateSerial) {
      //     onAddDevice();
      //   } else {
      //     setDeviceError(true);
      //     setPurchaseError(true);
      //   }
      // } else {
      //   onAddDevice();
      // }
      onAddDevice();
    } else {
      setDeviceError(true);
      setPurchaseError(true);
    }
  };
  const onDeviceButtonClick = (itemId) => {
    setIsLoading(true);
    setProductTypeId(itemId);
    dispatch(dashboardActions.onGetProducts(itemId)).then((data) => {
      dispatch(dashboardActions.getAllDealers(itemId));
      setIsLoading(false);
    });
    setDeviceButton(false);
    setDeviceImage(true);
  };
  // const onDeviceButtonClick = itemId => {
  //   this.setState({ isLoading: true, productTypeId: itemId });
  //   this.props.homeActions.onGetProducts(itemId).then(data => {
  //     this.props.homeActions.getAllDealers(itemId);
  //     this.setState({ isLoading: false });
  //   });
  //   setDeviceButton({
  //     deviceButton: false,
  //     deviceImage: true,
  //   });
  // };

  const onSelectProduct = (item) => {
    setSelectedProduct(item);
    setIsShowSerialNo(item.isSerialized === 0 ? false : true);
    setSerialNumber("");
  };
  const checkSelectedProd = (pId) => {
    // const { selectedProduct } = this.state;
    if (selectedProduct !== "" && selectedProduct?.productId === pId) {
      return true;
    } else {
      return false;
    }
  };

  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };
  const handleSearchQueryChangeProduct = (event) => {
    const query = event.target.value;
    setSearchQueryProduct(query);
  };
  const supportedFormat = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    if (!supportedFormat?.includes(file?.type)) {
      toast.error("Please upload only supported formats");
      return;
    }
    if (e.target.files.length) {
      setIsLoading(true);
      dispatch(dashboardActions.onUploadReceiptImage(e.target.files[0])).then(
        (data) => {
          setIsLoading(false);
          if (data && data.data && data.data.success) {
            setReceipt(data.data.data.url);
            toBase64(e.target.files[0]).then((data) => {
              const result = data.split("base64,")[1];
              setDisplayReceipt(result);
              setReceiptStatus(1);
            });
            return;
          } else {
            setReceipt("");
            setDisplayReceipt("");
            setReceiptStatus(0);
          }
        }
      );
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const changeHandler = (event) => {
    // setLoading(true);
    dispatch(
      deviceOverviewAction.onGetDelears(
        event.target.value,
        productCategory.productCategoryId
      )
    ).then((res) => {
      console.log("res", res?.data || []);
      // this.setState({ allDelears: res?.data || [] });
      setAllDealers(res?.data || []);
      // setLoading(false);
    });
  };

  const isDublicateSerial = (e) => {
    if (e.target.value !== "" && isShowSerialNo) {
      // setIsLoading(true);
      dispatch(dashboardActions.onDublicateSerial(e.target.value)).then(
        (data) => {
          if (data && data.status === 200 && data.data && data.data.success) {
            setDublicateSerial(false);
          }
          setIsLoading(false);
        }
      );
    } else {
      setDublicateSerial(false);
    }
  };

  const filtered = productType?.filter((item) =>
    item?.categoryName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const filteredAllProduct = allProducts?.filter((item) => {
    return (
      item.productName
        ?.toLowerCase()
        .includes(searchQueryProduct?.toLowerCase()) ||
      item.sku?.toLowerCase().includes(searchQueryProduct?.toLowerCase())
    );
  });

  const onDeviceSelection = () => {
    if (selectedProduct !== "" && selectedProduct?.productId) {
      onClick();
    } else {
      toast.error("Please select any product!");
    }
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      const truncatedText = text.slice(0, maxLength);
      return `${truncatedText}...`;
    }
    return text;
  };
  const today = new Date();
  const handleRemove = () => {
    // setReceipt("");
    setViewReceipt(true);
  };

  return (
    <>
      <div className="userRegistration__tabs addDevice">
        {stepNumber === 1 ? (
          <>
            {isAddDeviceFlow ? null : (
              <h2
                className="productRegisterHeading"
                style={{ marginBottom: 24, marginTop: 12 }}
              >
                Product Registration
              </h2>
            )}
            <h4
              style={{
                fontSize: 22,
                fontWeight: 600,
                fontFamily: "Poppins",
                color: "#181C32",
              }}
            >
              Find Your Product
            </h4>
            <p className="environmentDesc">
              Let’s add a device to your Aquatic AV account. Select the Product
              Category below that best represents the device you want to add.
            </p>

            {!deviceImage ? (
              <div className="d-flex align-items-center product-search">
                <img src={searchIcon} width={20} height={20} alt="" />
                <input
                  placeholder="Search Category"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                ></input>
              </div>
            ) : (
              <div style={{ marginTop: 30 }}>
                <h4
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    color: "#202A4C",
                  }}
                >
                  {selectedProduct?.categoryName
                    ? selectedProduct?.categoryName
                    : "Popular Products"}
                </h4>
                <div className="d-flex align-items-center product-search mb-2">
                  <img src={searchIcon} width={20} height={20} alt="" />
                  <input
                    placeholder="Search Product by Name or SKU"
                    value={searchQueryProduct}
                    onChange={handleSearchQueryChangeProduct}
                  ></input>
                </div>
                <div
                  onClick={() => {
                    setDeviceImage(false);
                    setDeviceButton(true);
                    setSearchQueryProduct("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={ChevronBack}
                    width={15}
                    height={15}
                    style={{ objectFit: "contain" }}
                    alt=""
                  />
                  <span
                    style={{
                      marginLeft: 12,
                      fontSize: 14,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      color: "#202A4C",
                    }}
                  >
                    Back to Categories
                  </span>
                </div>
              </div>
            )}

            <div className="deviceInner">
              {selectedProduct === "" && deviceError ? (
                <span style={{ color: "red" }}>
                  {"Please select device first"}
                </span>
              ) : null}
              {deviceButton && productType && productType.length > 0 && (
                <div className="deviceButtons">
                  {filtered.map((items, index) => (
                    <AddDeviceBox
                      key={index}
                      text={items.categoryName}
                      picture={items.featuredImage}
                      deviceClick={() => {
                        onDeviceButtonClick(items.productCategoryId);
                        // Update the state directly using hooks setter
                        setSelectedProduct(items);
                        setProductCategory(items);
                      }}
                    />
                  ))}
                </div>
              )}

              {deviceImage && (
                <span className="productsPictures">
                  {allProducts && allProducts?.length > 0 ? (
                    <>
                      {allProducts &&
                        allProducts?.length > 0 &&
                        filteredAllProduct?.map((items, index) => {
                          if (!step2) {
                            return (
                              <i
                                className={
                                  checkSelectedProd(items?.productId)
                                    ? "selectedProductBorder"
                                    : ""
                                }
                                key={index}
                                onClick={() => onSelectProduct(items)}
                                style={{ marginLeft: 20 }}
                              >
                                <img
                                  src={items?.featureImage}
                                  alt=""
                                  style={{
                                    borderRadius: 75,
                                    height: 130,
                                    width: 130,
                                    objectFit: "cover",
                                  }}
                                />
                                <div style={{ marginTop: 0 }}>
                                  {/* <span
                                    style={{
                                      wordBreak: "break-all",
                                      fontSize: 18,
                                      fontFamily: "Roboto",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      color: "#000000",
                                    }}
                                    className="mobileProductName"
                                  >
                                    {truncateText(items.productName, 15)}
                                  </span> */}
                                  <span
                                    style={{
                                      wordBreak: "break-all",
                                      fontSize: 18,
                                      fontFamily: "Roboto",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      color: "#000000",
                                    }}
                                    className="desktopProductName"
                                  >
                                    {items.productName}
                                  </span>

                                  <span
                                    style={{
                                      color: "#828282",
                                      fontFamily: "Roboto",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                    }}
                                  >
                                    {items?.sku}
                                  </span>
                                </div>

                                {/* {checkSelectedProd(items?.productId) && (
                              <span className="selectedProduct">
                                <RiCheckFill />
                              </span>
                            )} */}
                              </i>
                            );
                          } else if (
                            checkSelectedProd(items?.productId) &&
                            step2
                          ) {
                            return (
                              <i
                                key={index}
                                onClick={() => onSelectProduct(items)}
                              >
                                <img src={items?.featureImage} alt="" />
                                <div>
                                  <span>{items?.productName}</span>
                                  <span
                                    style={{
                                      color: "#828282",
                                    }}
                                  >
                                    {items?.productId}
                                  </span>
                                </div>

                                {checkSelectedProd(items?.productId) && (
                                  <span className="selectedProduct">
                                    <RiCheckFill />
                                  </span>
                                )}
                              </i>
                            );
                          }
                        })}
                    </>
                  ) : (
                    <span>Products are not available</span>
                  )}

                  {/* <span className="backBtn" onClick={() => this.onBackArroClick()}>
                <i>
                  <img src={backArrow} alt="" />
                  Back to Devices
                </i>
              </span> */}
                </span>
              )}
            </div>
            <div className="align-items-center justify-content-center mt-4 productfeet">
              <p
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <b
                  style={{
                    marginTop: 30,
                    fontWeight: 700,
                    color: "#181C32",
                    fontSize: 14,
                    fontFamily: "Poppins",
                  }}
                >
                  Can’t find your device?
                </b>{" "}
                <span
                  style={{
                    marginTop: 30,
                    marginLeft: 5,
                    fontWeight: 400,
                    fontSize: 14,
                    fontFamily: "Poppins",
                    color: "#181C32",
                  }}
                >
                  <a
                    href="https://www.aquaticav.com/pages/contact"
                    target="_blank"
                    style={{ color: "#181C32" }}
                  >
                    Reach out to Aquatic AV Support to continue.
                  </a>
                </span>
              </p>
            </div>

            <div className="footerBtnWrap ">
              {deviceImage ? (
                <button
                  id="pane2"
                  className="nextNavButton"
                  onClick={onDeviceSelection}
                >
                  {isAddDeviceFlow ? "NEXT" : "NEXT STEP"}
                </button>
              ) : null}
            </div>
          </>
        ) : null}

        {/* <div className="gap"></div> 
        <div className="gap"></div> */}

        {stepNumber === 2 ? (
          <>
            <div style={{ marginBottom: 70 }}>
              {isAddDeviceFlow ? null : (
                <h2
                  className="productRegisterHeading"
                  style={{
                    marginBottom: 24,
                    marginTop: 12,
                  }}
                >
                  Product Registration
                </h2>
              )}
              <h4
                style={{
                  fontSize: 22,
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  color: "#181C32",
                }}
              >
                Add Your Device Details
              </h4>
              <p className="environmentDesc">
                Registering your device gives you access to more in-app
                features, device customization & updates for certain Aquatic AV
                products, priority customer support, and an extended warranty.
              </p>
            </div>

            <Grid container>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  display: "flex",
                  paddingRight: 10,
                }}
                className="productMobImg"
              >
                <i
                  onClick={() => {}}
                  style={{
                    marginLeft: 20,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 85,
                      height: 170,
                      width: 170,
                      border: "2px solid #1BBED0",
                    }}
                  >
                    <img
                      src={selectedProduct?.featureImage}
                      alt=""
                      style={{
                        borderRadius: 65,
                        height: 130,
                        width: 130,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <span
                      style={{
                        // wordBreak: "break-all",
                        fontSize: 18,
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontStyle: "normal",
                        color: "#000000",
                      }}
                    >
                      {selectedProduct?.productName}
                    </span>
                    <span
                      style={{
                        color: "#828282",
                        fontFamily: "Roboto",
                        fontSize: 14,
                        fontWeight: 400,
                        fontStyle: "normal",
                      }}
                    >
                      ({selectedProduct?.productId})
                    </span>
                  </div>
                </i>
              </Grid>

              <Grid item xs={12} md={9}>
                <h1 className="deviceDetailsHeadings">Name Device</h1>
                <div style={{ marginTop: 10 }}>
                  <p className="deviceDetailsText">
                    Choose a name that fits you and your environment.
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </p>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    className="inputProductColmn"
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "40%",
                      }}
                      className="inputWidthProduct"
                    >
                      <label className="purchaseRecWrapper">
                        Device Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <FloatingLabelInput
                        value={nameDevice}
                        className={
                          nameDevice === "" && deviceError
                            ? "errorOutline"
                            : "floatingInputStyle"
                        }
                        onChange={(e) => setNameDevice(e.target.value)}
                        // onBlur={() => this.activeStep3()}
                        maxLength={30}
                      />
                    </span>

                    <span
                      style={{ marginLeft: 40 }}
                      className="inputWidthProduct"
                    >
                      <FloatingLabelInput
                        placeholder="Serial Number"
                        // mandatory={
                        //   selectedProduct.isSerialized === 1 ? true : false
                        // }
                        value={serialNumber}
                        // className={
                        //   (isShowSerialNo &&
                        //     deviceError &&
                        //     serialNumber === "") ||
                        //   dublicateSerial
                        //     ? "errorOutline"
                        //     : "floatingInputStyle"
                        // }
                        className="floatingInputStyle"
                        onChange={(e) => setSerialNumber(e.target.value)}
                        onBlur={(e) => isDublicateSerial(e)}
                        style={{
                          fontSize: "20px",
                          color: "red !important",
                        }}
                      />
                      {/* {isShowSerialNo && dublicateSerial ? (
                        <span style={{ color: "red" }}>
                          {"Please add serial number"}
                        </span>
                      ) : null} */}
                    </span>
                  </span>
                </div>

                <>
                  <h1
                    className="deviceDetailsHeadings"
                    style={{ marginTop: 40 }}
                  >
                    Purchase Details
                  </h1>
                  <div style={{ marginTop: 10 }}>
                    <p className="deviceDetailsText">
                      Enter the details of your device purchase.
                    </p>

                    <span>
                      <label className="purchaseRecWrapper">
                        Purchase Location
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <AutoCompleteLoc
                        label="Purchase Location"
                        value={purchaseLoc}
                        isReg
                        onChange={(e) => {
                          changeHandler(e);
                          setPurchaseLoc(e.target.value);
                        }}
                        className={
                          purchaseLoc === "" && purchaseError
                            ? "loctionStyleError errorOutline"
                            : isAddDeviceFlow
                            ? "loctionStyleDeviceFlow"
                            : "loctionStyle"
                        }
                        eventLocation={allDelears}
                        setEvent={(v) => {
                          setPurchaseLoc(v.value);
                          setDealerId(v);
                        }}
                      />
                    </span>

                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        flexDirection: "row",
                      }}
                      className="locmobile"
                    >
                      <span
                        className="loginWrapper__loginFormWrapper--inner  groupFields inputWidthProduct"
                        style={{ width: "41%" }}
                      >
                        <label className="purchaseRecWrapper">
                          Purchase Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="my-component">
                          <SingleDatePicker
                            date={purchaseDate !== "" ? purchaseDate : null}
                            onDateChange={(purchaseDate) => {
                              if (purchaseDate <= today) {
                                setPurchaseDate(purchaseDate);
                              } else {
                                setPurchaseDate(null);
                              }
                            }}
                            focused={focused}
                            onFocusChange={({ focused }) => setFocused(focused)}
                            id={"1"}
                            numberOfMonths={1}
                            displayFormat="YYYY/MM/DD"
                            placeholder={"YYYY/MM/DD"}
                            isOutsideRange={() => false}
                            hideKeyboardShortcutsPanel={true}
                          />
                        </div>
                        {deviceError && purchaseDate === "" ? (
                          <span style={{ color: "red" }}>
                            {"Please select Date"}
                          </span>
                        ) : null}
                      </span>

                      <span
                        className="loginWrapper__loginFormWrapper--inner  groupFields"
                        style={{ width: "41%" }}
                      >
                        <label className="purchaseRecWrapper">
                          Installation Date
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <div className="my-component">
                          <SingleDatePicker
                            date={
                              installationDate !== "" ? installationDate : null
                            }
                            onDateChange={(installationDate) => {
                              // discuss with awais bhai and zain and basit bhai
                              // && installationDate <= today
                              if (installationDate >= purchaseDate) {
                                setInstallationDate(installationDate);
                              } else {
                                setInstallationDate("");
                              }
                            }}
                            focused={installationDateFocused}
                            onFocusChange={({ focused }) =>
                              setInstallationDateFocused(focused)
                            }
                            id={"1"}
                            numberOfMonths={1}
                            displayFormat="YYYY/MM/DD"
                            placeholder={"YYYY/MM/DD"}
                            isOutsideRange={() => false}
                            hideKeyboardShortcutsPanel={true}
                          />
                        </div>
                        {deviceError && purchaseDate === "" ? (
                          <span style={{ color: "red" }}>
                            {"Please select Date"}
                          </span>
                        ) : null}
                      </span>
                    </div>

                    <span className="loginWrapper__loginFormWrapper--inner">
                      {/* <FileUpload /> */}
                      <h3
                        style={{
                          fontWeight: "500",
                          color: "red",
                          fontSize: "12px",
                          marginTop: "8px",
                        }}
                      >
                        Please ensure the Price, Model Number, Seller Name, and
                        Date of Purchase fields are properly filled on the
                        receipt.*
                      </h3>
                      <h3
                        style={{
                          fontWeight: "500",
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}
                      >
                        Receipt supported only. (JPEG, JPG, PDF and PNG)*
                      </h3>
                      <div
                        className="upload-btn-wrapper"
                        style={{
                          backgroundColor: "#1BBED0",
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                      >
                        <button className="btn">
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              fontFamily: "Roboto",
                              color: "#FFFFFF",
                            }}
                          >
                            UPLOAD RECEIPT
                          </span>
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          accept={supportedFormat.join(",")}
                          onChange={(e) => handleChangeImage(e)}
                        />
                      </div>
                    </span>
                    {!isPDFUrl(receipt) && receipt !== "" ? (
                      <div className="uploadYhumbnail">
                        <span>
                          {
                            <img
                              src={`${"data:image/png;base64,"}${displayReceipt}`}
                              alt=""
                            />
                          }
                        </span>
                      </div>
                    ) : (
                      receipt !== "" && (
                        <div
                          className="attechmentPdfContainer"
                          onClick={() => handleRemove()}
                        >
                          <span>Attachment.pdf </span>
                          {/* <i onClick={() => setViewReceipt("")}>X</i>  */}
                        </div>
                      )
                    )}

                    <Modal
                      title="Model"
                      open={viewReceipt}
                      onClose={() => setViewReceipt(!viewReceipt)}
                    >
                      <Box style={style}>
                        <div className="registerDevicePopup">
                          <div
                            style={{
                              overflowX: "scroll",
                              overflowY: "scroll",
                              bottom: "0px",
                            }}
                          >
                            <Document file={receipt}>
                              <Page pageNumber={1} />
                            </Document>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </>
              </Grid>

              <div className="footerBtnWrap">
                <button
                  id="pane2"
                  className="nextNavButton"
                  onClick={() => checkAddDevice()}
                >
                  {isAddDeviceFlow ? "NEXT" : "NEXT STEP"}
                </button>
              </div>
            </Grid>
          </>
        ) : null}

        {isLoading ? <Loader /> : null}
      </div>
    </>
  );
};

export { AddProduct };
