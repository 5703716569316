import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

const GenericCardComponent = ({
  icon,
  title,
  subheadings = [],
  backgroundColor,
  hasBorder,
  hasMargin,
  iconStyle,
  isCentered,
  width,
  height,
  button,
  hasRight,
  onClick,
  cardtitle
}) => {
  const cardStyles = {
    backgroundColor: backgroundColor,
    border: hasBorder ? "1px solid #1BBED0" : undefined,
    marginRight: hasMargin ? "30px" : undefined,
    width: width,
    height: height,
    display: "flex",
    flexDirection: "column",
    justifyContent: subheadings.length
      ? "flex-start"
      : "center", // Adjust alignment
    alignItems: "center",
    marginTop: "10px",
    marginLeft: hasRight ? "20px" : undefined,
    fontFamily: "Poppins",
    marginBottom: "20px",
  };
  const iconClass = isCentered
    ? "center-icon"
    : "normal-icon";

  return (
    <div
      className="GenericCard"
      style={cardStyles}
      onClick={onClick}
    >
      {icon && (
        <div className={iconClass}>
          <img
            src={icon}
            alt="icon"
            className="Generic_cardIcon"
            style={iconStyle}
          />
        </div>
      )}
      {title && (
        <Tooltip title={cardtitle}>
          <h2>{title}</h2>
        </Tooltip>
      )}
      {subheadings[0] && (
        <div style={subheadings[0]?.style} className="displaynone">
          {subheadings[0]?.icon && (
            <img
              src={subheadings[0]?.icon}
              alt="subheading icon"
              className="subheadingIcon"
            />
          )}
          <h3 className="displaynone">{subheadings[0]?.text}</h3>
        </div>
      )}
      <div
        style={{
          border: subheadings[1]?.hasBorder
            ? "1px dashed #E4E6EF"
            : undefined,
          padding: "5px 10px",
          borderRadius: "12px",
        }}
      >
        {[subheadings[1], subheadings[2]].map(
          (subheading, index) =>
            subheading ? (
              <div key={index} style={subheading?.style}>
                {subheading?.icon && (
                  <img
                    src={subheading?.icon}
                    alt="subheading icon"
                    className="subheadingIcon"
                  />
                )}
                <h5 className="NoDevices">
                  {subheading?.text}
                </h5>
              </div>
            ) : null
        )}
      </div>

      {button && <button>{button?.text}</button>}
    </div>
  );
};

export default GenericCardComponent;
