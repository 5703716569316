import React, { useState } from "react";
import {
  FloatingLabelInput,
  Loader,
} from "../../../components/common";
import Autocomplete from "react-google-autocomplete";
import { verifyEmailCode } from "../../../resources/images";

const VerifyEmailForm = ({
  onClick,
  onSkipClick,
  onLoading,
  onClickNext,
}) => {
  return (
    <>
      <div className="userRegistration__tabs">
        <h2
          style={{
            marginBottom: 24,
            marginTop: 17,
            fontWeight: 600,
            fontSize: "30px",
            color: "#202A4C",
            fontFamily: "poppins",
          }}
        >
          Verify Email
        </h2>
        <h4
          style={{
            fontSize: "22px",
            fontWeight: 600,
            color: "#181C32",
            fontFamily: "poppins",
          }}
        >
          Check Your E-mail For A Verification Link
        </h4>
        <p
          className="environmentDesc"
          style={{
            fontSize: "16px",
            fontWeight: 400,
            color: "#000000",
            fontFamily: "poppins",
            marginBottom: "60px",
            // fontStyle:"poppins"
          }}
        >
 We just sent you an e-mail with a verification link so we can verify your e-mail address is correct. Your link will expire within 15 minutes so make sure to hurry. If you don’t get an e-mail soon, check your Junk/Spam folders first or click the button below to resend the verification link.
        </p>

        <div className="verificationEmailWrapper">
          <div>
            <img
              src={verifyEmailCode}
              alt="verifycation email icon"
            />
          </div>
          <div>
            <button
              className="resendBtn"
              onClick={() => onClick()}
              style={{ width: "96px", height: "42px" }}
            >
              RESEND
            </button>
          </div>
        </div>

        <div className="footerBtnWrap ">
          <button
            id="pane2"
            className="skipBtn"
            onClick={() => onSkipClick()}
          >
            Skip for Now
          </button>
          {onLoading ? (
            <Loader />
          ) : (
            <button
              id="pane2"
              className="nextNavButton"
              onClick={() => onClickNext()}
            >
              NEXT STEP
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export { VerifyEmailForm };
