import { accountTypes } from "./accountTypes";
import { accountApi } from "./accountApi";

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: accountTypes.ACCOUNT_LOGIN_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onSignInPress = (email, password) => async (dispatch) => {
  let response = await accountApi.login(email, password);

  return response;
};

const onForgotPassword = (email) => async (dispatch) => {
  localStorage.setItem("userEmail", email);
  let response = await accountApi.forgot(email);
  if (response.status === 200 && response.data.success) {
    const codeIs = response.data.data.code;
    const token = response.data.data.token;
    dispatch({
      type: accountTypes.ACCOUNT_LOGIN_ONCHANGE,
      payload: { prop: "forgotCode", value: codeIs },
    });
    dispatch({
      type: accountTypes.ACCOUNT_LOGIN_ONCHANGE,
      payload: { prop: "forgotToken", value: token },
    });
    localStorage.setItem("forgotCode", codeIs);
    localStorage.setItem("forgotToken", token);
  }

  return response;
};

const onVerificationCode = (code) => async (dispatch) => {
  let response = await accountApi.verificationCode(code);
  return response;
};

const onResetPassword = (reqObj) => async (dispatch) => {
  let response = await accountApi.resetPassword(reqObj);
  return response;
};

const onRegisterUser = (data) => async (dispatch) => {
  let response = await accountApi.onRegisterUser(data);
  return response;
};

const onVerifyUserEmail = (uEmail) => async (dispatch) => {
  let response = await accountApi.verifyUserEmail(uEmail);
  return response;
};

const onUpdateUserNotifications = (req) => async (dispatch) => {
  let response = await accountApi.onUpdateUserNotifications(req);
  return response;
};

const onLoginWithToken = (token) => async (dispatch) => {
  let response = await accountApi.loginWithToken(token);

  return response;
};

const onUserEmailVerification = (id) => async (dispatch) => {
  let response = await accountApi.onUserEmailVerification(id);
  return response;
};

const onUserSMSVerification = (id) => async (dispatch) => {
  let response = await accountApi.onUserSMSVerification(id);
  return response;
};

const onGetUserEmailVerification = (data) => async (dispatch) => {
  let response = await accountApi.onGetUserEmailVerification(data);
  return response;
};

const onGetUserSMSVerification = (data) => async (dispatch) => {
  let response = await accountApi.onGetUserSMSVerification(data);
  return response;
};
const onNotificationSubmission = (data) => async (dispatch) => {
  let response = await accountApi.onNotificationSubmission(data);
  return response;
};


export const accountActions = {
  onChange: onChange,
  onSignInPress: onSignInPress,
  onForgotPassword: onForgotPassword,
  onVerificationCode: onVerificationCode,
  onResetPassword: onResetPassword,
  onRegisterUser: onRegisterUser,
  onVerifyUserEmail: onVerifyUserEmail,
  onUpdateUserNotifications: onUpdateUserNotifications,
  onLoginWithToken: onLoginWithToken,
  onUserEmailVerification: onUserEmailVerification,
  onUserSMSVerification: onUserSMSVerification,
  onGetUserEmailVerification: onGetUserEmailVerification,
  onGetUserSMSVerification: onGetUserSMSVerification,
  onNotificationSubmission:onNotificationSubmission,
};
