import React, { useState } from "react";
import { AppModal } from "../../../components/common/index";

function DevicePopup({ closeModal, deviceData }) {
  return (
    <div className="sessionTimeoutWrapper">
      <AppModal title="Modal" handleclose={closeModal}>
        <div className="sessionInner">
          <span className="closeUpInner">
            <img
              src={`${deviceData.receipt_path}${"/"}${deviceData.receipt}`}
              alt=""
            />
          </span>
        </div>
      </AppModal>
    </div>
  );
}

export default DevicePopup;
