import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import fotgotPassword from "../../resources/images/fotgotPassword.png";
import logo from "../../resources/images/logo.png";
import thankyou from "../../resources/images/thankyou.png";
import notverified from "../../resources/images/notverified.webp";
import {
  FloatingLabelInput,
  AppBtn,
  Loader,
} from "../../components/common/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "../../store/accounts/accountActions";
import { RoutesObj } from "../../routes";
import { toast } from "react-toastify";

class EmailVerification extends Component {
  constructor() {
    super();

    this.state = {
      isValid: false,
      forgotEmail: "",
      isLoading: false,
      isVerified: null,
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this?.props?.location.search);
    const param1 = queryParams?.get("id");
    if (param1) {
      this.onVerifyEmail(param1);
    }
  }

  onVerifyEmail = (id) => {
    console.log("query params:", id);
    this.setState({ isLoading: true });

    let param = {
      id,
    };

    this.props.actions.onGetUserEmailVerification(param).then((data) => {
      this.setState({ isLoading: false });
      if (data.status === 200) {
        this.setState({ isVerified: true });
      } else {
        this.setState({ isVerified: false });
      }
    });
  };

  onFocusOutFun = (value) => {
    if (
      value !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      this.setState({ isValid: true });
    }
  };

  render() {
    const { forgotEmail } = this.props.state;
    return (
      <div className="main-container">
        {this.state.isLoading && <Loader />}
        <div className="main-container__column-center">
          <img src={logo} alt="Logo" />
          <div className="loginWrapper__loginFormWrapper">
            <div className="loginTitle">
              <img
                className="verification_image_style"
                src={this.state.isVerified ? thankyou : notverified}
                alt="Logo"
              />
              {this.state.isVerified !== null ? (
                this.state.isVerified ? (
                  <h2>Email has been verified successfully!</h2>
                ) : (
                  <h2>Sorry! We couldn't verify your email.</h2>
                )
              ) : null}
            </div>
            <div className="gap"></div>

            {/* <span className="loginBtnWrapper">
              <AppBtn
                text="Continue"
                onClick={() =>
                  forgotEmail !== "" && !this.state.isValid
                    ? this.forgotPasswordPress(forgotEmail)
                    : this.setState({ isValid: true })
                }
              />
            </span> */}
            {/* <span className="loginWrapper__loginFormWrapper--inner">
              <div className="login_description">Need help? Chat with us.</div>
            </span> */}
          </div>
        </div>
        {/* <div className="main-container__column-right"></div> */}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    state: state.AccountReducer,
  }),
  (dispatch) => ({
    actions: bindActionCreators(accountActions, dispatch),
  })
)(withRouter(EmailVerification));
