import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Header, Loader } from "../../components/common";
import "./style.css";
import { SearchIcon, add } from "../../assets/images/images";
import MessageInfo from "./components/MessageInfo";
import NewChat from "./components/NewChat";
import MblMessageInfo from "./MobileComponents/MblMessageInfo";
import MblNewChat from "./MobileComponents/MblNewChat";
import { useDispatch } from "react-redux";
import { conversationAction } from "../../store/conversations/conversationAction";
import * as signalR from "@microsoft/signalr";
import DOMPurify from "dompurify";
import { dashboardActions } from "../../store/dashboard/dashboardActions";

const UpdateMessages = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const connectionUrl = "https://aquaticavchatdev.azurewebsites.net/signalr";
  const connectionUrl = "https://aquaticavchat.azurewebsites.net/signalr";
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [chatFullScreen, setChatFullScreen] = useState(false);
  const [startChat, setStartChat] = useState(true);
  const [selectChat, setSelectChat] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedRoomId, setRoomId] = useState(0);
  const [isMblMessageList, setIsMblMessageList] = useState(true);
  const [isMblNewChat, setIsMblNewChat] = useState(false);
  const [connection, setConnection] = useState(null);
  const [totallCount, setTotalCount] = useState("");
  const [allDealors, setAllDealors] = useState([]);
  const [messageDetailsData, setMessageDetailsData] = useState([]);
  const [userRooms, setUserRooms] = useState([]);
  const [selectedChatBox, setSelectedChatBox] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNewRoom, setSelectedNewRoom] = useState(false);
  const [devices, setDevice] = useState([]);

  const roomId = useRef(0);
  const hubUserId = useRef(0);
  const messageDetail = useRef([]);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const options = {
    // transport: transport,
    logMessageContent: true,
    logger: signalR.LogLevel.Trace,
    // skipNegotiation: true,
  };

  const buildConnection = () => {
    setLoading(true);
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    let connect = new signalR.HubConnectionBuilder()
      .withUrl(
        connectionUrl +
          "?ClientId=" +
          userInfo?.id +
          "&ProductId=" +
          2 +
          // "&ClientName=" +
          // userInfo.name +
          // "" +
          "&generic=" +
          userInfo?.role,
        options
      )
      .withAutomaticReconnect()
      .withHubProtocol(protocol)
      .build();
    setConnection(connect);
    connect.on("send", (data) => {
      console.log(data);
    });

    connect
      .start()
      .then(() => {
        console.log("connection started");
      })
      .catch((err) => {
        console.log("error while establishing signalr connection: " + err);
      });
  };
  useEffect(() => {
    buildConnection();
  }, []);
  useEffect(() => {
    if (connection) {
      connection.off("messageRecieved");
      connection.on("onUserConnected", (data) => {
        if (data) {
          localStorage.setItem("hubUserId", data?.hubUserId);
          hubUserId.current = data?.hubUserId;
          loadChat(data.hubUserId);
        }
      });

      connection.on("messageRecieved", async (data) => {
        if (hubUserId.current > 0) {
          if (roomId.current && data?.roomId === roomId.current) {
            chatDetail(data?.roomId);
          } else {
            loadChat(hubUserId?.current);
          }
        }
      });
    }
  }, [connection]);

  // useEffect(() => {
  //   if (history?.state?.roomId) {
  //     chatDetail(history?.state?.roomId);
  //   }
  // }, []);

  const uploadAttachment = (messageContent, attachment) => {
    let formdata = new FormData();
    formdata.append("file", attachment);
    setLoading(true);
    dispatch(conversationAction.uploadFile(formdata)).then(({ data }) => {
      console.log("data,", data);
      setLoading(false);
      if (data.responseCode === 1) {
        let reqdata = {
          TimeZone: timeZone,
          RoomId: selectedRoomId,
          HubUserId: hubUserId.current,
          Content: messageContent,
          Attachments: [
            {
              Name: attachment.name,
              path: data.responseData.url,
            },
          ],
        };
        connection.send("SendMessage", reqdata).then((x) => {
          chatDetail(selectedRoomId);
          loadChat(hubUserId?.current);
          console.log("Message Sent Successfully");
        });
      }
    });
  };
  const uploadAttachmentWithNewRoom = (
    messageContent,
    selectedUser,
    attachment
  ) => {
    let formdata = new FormData();
    formdata.append("file", attachment);
    dispatch(conversationAction.uploadFile(formdata)).then(({ data }) => {
      if (data.responseCode === 1) {
        const newMsgContent = {
          content: messageContent,
          TimeZone: timeZone,
          roomName: "TrbAi Room Name",
          Attachments: [
            {
              Name: attachment.name,
              path: data.responseData.url,
            },
          ],
          messageFrom: {
            id: userInfo.id,
            name: `${userInfo.firstName}  ${userInfo.lastName}`,
            allowedOnCampaignsId: "1",
            generic: "",
          },
          messageTo: [
            {
              id: selectedUser?.id,
              name: selectedUser.name,
              allowedOnCampaignsId: "",
              generic: "",
            },
          ],
          productId: 2,
        };
        dispatch(conversationAction.onSendMessageViaApi(newMsgContent))
          .then((res) => {
            if (res.responseCode === 1) {
              setSelectedNewRoom(false);
              chatDetail(res?.responseData?.roomId);
              setRoomId(res?.responseData?.roomId);
            }
          })
          .catch((error) => {
            console.log(error, "e=>");
          });
      }
    });
  };

  const onSendMessageWithExistingRoom = (content, attachment) => {
    let messageContent = content;
    if (!messageContent.trim()) {
      return;
    }
    if (!attachment) {
      let msgdata = {
        RoomId: selectedRoomId,
        HubUserId: hubUserId.current,
        Content: messageContent,
        TimeZone: timeZone,
      };
      connection.send("SendMessage", msgdata).then((x) => {
        chatDetail(selectedRoomId);
        loadChat(hubUserId?.current);
        console.log("Message Sent Successfully");
      });
    } else {
      uploadAttachment(messageContent, attachment);
    }
  };
  const onSendNewMessage = async (content, selectedUser, attachment) => {
    let messageContent = content;
    // const sanitizedHtml = DOMPurify.sanitize(messageContent);
    // dangerouslySetInnerHTML={{ __html: sanitizedHtml }}

    if (!messageContent.trim()) {
      return;
    }
    if (selectedNewRoom) {
      if (!attachment) {
        const newMsgContent = {
          content: messageContent,
          TimeZone: timeZone,
          roomName: `${userInfo.firstName}  ${userInfo.lastName}`,

          messageFrom: {
            id: userInfo.id,
            name: `${userInfo.firstName}  ${userInfo.lastName}`,
            allowedOnCampaignsId: "1",
            generic: "",
          },
          messageTo: [
            {
              id: selectedUser?.id,
              name: selectedUser?.name,
              allowedOnCampaignsId: "1",
              generic: "",
            },
          ],
          productId: 2,
          attachments: [
            {
              name: "",
              url: "",
            },
          ],
        };
        dispatch(conversationAction.onSendMessageViaApi(newMsgContent))
          .then((res) => {
            if (res.responseCode === 1) {
              setSelectedNewRoom(false);
              chatDetail(res?.responseData?.roomId);
              setRoomId(res?.responseData?.roomId);
              console.log("Message Sent Successfully");
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } else {
        uploadAttachmentWithNewRoom(messageContent, selectedUser, attachment);
      }
    } else {
      onSendMessageWithExistingRoom(content, attachment);
    }
  };

  const handleSelectChat = () => {
    setMessageDetailsData([]);
    setSelectChat(!selectChat);
    setStartChat(true);
    setChatFullScreen(false);
  };

  const handleMblChatScreen = () => {
    setIsMblMessageList(false);
    setIsMblNewChat(true);
  };

  const handleMessageListClick = (chat) => {
    setIsMblMessageList(false);
    setIsMblNewChat(false);
    setSelectChat(true);
    setRoomId(chat?.roomId);
    chatDetail(chat?.roomId);
    setSelectedChatBox(chat);
    roomId.current = chat?.roomId;
  };
  const loadChat = (hubId) => {
    dispatch(
      conversationAction.onGetUserRoomsByUsersId({
        roomName: "",
        hubUserId: hubId,
        // status: "1",
        productId: 2,
        isFavorite: false,
        campaignId: 1,
        pageNo: 0,
        take: 200,
        name: "",
      })
    )
      .then((res) => {
        setTotalCount(res?.responseData?.count);
        setUserRooms(res?.responseData?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };
  const fetchAllChatUsers = () => {
    let userId = localStorage.getItem("userId");

    setSelectChat(false);
    setStartChat(false);
    setSelectedNewRoom(true);
    setSelectedChatBox("");
    setLoading(true);
    setRoomId("");
    dispatch(
      conversationAction.onGetUserProductDealers({
        userId,
      })
    )
      .then((res) => {
        setAllDealors(res?.data || []);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const selectedChatWithChatBox = (chat) => {
    setSelectedNewRoom(false);
    setSelectChat(true);
    setRoomId(chat?.roomId);
    chatDetail(chat?.roomId);
    setSelectedChatBox(chat);
    roomId.current = chat?.roomId;
  };
  const chatDetail = (roomId) => {
    dispatch(
      conversationAction.onGetChatDetailsByRoomId({
        roomId: roomId,
        hubUserId: hubUserId.current,
        productId: 2,
        take: 200,
        skip: 0,
      })
    )
      .then((res) => {
        if (res.responseCode === 1) {
          messageDetail.current =
            res?.responseData?.length > 0 ? res.responseData : [];
          setMessageDetailsData(
            res?.responseData?.length > 0 ? res.responseData : []
          );
        }
      })
      .catch((error) => {
        console.log("There is some issue in chat Detail", error);
      });
  };

  const handleSearchMessage = () => {
    setLoading(true);
    dispatch(
      conversationAction.onGetUserRoomsByUsersId({
        roomName: "",
        hubUserId: hubUserId.current,
        productId: 2,
        isFavorite: false,
        campaignId: 1,
        pageNo: 0,
        take: 200,
        name: searchTerm !== "" ? searchTerm : searchTerm,
      })
    )
      .then((res) => {
        setTotalCount(res?.responseData?.count);
        setUserRooms(res?.responseData.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchMessage();
    }
  };
  const decideIsSPA = () => {
    let isSPA = false;
    let SPAIId = null;
    devices?.forEach((value, index) => {
      if (
        value.productType === "SPABasic" ||
        value.productType === "SPAAdvance" ||
        value.productType === "SPAMid"
      ) {
        isSPA = true;
        SPAIId = value.id;
      }
    });

    return { isSPA, SPAIId };
  };
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    dispatch(dashboardActions.onGetUserDevices(userId))
      .then((res) => {
        setDevice(res.data.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);
  const toggleSubNav = () => {
    // this.setState((prevState) => ({ showSubMenu: !prevState.showSubMenu }));
  };
  const removeSubNav = () => {
    // this.setState({ showSubMenu: false });
  };

  return (
    <div className="Dashboardmain-container">
      {isLoading ? <Loader /> : null}
      <div className="Dashboardmain-container__header">
        <Header
          userInformation={userInfo ? userInfo : null}
          isDashboardShow={true}
          history={history}
          isSPA={decideIsSPA().isSPA} // sending this to show the spa dropdwon if there is any SPA in the environemnt
          SPAIId={decideIsSPA().SPAIId}
          toggleSubNav={toggleSubNav}
          removeSubNav={removeSubNav}
        />
      </div>

      <div className="dashboardMainSection message-screen">
        <h1 className="envrionmentsMainHeading">Messages</h1>
        <h1 className="breadcrumbs">
          Home / <span style={{ color: "#3F4254" }}> Inbox</span>
        </h1>
        <div className="message-box">
          {!chatFullScreen ? (
            <div className="message-list">
              <div className="heading">
                <div className="heading-text">
                  <h3>All Messages</h3>
                  <span>{totallCount}</span>
                </div>
                <div
                  className="add-chat"
                  onClick={() => {
                    setStartChat(false);
                    fetchAllChatUsers();
                  }}
                >
                  <img src={add} alt="add" />
                </div>
              </div>
              <div className="message-search">
                <img
                  src={SearchIcon}
                  alt="searchIcon"
                  onClick={handleSearchMessage}
                />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <MessageInfo
                handleSelectedChat={selectedChatWithChatBox}
                userRooms={userRooms}
                isLoading={isLoading}
              />
            </div>
          ) : (
            ""
          )}
          <div
            className="message-detail"
            style={{ width: chatFullScreen ? "100%" : "" }}
          >
            <NewChat
              isLoading={isLoading}
              selectChat={selectChat}
              hubUserIdProp={hubUserId}
              selectedChatBox={selectedChatBox}
              messageDetailsData={messageDetailsData}
              selectedChat={handleSelectChat}
              setSelectChat={setSelectChat}
              startChat={startChat}
              setStartChat={setStartChat}
              chatFullScreen={chatFullScreen}
              setChatFullScreen={setChatFullScreen}
              allDealors={allDealors}
              onSendNewMessage={onSendNewMessage}
              fetchAllChatUsers={fetchAllChatUsers}
              chatDetail={chatDetail}
              setMessageDetailsData={setMessageDetailsData}
            />
          </div>
        </div>
        <div className="mbl-message-box">
          {isMblMessageList ? (
            <div className="message-list">
              <div className="heading">
                <div className="heading-text">
                  <h3>All Messages</h3>
                  <span>{totallCount}</span>
                </div>
                <div
                  className="add-chat"
                  onClick={() => {
                    handleMblChatScreen();
                    fetchAllChatUsers();
                  }}
                >
                  <img src={add} alt="add" />
                </div>
              </div>
              <div className="message-search">
                <img
                  src={SearchIcon}
                  alt="searchIcon"
                  onClick={handleSearchMessage}
                />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <MblMessageInfo
                handleMessageListClick={handleMessageListClick}
                userRooms={userRooms}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <div className="message-detail">
              <MblNewChat
                isMblNewChat={isMblNewChat}
                setIsMblNewChat={setIsMblNewChat}
                setIsMblMessageList={setIsMblMessageList}
                isLoading={isLoading}
                selectChat={selectChat}
                hubUserIdProp={hubUserId}
                selectedChatBox={selectedChatBox}
                messageDetailsData={messageDetailsData}
                selectedChat={handleSelectChat}
                setSelectChat={setSelectChat}
                startChat={startChat}
                setStartChat={setStartChat}
                chatFullScreen={chatFullScreen}
                setChatFullScreen={setChatFullScreen}
                allDealors={allDealors}
                onSendNewMessage={onSendNewMessage}
                fetchAllChatUsers={fetchAllChatUsers}
                chatDetail={chatDetail}
                setSelectedNewRoom={setSelectedNewRoom}
                setMessageDetailsData={setMessageDetailsData}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateMessages;
