import { dealerLocatorTypes } from "./dealerLocatorTypes";
import { dealerLocatorApi } from "./dealerLocatorApi";

const onChange = (field, value) => async (dispatch) => {
  dispatch({
    type: dealerLocatorTypes.DEALERLOCATOR_ONCHANGE,
    payload: { prop: field, value: value },
  });
};

const getDealersSuggestion =
  (search, sourceLat, sourceLong, dealerType, environments, distance) =>
    async (dispatch) => {
      let response = await dealerLocatorApi.getDealersSuggestion(
        search,
        sourceLat,
        sourceLong,
        dealerType,
        environments,
        distance
      );
      if (response && response.data && response.data.success) {
        dispatch({
          type: dealerLocatorTypes.DEALERLOCATOR_DATA,
          payload: { value: response.data.data },
        });
      } else {
        dispatch({
          type: dealerLocatorTypes.DEALERLOCATOR_DATA,
          payload: { value: [] },
        });
      }

      return response;
    };
const getDealersFilter = () => async (dispatch) => {
  let response = await dealerLocatorApi.getDealersFilter();
  // if (response && response.data && response.data.success) {
  //   dispatch({
  //     type: dealerLocatorTypes.DEALERLOCATOR_FILTER,
  //     payload: {
  //       milesdata: response.data.data?.milesdata,
  //       dealertypedata: response.data.data?.dealertypedata,
  //       envdata: response.data.data?.envdata,
  //     },
  //   });
  // }

  return response.data;
};
const getAuthorizedDealers = () => async (dispatch) => {
  let response = await dealerLocatorApi.getAuthorizedDealers();
  return response;
}
const getDNBLDealers = () => async (dispatch) => {
  let response = await dealerLocatorApi.getDNBLDealers();
  return response;
}
const onAutoComplete = (text) => async (dispatch) => {
  let response = await dealerLocatorApi.getAutoCompleteSuggestion(text);
  return response;
};

export const dealerLocatorActions = {
  onChange: onChange,
  getDealersSuggestion,
  getDealersFilter,
  onAutoComplete: onAutoComplete,
  getAuthorizedDealers: getAuthorizedDealers,
  getDNBLDealers:getDNBLDealers,
};
