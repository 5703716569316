import { ProductsApi } from "./ProductsApi";
import { ProductsTypes } from "./ProductsTypes";

const onGetNewArrivalsProducts = () => async (dispatch) => {
  let response = await ProductsApi.getNewArrivalsProducts();
  return response;
};

const onGetPopularProducts = () => async (dispatch) => {
  let response = await ProductsApi.getPopularProducts();
  return response;
};

const GetProductsDetailById = (productId) => async (dispatch) => {
  let response = await ProductsApi.getProductsDetailById(productId);
  return response;
};

const GetAllNewsRoomContentByRoomId = () => async (dispatch) => {
  let response = await ProductsApi.getAllNewsRoomContentByRoomId();
  return response;
};

const GetProductCategory = () => async (dispatch) => {
  let response = await ProductsApi.GetProductCategory();
  return response;
};

const GetProductsByProductCategory = (categoryId) => async (dispatch) => {
  let response = await ProductsApi.GetProductsByProductCategory(categoryId);
  return response;
};

export const ProductsAction = {
  onGetNewArrivalsProducts,
  onGetPopularProducts,
  GetProductsDetailById,
  GetAllNewsRoomContentByRoomId,
  GetProductCategory,
  GetProductsByProductCategory,
};
