import React, { useEffect, useState, useRef } from "react";
import { Routes } from "./routes";
import SessionPopup from "./components/common/SessionPopup";
import IdleTimer from "react-idle-timer";
import axios from "axios";
import Cookies from "js-cookie";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UserRegistrationFooter from "./views/UserRegistration/UserRegistrationFooter";
import { UpdateConversation } from "./views";
let timeout;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated")
  );
  const [isSessionModal, setIsSessionModal] = useState(false);
  const [expiryMin, setExpiryMin] = useState(0);
  const idleTimer = useRef(null);
  const [authExpiry, setAuthExpiry] = useState(
    new Date(parseInt(localStorage.getItem("access_token_expiry")))
  );

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  // useEffect(() => {
  //   async function getLocation() {
  //     const res = await axios.get("https://geolocation-db.com/json/");
  //     console.log(res, "res=>");
  //     if (res && res.data) {
  //       localStorage.setItem("userIpAddress", res.data.IPv4);
  //       localStorage.setItem("locationLat", res.data.latitude);
  //       localStorage.setItem("locationLong", res.data.longitude);
  //       // localStorage.setItem("locationLat", "44.8793886");
  //       // localStorage.setItem("locationLong", "-91.4308606");
  //     }
  //   }
  //   getLocation();
  //   setIsAuthenticated(localStorage.getItem("isAuthenticated"));
  // }, []);

  useEffect(() => {
    setAuthExpiry(
      new Date(parseInt(localStorage.getItem("access_token_expiry")))
    );
  }, [isAuthenticated]);

  const onLogout = () => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("password");
    localStorage.removeItem("passwordDec");
    localStorage.removeItem("currentEnv");
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_token_expiry");
    localStorage.removeItem("userId");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("forgotToken");
    localStorage.removeItem("isAuthenticated");
    window.location = "/";
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!isSessionModal) {
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    const delay = 300000;
    setIsAuthenticated(localStorage.getItem("isAuthenticated"));
    if (isAuthenticated === "true" && authExpiry) {
      timeout = setTimeout(() => {
        let currentTime = new Date();
        let sessionTime = null;
        let numberOfMlSeconds = authExpiry.getTime();
        let subMlSeconds = 15 * 60 * 1000;
        sessionTime = new Date(numberOfMlSeconds - subMlSeconds);
        if (authExpiry.getTime() < currentTime.getTime()) {
          // onLogout();
        } else if (sessionTime.getTime() < currentTime.getTime()) {
          let leftMin = Math.ceil(
            Math.abs((authExpiry.getTime() - currentTime.getTime()) / 60000)
          );
          setExpiryMin(leftMin);
          setIsSessionModal(true);
        }
      }, delay);
    }
  };

  const closeModal = () => {
    setAuthExpiry(
      new Date(parseInt(localStorage.getItem("access_token_expiry")))
    );
    setIsSessionModal(false);
  };
  return (
    <>
      <Routes />
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={5000}
      />
      {isSessionModal && isAuthenticated === "true" && (
        <SessionPopup
          closeModal={() => closeModal()}
          expiryMin={expiryMin}
          logout={() => onLogout()}
          continueSession={() => closeModal()}
        />
      )}
    </>
  );
}

export default App;
