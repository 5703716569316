import { PriceListApi } from "./PriceListApi";
import { PriceListTypes } from "./PriceListTypes";

const onGetPriceList = (groupId) => async (dispatch) => {
  let response = await PriceListApi.getPriceList(groupId);
  if (response != null && response.success === true) {
    dispatch({
      type: PriceListTypes.GET_PRICE_LIST,
      payload: response.data,
    });
  }
  return response;
};

const onGetPriceListById = (priceListId) => async (dispatch) => {
  let response = await PriceListApi.getPriceListById(priceListId);
  return response;
};

const onDownloadCSV = (priceListId) => async (dispatch) => {
  let response = await PriceListApi.DownloadCSV(priceListId);
  return response;
};

const onDownloadPDF = (priceListId) => async (dispatch) => {
  try {
    let response = await PriceListApi.DownloadPDF(priceListId);
    return response;
  } catch (err) {
    throw err;
  }
};

export const PriceListAction = {
  onGetPriceList,
  onGetPriceListById,
  onDownloadCSV,
  onDownloadPDF,
};
