export const deviceOverviewTypes = {
  DEVICE_OVERVIEW_LOAD_DATA: "DEVICE_OVERVIEW_LOAD_DATA",
  FAV_COLORS: "FAV_COLORS",
  SET_COLOR: "SET_COLOR",
  DELETE_COLOR: "DELETE_COLOR",
  CLEAR_DELETE_COLOR: "CLEAR_DELETE_COLOR",
  DEVICEOVERVIEW_ONCHANGE: "DEVICEOVERVIEW_ONCHANGE",
  REMOVE_EXTRA_COLOR: "REMOVE_EXTRA_COLOR",
  ON_UPDATE_COLOR: "ON_UPDATE_COLOR",
  ON_ADD_NEW_COLOR: "ON_ADD_NEW_COLOR",
};
