import React, { useState } from "react";
import { FloatingLabelInput, Loader } from "../../../components/common";
import { toast } from "react-toastify";
import { accountActions } from "../../../store/accounts/accountActions";
import { useDispatch } from "react-redux";
const PersonalInfoForm = ({ onClick, onLoading }) => {
  const [personalInfo, setPersonalInfo] = useState({
    currentTabId: "pane1",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
    termsError: false,
    emailError: false,
    passError: false,
    confPassError: false,
    nameError: false,
    firstNameError: false,
    lastNameError: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onCheckPersonalInfo = () => {
    const errors = {
      firstNameError: personalInfo.firstName === "",
      lastNameError: personalInfo.lastName === "",
      confPassError: personalInfo.confirmPassword === "",
      emailError: personalInfo.email === "",
      passError: personalInfo.password === "",
      termsError: !personalInfo.terms,
    };

    // Check for empty fields and display toasts
    for (const field in errors) {
      if (errors[field]) {
        const fieldName = field.replace("Error", ""); // Remove "Error" from the field name
        console.log(fieldName, "fieldName");
        setPersonalInfo({
          ...personalInfo,
          [`${fieldName}Error`]: true,
        });
        toast.error(
          `Please fill in the ${
            fieldName === "confPass" ? "confirm password" : fieldName
          } field.`
        );
        return; // Exit the function if there's an empty field or invalid email
      }
    }

    // If all fields are filled, proceed with your logic

    // Rest of your code
  };

  const onFirstNameValidation = (value) => {
    // Allow alphabetic characters and spaces at the beginning, end, or between characters
    if (!/^[a-zA-Z]*(?:\s[a-zA-Z]+)*$/.test(value)) {
      setPersonalInfo({
        ...personalInfo,
        firstNameError: true,
      });
    } else {
      setPersonalInfo({
        ...personalInfo,
        firstNameError: false,
        firstName: value,
      });
    }
  };

  const onLastNameValidation = (value) => {
    if (!/^[a-zA-Z]*(?:\s[a-zA-Z]+)*$/.test(value)) {
      setPersonalInfo({
        ...personalInfo,
        lastNameError: true,
      });
    } else {
      setPersonalInfo({
        ...personalInfo,
        lastName: value,
        lastNameError: false,
      });
    }
  };

  const isValidEmail = (email) => {
    if (email === "") {
      setPersonalInfo({
        ...personalInfo,
        email: email,
        emailError: true,
      });
    } else {
      setPersonalInfo({
        ...personalInfo,
        email: email,
        emailError: false,
      });
    }
  };

  const onPasswordValidation = (value) => {
    if (
      value !== "" &&
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).*$/i.test(value)
    ) {
      setPersonalInfo({
        ...personalInfo,
        passError: true,
        password: value,
      });
    } else {
      setPersonalInfo({
        ...personalInfo,
        password: value,
        passError: false,
      });
    }
  };
  // const dispatch = useDispatch();

  // const onVerifyUserEmail = () => {
  //   const email = personalInfo.email;
  //   setIsLoading(true);

  //   dispatch(accountActions.onVerifyUserEmail(email)).then((data) => {
  //     setIsLoading(true);
  //     if (data && data.success == false) {
  //       toast.error("Email address is already registered.");
  //     } else {
  //       // this.props.actions.onChange("currentPaneId", "pane2");
  //     }
  //   });
  // };
  // const onFocusOutFun = (value) => {
  //   if (
  //     value !== "" &&
  //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  //   ) {
  //     setPersonalInfo({
  //       ...personalInfo,
  //       emailError: true,
  //     });
  //   }
  // };
  return (
    <div className="userRegistration__tabs">
      <h2
        style={{
          marginBottom: 24,
          marginTop: 16,
          fontWeight: 600,
          fontSize: "30px",
          color: "#202A4C",
          fontFamily: "Poppins",
        }}
      >
        Let’s Get Started
      </h2>
      <h4
        style={{
          fontSize: "22px",
          fontWeight: 600,
          color: "#181C32",
          fontFamily: "Poppins",
        }}
      >
        Create An Aquatic AV Account
      </h4>
      <p
        style={{
          fontSize: "18px",
          fontWeight: 400,
          color: "#000000",
          fontFamily: "Poppins",
        }}
      >
        Create a free Aquatic AV Account to organize, register, and manage your
        Aquatic AV products. Let’s get started with some basic account
        information that we need to create your account.
      </p>
      <span className="groupFieldsMain">
        <span className="loginWrapper__loginFormWrapper--inner">
          <FloatingLabelInput
            placeholder="First Name"
            value={personalInfo.firstName}
            className={
              personalInfo.firstName === "" && personalInfo.firstNameError
                ? "errorOutline"
                : ""
            }
            onChange={(e) => onFirstNameValidation(e.target.value)}
          />
        </span>
        <span className="loginWrapper__loginFormWrapper--inner ">
          <FloatingLabelInput
            placeholder="Last Name"
            value={personalInfo.lastName}
            // onBlur={() =>
            //   setPersonalInfo({
            //     lastName: personalInfo.lastName.trim(),
            //     lastNameError: personalInfo.lastName !== "" ? false : true,
            //   })
            // }
            className={
              personalInfo.lastName === "" && personalInfo.lastNameError
                ? "errorOutline"
                : ""
            }
            onChange={(e) => onLastNameValidation(e.target.value)}
          />
        </span>

        <span className="loginWrapper__loginFormWrapper--inner">
          <FloatingLabelInput
            placeholder="Email"
            type="email"
            value={personalInfo.email}
            className={
              personalInfo.email === "" && personalInfo.emailError
                ? "errorOutline"
                : ""
            }
            // onBlur={() => onFocusOutFun(personalInfo.email)}
            onChange={(e) => isValidEmail(e.target.value)}
          />
          {/* {personalInfo.emailError ? (
            <span style={{ color: "red" }}>
              {personalInfo.email !== "" ? "Invalid Email" : ""}
            </span>
          ) : null} */}
        </span>

        <span className="loginWrapper__loginFormWrapper--inner">
          <FloatingLabelInput
            placeholder="Password"
            type="password"
            value={personalInfo.password}
            className={personalInfo.passError ? "errorOutline" : ""}
            onChange={(e) => onPasswordValidation(e.target.value)}
          />
          {personalInfo.passError ? (
            <span style={{ color: "red" }}>
              {personalInfo.password !== ""
                ? "Password must contain at least 1 uppercase, lowercase, number and special character"
                : ""}
            </span>
          ) : null}
        </span>

        <span className="loginWrapper__loginFormWrapper--inner">
          <FloatingLabelInput
            placeholder="Confirm Password"
            type="password"
            value={personalInfo.confirmPassword}
            className={personalInfo.confPassError ? "errorOutline" : ""}
            // onBlur={() =>
            //   personalInfo.confirmPassword !== "" &&
            //   personalInfo.password !== personalInfo.confirmPassword
            //     ? onFocusOutFun({ ...personalInfo, confPassError: true })
            //     : null
            // }
            onChange={(e) =>
              setPersonalInfo({
                ...personalInfo,
                confirmPassword: e.target.value,
                confPassError: false,
              })
            }
          />
          {personalInfo.confPassError ? (
            <span style={{ color: "red" }}>
              {personalInfo.confirmPassword !== ""
                ? "Password Not Matched"
                : ""}
            </span>
          ) : null}
        </span>
      </span>

      <label className="container">
        <a
          href="https://aquaticav.com/terms/"
          target="_blank"
          rel="noreferrer"
          className="linkText"
        >
          I Accept the Terms and Conditions
        </a>
        <input
          type="checkbox"
          id="tnc"
          value={personalInfo.terms}
          onChange={(e) =>
            setPersonalInfo({
              ...personalInfo,
              terms: !personalInfo.terms,
              termsError: false,
            })
          }
        />
        <span
          className={`checkmark ${
            personalInfo.termsError ? "errorOutline" : ""
          }`}
        ></span>
      </label>
      <span className="footerNav ">
        {onLoading ? (
          <Loader />
        ) : (
          <button
            id="pane2"
            className="nextNavButton"
            // onClick={() => onRegisterNewUser()}
            onClick={() =>
              personalInfo?.firstName?.trim() !== "" &&
              personalInfo?.lastName?.trim() !== "" &&
              personalInfo.email !== "" &&
              personalInfo.terms
                ? // ? onRegisterNewUser()
                  onClick({
                    firstName: personalInfo.firstName,
                    lastName: personalInfo.lastName,
                    email: personalInfo.email,
                    confirmEmail: personalInfo.email,
                    password:
                      personalInfo.password !== "" ? personalInfo.password : "",
                    confirmPassword:
                      personalInfo.confirmPassword !== ""
                        ? personalInfo.confirmPassword
                        : "",
                    groupId: 0,
                    customerTypeName: "",
                    companyId: 0,
                    isFavourite: false,
                    isAdmin: false,
                    image: "",
                    rating: "",
                    policies: [],
                  })
                : onCheckPersonalInfo()
            }
          >
            NEXT STEP
          </button>
        )}
      </span>
      {/* <input type="checkbox" id="tnc" value={terms} onChange={e => this.setState({ terms: !terms })} />
            <label htmlFor="tnc"> I accept the Terms and Conditions</label> */}
    </div>
  );
};
export { PersonalInfoForm };
