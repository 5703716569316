import apiCall from "axios";
import Cookies from "js-cookie";
import { endpoints } from "../../config/apiConfig";
import { axios } from "../../config/axiosConfig";
var sha512 = require("js-sha512");

async function login(email, password) {
  // const userIp = localStorage.getItem("userIpAddress"); // old location value usage
  const encPass = sha512(password);
  var data = JSON.stringify({
    // user_email: email, // old login key
    email: email,
    password: password, // new code
    // password: encPass, // old salted password
    // device_info: "Web", // old login key
    // is_login: 1, // old login key
    // device_val: userIp, // old login key
  });
  var config = {
    method: "post",
    url: endpoints.accounts.login,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(response => {
      if (
        response.status === 200 &&
        response.data.success
        // response.data.user_info.user_exist === "True" // old login check for success
      ) {
        // localStorage.setItem("userEmail", email);
        // localStorage.setItem("password", encPass); // plain password storage in local store user vanulbality
        localStorage.setItem("passwordDec", password);
        // localStorage.setItem("access_token", response.data.user_info.token); // old access token path
        // localStorage.setItem("userId", response.data.user_info.user_id);
        // localStorage.setItem(
        //   "userInfo",
        //   JSON.stringify(response.data.user_info)
        // );

        localStorage.setItem("userEmail", email);
        localStorage.setItem("password", encPass);
        localStorage.setItem(
          "access_token",
          response.data.data.accessToken.token
        );
        Cookies.set("access_token", response.data.data.accessToken.token);
        let currentDateObj = new Date();
        let numberOfMlSeconds = currentDateObj.getTime();
        let addMlSeconds = 16 * 60 * 1000;
        let newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

        localStorage.setItem(
          "access_token_expiry",
          newDateObj.getTime() //response.data.data.accessToken.expiry
        );
        localStorage.setItem("userId", response.data.data.id);
        localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        localStorage.setItem("isAuthenticated", true);
      }
      return response;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}

async function forgot(email) {
  return axios
    .get(`${endpoints.accounts.forgot}${"email="}${email}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Max-Age": "1800",
        "Access-Control-Allow-Headers": "X-Requested-With, content-type",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}

async function verificationCode(code) {
  return axios
    .get(`${endpoints.accounts.verification}${"user_email="}${code}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Max-Age": "1800",
        "Access-Control-Allow-Headers": "X-Requested-With, content-type",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}

async function resetPassword(reqObj) {
  return axios
    .post(endpoints.accounts.resetPassword, reqObj)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}

async function onRegisterUser(value) {
  // const userIp = localStorage.getItem("userIpAddress");
  let data = JSON.stringify(value);
  const encPass = sha512(value.password);

  // var config = {
  //   method: "post",
  //   url: endpoints.accounts.newUserRegister,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   data: data,
  // };

  return axios
    .post(endpoints.accounts.newUserRegister, data)
    .then(response => {
      if (response && response.data && response.data.success) {
        localStorage.setItem("userEmail", value.email);
        localStorage.setItem("passwordDec", value.password);
        localStorage.setItem("password", encPass);
        localStorage.setItem(
          "access_token",
          response.data.data.accessToken.token
        );
        localStorage.setItem(
          "access_token_expiry",
          response.data.data.accessToken.expiry
        );
        localStorage.setItem("userId", response.data.data.id);
        localStorage.setItem("userInfo", JSON.stringify(response.data.data));
      }
      return response;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}

async function verifyUserEmail(userEmail) {
  return axios
    .post(
      `${endpoints.accounts.verifyUser}`,
      JSON.stringify({ email: userEmail })
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function onUpdateUserNotifications(req) {
  return axios
    .post(`${endpoints.accounts.updateNotification}`, JSON.stringify(req))
    .then(response => {
      return response;
    })
    .catch(error => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}

async function loginWithToken(token) {
  return apiCall({
    url: endpoints.accounts.tokenLogin,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      if (response.status === 200 && response.data.success) {
        localStorage.setItem("userEmail", response?.data?.data?.email);
        localStorage.setItem("access_token", token);
        let currentDateObj = new Date();
        let numberOfMlSeconds = currentDateObj.getTime();
        let addMlSeconds = 16 * 60 * 1000;
        let newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

        localStorage.setItem(
          "access_token_expiry",
          newDateObj.getTime() //response.data.data.accessToken.expiry
        );
        localStorage.setItem("userId", response.data.data.id);
        localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        localStorage.setItem("isAuthenticated", true);
      }
      return response;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}

async function onUserEmailVerification(userId) {
  return axios
    .post(`${endpoints.accounts.onUserEmailVerification}/${userId}`)
    .then(response => {
      console.log("onUserEmailVerification response:::", response);
      return response;
    })
    .catch(error => {
      return error;
    });
}

async function onUserSMSVerification(userId) {
  return axios
    .post(`${endpoints.accounts.onUserSMSVerification}/${userId}`)
    .then(response => {
      console.log("onUserSMSVerification response:::", response);
      return response;
    })
    .catch(error => {
      return error;
    });
}

async function onGetUserEmailVerification(userId) {
  return axios
    .get(`${endpoints.accounts.onGetUserEmailVerification}`, { params: userId })
    .then(response => {
      console.log("onGetUserEmailVerification response:::", response);
      return response;
    })
    .catch(error => {
      return error;
    });
}

async function onGetUserSMSVerification(userId) {
  return axios
    .get(`${endpoints.accounts.onGetUserSMSVerification}`, { params: userId })
    .then(response => {
      console.log("onGetUserSMSVerification response:::", response);
      return response;
    })
    .catch(error => {
      return error;
    });
}
async function onNotificationSubmission(notifications) {
  return axios
    .post(
      `${endpoints.accounts.addAccountNotifications}`,
      JSON.stringify(notifications)
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return {
        data: {
          success: false,
          message:
            error.message === "Network Error" ? "Network Error" : error.message,
        },
      };
    });
}
export const accountApi = {
  login: login,
  forgot: forgot,
  verificationCode: verificationCode,
  resetPassword: resetPassword,
  onRegisterUser: onRegisterUser,
  verifyUserEmail: verifyUserEmail,
  onUpdateUserNotifications: onUpdateUserNotifications,
  loginWithToken: loginWithToken,
  onUserEmailVerification: onUserEmailVerification,
  onUserSMSVerification: onUserSMSVerification,
  onGetUserEmailVerification: onGetUserEmailVerification,
  onGetUserSMSVerification: onGetUserSMSVerification,
  onNotificationSubmission: onNotificationSubmission,
};
