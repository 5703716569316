import { spaOverviewApi } from "./SpaOverviewApi";
import { spaOverviewTypes } from "./SpaOverviewTypes";
import _ from "lodash";

const onGetSpaInfo = (deviceId) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaInfo(deviceId);
  dispatch({
    type: spaOverviewTypes.SET_SPA,
    payload: response.data,
  });
  return response;
};
const onGetSpaUser = (id) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaUser(id);
  dispatch({
    type: spaOverviewTypes.SPA_USER_DETAIL,
    payload: response.data,
  });
  return response;
};

const onSelectSpaLocation = (locationData) => async (dispatch) => {
  dispatch({
    type: spaOverviewTypes.SET_SELECT_LOCATION_SPA,
    payload: locationData,
  });
};
const onResetSpaFields = () => async (dispatch) => {
  dispatch({
    type: spaOverviewTypes.SPA_RESET_FIELDS,
  });
};
const onAddUpdateSpaSetting = (spaData) => async (dispatch) => {
  let response = await spaOverviewApi.addUpdateSpaSetting(spaData);
  return response;
};
const onChangeWaterTreatment = (wtrData) => async (dispatch) => {
  let response = await spaOverviewApi.changeWaterTreatment(wtrData);
  return response;
};
const onUpdataGlobalSetting = (globalData) => async (dispatch) => {
  let response = await spaOverviewApi.changeGlobalSetting(globalData);
  return response;
};
const onUpdataTempSetting = (tempData) => async (dispatch) => {
  let response = await spaOverviewApi.changeTemperatureSetting(tempData);
  return response;
};

const onGetSpaGeneralInfo = (genData) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaGeneralInfor(genData);
  if (response?.success) {
    dispatch({
      type: spaOverviewTypes.ON_CHANGE_SPA,
      payload: { prop: "spaGeneralInfo", value: response.data },
    });
  }
  return response;
};
const onGetSpaFilterInfo = (filtData) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaFilterInfor(filtData);
  return response;
};
const onGetSpaTempInfo = (tempData) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaTempInfor(tempData);
  return response;
};
const onGetSpaAllWaterLife = (spaId) => async (dispatch) => {
  let response = await spaOverviewApi.getAllWaterLife(spaId);
  if (response?.success) {
    dispatch({
      type: spaOverviewTypes.ON_CHANGE_SPA,
      payload: { prop: "spaAllWaterLife", value: response.data },
    });
  }
  return response;
};

const onAddUpdateWaterLife = (waterInfo) => async (dispatch) => {
  let response = await spaOverviewApi.addUpdateWaterLife(waterInfo);
  return response;
};
const onAddUpdateChemicalTesting = (chemicalInfo) => async (dispatch) => {
  let response = await spaOverviewApi.addUpdateChemicalTesting(chemicalInfo);
  return response;
};
const onGetSpaAllFilterLife = (spaId) => async (dispatch) => {
  let response = await spaOverviewApi.getAllFilterLife(spaId);
  if (response?.success) {
    dispatch({
      type: spaOverviewTypes.ON_CHANGE_SPA,
      payload: { prop: "spaAllFilterLife", value: response.data },
    });
  }
  return response;
};

const onAddUpdateFilterLife = (waterInfo) => async (dispatch) => {
  let response = await spaOverviewApi.addUpdateFilterLife(waterInfo);
  return response;
};

const onGetFilterLifeStats = (spaId) => async (dispatch) => {
  let response = await spaOverviewApi.getFilterStats(spaId);
  if (response?.success) {
    dispatch({
      type: spaOverviewTypes.ON_CHANGE_SPA,
      payload: { prop: "filterLifeStats", value: response.data },
    });
  }
  return response;
};
const onGetWaterLifeStats = (spaId) => async (dispatch) => {
  let response = await spaOverviewApi.getWaterStats(spaId);
  if (response?.success) {
    dispatch({
      type: spaOverviewTypes.ON_CHANGE_SPA,
      payload: { prop: "waterLifeStats", value: response.data },
    });
  }
  return response;
};

const onDeleteFilterLife = (filtrInfo) => async (dispatch) => {
  let response = await spaOverviewApi.deleteFilterLife(filtrInfo);
  return response;
};
const onDeleteWaterLife = (waterInfo) => async (dispatch) => {
  let response = await spaOverviewApi.deleteWaterLife(waterInfo);
  return response;
};
const onGetCurrentSpaChemicalTestig = (testInfo) => async (dispatch) => {
  let response = await spaOverviewApi.getCurrentSpaChemicalTestig(testInfo);
  return response;
};

const onGetCurrentSpaChemicalTestigGraphDetails =
  (testInfo) => async (dispatch) => {
    let response = await spaOverviewApi.getCurrentSpaChemicalTestigGraphDetails(
      testInfo
    );
    return response;
  };

const onGetFilterLifeGraphDetails =
  (payload) => async (dispatch) => {
    return await spaOverviewApi.getFilterLifeGraphDetails(
      payload
    );
  };

  const onGetWaterLifeGraphDetails =
  (payload) => async (dispatch) => {
    return await spaOverviewApi.getWaterLifeGraphDetails(
      payload
    );
  };

const onGetSpaChemicalTestigList = (testInfo) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaChemicalTestigList(testInfo);
  return response;
};

const onDeleteSPAChemicalTesting = (filtrInfo) => async (dispatch) => {
  let response = await spaOverviewApi.deleteSPAChemicalTesting(filtrInfo);
  return response;
};

const onGetSpaFilterSettings = (testInfo) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaFilterSettings(testInfo);
  return response;
};

const onUpdateSpaFilterSettings = (testInfo) => async (dispatch) => {
  let response = await spaOverviewApi.updateSpaFilterSettings(testInfo);
  return response;
};
const onAddUpdateSpaUser = (spaUserData) => async (dispatch) => {
  let response = await spaOverviewApi.addUpdateSpaUser(spaUserData);
  return response;
};

const removeSpaUser = (id) => async (dispatch) => {
  let response = await spaOverviewApi.removeSpaUser(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};

// Spa Access
const onGetSpaDevices = (id) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaDevices(id);
  dispatch({
    type: spaOverviewTypes.SPA_DEVICES,
    payload: response.data,
  });
  return response;
};

const removeSpaDevice = (id) => async (dispatch) => {
  let response = await spaOverviewApi.removeSpaDevice(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};

const updateSpaDevice = (spaUserData) => async (dispatch) => {
  let response = await spaOverviewApi.updateSpaDevice(spaUserData);
  return response;
};
// spa notification
const onGetSpaNotification = (id) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaNotification(id);
  dispatch({
    type: spaOverviewTypes.SPA_NOTIFICATION,
    payload: response.data,
  });
  return response;
};

// spa global setting

const onGetSpaGlobalScenes = (id) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaGlobalScenes(id);
  dispatch({
    type: spaOverviewTypes.SPA_GLOBAL_SCENCES,
    payload: response.data,
  });
  return response;
};

const onRemoveSpaGlobalScene = (id) => async (dispatch) => {
  let response = await spaOverviewApi.removeSpaGlobalScene(id);
  if (response && response.data && response.data.success) {
  }
  return response;
};
const onSetAsCurrentGlobalSpaScene = (currentScene) => async (dispatch) => {
  let response = await spaOverviewApi.setAsCurrentSpaGlobalScene(currentScene);
  return response;
};
const onGetSpaSupportResources = (id) => async (dispatch) => {
  let response = await spaOverviewApi.getSpaSupportResources(id);
  dispatch({
    type: spaOverviewTypes.SUPPORT_RESOURCES,
    payload: response.data,
  });
  return response;
};
const onGetSpaAccessIcons = () => async (dispatch) => {
  let response = await spaOverviewApi.getSpaAccessIcons();
  dispatch({
    type: spaOverviewTypes.USER_ICONS,
    payload: response.data,
  });
  return response;
};

const getDeviceUseHistory = (data) => async (dispatch) => {
  let response = await spaOverviewApi.getDeviceUseHistory(data);
  return response;
};

const onSetSpaDeviceName = (name) => async (dispatch) => {
  dispatch({
    type: spaOverviewTypes.SPA_DEVICE_NAME,
    payload: name,
  });
  return true;
};

export const SpaOverviewAction = {
  onGetSpaInfo: onGetSpaInfo,
  onSelectSpaLocation: onSelectSpaLocation,
  onResetSpaFields: onResetSpaFields,
  onAddUpdateSpaSetting: onAddUpdateSpaSetting,
  onChangeWaterTreatment: onChangeWaterTreatment,
  onUpdataGlobalSetting: onUpdataGlobalSetting,
  onUpdataTempSetting: onUpdataTempSetting,
  onGetSpaGeneralInfo: onGetSpaGeneralInfo,
  onGetSpaFilterInfo: onGetSpaFilterInfo,
  onGetSpaTempInfo: onGetSpaTempInfo,
  onGetSpaAllWaterLife: onGetSpaAllWaterLife,
  onAddUpdateWaterLife: onAddUpdateWaterLife,
  onGetSpaAllFilterLife: onGetSpaAllFilterLife,
  onAddUpdateFilterLife: onAddUpdateFilterLife,
  onGetFilterLifeStats: onGetFilterLifeStats,
  onGetWaterLifeStats: onGetWaterLifeStats,
  onDeleteFilterLife: onDeleteFilterLife,
  onDeleteWaterLife: onDeleteWaterLife,
  onGetCurrentSpaChemicalTestig: onGetCurrentSpaChemicalTestig,
  onGetCurrentSpaChemicalTestigGraphDetails:
    onGetCurrentSpaChemicalTestigGraphDetails,
    onGetFilterLifeGraphDetails: onGetFilterLifeGraphDetails,
    onGetWaterLifeGraphDetails: onGetWaterLifeGraphDetails,
  onAddUpdateChemicalTesting: onAddUpdateChemicalTesting,
  onGetSpaChemicalTestigList: onGetSpaChemicalTestigList,
  onDeleteSPAChemicalTesting: onDeleteSPAChemicalTesting,
  onGetSpaFilterSettings: onGetSpaFilterSettings,
  onUpdateSpaFilterSettings: onUpdateSpaFilterSettings,
  onAddUpdateSpaUser: onAddUpdateSpaUser,
  onGetSpaUser: onGetSpaUser,
  removeSpaUser: removeSpaUser,
  onGetSpaDevices: onGetSpaDevices,
  removeSpaDevice: removeSpaDevice,
  updateSpaDevice: updateSpaDevice,
  onGetSpaNotification: onGetSpaNotification,
  onGetSpaGlobalScenes: onGetSpaGlobalScenes,
  onRemoveSpaGlobalScene: onRemoveSpaGlobalScene,
  onSetAsCurrentGlobalSpaScene: onSetAsCurrentGlobalSpaScene,
  onGetSpaSupportResources: onGetSpaSupportResources,
  onGetSpaAccessIcons: onGetSpaAccessIcons,
  getDeviceUseHistory: getDeviceUseHistory,
  onSetSpaDeviceName: onSetSpaDeviceName,
};
