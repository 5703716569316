import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getNewArrivalsProducts() {
  return axios
    .get(endpoints.products.GetNewArrivalsProducts)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getPopularProducts() {
  return axios
    .get(endpoints.products.GetPopularProducts)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getProductsDetailById(productId) {
  return axios
    .post(endpoints.products.GetProductsDetailById, { productId })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getAllNewsRoomContentByRoomId() {
  return axios
    .get(endpoints.products.GetAllNewsRoomContentByRoomId)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function GetProductCategory() {
  return axios
    .post(endpoints.products.GetProductCategory)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function GetProductsByProductCategory(categoryId) {
  return axios
    .post(endpoints.products.GetProductsByProductCategory, { id: categoryId })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

export const ProductsApi = {
  getNewArrivalsProducts,
  getPopularProducts,
  getProductsDetailById,
  getAllNewsRoomContentByRoomId,
  GetProductCategory,
  GetProductsByProductCategory,
};
