import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  AppBtn,
  AppModal,
  Header,
  Loader,
} from "../../components/common/index";
import {
  Headphone,
  Speaker,
  ListPlus,
  SearchBlue,
} from "../../resources/images";
import { RoutesObj } from "../../routes";

import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { deviceOverviewAction } from "../../store/deviceOverview/deviceOverviewAction";

import { SpaOverviewAction } from "../../store/SpaOverview/SpaOverviewAction";

import Carousel from "react-bootstrap/Carousel";

import TabNav from "../Dashboard/TabNav";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProductsAction } from "../../store/Products/ProductsAction";

const Pane = (props) => (
  <div
    className={"tab-pane" + (props.activepane ? " active" : "")}
    id={props.id}
  >
    {props.children}
  </div>
);

class Catalog extends Component {
  constructor() {
    super();

    this.state = {
      activepane: "pane1",
      productDetail: null,
      ProductCategory: [],
    };
  }

  componentDidMount() {
    const { location } = this.props;

    this.setState({
      isLoading: true,
    });
    this.props.ProductsAction.GetProductsDetailById(
      location.state.productId
    ).then((response) => {
      if (response !== null && response.success === true) {
        this.setState({
          productDetail: response.data,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error("Something went wrong, Please try again");
      }
    });

    this.props.ProductsAction.GetProductCategory().then((response) => {
      if (response !== null && response.success === true) {
        this.setState({
          ProductCategory: response.data,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error("Something went wrong, Please try again");
      }
    });
  }

  render() {
    const { userInfo, userEnv } = this.props.state;
    const { isLoading } = this.state;
    const { history } = this.props;

    console.log("sta", this.state.productDetail);

    let currentEnv = {};
    try {
      currentEnv = JSON.parse(localStorage.getItem("currentEnv"));
    } catch (error) {
      console.log(error);
    }
    if (this.state.productDetail !== null)
      return (
        <div className="Dashboardmain-container">
          <div className="Dashboardmain-container__header">
            <Header
              userInformation={userInfo ? userInfo : null}
              userEnvoirnment={userEnv}
              history={history}
            />

            <div className="mainTitle">
              <span>
                {this.state.productDetail.productName}
                <span className="mainTitle__breadCrum">
                  Catalog <i></i> {this.state.productDetail.category} <i></i>{" "}
                  {this.state.productDetail.productName}
                </span>
              </span>
              <div className="d-flex">
                <button
                  onClick={() => {
                    history.push({
                      pathname: RoutesObj.PriceList.path,
                    });
                  }}
                  className="view-price"
                >
                  <img src={ListPlus} />
                  View Price Lists
                </button>
                <div className="d-flex align-items-center input-search">
                  <img src={SearchBlue} />
                  <input placeholder="Search Catalog"></input>
                </div>
              </div>
            </div>
          </div>

          <div className="nav-btn py-4">
            <div className="container">
              <div className="row justify-content-around">
                {this.state.ProductCategory.map((v, i) => {
                  return (
                    <button
                      className={`btn1 ${
                        this.state.productDetail.categoryId === v.id
                          ? "tab-view"
                          : ""
                      }`}
                    >
                      {v.value}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container">
            <div class="row">
              <div class="col-md-7">
                <div class="product-carousel">
                  <div className="container">
                    <Carousel variant="dark" className="carou">
                      {this.state.productDetail.featureImagesDetail.map(
                        (v, i) => (
                          <Carousel.Item>
                            <img
                              className="d-block w-100"
                              src={v.fileName}
                              alt="First slide"
                            />
                          </Carousel.Item>
                        )
                      )}
                      {/* <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={this.state.productDetail.featureImage}
                          alt="First slide"
                        />
                      </Carousel.Item> */}
                    </Carousel>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="product-name">
                  <h4
                    style={{
                      color: "#142560",
                      fontWeight: "700",
                    }}
                  >
                    {this.state.productDetail.productName}
                  </h4>
                  <h6 style={{ color: "#828282", fontWeight: "700" }}>
                    {this.state.productDetail.productSKU}
                  </h6>

                  <span style={{ display: "flex" }}>
                    {[{}, {}, {}, {}, {}].map((v, i) => {
                      if (i + 1 <= this.state.productDetail.rating) {
                        return (
                          <StarRoundedIcon
                            style={{
                              color: "#FFA800",
                            }}
                          />
                        );
                      } else {
                        return (
                          <StarOutlineRoundedIcon
                            style={{
                              color: "#FFA800",
                            }}
                          />
                        );
                      }
                    })}

                    <p
                      style={{
                        fontWeight: "bold",
                        textDecorationLine: "underline",
                        color: "#142560",
                      }}
                    >
                      {this.state.productDetail.rating} Stars
                    </p>
                  </span>

                  <h3
                    class="font-weight-bold pt-3"
                    style={{
                      color: "#142560",
                    }}
                  >
                    ${this.state.productDetail.price}
                  </h3>
                  <h6>Retail Price (MSRP)</h6>
                  <p class="pt-4">
                    {this.state.productDetail.shortDescription}
                  </p>
                  <p class="pt-5">
                    Contact Your Distributor to Purchase this Product
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="Messages-container__innerBody">
            <div className="container">
              <div className="Messages-tabContainer">
                <TabNav
                  activepane={this.state.activepane}
                  clickTab={(value) => this.setState({ activepane: value })}
                >
                  <Pane id="pane1" title="PRODUCT OVERVIEW">
                    <div style={{ height: "500px" }}> </div>
                  </Pane>
                  <Pane id="pane2" title="SPECIFICATIONS">
                    <div style={{ height: "500px" }}> </div>
                  </Pane>
                  <Pane id="pane3" title="REVIEWS">
                    <div style={{ height: "500px" }}> </div>
                  </Pane>
                  <Pane id="pane4" title="SUPPORT RESOURCES">
                    <div style={{ height: "500px" }}> </div>
                  </Pane>
                </TabNav>
              </div>
            </div>
          </div>
        </div>
      );
    else {
      return (
        <div className="Dashboardmain-container">{isLoading && <Loader />}</div>
      );
    }
  }
}

export default connect(
  (state) => ({
    state: state.DashboardReducer,
  }),
  (dispatch) => ({
    ProductsAction: bindActionCreators(ProductsAction, dispatch),
  })
)(withRouter(Catalog));
