import React, { useEffect, useRef, useState } from "react";
import { Loader } from "../../components/common";
import "./ConversationStyle.css";

import NewChat from "./components/NewChat";
import { useDispatch } from "react-redux";
import { conversationAction } from "../../store/conversations/conversationAction";
import * as signalR from "@microsoft/signalr";
import DOMPurify from "dompurify";
import { dashboardActions } from "../../store/dashboard/dashboardActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Flag } from "@material-ui/icons";

const UpdateConversation = () => {
  const dispatch = useDispatch();
  // const connectionUrl = "https://aquaticavchatdev.azurewebsites.net/signalr";
  const connectionUrl = "https://aquaticavchat.azurewebsites.net/signalr";
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [chatFullScreen, setChatFullScreen] = useState(false);
  const [startChat, setStartChat] = useState(true);
  const [selectChat, setSelectChat] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedRoomId, setRoomId] = useState(0);
  const [connection, setConnection] = useState(null);
  const [messageDetailsData, setMessageDetailsData] = useState([]);
  const [userRooms, setUserRooms] = useState([]);
  const [isNewChat, setIsNewChat] = useState(true);
  const [selectedNewRoomChat, setSelectedNewRoomChat] = useState(
    JSON.parse(localStorage.getItem("selectedRoomIdLocal")) || {
      isChatRoomExist: true,
    }
  );

  let getHubId = JSON.parse(localStorage.getItem("selectedRoomIdLocal"));
  const [devices, setDevice] = useState([]);
  const roomId = useRef(0);
  const hubUserId = useRef(0);
  const messageDetail = useRef([]);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  // const userId = "901fbefd-6996-4e0f-9e2b-023a94c21ccc";
  const userId = "901fbefd-6996-4e0f-9e2b-023a94c21ccc";

  const generateGuid = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const options = {
    // transport: transport,
    logMessageContent: true,
    logger: signalR.LogLevel.Trace,
    // skipNegotiation: true,
  };

  const buildConnection = () => {
    setLoading(true);
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    let connect = new signalR.HubConnectionBuilder()
      .withUrl(
        connectionUrl +
          "?ClientId=" +
          userId +
          "&ProductId=" +
          2 +
          // "&ClientName=" +
          // userInfo.name +
          // "" +
          "&generic=" +
          "customer",
        options
      )
      .withAutomaticReconnect()
      .withHubProtocol(protocol)
      .build();
    setConnection(connect);
    connect.on("send", (data) => {
      console.log(data);
    });

    connect
      .start()
      .then(() => {
        console.log("connection started");
      })
      .catch((err) => {
        console.log("error while establishing signalr connection: " + err);
      });
  };
  useEffect(() => {
    setLoading(true);
    buildConnection();
  }, []);

  useEffect(() => {
    setLoading(true);
    const interval = setInterval(() => {
      if(getHubId?.id && getHubId?.hubUserId){
      loadChat(selectedNewRoomChat?.hubUserId);
      chatDetail(selectedNewRoomChat?.id);
      }
    }, 7000); // 10000 milliseconds = 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [selectedNewRoomChat]);

  useEffect(() => {
    if (connection) {
      setLoading(true);
      connection.off("messageRecieved");
      connection.on("onUserConnected", (data) => {
        if (data) {
          // hubUserId.current = data?.hubUserId;
          loadChat(getHubId?.hubUserId);
          chatDetail(selectedNewRoomChat?.id);
        }
      });

      connection.on("messageRecieved", async (data) => {
        if (getHubId?.hubUserId > 0) {
          if (
            selectedNewRoomChat?.id &&
            data?.roomId === selectedNewRoomChat?.id
          ) {
            chatDetail(data?.roomId);
          } else {
            loadChat(getHubId?.hubUserId);
          }
        }
      });
    }
  }, [connection]);

  const uploadAttachment = (messageContent, attachment) => {
    let formdata = new FormData();
    formdata.append("file", attachment);
    setLoading(true);
    dispatch(conversationAction.uploadFile(formdata)).then(({ data }) => {
      setLoading(false);
      if (data.responseCode === 1) {
        let reqdata = {
          TimeZone: timeZone,
          RoomId: selectedNewRoomChat?.id,
          HubUserId: getHubId?.hubUserId,
          Content: messageContent,
          Attachments: [
            {
              Name: attachment.name,
              path: data.responseData.url,
            },
          ],
        };
        connection.send("SendMessage", reqdata).then((x) => {
          chatDetail(selectedNewRoomChat?.id);
          console.log("Message Sent Successfully");
        });
      }
    });
  };

  const uploadAttachmentWithNewRoom = (
    messageContent,
    selectedUser,
    attachment
  ) => {
    let formdata = new FormData();
    formdata.append("file", attachment);
    dispatch(conversationAction.uploadFile(formdata)).then(({ data }) => {
      if (data.responseCode === 1) {
        const newMsgContent = {
          content: messageContent,
          TimeZone: timeZone,
          roomName: "TrbAi room Name",
          Attachments: [
            {
              Name: attachment.name,
              path: data.responseData.url,
            },
          ],
          messageFrom: {
            id: userId,
            name: `${userInfo.firstName}  ${userInfo.lastName}`,
            allowedOnCampaignsId: "1",
            generic: "",
          },
          messageTo: [
            {
              id: selectedUser?.id,
              name: selectedUser.name,
              allowedOnCampaignsId: "",
              generic: "",
            },
          ],
          productId: 2,
        };
        dispatch(conversationAction.onSendMessageViaApi(newMsgContent))
          .then((res) => {
            if (res.responseCode === 1) {
              chatDetail(res?.responseData?.roomId);
              setRoomId(res?.responseData?.roomId);
              setSelectedNewRoomChat({
                id: res?.responseData?.roomId,
                isChatRoomExist: false,
              });
              localStorage.setItem(
                "selectedRoomIdLocal",
                JSON.stringify({
                  id: res?.responseData?.roomId,
                  isChatRoomExist: false,
                  hubUserId: res?.responseData?.hubUserId,
                })
              );
            }
          })
          .catch((error) => {
            console.log(error, "e=>");
          });
      }
    });
  };

  const onSendMessageWithExistingRoom = (content, attachment) => {
    let messageContent = content;
    if (!messageContent.trim() && !attachment) {
      return;
    }
    if (!attachment) {
      let msgdata = {
        RoomId: selectedNewRoomChat?.id,
        HubUserId: getHubId?.hubUserId,
        Content: messageContent,
        TimeZone: timeZone,
      };
      connection.send("SendMessage", msgdata).then((x) => {
        chatDetail(selectedNewRoomChat?.id);
        loadChat(getHubId?.hubUserId);
        console.log("Message Sent Successfully");
      });
    } else {
      uploadAttachment(messageContent, attachment);
    }
  };
  const onSendNewMessage = async (content, selectedUser, attachment) => {
    let messageContent = content;
    // const sanitizedHtml = DOMPurify.sanitize(messageContent);
    // dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    if (!messageContent.trim() && !attachment) {
      return;
    }
    if (selectedNewRoomChat?.isChatRoomExist) {
      if (!attachment) {
        const newMsgContent = {
          content: messageContent,
          TimeZone: timeZone,
          roomName: "",

          messageFrom: {
            id: "-1",
            name: "Guest user",
            allowedOnCampaignsId: "1",
            generic: "",
          },
          messageTo: [
            {
              //For Production
              id: "5e63f328-1876-4771-bcb6-53f59f29f8a3",
              //For Dev
              //id: "d5083803-29d5-4bf0-bce7-fbaab34011a6",
              name: "AQUATIC AV",
              allowedOnCampaignsId: "1",
              generic: "",
            },
          ],

          productId: 2,
          attachments: [
            {
              name: "",
              url: "",
            },
          ],
        };
        dispatch(conversationAction.onSendMessageViaApi(newMsgContent))
          .then((res) => {
            if (res.responseCode === 1) {
              setSelectedNewRoomChat({
                id: res?.responseData?.roomId,
                isChatRoomExist: false,
              });
              localStorage.setItem(
                "selectedRoomIdLocal",
                JSON.stringify({
                  id: res?.responseData?.roomId,
                  isChatRoomExist: false,
                  hubUserId: res?.responseData?.hubUserId,
                })
              );
              chatDetail(res?.responseData?.roomId);
              setRoomId(res?.responseData?.roomId);
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } else {
        uploadAttachmentWithNewRoom(messageContent, selectedUser, attachment);
      }
    } else {
      onSendMessageWithExistingRoom(content, attachment);
    }
  };

  const handleSelectChat = () => {
    setMessageDetailsData([]);
    setSelectChat(!selectChat);
    setStartChat(true);
    setChatFullScreen(false);
  };

  const loadChat = (hubId) => {
    dispatch(
      conversationAction.onGetUserRoomsByUsersId({
        roomName: "",
        hubUserId: hubId,
        productId: 2,
        isFavorite: false,
        campaignId: 1,
        pageNo: 0,
        name: "",
      })
    )
      .then((res) => {
        setUserRooms(res?.responseData?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const chatDetail = (roomId) => {
    dispatch(
      conversationAction.onGetChatDetailsByRoomId({
        roomId: roomId,
        hubUserId: getHubId?.hubUserId,
        productId: 2,
        take: 200,
        skip: 0,
      })
    )
      .then((res) => {
        if (res.responseCode === 1) {
          messageDetail.current =
            res?.responseData?.length > 0 ? res.responseData : [];
          setMessageDetailsData(
            res?.responseData?.length > 0 ? res.responseData : []
          );
        }
      })
      .catch((error) => {
        console.log("There is some issue in chat Detail", error);
      });
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    dispatch(dashboardActions.onGetUserDevices(userId))
      .then((res) => {
        setDevice(res.data.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  const handleStartChat = () => {
    // setStartChat(false);
    // var roomId = localStorage.getItem("selectedRoomId");
    // if (roomId > 0) {
    // }
  };

  useEffect(() => {
    chatDetail(selectedNewRoomChat?.id);
  }, []);

  useEffect(() => {
    if(getHubId?.id && getHubId?.hubUserId){
      setIsNewChat(false);
    } else {
      setIsNewChat(true);
    }
  },[selectedNewRoomChat])
  const getChatIds = (room)=>{
    localStorage.setItem("hubUserId", room?.hubUserId);
    localStorage.setItem(
     "selectedRoomIdLocal",
     JSON.stringify({
       id: room?.roomId,
       isChatRoomExist: false,
       hubUserId: room?.hubUserId,
     })
   )}

   useEffect(()=>{
    setSelectedNewRoomChat(
      JSON.parse(localStorage.getItem("selectedRoomIdLocal"))
    )
    getHubId = JSON.parse(localStorage.getItem("selectedRoomIdLocal"))
  },[isNewChat])

  return (
    <div className="Dashboardmain-container  chat-av-shopify">
      {isLoading ? <Loader /> : null}
      <div className="Dashboardmain-container__header"></div>

      <div className="dashboardMainSection message-screen">
        <div className="message-box">
          <div
            className="message-detail"
            style={{ width: chatFullScreen ? "100%" : "" }}
          >
            <NewChat
              isLoading={isLoading}
              selectChat={selectChat}
              hubUserIdProp={hubUserId}
              messageDetailsData={messageDetailsData}
              selectedChat={handleSelectChat}
              setSelectChat={setSelectChat}
              startChat={startChat}
              setStartChat={setStartChat}
              chatFullScreen={chatFullScreen}
              setChatFullScreen={setChatFullScreen}
              onSendNewMessage={onSendNewMessage}
              chatDetail={chatDetail}
              handleStartChat={handleStartChat}
              selectedNewRoomChatRoomId={selectedNewRoomChat}
              getHubId={getHubId}
              isNewChat={isNewChat }
              setIsNewChat ={setIsNewChat}
              getChatIds={getChatIds}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateConversation;
