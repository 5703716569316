import React from "react";
import { closeIcon } from "../../../resources/images/index";

function AppModal({ handleclose, children }) {
  return (
    <div className="overlay">
      <div className="overlay__modalWrapper">
        <span
          className="overlay__modalWrapper__modalTiele--close"
          onClick={handleclose}
        >
          <img src={closeIcon} alt="" />
        </span>
        <span className="overlay__modalWrapper__modalContent">{children}</span>
      </div>
    </div>
  );
}

export default AppModal;
