import React from "react";

const NotificationCard = ({
  icon,
  title,
  backgroundColor,
  borderColor,
  iconStyle,
  margin,
  height,
}) => {
  const cardStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    borderStyle: borderColor ? "solid 2px" : undefined,
    height: "100%", // adjust the height based on your need
    margin: margin,
    borderRadius: "12px",
    width: "440px",
    height: height,
    paddingRight: "20px",
  };

  return (
    <div className="CenteredCard" style={cardStyles}>
      {icon && (
        <img
          src={icon}
          alt="icon"
          className="Centered_cardIcon"
          style={iconStyle}
        />
      )}
      <h2>{title}</h2>
    </div>
  );
};

export default NotificationCard;
